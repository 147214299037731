import { Box } from '@material-ui/core';
import AtomicTextFieldV2 from 'components/atomic/AtomicTextFieldV2';
import Panel from 'components/Panel';
import { NewConsolidationStates } from '../states';

export default function ConsolidationRemarks() {
  return (
    <Panel title="Remarks" growPanel>
      <Box display="flex" flexGrow={1} style={{ margin: '16px 24px 24px 24px', overflow: 'auto' }}>
        <AtomicTextFieldV2
          state={NewConsolidationStates.step2.consolidationRemark}
          label="Remarks"
          multiline
          rows={4}
          placeholder="Add your remarks here"
        />
      </Box>
    </Panel>
  );
}
