import { Box, InputBase } from '@material-ui/core';
import { navigate } from '@reach/router';
import { AutocompletePopover } from 'components/AutocompletePopover';
import { useSearchAutocomplete } from 'components/form/FormInputSearch';
import React from 'react';
import { useRecoilState, useRecoilValue } from 'recoil';
import { mapSearchType } from 'utils/Enums';
import { HeaderStates } from './states';

export default function HeaderSearchBar() {
  const ref = React.useRef<HTMLInputElement>(null);
  const [isFocused, setIsFocused] = React.useState<boolean>(false);
  const [isLosingFocus, setIsLosingFocus] = React.useState<boolean>(false);
  const searchType = useRecoilValue(HeaderStates.searchType);
  const [search, setSearch] = useRecoilState(HeaderStates.search);
  const [searchResult, setSearchResult] = useRecoilState(HeaderStates.searchResult);
  const [searchText, setSearchText] = useRecoilState(HeaderStates.searchText);

  useSearchAutocomplete({
    setSearch,
    searchType,
    searchText,
    numResults: 10,
    result: searchResult,
  });

  React.useEffect(() => {
    let timeoutHandle: any;
    if (isFocused && isLosingFocus) {
      timeoutHandle = setTimeout(() => {
        setIsFocused(false);
        setSearchText(searchResult?.value || '');
      }, 200);
    }
    return () => {
      clearTimeout(timeoutHandle);
    };
  }, [isFocused, isLosingFocus, searchResult]);

  return (
    <Box flexGrow={1} paddingX={1} display="flex" alignItems="stretch">
      <InputBase
        placeholder={'Search ' + mapSearchType(searchType) + ' ...'}
        value={searchText}
        onChange={(e) => setSearchText(e.currentTarget.value)}
        inputRef={ref}
        onFocus={(e) => {
          setIsFocused(true);
          setIsLosingFocus(false);
        }}
        onBlur={(e) => {
          setIsLosingFocus(true);
        }}
        fullWidth
      />
      <AutocompletePopover
        anchorEl={ref.current}
        open={isFocused}
        query={searchText}
        results={search.results}
        onSelect={(result) => {
          setSearchResult({
            id: result.id,
            value: result.value,
          });
          setSearchText(result.value);
          navigate(`/search/${searchType}/${result.id}`);
        }}
        waiting={search.waiting}
        error={search.error}
      />
    </Box>
  );
}
