import { RouteComponentProps } from '@reach/router';
import { FlexGrowRouter } from 'components/StyledComponents';
import React from 'react';
import { useResetAtomGroupState } from 'recoil-utils/utils';
import GlobalUpdateStep1 from './global-update-step1';
import GlobalUpdateStep2 from './global-update-step2';
import { GUStates } from './states';

interface DocumentsProps extends RouteComponentProps {}

function GlobalUpdatePage(props: DocumentsProps) {
  const reset = useResetAtomGroupState(GUStates);

  React.useEffect(() => {
    return () => {
      reset();
    };
  }, []);

  return (
    <FlexGrowRouter>
      <GlobalUpdateStep1 path="/" />
      <GlobalUpdateStep2 path="/update" />
    </FlexGrowRouter>
  );
}

export default GlobalUpdatePage;
