import { InputAdornment } from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import Popover from '@material-ui/core/Popover';
import TextField from '@material-ui/core/TextField';
import CloseIcon from '@material-ui/icons/Close';
import EventIcon from '@material-ui/icons/Event';
import { PickersDay, StaticDatePicker } from '@material-ui/pickers';
import clsx from 'clsx';
import * as React from 'react';
import { makeCss, theme } from 'styles';
import { parseDate } from 'types/Date';
import { formatDate } from 'utils/Dates';
import { FormInputText } from './FormInputText';

const classes = makeCss({
  input: {
    '&:hover $clearButtonDirty, & .Mui-focused $clearButtonDirty': {
      visibility: 'visible',
    },
  },
  clearButtonDirty: {},
  clearButton: {
    padding: '2px',
    visibility: 'hidden',
  },
});

interface Props {
  value: Date | null;
  onValue(v: Date | null): void;

  label?: string;
  required?: boolean;
  disabled?: boolean;
  error?: string | null;

  minDate?: Date | null;
  maxDate?: Date | null;

  clearable?: boolean;
}

export const FormInputDate: React.FC<Props> = ({
  value,
  onValue,
  label,
  required,
  disabled,
  error,
  minDate,
  maxDate,
  clearable,
}) => {
  const anchorRef = React.useRef<HTMLInputElement>(null);
  const [open, setOpen] = React.useState<boolean>(false);

  const valueAsString = formatDate(value);
  const [text, setText] = React.useState<string>(valueAsString);
  const [isFocused, setIsFocused] = React.useState<boolean>(false);

  React.useEffect(() => {
    if (isFocused) {
      // don't mess with the input while they are typing
    } else {
      setText(formatDate(value));
    }
  }, [value, isFocused]);

  return (
    <>
      <FormInputText
        inputRef={anchorRef}
        className={classes.input}
        value={text}
        onValue={setText}
        onFocus={(e) => {
          setIsFocused(true);
        }}
        onBlur={(e) => {
          setIsFocused(false);
          const src = e.currentTarget.value;
          const userChanged = valueAsString !== src;
          if (userChanged) {
            const d = parseDate(src);
            setText(formatDate(d));
            onValue(d);
          }
        }}
        placeholder="mm/dd/yyyy"
        label={label}
        required={required}
        disabled={disabled}
        error={!!error || (required && !value)}
        helperText={error || ''}
        endAdornment={
          <InputAdornment position="end" style={{ marginRight: theme.spacing(-1) }}>
            {clearable && (
              <IconButton
                title="Clear"
                className={clsx(classes.clearButton, {
                  [classes.clearButtonDirty]: !!value,
                })}
                onClick={() => {
                  onValue(null);
                }}
                size="small"
                tabIndex={-1}
              >
                <CloseIcon fontSize="small" />
              </IconButton>
            )}
            <IconButton
              // color="secondary"
              size="small"
              disabled={disabled}
              onClick={(e) => {
                if (!open && !value) {
                  onValue(new Date()); // if a day is not selected, start with today - OMS-98
                }
                setOpen(!open);
              }}
            >
              <EventIcon />
            </IconButton>
          </InputAdornment>
        }
      />
      <Popover
        open={open}
        anchorEl={anchorRef.current}
        onClose={() => {
          setOpen(false);
        }}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
      >
        <StaticDatePicker
          views={['month', 'date']}
          openTo="date"
          value={value}
          onChange={(date) => {
            if (date) {
              onValue(date);
              setOpen(false);
            } else {
              onValue(null);
            }
          }}
          minDate={minDate}
          maxDate={maxDate}
          displayStaticWrapperAs="desktop"
          renderInput={(params) => <TextField {...params} />}
          renderDay={(day, selectedDays, dayProps) => {
            if (dayProps.selected) {
              return (
                <div
                  key={dayProps.key}
                  onClick={(e) => {
                    // When they click the selected date, it should close the popup - see OMS-98
                    setOpen(false);
                  }}
                >
                  <PickersDay {...dayProps} />
                </div>
              );
            }
            if (dayProps.today) {
              return (
                <PickersDay
                  {...dayProps}
                  style={{ border: '1px solid ' + theme.palette.primary.main }}
                />
              );
            }
            return <PickersDay {...dayProps} />;
          }}
        />
      </Popover>
    </>
  );
};
