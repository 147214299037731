import { Box, PopoverActions, Typography } from '@material-ui/core';
import UWLTableV2 from 'components/g-table/UWLTableV2';
import TableExportWindowGroup from 'components/TableExportWindowGroup';
import UniversalPopover from 'components/UniversalPopover';
import React from 'react';
import { useRecoilValue } from 'recoil';
import { AlertButton } from '../components/AlertButton';
import {
  ContainersInTransitDisplay,
  containersInTransitDisplayColumns,
  DashboardPageStates,
  transformContainerToShipmentInTransitDisplay,
} from '../states';

export default function DueToArriveButton() {
  const inTransit = useRecoilValue(DashboardPageStates.inTransit);

  const [containers, setContainers] = React.useState<ContainersInTransitDisplay[]>([]);
  const [anchorEl, setAnchorEl] = React.useState<(EventTarget & HTMLElement) | null>(null);
  const [openPopover, setOpenPopover] = React.useState(false);
  const action: React.RefObject<PopoverActions> | null = React.useRef(null);
  const [tableHeight, setTableHeight] = React.useState(0);

  React.useEffect(() => {
    if (action && action.current) {
      action.current.updatePosition();
    }
  }, [tableHeight]);

  React.useEffect(() => {
    if (inTransit) {
      const dueToArriveShipments = inTransit
        .filter((transit) => transit.isDueToArrive)
        .map((shipment) => shipment.container);
      setContainers(transformContainerToShipmentInTransitDisplay(dueToArriveShipments));
    }
  }, [inTransit]);

  return (
    <>
      <AlertButton
        label="Due To Arrive"
        quantity={containers.length}
        onClick={(e: any) => {
          setOpenPopover(true);
          setAnchorEl(e.currentTarget);
        }}
      />

      <UniversalPopover
        popoverId="due-to-arrive-popover"
        open={openPopover}
        anchorEl={anchorEl}
        action={action}
        onClose={() => {
          setAnchorEl(null);
          setOpenPopover(false);
        }}
        anchorOriginVertical={20}
        anchorOriginHorizontal={0}
      >
        <Box padding={3} width={window.innerWidth * 0.9} display="flex" flexDirection="column">
          <Box display="flex" justifyContent="space-between" marginBottom={2}>
            <Box marginRight={3} display="flex" alignItems="center">
              <Typography variant="h3">Containers Due to Arrive</Typography>
              <TableExportWindowGroup
                label="DueToArrive"
                rows={containers}
                columns={containersInTransitDisplayColumns}
              />
            </Box>
            <Box marginLeft={3}>
              <Box display="flex" alignItems="center">
                <Box marginRight={1}>
                  <Typography variant="h4">
                    <i>Total Shipments:</i>
                  </Typography>
                </Box>
                <Typography variant="h3">{containers.length}</Typography>
              </Box>
            </Box>
          </Box>
          <UWLTableV2
            rows={containers}
            columns={containersInTransitDisplayColumns}
            emptyMessage="No Containers Due to Arrive"
            maxHeight={500}
            onHeightChange={(height) => {
              setTableHeight(height);
            }}
          />
        </Box>
      </UniversalPopover>
    </>
  );
}
