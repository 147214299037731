import { Box } from '@material-ui/core';
import { HotBookingSwitch } from 'app/components/HotBookingSwitch';
import { Link } from '@reach/router';
import { GqlBooking, PartyType, useBookingListQuery } from 'api/GQL_Types';
import { userContextAtom } from 'app';
import ErrorMessage from 'components/ErrorMessage';
import Panel from 'components/Panel';
import SearchBar from 'components/SearchBar';
import { Prepend } from 'components/Prepend';
import TableExportWindowGroup, { uwlColToGTable } from 'components/TableExportWindowGroup';
import { UWLTable } from 'components/UWLTable/UWLTable';
import React from 'react';
import { selector, useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import { genKey, newAtom } from 'recoil-utils/utils';
import { portToStringMaybe } from 'types/Port';
import { UWLTableColumn } from 'types/UWLTable';
import { formatDate } from 'utils/Dates';
import { mapMoveType, mapShipmentStatus } from 'utils/Enums';
import { formatNumber } from 'utils/Numbers';
import { bookingListSelectedTab } from '.';
import { BookingConsolidationToggler } from '../BookingConsolidationToggler';
import { HotState } from 'components/HotToggleSwitch';

const bookingSearchState = newAtom('');
const bookingListState = newAtom<TableRow[]>([]);

const filteredBookingListState = selector<TableRow[]>({
  key: genKey(),
  get: ({ get }) => {
    const searchField = get<string>(bookingSearchState);
    const bookings = get(bookingListState);
    const lowerField = searchField.toLowerCase();

    if (searchField === '') {
      return bookings;
    } else {
      return bookings.filter((row) => row.filterKey.includes(lowerField));
    }
  },
});

const columns: UWLTableColumn<TableRow>[] = [
  { id: 'booking', label: 'Booking', type: 'string', whiteSpace: 'nowrap' },
  { id: 'supplierName', label: 'Supplier', type: 'string', whiteSpace: 'nowrap' },
  { id: 'bookingDate', label: 'Booking Date', type: 'date' },
  { id: 'bookingStatus', label: 'Booking Status', type: 'string' },
  { id: 'pol', label: 'POL', type: 'string', whiteSpace: 'nowrap' },
  { id: 'cargoReadyDate', label: 'Cgo Rdy Date', type: 'date' },
  { id: 'revisedCargoReadyDate', label: 'REV CRG RDY Date', type: 'date' },
  { id: 'polEtd', label: 'ETD', type: 'date' },
  { id: 'pod', label: 'POD', type: 'string', whiteSpace: 'nowrap' },
  { id: 'moveType', label: 'Delivery Type', type: 'string' },
  { id: 'containers', label: 'Equipment (QTY)', type: 'string', whiteSpace: 'pre-wrap' },
  { id: 'deliveryLocationName', label: 'Delivery Loc', type: 'string' },
];

const defaultColumnIds = [
  'booking',
  'supplierName',
  'bookingDate',
  'bookingStatus',
  'pol',
  'cargoReadyDate',
  'revisedCargoReadyDate',
  'polEtd',
  'pod',
  'moveType',
  'containers',
  'deliveryLocationName',
];

const factoryColumnIds = [
  'booking',
  'bookingDate',
  'bookingStatus',
  'pol',
  'cargoReadyDate',
  'revisedCargoReadyDate',
  'moveType',
  'containers',
  'polEtd',
  'pod',
  'deliveryLocationName',
];

interface TableRow {
  id: string;
  booking: string;
  supplierName: string;
  bookingDate: Date | null;
  bookingStatus: string;
  cargoReadyDate: Date | null;
  revisedCargoReadyDate: Date | null | undefined;
  pol: string;
  polEtd: Date | null | undefined;
  polAtd: Date | null | undefined;
  pod: string;
  moveType: string;
  containers: string;
  deliveryLocationName: string;
  hot: HotState;

  filterKey: string; // The text rows can be filtered by with the quick search
}

interface Props {}
export default function BookingBrowser(props: Props) {
  const userContext = useRecoilValue(userContextAtom);
  const setBookings = useSetRecoilState(bookingListState);
  const [searchField, setSearchField] = useRecoilState(bookingSearchState);
  const filteredBookings = useRecoilValue(filteredBookingListState);

  const { loading, error } = useBookingListQuery({
    fetchPolicy: 'no-cache',
    onCompleted(bookingsData) {
      setBookings(
        bookingsData.shipments
          .map((booking): TableRow => {
            booking = booking as GqlBooking;
            let containers = '';
            if (booking.containers) {
              const nContainersByType = new Map<string, number>();
              booking.containers.forEach((container) => {
                const n = nContainersByType.get(container.containerType) || 0;
                nContainersByType.set(container.containerType, n + 1);
              });
              const lines: string[] = [];
              nContainersByType.forEach((nContainers, type) => {
                lines.push(`${type} (QTY ${formatNumber(nContainers)})`);
              });
              containers = lines.join(',\n');
            }

            let supplierName = '';
            booking.relatedParties.forEach((rp) => {
              if (rp.partyType === PartyType.Supplier) {
                supplierName = rp.party.name;
              }
            });

            return {
              id: booking.id,
              booking: booking.referenceNumber,
              supplierName: supplierName,
              bookingDate: booking.createDate,
              bookingStatus: mapShipmentStatus(booking.status),
              cargoReadyDate: booking.cargoReadyDate,
              revisedCargoReadyDate: booking.revisedCargoReadyDate,
              pol: portToStringMaybe(booking.logistics.pol),
              polEtd: booking.logistics.polEtd,
              polAtd: booking.logistics.polAtd,
              pod: portToStringMaybe(booking.logistics.pod),
              moveType: booking.logistics.moveType ? mapMoveType(booking.logistics.moveType) : '',
              containers: containers,
              deliveryLocationName: booking.logistics.deliveryLocation?.name ?? '',
              hot: {
                isHot: booking.isHot,
                hotMarkedBy: booking.hotMarkedBy,
                hotMarkedTimestamp: booking.hotMarkedTimestamp,
              },
              filterKey: '', // will get set in the next pass
            };
          })
          .map((row) => {
            return {
              ...row,
              filterKey: [
                // Include all the text they can search by
                row.booking,
                row.bookingStatus,
                row.containers,
                row.deliveryLocationName,
                row.moveType,
                row.supplierName,
                row.pod,
                row.pol,
                row.hot,
                formatDate(row.bookingDate),
                formatDate(row.polEtd),
                formatDate(row.cargoReadyDate),
                formatDate(row.revisedCargoReadyDate),
              ]
                .join('||||')
                .toLowerCase(),
            };
          })
      );
    },
  });

  const columnsDisplay =
    userContext?.activeContact?.role?.name === 'Factory' // TODO FIXME HACK
      ? factoryColumnIds
      : defaultColumnIds;

  return (
    <Panel
      title="Bookings"
      growPanel
      titleDecorator={
        <TableExportWindowGroup
          label="Bookings"
          rows={filteredBookings}
          columns={columnsDisplay
            .reduce((list, cid) => {
              return list.concat(columns.filter((c) => c.id === cid));
            }, [] as UWLTableColumn<TableRow>[])
            .map(uwlColToGTable)}
        />
      }
      topRight={
        <Box width="25%" bgcolor="#F8F8F8" padding={1}>
          <SearchBar
            placeholder="Search by Booking #"
            field={searchField}
            updateField={setSearchField}
          />
        </Box>
      }
    >
      <BookingConsolidationToggler state={bookingListSelectedTab} />
      <Box height="100%" paddingX={2} paddingBottom={2}>
        <ErrorMessage error={error ? error + '' : null} />
        <UWLTable
          rowId="id"
          columns={columns}
          columnsDisplay={columnsDisplay}
          isLoading={loading}
          rows={filteredBookings}
          emptyMessage="- No Bookings Available -"
          renderCell={{
            booking(row) {
              return (
                <Prepend
                  item={<HotBookingSwitch bookingId={row.id} initialState={row.hot} small />}
                >
                  <Link to={'/bookings/' + row.id}>{row.booking}</Link>
                </Prepend>
              );
            },
          }}
        />
      </Box>
    </Panel>
  );
}
