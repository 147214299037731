import {
  add,
  endOfMonth,
  endOfWeek,
  format,
  getWeek,
  isValid,
  startOfMonth,
  startOfWeek,
  sub,
} from 'date-fns';

export function isValidDate(date: any): date is Date {
  return isValid(date);
}

/**
 * The default date format across the system.
 */
export function formatDate(date: Date | null | undefined): string {
  if (isValidDate(date)) {
    return format(date, 'MM/dd/yyyy');
  }
  return '';
}

/**
 * The default date+time format across the system.
 */
export function formatDateTime(date: Date | null | undefined): string {
  if (isValidDate(date)) {
    return format(date, 'MM/dd/yyyy hh:mm a');
  }
  return '';
}

export function formatTime(date: Date | null | undefined, formatString: string): string {
  if (isValidDate(date)) {
    return format(date, formatString);
  }
  return '';
}

export function addTime(
  date: Date,
  obj: {
    years?: number;
    months?: number;
    weeks?: number;
    days?: number;
    hours?: number;
    minutes?: number;
    seconds?: number;
  }
): Date {
  return add(date, obj);
}

export function subTime(
  date: Date,
  obj: {
    years?: number;
    months?: number;
    weeks?: number;
    days?: number;
    hours?: number;
    minutes?: number;
    seconds?: number;
  }
): Date {
  return sub(date, obj);
}

export function startOf(date: Date, token: 'week' | 'month'): Date {
  switch (token) {
    case 'week':
      return startOfWeek(date);
    case 'month':
      return startOfMonth(date);
    default:
      return date;
  }
}

export function endOf(date: Date, token: 'week' | 'month'): Date {
  switch (token) {
    case 'week':
      return endOfWeek(date);
    case 'month':
      return endOfMonth(date);
    default:
      return date;
  }
}

export function getWeekNumber(date: Date): number {
  return getWeek(date, {
    weekStartsOn: 1,
  });
}

export function compareDates(a: Date | null | undefined, b: Date | null | undefined): number {
  const aDate = isValidDate(a) ? a.getTime() : 0;
  const bDate = isValidDate(b) ? b.getTime() : 0;
  return aDate - bDate;
}
