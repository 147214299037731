import { Box } from '@material-ui/core';
import { navigate, RouteComponentProps } from '@reach/router';
import { useGlobalUpdateLazyQuery } from 'api/GQL_Types';
import CompositePanel from 'components/CompositePanel';
import { Label } from 'components/TextFieldWarnDecorator';
import React from 'react';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { formatDate } from 'utils/Dates';
import { formatNumber } from 'utils/Numbers';
import { GUStates } from '../states';
import GlobalUpdateStep2Customs from './customs';
import GlobalUpdateStep2Confirmation from './GlobalUpdateStep2Confirmation';
import GlobalUpdateStep2Header from './GlobalUpdateStep2Header';
import GlobalUpdateStep2Records from './records';
import GlobalUpdateStep2Transportation from './transportation';

interface GlobalUpdateProps extends RouteComponentProps {}
export default function GlobalUpdateStep2(props: GlobalUpdateProps) {
  const selectedResults = useRecoilValue(GUStates.selectedGlobalUpdateRecords);
  const setAllRecordData = useSetRecoilState(GUStates.recordData);

  const setConfNumber = useSetRecoilState(GUStates.form.confirmationNumber);
  const setConfirmedBookingDate = useSetRecoilState(GUStates.form.confirmedDate);
  const setPolEtd = useSetRecoilState(GUStates.form.polEtd);
  const setPolAtd = useSetRecoilState(GUStates.form.polAtd);
  const setTranshipEta = useSetRecoilState(GUStates.form.transhipEta);
  const setTranshipAta = useSetRecoilState(GUStates.form.transhipAta);
  const setTranshipEtd = useSetRecoilState(GUStates.form.transhipEtd);
  const setTranshipAtd = useSetRecoilState(GUStates.form.transhipAtd);
  const setPodEta = useSetRecoilState(GUStates.form.podEta);
  const setPodAta = useSetRecoilState(GUStates.form.podAta);
  const setRampEta = useSetRecoilState(GUStates.form.rampEta);
  const setRampAta = useSetRecoilState(GUStates.form.rampAta);
  const setRampOutGateDate = useSetRecoilState(GUStates.form.rampOutGateDate);
  const setPortOutGateDate = useSetRecoilState(GUStates.form.portOutGateDate);
  const setDeliveryEta = useSetRecoilState(GUStates.form.deliveryEta);
  const setDeliveryAta = useSetRecoilState(GUStates.form.deliveryAta);
  const setEmptyReturnDate = useSetRecoilState(GUStates.form.emptyReturnDate);
  const setEmptyNotify = useSetRecoilState(GUStates.form.emptyNotify);
  const setEmptyPickup = useSetRecoilState(GUStates.form.emptyPickup);
  const setDemurrageLfd = useSetRecoilState(GUStates.form.demurrageLfd);
  const setDetentionLfd = useSetRecoilState(GUStates.form.detentionLfd);
  const setConsolidatorLfd = useSetRecoilState(GUStates.form.consolidatorLfd);
  const setCustomsBrokerRef = useSetRecoilState(GUStates.form.customsBrokerRef);
  const setIsfResponseNo = useSetRecoilState(GUStates.form.isfResponseNo);
  const setIsfFilingDate = useSetRecoilState(GUStates.form.isfFilingDate);
  const setEntryNumber = useSetRecoilState(GUStates.form.entryNumber);
  const setEntryDate = useSetRecoilState(GUStates.form.entryDate);
  const setEntryValue = useSetRecoilState(GUStates.form.entryValue);
  const setMpf = useSetRecoilState(GUStates.form.mpf);
  const setHmf = useSetRecoilState(GUStates.form.hmf);
  const setDuty = useSetRecoilState(GUStates.form.duty);
  const setCustomsExamType = useSetRecoilState(GUStates.form.customsExamType);
  const setCustomsExamNotifyDate = useSetRecoilState(GUStates.form.customsExamNotifyDate);
  const setCustomsExamFee = useSetRecoilState(GUStates.form.customsExamFee);
  const setCustomsReleaseDate = useSetRecoilState(GUStates.form.customsReleaseDate);
  const setFdaExamType = useSetRecoilState(GUStates.form.fdaExamType);
  const setFdaExamNotifyDate = useSetRecoilState(GUStates.form.fdaExamNotifyDate);
  const setFdaExamFee = useSetRecoilState(GUStates.form.fdaExamFee);
  const setFdaReleaseDate = useSetRecoilState(GUStates.form.fdaReleaseDate);
  const setEntryFeeTotal = useSetRecoilState(GUStates.form.entryFeeTotal);

  // Initial value setters
  const setInitConfirmedBookingDate = useSetRecoilState(GUStates.initialState.initConfirmedDate);
  const setInitConfNumber = useSetRecoilState(GUStates.initialState.initConfirmationNumber);
  const setInitPolEtd = useSetRecoilState(GUStates.initialState.initPolEtd);
  const setInitPolAtd = useSetRecoilState(GUStates.initialState.initPolAtd);
  const setInitTranshipEta = useSetRecoilState(GUStates.initialState.initTranshipEta);
  const setInitTranshipAta = useSetRecoilState(GUStates.initialState.initTranshipAta);
  const setInitTranshipEtd = useSetRecoilState(GUStates.initialState.initTranshipEtd);
  const setInitTranshipAtd = useSetRecoilState(GUStates.initialState.initTranshipAtd);
  const setInitPodEta = useSetRecoilState(GUStates.initialState.initPodEta);
  const setInitPodAta = useSetRecoilState(GUStates.initialState.initPodAta);
  const setInitRampEta = useSetRecoilState(GUStates.initialState.initRampEta);
  const setInitRampAta = useSetRecoilState(GUStates.initialState.initRampAta);
  const setInitRampOutGateDate = useSetRecoilState(GUStates.initialState.initRampOutGateDate);
  const setInitPortOutGateDate = useSetRecoilState(GUStates.initialState.initPortOutGateDate);
  const setInitDeliveryEta = useSetRecoilState(GUStates.initialState.initDeliveryEta);
  const setInitDeliveryAta = useSetRecoilState(GUStates.initialState.initDeliveryAta);
  const setInitEmptyReturnDate = useSetRecoilState(GUStates.initialState.initEmptyReturnDate);
  const setInitEmptyNotify = useSetRecoilState(GUStates.initialState.initEmptyNotify);
  const setInitEmptyPickup = useSetRecoilState(GUStates.initialState.initEmptyPickup);
  const setInitDemurrageLfd = useSetRecoilState(GUStates.initialState.initDemurrageLfd);
  const setInitDetentionLfd = useSetRecoilState(GUStates.initialState.initDetentionLfd);
  const setInitConsolidatorLfd = useSetRecoilState(GUStates.initialState.initConsolidatorLfd);
  const setInitCustomsBrokerRef = useSetRecoilState(GUStates.initialState.initCustomsBrokerRef);
  const setInitIsfResponseNo = useSetRecoilState(GUStates.initialState.initIsfResponseNo);
  const setInitIsfFilingDate = useSetRecoilState(GUStates.initialState.initIsfFilingDate);
  const setInitEntryNumber = useSetRecoilState(GUStates.initialState.initEntryNumber);
  const setInitEntryDate = useSetRecoilState(GUStates.initialState.initEntryDate);
  const setInitEntryValue = useSetRecoilState(GUStates.initialState.initEntryValue);
  const setInitMpf = useSetRecoilState(GUStates.initialState.initMpf);
  const setInitHmf = useSetRecoilState(GUStates.initialState.initHmf);
  const setInitDuty = useSetRecoilState(GUStates.initialState.initDuty);
  const setInitCustomsExamType = useSetRecoilState(GUStates.initialState.initCustomsExamType);
  const setInitCustomsExamNotifyDate = useSetRecoilState(
    GUStates.initialState.initCustomsExamNotifyDate
  );
  const setInitCustomsExamFee = useSetRecoilState(GUStates.initialState.initCustomsExamFee);
  const setInitCustomsReleaseDate = useSetRecoilState(GUStates.initialState.initCustomsReleaseDate);
  const setInitFdaExamType = useSetRecoilState(GUStates.initialState.initFdaExamType);
  const setInitFdaExamNotifyDate = useSetRecoilState(GUStates.initialState.initFdaExamNotifyDate);
  const setInitFdaExamFee = useSetRecoilState(GUStates.initialState.initFdaExamFee);
  const setInitFdaReleaseDate = useSetRecoilState(GUStates.initialState.initFdaReleaseDate);
  const setInitEntryFeeTotal = useSetRecoilState(GUStates.initialState.initEntryFeeTotal);

  //Labels setters
  const setConfNumberLabels = useSetRecoilState(GUStates.form.confirmationNumberLabels);
  const setConfirmedBookingDateLabels = useSetRecoilState(GUStates.form.confirmedDateLabels);
  const setPolEtdLabels = useSetRecoilState(GUStates.form.polEtdLabels);
  const setPolAtdLabels = useSetRecoilState(GUStates.form.polAtdLabels);
  const setTranshipEtaLabels = useSetRecoilState(GUStates.form.transhipEtaLabels);
  const setTranshipAtaLabels = useSetRecoilState(GUStates.form.transhipAtaLabels);
  const setTranshipEtdLabels = useSetRecoilState(GUStates.form.transhipEtdLabels);
  const setTranshipAtdLabels = useSetRecoilState(GUStates.form.transhipAtdLabels);
  const setPodEtaLabels = useSetRecoilState(GUStates.form.podEtaLabels);
  const setPodAtaLabels = useSetRecoilState(GUStates.form.podAtaLabels);
  const setRampEtaLabels = useSetRecoilState(GUStates.form.rampEtaLabels);
  const setRampAtaLabels = useSetRecoilState(GUStates.form.rampAtaLabels);
  const setRampOutGateDateLabels = useSetRecoilState(GUStates.form.rampOutGateDateLabels);
  const setPortOutGateDateLabels = useSetRecoilState(GUStates.form.portOutGateDateLabels);
  const setDeliveryEtaLabels = useSetRecoilState(GUStates.form.deliveryEtaLabels);
  const setDeliveryAtaLabels = useSetRecoilState(GUStates.form.deliveryAtaLabels);
  const setEmptyReturnDateLabels = useSetRecoilState(GUStates.form.emptyReturnDateLabels);
  const setEmptyNotifyLabels = useSetRecoilState(GUStates.form.emptyNotifyLabels);
  const setEmptyPickupLabels = useSetRecoilState(GUStates.form.emptyPickupLabels);
  const setDemurrageLfdLabels = useSetRecoilState(GUStates.form.demurrageLfdLabels);
  const setDetentionLfdLabels = useSetRecoilState(GUStates.form.detentionLfdLabels);
  const setConsolidatorLfdLabels = useSetRecoilState(GUStates.form.consolidatorLfdLabels);
  const setCustomsBrokerRefLabels = useSetRecoilState(GUStates.form.customsBrokerRefLabels);
  const setIsfResponseNoLabels = useSetRecoilState(GUStates.form.isfResponseNoLabels);
  const setIsfFilingDateLabels = useSetRecoilState(GUStates.form.isfFilingDateLabels);
  const setEntryNumberLabels = useSetRecoilState(GUStates.form.entryNumberLabels);
  const setEntryDateLabels = useSetRecoilState(GUStates.form.entryDateLabels);
  const setEntryValueLabels = useSetRecoilState(GUStates.form.entryValueLabels);
  const setMpfLabels = useSetRecoilState(GUStates.form.mpfLabels);
  const setHmfLabels = useSetRecoilState(GUStates.form.hmfLabels);
  const setDutyLabels = useSetRecoilState(GUStates.form.dutyLabels);
  const setCustomsExamTypeLabels = useSetRecoilState(GUStates.form.customsExamTypeLabels);
  const setCustomsExamNotifyDateLabels = useSetRecoilState(
    GUStates.form.customsExamNotifyDateLabels
  );
  const setCustomsExamFeeLabels = useSetRecoilState(GUStates.form.customsExamFeeLabels);
  const setCustomsReleaseDateLabels = useSetRecoilState(GUStates.form.customsReleaseDateLabels);
  const setFdaExamTypeLabels = useSetRecoilState(GUStates.form.fdaExamTypeLabels);
  const setFdaExamNotifyDateLabels = useSetRecoilState(GUStates.form.fdaExamNotifyDateLabels);
  const setFdaExamFeeLabels = useSetRecoilState(GUStates.form.fdaExamFeeLabels);
  const setFdaReleaseDateLabels = useSetRecoilState(GUStates.form.fdaReleaseDateLabels);
  const setEntryFeeTotalLabels = useSetRecoilState(GUStates.form.entryFeeTotalLabels);

  const [getRecordData, { data: globalUpdateRecordData }] = useGlobalUpdateLazyQuery({
    fetchPolicy: 'network-only',
  });

  React.useEffect(() => {
    if (selectedResults.length === 0) {
      navigate('/admin/global-updates');
    } else {
      getRecordData({
        variables: {
          recordIds: selectedResults.map((r) => r.recordId),
        },
      });
    }
  }, [selectedResults]);

  React.useEffect(() => {
    if (globalUpdateRecordData) {
      //////////////////////////////////////////////
      // logistics
      {
        const { labels, value } = gatherDates(
          globalUpdateRecordData.globalUpdate.logistics,
          (l) => l.referenceNumber || '',
          (l) => l.confirmationDate
        );
        setConfirmedBookingDateLabels(labels);
        setConfirmedBookingDate(value);
        setInitConfirmedBookingDate(value);
      }
      {
        const { labels, value } = gatherStrings(
          globalUpdateRecordData.globalUpdate.logistics,
          (l) => l.referenceNumber || '',
          (l) => l.confirmationNumber
        );
        setConfNumberLabels(labels);
        setConfNumber(value);
        setInitConfNumber(value);
      }
      {
        const { labels, value } = gatherDates(
          globalUpdateRecordData.globalUpdate.logistics,
          (l) => l.referenceNumber || '',
          (l) => l.polEtd
        );
        setPolEtdLabels(labels);
        setPolEtd(value);
        setInitPolEtd(value);
      }
      {
        const { labels, value } = gatherDates(
          globalUpdateRecordData.globalUpdate.logistics,
          (l) => l.referenceNumber || '',
          (l) => l.polAtd
        );
        setPolAtdLabels(labels);
        setPolAtd(value);
        setInitPolAtd(value);
      }
      {
        const { labels, value } = gatherDates(
          globalUpdateRecordData.globalUpdate.logistics,
          (l) => l.referenceNumber || '',
          (l) => l.transitPortEta
        );
        setTranshipEtaLabels(labels);
        setTranshipEta(value);
        setInitTranshipEta(value);
      }
      {
        const { labels, value } = gatherDates(
          globalUpdateRecordData.globalUpdate.logistics,
          (l) => l.referenceNumber || '',
          (l) => l.transitPortAta
        );
        setTranshipAtaLabels(labels);
        setTranshipAta(value);
        setInitTranshipAta(value);
      }
      {
        const { labels, value } = gatherDates(
          globalUpdateRecordData.globalUpdate.logistics,
          (l) => l.referenceNumber || '',
          (l) => l.transitPortEtd
        );
        setTranshipEtdLabels(labels);
        setTranshipEtd(value);
        setInitTranshipEtd(value);
      }
      {
        const { labels, value } = gatherDates(
          globalUpdateRecordData.globalUpdate.logistics,
          (l) => l.referenceNumber || '',
          (l) => l.transitPortAtd
        );
        setTranshipAtdLabels(labels);
        setTranshipAtd(value);
        setInitTranshipAtd(value);
      }
      {
        const { labels, value } = gatherDates(
          globalUpdateRecordData.globalUpdate.logistics,
          (l) => l.referenceNumber || '',
          (l) => l.podEta
        );
        setPodEtaLabels(labels);
        setPodEta(value);
        setInitPodEta(value);
      }
      {
        const { labels, value } = gatherDates(
          globalUpdateRecordData.globalUpdate.logistics,
          (l) => l.referenceNumber || '',
          (l) => l.podAta
        );
        setPodAtaLabels(labels);
        setPodAta(value);
        setInitPodAta(value);
      }

      //////////////////////////////////////////////
      // containers
      const selectedContainerIds = selectedResults.map((result) => result.containerId);
      const selectedContainers = globalUpdateRecordData.globalUpdate.containers.filter((c) =>
        selectedContainerIds.includes(c.containerId)
      );
      {
        const { labels, value } = gatherDates(
          selectedContainers,
          (c) => c.containerNumber || '',
          (c) => c.rampEta
        );
        setRampEtaLabels(labels);
        setRampEta(value);
        setInitRampEta(value);
      }
      {
        const { labels, value } = gatherDates(
          selectedContainers,
          (c) => c.containerNumber || '',
          (c) => c.rampAta
        );
        setRampAtaLabels(labels);
        setRampAta(value);
        setInitRampAta(value);
      }
      {
        const { labels, value } = gatherDates(
          selectedContainers,
          (c) => c.containerNumber || '',
          (c) => c.rampOutGateDate
        );
        setRampOutGateDateLabels(labels);
        setRampOutGateDate(value);
        setInitRampOutGateDate(value);
      }
      {
        const { labels, value } = gatherDates(
          selectedContainers,
          (c) => c.containerNumber || '',
          (c) => c.portOutGateDate
        );
        setPortOutGateDateLabels(labels);
        setPortOutGateDate(value);
        setInitPortOutGateDate(value);
      }
      {
        const { labels, value } = gatherDates(
          selectedContainers,
          (c) => c.containerNumber || '',
          (c) => c.deliveryEta
        );
        setDeliveryEtaLabels(labels);
        setDeliveryEta(value);
        setInitDeliveryEta(value);
      }
      {
        const { labels, value } = gatherDates(
          selectedContainers,
          (c) => c.containerNumber || '',
          (c) => c.deliveryAta
        );
        setDeliveryAtaLabels(labels);
        setDeliveryAta(value);
        setInitDeliveryAta(value);
      }
      {
        const { labels, value } = gatherDates(
          selectedContainers,
          (c) => c.containerNumber || '',
          (c) => c.emptyReturnedDate
        );
        setEmptyReturnDateLabels(labels);
        setEmptyReturnDate(value);
        setInitEmptyReturnDate(value);
      }
      {
        const { labels, value } = gatherDates(
          selectedContainers,
          (c) => c.containerNumber || '',
          (c) => c.emptyNotifyDate
        );
        setEmptyNotifyLabels(labels);
        setEmptyNotify(value);
        setInitEmptyNotify(value);
      }
      {
        const { labels, value } = gatherDates(
          selectedContainers,
          (c) => c.containerNumber || '',
          (c) => c.emptyPickupDate
        );
        setEmptyPickupLabels(labels);
        setEmptyPickup(value);
        setInitEmptyPickup(value);
      }
      {
        const { labels, value } = gatherDates(
          selectedContainers,
          (c) => c.containerNumber || '',
          (c) => c.demurrageLastFreeDayDate
        );
        setDemurrageLfdLabels(labels);
        setDemurrageLfd(value);
        setInitDemurrageLfd(value);
      }
      {
        const { labels, value } = gatherDates(
          selectedContainers,
          (c) => c.containerNumber || '',
          (c) => c.detentionLastFreeDayDate
        );
        setDetentionLfdLabels(labels);
        setDetentionLfd(value);
        setInitDetentionLfd(value);
      }
      {
        const { labels, value } = gatherDates(
          selectedContainers,
          (c) => c.containerNumber || '',
          (c) => c.consolidatorLastFreeDayDate
        );
        setConsolidatorLfdLabels(labels);
        setConsolidatorLfd(value);
        setInitConsolidatorLfd(value);
      }

      //////////////////////////////////////////////
      // customs

      {
        const { labels, value } = gatherStrings(
          globalUpdateRecordData.globalUpdate.customs,
          (c) => c.customsId,
          (c) => c.customsBrokerRef
        );
        setCustomsBrokerRefLabels(labels);
        setCustomsBrokerRef(value);
        setInitCustomsBrokerRef(value);
      }
      {
        const { labels, value } = gatherStrings(
          globalUpdateRecordData.globalUpdate.customs,
          (c) => c.customsId,
          (c) => c.isfResponseNumber
        );
        setIsfResponseNoLabels(labels);
        setIsfResponseNo(value);
        setInitIsfResponseNo(value);
      }
      {
        const { labels, value } = gatherDates(
          globalUpdateRecordData.globalUpdate.customs,
          (c) => c.customsId,
          (c) => c.isfFilingDate
        );
        setIsfFilingDateLabels(labels);
        setIsfFilingDate(value);
        setInitIsfFilingDate(value);
      }
      {
        const { labels, value } = gatherStrings(
          globalUpdateRecordData.globalUpdate.customs,
          (c) => c.customsId,
          (c) => c.entryNumber
        );
        setEntryNumberLabels(labels);
        setEntryNumber(value);
        setInitEntryNumber(value);
      }
      {
        const { labels, value } = gatherDates(
          globalUpdateRecordData.globalUpdate.customs,
          (c) => c.customsId,
          (c) => c.entryDate
        );
        setEntryDateLabels(labels);
        setEntryDate(value);
        setInitEntryDate(value);
      }
      {
        const { labels, value } = gatherNumbers(
          globalUpdateRecordData.globalUpdate.customs,
          (c) => c.customsId,
          (c) => c.entryValue
        );
        setEntryValueLabels(labels);
        setEntryValue(value);
        setInitEntryValue(value);
      }
      {
        const { labels, value } = gatherNumbers(
          globalUpdateRecordData.globalUpdate.customs,
          (c) => c.customsId,
          (c) => c.mpf
        );
        setMpfLabels(labels);
        setMpf(value);
        setInitMpf(value);
      }
      {
        const { labels, value } = gatherNumbers(
          globalUpdateRecordData.globalUpdate.customs,
          (c) => c.customsId,
          (c) => c.hmf
        );
        setHmfLabels(labels);
        setHmf(value);
        setInitHmf(value);
      }
      {
        const { labels, value } = gatherNumbers(
          globalUpdateRecordData.globalUpdate.customs,
          (c) => c.customsId,
          (c) => c.duty
        );
        setDutyLabels(labels);
        setDuty(value);
        setInitDuty(value);
      }
      {
        const { labels, value } = gatherStrings(
          globalUpdateRecordData.globalUpdate.customs,
          (c) => c.customsId,
          (c) => c.customsExamType
        );
        setCustomsExamTypeLabels(labels);
        setCustomsExamType(value);
        setInitCustomsExamType(value);
      }
      {
        const { labels, value } = gatherDates(
          globalUpdateRecordData.globalUpdate.customs,
          (c) => c.customsId,
          (c) => c.customsExamNotifyDate
        );
        setCustomsExamNotifyDateLabels(labels);
        setCustomsExamNotifyDate(value);
        setInitCustomsExamNotifyDate(value);
      }
      {
        const { labels, value } = gatherNumbers(
          globalUpdateRecordData.globalUpdate.customs,
          (c) => c.customsId,
          (c) => c.customsExamFee
        );
        setCustomsExamFeeLabels(labels);
        setCustomsExamFee(value);
        setInitCustomsExamFee(value);
      }
      {
        const { labels, value } = gatherDates(
          globalUpdateRecordData.globalUpdate.customs,
          (c) => c.customsId,
          (c) => c.customsReleaseDate
        );
        setCustomsReleaseDateLabels(labels);
        setCustomsReleaseDate(value);
        setInitCustomsReleaseDate(value);
      }
      {
        const { labels, value } = gatherStrings(
          globalUpdateRecordData.globalUpdate.customs,
          (c) => c.customsId,
          (c) => c.fdaExamType
        );
        setFdaExamTypeLabels(labels);
        setFdaExamType(value);
        setInitFdaExamType(value);
      }
      {
        const { labels, value } = gatherDates(
          globalUpdateRecordData.globalUpdate.customs,
          (c) => c.customsId,
          (c) => c.fdaExamNotifyDate
        );
        setFdaExamNotifyDateLabels(labels);
        setFdaExamNotifyDate(value);
        setInitFdaExamNotifyDate(value);
      }
      {
        const { labels, value } = gatherNumbers(
          globalUpdateRecordData.globalUpdate.customs,
          (c) => c.customsId,
          (c) => c.fdaExamFee
        );
        setFdaExamFeeLabels(labels);
        setFdaExamFee(value);
        setInitFdaExamFee(value);
      }
      {
        const { labels, value } = gatherDates(
          globalUpdateRecordData.globalUpdate.customs,
          (c) => c.customsId,
          (c) => c.fdaReleaseDate
        );
        setFdaReleaseDateLabels(labels);
        setFdaReleaseDate(value);
        setInitFdaReleaseDate(value);
      }
      {
        const { labels, value } = gatherNumbers(
          globalUpdateRecordData.globalUpdate.customs,
          (c) => c.customsId,
          (c) => c.entryFeeTotal
        );
        setEntryFeeTotalLabels(labels);
        setEntryFeeTotal(value);
        setInitEntryFeeTotal(value);
      }

      setAllRecordData(globalUpdateRecordData.globalUpdate);
    }
  }, [globalUpdateRecordData]);

  return (
    <Box marginBottom={3}>
      <CompositePanel noHeightLimit>
        <GlobalUpdateStep2Header />
        <Box paddingX={2}>
          <GlobalUpdateStep2Confirmation />
          <GlobalUpdateStep2Transportation />
          <GlobalUpdateStep2Customs />
          <GlobalUpdateStep2Records />
        </Box>
      </CompositePanel>
    </Box>
  );
}

/**
 * Since global update allows the user to select multiple rows, this tool gathers the list of Labels
 * and selects the value that should be used as the initial form input value.
 */
function gather<T, V>(
  rows: T[],
  toLabel: (row: T) => string,
  toValue: (row: T) => V,
  defaultValue: V,
  displayValue: (v: V) => string
): {
  labels: Label[];
  value: V;
} {
  const labels: Label[] = [];
  let initialValue: V = defaultValue;
  let uniques = new Set<string>();
  let lastValue: V = defaultValue;
  for (const row of rows) {
    let value = toValue(row);
    lastValue = value;
    uniques.add(displayValue(value));
    labels.push({
      label: toLabel(row),
      value: displayValue(value),
    });
  }
  if (uniques.size === 1) {
    initialValue = lastValue;
  }
  return { labels, value: initialValue };
}

function gatherStrings<T>(
  rows: T[],
  toLabel: (row: T) => string,
  toValue: (row: T) => string | null | undefined
) {
  return gather<T, string>(
    rows,
    toLabel,
    (row) => toValue(row) || '',
    '',
    (v) => v
  );
}

function gatherNumbers<T>(
  rows: T[],
  toLabel: (row: T) => string,
  toValue: (row: T) => number | null | undefined
) {
  return gather<T, number>(
    rows,
    toLabel,
    (row) => toValue(row) || 0,
    0,
    (v) => formatNumber(v)
  );
}

function gatherDates<T>(
  rows: T[],
  toLabel: (row: T) => string,
  toValue: (row: T) => Date | null | undefined
) {
  return gather<T, Date | null>(
    rows,
    toLabel,
    (row) => toValue(row) || null,
    null,
    (v) => formatDate(v)
  );
}
