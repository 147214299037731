import { Grid } from '@material-ui/core';
import PortDisplay from 'components/PortDisplay';
import { PortDisplayDate } from 'components/PortDisplayDate';
import { useRecoilValue } from 'recoil';
import { portToStringMaybe } from 'types/Port';
import { formatDate } from 'utils/Dates';
import { MblPageStates } from '../states';

export default function PortDetails() {
  const mbl = useRecoilValue(MblPageStates.mbl);

  return (
    <Grid container alignContent="center" justify="space-evenly" spacing={1}>
      <Grid item xs={6} md={3}>
        <PortDisplay
          matIcon="anchor"
          label="POL"
          port={portToStringMaybe(mbl?.shipment.logistics.pol)}
        >
          <PortDisplayDate label="ETD" date={mbl?.shipment.logistics.polEtd} />
          <PortDisplayDate label="ATD" date={mbl?.shipment.logistics.polAtd} />
        </PortDisplay>
      </Grid>
      <Grid item xs={6} md={3}>
        <PortDisplay
          matIcon="anchor"
          label="POD"
          port={portToStringMaybe(mbl?.shipment.logistics.pod)}
        >
          <PortDisplayDate label="ETA" date={mbl?.shipment.logistics.podEta} />
          <PortDisplayDate label="ATA" date={mbl?.shipment.logistics.podAta} />
        </PortDisplay>
      </Grid>
      <Grid item xs={6} md={3}>
        <PortDisplay
          matIcon="train"
          label="RAIL RAMP"
          port={portToStringMaybe(mbl?.shipment.logistics.ramp)}
        >
          {mbl?.shipment.logistics.rampAta ? (
            <PortDisplayDate
              label="ATA"
              date={mbl.shipment.logistics.rampAta}
              tooltip={`ETA: ${formatDate(mbl.shipment.logistics.rampEta) || 'N/A'}`}
            />
          ) : (
            <PortDisplayDate label="ETA" date={mbl?.shipment.logistics.rampEta} />
          )}
        </PortDisplay>
      </Grid>
      <Grid item xs={6} md={3}>
        <PortDisplay
          matIcon="room"
          label="DELIVERY LOCATION"
          port={mbl?.shipment.logistics.deliveryLocation?.name ?? ''}
        >
          {mbl?.shipment.logistics.deliveryAta ? (
            <PortDisplayDate
              label="ATA"
              date={mbl.shipment.logistics.deliveryAta}
              tooltip={`ETA: ${formatDate(mbl.shipment.logistics.deliveryEta) || 'N/A'}`}
            />
          ) : (
            <PortDisplayDate label="ETA" date={mbl?.shipment.logistics.deliveryEta} />
          )}
        </PortDisplay>
      </Grid>
    </Grid>
  );
}
