import { useProfileTypeTemplatesQuery } from 'api/GQL_Types';
import { useSetupNewProfileForm } from 'app/admin/components/NewProfileForm/states';
import UniversalDialog from 'components/UniversalDialog';
import React from 'react';
import { useRecoilState, useSetRecoilState } from 'recoil';
import { AtomGroupCleaner } from 'recoil-utils/utils';
import {
  AvailableProfileTemplateType,
  fromGqlProfileTypeTemplatesQuery,
  NewNetworkPageStates,
} from './states';
import DialogStepper from './stepper';

export default function NewNetworkDialog() {
  const [openDialog, setOpenDialog] = useRecoilState(NewNetworkPageStates.newNetworkDialog_Open);
  const [templates, setTemplates] = useRecoilState<AvailableProfileTemplateType[]>(
    NewNetworkPageStates.availableProfileTypeTemplates
  );
  const setSelected = useSetRecoilState<string[]>(NewNetworkPageStates.fields.selectedProfileTypes);

  const setupNewProfileForm = useSetupNewProfileForm();

  React.useEffect(() => {
    setupNewProfileForm();
  }, [openDialog]);

  React.useEffect(() => {
    if (openDialog && templates) {
      const selectedTemplates: string[] = templates.map((template) => template.id);
      setSelected(selectedTemplates);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [openDialog, templates]);

  useProfileTypeTemplatesQuery({
    fetchPolicy: 'no-cache',
    onCompleted(data) {
      const templateList = fromGqlProfileTypeTemplatesQuery(data);
      const selectedTemplates: string[] = templateList.map((template) => template.id);
      setTemplates(templateList);
      setSelected(selectedTemplates);
    },
  });

  return (
    <UniversalDialog
      open={openDialog}
      title="New Network"
      large
      setClose={() => {
        setOpenDialog(false);
      }}
    >
      <AtomGroupCleaner atomGroup={NewNetworkPageStates.fields} />
      <DialogStepper />
    </UniversalDialog>
  );
}
