import * as React from 'react';
import { DatasetFilterType } from '..';
import FormInputSelect from '../../form/FormInputSelect';

export const SelectFilter: DatasetFilterType<string | null> = {
  deserializeValue(filterable, str) {
    if (typeof str === 'string') {
      return str;
    }
    return '';
  },
  serializeValue(filterable, value) {
    return value;
  },

  defaultValue(filterable) {
    return '';
  },

  validate(filterable, value) {
    return null;
  },

  RenderInput(props) {
    const options = props.filterable.options || [];

    return (
      <FormInputSelect
        options={options.map((o) => ({ id: o.value, label: o.label }))}
        value={props.value ? props.value + '' : null}
        onValue={(val) => {
          props.onValue(val);
        }}
        label=""
        nullOptionLabel="ALL"
        disabled={props.disabled}
      />
    );
  },
};
