import { faNetworkWired, faPlug } from '@fortawesome/free-solid-svg-icons';
import { Box, Fab, Typography } from '@material-ui/core';
import { navigate, RouteComponentProps } from '@reach/router';
import { SystemPermissionCode, useAllNetworksQuery } from 'api/GQL_Types';
import { auth } from 'app';
import AdminCardList from 'app/admin/components/AdminCardList';
import { ManagePageHeader_BackStack } from 'app/admin/components/ManagePageHeader';
import SearchBar from 'components/SearchBar';
import React from 'react';
import { selector, useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import { genKey, newAtom } from 'recoil-utils/utils';
import { theme } from 'styles';
import NewNetworkDialog from './new-network-dialog';
import { NewNetworkPageStates } from './new-network-dialog/states';

const networksSearchState = newAtom('');
const networksListState = newAtom<AdminCard[]>([]);
const filteredNetworksListState = selector({
  key: genKey(),
  get: ({ get }) => {
    const searchField = get<string>(networksSearchState);
    const networks = get(networksListState);
    const lowerField = searchField.toLowerCase();

    if (searchField === '') {
      return networks;
    } else {
      return networks.filter(
        (network: any) =>
          network.name.toLowerCase().includes(lowerField) ||
          network.controllingClient.toLowerCase().includes(lowerField)
      );
    }
  },
});

interface AdminCard {
  id: string;
  name: string;
  controllingClient: string | undefined;
  connections: number;
}

interface NetworkListProps extends RouteComponentProps {}
export default function NetworkList(props: NetworkListProps) {
  const { userContext } = auth.useAuthState();
  const setBackStack = useSetRecoilState(ManagePageHeader_BackStack);
  const setNetworks = useSetRecoilState(networksListState);
  const [searchField, setSearchField] = useRecoilState(networksSearchState);
  const filteredNetworks = useRecoilValue(filteredNetworksListState);

  const { data: networkData } = useAllNetworksQuery({
    fetchPolicy: 'cache-and-network',
  });

  React.useEffect(() => {
    if (networkData) {
      let networkList = [];
      const networkTable = networkData.allNetworks;
      for (const network of networkTable) {
        const controllingProfile = network.connectedProfiles.find(
          (client: any) => client.isControllingClient
        );
        networkList.push({
          id: network.id,
          name: network.name,
          controllingClient: controllingProfile?.name,
          connections: network.connectedProfiles.length,
        });
      }
      setNetworks(networkList);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [networkData]);

  return (
    <div>
      <Box margin="16px 24px">
        <Box display="flex" justifyContent="space-between" marginBottom={3}>
          <Box>
            <Typography variant="h2">Networks</Typography>
            <Typography variant="body1">{filteredNetworks.length} Total</Typography>
          </Box>
          <Box
            width="25%"
            bgcolor={theme.palette.common.white}
            padding={1}
            alignSelf="center"
            borderRadius="4px"
          >
            <SearchBar
              placeholder="Search Networks"
              field={searchField}
              updateField={setSearchField}
            />
          </Box>
        </Box>
        <AdminCardList
          list={filteredNetworks}
          iconList={[
            {
              name: 'CTRL Client',
              key: 'controllingClient',
              icon: faNetworkWired,
              color: theme.palette.primary.light,
            },
            {
              name: 'Connections',
              key: 'connections',
              icon: faPlug,
              color: theme.palette.blueGreen.main,
            },
            // {
            //   name: 'Rules Applied',
            //   key: 'rulesApplied',
            //   icon: faTasks,
            //   color: theme.palette.orange.main,
            // },
          ]}
          onManageClicked={(item) => {
            setBackStack([{ label: 'Network List', path: '/admin/networks' }]);
            navigate('admin/networks/' + item.id);
          }}
        />
      </Box>

      {userContext?.systemPermissionCodes.has(SystemPermissionCode.NetworkCreate) && (
        <Box position="absolute" bottom="48px" right="36px">
          <NewNetworkButton />
        </Box>
      )}

      <NewNetworkDialog />
    </div>
  );
}

function NewNetworkButton() {
  const setOpen = useSetRecoilState(NewNetworkPageStates.newNetworkDialog_Open);

  return (
    <Fab
      color="primary"
      aria-label="add"
      variant="extended"
      onClick={() => {
        setOpen(true);
      }}
    >
      <i className="material-icons" style={{ marginRight: '8px' }}>
        {'add'}
      </i>
      Create New Network
    </Fab>
  );
}
