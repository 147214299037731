import { faFire } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Box, InputAdornment, TextField, Typography } from '@material-ui/core';
import { Link } from '@reach/router';
import { GqlContainer } from 'api/GQL_Types';
import { GTableColumn } from 'components/g-table/types';
import UWLTableV2 from 'components/g-table/UWLTableV2';
import Panel from 'components/Panel';
import { useRecoilValue } from 'recoil';
import { HblPageStates } from '../states';

const columns: GTableColumn<GqlContainer>[] = [
  { id: 'isHot', label: '', type: 'string', weight: 1 },
  { id: 'containerNumber', label: 'Container', type: 'string', weight: 1 },
  { id: 'containerType', label: 'Container Type', type: 'string', weight: 1 },
  { id: 'sealNumber', label: 'Seal', type: 'string', weight: 1 },
  { id: 'volume', label: 'Volume', type: 'number', weight: 1 },
  { id: 'weight', label: 'Weight', type: 'number', weight: 1 },
  { id: 'shipFromFactoryDate', label: 'Ship From Factory', type: 'date', weight: 1 },
  { id: 'terminalReceivedDate', label: "Terminal Rec'd", type: 'date', weight: 1 },
];

const columnsAlt: GTableColumn<GqlContainer>[] = [
  { id: 'containerNumber', label: 'Container', type: 'string', weight: 1 },
  { id: 'containerType', label: 'Container Type', type: 'string', weight: 1 },
  { id: 'sealNumber', label: 'Seal', type: 'string', weight: 1 },
  { id: 'volume', label: 'Volume', type: 'number', weight: 1 },
  { id: 'weight', label: 'Weight', type: 'number', weight: 1 },
  { id: 'shipFromFactoryDate', label: 'Ship From Factory', type: 'date', weight: 1 },
  { id: 'terminalReceivedDate', label: "Terminal Rec'd", type: 'date', weight: 1 },
];

export default function HBLEquipment() {
  const hbl = useRecoilValue(HblPageStates.hbl);

  return (
    <Panel title="Equipment">
      <Box maxHeight={300} overflow="auto" margin={2}>
        <UWLTableV2
          columns={hasHotContainers(hbl?.booking.containers ?? []) ? columns : columnsAlt}
          rows={hbl?.booking.containers ?? []}
          emptyMessage="No Containers Available"
          maxHeight={300}
          renderCell={{
            isHot(row) {
              return row.isHot ? (
                <FontAwesomeIcon icon={faFire} style={{ color: 'orange' }} size="lg" />
              ) : null;
            },
            containerNumber(row) {
              return (
                <>
                  {row.containerNumber ? (
                    <Link to={'/equipment/' + row.id}>{row.containerNumber}</Link>
                  ) : (
                    <Typography>Not Assigned</Typography>
                  )}
                </>
              );
            },
            containerType(row) {
              return <Typography variant="body1">{row.containerType}</Typography>;
            },
            weight(row) {
              let totalWeight = 0;
              totalWeight += row.weight ? row.weight : 0;
              return (
                <TextField
                  id={row.id + 'weight'}
                  value={totalWeight.toFixed(2)}
                  disabled
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <Typography
                          variant="caption"
                          style={{ lineHeight: '1.5', marginRight: '16px' }}
                        >
                          KG
                        </Typography>
                      </InputAdornment>
                    ),
                    disableUnderline: true,
                    style: {
                      color: '#333',
                      maxWidth: '100px',
                    },
                  }}
                  inputProps={{
                    style: {
                      textAlign: 'end',
                      marginRight: '-16px',
                    },
                  }}
                  type="number"
                />
              );
            },
            volume(row) {
              let totalVolume = 0;
              totalVolume += row.volume ? row.volume : 0;
              return (
                <TextField
                  id={row.id + 'volume'}
                  value={totalVolume.toFixed(2)}
                  disabled
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <Typography
                          variant="caption"
                          style={{ lineHeight: '1.5', marginRight: '16px' }}
                        >
                          M3
                        </Typography>
                      </InputAdornment>
                    ),
                    disableUnderline: true,
                    style: {
                      color: '#333',
                      maxWidth: '100px',
                    },
                  }}
                  inputProps={{
                    style: {
                      textAlign: 'end',
                      marginRight: '-16px',
                    },
                  }}
                  type="number"
                />
              );
            },
          }}
        />
      </Box>
    </Panel>
  );
}

function hasHotContainers(containers: GqlContainer[]) {
  return Boolean(containers.find((container) => container.isHot));
}
