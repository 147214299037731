import { Box, Button, Typography } from '@material-ui/core';
import { Link } from '@reach/router';
import { downloadDocuments } from 'api/download';
import { PermissionCode, useMarkDocumentsAsViewedMutation } from 'api/GQL_Types';
import { auth } from 'app';
import Panel from 'components/Panel';
import TableExportWindowGroup, { uwlColToGTable } from 'components/TableExportWindowGroup';
import { UploadDocumentDialog, UploadDocumentDialog_Data } from 'components/UploadDocumentDialog';
import { UWLTable } from 'components/UWLTable/UWLTable';
import React from 'react';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { theme } from 'styles';
import { UWLTableColumn } from 'types/UWLTable';
import { DocumentBrowserRow, DocumentBrowserStates } from './states';

const columns: UWLTableColumn<DocumentBrowserRow>[] = [
  { id: 'bookingNumber', label: 'Booking #', type: 'string' },
  { id: 'documentType', label: 'Doc Type', type: 'string' },
  { id: 'uploadDate', label: 'Upload Date', type: 'date' },
  { id: 'shipperName', label: 'Shipper Name', type: 'string' },
  { id: 'hblNumber', label: 'HBL #', type: 'string' },
  { id: 'pol', label: 'POL', type: 'string' },
  { id: 'polEtd', label: 'POL ETD', type: 'date' },
  { id: 'polAtd', label: 'POL ATD', type: 'date' },
  { id: 'pod', label: 'POD', type: 'string' },
  { id: 'podEta', label: 'POD ETA', type: 'date' },
  { id: 'deliveryLocation', label: 'Place of Delivery', type: 'string' },
];

export default function DocumentList() {
  const { userContext } = auth.useAuthState();
  const documents = useRecoilValue(DocumentBrowserStates.documents);
  const loading = useRecoilValue(DocumentBrowserStates.loading);
  const error = useRecoilValue(DocumentBrowserStates.error);
  const [filesSelected, setFilesSelected] = React.useState<string[]>([]);
  const setUploadDialog = useSetRecoilState(UploadDocumentDialog_Data);

  const [markViewed] = useMarkDocumentsAsViewedMutation();

  return (
    <Box height="100%" display="flex">
      <Panel
        title="Documents"
        titleDecorator={
          <TableExportWindowGroup
            label="Documents"
            rows={documents}
            columns={columns.map(uwlColToGTable)}
          />
        }
        growPanel
        topRight={
          <div>
            {filesSelected.length > 0 && (
              <Button
                variant="text"
                color="primary"
                style={{ marginRight: theme.spacing(1) }}
                onClick={() => {
                  const files = documents.filter((doc) => filesSelected.includes(doc.id));
                  markViewed({
                    variables: {
                      input: {
                        documentIds: files.map((doc) => doc.id),
                      },
                    },
                  });
                  setFilesSelected([]);
                }}
              >
                Mark as Viewed ({filesSelected.length})
              </Button>
            )}
            {userContext?.permissionCodes.has(PermissionCode.DocumentUpload) && (
              <Button
                variant="contained"
                color="secondary"
                style={{ marginRight: theme.spacing(1) }}
                onClick={() => {
                  setUploadDialog({});
                }}
              >
                Upload
              </Button>
            )}
            <Button
              variant="contained"
              color="primary"
              disabled={loading || filesSelected.length === 0}
              onClick={() => {
                const files = documents.filter((doc) => filesSelected.includes(doc.id));
                downloadDocuments(files.map((doc) => doc.id));
                setFilesSelected([]);
              }}
            >
              Download ({filesSelected.length})
            </Button>
          </div>
        }
      >
        <Box height="100%" paddingX={2} paddingBottom={2}>
          <UWLTable
            rowId="id"
            columns={columns}
            isLoading={loading}
            checkboxes={{
              checked: filesSelected,
              setChecked: setFilesSelected,
            }}
            error={error}
            rows={documents}
            emptyMessage="No Documents"
            renderCell={{
              bookingNumber(row) {
                if (!row.bookingId || !row.bookingNumber) {
                  return '';
                }
                return (
                  <Typography variant="body1">
                    <Link to={'/bookings/' + row.bookingId}>{row.bookingNumber}</Link>
                  </Typography>
                );
              },
              hblNumber(row) {
                if (!row.hblId || !row.hblNumber) {
                  return '';
                }
                return (
                  <Typography variant="body1">
                    <Link to={'/hbl/' + row.hblId}>{row.hblNumber}</Link>
                  </Typography>
                );
              },
            }}
          />
        </Box>
      </Panel>
      <UploadDocumentDialog />
    </Box>
  );
}
