import { Button, Typography, Box } from '@material-ui/core';
import React from 'react';

interface Props {
  children?: any;
  color: string;
  label: string;
  value: number;
  matIcon: string;
  onClick: Function;
}

export default function POButton(props: Props) {
  return (
    <Button
      variant="contained"
      color="default"
      size="medium"
      onClick={(e: any) => {
        props.onClick(e);
      }}
      style={{
        height: '54px',
        width: '32%',
        backgroundColor: props.color,
      }}
    >
      <Box width={1} display="flex" justifyContent="space-between" alignItems="center">
        <Box color="#fff" textAlign="left">
          <Typography variant="h1" color="inherit" align="left">
            {props.value}
          </Typography>
          <Typography
            variant="h3"
            color="inherit"
            style={{ textTransform: 'capitalize', lineHeight: '1' }}
          >
            {props.label}
          </Typography>
        </Box>
        <Box
          height={42}
          width={42}
          borderRadius="50%"
          color="#fff"
          display="flex"
          justifyContent="center"
          alignItems="center"
        >
          <span
            style={{ fontSize: '32px', color: '#fff', opacity: 0.6 }}
            className="material-icons"
          >
            {props.matIcon}
          </span>
        </Box>
        {/* <Box bgcolor="#fff" height={42} width={42} borderRadius="50%" color={props.color}>
          <span
            style={{ fontSize: '35px', padding: '6px',  }}
            className="material-icons"
          >
            {props.matIcon}
          </span>
        </Box> */}
      </Box>
    </Button>
  );
}
