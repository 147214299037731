import { gql } from '@apollo/client/core';

export const PoImportFileHeadersFragment = gql`
  fragment PoImportFileHeaders on PoImportHeader {
    id
    name
    headerIndex
    mapping {
      key
      name
      group {
        name
      }
    }
  }
`;

export const PoImportFileStatusFragment = gql`
  fragment PoImportFileStatus on PoImportFile {
    totalPos
    totalLines
    updatedPos
    updatedLines
    poErrors
    lineErrors
    newPos
    newLines
    fileStatus
    headers {
      ...PoImportFileHeaders
    }
    errors {
      rowIndex
      headerIndex
      error
    }
  }
  ${PoImportFileHeadersFragment}
`;

export const PoImportFileQuery = gql`
  query PoImportFile($fileId: ID!) {
    poImportFile(poImportFileId: $fileId) {
      id
      network {
        id
      }
      rows
      ...PoImportFileStatus
    }
  }
  ${PoImportFileStatusFragment}
`;

export const PoImportFieldGroups = gql`
  query PoImportFieldGroups {
    poImportFieldGroups {
      key
      name
      fields {
        key
        name
      }
    }
  }
`;

export const PoImportFilesQuery = gql`
  query PoImportFiles {
    poImportFiles {
      id
      fileName
      importFileName
      extension
      totalPos
      fileStatus
      uploadDate
      network {
        name
        controllingClient {
          name
        }
      }
      uploadedBy {
        firstName
        lastName
      }
    }
  }
`;

export const SetHeaderMutation = gql`
  mutation SetHeader($headerId: ID!, $omsKey: String) {
    setHeaderMapping(headerId: $headerId, omsKey: $omsKey) {
      ...PoImportFileStatus
    }
  }
  ${PoImportFileStatusFragment}
`;

export const ConfirmImportMutation = gql`
  mutation ConfirmImport($fileId: ID!) {
    confirmImport(fileId: $fileId) {
      success
      message
    }
  }
`;

export const CancelImportMutation = gql`
  mutation CancelImport($fileId: ID!) {
    cancelImport(fileId: $fileId) {
      success
      message
    }
  }
`;

export const UploadPoFileMutation = gql`
  mutation UploadPoFileMutation($file: Upload!, $networkId: ID!) {
    uploadPoFile(file: $file, networkId: $networkId) {
      id
    }
  }
`;
