import { InputAdornment, TextField, Typography } from '@material-ui/core';
import clsx from 'clsx';
import React from 'react';
import { makeCss } from 'styles';

const classes = makeCss({
  root: {
    margin: 0,
  },
  error: {
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: '#dd090a',
      },
    },
    '& .MuiOutlinedInput-root:hover': {
      '& fieldset': {
        borderColor: '#dd090a',
      },
    },
    '& .MuiFormLabel-root': {
      color: '#dd090a',
    },
    '& .MuiIconButton-edgeEnd': {
      color: '#dd090a',
    },
  },
});

export interface Props {
  value: string;
  onValue(value: string): void;

  // Label of text field
  label?: string;
  // If flag provided the text field is disabled
  disabled?: boolean;
  // HTML type of text field Ex. type="password"
  type?: string; // NOTE don't use type="number" or "date". Use the dedicated component instead
  required?: boolean;
  error?: boolean; // When true, indicate error status i.e. red border
  helperText?: string | null;
  multiline?: boolean;
  rows?: number;
  // Text field placeholder
  placeholder?: string;

  // Adornments at the start or end of the input
  startAdornment?: string | React.ReactNode;
  endAdornment?: string | React.ReactNode;

  inputRef?: React.Ref<any>;
  onFocus?: React.FocusEventHandler<HTMLTextAreaElement | HTMLInputElement>;
  onBlur?: React.FocusEventHandler<HTMLTextAreaElement | HTMLInputElement>;

  onKeyDown?: React.KeyboardEventHandler<HTMLDivElement>;
  onClick?: React.MouseEventHandler<HTMLInputElement>;

  className?: string;

  onMouseOver?: React.MouseEventHandler<HTMLDivElement>;
  onMouseLeave?: React.MouseEventHandler<HTMLDivElement>;

  autoComplete?: 'on' | 'off';

  size?: 'xs';

  /**
   * It prevents the user from changing the value of the field
   * (not from interacting with the field).
   */
  readOnly?: boolean;

  notFullWidth?: boolean;
}

export const FormInputText: React.FC<Props> = (props) => {
  const hasError = !!props.error || !!(props.required && !props.value);

  return (
    <TextField
      multiline={props.multiline}
      rows={props.rows}
      placeholder={props.placeholder}
      type={props.type}
      label={props.label}
      variant="outlined"
      margin="dense"
      value={props.value}
      required={props.required}
      error={hasError}
      className={clsx(classes.root, props.className, hasError && classes.error)}
      onChange={(e) => {
        props.onValue(e.currentTarget.value);
      }}
      onClick={props.onClick}
      onKeyDown={props.onKeyDown}
      helperText={props.helperText}
      inputRef={props.inputRef}
      onFocus={props.onFocus}
      onBlur={props.onBlur}
      onMouseOver={props.onMouseOver}
      onMouseLeave={props.onMouseLeave}
      InputProps={{
        style: { color: props.disabled ? undefined : 'black' },
        inputProps: {
          style: props.size === 'xs' ? { paddingTop: 4, paddingBottom: 4 } : undefined,
          readOnly: props.readOnly,
        },
        startAdornment: renderAdornment('start', props.startAdornment),
        endAdornment: renderAdornment('end', props.endAdornment),
      }}
      disabled={props.disabled}
      autoComplete={props.autoComplete}
      fullWidth={!props.notFullWidth}
      InputLabelProps={{
        shrink: true, // See #OMS-54
      }}
    />
  );
};

function renderAdornment(position: 'start' | 'end', adornment: any): React.ReactNode | undefined {
  if (adornment === undefined || adornment === null || adornment === '') {
    return undefined;
  }
  if (typeof adornment === 'string') {
    return (
      <InputAdornment position={position}>
        {typeof adornment === 'string' ? (
          <Typography variant="caption" style={{ lineHeight: '1.5' }}>
            {adornment}
          </Typography>
        ) : (
          adornment
        )}
      </InputAdornment>
    );
  }
  return adornment;
}
