import * as React from 'react';
import { isNumber } from 'utils/Numbers';
import { FormInputText } from './FormInputText';

export function parseHHMMaa(src: string): { hour: number; minute: number } | null {
  let isPM = /p/i.test(src);
  src = src.replace(/[^0-9:]/g, '');

  const parts = src.split(':');
  if (parts.length === 0) {
    return null;
  }

  let hour = parseInt(parts[0], 10);
  if (isNumber(hour)) {
    return null; // NaN
  }

  if (hour === 12 && !isPM) {
    hour = 0;
  }

  hour = hour % 24;
  if (0 < hour && hour < 12 && isPM) {
    hour += 12;
    hour = hour % 24;
  }

  let minute = 0;
  if (parts.length > 1) {
    minute = parseInt(parts[1], 10) || 0;
    minute = minute % 60;
  }
  return { hour, minute };
}

function fmt(value: Value | null): string {
  if (!value) return '';

  let { hour, minute } = value;
  let isPM = hour >= 12;
  hour = hour % 12;
  if (hour === 0) {
    hour = 12;
  }

  return `${hour}:${minute.toString().padStart(2, '0')} ${isPM ? 'PM' : 'AM'}`;
}

interface Value {
  hour: number;
  minute: number;
}

interface Props {
  value: Value | null;
  onValue(v: Value | null): void;

  label: string;
  required?: boolean;
  disabled?: boolean;
  error?: string | null;
}

export default function FormInputTime({ value, onValue, label, required, disabled, error }: Props) {
  const fmtText = fmt(value);
  const [text, setText] = React.useState(fmtText);
  React.useEffect(() => {
    if (text !== fmtText) {
      setText(fmtText);
    }
  }, [fmtText]);

  return (
    <FormInputText
      value={text}
      onValue={setText}
      onBlur={(e) => {
        const src = e.currentTarget.value;
        const time = parseHHMMaa(src);
        setText(fmt(time));
        onValue(time);
      }}
      label={label}
      required={required}
      disabled={disabled}
      error={!!error}
      helperText={error}
    />
  );
}
