import {
  Currency,
  DimsUnit,
  GqlPortFragment,
  GqlPurchaseOrderQuery,
  HistoryRecordType,
  OrderStatus,
  PartyType,
  VolumeUnit,
  WeightUnit,
} from 'api/GQL_Types';
import { HotState } from 'components/HotToggleSwitch';
import { OverrideRelatedPartyValue } from 'components/override-related-parties-dialog/OverrideRelatedPartiesDialog2';
import { formatDate } from 'utils/Dates';

export interface PoPageData {
  hot: HotState;

  id: string;
  poNumber: string;

  details: PoPageDataDetails;

  historyEntries: PoPageDataHistoryEntry[];

  orderLineRows: PoPageDataLineRow[];

  relatedParties: OverrideRelatedPartyValue[];
}

export interface PoPageDataDetails {
  poNumber: string;
  poDate: Date | null;
  poStatus: OrderStatus;
  customerPoNumber: string;
  altPoNumber: string;
  poType: string;

  relatedPartyByType: Map<PartyType, { id: string; name: string; code: string }>;

  expectedCargoReadyDate: string;
  revisedCargoReadyDate: string;
  firstShipDate: string;
  lastShipDate: string;
  bookByDate: string;
  customerStartShipDate: string;
  customerCancelDate: string;

  pol: GqlPortFragment | null;
  pod: GqlPortFragment | null;
  countryOfOrigin: string;

  incoTerm: string;
  htsCode: string;
  indcDate: string;

  shipToLocationName: string;
  shipToLocationCode: string;
}

export interface PoPageDataLineRow {
  lineId: string;
  lineNumber: string;
  itemNumber: string;
  lineStatus: OrderStatus;
  description: string;
  bookByDate: Date | null | undefined;

  totalQty: number;
  innerQty: number;
  outerQty: number;

  approvedQty: number;
  balanceQty: number;
  shippedQty: number;

  expectedCargoReadyDate: Date | null | undefined;
  color: string;
  hot: HotState;
  htsCode: string;
  indcDate: Date | null | undefined;
  material: string;
  categoryCode: string;
  revisedCargoReadyDate: Date | null | undefined;
  firstShipDate: Date | null | undefined;
  lastShipDate: Date | null | undefined;
  shipToLocationName: string;
  shipToLocationCode: string;
  size: string;
  style: string;

  dutyPrice: number;
  dutyCurrencyCode: Currency;

  unitPrice: number;
  unitCurrencyCode: Currency;

  weight: { weight: number; unit: WeightUnit } | null;
  volume: { volume: number; unit: VolumeUnit } | null;
  width: { value: number; unit: DimsUnit } | null;
  length: { value: number; unit: DimsUnit } | null;
  height: { value: number; unit: DimsUnit } | null;
}

export interface PoPageDataHistoryEntry {
  id: string;
  timestamp: Date | null;
  recordType: HistoryRecordType;
  message: string;
  byUserName: string | null;
  byPoUploadFileName: string | null;
}

export function fromGqlPurchaseOrderQuery(res: GqlPurchaseOrderQuery): PoPageData | null {
  const po = res.purchaseOrder;

  if (!po) {
    return null;
  }

  let isPoHot = false;

  for (const line of po.orderLines) {
    isPoHot = isPoHot || line.openOrder.isHot;
  }

  const relatedPartyByType = new Map<PartyType, { id: string; name: string; code: string }>();

  for (const rp of po.relatedParties) {
    relatedPartyByType.set(rp.partyType, {
      id: rp.party.id,
      name: rp.party.name,
      code: rp.party.profileCode || '',
    });
  }

  const details: PoPageDataDetails = {
    poNumber: po.poNumber,
    poDate: po.poDate || null,
    poStatus: po.poStatus,
    customerPoNumber: po.customerPoNumber || '',
    altPoNumber: po.altPoNumber || '',
    poType: po.poType || '',

    relatedPartyByType,

    // TODO FIXME HACK do we really want to bubble these all up from the lines?
    // - the user may want to see what the PO header says irrespective of the lines
    // - or, the backend should be doing this to make sure the logic is correct
    expectedCargoReadyDate: oneOrVarious(
      po.orderLines.map((line) => formatDate(line.expectedCargoReadyDate))
    ),
    revisedCargoReadyDate: oneOrVarious(
      po.orderLines.map((line) => formatDate(line.revisedCargoReadyDate))
    ),
    firstShipDate: oneOrVarious(po.orderLines.map((line) => formatDate(line.firstShipDate))),
    lastShipDate: oneOrVarious(po.orderLines.map((line) => formatDate(line.lastShipDate))),
    bookByDate: oneOrVarious(po.orderLines.map((line) => formatDate(line.bookByDate))),
    customerStartShipDate: formatDate(po.customerStartShipDate),
    customerCancelDate: formatDate(po.customerCancelDate),
    indcDate: oneOrVarious(po.orderLines.map((line) => formatDate(line.indcDate))),

    pol: po.pol || null,
    pod: po.pod || null,
    countryOfOrigin: po.originCountry || '',

    incoTerm: po.incoTerm || '',
    htsCode: po.htsCode || '',

    shipToLocationName: oneOrVarious(
      po.orderLines
        .map((line) => (line.shipToLocation?.name || '').trim())
        .filter((name) => name.length > 0)
    ),
    shipToLocationCode: oneOrVarious(
      po.orderLines
        .map((line) => (line.shipToLocation?.code || '').trim())
        .filter((code) => code.length > 0)
    ),
  };

  return {
    hot: {
      isHot: po.isHot,
      hotMarkedBy: po.hotMarkedBy,
      hotMarkedTimestamp: po.hotMarkedTimestamp,
    },

    id: po.id,
    poNumber: po.poNumber,

    details,

    historyEntries: po.poHistory.map((entry) => {
      return {
        id: entry.id,
        timestamp: entry.timestamp,
        recordType: entry.recordType,
        message: entry.message,
        byUserName: entry.byUserName || null,
        byPoUploadFileName: entry.byPoUploadFileName || null,
      };
    }),

    orderLineRows: po.orderLines.map((line): PoPageDataLineRow => {
      return {
        lineId: line.id,
        lineNumber: line.lineNumber || '',
        itemNumber: line.itemNumber || '',
        lineStatus: line.lineStatus,
        description: line.itemDescription || '',
        bookByDate: line.bookByDate,

        approvedQty: line.openOrder.approvedQty,
        balanceQty: line.openOrder.balanceQty,
        shippedQty: line.openOrder.shippedQty,

        totalQty: line.totalQty || 0,
        outerQty: line.outerQty || 0,
        innerQty: line.innerQty || 0,

        expectedCargoReadyDate: line.expectedCargoReadyDate,
        revisedCargoReadyDate: line.revisedCargoReadyDate,
        firstShipDate: line.firstShipDate,
        lastShipDate: line.lastShipDate,

        color: line.itemColor || '',

        hot: {
          isHot: !!line.openOrder?.isHot,
          hotMarkedBy: line.openOrder?.hotMarkedBy,
          hotMarkedTimestamp: line.openOrder?.hotMarkedTimestamp,
        },

        htsCode: line.itemHtsCode || '',

        indcDate: line.indcDate,
        material: line.itemMaterial,
        categoryCode: line.itemCategoryCode,

        shipToLocationName: line.shipToLocation?.name || '',
        shipToLocationCode: line.shipToLocation?.code || '',

        size: line.itemSize || '',
        style: line.itemStyle || '',

        dutyPrice: line.itemDuty || 0,
        dutyCurrencyCode: line.itemDutyCurrencyCode,

        unitPrice: line.unitPrice || 0,
        unitCurrencyCode: line.unitPriceCurrencyCode,

        weight:
          typeof line.weight === 'number'
            ? {
                weight: line.weight,
                unit: line.weightUnit,
              }
            : null,

        volume:
          typeof line.volume === 'number'
            ? {
                volume: line.volume,
                unit: line.volumeUnit,
              }
            : null,

        width:
          typeof line.itemWidth === 'number'
            ? {
                value: line.itemWidth,
                unit: line.itemDimsUnit,
              }
            : null,
        length:
          typeof line.itemLength === 'number'
            ? {
                value: line.itemLength,
                unit: line.itemDimsUnit,
              }
            : null,
        height:
          typeof line.itemHeight === 'number'
            ? {
                value: line.itemHeight,
                unit: line.itemDimsUnit,
              }
            : null,
      };
    }),

    relatedParties: po.relatedParties.map((rp) => {
      return {
        id: rp.id,
        profileId: rp.party.id,
        partyType: rp.partyType,
      };
    }),
  };
}

export function oneOrVarious(values: string[]): string {
  const set = new Set<string>();
  for (const value of values) {
    if (typeof value === 'string' && value.trim().length > 0) {
      set.add(value);
    }
  }
  if (set.size === 0) {
    return '';
  }
  if (set.size === 1) {
    return set.values().next().value;
  }
  return 'Various';
}
