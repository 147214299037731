import { Box, Button, Grid, Typography } from '@material-ui/core';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import { navigate } from '@reach/router';
import {
  GqlContainerInput,
  GqlNewBookingInput,
  PartyType,
  useSaveBookingMutation,
} from 'api/GQL_Types';
import { OpenOrdersQuery } from 'api/queries/openOrdersQueries';
import { userContextAtom } from 'app';
import UniversalDialog from 'components/UniversalDialog';
import { useSnackbar } from 'notistack';
import React from 'react';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { AtomGroupCleaner, useResetAtomGroupState } from 'recoil-utils/utils';
import { NewBookingPageStates } from '../states';

export default function CompleteBookingButton() {
  const setActiveStep = useSetRecoilState(NewBookingPageStates.activeStep);
  const newBooking = useRecoilValue(NewBookingPageStates.newBooking);
  const resetState = useResetAtomGroupState(NewBookingPageStates);

  return (
    <>
      <Button
        onClick={() => {
          setActiveStep(0);
        }}
        style={{
          marginRight: '24px',
          backgroundColor: 'rgba(0, 0, 0, 0.12)',
          color: '#043c5f',
          padding: '8px 32px',
        }}
      >
        <Typography variant="h3" color="inherit" style={{ width: '112px' }}>
          Back
        </Typography>
      </Button>

      <SubmitButton />

      {newBooking && (
        <UniversalDialog
          open
          small
          setClose={() => {
            resetState();
          }}
        >
          {/* This should clean the form when the dialog closes */}
          <AtomGroupCleaner atomGroup={NewBookingPageStates} />
          <Box padding={3} textAlign="center">
            <Box display="flex" justifyContent="center">
              <CheckCircleIcon style={{ fontSize: 90, color: '#009600' }} />
            </Box>
            <Typography variant="h1" style={{ paddingBottom: '16px' }}>
              Booking Created
            </Typography>
            <Box display="flex" justifyContent="center">
              <Typography variant="h4" style={{ paddingRight: '4px' }}>
                Booking # {newBooking.referenceNumber}
              </Typography>
              <Typography variant="body1" style={{ lineHeight: '1.2' }}>
                {' '}
                was successfully created and ready to view.
              </Typography>
            </Box>
            <Grid
              container
              spacing={3}
              direction="row"
              justify="center"
              alignItems="center"
              style={{ marginTop: '24px' }}
            >
              <Grid item xs={4}>
                <Button
                  variant="contained"
                  color="secondary"
                  fullWidth
                  onClick={() => {
                    setActiveStep(0);
                    resetState();
                  }}
                >
                  New Booking
                </Button>
              </Grid>
              <Grid item xs={4}>
                <Button
                  variant="contained"
                  color="primary"
                  fullWidth
                  onClick={() => {
                    resetState();
                    navigate('/bookings/' + newBooking.bookingId);
                  }}
                >
                  View Booking
                </Button>
              </Grid>
            </Grid>
          </Box>
        </UniversalDialog>
      )}
    </>
  );
}

function SubmitButton() {
  const userContext = useRecoilValue(userContextAtom);
  const cargoReadyDate = useRecoilValue(NewBookingPageStates.step2.formCargoReadyDate);
  const incoTerm = useRecoilValue(NewBookingPageStates.step2.formIncoTerm);
  const moveType = useRecoilValue(NewBookingPageStates.step2.formMoveType);
  const containerMode = useRecoilValue(NewBookingPageStates.step2.formContainerMode);
  const transportationMode = useRecoilValue(NewBookingPageStates.step2.formTransportationMode);
  const remark = useRecoilValue(NewBookingPageStates.step2.bookingRemark);
  const contractType = useRecoilValue(NewBookingPageStates.step2.formContractType);
  const contract = useRecoilValue(NewBookingPageStates.step2.formContract);
  const carrier = useRecoilValue(NewBookingPageStates.step2.formCarrier);
  const pickupLocation = useRecoilValue(NewBookingPageStates.step2.formPickupLocation);
  const deliveryLocation = useRecoilValue(NewBookingPageStates.step2.formDeliveryLocation);
  const etd = useRecoilValue(NewBookingPageStates.step2.formEtd);
  const eta = useRecoilValue(NewBookingPageStates.step2.formEta);
  const pod = useRecoilValue(NewBookingPageStates.step2.formPod);
  const pol = useRecoilValue(NewBookingPageStates.step2.formPol);
  const equipmentList = useRecoilValue(NewBookingPageStates.step2.equipmentList);
  const packLines = useRecoilValue(NewBookingPageStates.step2.packLines);
  const consignee = useRecoilValue(NewBookingPageStates.step2.formConsignee);
  const supplier = useRecoilValue(NewBookingPageStates.step2.formSupplier);
  const manufacturer = useRecoilValue(NewBookingPageStates.step2.formManufacturer);
  const { enqueueSnackbar } = useSnackbar();

  const setNewBooking = useSetRecoilState(NewBookingPageStates.newBooking);

  const [saveBooking, { loading }] = useSaveBookingMutation({
    refetchQueries: () => [
      {
        query: OpenOrdersQuery,
        variables: { profileId: userContext?.activeContact?.profile.id },
      },
    ],
    onCompleted(data) {
      const newBooking = data.newBooking?.booking;
      if (data.newBooking.success && newBooking) {
        setNewBooking({
          bookingId: newBooking.id,
          referenceNumber: newBooking.referenceNumber,
        });
      } else {
        enqueueSnackbar(
          'Failed to create booking: ' + (data.newBooking.message || 'Unexpected Error'),
          {
            variant: 'error',
            anchorOrigin: {
              vertical: 'top',
              horizontal: 'right',
            },
          }
        );
      }
    },
    onError(error) {
      enqueueSnackbar('Failed to create booking: ' + error, {
        variant: 'error',
        anchorOrigin: {
          vertical: 'top',
          horizontal: 'right',
        },
      });
    },
  });

  return (
    <Button
      disabled={cargoReadyDate === null || loading}
      variant="contained"
      color="primary"
      onClick={() => {
        if (cargoReadyDate === null) {
          return;
        }

        const containers: GqlContainerInput[] = [];
        for (const item of equipmentList) {
          for (let i = 0; i < item.qty; i++) {
            containers.push({ containerType: item.containerType });
          }
        }

        const input: GqlNewBookingInput = {
          booking: {
            incoTerm: incoTerm,
            cargoReadyDate: cargoReadyDate,
          },
          logistics: {
            moveType: moveType,
            transportationMode: transportationMode,
            containerMode: containerMode,
            contractType: contractType,
            contractNumber: contract,
            carrierId: carrier ? carrier.id : undefined,
            pickupLocationId: pickupLocation?.id,
            deliveryLocationId: deliveryLocation?.id,
            finalDestinationId: deliveryLocation?.id,
            podCode: pod?.code,
            polCode: pol?.code,
            polEtd: etd,
            podEta: eta,
          },
          packLines: packLines.map((pl) => {
            return {
              requestedDeliveryDate: pl.requestedDeliveryDate,
              shippedQty: pl.shippedQty,
              shippedCartons: pl.shippedCartons,
              volume: pl.volume,
              volumeUnit: pl.volumeUnit,
              weight: pl.weight,
              weightUnit: pl.weightUnit,
              orderLineId: pl.id,
            };
          }),
          containers: containers,
          remark: remark
            ? {
                text: remark,
              }
            : undefined,
          relatedParties: [
            { partyType: PartyType.Consignee, partyId: consignee?.party.id },
            { partyType: PartyType.Supplier, partyId: supplier?.party.id },
            { partyType: PartyType.Manufacturer, partyId: manufacturer?.party.id },
            { partyType: PartyType.Shipper, partyId: manufacturer?.party.id },
          ],
        };
        saveBooking({
          variables: {
            input: input,
          },
        });
      }}
      style={{ padding: '8px 32px' }}
    >
      <Typography variant="h3" color="inherit" style={{ width: '100px' }}>
        Finish
      </Typography>
    </Button>
  );
}
