import Typography from '@material-ui/core/Typography';
import { RouteComponentProps } from '@reach/router';
import { SearchType, useQuickSearchLazyQuery } from 'api/GQL_Types';
import { HeaderStates } from 'app/layout/header/states';
import Loading from 'app/Loading';
import ErrorMessage from 'components/ErrorMessage';
import React from 'react';
import { useRecoilState, useResetRecoilState, useSetRecoilState } from 'recoil';
import { theme } from 'styles';
import { mapSearchType } from 'utils/Enums';
import SearchResultBookings from './SearchResultBookings';
import SearchResultContainers from './SearchResultContainers';
import SearchResultPurchaseOrders from './SearchResultPurchaseOrders';
import SearchResultShipments from './SearchResultShipments';
import {
  QuickSearchBookingResult,
  QuickSearchContainerResult,
  QuickSearchPoResult,
  QuickSearchShipmentResult,
  SearchPageStates,
} from './states';

export interface Props extends RouteComponentProps {
  searchType?: string;
  searchId?: string;
}

export default function SearchResults(props: Props) {
  const resetSearchPageData = useResetRecoilState(SearchPageStates.data);
  const [searchPageData, setSearchPageData] = useRecoilState(SearchPageStates.data);
  const setSearchResult = useSetRecoilState(HeaderStates.searchResult);
  const setSearchType = useSetRecoilState(HeaderStates.searchType);
  const setSearchText = useSetRecoilState(HeaderStates.searchText);

  const [doQuickSearch, { loading, error }] = useQuickSearchLazyQuery({
    fetchPolicy: 'no-cache',
    onCompleted(data) {
      setSearchType(data.quickSearch.searchType);
      setSearchText(data.quickSearch.searchTerm);
      setSearchResult({
        id: props.searchId || '',
        value: data.quickSearch.searchTerm,
      });
      setSearchPageData({
        search: {
          type: data.quickSearch.searchType,
          term: data.quickSearch.searchTerm,
        },
        poCount: data.quickSearch.poCount,
        purchaseOrders: data.quickSearch.purchaseOrders.map((po): QuickSearchPoResult => {
          return {
            id: po.id,
            orderLineId: po.orderLineId,
            poNumber: po.poNumber ?? '',
            poDate: po.poDate,
            supplierName: po.supplierName ?? null,
            itemNumber: po.itemNumber ?? null,
            cargoReadyDate: po.cargoReadyDate,
            firstShipDate: po.firstShipDate,
            lastShipDate: po.lastShipDate,
            shipToLocationName: po.shipToLocationName ?? null,
          };
        }),
        containerCount: data.quickSearch.containerCount,
        containers: data.quickSearch.containers.map((container): QuickSearchContainerResult => {
          return {
            containerId: container.containerId,
            orderLineId: container.orderLineId,
            containerNumber: container.containerNumber ?? null,
            railRamp: container.railRamp ?? null,
            railRampEta: container.railRampEta,
            railRampAta: container.railRampAta,
            deliveryLocation: container.deliveryLocation ?? null,
            finalEta: container.finalEta,
            finalAta: container.finalAta,
          };
        }),
        bookingCount: data.quickSearch.bookingCount,
        bookings: data.quickSearch.bookings.map((booking): QuickSearchBookingResult => {
          return {
            bookingId: booking.shipmentId,
            orderLineId: booking.orderLineId,
            bookingNumber: booking.referenceNumber ?? '',
            mblId: booking.mblId ?? null,
            mblNumber: booking.mblNumber ?? null,
            cargoReadyDate: booking.cargoReadyDate,
            shipToLocationName: booking.shipToLocationName ?? null,
            carrierName: booking.carrierName ?? null,
            polCode: booking.polCode ?? null,
            polEtd: booking.polEtd,
            polAtd: booking.polAtd,
            podCode: booking.podCode ?? null,
            podEta: booking.podEta,
            podAta: booking.podAta,
          };
        }),
        shipmentCount: data.quickSearch.shipmentCount,
        shipments: data.quickSearch.shipments.map((shipment): QuickSearchShipmentResult => {
          return {
            bookingId: shipment.shipmentId,
            orderLineId: shipment.orderLineId,
            bookingNumber: shipment.referenceNumber ?? '',
            mblId: shipment.mblId ?? null,
            mblNumber: shipment.mblNumber ?? null,
            cargoReadyDate: shipment.cargoReadyDate,
            shipToLocationName: shipment.shipToLocationName ?? null,
            carrierName: shipment.carrierName ?? null,
            polCode: shipment.polCode ?? null,
            polEtd: shipment.polEtd,
            polAtd: shipment.polAtd,
            podCode: shipment.podCode ?? null,
            podEta: shipment.podEta,
            podAta: shipment.podAta,
          };
        }),
      });
    },
  });

  React.useEffect(() => {
    resetSearchPageData();
    doQuickSearch({
      variables: {
        searchType: props.searchType as SearchType,
        searchId: props.searchId || '',
      },
    });
  }, [props.searchType, props.searchId]);

  React.useEffect(() => {
    return () => {
      // When they leave the page
      setSearchText('');
      setSearchResult(null);
    };
  }, []);

  return (
    <div>
      <ErrorMessage error={error ? error + '' : null} />
      {loading ? (
        <Loading />
      ) : searchPageData.search ? (
        <div>
          <div style={{ display: 'flex', margin: theme.spacing(0, 0, 2, 2) }}>
            <Typography variant="h2" color="primary">
              {mapSearchType(searchPageData.search.type)}
            </Typography>
            <div style={{ marginLeft: theme.spacing(1) }}>{searchPageData.search.term}</div>
          </div>
          <SearchResultPurchaseOrders />
          <SearchResultContainers />
          <SearchResultBookings />
          <SearchResultShipments />
        </div>
      ) : (
        <div>
          <Typography variant="h2" color="primary">
            Search
          </Typography>
        </div>
      )}
    </div>
  );
}
