import { GTableColumn, GTableIndexedColumns } from './types';

export function indexCols<T extends {}>(columns: GTableColumn<T>[]) {
  const indexedCols: GTableIndexedColumns<T> = {};

  for (let i = 0; i < columns.length; i++) {
    indexedCols[i] = columns[i];
  }

  return indexedCols;
}

export function fromDataIndicesToTableIndices(dataIndices: number[]): number[] {
  const tableIndices = [...dataIndices];
  for (let i = 0; i < tableIndices.length; i++) {
    tableIndices[i] = tableIndices[i] + tableIndices.length - i - 1;
  }
  return tableIndices;
}

export function fromTableIndicesToDataIndices(tableIndices: number[]): number[] {
  const dataIndices = [...tableIndices].sort((n1, n2) => n1 - n2);
  let subOffset = 0;
  for (let i = 0; i < dataIndices.length; i++) {
    dataIndices[i] = dataIndices[i] - subOffset;
    subOffset++;
  }
  return dataIndices.sort((n1, n2) => n2 - n1);
}

export function fromTableIndexToDataIndex(index: number, tableIndices: number[]): number {
  const ascTableIndices = [...tableIndices].sort((n1, n2) => n1 - n2);
  let subOffset = 0;
  for (let i = 0; i < ascTableIndices.length; i++) {
    if (index > ascTableIndices[i]) {
      subOffset++;
    } else {
      break;
    }
  }
  return index - subOffset;
}

export function fromDataIndexToTableIndex(index: number, selected: number[]): number {
  const tableIndices = fromDataIndicesToTableIndices(selected);
  for (let i = 0; i < tableIndices.length; i++) {
    if (tableIndices[i] === index - 1) {
      return -1;
    }
  }
  return index;
}

export function getRenderedRows(rows: any[], expandedRows: any[]) {
  const renderedRows: any[] = [...rows];
  for (let i = 0; i < expandedRows.length; i++) {
    if (rows[expandedRows[i]]) {
      renderedRows.splice(expandedRows[i] + 1, 0, {
        ...rows[expandedRows[i]],
        _isCollapsibleContent: true,
        id: rows[expandedRows[i]].id + '-content',
      });
    }
  }
  return renderedRows;
}

export function calculateTableHeightCache(realHeightCache: any, cache: any, renderedRows: any[]) {
  const realHC: any = { ...realHeightCache };

  for (let i = 0; i < renderedRows.length; i++) {
    const h = cache.rowHeight({ index: i });
    if (!Object.keys(realHeightCache).includes(renderedRows[i].id) && h !== 44.44) {
      realHC[renderedRows[i].id] = h;
    }
  }
  return realHC;
}
