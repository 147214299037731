import Box from '@material-ui/core/Box';
import Checkbox from '@material-ui/core/Checkbox';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell, { TableCellProps } from '@material-ui/core/TableCell';
import TableFooter from '@material-ui/core/TableFooter';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import Typography from '@material-ui/core/Typography';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { Link } from '@reach/router';
import clsx from 'clsx';
import * as React from 'react';
import { useState } from 'react';
import { formatDate, formatDateTime } from 'utils/Dates';
import { formatNumber, formatNumberDecimalPlaces } from 'utils/Numbers';
import { SortDirection } from '../../lib/sort';
import { useDivClientWidthHeight } from '../../lib/useDivClientWidthHeight';
import { makeCss, theme } from '../../styles';
import { sortedRows, toVisibleColumnsInOrder, UWLTableColumn } from '../../types/UWLTable';
import ErrorMessage from '../ErrorMessage';
import LoadingProgress from '../LoadingProgress';

export const STANDARD_ROW_INNER_HEIGHT = 24;
export const STANDARD_ROW_PADDING_HEIGHT = 8 + 8 + 1; // top, bottom, border
export const STANDARD_ROW_OVERALL_HEIGHT = STANDARD_ROW_INNER_HEIGHT + STANDARD_ROW_PADDING_HEIGHT;

const classes = makeCss({
  root: {
    overflowY: 'auto',
    height: '100%',
  },
  fixedCellHeight: {
    overflow: 'hidden !important',
    display: 'flex',
    alignItems: 'center',
  },
  spacer_tr: {
    margin: '0 !important',
  },

  rowExpandCell: {},
  theExpandedRow: {},
  clickableRow: {},
  isScrollingAboveBottom: {},
  table: {
    '& > tbody > tr$theExpandedRow > td': {
      border: 'none',
    },
    '& > tbody > tr$clickableRow > td': {
      cursor: 'pointer',
      userSelect: 'none',
    },
    '&$isScrollingAboveBottom > tfoot > tr > td': {
      boxShadow: '6px -2px 5px rgba(0,0,0,0.20)',
    },
  },

  tableCellHead: {
    ...(theme.typography.h3 as any),
    whiteSpace: 'nowrap',
    textTransform: 'uppercase',
    backgroundColor: theme.palette.background.paper,
    paddingTop: '0 !important',
    borderBottomColor: theme.palette.secondary.main,
    borderBottomWidth: 3,
  },
  tableCellFooter: {
    ...theme.typography.body1,
    borderBottom: 'none',

    bottom: 0,
    left: 0,
    zIndex: 2,
    position: 'sticky',
    backgroundColor: theme.palette.background.paper,
  },
  tableCellSizeSmall: {
    padding: theme.spacing(1, 0.5),
  },

  tableCellBase: {
    ...theme.typography.body1,
  },

  unit: {
    fontWeight: theme.typography.caption.fontWeight,
    fontStyle: 'italic',
    marginLeft: theme.spacing(1),
  },
});

const tableCellClasses = {
  head: classes.tableCellHead,
  footer: classes.tableCellFooter,
  sizeSmall: classes.tableCellSizeSmall,
};

export const StyledTableCell: React.FC<TableCellProps> = (props) => {
  return <TableCell {...props} classes={tableCellClasses} />;
};

function toggleChecked(ids: string[], id: string): string[] {
  if (ids.includes(id)) {
    return ids.filter((i) => i !== id);
  } else {
    return ids.concat([id]);
  }
}

function colAlign(col: UWLTableColumn<any>): 'left' | 'right' | 'center' {
  if (col.align) {
    return col.align;
  }
  switch (col.type) {
    case 'currency':
    case 'weight':
    case 'volume':
    case 'distance':
    case 'number':
      return 'right';
  }
  return 'left';
}

function renderUnit(unit: string | null | undefined) {
  if (!unit) {
    return '';
  }
  return <span className={classes.unit}>{unit}</span>;
}

export function DefaultCellRender<T extends {}>(props: {
  column: UWLTableColumn<T>;
  row: T;
}): React.ReactElement | null {
  const { row, column } = props;

  switch (column.type) {
    case 'weight': {
      const value = (row as any)[column.id];
      const unit = column.unit || (column.unitField ? (row as any)[column.unitField] : null);
      return (
        <span>
          {formatNumber(value)} {renderUnit(unit)}
        </span>
      );
    }

    case 'volume': {
      const value = (row as any)[column.id];
      const unit = column.unit || (column.unitField ? (row as any)[column.unitField] : null);
      return (
        <span>
          {formatNumber(value)} {renderUnit(unit)}
        </span>
      );
    }

    case 'distance': {
      const value = (row as any)[column.id];
      const unit = column.unit || (column.unitField ? (row as any)[column.unitField] : null);
      return (
        <span>
          {formatNumber(value)} {renderUnit(unit)}
        </span>
      );
    }

    case 'currency': {
      const value = (row as any)[column.id];
      const currency = column.currencyField && (row as any)[column.currencyField];
      return (
        <span>
          {formatNumberDecimalPlaces(value, 2, 2)} {currency || ''}
        </span>
      );
    }

    case 'number':
      return <span>{formatNumber((row as any)[column.id])}</span>;

    case 'date': {
      const value = (row as any)[column.id];
      return <span>{formatDate(value)}</span>;
    }

    case 'datetime': {
      const value = (row as any)[column.id];
      return <span>{formatDateTime(value)}</span>;
    }

    case 'bool': {
      return <span>{(row as any)[column.id] ? 'True' : 'False'}</span>;
    }

    case 'link': {
      const value = (row as any)[column.id];
      if (!value) {
        return null;
      }
      if (typeof value.to !== 'string' || typeof value.value !== 'string') {
        return <ErrorMessage error={`Expected link: ${value + ''}`} />;
      }
      return <Link to={value.to}>{value.value}</Link>;
    }
  }

  const value = (row as any)[column.id];
  return <div>{value === null || value === undefined ? '' : value + ''}</div>;
}

interface Props<T extends {}> {
  rowId: Extract<keyof T, string>;
  columns: UWLTableColumn<T>[];
  rows: T[];
  columnsDisplay?: string[];
  onRowClick?: (row: T) => void;
  isLoading?: boolean;
  error?: string | null;
  emptyMessage?: string | (() => React.ReactNode);
  rowAction?: (row: T) => React.ReactNode;
  rowExpand?: (row: T) => React.ReactNode;
  sizeMedium?: boolean;

  /**
   * Set's whiteSpace=nowrap on all cells.
   */
  noWrapAllCells?: boolean;

  /**
   * Rendering big tables is expensive.
   * Virtualization renders only the visible portion of the table.
   */
  virtualize?: 'single-line-cells' | VirtualizeSpec<T>;

  renderCell?: Partial<
    {
      [key in keyof T]: (row: T) => React.ReactNode;
    }
  >;

  renderFooterCell?: Partial<
    {
      [key in keyof T]: () => React.ReactNode;
    }
  >;

  checkboxes?: {
    checked: string[];
    setChecked(ids: string[]): void;
  };
  disabled?: boolean;

  defaultOrderBy?: keyof T | null;
  defaultSortDirection?: SortDirection;

  onSortStateChange?: (sortState: {
    orderBy: keyof T | null;
    sortDirection: SortDirection;
  }) => void;
}

export function UWLTable<T extends {}>(props: Props<T>) {
  const [orderBy, setOrderBy] = useState<keyof T | null>(props.defaultOrderBy || null);
  const [sortDirection, setSortDirection] = useState<SortDirection>(
    props.defaultSortDirection || 'desc'
  );
  const [expandedRowIDs, setExpandedRowIDs] = useState<string[]>([]);

  const disabled = !!props.disabled;
  const columns = toVisibleColumnsInOrder(props.columns, props.columnsDisplay);
  const rows = sortedRows(props.columns, props.rows, orderBy, sortDirection);

  React.useEffect(() => {
    if (props.onSortStateChange) {
      props.onSortStateChange({ orderBy, sortDirection });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [orderBy, sortDirection]);

  const { ref: rootRef, height: visibleHeight } = useDivClientWidthHeight();
  const [scrollTop, setScrollTop] = React.useState(0);
  const [scrollHeight, setScrollHeight] = React.useState(0);

  React.useEffect(() => {
    const root = rootRef.current;
    if (root) {
      setScrollTop(root.scrollTop);
      setScrollHeight(root.scrollHeight);
    }
  }, [rows.length]);

  let spaceBefore = 0;
  let spaceAfter = 0;

  let rowsToRender: T[];
  const rowPaddedHeight = props.sizeMedium ? 33 : 31;
  const headerHeight = props.sizeMedium ? 39 : 17;

  const isScrollingAboveBottom = scrollTop + visibleHeight < scrollHeight;

  const virtualize =
    props.virtualize === 'single-line-cells' ? singleLineCellVirtualize : props.virtualize;

  const noWrapAllCells = props.virtualize === 'single-line-cells' ? true : !!props.noWrapAllCells;

  if (virtualize && props.rowExpand) {
    console.error("Can't use virtualize with rowExpand");
    // NOTE Technically we could support this if we take height of expanded row into account
    return <h1>Can't use virtualize with props.rowExpand</h1>;
  } else if (virtualize) {
    rowsToRender = [];
    const minY = scrollTop - virtualize.bufferHeight;
    const maxY = scrollTop + virtualize.bufferHeight + visibleHeight;

    let currentY = headerHeight;
    let renderedHeight = 0;

    for (let i = 0; i < rows.length; i++) {
      const row = rows[i];
      const height = virtualize.rowHeight(row) + rowPaddedHeight;

      if (currentY < minY) {
        spaceBefore = currentY + height;
      } else if (currentY > maxY) {
        // skip rows after
      } else {
        rowsToRender.push(row);
        renderedHeight += height;
      }

      currentY += height;
    }
    spaceAfter = currentY - renderedHeight - spaceBefore;
  } else {
    rowsToRender = rows;
  }

  return (
    <div
      ref={rootRef}
      className={classes.root}
      onScroll={(e) => {
        setScrollTop(e.currentTarget.scrollTop);
        setScrollHeight(e.currentTarget.scrollHeight);
      }}
    >
      <Table
        stickyHeader
        size={props.sizeMedium ? 'medium' : 'small'}
        className={clsx(classes.table, isScrollingAboveBottom && classes.isScrollingAboveBottom)}
      >
        <TableHead>
          <TableRow>
            {props.checkboxes && (
              <StyledTableCell variant="head" padding="checkbox">
                <Checkbox
                  indeterminate={
                    props.checkboxes.checked.length > 0 &&
                    props.checkboxes.checked.length < rows.length
                  }
                  checked={props.checkboxes.checked.length === rows.length && rows.length > 0}
                  onChange={() => {
                    if (!props.checkboxes) return;
                    if (props.checkboxes.checked.length === rows.length) {
                      props.checkboxes.setChecked([]);
                    } else {
                      props.checkboxes.setChecked(rows.map((row) => row[props.rowId] + ''));
                    }
                  }}
                  inputProps={{ 'aria-label': 'select all' }}
                  disabled={disabled}
                />
              </StyledTableCell>
            )}
            {columns.map((col) => {
              return (
                <StyledTableCell
                  key={col.id}
                  variant="head"
                  align={colAlign(col)}
                  sortDirection={orderBy === col.id ? sortDirection : false}
                >
                  <TableSortLabel
                    active={orderBy === col.id}
                    direction={sortDirection}
                    onClick={() => {
                      if (disabled) return;
                      if (orderBy === col.id) {
                        setSortDirection(sortDirection === 'asc' ? 'desc' : 'asc');
                      } else {
                        setOrderBy(col.id);
                        setSortDirection('desc');
                      }
                    }}
                    disabled={disabled}
                    title={col.labelTitle}
                    style={{ paddingLeft: colAlign(col) === 'center' ? 24 : undefined }}
                  >
                    {col.label}
                  </TableSortLabel>
                </StyledTableCell>
              );
            })}
            {props.rowAction && <StyledTableCell variant="head" style={{ width: 1 }} />}
            {props.rowExpand && <StyledTableCell variant="head" style={{ width: 1 }} />}
          </TableRow>
        </TableHead>
        <TableBody>
          <tr className={classes.spacer_tr} style={{ height: spaceBefore }}></tr>

          {rowsToRender.map((row) => {
            const id = row[props.rowId] + '';
            const isExpanded = props.rowExpand && expandedRowIDs.includes(id);

            const height = virtualize ? virtualize.rowHeight(row) : 'auto';

            function toggleExpandedRow() {
              if (expandedRowIDs.includes(id)) {
                setExpandedRowIDs(expandedRowIDs.filter((i) => i !== id));
              } else {
                setExpandedRowIDs(expandedRowIDs.concat([id]));
              }
            }

            return (
              <React.Fragment key={id}>
                <TableRow
                  hover={!isExpanded}
                  onClick={() => {
                    if (disabled) return;
                    if (props.onRowClick) {
                      props.onRowClick(row);
                    } else if (props.rowExpand) {
                      toggleExpandedRow();
                    } else if (props.checkboxes) {
                      props.checkboxes.setChecked(
                        toggleChecked(props.checkboxes.checked, row[props.rowId] + '')
                      );
                    }
                  }}
                  className={clsx(
                    isExpanded && classes.theExpandedRow,
                    props.onRowClick && classes.clickableRow
                  )}
                >
                  {props.checkboxes && (
                    <StyledTableCell padding="checkbox">
                      <Checkbox
                        checked={props.checkboxes.checked.includes(row[props.rowId] + '')}
                        onChange={() => {
                          if (!props.checkboxes) return;
                          props.checkboxes.setChecked(
                            toggleChecked(props.checkboxes.checked, row[props.rowId] + '')
                          );
                        }}
                        disabled={disabled}
                      />
                    </StyledTableCell>
                  )}
                  {columns.map((col) => {
                    let renderCell = props.renderCell && props.renderCell[col.id];

                    const align = colAlign(col);

                    return (
                      <StyledTableCell
                        key={col.id}
                        scope={col.id === props.rowId ? 'row' : void 0}
                        align={align}
                      >
                        <div
                          className={clsx(classes.fixedCellHeight, classes.tableCellBase)}
                          style={{
                            height,
                            justifyContent:
                              align === 'center'
                                ? 'center'
                                : align === 'right'
                                ? 'flex-end'
                                : undefined,
                            whiteSpace: noWrapAllCells ? 'nowrap' : col.whiteSpace,
                          }}
                        >
                          {renderCell ? (
                            renderCell(row)
                          ) : (
                            <DefaultCellRender column={col} row={row} />
                          )}
                        </div>
                      </StyledTableCell>
                    );
                  })}
                  {props.rowAction && (
                    <StyledTableCell onClick={(e) => e.stopPropagation()}>
                      <div className={classes.fixedCellHeight} style={{ height }}>
                        {props.rowAction(row)}
                      </div>
                    </StyledTableCell>
                  )}
                  {props.rowExpand && (
                    <StyledTableCell onClick={toggleExpandedRow} style={{ textAlign: 'right' }}>
                      <div className={classes.fixedCellHeight} style={{ height }}>
                        {expandedRowIDs.includes(id) ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                      </div>
                    </StyledTableCell>
                  )}
                </TableRow>
                {props.rowExpand && isExpanded && (
                  <TableRow>
                    <TableCell
                      className={classes.rowExpandCell}
                      colSpan={columns.length + (props.rowAction ? 1 : 0) + 1}
                    >
                      {props.rowExpand(row)}
                    </TableCell>
                  </TableRow>
                )}
              </React.Fragment>
            );
          })}

          <tr className={classes.spacer_tr} style={{ height: spaceAfter }}></tr>
        </TableBody>
        {props.renderFooterCell && rowsToRender.length > 0 && (
          <TableFooter>
            <TableRow>
              {props.checkboxes && <StyledTableCell variant="footer" />}
              {columns.map((col) => {
                let renderCell = props.renderFooterCell && props.renderFooterCell[col.id];
                return (
                  <StyledTableCell key={col.id} variant="footer">
                    {renderCell && renderCell()}
                  </StyledTableCell>
                );
              })}
              {props.rowAction && <StyledTableCell variant="footer" />}
              {props.rowExpand && <StyledTableCell variant="footer" />}
            </TableRow>
          </TableFooter>
        )}
      </Table>
      {props.isLoading && rows.length === 0 && <LoadingProgress />}
      {!props.isLoading && rows.length === 0 && (
        <Box padding={2}>
          {props.error ? (
            <ErrorMessage error={props.error} />
          ) : props.emptyMessage ? (
            typeof props.emptyMessage === 'string' ? (
              <Typography>{props.emptyMessage}</Typography>
            ) : (
              props.emptyMessage()
            )
          ) : (
            <Typography>- no data -</Typography>
          )}
        </Box>
      )}
    </div>
  );
}

interface VirtualizeSpec<T> {
  /**
   * Calculate the height of the row contents. Don't worry about any padding the cable adds to the cells.
   *
   * @param row the table row
   */
  rowHeight(row: T): number;

  /**
   * How many pixels above and below the visible portion should be rendered.
   * This helps keep the scroll smooth.
   */
  bufferHeight: number;
}

const singleLineCellVirtualize: VirtualizeSpec<unknown> = {
  rowHeight(row) {
    return STANDARD_ROW_INNER_HEIGHT;
  },
  bufferHeight: STANDARD_ROW_INNER_HEIGHT * 3,
};
