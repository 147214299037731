import { faFire } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Box, ListItem, ListItemIcon, Typography } from '@material-ui/core';
import React from 'react';
import { theme } from 'styles';
import { formatNumber, isNumber } from 'utils/Numbers';

interface Props {
  name: string;

  unreadCount?: number;
  resultCount?: number;

  faIcon: any;
  addHotIcon?: boolean;

  onClick?: React.MouseEventHandler<HTMLDivElement>;

  innerRef?: React.Ref<HTMLDivElement>;
}

export const AlertIconButton: React.FC<Props> = (props) => {
  const hasUnread = isNumber(props.unreadCount) && props.unreadCount > 0;
  return (
    <ListItem
      ref={props.innerRef}
      button
      style={{
        padding: '8px 8px',
        backgroundColor: '#fff',
        width: '50%',
        display: 'inline-flex',
      }}
      onClick={props.onClick}
    >
      <ListItemIcon style={{ alignItems: 'center' }}>
        <Box
          component="span"
          padding="13px"
          fontSize="20px"
          className="material-icons"
          color={theme.palette.common.white}
          bgcolor={theme.palette.indigoBlue.main}
          borderRadius="50%"
        >
          <Box display="flex" style={{ flexFlow: 'wrap-reverse' }}>
            <FontAwesomeIcon icon={props.faIcon} style={{ verticalAlign: 0, width: '1em' }} />
            {props.addHotIcon && (
              <FontAwesomeIcon
                icon={faFire}
                color={theme.palette.error.main}
                style={{ fontSize: '10px', marginLeft: '-7px' }}
              />
            )}
          </Box>
        </Box>

        {hasUnread && (
          <Box
            component="span"
            marginLeft="-13px"
            marginBottom="28px"
            padding="5px"
            fontSize="8px"
            className="material-icons"
            color={theme.palette.common.white}
            bgcolor={theme.palette.error.main}
            borderRadius="50%"
          >
            <Typography
              variant="subtitle1"
              color="textSecondary"
              style={{
                padding: '0 2px',
                lineHeight: '0.9',
                fontSize: '14px',
                fontWeight: 800,
              }}
            >
              {props.unreadCount}
            </Typography>
          </Box>
        )}

        <Typography
          variant="h4"
          style={{ paddingLeft: hasUnread ? '8px' : '16px' }}
          color="primary"
        >
          {props.name}
          {isNumber(props.resultCount) &&
            props.resultCount > 0 &&
            ` (${formatNumber(props.resultCount)})`}
        </Typography>
      </ListItemIcon>
    </ListItem>
  );
};
