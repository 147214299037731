export const API_URL: string = (window as any)._env_.API_URL;
export const BUILD_ENV: string = (window as any)._env_.BUILD_ENV;
export const GRAPHQL_URL: string = (window as any)._env_.API_URL + 'graphql/';
export const STATIC_URL: string = (window as any)._env_.API_URL + 'static/';

export const AUTH_LOCAL_STORAGE_KEY = 'oms-auth';

export const SSO_ISSUER: string = (window as any)._env_.SSO_ISSUER;
export const SSO_CLIENT_ID: string = (window as any)._env_.SSO_CLIENT_ID;
export const SSO_SCOPES: string = (window as any)._env_.SSO_SCOPES;
export const SSO_SIGNOUT_URL: string =
  (window as any)._env_.SSO_SIGNOUT_URL || SSO_ISSUER + '/signout';
export const BASE_URL: string = (window as any)._env_.BASE_URL;
