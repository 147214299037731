import {
  GqlGlobalUpdateRecord,
  GqlGlobalUpdateResults,
  SearchType,
  useGlobalUpdateSearchResultsLazyQuery,
} from 'api/GQL_Types';
import { SearchResult, SearchState } from 'components/form/FormInputSearch';
import { Label } from 'components/TextFieldWarnDecorator';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { DateAtomType, newAtom } from 'recoil-utils/utils';

let nextRowId = 0;
export function genRowId(): string {
  return `row${nextRowId++}`;
}

export interface GlobalUpdateRecordRow extends GqlGlobalUpdateRecord {
  rowId: string;
}

export const GUStates = {
  // Search Bar states
  searchType: newAtom<SearchType>(SearchType.Hbl),
  search: newAtom<SearchState>({
    waiting: false,
    error: null,
    results: [],
  }),
  searchResult: newAtom<SearchResult | null>(null),

  // Search Result states
  globalUpdateRecordsLoading: newAtom<boolean>(false),
  globalUpdateRecordsError: newAtom<string | null>(null),
  globalUpdateRecords: newAtom<GlobalUpdateRecordRow[]>([]),
  selectedGlobalUpdateRecords: newAtom<GlobalUpdateRecordRow[]>([]),

  // Step 2 states
  recordData: newAtom<GqlGlobalUpdateResults>({
    logistics: [],
    containers: [],
    customs: [],
    records: [],
  }),

  form: {
    confirmedDate: newAtom<DateAtomType>(null),
    confirmationNumber: newAtom<string>(''),
    polEtd: newAtom<DateAtomType>(null),
    polAtd: newAtom<DateAtomType>(null),
    transhipEta: newAtom<DateAtomType>(null),
    transhipAta: newAtom<DateAtomType>(null),
    transhipEtd: newAtom<DateAtomType>(null),
    transhipAtd: newAtom<DateAtomType>(null),
    podEta: newAtom<DateAtomType>(null),
    podAta: newAtom<DateAtomType>(null),
    rampEta: newAtom<DateAtomType>(null),
    rampAta: newAtom<DateAtomType>(null),
    rampOutGateDate: newAtom<DateAtomType>(null),
    portOutGateDate: newAtom<DateAtomType>(null),
    deliveryEta: newAtom<DateAtomType>(null),
    deliveryAta: newAtom<DateAtomType>(null),
    emptyReturnDate: newAtom<DateAtomType>(null),
    emptyNotify: newAtom<DateAtomType>(null),
    emptyPickup: newAtom<DateAtomType>(null),
    demurrageLfd: newAtom<DateAtomType>(null),
    detentionLfd: newAtom<DateAtomType>(null),
    consolidatorLfd: newAtom<DateAtomType>(null),
    customsBrokerRef: newAtom<string>(''),
    isfResponseNo: newAtom<string>(''),
    isfFilingDate: newAtom<DateAtomType>(null),
    entryNumber: newAtom<string>(''),
    entryDate: newAtom<DateAtomType>(null),
    entryValue: newAtom<number | null>(0),
    mpf: newAtom<number | null>(0),
    hmf: newAtom<number | null>(0),
    duty: newAtom<number | null>(0),
    customsExamType: newAtom<string>(''),
    customsExamNotifyDate: newAtom<DateAtomType>(null),
    customsExamFee: newAtom<number | null>(0),
    customsReleaseDate: newAtom<DateAtomType>(null),
    fdaExamType: newAtom<string>(''),
    fdaExamNotifyDate: newAtom<DateAtomType>(null),
    fdaExamFee: newAtom<number | null>(0),
    fdaReleaseDate: newAtom<DateAtomType>(null),
    entryFeeTotal: newAtom<number | null>(0),

    confirmedDateLabels: newAtom<Label[]>([]),
    confirmationNumberLabels: newAtom<Label[]>([]),
    polEtdLabels: newAtom<Label[]>([]),
    polAtdLabels: newAtom<Label[]>([]),
    transhipEtaLabels: newAtom<Label[]>([]),
    transhipAtaLabels: newAtom<Label[]>([]),
    transhipEtdLabels: newAtom<Label[]>([]),
    transhipAtdLabels: newAtom<Label[]>([]),
    podEtaLabels: newAtom<Label[]>([]),
    podAtaLabels: newAtom<Label[]>([]),
    rampEtaLabels: newAtom<Label[]>([]),
    rampAtaLabels: newAtom<Label[]>([]),
    rampOutGateDateLabels: newAtom<Label[]>([]),
    portOutGateDateLabels: newAtom<Label[]>([]),
    deliveryEtaLabels: newAtom<Label[]>([]),
    deliveryAtaLabels: newAtom<Label[]>([]),
    emptyReturnDateLabels: newAtom<Label[]>([]),
    emptyNotifyLabels: newAtom<Label[]>([]),
    emptyPickupLabels: newAtom<Label[]>([]),
    demurrageLfdLabels: newAtom<Label[]>([]),
    detentionLfdLabels: newAtom<Label[]>([]),
    consolidatorLfdLabels: newAtom<Label[]>([]),
    customsBrokerRefLabels: newAtom<Label[]>([]),
    isfResponseNoLabels: newAtom<Label[]>([]),
    isfFilingDateLabels: newAtom<Label[]>([]),
    entryNumberLabels: newAtom<Label[]>([]),
    entryDateLabels: newAtom<Label[]>([]),
    entryValueLabels: newAtom<Label[]>([]),
    mpfLabels: newAtom<Label[]>([]),
    hmfLabels: newAtom<Label[]>([]),
    dutyLabels: newAtom<Label[]>([]),
    customsExamTypeLabels: newAtom<Label[]>([]),
    customsExamNotifyDateLabels: newAtom<Label[]>([]),
    customsExamFeeLabels: newAtom<Label[]>([]),
    customsReleaseDateLabels: newAtom<Label[]>([]),
    fdaExamTypeLabels: newAtom<Label[]>([]),
    fdaExamNotifyDateLabels: newAtom<Label[]>([]),
    fdaExamFeeLabels: newAtom<Label[]>([]),
    fdaReleaseDateLabels: newAtom<Label[]>([]),
    entryFeeTotalLabels: newAtom<Label[]>([]),
  },

  initialState: {
    initConfirmedDate: newAtom<DateAtomType>(null),
    initConfirmationNumber: newAtom<string>(''),
    initPolEtd: newAtom<DateAtomType>(null),
    initPolAtd: newAtom<DateAtomType>(null),
    initTranshipEta: newAtom<DateAtomType>(null),
    initTranshipAta: newAtom<DateAtomType>(null),
    initTranshipEtd: newAtom<DateAtomType>(null),
    initTranshipAtd: newAtom<DateAtomType>(null),
    initPodEta: newAtom<DateAtomType>(null),
    initPodAta: newAtom<DateAtomType>(null),
    initRampEta: newAtom<DateAtomType>(null),
    initRampAta: newAtom<DateAtomType>(null),
    initRampOutGateDate: newAtom<DateAtomType>(null),
    initPortOutGateDate: newAtom<DateAtomType>(null),
    initDeliveryEta: newAtom<DateAtomType>(null),
    initDeliveryAta: newAtom<DateAtomType>(null),
    initEmptyReturnDate: newAtom<DateAtomType>(null),
    initEmptyNotify: newAtom<DateAtomType>(null),
    initEmptyPickup: newAtom<DateAtomType>(null),
    initDemurrageLfd: newAtom<DateAtomType>(null),
    initDetentionLfd: newAtom<DateAtomType>(null),
    initConsolidatorLfd: newAtom<DateAtomType>(null),
    initCustomsBrokerRef: newAtom<string>(''),
    initIsfResponseNo: newAtom<string>(''),
    initIsfFilingDate: newAtom<DateAtomType>(null),
    initEntryNumber: newAtom<string>(''),
    initEntryDate: newAtom<DateAtomType>(null),
    initEntryValue: newAtom<number | null>(0),
    initMpf: newAtom<number | null>(0),
    initHmf: newAtom<number | null>(0),
    initDuty: newAtom<number | null>(0),
    initCustomsExamType: newAtom<string>(''),
    initCustomsExamNotifyDate: newAtom<DateAtomType>(null),
    initCustomsExamFee: newAtom<number | null>(0),
    initCustomsReleaseDate: newAtom<DateAtomType>(null),
    initFdaExamType: newAtom<string>(''),
    initFdaExamNotifyDate: newAtom<DateAtomType>(null),
    initFdaExamFee: newAtom<number | null>(0),
    initFdaReleaseDate: newAtom<DateAtomType>(null),
    initEntryFeeTotal: newAtom<number | null>(0),
  },
};

export function useSearchGURecords() {
  const searchResult = useRecoilValue(GUStates.searchResult);
  const searchType = useRecoilValue(GUStates.searchType);

  const setSelectedResults = useSetRecoilState(GUStates.selectedGlobalUpdateRecords);
  const setTableData = useSetRecoilState(GUStates.globalUpdateRecords);
  const setLoading = useSetRecoilState(GUStates.globalUpdateRecordsLoading);
  const setError = useSetRecoilState(GUStates.globalUpdateRecordsError);

  const [searchRecords] = useGlobalUpdateSearchResultsLazyQuery({
    onCompleted(data) {
      setLoading(false);
      setError(null);
      setTableData(
        data.globalUpdateSearch.map((record) => {
          return {
            ...record,
            rowId: genRowId(),
          };
        })
      );
      setSelectedResults([]);
    },
    onError(error) {
      setLoading(false);
      setError(error + '');
    },
    fetchPolicy: 'no-cache',
  });

  return () => {
    setError(null);
    setTableData([]);
    setSelectedResults([]);
    if (searchResult) {
      setLoading(true);
      searchRecords({
        variables: {
          searchType: searchType,
          searchId: searchResult.id,
        },
      });
    }
  };
}
