import { faFileInvoiceDollar } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Box, Typography } from '@material-ui/core';
import { DashboardFeatureCode, OrderStatus, PartyType, useDashboardPOsQuery } from 'api/GQL_Types';
import { userContextAtom } from 'app';
import Loading from 'app/Loading';
import CompositePanel from 'components/CompositePanel';
import React from 'react';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import { theme } from 'styles';
import { formatNumberDecimalPlaces, isNumber } from 'utils/Numbers';
import { DashboardPageStates, PoTable } from '../states';
import POButton from './POButton';
import POButtonsPopover from './POButtonsPopover';

export default function DashboardPOButtons() {
  const userContext = useRecoilValue(userContextAtom);
  const [openPos, setOpenPos] = useRecoilState(DashboardPageStates.po.openPos);
  const [expiredPos, setExpiredPos] = useRecoilState(DashboardPageStates.po.expiredPos);
  const [latePos, setLatePos] = useRecoilState(DashboardPageStates.po.latePos);
  const setOpenPopover = useSetRecoilState(DashboardPageStates.po.openPopover);
  const setSelectedOpenData = useSetRecoilState(DashboardPageStates.po.selectedOpenData);
  const setPopoverLabel = useSetRecoilState(DashboardPageStates.po.popoverLabel);
  const [anchorEl, setAnchorEl] = React.useState<(EventTarget & HTMLElement) | null>(null);

  const { loading: posDataLoading } = useDashboardPOsQuery({
    fetchPolicy: 'cache-and-network',
    onCompleted(data) {
      const pos = data.dashboardPurchaseOrders;
      const dashPos = pos.map((dashPo): PoTable => {
        const vendor = dashPo.orderLine.purchaseOrder.relatedParties.find(
          (rp) => rp.partyType === PartyType.Supplier
        );
        return {
          id: dashPo.orderLine.id,
          lineStatus: dashPo.orderLine.lineStatus,
          po: {
            id: dashPo.orderLine.purchaseOrder.id,
            poNumber: dashPo.orderLine.purchaseOrder.poNumber,
          },
          poLink: {
            to: '/purchase-orders/' + dashPo.orderLine.purchaseOrder.id,
            value: dashPo.orderLine.purchaseOrder.poNumber,
          },
          itemNumber: dashPo.orderLine.itemNumber,
          shipToLocationName: dashPo.orderLine.shipToLocation?.name ?? '',
          qty: dashPo.orderLine.totalQty ?? 0,
          vendor: vendor?.party.name ?? '',
          expectedCargoReadyDate: dashPo.orderLine.expectedCargoReadyDate,
          indcDate: dashPo.orderLine.indcDate,
          poValue: isNumber(dashPo.orderLine.unitPrice)
            ? `$ ${formatNumberDecimalPlaces(dashPo.orderLine.unitPrice, 2, 2)} ${
                dashPo.orderLine.unitPriceCurrencyCode === 'USD'
                  ? ''
                  : dashPo.orderLine.unitPriceCurrencyCode || ''
              }`.trim()
            : '',
        };
      });

      const open = dashPos.filter((po) => po.lineStatus === OrderStatus.Open);
      const expired = dashPos.filter((po) => po.lineStatus === OrderStatus.Expired);
      const late = dashPos.filter((po) => po.lineStatus === OrderStatus.Critical);

      setOpenPos(open);
      setExpiredPos(expired);
      setLatePos(late);
    },
  });

  if (posDataLoading) {
    return (
      <CompositePanel>
        <Loading />
      </CompositePanel>
    );
  }

  return (
    <CompositePanel>
      <Box margin={2} display="flex" alignItems="center" justifyContent="space-between">
        <Typography
          variant="h2"
          color="primary"
          style={{ minWidth: 'fit-content', letterSpacing: '0.5px' }}
        >
          <Box component="span" marginRight={2}>
            <FontAwesomeIcon icon={faFileInvoiceDollar} color={theme.palette.indigoBlue.main} />
          </Box>
          Purchase Orders
        </Typography>
      </Box>
      <Box display="flex" padding={1} marginBottom={1} flexGrow={1}>
        <Box display="flex" justifyContent="space-around" width={'100%'} padding={0}>
          {userContext?.dashboardFeatureCodes.has(DashboardFeatureCode.PoStatusOpen) && (
            <POButton
              color={theme.palette.dodgerBlue.main}
              label="Open PO's"
              value={openPos.length}
              matIcon="assignment_turned_in"
              onClick={(e: any) => {
                setPopoverLabel('Open Purchase Orders');
                setOpenPopover(true);
                setAnchorEl(e.currentTarget);
                setSelectedOpenData(openPos);
              }}
            />
          )}
          {userContext?.dashboardFeatureCodes.has(DashboardFeatureCode.PoStatusCritical) && (
            <POButton
              color={theme.palette.secondary.main}
              matIcon="assignment_late"
              label="Critical PO's"
              value={latePos.length}
              onClick={(e: any) => {
                setPopoverLabel('Critical Purchase Orders');
                setOpenPopover(true);
                setAnchorEl(e.currentTarget);
                setSelectedOpenData(latePos);
              }}
            />
          )}
          {userContext?.dashboardFeatureCodes.has(DashboardFeatureCode.PoStatusExpired) && (
            <POButton
              color={theme.palette.orange.main}
              matIcon="assignment_late"
              label="Expired PO's"
              value={expiredPos.length}
              onClick={(e: any) => {
                setPopoverLabel('Expired Purchase Orders');
                setOpenPopover(true);
                setAnchorEl(e.currentTarget);
                setSelectedOpenData(expiredPos);
              }}
            />
          )}
        </Box>
        <POButtonsPopover
          anchorEl={anchorEl}
          onClose={() => {
            setOpenPopover(false);
          }}
        />
      </Box>
    </CompositePanel>
  );
}
