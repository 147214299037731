import { Box, Typography } from '@material-ui/core';
import Popover from '@material-ui/core/Popover';
import {
  GqlPoImportFileStatusFragment,
  GqlPoImportFileError,
  GqlPoImportFileHeadersFragment,
} from 'api/GQL_Types';
import { formatNumber } from 'utils/Numbers';
import React from 'react';
import { theme } from 'styles';

interface Props {
  fileStatusData: GqlPoImportFileStatusFragment;
}

/**
 * TODO find a better way of displaying these errors
 */
export const ImportWarningSummary: React.FC<Props> = ({ fileStatusData }) => {
  const anchorEl = React.useRef<HTMLDivElement | null>(null);
  const [errorSummaryPopupMessage, setErrorSummaryPopupMessage] =
    React.useState<string | null>(null);

  const nErrors = fileStatusData.errors.length;

  const nHeadersWithErrors = new Set(
    fileStatusData.errors.filter((err) => err.headerIndex >= 0).map((err) => err.headerIndex)
  ).size;

  const nRowsWithErrors = new Set(
    fileStatusData.errors.filter((err) => err.rowIndex >= 0).map((err) => err.rowIndex)
  ).size;

  return (
    <div>
      <Box
        padding={3}
        style={{ cursor: 'pointer', userSelect: 'none' }}
        onClick={() => {
          let msg = ``;

          const headersByIndex = new Map<number, GqlPoImportFileHeadersFragment>();
          for (const header of fileStatusData.headers) {
            headersByIndex.set(header.headerIndex, header);
          }

          const errorsByHeaderI = new Map<number, GqlPoImportFileError[]>();

          for (const error of fileStatusData.errors) {
            if (error.headerIndex >= 0) {
              const errors = errorsByHeaderI.get(error.headerIndex) || [];
              errors.push(error);
              errorsByHeaderI.set(error.headerIndex, errors);
            }
          }
          errorsByHeaderI.forEach((errors, headerIndex) => {
            const header = headersByIndex.get(headerIndex);
            if (header) {
              msg += `${header.name} (${
                header.mapping
                  ? `Mapped to: ${header.mapping.group.name} - ${header.mapping.name}`
                  : 'not mapped'
              }) Has ${formatNumber(errors.length)} errors\n\n`;
            }
          });

          setErrorSummaryPopupMessage(msg.trim());
        }}
      >
        <Typography variant="h3">WARNINGS</Typography>

        <div ref={anchorEl} style={{ display: 'flex', alignItems: 'center' }}>
          <Typography variant="body1">{formatNumber(nErrors)} Total</Typography>
        </div>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <Typography variant="body1">
            {nHeadersWithErrors === 1 ? '1 Column' : `${formatNumber(nHeadersWithErrors)} Columns`}
          </Typography>
        </div>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <Typography variant="body1">
            {nRowsWithErrors === 1 ? '1 Row' : `${formatNumber(nRowsWithErrors)} Rows`}
          </Typography>
        </div>
      </Box>
      <Popover
        open={!!errorSummaryPopupMessage}
        anchorEl={anchorEl.current}
        onClose={() => setErrorSummaryPopupMessage(null)}
        anchorOrigin={{
          vertical: 'center',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'center',
          horizontal: 'center',
        }}
      >
        <div style={{ whiteSpace: 'pre-wrap', padding: theme.spacing(2) }}>
          {errorSummaryPopupMessage}
        </div>
      </Popover>
    </div>
  );
};
