import { Box } from '@material-ui/core';
import { RouteComponentProps } from '@reach/router';
import Admin from 'app/admin';
import Bookings from 'app/bookings';
import Consolidations from 'app/consolidations';
import Dashboard from 'app/dashboard';
import Documents from 'app/documents';
import Equipment from 'app/equipment';
import GlobalUpdatePage from 'app/global-update';
import HouseBills from 'app/house-bills';
import MasterBills from 'app/master-bills';
import PurchaseOrders from 'app/purchase-orders';
import Reports from 'app/reports';
import SearchResults from 'app/search-results';
import { FlexGrowRouter } from 'components/StyledComponents';
import Header from './header';
import SideNavigation from './side-nav';
import { classes } from './styles';

interface Props extends RouteComponentProps {}

export default function Layout(props: Props) {
  return (
    <Box className={classes.root}>
      <SideNavigation />
      <main className={classes.main}>
        <Header />
        <Box className={classes.content}>
          <FlexGrowRouter>
            <Dashboard path="/" />
            <GlobalUpdatePage path="/admin/global-updates/*" />
            <Admin path="/admin/*" />
            <PurchaseOrders path="/purchase-orders/*" />
            <Bookings path="/bookings/*" />
            <Consolidations path="/consolidations/*" />
            <Equipment path="/equipment/*" />
            <HouseBills path="/hbl/*" />
            <MasterBills path="/mbl/*" />
            <Documents path="/documents/*" />
            <Reports path="/reports/*" />
            <SearchResults path="/search/:searchType/:searchId" />
          </FlexGrowRouter>
        </Box>
      </main>
    </Box>
  );
}
