import { Box, Button } from '@material-ui/core';
import { GqlProfile, PartyType } from 'api/GQL_Types';
import AtomicAutocompleteV2 from 'components/atomic/AtomicAutocompleteV2';
import React from 'react';
import { selectorFamily, useRecoilState } from 'recoil';
import { genKey, newAtomFamily } from 'recoil-utils/utils';
import { mapPartyType } from 'utils/Enums';
import { RelatedPartyItem } from '.';

const selectedProfileRelations = newAtomFamily<RelatedPartyItem>({
  relationId: '',
  profile: null,
  partyType: null,
});

// For getting full item from RelatedPartyItem family
const itemSelector = selectorFamily<RelatedPartyItem, string>({
  key: genKey(),
  get:
    (key: string) =>
    ({ get }) => {
      return get(selectedProfileRelations(key));
    },
  set:
    (key: string) =>
    ({ set }, newValue) => {
      set(selectedProfileRelations(key), newValue);
    },
});

// For getting profile display object from RelatedPartyItem family
const profileSelector = selectorFamily<{ id: string; name: string } | null, string>({
  key: genKey(),
  get:
    (key: string) =>
    ({ get }) => {
      const obj = get(selectedProfileRelations(key));
      if (obj.profile) {
        return { id: obj.profile.id, name: obj.profile.name };
      } else {
        return null;
      }
    },

  set:
    (key: string) =>
    ({ get, set }, newValue) => {
      const obj = get(selectedProfileRelations(key));
      set(selectedProfileRelations(key), {
        ...obj,
        profile: newValue as { id: string; name: string },
      });
    },
});

// For getting relation enum from RelatedPartyItem family
const relationSelector = selectorFamily<PartyType | null, string>({
  key: genKey(),
  get:
    (key: string) =>
    ({ get }) => {
      const obj = get(selectedProfileRelations(key));
      return obj.partyType;
    },

  set:
    (key: string) =>
    ({ get, set }, newValue) => {
      const obj = get(selectedProfileRelations(key));
      set(selectedProfileRelations(key), {
        ...obj,
        partyType: newValue as PartyType,
      });
    },
});

interface Props {
  itemSelectorId: string;
  items: { [key: string]: RelatedPartyItem };
  profileOptions: GqlProfile[];
  relationOptions: PartyType[];
  onItemChanged: (itemId: string, item: RelatedPartyItem) => void;
  onDeleteClicked: (itemId: string) => void;
}
export default function RelationItem(props: Props) {
  const [item, setItem] = useRecoilState(itemSelector(props.itemSelectorId));

  React.useEffect(() => {
    setItem({ ...props.items[props.itemSelectorId] });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  React.useEffect(() => {
    props.onItemChanged(props.itemSelectorId, item);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [item]);

  // For each profile filter out already selected relations. Helps to prevent adding same profile with same relationType.
  const options = Object.values(props.items).map((rp) => rp.partyType);

  return (
    <Box display="flex" alignItems="flex-start" marginBottom={2}>
      <AtomicAutocompleteV2<{ id: string; name: string }>
        state={profileSelector(props.itemSelectorId)}
        label="Party"
        optionsList={props.profileOptions}
        displayResolver={(profile) => profile.name}
        required
      />
      <AtomicAutocompleteV2<PartyType>
        state={relationSelector(props.itemSelectorId)}
        label="Party Type"
        optionsList={props.relationOptions.filter((rt) => !options.includes(rt))}
        displayResolver={mapPartyType}
        required
      />
      <Box alignSelf="center">
        <Button
          onClick={() => {
            props.onDeleteClicked(props.itemSelectorId);
          }}
        >
          Remove
        </Button>
      </Box>
    </Box>
  );
}
