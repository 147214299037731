import { Grid } from '@material-ui/core';
import PortDisplay from 'components/PortDisplay';
import { PortDisplayDate } from 'components/PortDisplayDate';
import { useRecoilValue } from 'recoil';
import { portToStringMaybe } from 'types/Port';
import { formatDate } from 'utils/Dates';
import { HblPageStates } from '../states';

export default function HBLTransitDetails() {
  const hbl = useRecoilValue(HblPageStates.hbl);

  return (
    <Grid container spacing={2} alignItems="center" justify="space-evenly">
      <Grid item xs={3}>
        <PortDisplay
          matIcon="anchor"
          label="POL"
          port={portToStringMaybe(hbl?.booking.logistics.pol)}
        >
          <PortDisplayDate label="ETD" date={hbl?.booking.logistics.polEtd} />
          <PortDisplayDate label="ATD" date={hbl?.booking.logistics.polAtd} />
        </PortDisplay>
      </Grid>

      <Grid item xs={3}>
        <PortDisplay
          matIcon="anchor"
          label="POD"
          port={portToStringMaybe(hbl?.booking.logistics.pod)}
        >
          <PortDisplayDate label="ETA" date={hbl?.booking.logistics.podEta} />
          <PortDisplayDate label="ATA" date={hbl?.booking.logistics.podAta} />
        </PortDisplay>
      </Grid>

      <Grid item xs={3}>
        <PortDisplay
          matIcon="train"
          label="Rail Ramp"
          port={portToStringMaybe(hbl?.booking.logistics.ramp)}
        >
          {hbl?.booking.logistics.rampAta ? (
            <PortDisplayDate
              label="ATA"
              date={hbl.booking.logistics.rampAta}
              tooltip={`ETA: ${formatDate(hbl.booking.logistics.rampEta) || 'N/A'}`}
            />
          ) : (
            <PortDisplayDate label="ETA" date={hbl?.booking.logistics.rampEta} />
          )}
        </PortDisplay>
      </Grid>

      <Grid item xs={3}>
        <PortDisplay
          matIcon="room"
          label="Delivery Location"
          port={hbl?.booking.logistics.deliveryLocation?.name ?? ''}
        >
          {hbl?.booking.logistics.deliveryAta ? (
            <PortDisplayDate
              label="ATA"
              date={hbl.booking.logistics.deliveryAta}
              tooltip={`ETA: ${formatDate(hbl.booking.logistics.deliveryEta) || 'N/A'}`}
            />
          ) : (
            <PortDisplayDate label="ETA" date={hbl?.booking.logistics.deliveryEta} />
          )}
        </PortDisplay>
      </Grid>
    </Grid>
  );
}
