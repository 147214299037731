import { Tab, Tabs, Typography, withStyles } from '@material-ui/core';
import { RouteComponentProps } from '@reach/router';
import { usePurchaseOrderQuery } from 'api/GQL_Types';
import Loading from 'app/Loading';
import ErrorMessage from 'components/ErrorMessage';
import React from 'react';
import { theme } from 'styles';
import { PoDetails } from './PoDetails';
import { PoHistory } from './PoHistory';
import { PoOrderLines } from './PoOrderLines';
import { fromGqlPurchaseOrderQuery, PoPageData } from './states';
import { Title } from './Title';

const PoTabs = withStyles({
  root: {
    backgroundColor: '#FFF',
    borderBottom: '1px solid #e8e8e8',
  },
  indicator: {
    backgroundColor: theme.palette.secondary.main,
    height: '4px',
  },
})(Tabs);

interface PoPageProps extends RouteComponentProps {
  poId?: string;
}

export default function PoPage(props: PoPageProps) {
  const [pageData, setPageData] = React.useState<PoPageData | null>(null);
  const [tabShown, setTabShown] = React.useState(0);

  const { loading, error, refetch } = usePurchaseOrderQuery({
    fetchPolicy: 'no-cache',
    variables: { poId: props.poId || '' },
    onCompleted(data) {
      setPageData(fromGqlPurchaseOrderQuery(data));
    },
  });

  return (
    <div>
      {pageData ?
        <Title data={pageData} reloadPoPageData={() => refetch()} />
        : null
      }
      {loading ? (
        <div>
          <Loading />
        </div>
      ) : error ? (
        <div>
          <ErrorMessage error={error + ''} />
        </div>
      ) : !pageData ? (
        <div>PO Not Found</div>
      ) : (
        <>
          <PoTabs
            value={tabShown}
            onChange={(e, v) => {
              setTabShown(v);
            }}
            variant="fullWidth"
          >
            <Tab
              label={<Typography variant="h3">Details</Typography>}
              style={{ borderBottom: '4px solid #888' }}
            />
            <Tab
              label={<Typography variant="h3">History</Typography>}
              style={{ borderBottom: '4px solid #888' }}
            />
          </PoTabs>
          <div style={{ paddingTop: theme.spacing(1) }}>
            {tabShown === 0 && (
              <div>
                <div>
                  <PoDetails data={pageData} />
                </div>
                <div>
                  <PoOrderLines data={pageData} />
                </div>
              </div>
            )}
            {tabShown === 1 && <PoHistory data={pageData} />}
          </div>
        </>
      )}
    </div>
  );
}
