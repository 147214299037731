import { Box } from '@material-ui/core';
import { RouteComponentProps } from '@reach/router';
import { usePurchaseOrdersQuery } from 'api/GQL_Types';
import ErrorMessage from 'components/ErrorMessage';
import { contentHeightAtom } from 'components/g-table/body-grid';
import { GTableColumn } from 'components/g-table/types';
import UWLTableV2 from 'components/g-table/UWLTableV2';
import Panel from 'components/Panel';
import SearchBar from 'components/SearchBar';
import TableExportWindowGroup from 'components/TableExportWindowGroup';
import React from 'react';
import { selector, useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import { genKey, newAtom } from 'recoil-utils/utils';
import { formatDate } from 'utils/Dates';
import { oneOrVarious } from './page/states';

const poSearchState = newAtom('');
const poListState = newAtom<PoFields[]>([]);

export const filteredPoListState = selector({
  key: genKey(),
  get: ({ get }) => {
    const searchField = get<string>(poSearchState);
    const pos = get(poListState);
    const lowerField = searchField.toLowerCase();

    if (searchField === '') {
      return pos;
    } else {
      return pos.filter((po) => po.filterKey.includes(lowerField));
    }
  },
});

interface PoFields {
  id: string;
  poNumber: { to: string; value: string };
  poDate: Date | null | undefined;
  supplierName: string;
  expectedCargoReadyDate: string;
  revisedCargoReadyDate: string;
  firstShipDate: string;
  lastShipDate: string;
  shipToLocation: string;
  orderLines: OrderLineFields[];
  open: boolean;

  filterKey: string; // The text rows can be filtered by with the quick search
}

const poColumns: GTableColumn<PoFields>[] = [
  { id: 'poNumber', label: 'PO', weight: 1, type: 'link' },
  { id: 'poDate', label: 'PO Date', weight: 1, type: 'date' },
  { id: 'supplierName', label: 'Supplier', weight: 1, type: 'string' },
  { id: 'expectedCargoReadyDate', label: 'Cgo Rdy Date', weight: 1, type: 'string' },
  { id: 'revisedCargoReadyDate', label: 'Rev Cgo Rdy Date', weight: 1, type: 'string' },
  { id: 'firstShipDate', label: 'First Ship', weight: 1, type: 'string' },
  { id: 'lastShipDate', label: 'Last Ship', weight: 1, type: 'string' },
  { id: 'shipToLocation', label: 'Ship to Loc', weight: 1, type: 'string' },
  { id: 'open', label: '', weight: 75, type: 'custom' },
];

const lineColumns: GTableColumn<OrderLineFields>[] = [
  { id: 'itemNumber', label: 'Item / SKU', weight: 1, type: 'string' },
  { id: 'description', label: 'Description', weight: 1, type: 'string' },
  { id: 'shipToLocation', label: 'Ship To Loc', weight: 1, type: 'custom' },
  { id: 'orderQty', label: 'Order Qty', weight: 1, type: 'number' },
  { id: 'balanceQty', label: 'Balance Qty', weight: 1, type: 'number' },
];

interface PoBrowserProps extends RouteComponentProps {}
export default function PoBrowser(props: PoBrowserProps) {
  const setPurchaseOrders = useSetRecoilState(poListState);
  const [searchField, setSearchField] = useRecoilState(poSearchState);
  const filteredPos = useRecoilValue(filteredPoListState);

  const { loading, error } = usePurchaseOrdersQuery({
    fetchPolicy: 'no-cache',
    onCompleted(posData) {
      const poList = posData.purchaseOrders
        .map((po): PoFields => {
          const supplier = po.relatedParties.find((party) => party.partyType === 'SUPPLIER');
          return {
            id: po.id,
            poNumber: { to: '/purchase-orders/' + po.id, value: po.poNumber },
            poDate: po.poDate,
            supplierName: supplier ? supplier.party.name : '',
            expectedCargoReadyDate: oneOrVarious(
              po.orderLines.map((ol) => formatDate(ol.expectedCargoReadyDate))
            ),
            revisedCargoReadyDate: oneOrVarious(
              po.orderLines.map((ol) => formatDate(ol.revisedCargoReadyDate))
            ),
            firstShipDate: oneOrVarious(po.orderLines.map((ol) => formatDate(ol.firstShipDate))),
            lastShipDate: oneOrVarious(po.orderLines.map((ol) => formatDate(ol.lastShipDate))),
            shipToLocation: oneOrVarious(po.orderLines.map((ol) => ol.shipToLocation?.name || '')),
            orderLines: po.orderLines.map((line): OrderLineFields => {
              return {
                id: line.id,
                itemNumber: line.itemNumber,
                description: line.itemDescription ?? '',
                shipToLocation: line.shipToLocation?.name ?? '',
                orderQty: line.openOrder.orderQty,
                balanceQty: line.openOrder.balanceQty,
              };
            }),
            open: false,
            filterKey: '', // will get set in the next pass
          };
        })
        .map((row) => {
          return {
            ...row,
            filterKey: [
              // Include all the text they can search by
              row.poNumber.value,
              formatDate(row.poDate),
              row.supplierName,
              row.expectedCargoReadyDate,
              row.revisedCargoReadyDate,
              row.firstShipDate,
              row.lastShipDate,
              row.shipToLocation,
            ]
              .join('||||')
              .toLowerCase(),
          };
        });

      setPurchaseOrders(poList);
    },
  });

  return (
    <Panel
      title="PO Browser"
      titleDecorator={
        <TableExportWindowGroup label="PurchaseOrders" rows={filteredPos} columns={poColumns} />
      }
      growPanel
      topRight={
        <Box width="25%" bgcolor="#F8F8F8" padding={1}>
          <SearchBar
            placeholder="Search by Purchase Order #"
            field={searchField}
            updateField={setSearchField}
          />
        </Box>
      }
    >
      <Box height="100%" paddingX={2} paddingBottom={2}>
        <ErrorMessage error={error ? error + '' : null} />
        <UWLTableV2
          debugId="Table"
          rows={filteredPos}
          isLoading={loading}
          columns={poColumns}
          emptyMessage="No Purchase Orders"
          expandedContent={{
            id: 'open',
            content: (row) => {
              return <TableContent rowId={row.id} orderLines={row.orderLines} />;
            },
          }}
        />
      </Box>
    </Panel>
  );
}

interface OrderLineFields {
  id: string;
  itemNumber: string;
  description: string;
  shipToLocation: string;
  orderQty: number;
  balanceQty: number;
}

interface TCProps {
  rowId: string;
  orderLines: OrderLineFields[];
}

function TableContent(props: TCProps) {
  const setContentHeight = useSetRecoilState(contentHeightAtom);

  return (
    <Box display="flex" flexDirection="column" padding="12px">
      <UWLTableV2
        columns={lineColumns}
        rows={props.orderLines}
        maxHeight={300}
        onHeightChange={(height) => {
          setContentHeight({ id: props.rowId, height: height < 300 ? height : 300 });
        }}
      />
    </Box>
  );
}
