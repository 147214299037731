import styled from 'styled-components';
import { Link, Router } from '@reach/router';
import { theme } from 'styles';

interface LineProps {
  color?: string; // specific theme color to be used as fill for this line
  height: number; // specifies the height of the line (stroke)
}
export const Line = styled.div((props: LineProps) => ({
  width: '100%',
  backgroundColor: props.color ? props.color : '#ccc',
  height: props.height ? props.height : '1px',
  margin: '8px 0',
}));

export const ManageContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  border-radius: 4px;
  background-color: #fff;
  box-shadow: rgb(0 0 0 / 20%) 0px 3px 1px -2px, rgb(0 0 0 / 14%) 0px 2px 2px 0px,
    rgb(0 0 0 / 12%) 0px 1px 5px 0px;
`;

export const ManageHeader = styled.div`
  display: flex;
  margin-top: 16px;
  margin-left: 24px;
`;

export const ManageContent = styled.div`
  display: flex;
  flex-grow: 1;
`;

export const ManageBody = styled.div`
  flex-grow: 1;
  padding: 12px 24px;
`;

interface MangeLinkProps {
  selected?: boolean;
}
export const ManageLink = styled(Link)<MangeLinkProps>`
  width: 100%;
  border-bottom: solid;
  text-align: center;
  color: ${(props) =>
    props.selected ? theme.palette.blueGreen.main : theme.palette.grayscale.main};
  text-decoration: none;
`;

interface FlexGrowRouterProps {
  background?: string;
}

export const FlexGrowRouter = styled(Router)<FlexGrowRouterProps>`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  background-color: ${(props) => (props.background ? props.background : undefined)};
`;

export const StretchFitRouter = styled(Router)`
  display: flex;
  flex-direction: column;
  height: 100%;
`;

export const DialogForm = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 12px;
`;
