import {
  NotificationCode,
  DashboardFeatureCode,
  LocationType,
  PermissionCode,
  RuleFieldType,
  SystemPermissionCode,
  useApiConstantsLazyQuery,
} from 'api/GQL_Types';
import { fromGQL_Dataset } from 'api/queries/reportQueries';
import { userContextAtom } from 'app';
import React from 'react';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { newAtom } from 'recoil-utils/utils';
import reportCategoriesStore from 'stores/reportCategoriesStore';
import { Dataset } from 'types/Dataset';
import {
  EnumInfo,
  EnumInfoCollection,
  makeEnumInfoCollection,
  makeEnumInfoCollectionFallback,
} from 'types/Enum';
import { NetworkContainerType } from 'types/NetworkContainerType';
import { NetworkDocumentType } from 'types/NetworkDocumentType';

export const AppWideStates = {
  permissions: newAtom<EnumInfoCollection<PermissionCode>>(
    makeEnumInfoCollectionFallback(Object.values(PermissionCode))
  ),

  systemPermissions: newAtom<EnumInfoCollection<SystemPermissionCode>>(
    makeEnumInfoCollectionFallback(Object.values(SystemPermissionCode))
  ),

  notificationCodes: newAtom<EnumInfoCollection<NotificationCode>>(
    makeEnumInfoCollectionFallback(Object.values(NotificationCode))
  ),

  locationTypes: newAtom<EnumInfoCollection<LocationType>>(
    makeEnumInfoCollectionFallback(Object.values(LocationType))
  ),

  ruleFieldTypes: newAtom<EnumInfoCollection<RuleFieldType>>(
    makeEnumInfoCollectionFallback(Object.values(RuleFieldType))
  ),

  dashboardFeatureCodes: newAtom<EnumInfoCollection<DashboardFeatureCode>>(
    makeEnumInfoCollectionFallback(Object.values(DashboardFeatureCode))
  ),

  network: {
    containerTypes: newAtom<{
      all: NetworkContainerType[];
      byCode: Map<string, NetworkContainerType>;
      codes: string[];
    }>({ all: [], byCode: new Map(), codes: [] }),

    documentTypes: newAtom<{
      all: NetworkDocumentType[];
      byCode: Map<string, NetworkDocumentType>;
    }>({ all: [], byCode: new Map() }),

    rules: newAtom<{
      bookingQtyToleranceEnabled: boolean;
      bookingQtyTolerancePercentOver: number;
      bookingQtyTolerancePercentUnder: number;
    }>({
      bookingQtyToleranceEnabled: false,
      bookingQtyTolerancePercentOver: 0,
      bookingQtyTolerancePercentUnder: 0,
    }),
  },

  datasets: newAtom<Dataset[]>([]),
};

export function useSetupApiConstants() {
  const userContext = useRecoilValue(userContextAtom);
  const setPermissions = useSetRecoilState(AppWideStates.permissions);
  const setSystemPermissions = useSetRecoilState(AppWideStates.systemPermissions);
  const setNotificationCodes = useSetRecoilState(AppWideStates.notificationCodes);
  const setLocationTypes = useSetRecoilState(AppWideStates.locationTypes);
  const setRuleFieldTypes = useSetRecoilState(AppWideStates.ruleFieldTypes);
  const setDashboardFeatureCodes = useSetRecoilState(AppWideStates.dashboardFeatureCodes);
  const setDocumentTypes = useSetRecoilState(AppWideStates.network.documentTypes);
  const setContainerTypes = useSetRecoilState(AppWideStates.network.containerTypes);
  const setNetworkRules = useSetRecoilState(AppWideStates.network.rules);
  const setDatasets = useSetRecoilState(AppWideStates.datasets);

  const [getConstants] = useApiConstantsLazyQuery({
    onCompleted(data) {
      setPermissions(
        makeEnumInfoCollection(
          data.permissions.map((permission): EnumInfo<PermissionCode> => {
            return {
              code: permission.code,
              group: permission.group,
              name: permission.name,
              description: permission.description,
            };
          })
        )
      );

      setSystemPermissions(
        makeEnumInfoCollection(
          data.systemPermissions.map((permission): EnumInfo<SystemPermissionCode> => {
            return {
              code: permission.code,
              group: permission.group,
              name: permission.name,
              description: permission.description,
            };
          })
        )
      );

      setNotificationCodes(
        makeEnumInfoCollection(
          data.notificationCodes.map((item): EnumInfo<NotificationCode> => {
            return {
              code: item.code,
              group: item.group,
              name: item.name,
              description: item.description,
            };
          })
        )
      );

      setLocationTypes(
        makeEnumInfoCollection(
          data.locationTypes.map((loc): EnumInfo<LocationType> => {
            return {
              code: loc.code,
              group: loc.group,
              name: loc.name,
              description: loc.description,
            };
          })
        )
      );

      setRuleFieldTypes(
        makeEnumInfoCollection(
          data.ruleFieldTypes.map((field): EnumInfo<RuleFieldType> => {
            return {
              code: field.code,
              group: field.group,
              name: field.name,
              description: field.description,
            };
          })
        )
      );

      setDashboardFeatureCodes(
        makeEnumInfoCollection(
          data.dashboardFeatureCodes.map((field): EnumInfo<DashboardFeatureCode> => {
            return {
              code: field.code,
              group: field.group,
              name: field.name,
              description: field.description,
            };
          })
        )
      );

      const allDocumentTypes: NetworkDocumentType[] = [];
      const documentTypesByCode = new Map<string, NetworkDocumentType>();
      for (const dt of data.network?.documentTypes || []) {
        allDocumentTypes.push(dt);
        documentTypesByCode.set(dt.code, dt);
      }
      setDocumentTypes({
        all: allDocumentTypes,
        byCode: documentTypesByCode,
      });

      const allContainerTypes: NetworkContainerType[] = [];
      const containerTypesByCode = new Map<string, NetworkContainerType>();
      for (const ct of data.network?.containerTypes || []) {
        allContainerTypes.push(ct);
        containerTypesByCode.set(ct.code, ct);
      }
      setContainerTypes({
        all: allContainerTypes,
        byCode: containerTypesByCode,
        codes: allContainerTypes.map((ct) => ct.code),
      });

      setNetworkRules({
        bookingQtyToleranceEnabled: data.network?.rules?.bookingQtyToleranceEnabled || false,
        bookingQtyTolerancePercentOver: data.network?.rules?.bookingQtyTolerancePercentOver || 0,
        bookingQtyTolerancePercentUnder: data.network?.rules?.bookingQtyTolerancePercentUnder || 0,
      });

      const datasets = data.datasets.map(fromGQL_Dataset);
      setDatasets(datasets);
      reportCategoriesStore.setDatasets(datasets);
    },
  });

  React.useEffect(() => {
    if (userContext) {
      getConstants();
    }
  }, [userContext]);
}

export function useLocationTypeNameLookup() {
  const locationTypes = useRecoilValue(AppWideStates.locationTypes);
  return (code: LocationType) => {
    const info = locationTypes.byCode.get(code);
    return info ? info.name : code + '';
  };
}
