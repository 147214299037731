import { Box, Button, Grid, Typography } from '@material-ui/core';
import { navigate, RouteComponentProps } from '@reach/router';
import {
  GqlProfile,
  PartyType,
  PermissionCode,
  useAssignHblRelatedPartyMutation,
  useHblLazyQuery,
  useHblQuery,
  useNetworkProfilesQuery,
  useNewHblRemarkMutation,
  useRemoveHblRelatedPartyMutation,
  useUpdateHblRelatedPartyMutation,
} from 'api/GQL_Types';
import { HblQuery } from 'api/queries/hblQueries';
import { userContextAtom } from 'app';
import { Document, DocumentsPanel } from 'app/components/DocumentsPanel';
import { HotBookingSwitch } from 'app/components/HotBookingSwitch';
import Loading from 'app/Loading';
import { HotState } from 'components/HotToggleSwitch';
import OverrideRelatedPartiesDialog from 'components/related-parties-override';
import Remarks, { Remark, sortRemarks } from 'components/Remarks';
import { useSnackbar } from 'notistack';
import React from 'react';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import { AtomGroupCleaner, newAtom } from 'recoil-utils/utils';
import HBLEquipment from './equipment';
import HBLDetailsPanel from './hbl-detail-panel';
import { HblPageData, HblPageStates } from './states';

const openRelatedPartiesDialog = newAtom(false);

interface Props extends RouteComponentProps {
  shipmentId?: string;
}

export default function HBLPage(props: Props) {
  const hblId = props.shipmentId ?? '';
  const userContext = useRecoilValue(userContextAtom);
  const [hbl, setHbl] = useRecoilState(HblPageStates.hbl);
  const [allRemarks, setAllRemarks] = useRecoilState(HblPageStates.allRemarks);
  const [allDocuments, setAllDocuments] = useRecoilState(HblPageStates.allDocuments);
  const { enqueueSnackbar } = useSnackbar();
  const setOpenOverrideDialog = useSetRecoilState(openRelatedPartiesDialog);

  const [assignHblRelatedParty] = useAssignHblRelatedPartyMutation();
  const [removeHblRelatedParty] = useRemoveHblRelatedPartyMutation();
  const [updateHblRelatedParty] = useUpdateHblRelatedPartyMutation();

  const [refetchHblQuery] = useHblLazyQuery({
    variables: { hblId: hblId },
    fetchPolicy: 'network-only',
    onCompleted: (data) => {
      if (data.hbl) {
        enqueueSnackbar('HBL Related Parties Updated!', {
          variant: 'success',
          anchorOrigin: {
            vertical: 'top',
            horizontal: 'right',
          },
        });
        setOpenOverrideDialog(false);
      }
    },
  });

  const { data: hblData } = useHblQuery({
    variables: { hblId: hblId },
    fetchPolicy: 'cache-and-network',
  });

  const [saveHblRemark] = useNewHblRemarkMutation({
    refetchQueries: () => [
      {
        query: HblQuery,
        variables: { hblId: hblId },
      },
    ],
    onCompleted(data) {
      if (data.newHblRemark.success) {
        enqueueSnackbar('New HBL Remark Created.', {
          variant: 'success',
          anchorOrigin: {
            vertical: 'top',
            horizontal: 'right',
          },
        });
      }
    },
  });

  const { data: profilesData } = useNetworkProfilesQuery({
    variables: { networkId: userContext?.activeContact?.profile.network.id ?? '' },
    fetchPolicy: 'network-only',
  });

  const [profileOptions, setProfileOptions] = React.useState<GqlProfile[]>([]);

  React.useEffect(() => {
    if (profilesData?.network) {
      setProfileOptions(profilesData.network.connectedProfiles as GqlProfile[]);
    }
  }, [profilesData]);

  React.useEffect(() => {
    if (hblData) {
      const hbl = hblData.hbl;
      if (!hbl) {
        navigate('/bookings');
      } else {
        if (hbl.id) {
          let remarks: Remark[] = [];
          remarks = remarks.concat(hbl?.booking.mbl?.remarks ?? []);
          remarks = remarks.concat(hbl?.booking.remarks ?? []);
          remarks = remarks.concat(hbl?.remarks ?? []);
          remarks = sortRemarks(remarks);
          setAllRemarks(remarks);

          let bookingDocuments: Document[] = [];
          let hblDocuments: Document[] = [];
          for (const doc of hbl.documents) {
            hblDocuments.push({
              id: doc.id,
              bookingId: '',
              bookingNumber: '',
              documentType: doc.documentType,
              fileName: doc.fileName,
              importFileName: doc.importFileName,
              fileType: doc.fileType,
              fileSize: doc.fileSize,
              uploadDate: doc.uploadDate,
              description: doc.description || '',
            });
          }
          for (const doc of hbl.booking.documents) {
            bookingDocuments.push({
              id: doc.id,
              bookingId: hbl.booking.id,
              bookingNumber: hbl.booking.referenceNumber,
              documentType: doc.documentType,
              fileName: doc.fileName,
              importFileName: doc.importFileName,
              fileType: doc.fileType,
              fileSize: doc.fileSize,
              uploadDate: doc.uploadDate,
              description: doc.description || '',
            });
          }
          const hotHbl: HotState = {
            isHot: hbl.booking.isHot,
            hotMarkedBy: hbl.booking.hotMarkedBy,
            hotMarkedTimestamp: hbl.booking.hotMarkedTimestamp
          };
          const hblObj = {...hbl, hot: hotHbl};
          setAllDocuments([...bookingDocuments, ...hblDocuments]);
          setHbl(hblObj as HblPageData);
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hblData]);

  if (!hbl) {
    return <Loading />;
  }

  return (
    <>
      <AtomGroupCleaner atomGroup={HblPageStates} />
      <Grid container alignItems="stretch" direction="column" spacing={2}>
        <Grid item xs={12}>
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            marginLeft={1}
            marginTop={1}
          >
            <Box display="flex" alignItems="center">
              <Typography variant="h2" color="primary">
                HBL: {hbl?.referenceNumber}
              </Typography>
              <HotBookingSwitch bookingId={hbl.booking.id} initialState={hbl.hot} showDetailsOnRight={true} />
            </Box>
            {userContext?.permissionCodes.has(PermissionCode.HblRelatedPartiesOverride) && (
              <>
                <Button
                  variant="contained"
                  color="primary"
                  size="large"
                  onClick={() => {
                    setOpenOverrideDialog(true);
                  }}
                >
                  Hbl Party Override
                </Button>
                <OverrideRelatedPartiesDialog
                  openState={openRelatedPartiesDialog}
                  relatedParties={hbl.relatedParties}
                  profileOptions={profileOptions}
                  onOverrideClicked={async (changes) => {
                    await Promise.all(
                      changes.delete.map((item) =>
                        removeHblRelatedParty({
                          variables: {
                            input: {
                              relatedPartyId: item.relationId,
                            },
                          },
                        })
                      )
                    );
                    await Promise.all(
                      changes.new.map((item) =>
                        assignHblRelatedParty({
                          variables: {
                            input: {
                              hblId: hblId,
                              profileId: item.profile ? item.profile.id : '',
                              partyType: item.partyType ?? PartyType.Related,
                            },
                          },
                        })
                      )
                    );
                    await Promise.all(
                      changes.update.map((item) =>
                        updateHblRelatedParty({
                          variables: {
                            input: {
                              hblId: hblId,
                              relatedPartyId: item.relationId,
                              profileId: item.profile ? item.profile.id : '',
                              partyType: item.partyType ?? PartyType.Related,
                            },
                          },
                        })
                      )
                    );
                    refetchHblQuery();
                  }}
                />
              </>
            )}
          </Box>
        </Grid>
        <Grid item xs={12}>
          <HBLDetailsPanel />
        </Grid>
        <Grid item xs={12}>
          <Remarks
            title="Remarks"
            allowComments
            remarks={allRemarks}
            makeRemark={(text) => {
              saveHblRemark({
                variables: {
                  input: {
                    hblId: hblId,
                    remark: {
                      text: text,
                    },
                  },
                },
              });
            }}
          />
        </Grid>
        <Grid item xs={12}>
          <HBLEquipment />
        </Grid>
        <Grid item xs={12}>
          <DocumentsPanel documents={allDocuments} showBookingNumber />
        </Grid>
      </Grid>
    </>
  );
}
