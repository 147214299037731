import React from 'react';
import GTable from '.';
import { CollapsableContent, GTableColumn, RenderCells } from './types';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';

interface Props<T> {
  columns: GTableColumn<T>[];
  rows: T[];
  onRowClick?: (row: T) => void;
  emptyMessage?: string;
  renderHeaderCell?: Partial<
    {
      [key in keyof T]: (row: T) => React.ReactNode;
    }
  >;
  renderHeaderCells?: RenderCells<T>;
  renderCell?: Partial<
    {
      [key in keyof T]: (row: T) => React.ReactNode;
    }
  >;
  renderCells?: RenderCells<T>;
  renderFooterCell?: Partial<
    {
      [key in keyof T]: (row: T) => React.ReactNode;
    }
  >;
  expandedContent?: CollapsableContent<T>;
  debugId?: string;
  isLoading?: boolean;
  onWidthEstimated?: (width: number) => void;
  linkInTab?: boolean;
  onHeightChange?: (height: number) => void;
  maxHeight?: number | string;
  height?: number | string;
}

export default function UWLTableV2<T extends { id: string }>(props: Props<T>) {
  const [tableHeight, setTableHeight] = React.useState(window.innerHeight);
  const headingLabels: Partial<
    {
      [key in keyof T]: string;
    }
  > = {};

  for (const col of props.columns) {
    headingLabels[col.id] = col.label;
  }

  React.useEffect(() => {
    if (props.rows) {
      for (const row of props.rows) {
        if (!row.id) {
          throw new Error('Data does not have an id prop');
        }
      }

      const widths: { [colId: string]: number } = {};
      const charWidth = 9;
      const headerCharWidth = 12;
      const rightPadding = 30;

      for (const column of props.columns) {
        widths[column.id] = column.weight;
        for (const r of props.rows) {
          if (!r[column.id]) {
            widths[column.id] = column.weight;
          } else if (column.type === 'date') {
            widths[column.id] = 10 * charWidth;
            break;
          } else if (column.type === 'string') {
            widths[column.id] =
              widths[column.id] < (r[column.id] as any).length * charWidth + rightPadding
                ? (r[column.id] as any).length * charWidth + rightPadding
                : widths[column.id];
            if (widths[column.id] > 250) {
              widths[column.id] = 250;
              break;
            }
          } else if (column.type === 'currency') {
            const val = (Math.round(((r[column.id] as any) + Number.EPSILON) * 100) / 100).toFixed(
              2
            );
            widths[column.id] =
              widths[column.id] < val.length * charWidth + charWidth * 2 + rightPadding
                ? val.length * charWidth + charWidth * 2 + rightPadding
                : widths[column.id];
          } else if (column.type === 'link') {
            if (Array.isArray(r[column.id])) {
              // multiple links
              let max = 0;
              for (const thing of r[column.id] as any) {
                max =
                  max < thing.value.length * charWidth + rightPadding
                    ? thing.value.length * charWidth + rightPadding
                    : max;
              }
              widths[column.id] = widths[column.id] < max ? max : widths[column.id];
            } else if ((r[column.id] as any).value) {
              widths[column.id] =
                widths[column.id] < (r[column.id] as any).value.length * charWidth + rightPadding
                  ? (r[column.id] as any).value.length * charWidth + rightPadding
                  : widths[column.id];
            }
          }
        }
        widths[column.id] =
          widths[column.id] < column.label.length * headerCharWidth + rightPadding
            ? column.label.length * headerCharWidth + rightPadding
            : widths[column.id];

        column.weight = widths[column.id];
      }
    }
  }, [props.rows, props.columns]);

  return (
    <div
      style={{
        height: props.maxHeight ? tableHeight : '100%',
        maxHeight: props.rows.length === 0 ? 110 : props.maxHeight ?? undefined,
      }}
    >
      <GTable
        debugId={props.debugId}
        noContentRenderer={props.emptyMessage}
        rows={props.rows}
        columns={props.columns}
        headingLabels={headingLabels}
        renderFooterCell={props.renderFooterCell}
        renderHeaderCell={props.renderHeaderCell}
        renderHeaderCells={props.renderHeaderCells}
        renderCell={props.renderCell}
        renderCells={props.renderCells}
        linkInTab={props.linkInTab}
        isLoading={props.isLoading}
        emptyMessage={props.emptyMessage}
        onHeightEstimated={(height, headerHeight) => {
          setTableHeight(height);
          if (props.onHeightChange) {
            props.onHeightChange(height);
          }
        }}
        expandable={
          props.expandedContent
            ? {
                id: props.expandedContent.id,
                controlRenderCell: (row) => {
                  return row._isCollapsed ? (
                    <div
                      style={{
                        cursor: 'pointer',
                        width: '100%',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                      }}
                    >
                      <KeyboardArrowDownIcon
                        onClick={() => {
                          row._toggleCollapse();
                        }}
                      />
                    </div>
                  ) : (
                    <div
                      style={{
                        cursor: 'pointer',
                        width: '100%',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                      }}
                    >
                      <KeyboardArrowUpIcon
                        onClick={() => {
                          row._toggleCollapse();
                        }}
                      />
                    </div>
                  );
                },
                content: props.expandedContent.content,
              }
            : undefined
        }
        headerStyles={{
          display: 'flex',
          borderBottom: '3px solid #0288d1',
          color: '#183041',
          fontWeight: 900,
          padding: '8px',
          backgroundColor: 'white',
          fontSize: '1rem',
          textTransform: 'uppercase',
        }}
        cellStyles={{
          display: 'flex',
          alignItems: 'center',
          padding: '8px',
          fontWeight: 400,
          whiteSpace: 'pre-wrap',
          lineHeight: 1.2,
          fontSize: '1rem',
          backgroundColor: 'white',
          color: 'black',
          borderBottom: '1px solid #ccc',
        }}
        footerStyles={{
          justifyContent: 'flex-start',
          display: 'flex',
          borderTop: '1px solid #ccc',
          fontWeight: 600,
          padding: '4px',
          fontSize: '1rem',
          backgroundColor: 'white',
        }}
      />
    </div>
  );
}
