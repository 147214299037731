import { Box, Button, Grid } from '@material-ui/core';
import Tooltip from '@material-ui/core/Tooltip';
import WarningIcon from '@material-ui/icons/Warning';
import { navigate, RouteComponentProps } from '@reach/router';
import { gqlClient } from 'api/ApolloClient';
import {
  GqlCancelImportMutationVariables,
  GqlConfirmImportMutationVariables,
  ImportFileStatus,
  PermissionCode,
  usePoImportFieldGroupsQuery,
  usePoImportFileQuery,
} from 'api/GQL_Types';
import { CancelImportMutation, ConfirmImportMutation } from 'api/queries/poUploadQueries';
import { auth } from 'app';
import { EditFields } from 'components/EditFields';
import FakeProgressBar from 'components/FakeProgressBar';
import UWLTableV2 from 'components/g-table/UWLTableV2';
import Panel from 'components/Panel';
import SearchBar from 'components/SearchBar';
import UniversalDialog from 'components/UniversalDialog';
import { useAsyncAction } from 'lib/useAsyncAction';
import React from 'react';
import { theme } from 'styles';
import { CancelUploadDialog } from './CancelUploadDialog';
import { ConfirmUploadDialog } from './ConfirmUploadDialog';
import { FileSummary } from './FileSummary';
import { reviewUploadStore } from './reviewUploadStore';
import { TableHeaderCell } from './TableHeaderCell';

interface Props extends RouteComponentProps {
  poFileId?: string;
}

export default function ReviewUploadPage(props: Props) {
  const { userContext } = auth.useAuthState();
  const [loadingView, setLoadingView] = React.useState<boolean>(true);
  const [showConfirmCancel, setShowConfirmCancel] = React.useState<boolean>(false);

  const actionConfirmImport = useAsyncAction(confirmImport);
  const actionCancelImport = useAsyncAction(cancelImport);

  const tableState = reviewUploadStore.use();
  const [searchField, setSearchField] = React.useState<string>('');

  const { data, loading } = usePoImportFileQuery({
    variables: { fileId: props.poFileId ?? '' },
    fetchPolicy: 'no-cache',
  });

  React.useEffect(() => {
    if (!loading && data && data.poImportFile === null) {
      navigate('/purchase-orders/uploads');
      return;
    }
    reviewUploadStore.setup(data);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  const { data: allOmsMappings } = usePoImportFieldGroupsQuery();
  const poImportFieldGroups = allOmsMappings?.poImportFieldGroups ?? [];

  const keysAlreadyMapped = tableState.status.headers
    .filter((h) => h.mapping)
    .map((h) => h.mapping?.key || '');

  const rowFilterQuery = searchField.toLowerCase().trim();
  let tableRows = tableState.tableRows;
  if (rowFilterQuery.length > 0) {
    tableRows = tableRows.filter((row) => row.rowFilterIndex.indexOf(rowFilterQuery) >= 0);
  }

  const allowedToMapFields = !!userContext?.permissionCodes.has(PermissionCode.PoImportMapFields);

  return (
    <Panel
      title="Review Upload"
      topRight={
        <Box display="flex" alignItems="center" width={'50%'}>
          <Box bgcolor="#F8F8F8" padding={1} flexGrow={1}>
            <SearchBar
              placeholder="Search by PO#, SKU #, etc."
              field={searchField}
              updateField={setSearchField}
            />
          </Box>
          <Box>
            <EditFields
              displayables={tableState.displayables}
              fields={tableState.fields}
              defaultFieldSelection={tableState.defaultFieldSelection}
              onChange={reviewUploadStore.setSelectedFields}
              disabled={loadingView}
            />
          </Box>
        </Box>
      }
    >
      {loadingView ? (
        <Box margin={2}>
          <FakeProgressBar
            start={loadingView}
            finished={!loading}
            onFinished={() => {
              setLoadingView(false);
            }}
            loadingText="Loading File..."
            finishText="Finishing Up..."
          />
        </Box>
      ) : (
        <>
          <Box paddingX={3}>
            <Grid container direction="row" justify="center" alignItems="center">
              <FileSummary fileStatusData={tableState.status} />
            </Grid>

            <Box height="500px">
              <UWLTableV2
                columns={tableState.tableColumns}
                rows={tableRows}
                isLoading={loadingView}
                emptyMessage="No Purchase Orders"
                renderHeaderCells={(col, _) => {
                  const header = tableState.headersByColId.get(col.id);
                  if (!header) {
                    return <div>{col.label}</div>;
                  }
                  return (
                    <TableHeaderCell
                      header={header}
                      poImportFieldGroups={poImportFieldGroups}
                      keysAlreadyMapped={keysAlreadyMapped}
                      fileStatusData={tableState.status}
                      setFileStatusData={reviewUploadStore.setFileStatusData}
                      allowedToMapFields={allowedToMapFields}
                    />
                  );
                }}
                renderCells={(col, row) => {
                  const cellValue = row[col.id];

                  const header = tableState.headersByColId.get(col.id);
                  if (header) {
                    const errors = tableState.cellErrors.get(`${header.headerIndex}-${row.id}`);
                    if (errors && errors.length > 0) {
                      return (
                        <div style={{ display: 'flex' }}>
                          {cellValue}{' '}
                          <Tooltip placement="top" arrow title={errors.join('\n')}>
                            <WarningIcon htmlColor="#ff4e4e" style={{ height: '20px' }} />
                          </Tooltip>
                        </div>
                      );
                    }
                  }

                  return cellValue;
                }}
              />
            </Box>

            <hr style={{ marginTop: '20px' }} />
            <Box display="flex" justifyContent="flex-end" flex="row" padding={1}>
              {userContext?.permissionCodes.has(PermissionCode.PoImportCancel) && (
                <Button
                  color="primary"
                  style={{ marginRight: '16px' }}
                  onClick={() => {
                    setShowConfirmCancel(true);
                  }}
                >
                  CANCEL
                </Button>
              )}
              {tableState.status.fileStatus === ImportFileStatus.Pending &&
              userContext?.permissionCodes.has(PermissionCode.PoImportConfirm) ? (
                <Button
                  onClick={() => {
                    actionConfirmImport.act({ fileId: props.poFileId || '' });
                  }}
                  variant="contained"
                  color="primary"
                >
                  CONTINUE UPLOAD
                </Button>
              ) : null}
            </Box>
          </Box>
        </>
      )}

      <ConfirmUploadDialog
        open={actionConfirmImport.called}
        onClose={actionConfirmImport.reset}
        finished={actionConfirmImport.finished}
        error={actionConfirmImport.error}
      />

      <CancelUploadDialog
        open={actionCancelImport.called}
        onClose={actionCancelImport.reset}
        finished={actionCancelImport.finished}
        error={actionCancelImport.error}
      />

      <UniversalDialog
        open={showConfirmCancel}
        title="Are you sure you want to cancel?"
        setClose={() => {
          setShowConfirmCancel(false);
        }}
      >
        <Box display="flex" justifyContent="center" marginTop={2}>
          <Button
            variant="contained"
            color="primary"
            size="large"
            onClick={() => {
              actionCancelImport.act({ fileId: props.poFileId || '' });
              setShowConfirmCancel(false);
            }}
          >
            Yes
          </Button>
          <Button
            variant="contained"
            color="default"
            size="large"
            style={{ height: '40px', marginLeft: theme.spacing(3) }}
            onClick={() => {
              setShowConfirmCancel(false);
            }}
          >
            No
          </Button>
        </Box>
      </UniversalDialog>
    </Panel>
  );
}

async function confirmImport(variables: GqlConfirmImportMutationVariables): Promise<void> {
  const res = await gqlClient.mutate({
    mutation: ConfirmImportMutation,
    variables,
  });
  if (!res.data.confirmImport.success) {
    throw new Error(res.data.confirmImport.message || 'Unexpected Error');
  }
}

async function cancelImport(variables: GqlCancelImportMutationVariables): Promise<void> {
  const res = await gqlClient.mutate({
    mutation: CancelImportMutation,
    variables,
  });
  if (!res.data.cancelImport.success) {
    throw new Error(res.data.cancelImport.message || 'Unexpected Error');
  }
}
