import { Box, PopoverActions, Typography } from '@material-ui/core';
import { GTableColumn } from 'components/g-table/types';
import UWLTableV2 from 'components/g-table/UWLTableV2';
import TableExportWindowGroup from 'components/TableExportWindowGroup';
import UniversalPopover from 'components/UniversalPopover';
import React from 'react';
import { MarkAllAsReadButton } from '../components/MarkAllAsReadButton';
import { MarkAsReadCell } from '../components/MarkAsReadCell';
import { BaseAlert, useMarkNotificationsAsRead } from './states';

const unreadCol: GTableColumn<BaseAlert>[] = [
  { id: 'unread', label: '!', type: 'custom', weight: 1 },
];

interface Props<T extends BaseAlert> {
  id: string;
  emptyMessage: string;
  headingLabel: string;
  exportLabel: string;
  totalLabel: string;
  anchorEl: any;
  openPopover: boolean;
  onClose: () => void;
  items: T[];
  columns: GTableColumn<T>[];
}

export default function AlertsPopover<T extends BaseAlert>(props: Props<T>) {
  const action: React.RefObject<PopoverActions> | null = React.useRef(null);
  const [tableHeight, setTableHeight] = React.useState(0);
  const markAsRead = useMarkNotificationsAsRead();

  React.useEffect(() => {
    if (action && action.current) {
      action.current.updatePosition();
    }
  }, [tableHeight]);

  return (
    <UniversalPopover
      popoverId={props.id}
      open={props.openPopover}
      anchorEl={props.anchorEl}
      action={action}
      onClose={() => {
        props.onClose();
      }}
      anchorOriginVertical={20}
      anchorOriginHorizontal={0}
    >
      <Box padding={3} width={window.innerWidth * 0.9} display="flex" flexDirection="column">
        <Box display="flex" justifyContent="space-between" marginBottom={2}>
          <Box marginRight={3} display="flex" alignItems="center">
            <Typography variant="h3">{props.headingLabel}</Typography>
            <TableExportWindowGroup
              label={props.exportLabel}
              rows={props.items}
              columns={props.columns}
            />
            <MarkAllAsReadButton
              onClick={() => {
                markAsRead(props.items.map((row) => row.id));
              }}
            />
          </Box>
          <Box marginLeft={3}>
            <Box display="flex" alignItems="center">
              <Box marginRight={1}>
                <Typography variant="h4">
                  <i>{props.totalLabel}:</i>
                </Typography>
              </Box>
              <Typography variant="h3">{props.items.length}</Typography>
            </Box>
          </Box>
        </Box>
        <UWLTableV2
          rows={props.items}
          columns={addUnreadCol(props.columns, props.items)}
          emptyMessage={props.emptyMessage}
          maxHeight={500}
          onHeightChange={(height) => {
            setTableHeight(height);
          }}
          renderCell={
            {
              unread(row: T) {
                return (
                  <MarkAsReadCell
                    isUnread={row.unread}
                    notificationId={row.id}
                    onClick={(notificationId) => {
                      markAsRead([notificationId]);
                    }}
                  />
                );
              },
            } as any
          }
        />
      </Box>
    </UniversalPopover>
  );
}

function addUnreadCol<T extends BaseAlert>(
  cols: GTableColumn<T>[],
  notifications: T[]
): GTableColumn<T>[] {
  if (notifications.length > 0) {
    return unreadCol.concat(cols as any);
  }
  return cols;
}
