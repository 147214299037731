import { faFire } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Box, Button, Typography } from '@material-ui/core';
import { Link } from '@reach/router';
import { GqlContainer, PermissionCode } from 'api/GQL_Types';
import { userContextAtom } from 'app';
import { GTableColumn } from 'components/g-table/types';
import UWLTableV2 from 'components/g-table/UWLTableV2';
import Panel from 'components/Panel';
import React from 'react';
import { useRecoilValue } from 'recoil';
import { roundNumberDecimalPlacesWithCommas } from 'utils/Numbers';
import { BookingPageStates } from '../states';
import EquipmentAssignmentDialog from './equipment-assignment-dialog';

const columns: GTableColumn<GqlContainer>[] = [
  { id: 'isHot', label: '', type: 'string', weight: 1 },
  { id: 'containerNumber', label: 'Container', type: 'string', weight: 1 },
  { id: 'containerType', label: 'Container Type', type: 'string', weight: 1 },
  { id: 'sealNumber', label: 'Seal', type: 'string', weight: 1 },
  { id: 'volume', label: 'Volume', type: 'number', weight: 1 },
  { id: 'weight', label: 'Weight', type: 'number', weight: 1 },
  { id: 'shipFromFactoryDate', label: 'Ship From Factory', type: 'date', weight: 1 },
  { id: 'terminalReceivedDate', label: "Terminal Rec'd", type: 'date', weight: 1 },
];
const columnsAlt: GTableColumn<GqlContainer>[] = [
  { id: 'containerNumber', label: 'Container', type: 'string', weight: 1 },
  { id: 'containerType', label: 'Container Type', type: 'string', weight: 1 },
  { id: 'sealNumber', label: 'Seal', type: 'string', weight: 1 },
  { id: 'volume', label: 'Volume', type: 'number', weight: 1 },
  { id: 'weight', label: 'Weight', type: 'number', weight: 1 },
  { id: 'shipFromFactoryDate', label: 'Ship From Factory', type: 'date', weight: 1 },
  { id: 'terminalReceivedDate', label: "Terminal Rec'd", type: 'date', weight: 1 },
];

interface Props {
  bookingId: string;
}
export default function BookingEquipment(props: Props) {
  const userContext = useRecoilValue(userContextAtom);
  const [open, setOpen] = React.useState(false);
  const booking = useRecoilValue(BookingPageStates.booking);

  return (
    <>
      <Panel
        title="Container/Equipment"
        topRight={
          userContext?.permissionCodes.has(PermissionCode.BookingContainerUpdate) && (
            <Button
              variant="contained"
              color="secondary"
              onClick={() => {
                setOpen(true);
              }}
            >
              Edit
            </Button>
          )
        }
      >
        <Box margin={2}>
          <UWLTableV2
            columns={hasHotContainers(booking?.containers ?? []) ? columns : columnsAlt}
            rows={booking?.containers ?? []}
            emptyMessage="No Containers Available"
            maxHeight={300}
            renderCell={{
              isHot(row) {
                return row.isHot ? (
                  <FontAwesomeIcon icon={faFire} style={{ color: 'orange' }} size="lg" />
                ) : null;
              },
              containerNumber(row) {
                return (
                  <>
                    {row.containerNumber ? (
                      <Link to={'/equipment/' + row.id}>{row.containerNumber}</Link>
                    ) : (
                      <Box>Not Assigned</Box>
                    )}
                  </>
                );
              },
              containerType(row) {
                return <Typography variant="body1">{row.containerType}</Typography>;
              },
              weight(row) {
                let totalWeight = 0;
                for (const van of row.vanPositions) {
                  totalWeight += van.weight ? van.weight : 0;
                }
                return (
                  <>
                    {row.vanPositions ? (
                      <Box
                        display="flex"
                        justifyContent="space-between"
                        alignItems="center"
                        width="120px"
                      >
                        <Typography variant="body1">
                          {roundNumberDecimalPlacesWithCommas(totalWeight, 2)}
                        </Typography>
                        <Box
                          component="span"
                          fontWeight="caption.fontWeight"
                          fontStyle="italic"
                          marginLeft={1}
                        >
                          KG
                        </Box>
                      </Box>
                    ) : (
                      <Box></Box>
                    )}
                  </>
                );
              },
              volume(row) {
                let totalVolume = 0;
                for (const van of row.vanPositions) {
                  totalVolume += van.volume ? van.volume : 0;
                }

                return (
                  <>
                    {row.vanPositions ? (
                      <Box
                        display="flex"
                        justifyContent="space-between"
                        alignItems="center"
                        width="100px"
                      >
                        <Typography variant="body1">
                          {roundNumberDecimalPlacesWithCommas(totalVolume, 2)}
                        </Typography>
                        <Box
                          component="span"
                          fontWeight="caption.fontWeight"
                          fontStyle="italic"
                          marginLeft={1}
                        >
                          M3
                        </Box>
                      </Box>
                    ) : (
                      <Box></Box>
                    )}
                  </>
                );
              },
            }}
          />
        </Box>
      </Panel>
      <EquipmentAssignmentDialog
        open={open}
        onClose={() => {
          setOpen(false);
        }}
      />
    </>
  );
}

function hasHotContainers(containers: GqlContainer[]) {
  return Boolean(containers.find((container) => container.isHot));
}
