import { IconButton } from '@material-ui/core';
import GetAppIcon from '@material-ui/icons/GetApp';
import OpenInNewIcon from '@material-ui/icons/OpenInNew';
import { openInNewTableWindow } from 'app/NewWindowTable';
import { GTableColumn, GTableColumnType } from 'components/g-table/types';
import React from 'react';
import { UWLTableColumn } from 'types/UWLTable';
import arrayToExcel from './ArrayToExcel';

export function uwlColToGTable<T>(old: UWLTableColumn<T>): GTableColumn<T> {
  let type: GTableColumnType = 'string';
  switch (old.type) {
    case 'currency':
      type = 'currency';
      break;
    case 'weight':
    case 'volume':
    case 'distance':
    case 'number':
      type = 'number';
      break;
    case 'date':
    case 'datetime':
      type = 'date';
      break;
    case 'link':
      type = 'link';
      break;
  }
  return {
    id: old.id,
    label: old.label,
    type,
    weight: 1,
  };
}

interface Props {
  label: string;
  columns: GTableColumn<any>[];
  rows: any[];
}

export default function TableExportWindowGroup(props: Props) {
  return (
    <>
      <IconButton
        size="small"
        onClick={() => {
          arrayToExcel(props.rows, props.label, props.columns);
        }}
      >
        <GetAppIcon />
      </IconButton>
      <IconButton
        size="small"
        onClick={() => {
          openInNewTableWindow(
            props.label.replace(/\s+/g, '-').toLowerCase(),
            props.columns,
            props.rows
          );
        }}
      >
        <OpenInNewIcon />
      </IconButton>
    </>
  );
}
