import { faFileInvoiceDollar } from '@fortawesome/free-solid-svg-icons';
import { Link } from '@reach/router';
import { PartyType } from 'api/GQL_Types';
import { markNotificationsAsRead } from 'api/queries/dashboardQueries';
import { AlertIconButton } from 'app/dashboard/components/AlertIconButton';
import { MarkAllAsReadButton } from 'app/dashboard/components/MarkAllAsReadButton';
import { GTableColumn } from 'components/g-table/types';
import TableExportWindowGroup from 'components/TableExportWindowGroup';
import { STANDARD_ROW_OVERALL_HEIGHT, UWLTable } from 'components/UWLTable/UWLTable';
import { useWindowSize } from 'lib/useWindowSize';
import React from 'react';
import { useRecoilState } from 'recoil';
import { isDate } from 'types/Date';
import { portToStringMaybe } from 'types/Port';
import { UWLTableColumn } from 'types/UWLTable';
import { formatDate } from 'utils/Dates';
import { formatNumber } from 'utils/Numbers';
import { ExceptionQueryResult } from '.';
import { MarkAsReadCell } from '../../components/MarkAsReadCell';
import { PopoverTable } from '../../components/PopoverTable';
import { DashboardPageStates, TableLink } from '../../states';

export interface LateBookingException {
  id: string;
  notificationId: string | null;
  isUnread: boolean;
  bookingId: string;
  bookingNumber: TableLink;
  bookingDate: Date | null | undefined;
  bookingStatus: string;
  crd: Date | null | undefined;
  revisedCRD: Date | null | undefined;
  shipper: string;
  origin: string;
  destination: string;

  lineId: string;
  linePoId: string;
  linePoNumber: TableLink;
  lineBookByDate: Date | null | undefined;
  lineItemNumber: string;
  lineExpectedCrd: Date | null | undefined;
}

export interface LateBookingExceptionGrouped {
  id: string;
  notificationId: string | null;
  isUnread: boolean;
  bookingNumber: TableLink;
  bookingDate: Date | null | undefined;
  bookingStatus: string;
  crd: Date | null | undefined;
  revisedCRD: Date | null | undefined;
  shipper: string;
  origin: string;
  destination: string;

  poNumbers: TableLink[];
  itemNumbers: string[];
  bookByDates: Date[];
  expectedCRDs: Date[];

  rows: LateBookingException[];
}

export function LateBookingException_toRow(
  exception: ExceptionQueryResult
): LateBookingException | null {
  if (exception.__typename !== 'LateBookingException') {
    return null;
  }
  const booking = exception.booking;
  const orderLine = exception.orderLine;

  let shipperName = '';
  booking.relatedParties.forEach((rp) => {
    if (rp.partyType === PartyType.Shipper) {
      shipperName = rp.party.name;
    }
  });

  return {
    id: booking.id + '|' + orderLine.id,
    notificationId: exception.notificationId || null,
    isUnread: exception.notificationId ? !exception.markedAsRead : false,

    bookingId: booking.id,
    bookingNumber: { to: '/bookings/' + booking.id, value: booking.referenceNumber },
    bookingDate: booking.createDate,
    bookingStatus: booking.status,
    crd: booking.cargoReadyDate,
    revisedCRD: booking.revisedCargoReadyDate,
    shipper: shipperName,
    origin: portToStringMaybe(booking.logistics.pol),
    destination: booking.logistics.finalDestination?.name || '',

    lineId: orderLine.id,
    linePoId: orderLine.purchaseOrder.id,
    linePoNumber: {
      to: '/purchase-orders/' + orderLine.purchaseOrder.id,
      value: orderLine.purchaseOrder.poNumber,
    },
    lineBookByDate: orderLine.bookByDate,
    lineItemNumber: orderLine.itemNumber,
    lineExpectedCrd: orderLine.expectedCargoReadyDate,
  };
}

export function LateBookingException_toGroupedRows(
  rows: LateBookingException[]
): LateBookingExceptionGrouped[] {
  const byBooking = new Map<string, LateBookingExceptionGrouped>();
  for (const row of rows) {
    let group = byBooking.get(row.bookingId);
    if (!group) {
      group = {
        id: row.id,
        notificationId: row.notificationId,
        isUnread: row.isUnread,
        bookingNumber: row.bookingNumber,
        bookingDate: row.bookingDate,
        bookingStatus: row.bookingStatus,
        crd: row.crd,
        revisedCRD: row.revisedCRD,
        shipper: row.shipper,
        origin: row.origin,
        destination: row.destination,

        poNumbers: [],
        itemNumbers: [],
        bookByDates: [],
        expectedCRDs: [],

        rows: [],
      };
    }
    group.rows.push(row);
    byBooking.set(row.bookingId, group);
  }

  const groups = Array.from(byBooking.values());
  for (const group of groups) {
    for (const row of group.rows) {
      if (!group.poNumbers.includes(row.linePoNumber)) {
        group.poNumbers.push(row.linePoNumber);
      }
      if (!group.itemNumbers.includes(row.lineItemNumber)) {
        group.itemNumbers.push(row.lineItemNumber);
      }
      if (
        isDate(row.lineBookByDate) &&
        !group.bookByDates.find((d) => formatDate(d) === formatDate(row.lineBookByDate))
      ) {
        group.bookByDates.push(row.lineBookByDate);
      }
      if (
        isDate(row.lineExpectedCrd) &&
        !group.expectedCRDs.find((d) => formatDate(d) === formatDate(row.lineExpectedCrd))
      ) {
        group.expectedCRDs.push(row.lineExpectedCrd);
      }
    }
  }

  return groups;
}

const columns: UWLTableColumn<LateBookingExceptionGrouped>[] = [
  { id: 'isUnread', label: '!', type: 'bool' },
  { id: 'bookingNumber', label: 'Booking #', type: 'link' },
  { id: 'bookingDate', label: 'Booking Date', type: 'date' },
  { id: 'bookByDates', label: 'Book by Date', type: 'date' },
  { id: 'bookingStatus', label: 'Booking Status', type: 'string' },
  { id: 'expectedCRDs', label: 'EXPECTED CGO RDY Date (PO)', type: 'date' },
  { id: 'crd', label: 'CGO RDY Date', type: 'date' },
  { id: 'revisedCRD', label: 'REV CGO RDY Date', type: 'date' },
  { id: 'poNumbers', label: 'PO #', type: 'link' },
  { id: 'itemNumbers', label: 'SKU#', type: 'string' },
  { id: 'shipper', label: 'Shipper', type: 'string' },
  { id: 'origin', label: 'Origin', type: 'string' },
  { id: 'destination', label: 'Destination', type: 'string' },
];

const displayColumnsUnread = columns.map((c) => c.id);
const displayColumnsAllRead = displayColumnsUnread.filter((id) => id !== 'isUnread');

const exportColumns: GTableColumn<LateBookingException>[] = [
  { id: 'bookingNumber', label: 'Booking #', type: 'link', weight: 1 },
  { id: 'bookingDate', label: 'Booking Date', type: 'date', weight: 1 },
  { id: 'lineBookByDate', label: 'Book by Date', type: 'date', weight: 1 },
  { id: 'bookingStatus', label: 'Booking Status', type: 'string', weight: 1 },
  { id: 'lineExpectedCrd', label: 'EXPECTED CGO RDY Date (PO)', type: 'date', weight: 1 },
  { id: 'crd', label: 'CGO RDY Date', type: 'date', weight: 1 },
  { id: 'revisedCRD', label: 'REV CGO RDY Date', type: 'date', weight: 1 },
  { id: 'linePoNumber', label: 'PO #', type: 'link', weight: 1 },
  { id: 'lineItemNumber', label: 'SKU#', type: 'string', weight: 1 },
  { id: 'shipper', label: 'Shipper', type: 'string', weight: 1 },
  { id: 'origin', label: 'Origin', type: 'string', weight: 1 },
  { id: 'destination', label: 'Destination', type: 'string', weight: 1 },
];

interface Props {}

export const LateBookingExceptionCmpt: React.FC<Props> = (props) => {
  const [open, setOpen] = React.useState(false);
  const refAnchor = React.useRef<HTMLDivElement>(null);
  const windowSize = useWindowSize();

  const [exceptions, setExceptions] = useRecoilState(DashboardPageStates.exceptions);
  const unreadCount = exceptions.bookingLateGrouped.filter((row) => row.isUnread).length;

  return (
    <>
      <AlertIconButton
        innerRef={refAnchor}
        name="Late Booking"
        unreadCount={unreadCount}
        resultCount={exceptions.bookingLateGrouped.length}
        faIcon={faFileInvoiceDollar}
        onClick={() => setOpen(true)}
      />

      <PopoverTable
        anchorEl={refAnchor.current}
        open={open}
        onClose={() => setOpen(false)}
        title="Late Booking"
        titleRight={
          <>
            <TableExportWindowGroup
              label="Late Booking"
              rows={exceptions.bookingLate}
              columns={exportColumns}
            />
            {unreadCount > 0 && (
              <MarkAllAsReadButton
                onClick={() => {
                  const unreadAlertIds: string[] = [];
                  for (const row of exceptions.bookingLate) {
                    if (row.isUnread && row.notificationId) {
                      unreadAlertIds.push(row.notificationId);
                    }
                  }
                  markAsRead(unreadAlertIds);
                }}
              />
            )}
          </>
        }
        totalLabel="Total Late Booking"
        totalValue={formatNumber(exceptions.bookingLateGrouped.length)}
        width={windowSize.width * 0.8}
        bodyHeight={exceptions.bookingLateGrouped.length * STANDARD_ROW_OVERALL_HEIGHT}
      >
        {open && (
          <UWLTable
            rowId={'id'}
            rows={exceptions.bookingLateGrouped}
            columns={columns}
            columnsDisplay={unreadCount > 0 ? displayColumnsUnread : displayColumnsAllRead}
            emptyMessage="No Late Booking"
            virtualize={'single-line-cells'}
            renderCell={{
              isUnread(row) {
                return (
                  <MarkAsReadCell
                    isUnread={row.isUnread}
                    notificationId={row.notificationId}
                    onClick={(notificationId) => {
                      markAsRead([notificationId]);
                    }}
                  />
                );
              },
              poNumbers(row) {
                switch (row.poNumbers.length) {
                  case 0:
                    return '';
                  case 1:
                    return <Link to={row.poNumbers[0].to}>{row.poNumbers[0].value}</Link>;
                }
                return 'Various';
              },
              itemNumbers(row) {
                switch (row.itemNumbers.length) {
                  case 0:
                    return '';
                  case 1:
                    return row.itemNumbers[0];
                }
                return 'Various';
              },
              bookByDates(row) {
                switch (row.bookByDates.length) {
                  case 0:
                    return '';
                  case 1:
                    return formatDate(row.bookByDates[0]);
                }
                return 'Various';
              },
              expectedCRDs(row) {
                switch (row.expectedCRDs.length) {
                  case 0:
                    return '';
                  case 1:
                    return formatDate(row.expectedCRDs[0]);
                }
                return 'Various';
              },
            }}
          />
        )}
      </PopoverTable>
    </>
  );

  function markAsRead(notificationIds: string[]) {
    markNotificationsAsRead(notificationIds);
    setExceptions((e) => {
      return {
        ...e,
        bookingLate: e.bookingLate.map((d) => {
          if (notificationIds.includes(d.notificationId || '')) {
            return { ...d, isUnread: false };
          }
          return d;
        }),
      };
    });
  }
};
