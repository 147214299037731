import { Box, Button, Typography } from '@material-ui/core';
import Panel from 'components/Panel';
import { Line } from 'components/StyledComponents';
import { UWLTable } from 'components/UWLTable/UWLTable';
import React from 'react';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import { UWLTableColumn } from 'types/UWLTable';
import { formatNumberDecimalPlaces } from 'utils/Numbers';
import { ConsolidationBookingsTV, NewConsolidationStates } from '../states';
import BookingsFiltersPopover from './filters/BookingsFiltersPopover';

const bookingsHeaders: UWLTableColumn<ConsolidationBookingsTV>[] = [
  { id: 'referenceNumber', label: 'Booking', type: 'link', whiteSpace: 'nowrap' },
  { id: 'supplierName', label: 'Supplier', type: 'string', whiteSpace: 'nowrap' },
  { id: 'bookingDate', label: 'Booking Date', type: 'date' },
  { id: 'weight', label: 'Wgt', type: 'number', align: 'left' },
  { id: 'volume', label: 'CBM', type: 'number', align: 'left' },
  { id: 'bookingStatus', label: 'Booking Status', type: 'string', whiteSpace: 'nowrap' },
  { id: 'cargoReadyDate', label: 'Cgo Rdy Date', type: 'date' },
  { id: 'pol', label: 'POL', type: 'string', whiteSpace: 'nowrap' },
  { id: 'polEtd', label: 'ETD', type: 'date' },
  { id: 'pod', label: 'POD', type: 'string', whiteSpace: 'nowrap' },
  { id: 'finalDestinationName', label: 'Final Destination', type: 'string', whiteSpace: 'nowrap' },
  { id: 'moveType', label: 'Delivery Type', type: 'string', whiteSpace: 'nowrap' },
  { id: 'containers', label: 'Eqpt Type', type: 'string', whiteSpace: 'nowrap' },
];

export default function BookingsTable() {
  const filteredBookings = useRecoilValue(NewConsolidationStates.step1.filteredBookings);
  const [bookingsChecked, setBookingsChecked] = useRecoilState(
    NewConsolidationStates.step1.bookingsChecked
  );
  const setSelectedBookings = useSetRecoilState(NewConsolidationStates.step1.selectedBookings);
  const filters = useRecoilValue(NewConsolidationStates.filters.filtersLength);
  const [anchorEl, setAnchorEl] = React.useState<(EventTarget & HTMLElement) | null>(null);
  const [openPopover, setOpenPopover] = React.useState(false);

  React.useEffect(() => {
    if (bookingsChecked) {
      setSelectedBookings(filteredBookings.filter((select) => bookingsChecked.includes(select.id)));
    } else {
      setSelectedBookings([]);
    }
  }, [bookingsChecked]);

  return (
    <Panel
      title="Bookings"
      topRight={
        <Button
          variant="contained"
          color="secondary"
          onClick={(e) => {
            setOpenPopover(true);
            setAnchorEl(e.currentTarget);
          }}
        >
          Filters ({filters})
        </Button>
      }
      growPanel
    >
      <Line height={1} />
      <Box height="100%" paddingX={2} paddingBottom={2}>
        <UWLTable
          rowId="id"
          columns={bookingsHeaders}
          rows={filteredBookings}
          emptyMessage="- No Bookings -"
          virtualize={{
            bufferHeight: 400,
            rowHeight(row) {
              return 24;
            },
          }}
          checkboxes={{
            checked: bookingsChecked,
            setChecked: setBookingsChecked,
          }}
          renderCell={{
            volume(row) {
              return (
                <>
                  {row.volume ? (
                    <Typography variant="body1">
                      {formatNumberDecimalPlaces(row.volume, 0, 2)}
                      <Box
                        component="span"
                        fontWeight="caption.fontWeight"
                        fontStyle="italic"
                        marginLeft={1}
                      >
                        M3
                      </Box>
                    </Typography>
                  ) : (
                    <Box>0</Box>
                  )}
                </>
              );
            },
            weight(row) {
              return (
                <>
                  {row.weight ? (
                    <Typography variant="body1">
                      {formatNumberDecimalPlaces(row.weight, 0, 2)}
                      <Box
                        component="span"
                        fontWeight="caption.fontWeight"
                        fontStyle="italic"
                        marginLeft={1}
                      >
                        KG
                      </Box>
                    </Typography>
                  ) : (
                    <Box>0</Box>
                  )}
                </>
              );
            },
          }}
        />
      </Box>
      <BookingsFiltersPopover
        anchorEl={anchorEl}
        openPopover={openPopover}
        onClose={() => {
          setAnchorEl(null);
          setOpenPopover(false);
        }}
      />
    </Panel>
  );
}
