import { Grid, Typography } from '@material-ui/core';
import { Link } from '@reach/router';
import { PermissionCode } from 'api/GQL_Types';
import { auth } from 'app';
import LineItem from 'components/LineItem';
import { useRecoilValue } from 'recoil';
import { mapMoveType } from 'utils/Enums';
import { HblPageStates } from '../states';

export default function HBLLineItems() {
  const { userContext } = auth.useAuthState();
  const hbl = useRecoilValue(HblPageStates.hbl);

  return (
    <Grid container spacing={3}>
      <Grid item xs={12} md={6} lg={4}>
        <LineItem
          label="MBL"
          value={hbl?.booking.mbl?.referenceNumber}
          customValue={(value: any) => {
            return userContext?.permissionCodes.has(PermissionCode.MblRead) ? (
              <Link to={'/mbl/' + hbl?.booking.mbl?.id} state={{ bookingId: hbl?.booking.id }}>
                {value}
              </Link>
            ) : (
              <Typography variant="body1">{value}</Typography>
            );
          }}
        />
        <LineItem label="Carrier" value={hbl?.booking.logistics.carrier?.name} />
        <LineItem label="Vessel" value={hbl?.booking.logistics.motherVessel?.name} />
        <LineItem label="Voyage" value={hbl?.booking.logistics.motherVoyage?.name} />
        <LineItem label="HTS" value="" />
      </Grid>
      <Grid item xs={12} md={6} lg={4}>
        <LineItem
          label="Booking #"
          value={hbl?.booking.id}
          customValue={() => {
            return <Link to={'/bookings/' + hbl?.booking.id}>{hbl?.booking.referenceNumber}</Link>;
          }}
        />
        <LineItem label="Contract Type" value={hbl?.booking.logistics.contractType} />
        <LineItem
          label="Delivery Type"
          value={hbl?.booking.logistics.moveType ? mapMoveType(hbl.booking.logistics.moveType) : ''}
        />
        <LineItem label="Payment Type" value={hbl?.paymentType} />
        <LineItem label="Description" value={hbl?.description} />
      </Grid>
      <Grid item xs={12} md={6} lg={4}>
        <LineItem label="Contract" value={hbl?.booking.logistics.contractNumber} />
        <LineItem label="Container Count" value={hbl?.containerCount} />
        <LineItem label="Total Pieces" value={hbl?.totalPieces} />
        <LineItem label="Total Cartons" value={hbl?.totalCartons} />
        <LineItem label="Release Type" value={hbl?.releaseType} />
      </Grid>
    </Grid>
  );
}
