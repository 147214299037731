import { gql } from '@apollo/client/core';
import { gqlClient } from 'api/ApolloClient';
import { downloadFile } from 'api/download';
import {
  GqlDeleteReportMutation,
  GqlDeleteReportMutationVariables,
  GqlFragment_DatasetFilterFragment,
  GqlFragment_DatasetFragment,
  GqlFragment_ReportFragment,
  GqlPutReportMutation,
  GqlPutReportMutationVariables,
  GqlReportInput,
  GqlReportQuery,
  GqlReportQueryVariables,
  GqlReportsQuery,
  GqlReportsQueryVariables,
  GqlSetReportScheduledMutation,
  GqlSetReportScheduledMutationVariables,
  ReportsDocument,
} from 'api/GQL_Types';
import * as moment from 'moment-timezone';
import { Dataset, DatasetFilter, DatasetRequest } from 'types/Dataset';
import { Report } from 'types/Report';

export const DatasetFragment = gql`
  fragment fragment_Dataset on Dataset {
    id
    name
    isReport
    isFinancial
    isRootAdminOnly
    filterables {
      field
      label
      type
      options {
        value
        label
      }
      required
    }
    displayables {
      field
      label
    }
    sortables {
      field
      label
    }
    defaultDisplayables
  }
`;

export function fromGQL_Dataset(data: GqlFragment_DatasetFragment): Dataset {
  const dataset: Dataset = {
    ...data,
    displayableByField: {},
  };
  for (const d of data.displayables) {
    dataset.displayableByField[d.field] = d;
  }
  dataset.filterables.sort((a, b) => {
    return a.label > b.label ? 1 : a.label < b.label ? -1 : 0;
  });
  return dataset;
}

export const DatasetFilterFragment = gql`
  fragment fragment_DatasetFilter on DatasetFilter {
    field
    value
    applied
  }
`;

export function fromGQL_DatasetFilter(filter: GqlFragment_DatasetFilterFragment): DatasetFilter {
  return {
    field: filter.field,
    value: typeof filter.value === 'string' ? filter.value : null,
    applied: !!filter.applied,
  };
}

export const ReportFragment = gql`
  fragment fragment_Report on Report {
    id
    profileId
    name

    scheduled
    period
    weekdays
    days
    timeHour
    timeMinute
    timezone

    datasetId
    fields
    fieldAliases {
      field
      name
    }
    filters {
      ...fragment_DatasetFilter
    }

    recipientContactIds
    recipientEmails

    nextRun
    lastRun
    lastError
  }
  ${DatasetFilterFragment}
`;

export function fromGQL_Report(report: GqlFragment_ReportFragment): Report {
  const fieldAliases: { [field: string]: string } = {};
  for (const alias of report.fieldAliases) {
    fieldAliases[alias.field] = alias.name;
  }

  return {
    ...report,
    period: report.period === 'monthly' ? 'monthly' : 'weekly',
    weekdays: report.weekdays as any,
    fieldAliases,
    filters: report.filters.map(fromGQL_DatasetFilter),
  };
}

export const ReportsQuery = gql`
  query Reports {
    reports {
      ...fragment_Report
    }
  }
  ${ReportFragment}
`;

export function getReports(): Promise<Report[]> {
  return gqlClient
    .query<GqlReportsQuery, GqlReportsQueryVariables>({
      query: ReportsDocument,
      fetchPolicy: 'no-cache',
    })
    .then((data) => data.data.reports.map(fromGQL_Report));
}

export const ReportQuery = gql`
  query Report($id: ID!) {
    report(id: $id) {
      ...fragment_Report
    }
  }
  ${ReportFragment}
`;

export function getReport(id: string): Promise<Report> {
  return gqlClient
    .query<GqlReportQuery, GqlReportQueryVariables>({
      query: ReportQuery,
      fetchPolicy: 'no-cache',
      variables: { id },
    })
    .then((data) => {
      return fromGQL_Report(data.data.report);
    });
}

export const DeleteReportMutation = gql`
  mutation deleteReport($id: ID!) {
    deleteReport(id: $id)
  }
`;

export const PutReportMutation = gql`
  mutation putReport($report: ReportInput!) {
    putReport(report: $report) {
      ...fragment_Report
    }
  }
  ${ReportFragment}
`;

export async function saveReport(report: GqlReportInput): Promise<Report> {
  const res = await gqlClient.mutate<GqlPutReportMutation, GqlPutReportMutationVariables>({
    mutation: PutReportMutation,
    fetchPolicy: 'no-cache',
    variables: { report },
  });
  if (!res.data) {
    throw new Error('Failed to Save Report');
  }
  return fromGQL_Report(res.data.putReport);
}

export async function deleteReport(reportId: string): Promise<void> {
  await gqlClient.mutate<GqlDeleteReportMutation, GqlDeleteReportMutationVariables>({
    mutation: DeleteReportMutation,
    variables: { id: reportId },
    fetchPolicy: 'no-cache',
  });
}

export const SetReportScheduledMutation = gql`
  mutation setReportScheduled($id: ID!, $scheduled: Boolean!) {
    setReportScheduled(id: $id, scheduled: $scheduled) {
      ...fragment_Report
    }
  }
  ${ReportFragment}
`;

export async function setReportScheduled(reportId: string, scheduled: boolean): Promise<Report> {
  const res = await gqlClient.mutate<
    GqlSetReportScheduledMutation,
    GqlSetReportScheduledMutationVariables
  >({
    mutation: SetReportScheduledMutation,
    variables: { id: reportId, scheduled },
    fetchPolicy: 'no-cache',
  });
  if (!res.data) {
    throw new Error('Unable to setReportScheduled');
  }
  return fromGQL_Report(res.data.setReportScheduled);
}

export async function apiDownloadReport(reportId: string, file_type: string): Promise<void> {
  return downloadFile(`report/download/${reportId}/${file_type}`, null, `report.${file_type}`);
}

export async function apiDownloadDynamicReport(
  file_type: 'xlsx' | 'pdf',
  data: DatasetRequest
): Promise<void> {
  return downloadFile(
    `report/download-dynamic/${file_type}`,
    {
      ...data,
      timezone: data.timezone || moment.tz.guess(),
    },
    `report.${file_type}`
  );
}
