import React from 'react';
import { Box, InputAdornment, TextField, Typography } from '@material-ui/core';
import { useRecoilValue } from 'recoil';
import { OpenLineItem, BookingPageStates } from 'app/bookings/details/states';
import UWLTableV2 from 'components/g-table/UWLTableV2';
import { GTableColumn } from 'components/g-table/types';

const columns: GTableColumn<OpenLineItem>[] = [
  { id: 'lineNumber', label: 'Line', type: 'number', weight: 150 },
  { id: 'orderNumber', label: 'PO', type: 'string', weight: 150 },
  { id: 'itemNumber', label: 'Item / Sku', type: 'string', weight: 150 },
  { id: 'itemDescription', label: 'Description', type: 'string', weight: 150 },
  { id: 'shippedQty', label: 'Shipped QTY', type: 'number', weight: 150 },
  { id: 'quantityLeft', label: 'Balance Qty', type: 'number', weight: 150 },
  { id: 'shippedCartons', label: 'Shipped CTNS', type: 'number', weight: 150 },
  { id: 'ctnsLeft', label: 'Balance CTNS', type: 'number', weight: 150 },
  { id: 'volume', label: 'Volume', type: 'number', weight: 150 },
  { id: 'weight', label: 'Weight', type: 'number', weight: 150 },
];

interface Props {}
export default function OpenItemsTable(props: Props) {
  const openBookingPackLines = useRecoilValue(
    BookingPageStates.containerAssignment.editContainer.availableBookingPackLines
  );

  return (
    <>
      <Box display="flex" marginY={1}>
        <Typography variant="h3" color="secondary">
          OPEN ITEMS
        </Typography>
      </Box>
      <Box margin={2} maxHeight={'25vh'} overflow="auto">
        <UWLTableV2
          columns={columns}
          rows={openBookingPackLines}
          emptyMessage="No Items Available"
          maxHeight={'25vh'}
          renderCell={{
            volume(row) {
              return (
                <TextField
                  value={row.volume.toFixed(2)}
                  disabled
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <Typography variant="caption">M3</Typography>
                      </InputAdornment>
                    ),
                    disableUnderline: true,
                    style: {
                      color: '#333',
                      maxWidth: '100px',
                    },
                  }}
                  inputProps={{
                    style: {
                      textAlign: 'end',
                      marginRight: '-16px',
                    },
                  }}
                  type="number"
                />
              );
            },
            weight(row) {
              return (
                <TextField
                  value={row.weight.toFixed(2)}
                  disabled
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <Typography variant="caption">KG</Typography>
                      </InputAdornment>
                    ),
                    disableUnderline: true,
                    style: {
                      color: '#333',
                      maxWidth: '100px',
                    },
                  }}
                  inputProps={{
                    style: {
                      textAlign: 'end',
                      marginRight: '-16px',
                    },
                  }}
                  type="number"
                />
              );
            },
          }}
        />
      </Box>
    </>
  );
}
