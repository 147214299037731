import { API_URL, STATIC_URL } from 'env';
import { getAuthorizationHeader } from './Auth';

export async function downloadStaticFile(
  filename: string,
  importFileName: string,
  fileExtension: string
) {
  await fetch(STATIC_URL + filename + fileExtension, {
    method: 'GET',
  })
    .then((response) => response.blob())
    .then((blob) => {
      return blob;
    })
    .then((blob) => {
      const url = URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;
      a.download = importFileName + fileExtension;
      a.click();
    });
}

export async function downloadFile(
  route: string,
  data: any = null,
  fallbackFileName: string = 'download'
) {
  const req: RequestInit = {
    method: 'POST',
    headers: new Headers({
      Authorization: getAuthorizationHeader(),
    }),
  };

  if (data !== null && data !== undefined) {
    req.body = JSON.stringify(data);
    (req.headers as Headers).append('Content-Type', 'application/json');
  }

  let res: Response;
  try {
    res = await fetch(API_URL + route, req);
  } catch (err) {
    throw new Error('Network error: ' + err);
  }

  if (res.status !== 200) {
    let errMessage = 'Failed to download';
    try {
      const json = await res.json();
      if (
        json &&
        json.error &&
        typeof json.error.message === 'string' &&
        json.error.message.trim().length > 0
      ) {
        errMessage = json.error.message;
      }
    } catch (err) {}
    throw new Error(errMessage);
  }

  // Make sure the api CORS policy exposes this header
  const filename = parseFileName(res.headers.get('content-disposition') || '') || fallbackFileName;
  const blob = await res.blob();

  if (window.navigator && window.navigator.msSaveOrOpenBlob) {
    // IE11 and Edge
    window.navigator.msSaveOrOpenBlob(blob, filename);
  } else {
    const url = window.URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = url;
    link.download = filename;
    document.body.appendChild(link);
    link.click();

    setTimeout(() => {
      // Wait 1 second before cleaning up the link. Some browsers won't download if you cleanup too fast
      link.remove();
      window.URL.revokeObjectURL(url);
    }, 1000);
  }
}

export async function downloadDocuments(documentIds: string[]) {
  const res = await fetch(API_URL + 'download/documents', {
    method: 'POST',
    headers: {
      Authorization: getAuthorizationHeader(),
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({ document_ids: documentIds }),
  });

  const fallbackFileName = 'file';

  // Make sure the api CORS policy exposes this header
  const filename = parseFileName(res.headers.get('content-disposition') || '') || fallbackFileName;
  const blob = await res.blob();

  if (window.navigator && window.navigator.msSaveOrOpenBlob) {
    // IE11 and Edge
    window.navigator.msSaveOrOpenBlob(blob, filename);
  } else {
    const url = window.URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = url;
    link.download = filename;
    document.body.appendChild(link);
    link.click();

    setTimeout(() => {
      // Wait 1 second before cleaning up the link. Some browsers won't download if you cleanup too fast
      link.remove();
      window.URL.revokeObjectURL(url);
    }, 1000);
  }
}

function parseFileName(disposition: string): string | null {
  const m = /filename[^;=\n]*=(?:(\\?['"])(.*?)\1|(?:[^\s]+'.*?')?([^;\n]*))/i.exec(disposition);
  if (!m) return null;
  const txt = m[2] || m[3];
  if (!txt) return null;
  return txt.replace(/[^a-z0-9#& .,_-]+/gi, ' ').replace(/\s+/g, ' ');
}
