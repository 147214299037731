import { Box } from '@material-ui/core';
import ContainerInformation from './container-information';
import ContainerItemList from './items';
import OpenItemsTable from './open-items';
import SubmitFooter from './SubmitFooter';

export default function EditEquipment() {
  return (
    <Box height="100%" display="flex" flexDirection="column">
      <Box flexGrow={1} display="flex" flexDirection="column" overflow="auto">
        <ContainerInformation />
        <ContainerItemList />
        <OpenItemsTable />
      </Box>
      <SubmitFooter />
    </Box>
  );
}
