import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null | undefined;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
const defaultOptions =  {}
/** All built-in and custom scalars, mapped to their actual values */
export interface Scalars {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  CountryCode: string;
  /**
   * A date by itself.
   * example: "2021-12-25"
   */
  Date: Date | null;
  /**
   * A date + time representation independent of timezone.
   * It does not represent a moment in time, it rather represents a date+time a user wrote down as if on a piece of paper.
   * It's intended the client will display this the same regardless of their timezone.
   */
  DateTime: Date | null;
  /**
   * This is a moment in time. Represented by an ISO datetime string.
   * Use this when representing the moment something happened.
   * It's intended the client will display this relative to their timezone.
   */
  Timestamp: Date | null;
  Upload: File;
}


export interface GqlArrivedAtFinalRampNotification extends GqlNotification {
  __typename?: 'ArrivedAtFinalRampNotification';
  id: Scalars['ID'];
  notificationCode: NotificationCode;
  createTime: Scalars['Timestamp'];
  container: GqlContainer;
}

export interface GqlArrivedAtPodNotification extends GqlNotification {
  __typename?: 'ArrivedAtPodNotification';
  id: Scalars['ID'];
  notificationCode: NotificationCode;
  createTime: Scalars['Timestamp'];
  container: GqlContainer;
}

export interface GqlAssignBookingContainerInput {
  bookingId: Scalars['ID'];
  container: GqlContainerInput;
  vanPositions?: Maybe<Array<GqlVanPositionInput>>;
}

export interface GqlAssignBookingContainerPayload extends GqlMutationPayload {
  __typename?: 'AssignBookingContainerPayload';
  success: Scalars['Boolean'];
  message?: Maybe<Scalars['String']>;
  error?: Maybe<GqlMutationError>;
  container?: Maybe<GqlContainer>;
}

export interface GqlAssignBookingRelatedPartyInput {
  bookingId: Scalars['ID'];
  profileId: Scalars['ID'];
  partyType: PartyType;
}

export interface GqlAssignBookingRelatedPartyPayload extends GqlMutationPayload {
  __typename?: 'AssignBookingRelatedPartyPayload';
  success: Scalars['Boolean'];
  message?: Maybe<Scalars['String']>;
  error?: Maybe<GqlMutationError>;
  relatedParty?: Maybe<GqlRelatedParty>;
}

export interface GqlAssignConsolidationContainerInput {
  consolidationId: Scalars['ID'];
  container: GqlContainerInput;
  vanPositions?: Maybe<Array<GqlVanPositionInput>>;
}

export interface GqlAssignConsolidationContainerPayload extends GqlMutationPayload {
  __typename?: 'AssignConsolidationContainerPayload';
  success: Scalars['Boolean'];
  message?: Maybe<Scalars['String']>;
  error?: Maybe<GqlMutationError>;
  container?: Maybe<GqlContainer>;
}

export interface GqlAssignConsolidationRelatedPartyInput {
  consolidationId: Scalars['ID'];
  profileId: Scalars['ID'];
  partyType: PartyType;
}

export interface GqlAssignConsolidationRelatedPartyPayload extends GqlMutationPayload {
  __typename?: 'AssignConsolidationRelatedPartyPayload';
  success: Scalars['Boolean'];
  message?: Maybe<Scalars['String']>;
  error?: Maybe<GqlMutationError>;
  relatedParty?: Maybe<GqlRelatedParty>;
}

export interface GqlAssignHblRelatedPartyInput {
  hblId: Scalars['ID'];
  profileId: Scalars['ID'];
  partyType: PartyType;
}

export interface GqlAssignHblRelatedPartyPayload extends GqlMutationPayload {
  __typename?: 'AssignHblRelatedPartyPayload';
  success: Scalars['Boolean'];
  message?: Maybe<Scalars['String']>;
  error?: Maybe<GqlMutationError>;
  relatedParty?: Maybe<GqlRelatedParty>;
}

export interface GqlAssignMblRelatedPartyInput {
  mblId: Scalars['ID'];
  profileId: Scalars['ID'];
  partyType: PartyType;
}

export interface GqlAssignMblRelatedPartyPayload extends GqlMutationPayload {
  __typename?: 'AssignMblRelatedPartyPayload';
  success: Scalars['Boolean'];
  message?: Maybe<Scalars['String']>;
  error?: Maybe<GqlMutationError>;
  relatedParty?: Maybe<GqlRelatedParty>;
}

export interface GqlBillOfLading {
  id: Scalars['ID'];
  referenceNumber: Scalars['String'];
  releaseType: ReleaseType;
  paymentType: PaymentType;
  containerCount: Scalars['Int'];
  totalPieces: Scalars['Int'];
  totalCartons: Scalars['Int'];
  remarks: Array<GqlRemark>;
  documents: Array<GqlDocument>;
  relatedParties: Array<GqlRelatedParty>;
  customs?: Maybe<GqlCustoms>;
}

export interface GqlBooking extends GqlShipment {
  __typename?: 'Booking';
  id: Scalars['ID'];
  referenceNumber: Scalars['String'];
  status: ShipmentStatus;
  createDate: Scalars['Timestamp'];
  bookingParty: GqlContact;
  cargoReadyDate: Scalars['Date'];
  revisedCargoReadyDate?: Maybe<Scalars['Date']>;
  incoTerm?: Maybe<IncoTerm>;
  logistics: GqlLogistics;
  remarks: Array<GqlRemark>;
  containers: Array<GqlContainer>;
  documents: Array<GqlDocument>;
  relatedParties: Array<GqlRelatedParty>;
  packLines: Array<GqlPackLine>;
  hbl?: Maybe<GqlHouseBill>;
  mbl?: Maybe<GqlMasterBill>;
  isHot: Scalars['Boolean'];
  hotMarkedBy?: Maybe<Scalars['String']>;
  hotMarkedTimestamp?: Maybe<Scalars['Timestamp']>;
}

export interface GqlBookingInput {
  id?: Maybe<Scalars['ID']>;
  incoTerm?: Maybe<IncoTerm>;
  /**
   * When updating the booking, the original cargo ready date isn't changed. Instead the revised cargo
   * ready date is set or updated.
   */
  cargoReadyDate?: Maybe<Scalars['Date']>;
}

export interface GqlCarrier {
  __typename?: 'Carrier';
  id: Scalars['ID'];
  scac: Scalars['String'];
  name: Scalars['String'];
  vessels?: Maybe<Array<GqlVessel>>;
}

export interface GqlConsolidation extends GqlShipment {
  __typename?: 'Consolidation';
  id: Scalars['ID'];
  referenceNumber: Scalars['String'];
  status: ShipmentStatus;
  createDate: Scalars['Timestamp'];
  bookingParty: GqlContact;
  logistics: GqlLogistics;
  remarks: Array<GqlRemark>;
  containers: Array<GqlContainer>;
  documents: Array<GqlDocument>;
  relatedParties: Array<GqlRelatedParty>;
  mbl?: Maybe<GqlMasterBill>;
  bookings: Array<GqlBooking>;
}

export interface GqlConsolidationBookingHblInput {
  bookingId?: Maybe<Scalars['ID']>;
  hbl?: Maybe<GqlHblInput>;
}

export interface GqlConsolidationInput {
  /** Note VT: There really isn't anything that is on the Consolidation record to update */
  id?: Maybe<Scalars['ID']>;
}

/**
 * A Contact is a record of a particular person that belongs to a particular profile. Contacts can be
 * assigned to locations in the same profile as an additional way to restrict a particular users
 * visibility in the system.
 *
 * Initially, a contact is just a standalone record with information about a person, but a contact
 * can be "enabled" as a user and assigned a role from its profile to allow them to login to the
 * system.
 */
export interface GqlContact {
  __typename?: 'Contact';
  id: Scalars['ID'];
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  phoneMain?: Maybe<Scalars['String']>;
  phoneCell?: Maybe<Scalars['String']>;
  phoneAlternative?: Maybe<Scalars['String']>;
  extension?: Maybe<Scalars['String']>;
  isMainContact: Scalars['Boolean'];
  isUserEnabled: Scalars['Boolean'];
  needsUpdate: Scalars['Boolean'];
  profile: GqlProfile;
  location?: Maybe<GqlLocation>;
  role?: Maybe<GqlRole>;
  notificationFrequency?: Maybe<NotificationFrequency>;
  user?: Maybe<GqlUser>;
}

export interface GqlContactInput {
  id?: Maybe<Scalars['ID']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  phoneMain?: Maybe<Scalars['String']>;
  extension?: Maybe<Scalars['String']>;
  phoneCell?: Maybe<Scalars['String']>;
  phoneAlternative?: Maybe<Scalars['String']>;
  isMainContact?: Maybe<Scalars['Boolean']>;
  isUserEnabled?: Maybe<Scalars['Boolean']>;
  locationId?: Maybe<Scalars['ID']>;
  roleId?: Maybe<Scalars['ID']>;
  notificationFrequency?: Maybe<NotificationFrequency>;
  credentials?: Maybe<GqlCredentialsInput>;
}

export interface GqlContainer {
  __typename?: 'Container';
  id: Scalars['ID'];
  sequential: Scalars['Int'];
  containerNumber?: Maybe<Scalars['String']>;
  containerType: Scalars['String'];
  sealNumber?: Maybe<Scalars['String']>;
  volume?: Maybe<Scalars['Float']>;
  volumeUnit?: Maybe<VolumeUnit>;
  weight?: Maybe<Scalars['Float']>;
  weightUnit?: Maybe<WeightUnit>;
  shipFromFactoryDate?: Maybe<Scalars['Date']>;
  terminalReceivedDate?: Maybe<Scalars['Date']>;
  ramp?: Maybe<GqlPort>;
  rampEta?: Maybe<Scalars['Date']>;
  rampAta?: Maybe<Scalars['Date']>;
  rampOutGateDate?: Maybe<Scalars['Date']>;
  portOutGateDate?: Maybe<Scalars['Date']>;
  deliveryEta?: Maybe<Scalars['Date']>;
  deliveryAta?: Maybe<Scalars['Date']>;
  deliveryUnload?: Maybe<Scalars['Date']>;
  emptyNotifyDate?: Maybe<Scalars['Date']>;
  emptyPickupDate?: Maybe<Scalars['Date']>;
  emptyReturnedDate?: Maybe<Scalars['Date']>;
  demurrageLastFreeDayDate?: Maybe<Scalars['Date']>;
  detentionLastFreeDayDate?: Maybe<Scalars['Date']>;
  consolidatorLastFreeDayDate?: Maybe<Scalars['Date']>;
  isHot: Scalars['Boolean'];
  hotMarkedBy?: Maybe<Scalars['String']>;
  hotMarkedTimestamp?: Maybe<Scalars['Timestamp']>;
  shipment: GqlShipment;
  vanPositions: Array<GqlVanPosition>;
}

export interface GqlContainerInput {
  id?: Maybe<Scalars['ID']>;
  containerNumber?: Maybe<Scalars['String']>;
  containerType?: Maybe<Scalars['String']>;
  sealNumber?: Maybe<Scalars['String']>;
  shipFromFactoryDate?: Maybe<Scalars['Date']>;
  terminalReceivedDate?: Maybe<Scalars['Date']>;
  rampOutGateDate?: Maybe<Scalars['Date']>;
  portOutGateDate?: Maybe<Scalars['Date']>;
  emptyReturnedDate?: Maybe<Scalars['Date']>;
}

export enum ContainerMode {
  Fcl = 'FCL',
  Lcl = 'LCL',
  Bcn = 'BCN'
}

export enum ContractType {
  Nvo = 'NVO',
  Direct = 'DIRECT'
}


export interface GqlCredentialsInput {
  email?: Maybe<Scalars['String']>;
  username?: Maybe<Scalars['String']>;
  password?: Maybe<Scalars['String']>;
}

export enum Currency {
  Usd = 'USD'
}

export interface GqlCustoms {
  __typename?: 'Customs';
  id: Scalars['ID'];
  customsBrokerRef?: Maybe<Scalars['String']>;
  isfResponseNumber?: Maybe<Scalars['String']>;
  isfFilingDate?: Maybe<Scalars['Date']>;
  entryNumber?: Maybe<Scalars['String']>;
  entryDate?: Maybe<Scalars['Date']>;
  entryValue?: Maybe<Scalars['Float']>;
  mpf?: Maybe<Scalars['Float']>;
  hmf?: Maybe<Scalars['Float']>;
  duty?: Maybe<Scalars['Float']>;
  customsExamType?: Maybe<Scalars['String']>;
  customsExamNotifyDate?: Maybe<Scalars['Date']>;
  customsExamFee?: Maybe<Scalars['Float']>;
  customsReleaseDate?: Maybe<Scalars['Date']>;
  fdaExamType?: Maybe<Scalars['String']>;
  fdaExamNotifyDate?: Maybe<Scalars['Date']>;
  fdaExamFee?: Maybe<Scalars['Float']>;
  fdaReleaseDate?: Maybe<Scalars['Date']>;
  entryFeeTotal?: Maybe<Scalars['Float']>;
  bol: GqlBillOfLading;
}

export interface GqlCustomsInput {
  id: Scalars['ID'];
  customsBrokerRef?: Maybe<Scalars['String']>;
  isfResponseNumber?: Maybe<Scalars['String']>;
  isfFilingDate?: Maybe<Scalars['Date']>;
  entryNumber?: Maybe<Scalars['String']>;
  entryDate?: Maybe<Scalars['Date']>;
  entryValue?: Maybe<Scalars['Float']>;
  mpf?: Maybe<Scalars['Float']>;
  hmf?: Maybe<Scalars['Float']>;
  duty?: Maybe<Scalars['Float']>;
  customsExamType?: Maybe<Scalars['String']>;
  customsExamNotifyDate?: Maybe<Scalars['Date']>;
  customsExamFee?: Maybe<Scalars['Float']>;
  customsReleaseDate?: Maybe<Scalars['Date']>;
  fdaExamType?: Maybe<Scalars['String']>;
  fdaExamNotifyDate?: Maybe<Scalars['Date']>;
  fdaExamFee?: Maybe<Scalars['Float']>;
  fdaReleaseDate?: Maybe<Scalars['Date']>;
  entryFeeTotal?: Maybe<Scalars['Float']>;
  bolId?: Maybe<Scalars['ID']>;
}

export interface GqlCwOrg {
  __typename?: 'CwOrg';
  id: Scalars['ID'];
  cwId: Scalars['ID'];
  orgCode: Scalars['String'];
  orgName: Scalars['String'];
}

export interface GqlDashboardBookingSummary {
  __typename?: 'DashboardBookingSummary';
  start?: Maybe<Scalars['Date']>;
  end?: Maybe<Scalars['Date']>;
  weekNumber?: Maybe<Scalars['Int']>;
  containers: Array<GqlContainer>;
  skuPackLines: Array<GqlPackLine>;
  bookings: Array<GqlShipment>;
  invoicePackLines: Array<GqlPackLine>;
}

export enum DashboardFeatureCode {
  ChartBookingsByWeek = 'CHART_BOOKINGS_BY_WEEK',
  ChartBookingsByWeekDetailsContainers = 'CHART_BOOKINGS_BY_WEEK_DETAILS_CONTAINERS',
  ChartBookingsByWeekDetailsSku = 'CHART_BOOKINGS_BY_WEEK_DETAILS_SKU',
  ChartBookingsByWeekDetailsBookings = 'CHART_BOOKINGS_BY_WEEK_DETAILS_BOOKINGS',
  ChartBookingsByWeekDetailsCommercialValue = 'CHART_BOOKINGS_BY_WEEK_DETAILS_COMMERCIAL_VALUE',
  ShipmentsInTransitOnWater = 'SHIPMENTS_IN_TRANSIT_ON_WATER',
  ShipmentsInTransitAtDischarge = 'SHIPMENTS_IN_TRANSIT_AT_DISCHARGE',
  ShipmentsInTransitAtRamp = 'SHIPMENTS_IN_TRANSIT_AT_RAMP',
  ShipmentsInTransitDelivered = 'SHIPMENTS_IN_TRANSIT_DELIVERED',
  PoStatusOpen = 'PO_STATUS_OPEN',
  PoStatusCritical = 'PO_STATUS_CRITICAL',
  PoStatusLateBooking = 'PO_STATUS_LATE_BOOKING',
  PoStatusExpired = 'PO_STATUS_EXPIRED',
  ExceptionHotPos = 'EXCEPTION_HOT_POS',
  ExceptionHotBookings = 'EXCEPTION_HOT_BOOKINGS',
  ExceptionHotContainers = 'EXCEPTION_HOT_CONTAINERS',
  ExceptionMissingDocuments = 'EXCEPTION_MISSING_DOCUMENTS',
  ExceptionPendingOutgate = 'EXCEPTION_PENDING_OUTGATE',
  ExceptionEmptyNotReturned = 'EXCEPTION_EMPTY_NOT_RETURNED',
  ExceptionDelayedUnload = 'EXCEPTION_DELAYED_UNLOAD',
  ExceptionLateBooking = 'EXCEPTION_LATE_BOOKING'
}

export interface GqlDashboardFeatureCodeInfo {
  __typename?: 'DashboardFeatureCodeInfo';
  code: DashboardFeatureCode;
  group: Scalars['String'];
  name: Scalars['String'];
  description: Scalars['String'];
}

export interface GqlDashboardPurchaseOrder {
  __typename?: 'DashboardPurchaseOrder';
  orderLine: GqlOrderLine;
}

export interface GqlDashboardShipmentInTransit {
  __typename?: 'DashboardShipmentInTransit';
  container: GqlContainer;
  status: ShipmentInTransitStatus;
  milestone: ShipmentInTransitMilestone;
  isDueToArrive: Scalars['Boolean'];
}

export interface GqlDataset {
  __typename?: 'Dataset';
  id: Scalars['ID'];
  name: Scalars['String'];
  filterables: Array<GqlDatasetFilterable>;
  displayables: Array<GqlDatasetDisplayable>;
  sortables: Array<GqlDatasetSortable>;
  isReport: Scalars['Boolean'];
  isFinancial: Scalars['Boolean'];
  isRootAdminOnly: Scalars['Boolean'];
  defaultDisplayables: Array<Scalars['String']>;
}

export interface GqlDatasetDisplayable {
  __typename?: 'DatasetDisplayable';
  field: Scalars['String'];
  label: Scalars['String'];
}

export interface GqlDatasetFilter {
  __typename?: 'DatasetFilter';
  field: Scalars['String'];
  value?: Maybe<Scalars['String']>;
  applied: Scalars['Boolean'];
}

export interface GqlDatasetFilterInput {
  field: Scalars['String'];
  value?: Maybe<Scalars['String']>;
  applied?: Maybe<Scalars['Boolean']>;
}

export interface GqlDatasetFilterable {
  __typename?: 'DatasetFilterable';
  field: Scalars['String'];
  label: Scalars['String'];
  type: Scalars['String'];
  options?: Maybe<Array<GqlDatasetFilterableOption>>;
  required?: Maybe<Scalars['Boolean']>;
}

export interface GqlDatasetFilterableOption {
  __typename?: 'DatasetFilterableOption';
  value: Scalars['String'];
  label: Scalars['String'];
}

export interface GqlDatasetSortable {
  __typename?: 'DatasetSortable';
  field: Scalars['String'];
  label: Scalars['String'];
}



export interface GqlDelayedArrivalException {
  __typename?: 'DelayedArrivalException';
  type: ExceptionType;
  booking: GqlBooking;
}

export interface GqlDeleteContactInput {
  contactId: Scalars['ID'];
}

export interface GqlDeleteContactPayload extends GqlMutationPayload {
  __typename?: 'DeleteContactPayload';
  success: Scalars['Boolean'];
  message?: Maybe<Scalars['String']>;
  error?: Maybe<GqlMutationError>;
  contactId?: Maybe<Scalars['ID']>;
}

export interface GqlDeleteContainerInput {
  containerId: Scalars['ID'];
}

export interface GqlDeleteContainerPayload extends GqlMutationPayload {
  __typename?: 'DeleteContainerPayload';
  success: Scalars['Boolean'];
  message?: Maybe<Scalars['String']>;
  error?: Maybe<GqlMutationError>;
  containerId?: Maybe<Scalars['ID']>;
}

export interface GqlDeleteLocationInput {
  locationId: Scalars['ID'];
}

export interface GqlDeleteLocationPayload extends GqlMutationPayload {
  __typename?: 'DeleteLocationPayload';
  success: Scalars['Boolean'];
  message?: Maybe<Scalars['String']>;
  error?: Maybe<GqlMutationError>;
  locationId?: Maybe<Scalars['ID']>;
}

export interface GqlDeleteProfileTypeInput {
  profileTypeId: Scalars['ID'];
}

export interface GqlDeleteProfileTypePayload extends GqlMutationPayload {
  __typename?: 'DeleteProfileTypePayload';
  success: Scalars['Boolean'];
  message?: Maybe<Scalars['String']>;
  error?: Maybe<GqlMutationError>;
  profileTypeId?: Maybe<Scalars['ID']>;
}

export interface GqlDeleteRoleInput {
  roleId: Scalars['ID'];
}

export interface GqlDeleteRolePayload extends GqlMutationPayload {
  __typename?: 'DeleteRolePayload';
  success: Scalars['Boolean'];
  message?: Maybe<Scalars['String']>;
  error?: Maybe<GqlMutationError>;
}

export enum DimsUnit {
  In = 'IN',
  Ft = 'FT',
  Cm = 'CM',
  M = 'M'
}

export interface GqlDocument {
  __typename?: 'Document';
  id: Scalars['ID'];
  documentType: Scalars['String'];
  importFileName: Scalars['String'];
  fileName: Scalars['String'];
  fileType: Scalars['String'];
  fileSize: Scalars['Int'];
  url: Scalars['String'];
  uploadDate: Scalars['Timestamp'];
  description?: Maybe<Scalars['String']>;
  uploadedBy: GqlContact;
}

export interface GqlDocumentDateFilter {
  documentEventCode: DocumentEventCode;
  from: Scalars['Date'];
  to: Scalars['Date'];
}

export enum DocumentEventCode {
  UploadDate = 'UPLOAD_DATE',
  PolEtd = 'POL_ETD',
  PodEta = 'POD_ETA'
}

export interface GqlDocumentFilter {
  dateFilter?: Maybe<GqlDocumentDateFilter>;
  documentTypeCode?: Maybe<Scalars['String']>;
  shipper?: Maybe<Scalars['String']>;
  deliveryLocation?: Maybe<Scalars['String']>;
  poId?: Maybe<Scalars['ID']>;
  itemId?: Maybe<Scalars['ID']>;
  onlyNew?: Maybe<Scalars['Boolean']>;
}

export interface GqlDocumentInput {
  id?: Maybe<Scalars['ID']>;
  documentType?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
}

export interface GqlDocumentRecord {
  __typename?: 'DocumentRecord';
  id: Scalars['ID'];
  bookingId?: Maybe<Scalars['ID']>;
  bookingNumber?: Maybe<Scalars['String']>;
  importFileName?: Maybe<Scalars['String']>;
  fileType?: Maybe<Scalars['String']>;
  documentType: Scalars['String'];
  uploadDate: Scalars['Timestamp'];
  shipperName?: Maybe<Scalars['String']>;
  hblId?: Maybe<Scalars['ID']>;
  hblNumber?: Maybe<Scalars['String']>;
  pol?: Maybe<GqlPort>;
  polEtd?: Maybe<Scalars['Date']>;
  polAtd?: Maybe<Scalars['Date']>;
  pod?: Maybe<GqlPort>;
  podEta?: Maybe<Scalars['Date']>;
  deliveryLocation?: Maybe<Scalars['String']>;
}

export interface GqlEmptyNotReturnedException {
  __typename?: 'EmptyNotReturnedException';
  type: ExceptionType;
  container: GqlContainer;
  notificationId?: Maybe<Scalars['ID']>;
  markedAsRead?: Maybe<Scalars['Timestamp']>;
}

export interface GqlEmptyNotReturnedNotification extends GqlNotification {
  __typename?: 'EmptyNotReturnedNotification';
  id: Scalars['ID'];
  notificationCode: NotificationCode;
  createTime: Scalars['Timestamp'];
  container: GqlContainer;
}

export type GqlException = GqlDelayedArrivalException | GqlPendingOutgateException | GqlEmptyNotReturnedException | GqlHotPurchaseOrder | GqlHotShipment | GqlHotContainer | GqlMissingDocumentException | GqlLateBookingException;

export enum ExceptionType {
  HotPo = 'HOT_PO',
  HotShipment = 'HOT_SHIPMENT',
  HotContainer = 'HOT_CONTAINER',
  PendingOutgate = 'PENDING_OUTGATE',
  DelayedPoArrival = 'DELAYED_PO_ARRIVAL',
  EmptyNotReturned = 'EMPTY_NOT_RETURNED',
  MissingDocument = 'MISSING_DOCUMENT',
  LateBooking = 'LATE_BOOKING'
}

export interface GqlGlobalUpdateContainer {
  __typename?: 'GlobalUpdateContainer';
  containerId: Scalars['ID'];
  containerNumber?: Maybe<Scalars['String']>;
  rampEta?: Maybe<Scalars['Date']>;
  rampAta?: Maybe<Scalars['Date']>;
  rampOutGateDate?: Maybe<Scalars['Date']>;
  portOutGateDate?: Maybe<Scalars['Date']>;
  deliveryEta?: Maybe<Scalars['Date']>;
  deliveryAta?: Maybe<Scalars['Date']>;
  deliveryUnload?: Maybe<Scalars['Date']>;
  emptyNotifyDate?: Maybe<Scalars['Date']>;
  emptyPickupDate?: Maybe<Scalars['Date']>;
  emptyReturnedDate?: Maybe<Scalars['Date']>;
  demurrageLastFreeDayDate?: Maybe<Scalars['Date']>;
  detentionLastFreeDayDate?: Maybe<Scalars['Date']>;
  consolidatorLastFreeDayDate?: Maybe<Scalars['Date']>;
}

export interface GqlGlobalUpdateContainerInput {
  containerIds: Array<Scalars['ID']>;
  rampEta?: Maybe<Scalars['Date']>;
  rampAta?: Maybe<Scalars['Date']>;
  rampOutGateDate?: Maybe<Scalars['Date']>;
  portOutGateDate?: Maybe<Scalars['Date']>;
  deliveryEta?: Maybe<Scalars['Date']>;
  deliveryAta?: Maybe<Scalars['Date']>;
  deliveryUnload?: Maybe<Scalars['Date']>;
  emptyNotifyDate?: Maybe<Scalars['Date']>;
  emptyPickupDate?: Maybe<Scalars['Date']>;
  emptyReturnedDate?: Maybe<Scalars['Date']>;
  demurrageLastFreeDayDate?: Maybe<Scalars['Date']>;
  detentionLastFreeDayDate?: Maybe<Scalars['Date']>;
  consolidatorLastFreeDayDate?: Maybe<Scalars['Date']>;
}

export interface GqlGlobalUpdateCustoms {
  __typename?: 'GlobalUpdateCustoms';
  customsId: Scalars['ID'];
  customsBrokerRef?: Maybe<Scalars['String']>;
  isfResponseNumber?: Maybe<Scalars['String']>;
  isfFilingDate?: Maybe<Scalars['Date']>;
  entryNumber?: Maybe<Scalars['String']>;
  entryDate?: Maybe<Scalars['Date']>;
  entryValue?: Maybe<Scalars['Float']>;
  mpf?: Maybe<Scalars['Float']>;
  hmf?: Maybe<Scalars['Float']>;
  duty?: Maybe<Scalars['Float']>;
  customsExamType?: Maybe<Scalars['String']>;
  customsExamNotifyDate?: Maybe<Scalars['Date']>;
  customsExamFee?: Maybe<Scalars['Float']>;
  customsReleaseDate?: Maybe<Scalars['Date']>;
  fdaExamType?: Maybe<Scalars['String']>;
  fdaExamNotifyDate?: Maybe<Scalars['Date']>;
  fdaExamFee?: Maybe<Scalars['Float']>;
  fdaReleaseDate?: Maybe<Scalars['Date']>;
  entryFeeTotal?: Maybe<Scalars['Float']>;
}

export interface GqlGlobalUpdateCustomsInput {
  customsIds: Array<Scalars['ID']>;
  bolIds: Array<Scalars['ID']>;
  customsBrokerRef?: Maybe<Scalars['String']>;
  isfResponseNumber?: Maybe<Scalars['String']>;
  isfFilingDate?: Maybe<Scalars['Date']>;
  entryNumber?: Maybe<Scalars['String']>;
  entryDate?: Maybe<Scalars['Date']>;
  entryValue?: Maybe<Scalars['Float']>;
  mpf?: Maybe<Scalars['Float']>;
  hmf?: Maybe<Scalars['Float']>;
  duty?: Maybe<Scalars['Float']>;
  customsExamType?: Maybe<Scalars['String']>;
  customsExamNotifyDate?: Maybe<Scalars['Date']>;
  customsExamFee?: Maybe<Scalars['Float']>;
  customsReleaseDate?: Maybe<Scalars['Date']>;
  fdaExamType?: Maybe<Scalars['String']>;
  fdaExamNotifyDate?: Maybe<Scalars['Date']>;
  fdaExamFee?: Maybe<Scalars['Float']>;
  fdaReleaseDate?: Maybe<Scalars['Date']>;
  entryFeeTotal?: Maybe<Scalars['Float']>;
}

export interface GqlGlobalUpdateInput {
  logistics?: Maybe<GqlGlobalUpdateLogisticsInput>;
  containers?: Maybe<GqlGlobalUpdateContainerInput>;
  customs?: Maybe<GqlGlobalUpdateCustomsInput>;
}

export interface GqlGlobalUpdateLogistics {
  __typename?: 'GlobalUpdateLogistics';
  logisticsId: Scalars['ID'];
  referenceNumber?: Maybe<Scalars['String']>;
  confirmationNumber?: Maybe<Scalars['String']>;
  confirmationDate?: Maybe<Scalars['Date']>;
  polEtd?: Maybe<Scalars['Date']>;
  polAtd?: Maybe<Scalars['Date']>;
  transitPortEta?: Maybe<Scalars['Date']>;
  transitPortAta?: Maybe<Scalars['Date']>;
  transitPortEtd?: Maybe<Scalars['Date']>;
  transitPortAtd?: Maybe<Scalars['Date']>;
  podEta?: Maybe<Scalars['Date']>;
  podAta?: Maybe<Scalars['Date']>;
  rampEta?: Maybe<Scalars['Date']>;
  rampAta?: Maybe<Scalars['Date']>;
}

export interface GqlGlobalUpdateLogisticsInput {
  logisticsIds: Array<Scalars['ID']>;
  confirmationNumber?: Maybe<Scalars['String']>;
  confirmationDate?: Maybe<Scalars['Date']>;
  polEtd?: Maybe<Scalars['Date']>;
  polAtd?: Maybe<Scalars['Date']>;
  transitPortEta?: Maybe<Scalars['Date']>;
  transitPortAta?: Maybe<Scalars['Date']>;
  transitPortEtd?: Maybe<Scalars['Date']>;
  transitPortAtd?: Maybe<Scalars['Date']>;
  podEta?: Maybe<Scalars['Date']>;
  podAta?: Maybe<Scalars['Date']>;
}

export interface GqlGlobalUpdatePayload extends GqlMutationPayload {
  __typename?: 'GlobalUpdatePayload';
  success: Scalars['Boolean'];
  message?: Maybe<Scalars['String']>;
  error?: Maybe<GqlMutationError>;
}

export interface GqlGlobalUpdateRecord {
  __typename?: 'GlobalUpdateRecord';
  recordId: Scalars['ID'];
  shipmentId?: Maybe<Scalars['ID']>;
  referenceNumber?: Maybe<Scalars['String']>;
  hblId?: Maybe<Scalars['ID']>;
  hblNumber?: Maybe<Scalars['String']>;
  mblId?: Maybe<Scalars['ID']>;
  mblNumber?: Maybe<Scalars['String']>;
  containerId?: Maybe<Scalars['ID']>;
  containerNumber?: Maybe<Scalars['String']>;
  poId?: Maybe<Scalars['ID']>;
  poNumber?: Maybe<Scalars['String']>;
  itemNumber?: Maybe<Scalars['String']>;
  vesselName?: Maybe<Scalars['String']>;
  voyageName?: Maybe<Scalars['String']>;
}

export interface GqlGlobalUpdateResults {
  __typename?: 'GlobalUpdateResults';
  logistics: Array<GqlGlobalUpdateLogistics>;
  containers: Array<GqlGlobalUpdateContainer>;
  customs: Array<GqlGlobalUpdateCustoms>;
  records: Array<GqlGlobalUpdateRecord>;
}

export interface GqlHblInput {
  id?: Maybe<Scalars['ID']>;
  referenceNumber?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  releaseType?: Maybe<ReleaseType>;
  paymentType?: Maybe<PaymentType>;
}

export enum HistoryRecordType {
  NewPo = 'NEW_PO',
  NewOl = 'NEW_OL',
  UpdatedPoField = 'UPDATED_PO_FIELD',
  UpdatedOlField = 'UPDATED_OL_FIELD'
}

export interface GqlHotContainer {
  __typename?: 'HotContainer';
  type: ExceptionType;
  container: GqlContainer;
}

export interface GqlHotPurchaseOrder {
  __typename?: 'HotPurchaseOrder';
  type: ExceptionType;
  openOrder: GqlOpenOrder;
}

export interface GqlHotShipment {
  __typename?: 'HotShipment';
  type: ExceptionType;
  shipment: GqlShipment;
}

export interface GqlHouseBill extends GqlBillOfLading {
  __typename?: 'HouseBill';
  id: Scalars['ID'];
  referenceNumber: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  releaseType: ReleaseType;
  paymentType: PaymentType;
  containerCount: Scalars['Int'];
  totalPieces: Scalars['Int'];
  totalCartons: Scalars['Int'];
  booking: GqlBooking;
  remarks: Array<GqlRemark>;
  documents: Array<GqlDocument>;
  relatedParties: Array<GqlRelatedParty>;
  customs?: Maybe<GqlCustoms>;
}

export interface GqlImage {
  __typename?: 'Image';
  id: Scalars['ID'];
  imageType: Scalars['String'];
  fileName: Scalars['String'];
  fileType: Scalars['String'];
  fileSize: Scalars['Int'];
  uploadDate: Scalars['Timestamp'];
  url: Scalars['String'];
}

export enum ImportFileStatus {
  Pending = 'PENDING',
  Confirmed = 'CONFIRMED',
  Cancelled = 'CANCELLED'
}

export enum IncoTerm {
  Fob = 'FOB',
  Exw = 'EXW',
  Fca = 'FCA',
  Cfr = 'CFR',
  Cif = 'CIF'
}

export interface GqlItem {
  __typename?: 'Item';
  id: Scalars['ID'];
  itemNumber: Scalars['String'];
  description: Scalars['String'];
  htsCode: Scalars['String'];
  style: Scalars['String'];
  size: Scalars['String'];
  color: Scalars['String'];
  material: Scalars['String'];
  category_code: Scalars['String'];
  length?: Maybe<Scalars['Float']>;
  width?: Maybe<Scalars['Float']>;
  height?: Maybe<Scalars['Float']>;
  dimsUnit: DimsUnit;
  weight?: Maybe<Scalars['Float']>;
  weightUnit: WeightUnit;
  volume?: Maybe<Scalars['Float']>;
  volumeUnit: VolumeUnit;
  duty?: Maybe<Scalars['Float']>;
  dutyCurrencyCode: Currency;
  supplier?: Maybe<GqlProfile>;
  belongsTo: GqlNetwork;
}

export interface GqlLateBookingException {
  __typename?: 'LateBookingException';
  type: ExceptionType;
  booking: GqlBooking;
  orderLine: GqlOrderLine;
  notificationId?: Maybe<Scalars['ID']>;
  markedAsRead?: Maybe<Scalars['Timestamp']>;
}

export interface GqlLocation {
  __typename?: 'Location';
  id: Scalars['ID'];
  name: Scalars['String'];
  code: Scalars['String'];
  nameAliases: Array<Scalars['String']>;
  codeAliases: Array<Scalars['String']>;
  addressLine1?: Maybe<Scalars['String']>;
  addressLine2?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  companyPhone?: Maybe<Scalars['String']>;
  companyUrl?: Maybe<Scalars['String']>;
  relatedPort?: Maybe<GqlPort>;
  profile: GqlProfile;
  locationTypes: Array<LocationType>;
  contacts: Array<GqlContact>;
  needsUpdate: Scalars['Boolean'];
}

export interface GqlLocationInput {
  id?: Maybe<Scalars['ID']>;
  name?: Maybe<Scalars['String']>;
  code?: Maybe<Scalars['String']>;
  nameAliases?: Maybe<Array<Scalars['String']>>;
  codeAliases?: Maybe<Array<Scalars['String']>>;
  addressLine1?: Maybe<Scalars['String']>;
  addressLine2?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  relatedPortCode?: Maybe<Scalars['String']>;
  companyPhone?: Maybe<Scalars['String']>;
  companyUrl?: Maybe<Scalars['String']>;
  locationTypes?: Maybe<Array<LocationType>>;
}

export enum LocationType {
  Primary = 'PRIMARY',
  Secondary = 'SECONDARY',
  Ap = 'AP',
  Ar = 'AR',
  Bol = 'BOL',
  Customs = 'CUSTOMS',
  ConsignmentDelivery = 'CONSIGNMENT_DELIVERY',
  PickupAddress = 'PICKUP_ADDRESS',
  DeliveryAddress = 'DELIVERY_ADDRESS',
  ConsignmentPickup = 'CONSIGNMENT_PICKUP',
  Postal = 'POSTAL',
  Mailing = 'MAILING'
}

export interface GqlLocationTypeInfo {
  __typename?: 'LocationTypeInfo';
  code: LocationType;
  group: Scalars['String'];
  name: Scalars['String'];
  description: Scalars['String'];
}

export interface GqlLogistics {
  __typename?: 'Logistics';
  id: Scalars['ID'];
  confirmationNumber?: Maybe<Scalars['String']>;
  confirmationDate?: Maybe<Scalars['Date']>;
  moveType?: Maybe<MoveType>;
  containerMode: ContainerMode;
  transportationMode: TransportMode;
  carrier?: Maybe<GqlCarrier>;
  carrierConfirmationNumber?: Maybe<Scalars['String']>;
  contractType?: Maybe<ContractType>;
  contractNumber?: Maybe<Scalars['String']>;
  cyCutoffDate?: Maybe<Scalars['Date']>;
  cfsOpenDate?: Maybe<Scalars['Date']>;
  cfsCutoffDate?: Maybe<Scalars['Date']>;
  cfsReceivedDate?: Maybe<Scalars['Date']>;
  vgmCutoffDate?: Maybe<Scalars['Date']>;
  siCutoffDate?: Maybe<Scalars['Date']>;
  motherVessel?: Maybe<GqlVessel>;
  motherVoyage?: Maybe<GqlVoyage>;
  feederVessel?: Maybe<GqlVessel>;
  feederVoyage?: Maybe<GqlVoyage>;
  pickupLocation?: Maybe<GqlLocation>;
  pol?: Maybe<GqlPort>;
  polEtd?: Maybe<Scalars['Date']>;
  polAtd?: Maybe<Scalars['Date']>;
  transitPort?: Maybe<GqlPort>;
  transitPortEta?: Maybe<Scalars['Date']>;
  transitPortAta?: Maybe<Scalars['Date']>;
  transitPortEtd?: Maybe<Scalars['Date']>;
  transitPortAtd?: Maybe<Scalars['Date']>;
  pod?: Maybe<GqlPort>;
  podEta?: Maybe<Scalars['Date']>;
  podAta?: Maybe<Scalars['Date']>;
  ramp?: Maybe<GqlPort>;
  rampEta?: Maybe<Scalars['Date']>;
  rampAta?: Maybe<Scalars['Date']>;
  deliveryEta?: Maybe<Scalars['Date']>;
  deliveryAta?: Maybe<Scalars['Date']>;
  deliveryLocation?: Maybe<GqlLocation>;
  finalDestination?: Maybe<GqlLocation>;
}

export interface GqlLogisticsInput {
  id?: Maybe<Scalars['ID']>;
  confirmationNumber?: Maybe<Scalars['String']>;
  confirmationDate?: Maybe<Scalars['Date']>;
  moveType?: Maybe<MoveType>;
  transportationMode?: Maybe<TransportMode>;
  containerMode?: Maybe<ContainerMode>;
  contractType?: Maybe<ContractType>;
  carrierConfirmationNumber?: Maybe<Scalars['String']>;
  polCode?: Maybe<Scalars['String']>;
  transitPortCode?: Maybe<Scalars['String']>;
  podCode?: Maybe<Scalars['String']>;
  rampCode?: Maybe<Scalars['String']>;
  contractNumber?: Maybe<Scalars['String']>;
  cyCutoffDate?: Maybe<Scalars['Date']>;
  cfsOpenDate?: Maybe<Scalars['Date']>;
  cfsCutoffDate?: Maybe<Scalars['Date']>;
  cfsReceivedDate?: Maybe<Scalars['Date']>;
  vgmCutoffDate?: Maybe<Scalars['Date']>;
  siCutoffDate?: Maybe<Scalars['Date']>;
  polEtd?: Maybe<Scalars['Date']>;
  polAtd?: Maybe<Scalars['Date']>;
  transitPortEtd?: Maybe<Scalars['Date']>;
  transitPortAtd?: Maybe<Scalars['Date']>;
  transitPortEta?: Maybe<Scalars['Date']>;
  transitPortAta?: Maybe<Scalars['Date']>;
  podEta?: Maybe<Scalars['Date']>;
  podAta?: Maybe<Scalars['Date']>;
  rampEta?: Maybe<Scalars['Date']>;
  rampAta?: Maybe<Scalars['Date']>;
  deliveryEta?: Maybe<Scalars['Date']>;
  deliveryAta?: Maybe<Scalars['Date']>;
  carrierId?: Maybe<Scalars['ID']>;
  pickupLocationId?: Maybe<Scalars['ID']>;
  deliveryLocationId?: Maybe<Scalars['ID']>;
  finalDestinationId?: Maybe<Scalars['ID']>;
}

export interface GqlMarkBookingAsHotInput {
  bookingId: Scalars['ID'];
  hot: Scalars['Boolean'];
}

export interface GqlMarkBookingAsHotPayload {
  __typename?: 'MarkBookingAsHotPayload';
  success: Scalars['Boolean'];
  message?: Maybe<Scalars['String']>;
  error?: Maybe<GqlMutationError>;
  booking?: Maybe<GqlBooking>;
}

export interface GqlMarkContainerAsHotInput {
  containerId: Scalars['ID'];
  hot: Scalars['Boolean'];
}

export interface GqlMarkContainerAsHotPayload {
  __typename?: 'MarkContainerAsHotPayload';
  success: Scalars['Boolean'];
  message?: Maybe<Scalars['String']>;
  error?: Maybe<GqlMutationError>;
  container?: Maybe<GqlContainer>;
}

export interface GqlMarkDocumentsAsViewedInput {
  documentIds: Array<Scalars['ID']>;
}

export interface GqlMarkDocumentsAsViewedPayload extends GqlMutationPayload {
  __typename?: 'MarkDocumentsAsViewedPayload';
  success: Scalars['Boolean'];
  message?: Maybe<Scalars['String']>;
  error?: Maybe<GqlMutationError>;
}

export interface GqlMarkNotificationsAsReadInput {
  notificationIds: Array<Scalars['ID']>;
}

export interface GqlMarkNotificationsAsReadPayload extends GqlMutationPayload {
  __typename?: 'MarkNotificationsAsReadPayload';
  success: Scalars['Boolean'];
  message?: Maybe<Scalars['String']>;
  error?: Maybe<GqlMutationError>;
}

export interface GqlMarkPoAsHotInput {
  purchaseOrderId: Scalars['ID'];
  hot: Scalars['Boolean'];
}

export interface GqlMarkPoAsHotPayload {
  __typename?: 'MarkPoAsHotPayload';
  success: Scalars['Boolean'];
  message?: Maybe<Scalars['String']>;
  error?: Maybe<GqlMutationError>;
  purchaseOrder?: Maybe<GqlPurchaseOrder>;
}

export interface GqlMarkPoLineAsHotInput {
  orderLineId: Scalars['ID'];
  hot: Scalars['Boolean'];
}

export interface GqlMarkPoLineAsHotPayload {
  __typename?: 'MarkPoLineAsHotPayload';
  success: Scalars['Boolean'];
  message?: Maybe<Scalars['String']>;
  error?: Maybe<GqlMutationError>;
  openOrder?: Maybe<GqlOpenOrder>;
}

export interface GqlMasterBill extends GqlBillOfLading {
  __typename?: 'MasterBill';
  id: Scalars['ID'];
  referenceNumber: Scalars['String'];
  totalPieces: Scalars['Int'];
  totalCartons: Scalars['Int'];
  containerCount: Scalars['Int'];
  releaseType: ReleaseType;
  paymentType: PaymentType;
  shipment: GqlShipment;
  remarks: Array<GqlRemark>;
  documents: Array<GqlDocument>;
  relatedParties: Array<GqlRelatedParty>;
  customs?: Maybe<GqlCustoms>;
}

export interface GqlMblInput {
  id?: Maybe<Scalars['ID']>;
  referenceNumber?: Maybe<Scalars['String']>;
  releaseType?: Maybe<ReleaseType>;
  paymentType?: Maybe<PaymentType>;
}

export interface GqlMergeLocationInput {
  parentLocationId: Scalars['ID'];
  mergedLocationId: Scalars['ID'];
}

export interface GqlMergeLocationPayload extends GqlMutationPayload {
  __typename?: 'MergeLocationPayload';
  success: Scalars['Boolean'];
  message?: Maybe<Scalars['String']>;
  error?: Maybe<GqlMutationError>;
  location?: Maybe<GqlLocation>;
}

export interface GqlMergeProfileInput {
  parentProfileId: Scalars['ID'];
  mergedProfileId: Scalars['ID'];
}

export interface GqlMergeProfilePayload extends GqlMutationPayload {
  __typename?: 'MergeProfilePayload';
  success: Scalars['Boolean'];
  message?: Maybe<Scalars['String']>;
  error?: Maybe<GqlMutationError>;
  profile?: Maybe<GqlProfile>;
}

export interface GqlMissingDocumentException {
  __typename?: 'MissingDocumentException';
  type: ExceptionType;
  documentType: Scalars['String'];
  booking: GqlBooking;
  notificationId?: Maybe<Scalars['ID']>;
  markedAsRead?: Maybe<Scalars['Timestamp']>;
}

export interface GqlMissingDocumentNotification extends GqlNotification {
  __typename?: 'MissingDocumentNotification';
  id: Scalars['ID'];
  notificationCode: NotificationCode;
  createTime: Scalars['Timestamp'];
  documentType: Scalars['String'];
  booking: GqlBooking;
}

/**
 * CY - Container Yard
 * CFS - Container Freight Station
 * Door - Warehouse, Factory etc.
 */
export enum MoveType {
  CyCy = 'CY_CY',
  CyCfs = 'CY_CFS',
  CyDoor = 'CY_DOOR',
  CfsCy = 'CFS_CY',
  CfsCfs = 'CFS_CFS',
  CfsDoor = 'CFS_DOOR',
  DoorCy = 'DOOR_CY',
  DoorCfs = 'DOOR_CFS',
  DoorDoor = 'DOOR_DOOR'
}

export interface GqlMutation {
  __typename?: 'Mutation';
  /**
   * Assign Van positions and fill in container details for a container on a booking.
   *
   * When a container is assigned, the status of the booking becomes "Assigned"
   */
  assignBookingContainer: GqlAssignBookingContainerPayload;
  assignBookingRelatedParty: GqlAssignBookingRelatedPartyPayload;
  assignConsolidationContainer: GqlAssignConsolidationContainerPayload;
  assignConsolidationRelatedParty: GqlAssignConsolidationRelatedPartyPayload;
  assignHblRelatedParty: GqlAssignHblRelatedPartyPayload;
  assignMblRelatedParty: GqlAssignMblRelatedPartyPayload;
  deleteContact: GqlDeleteContactPayload;
  deleteContainer: GqlDeleteContainerPayload;
  deleteLocation: GqlDeleteLocationPayload;
  /**
   * Deletes the provided profile type from the system.
   * Currently to ensure we don't mess up any actual profiles, you can only delete templates
   */
  deleteProfileType: GqlDeleteProfileTypePayload;
  globalUpdate: GqlGlobalUpdatePayload;
  markNotificationsAsRead: GqlMarkNotificationsAsReadPayload;
  /** Documents are being marked as viewed when they are downloaded */
  markDocumentsAsViewed: GqlMarkDocumentsAsViewedPayload;
  mergeLocation: GqlMergeLocationPayload;
  mergeProfile: GqlMergeProfilePayload;
  newBooking: GqlNewBookingPayload;
  newBookingContainers: GqlNewBookingContainersPayload;
  newBookingRemark: GqlNewBookingRemarkPayload;
  newConsolidation: GqlNewConsolidationPayload;
  newConsolidationContainers: GqlNewConsolidationContainersPayload;
  newConsolidationRemark: GqlNewConsolidationRemarkPayload;
  newHblRemark: GqlNewHblRemarkPayload;
  newMblRemark: GqlNewMblRemarkPayload;
  /** Creates a new network in the system */
  newNetwork: GqlNewNetworkPayload;
  /** Creates a new profile that belongs to the network */
  newNetworkConnectedProfile: GqlNewNetworkConnectedProfilePayload;
  /** Creates a new profile type that is scoped to the network */
  newNetworkProfileType: GqlNewNetworkProfileTypePayload;
  newProfileContact: GqlNewProfileContactPayload;
  newProfileLocation: GqlNewProfileLocationPayload;
  newProfileRelation: GqlNewProfileRelationPayload;
  newProfileRole: GqlNewProfileRolePayload;
  newProfileTypeDefaultRole: GqlNewProfileTypeDefaultRolePayload;
  /** Creates a new Profile Type template that can be used when creating a new network. */
  newProfileTypeTemplate: GqlNewProfileTypeTemplatePayload;
  removeBookingRelatedParty: GqlRemoveBookingRelatedPartyPayload;
  removeConsolidationRelatedParty: GqlRemoveConsolidationRelatedPartyPayload;
  removeHblRelatedParty: GqlRemoveHblRelatedPartyPayload;
  removeMblRelatedParty: GqlRemoveMblRelatedPartyPayload;
  removeProfileRelation?: Maybe<GqlRemoveProfileRelationPayload>;
  setActiveUserContact: GqlSetActiveUserContactPayload;
  setPoRelatedParties: GqlSetPoRelatedPartiesPayload;
  updateBooking: GqlUpdateBookingPayload;
  updateBookingLogistics: GqlUpdateBookingLogisticsPayload;
  updateBookingRelatedParty: GqlUpdateBookingRelatedPartyPayload;
  updateConsolidation: GqlUpdateConsolidationPayload;
  updateConsolidationLogistics: GqlUpdateConsolidationLogisticsPayload;
  updateConsolidationRelatedParty: GqlUpdateConsolidationRelatedPartyPayload;
  updateContact: GqlUpdateContactPayload;
  updateContainer: GqlUpdateContainerPayload;
  updateLocation: GqlUpdateLocationPayload;
  updateHblRelatedParty: GqlUpdateHblRelatedPartyPayload;
  updateMblRelatedParty: GqlUpdateMblRelatedPartyPayload;
  /**
   * Updates fields on the network.
   *
   * The only field that can really be updated is the name.
   */
  updateNetwork: GqlUpdateNetworkPayload;
  /** Updates rules on a Network. */
  updateNetworkRules: GqlUpdateNetworkRulesPayload;
  updateNetworkContainerTypes: GqlUpdateNetworkContainerTypesPayload;
  updateProfile: GqlUpdateProfilePayload;
  updateProfileRelation: GqlUpdateProfileRelationPayload;
  updateProfileType: GqlUpdateProfileTypePayload;
  updateRole: GqlUpdateRolePayload;
  deleteRole: GqlDeleteRolePayload;
  updateUser: GqlUpdateUserPayload;
  uploadBookingDocument: GqlUploadBookingDocumentPayload;
  markPoLineAsHot: GqlMarkPoLineAsHotPayload;
  markBookingAsHot: GqlMarkBookingAsHotPayload;
  markPoAsHot: GqlMarkPoAsHotPayload;
  markContainerAsHot: GqlMarkContainerAsHotPayload;
  uploadPoFile: GqlPoImportFile;
  confirmImport: GqlMutationConfirmation;
  cancelImport: GqlMutationConfirmation;
  setHeaderMapping: GqlPoImportFile;
  putReport: GqlReport;
  setReportScheduled: GqlReport;
  deleteReport: Scalars['Boolean'];
}


export interface GqlMutationAssignBookingContainerArgs {
  input: GqlAssignBookingContainerInput;
}


export interface GqlMutationAssignBookingRelatedPartyArgs {
  input: GqlAssignBookingRelatedPartyInput;
}


export interface GqlMutationAssignConsolidationContainerArgs {
  input: GqlAssignConsolidationContainerInput;
}


export interface GqlMutationAssignConsolidationRelatedPartyArgs {
  input: GqlAssignConsolidationRelatedPartyInput;
}


export interface GqlMutationAssignHblRelatedPartyArgs {
  input: GqlAssignHblRelatedPartyInput;
}


export interface GqlMutationAssignMblRelatedPartyArgs {
  input: GqlAssignMblRelatedPartyInput;
}


export interface GqlMutationDeleteContactArgs {
  input: GqlDeleteContactInput;
}


export interface GqlMutationDeleteContainerArgs {
  input: GqlDeleteContainerInput;
}


export interface GqlMutationDeleteLocationArgs {
  input: GqlDeleteLocationInput;
}


export interface GqlMutationDeleteProfileTypeArgs {
  input: GqlDeleteProfileTypeInput;
}


export interface GqlMutationGlobalUpdateArgs {
  input: GqlGlobalUpdateInput;
}


export interface GqlMutationMarkNotificationsAsReadArgs {
  input: GqlMarkNotificationsAsReadInput;
}


export interface GqlMutationMarkDocumentsAsViewedArgs {
  input: GqlMarkDocumentsAsViewedInput;
}


export interface GqlMutationMergeLocationArgs {
  input: GqlMergeLocationInput;
}


export interface GqlMutationMergeProfileArgs {
  input: GqlMergeProfileInput;
}


export interface GqlMutationNewBookingArgs {
  input: GqlNewBookingInput;
}


export interface GqlMutationNewBookingContainersArgs {
  input: GqlNewBookingContainersInput;
}


export interface GqlMutationNewBookingRemarkArgs {
  input: GqlNewBookingRemarkInput;
}


export interface GqlMutationNewConsolidationArgs {
  input: GqlNewConsolidationInput;
}


export interface GqlMutationNewConsolidationContainersArgs {
  input: GqlNewConsolidationContainersInput;
}


export interface GqlMutationNewConsolidationRemarkArgs {
  input: GqlNewConsolidationRemarkInput;
}


export interface GqlMutationNewHblRemarkArgs {
  input: GqlNewHblRemarkInput;
}


export interface GqlMutationNewMblRemarkArgs {
  input: GqlNewMblRemarkInput;
}


export interface GqlMutationNewNetworkArgs {
  input: GqlNewNetworkInput;
}


export interface GqlMutationNewNetworkConnectedProfileArgs {
  input: GqlNewNetworkConnectedProfileInput;
}


export interface GqlMutationNewNetworkProfileTypeArgs {
  input: GqlNewNetworkProfileTypeInput;
}


export interface GqlMutationNewProfileContactArgs {
  input: GqlNewProfileContactInput;
}


export interface GqlMutationNewProfileLocationArgs {
  input: GqlNewProfileLocationInput;
}


export interface GqlMutationNewProfileRelationArgs {
  input: GqlNewProfileRelationInput;
}


export interface GqlMutationNewProfileRoleArgs {
  input: GqlNewProfileRoleInput;
}


export interface GqlMutationNewProfileTypeDefaultRoleArgs {
  input: GqlNewProfileTypeDefaultRoleInput;
}


export interface GqlMutationNewProfileTypeTemplateArgs {
  input: GqlNewProfileTypeTemplateInput;
}


export interface GqlMutationRemoveBookingRelatedPartyArgs {
  input: GqlRemoveBookingRelatedPartyInput;
}


export interface GqlMutationRemoveConsolidationRelatedPartyArgs {
  input: GqlRemoveConsolidationRelatedPartyInput;
}


export interface GqlMutationRemoveHblRelatedPartyArgs {
  input: GqlRemoveHblRelatedPartyInput;
}


export interface GqlMutationRemoveMblRelatedPartyArgs {
  input: GqlRemoveMblRelatedPartyInput;
}


export interface GqlMutationRemoveProfileRelationArgs {
  input: GqlRemoveProfileRelationInput;
}


export interface GqlMutationSetActiveUserContactArgs {
  input: GqlSetActiveUserContactInput;
}


export interface GqlMutationSetPoRelatedPartiesArgs {
  input: GqlSetPoRelatedPartiesInput;
}


export interface GqlMutationUpdateBookingArgs {
  input: GqlUpdateBookingInput;
}


export interface GqlMutationUpdateBookingLogisticsArgs {
  input: GqlUpdateBookingLogisticsInput;
}


export interface GqlMutationUpdateBookingRelatedPartyArgs {
  input: GqlUpdateBookingRelatedPartyInput;
}


export interface GqlMutationUpdateConsolidationArgs {
  input: GqlUpdateConsolidationInput;
}


export interface GqlMutationUpdateConsolidationLogisticsArgs {
  input: GqlUpdateConsolidationLogisticsInput;
}


export interface GqlMutationUpdateConsolidationRelatedPartyArgs {
  input: GqlUpdateConsolidationRelatedPartyInput;
}


export interface GqlMutationUpdateContactArgs {
  input: GqlUpdateContactInput;
}


export interface GqlMutationUpdateContainerArgs {
  input: GqlUpdateContainerInput;
}


export interface GqlMutationUpdateLocationArgs {
  input: GqlUpdateLocationInput;
}


export interface GqlMutationUpdateHblRelatedPartyArgs {
  input: GqlUpdateHblRelatedPartyInput;
}


export interface GqlMutationUpdateMblRelatedPartyArgs {
  input: GqlUpdateMblRelatedPartyInput;
}


export interface GqlMutationUpdateNetworkArgs {
  input: GqlUpdateNetworkInput;
}


export interface GqlMutationUpdateNetworkRulesArgs {
  input: GqlUpdateNetworkRulesInput;
}


export interface GqlMutationUpdateNetworkContainerTypesArgs {
  input: GqlUpdateNetworkContainerTypesInput;
}


export interface GqlMutationUpdateProfileArgs {
  input: GqlUpdateProfileInput;
}


export interface GqlMutationUpdateProfileRelationArgs {
  input: GqlUpdateProfileRelationInput;
}


export interface GqlMutationUpdateProfileTypeArgs {
  input: GqlUpdateProfileTypeInput;
}


export interface GqlMutationUpdateRoleArgs {
  input: GqlUpdateRoleInput;
}


export interface GqlMutationDeleteRoleArgs {
  input: GqlDeleteRoleInput;
}


export interface GqlMutationUpdateUserArgs {
  input: GqlUpdateUserInput;
}


export interface GqlMutationUploadBookingDocumentArgs {
  input: GqlUploadBookingDocumentInput;
}


export interface GqlMutationMarkPoLineAsHotArgs {
  input: GqlMarkPoLineAsHotInput;
}


export interface GqlMutationMarkBookingAsHotArgs {
  input: GqlMarkBookingAsHotInput;
}


export interface GqlMutationMarkPoAsHotArgs {
  input: GqlMarkPoAsHotInput;
}


export interface GqlMutationMarkContainerAsHotArgs {
  input: GqlMarkContainerAsHotInput;
}


export interface GqlMutationUploadPoFileArgs {
  file: Scalars['Upload'];
  networkId: Scalars['ID'];
}


export interface GqlMutationConfirmImportArgs {
  fileId: Scalars['ID'];
}


export interface GqlMutationCancelImportArgs {
  fileId: Scalars['ID'];
}


export interface GqlMutationSetHeaderMappingArgs {
  headerId: Scalars['ID'];
  omsKey?: Maybe<Scalars['String']>;
}


export interface GqlMutationPutReportArgs {
  report: GqlReportInput;
}


export interface GqlMutationSetReportScheduledArgs {
  id: Scalars['ID'];
  scheduled: Scalars['Boolean'];
}


export interface GqlMutationDeleteReportArgs {
  id: Scalars['ID'];
}

export interface GqlMutationConfirmation {
  __typename?: 'MutationConfirmation';
  success?: Maybe<Scalars['Boolean']>;
  message?: Maybe<Scalars['String']>;
}

export type GqlMutationError = GqlNotYetImplementedError;

export interface GqlMutationPayload {
  success: Scalars['Boolean'];
  message?: Maybe<Scalars['String']>;
  error?: Maybe<GqlMutationError>;
}

/**
 * A Network can be thought of as an isolated instance of OMS, each network has its own set of
 * distinct profiles that serve as the supply chain for the “Controlling Client” of the network.
 */
export interface GqlNetwork {
  __typename?: 'Network';
  id: Scalars['ID'];
  name: Scalars['String'];
  controllingClient: GqlProfile;
  connectedProfiles: Array<GqlProfile>;
  profileTypes: Array<GqlProfileType>;
  rules: GqlNetworkRules;
  contacts: Array<GqlContact>;
  items: Array<GqlItem>;
  containerTypes: Array<GqlNetworkContainerType>;
  documentTypes: Array<GqlNetworkDocumentType>;
}

export interface GqlNetworkContainerType {
  __typename?: 'NetworkContainerType';
  code: Scalars['String'];
  name: Scalars['String'];
  capacityVolumeM3: Scalars['Float'];
  capacityWeightKg: Scalars['Float'];
  ruleMinVolumeM3: Scalars['Float'];
  ruleMaxVolumeM3: Scalars['Float'];
  ruleMinWeightKg: Scalars['Float'];
  ruleMaxWeightKg: Scalars['Float'];
}

export interface GqlNetworkContainerTypeInput {
  code: Scalars['String'];
  name: Scalars['String'];
  capacityVolumeM3: Scalars['Float'];
  capacityWeightKg: Scalars['Float'];
  ruleMinVolumeM3: Scalars['Float'];
  ruleMaxVolumeM3: Scalars['Float'];
  ruleMinWeightKg: Scalars['Float'];
  ruleMaxWeightKg: Scalars['Float'];
}

export interface GqlNetworkDocumentType {
  __typename?: 'NetworkDocumentType';
  code: Scalars['String'];
  name: Scalars['String'];
}

export interface GqlNetworkDocumentTypeRule {
  __typename?: 'NetworkDocumentTypeRule';
  documentType: Scalars['String'];
  missingEnabled: Scalars['Boolean'];
  missingDays: Scalars['Int'];
  missingField: RuleFieldType;
}

export interface GqlNetworkDocumentTypeRuleInput {
  documentType: Scalars['String'];
  missingEnabled: Scalars['Boolean'];
  missingDays: Scalars['Int'];
  missingField: RuleFieldType;
}

export interface GqlNetworkInput {
  id?: Maybe<Scalars['ID']>;
  name?: Maybe<Scalars['String']>;
}

export interface GqlNetworkRules {
  __typename?: 'NetworkRules';
  poExpiredEnabled: Scalars['Boolean'];
  poExpiredDays: Scalars['Int'];
  poExpiredField: RuleFieldType;
  poFirstShipEnabled: Scalars['Boolean'];
  poFirstShipDays: Scalars['Int'];
  poFirstShipField: RuleFieldType;
  poLastShipEnabled: Scalars['Boolean'];
  poLastShipDays: Scalars['Int'];
  poLastShipField: RuleFieldType;
  poBookByEnabled: Scalars['Boolean'];
  poBookByDays: Scalars['Int'];
  poBookByField: RuleFieldType;
  poCriticalEnabled: Scalars['Boolean'];
  poCriticalDays: Scalars['Int'];
  poCriticalField: RuleFieldType;
  bookingLateEnabled: Scalars['Boolean'];
  bookingLateDays: Scalars['Int'];
  bookingLateField: RuleFieldType;
  bookingPendingConfEnabled: Scalars['Boolean'];
  bookingPendingConfDays: Scalars['Int'];
  bookingQtyToleranceEnabled: Scalars['Boolean'];
  bookingQtyTolerancePercentOver: Scalars['Float'];
  bookingQtyTolerancePercentUnder: Scalars['Float'];
  consolConsolableMoveTypesEnabled: Scalars['Boolean'];
  consolConsolableMoveTypes: Array<MoveType>;
  shipmentPolEtdChangeEnabled: Scalars['Boolean'];
  shipmentPolEtdChangeDays: Scalars['Int'];
  shipmentPodEtaChangeEnabled: Scalars['Boolean'];
  shipmentPodEtaChangeDays: Scalars['Int'];
  shipmentRevisedCrdChangeEnabled: Scalars['Boolean'];
  shipmentRevisedCrdChangeDays: Scalars['Int'];
  shipmentCriticalEnabled: Scalars['Boolean'];
  shipmentCriticalDays: Scalars['Int'];
  shipmentCriticalField1: RuleFieldType;
  shipmentCriticalField2: RuleFieldType;
  milestoneLateOnWaterEnabled: Scalars['Boolean'];
  milestoneLateOnWaterDays: Scalars['Int'];
  milestoneLateAtDischargeEnabled: Scalars['Boolean'];
  milestoneLateAtDischargeDays: Scalars['Int'];
  milestoneLateAtRampEnabled: Scalars['Boolean'];
  milestoneLateAtRampDays: Scalars['Int'];
  milestoneLateDeliveryEnabled: Scalars['Boolean'];
  milestoneLateDeliveryDays: Scalars['Int'];
  milestonePendingOutgateEnabled: Scalars['Boolean'];
  milestonePendingOutgateDays: Scalars['Int'];
  milestoneDelayedUnloadEnabled: Scalars['Boolean'];
  milestoneDelayedUnloadDays: Scalars['Int'];
  milestoneDelayedUnloadField: RuleFieldType;
  milestoneEmptyNotReturnedEnabled: Scalars['Boolean'];
  milestoneEmptyNotReturnedDays: Scalars['Int'];
  documentTypes: Array<GqlNetworkDocumentTypeRule>;
}

export interface GqlNetworkRulesInput {
  poExpiredEnabled: Scalars['Boolean'];
  poExpiredDays: Scalars['Int'];
  poExpiredField: RuleFieldType;
  poFirstShipEnabled: Scalars['Boolean'];
  poFirstShipDays: Scalars['Int'];
  poFirstShipField: RuleFieldType;
  poLastShipEnabled: Scalars['Boolean'];
  poLastShipDays: Scalars['Int'];
  poLastShipField: RuleFieldType;
  poBookByEnabled: Scalars['Boolean'];
  poBookByDays: Scalars['Int'];
  poBookByField: RuleFieldType;
  poCriticalEnabled: Scalars['Boolean'];
  poCriticalDays: Scalars['Int'];
  poCriticalField: RuleFieldType;
  bookingLateEnabled: Scalars['Boolean'];
  bookingLateDays: Scalars['Int'];
  bookingLateField: RuleFieldType;
  bookingPendingConfEnabled: Scalars['Boolean'];
  bookingPendingConfDays: Scalars['Int'];
  bookingQtyToleranceEnabled: Scalars['Boolean'];
  bookingQtyTolerancePercentOver: Scalars['Float'];
  bookingQtyTolerancePercentUnder: Scalars['Float'];
  consolConsolableMoveTypesEnabled: Scalars['Boolean'];
  consolConsolableMoveTypes: Array<MoveType>;
  shipmentPolEtdChangeEnabled: Scalars['Boolean'];
  shipmentPolEtdChangeDays: Scalars['Int'];
  shipmentPodEtaChangeEnabled: Scalars['Boolean'];
  shipmentPodEtaChangeDays: Scalars['Int'];
  shipmentRevisedCrdChangeEnabled: Scalars['Boolean'];
  shipmentRevisedCrdChangeDays: Scalars['Int'];
  shipmentCriticalEnabled: Scalars['Boolean'];
  shipmentCriticalDays: Scalars['Int'];
  shipmentCriticalField1: RuleFieldType;
  shipmentCriticalField2: RuleFieldType;
  milestoneLateOnWaterEnabled: Scalars['Boolean'];
  milestoneLateOnWaterDays: Scalars['Int'];
  milestoneLateAtDischargeEnabled: Scalars['Boolean'];
  milestoneLateAtDischargeDays: Scalars['Int'];
  milestoneLateAtRampEnabled: Scalars['Boolean'];
  milestoneLateAtRampDays: Scalars['Int'];
  milestoneLateDeliveryEnabled: Scalars['Boolean'];
  milestoneLateDeliveryDays: Scalars['Int'];
  milestonePendingOutgateEnabled: Scalars['Boolean'];
  milestonePendingOutgateDays: Scalars['Int'];
  milestoneDelayedUnloadEnabled: Scalars['Boolean'];
  milestoneDelayedUnloadDays: Scalars['Int'];
  milestoneDelayedUnloadField: RuleFieldType;
  milestoneEmptyNotReturnedEnabled: Scalars['Boolean'];
  milestoneEmptyNotReturnedDays: Scalars['Int'];
  documentTypes: Array<GqlNetworkDocumentTypeRuleInput>;
}

export interface GqlNewBookingContainersInput {
  bookingId: Scalars['ID'];
  containers: Array<GqlContainerInput>;
}

export interface GqlNewBookingContainersPayload extends GqlMutationPayload {
  __typename?: 'NewBookingContainersPayload';
  success: Scalars['Boolean'];
  message?: Maybe<Scalars['String']>;
  error?: Maybe<GqlMutationError>;
  container?: Maybe<GqlContainer>;
}

/**
 * Required Fields:
 *   BookingInput.cargoReadyDate
 *   LogisticsInput.transportationMode
 *   LogisticsInput.containerMode
 *   LogisticsInput.contractType
 * Booking Input Required fields: cargoReadyDate
 */
export interface GqlNewBookingInput {
  booking: GqlBookingInput;
  logistics: GqlLogisticsInput;
  packLines: Array<GqlPackLineInput>;
  containers?: Maybe<Array<GqlContainerInput>>;
  remark?: Maybe<GqlRemarkInput>;
  relatedParties: Array<GqlRelatedPartyInput>;
}

export interface GqlNewBookingNotification extends GqlNotification {
  __typename?: 'NewBookingNotification';
  id: Scalars['ID'];
  notificationCode: NotificationCode;
  createTime: Scalars['Timestamp'];
  booking: GqlBooking;
}

export interface GqlNewBookingPayload extends GqlMutationPayload {
  __typename?: 'NewBookingPayload';
  success: Scalars['Boolean'];
  message?: Maybe<Scalars['String']>;
  error?: Maybe<GqlMutationError>;
  booking?: Maybe<GqlBooking>;
}

export interface GqlNewBookingRemarkInput {
  bookingId: Scalars['ID'];
  remark: GqlRemarkInput;
}

export interface GqlNewBookingRemarkPayload extends GqlMutationPayload {
  __typename?: 'NewBookingRemarkPayload';
  success: Scalars['Boolean'];
  message?: Maybe<Scalars['String']>;
  error?: Maybe<GqlMutationError>;
  remark?: Maybe<GqlRemark>;
}

export interface GqlNewConsolidationContainersInput {
  consolidationId: Scalars['ID'];
  containers: Array<GqlContainerInput>;
}

export interface GqlNewConsolidationContainersPayload extends GqlMutationPayload {
  __typename?: 'NewConsolidationContainersPayload';
  success: Scalars['Boolean'];
  message?: Maybe<Scalars['String']>;
  error?: Maybe<GqlMutationError>;
  containers?: Maybe<Array<GqlContainer>>;
}

export interface GqlNewConsolidationInput {
  bookingIds: Array<Scalars['ID']>;
  logistics: GqlLogisticsInput;
  containers?: Maybe<Array<GqlContainerInput>>;
  remark?: Maybe<GqlRemarkInput>;
  relatedParties: Array<GqlRelatedPartyInput>;
}

export interface GqlNewConsolidationPayload extends GqlMutationPayload {
  __typename?: 'NewConsolidationPayload';
  success: Scalars['Boolean'];
  message?: Maybe<Scalars['String']>;
  error?: Maybe<GqlMutationError>;
  consolidation?: Maybe<GqlConsolidation>;
}

export interface GqlNewConsolidationRemarkInput {
  consolidationId: Scalars['ID'];
  remark: GqlRemarkInput;
}

export interface GqlNewConsolidationRemarkPayload extends GqlMutationPayload {
  __typename?: 'NewConsolidationRemarkPayload';
  success: Scalars['Boolean'];
  message?: Maybe<Scalars['String']>;
  error?: Maybe<GqlMutationError>;
  remark?: Maybe<GqlRemark>;
}

export interface GqlNewDocumentNotification extends GqlNotification {
  __typename?: 'NewDocumentNotification';
  id: Scalars['ID'];
  notificationCode: NotificationCode;
  createTime: Scalars['Timestamp'];
  document: GqlDocument;
  booking: GqlBooking;
}

export interface GqlNewHblRemarkInput {
  hblId: Scalars['ID'];
  remark: GqlRemarkInput;
}

export interface GqlNewHblRemarkPayload extends GqlMutationPayload {
  __typename?: 'NewHblRemarkPayload';
  success: Scalars['Boolean'];
  message?: Maybe<Scalars['String']>;
  error?: Maybe<GqlMutationError>;
  remark?: Maybe<GqlRemark>;
}

export interface GqlNewMblRemarkInput {
  mblId: Scalars['ID'];
  remark: GqlRemarkInput;
}

export interface GqlNewMblRemarkPayload extends GqlMutationPayload {
  __typename?: 'NewMblRemarkPayload';
  success: Scalars['Boolean'];
  message?: Maybe<Scalars['String']>;
  error?: Maybe<GqlMutationError>;
  remark?: Maybe<GqlRemark>;
}

export interface GqlNewNetworkConnectedProfileInput {
  networkId: Scalars['ID'];
  connectedProfile: GqlProfileInput;
  profileMainLocation: GqlLocationInput;
  profileMainContact: GqlContactInput;
}

export interface GqlNewNetworkConnectedProfilePayload extends GqlMutationPayload {
  __typename?: 'NewNetworkConnectedProfilePayload';
  success: Scalars['Boolean'];
  message?: Maybe<Scalars['String']>;
  error?: Maybe<GqlMutationError>;
  profile?: Maybe<GqlProfile>;
}

export interface GqlNewNetworkInput {
  network: GqlNetworkInput;
  controllingClient: GqlProfileInput;
  controllingClientMainLocation: GqlLocationInput;
  controllingClientMainContact: GqlContactInput;
  selectedTemplateIds: Array<Scalars['ID']>;
}

export interface GqlNewNetworkPayload extends GqlMutationPayload {
  __typename?: 'NewNetworkPayload';
  success: Scalars['Boolean'];
  message?: Maybe<Scalars['String']>;
  error?: Maybe<GqlMutationError>;
  network?: Maybe<GqlNetwork>;
}

export interface GqlNewNetworkProfileTypeInput {
  networkId: Scalars['ID'];
  profileType: GqlProfileTypeInput;
  roles?: Maybe<Array<GqlRoleInput>>;
}

export interface GqlNewNetworkProfileTypePayload extends GqlMutationPayload {
  __typename?: 'NewNetworkProfileTypePayload';
  success: Scalars['Boolean'];
  message?: Maybe<Scalars['String']>;
  error?: Maybe<GqlMutationError>;
  profileType?: Maybe<GqlProfileType>;
}

export interface GqlNewProfileContactInput {
  profileId: Scalars['ID'];
  contact: GqlContactInput;
}

export interface GqlNewProfileContactPayload extends GqlMutationPayload {
  __typename?: 'NewProfileContactPayload';
  success: Scalars['Boolean'];
  message?: Maybe<Scalars['String']>;
  error?: Maybe<GqlMutationError>;
  contact?: Maybe<GqlContact>;
}

export interface GqlNewProfileLocationInput {
  profileId: Scalars['ID'];
  location: GqlLocationInput;
}

export interface GqlNewProfileLocationPayload extends GqlMutationPayload {
  __typename?: 'NewProfileLocationPayload';
  success: Scalars['Boolean'];
  message?: Maybe<Scalars['String']>;
  error?: Maybe<GqlMutationError>;
  location?: Maybe<GqlLocation>;
}

export interface GqlNewProfileRelationInput {
  profileId: Scalars['ID'];
  relatedProfileId: Scalars['ID'];
  partyType: PartyType;
}

export interface GqlNewProfileRelationPayload extends GqlMutationPayload {
  __typename?: 'NewProfileRelationPayload';
  success: Scalars['Boolean'];
  message?: Maybe<Scalars['String']>;
  error?: Maybe<GqlMutationError>;
  relatedProfile?: Maybe<GqlRelatedProfile>;
}

export interface GqlNewProfileRoleInput {
  profileId: Scalars['ID'];
  role: GqlRoleInput;
}

export interface GqlNewProfileRolePayload extends GqlMutationPayload {
  __typename?: 'NewProfileRolePayload';
  success: Scalars['Boolean'];
  message?: Maybe<Scalars['String']>;
  error?: Maybe<GqlMutationError>;
  role?: Maybe<GqlRole>;
}

export interface GqlNewProfileTypeDefaultRoleInput {
  profileTypeId: Scalars['ID'];
  role: GqlRoleInput;
}

export interface GqlNewProfileTypeDefaultRolePayload extends GqlMutationPayload {
  __typename?: 'NewProfileTypeDefaultRolePayload';
  success: Scalars['Boolean'];
  message?: Maybe<Scalars['String']>;
  error?: Maybe<GqlMutationError>;
  role?: Maybe<GqlRole>;
}

export interface GqlNewProfileTypeTemplateInput {
  profileType: GqlProfileTypeInput;
}

export interface GqlNewProfileTypeTemplatePayload extends GqlMutationPayload {
  __typename?: 'NewProfileTypeTemplatePayload';
  success: Scalars['Boolean'];
  message?: Maybe<Scalars['String']>;
  error?: Maybe<GqlMutationError>;
  template?: Maybe<GqlProfileType>;
}

export interface GqlNotYetImplementedError {
  __typename?: 'NotYetImplementedError';
  message?: Maybe<Scalars['String']>;
}

export interface GqlNotification {
  id: Scalars['ID'];
  notificationCode: NotificationCode;
  createTime: Scalars['Timestamp'];
}

export enum NotificationCode {
  BookingNew = 'BOOKING_NEW',
  LogisticsArrivedAtPod = 'LOGISTICS_ARRIVED_AT_POD',
  LogisticsArrivedAtFinalRamp = 'LOGISTICS_ARRIVED_AT_FINAL_RAMP',
  DocumentNew = 'DOCUMENT_NEW',
  DocumentMissing = 'DOCUMENT_MISSING',
  ContainerEmptyNotReturned = 'CONTAINER_EMPTY_NOT_RETURNED'
}

export interface GqlNotificationCodeInfo {
  __typename?: 'NotificationCodeInfo';
  code: NotificationCode;
  group: Scalars['String'];
  name: Scalars['String'];
  description: Scalars['String'];
}

export enum NotificationFrequency {
  Immediately = 'IMMEDIATELY',
  Daily = 'DAILY',
  Weekly = 'WEEKLY'
}

export interface GqlOpenOrder {
  __typename?: 'OpenOrder';
  id: Scalars['ID'];
  orderLine: GqlOrderLine;
  openOrderStatus: OrderStatus;
  orderQty: Scalars['Int'];
  approvedQty: Scalars['Int'];
  balanceQty: Scalars['Int'];
  shippedQty: Scalars['Int'];
  shippedCartons: Scalars['Int'];
  firstShipDate?: Maybe<Scalars['Date']>;
  lastShipDate?: Maybe<Scalars['Date']>;
  bookByDate?: Maybe<Scalars['Date']>;
  isHot: Scalars['Boolean'];
  hotMarkedBy?: Maybe<Scalars['String']>;
  hotMarkedTimestamp?: Maybe<Scalars['Timestamp']>;
}

export interface GqlOrderLine {
  __typename?: 'OrderLine';
  id: Scalars['ID'];
  lineNumber: Scalars['String'];
  lineStatus: OrderStatus;
  purchaseOrder: GqlPurchaseOrder;
  item: GqlItem;
  itemNumber: Scalars['String'];
  itemDescription: Scalars['String'];
  itemHtsCode: Scalars['String'];
  itemStyle: Scalars['String'];
  itemSize: Scalars['String'];
  itemColor: Scalars['String'];
  itemMaterial: Scalars['String'];
  itemCategoryCode: Scalars['String'];
  itemLength?: Maybe<Scalars['Float']>;
  itemWidth?: Maybe<Scalars['Float']>;
  itemHeight?: Maybe<Scalars['Float']>;
  itemDimsUnit: DimsUnit;
  itemWeight?: Maybe<Scalars['Float']>;
  itemWeightUnit: WeightUnit;
  itemVolume?: Maybe<Scalars['Float']>;
  itemVolumeUnit: VolumeUnit;
  itemDuty?: Maybe<Scalars['Float']>;
  itemDutyCurrencyCode: Currency;
  totalQty?: Maybe<Scalars['Int']>;
  innerQty?: Maybe<Scalars['Int']>;
  outerQty?: Maybe<Scalars['Int']>;
  weight?: Maybe<Scalars['Float']>;
  weightUnit: WeightUnit;
  volume?: Maybe<Scalars['Float']>;
  volumeUnit: VolumeUnit;
  unitPrice?: Maybe<Scalars['Float']>;
  unitPriceCurrencyCode: Currency;
  expectedCargoReadyDate?: Maybe<Scalars['Date']>;
  revisedCargoReadyDate?: Maybe<Scalars['Date']>;
  indcDate?: Maybe<Scalars['Date']>;
  shipToLocation?: Maybe<GqlLocation>;
  bookByDate?: Maybe<Scalars['Date']>;
  firstShipDate?: Maybe<Scalars['Date']>;
  lastShipDate?: Maybe<Scalars['Date']>;
  openOrder: GqlOpenOrder;
  packLines: Array<GqlPackLine>;
}

export enum OrderStatus {
  Open = 'OPEN',
  Closed = 'CLOSED',
  Critical = 'CRITICAL',
  Expired = 'EXPIRED',
  Cancelled = 'CANCELLED'
}

export interface GqlPackLine {
  __typename?: 'PackLine';
  id: Scalars['ID'];
  lineNumber: Scalars['Int'];
  orderLine: GqlOrderLine;
  requestedDeliveryDate?: Maybe<Scalars['Date']>;
  linePrice?: Maybe<Scalars['Float']>;
  shippedQty: Scalars['Int'];
  shippedCartons: Scalars['Int'];
  volume: Scalars['Float'];
  volumeUnit: VolumeUnit;
  weight: Scalars['Float'];
  weightUnit: WeightUnit;
  vanPositions: Array<GqlVanPosition>;
  booking: GqlBooking;
}

export interface GqlPackLineInput {
  id?: Maybe<Scalars['ID']>;
  lineNumber?: Maybe<Scalars['Int']>;
  requestedDeliveryDate?: Maybe<Scalars['Date']>;
  shippedQty?: Maybe<Scalars['Int']>;
  shippedCartons?: Maybe<Scalars['Int']>;
  volume?: Maybe<Scalars['Float']>;
  volumeUnit?: Maybe<VolumeUnit>;
  weight?: Maybe<Scalars['Float']>;
  weightUnit?: Maybe<WeightUnit>;
  orderLineId?: Maybe<Scalars['ID']>;
}

export enum PartyType {
  /** All POs must have a CONSIGNEE aka IMPORTER */
  Consignee = 'CONSIGNEE',
  /** All POs must have a SUPPLIER aka CONSIGNOR aka VENDOR */
  Supplier = 'SUPPLIER',
  /** Similar to a PO Supplier but used for Booking onward */
  Shipper = 'SHIPPER',
  Notify = 'NOTIFY',
  /** The one making the goods. aka Factory */
  Manufacturer = 'MANUFACTURER',
  /** It's who you are buying stuff from on the SUPPLIER/VENDOR. */
  BuyingAgent = 'BUYING_AGENT',
  /** The one in charge of buying stuff for the client/consignee */
  Buyer = 'BUYER',
  /** The final end customer i.e. who placed an order with the CONSIGNEE */
  Customer = 'CUSTOMER',
  /** Catch all for a related party */
  Related = 'RELATED',
  /** Helps the SUPPLIER i.e. WWL is a Origin Agent */
  OriginAgent = 'ORIGIN_AGENT',
  /** Helps the CONSIGNEE i.e. UWL is a Destination Agent */
  DestinationAgent = 'DESTINATION_AGENT'
}

export enum PaymentType {
  Prepaid = 'PREPAID',
  Collect = 'COLLECT'
}

export interface GqlPendingOutgateException {
  __typename?: 'PendingOutgateException';
  type: ExceptionType;
  container: GqlContainer;
  notificationId?: Maybe<Scalars['ID']>;
  markedAsRead?: Maybe<Scalars['Timestamp']>;
}

/**
 * A permission serves as our smallest unit that can be assigned to a profile type or role. The
 * combination of permissions, profile relationships, and network rules serve as the place we can
 * enforce logic for particular actions that can be taken by users in the system.
 */
export interface GqlPermission {
  __typename?: 'Permission';
  code: PermissionCode;
  group: Scalars['String'];
  name: Scalars['String'];
  description: Scalars['String'];
}

export enum PermissionCode {
  NetworkRead = 'NETWORK_READ',
  NetworkUpdate = 'NETWORK_UPDATE',
  ProfileRead = 'PROFILE_READ',
  ProfileCreate = 'PROFILE_CREATE',
  ProfileUpdate = 'PROFILE_UPDATE',
  ProfileDelete = 'PROFILE_DELETE',
  ProfileRoleRead = 'PROFILE_ROLE_READ',
  ProfileRoleCreate = 'PROFILE_ROLE_CREATE',
  ProfileRoleUpdate = 'PROFILE_ROLE_UPDATE',
  ProfileRoleDelete = 'PROFILE_ROLE_DELETE',
  ProfileLocationRead = 'PROFILE_LOCATION_READ',
  ProfileLocationCreate = 'PROFILE_LOCATION_CREATE',
  ProfileLocationUpdate = 'PROFILE_LOCATION_UPDATE',
  ProfileLocationDelete = 'PROFILE_LOCATION_DELETE',
  ProfileRelatedProfilesRead = 'PROFILE_RELATED_PROFILES_READ',
  ProfileRelatedProfilesCreate = 'PROFILE_RELATED_PROFILES_CREATE',
  ProfileRelatedProfilesUpdate = 'PROFILE_RELATED_PROFILES_UPDATE',
  ProfileRelatedProfilesDelete = 'PROFILE_RELATED_PROFILES_DELETE',
  ProfileContactRead = 'PROFILE_CONTACT_READ',
  ProfileContactCreate = 'PROFILE_CONTACT_CREATE',
  ProfileContactUpdate = 'PROFILE_CONTACT_UPDATE',
  ProfileContactDelete = 'PROFILE_CONTACT_DELETE',
  ProfileTypeRead = 'PROFILE_TYPE_READ',
  ProfileTypeCreate = 'PROFILE_TYPE_CREATE',
  ProfileTypeUpdate = 'PROFILE_TYPE_UPDATE',
  ProfileTypeDelete = 'PROFILE_TYPE_DELETE',
  ProfileTypeRoleRead = 'PROFILE_TYPE_ROLE_READ',
  ProfileTypeRoleCreate = 'PROFILE_TYPE_ROLE_CREATE',
  ProfileTypeRoleUpdate = 'PROFILE_TYPE_ROLE_UPDATE',
  ProfileTypeRoleDelete = 'PROFILE_TYPE_ROLE_DELETE',
  PoRead = 'PO_READ',
  PoRelatedPartiesOverride = 'PO_RELATED_PARTIES_OVERRIDE',
  PoMarkAsHot = 'PO_MARK_AS_HOT',
  PoImportRead = 'PO_IMPORT_READ',
  PoImportUpload = 'PO_IMPORT_UPLOAD',
  PoImportMapFields = 'PO_IMPORT_MAP_FIELDS',
  PoImportConfirm = 'PO_IMPORT_CONFIRM',
  PoImportCancel = 'PO_IMPORT_CANCEL',
  BookingCreate = 'BOOKING_CREATE',
  BookingRead = 'BOOKING_READ',
  BookingUpdate = 'BOOKING_UPDATE',
  BookingApprove = 'BOOKING_APPROVE',
  BookingConfirm = 'BOOKING_CONFIRM',
  BookingContainerCreate = 'BOOKING_CONTAINER_CREATE',
  BookingContainerRead = 'BOOKING_CONTAINER_READ',
  BookingContainerUpdate = 'BOOKING_CONTAINER_UPDATE',
  BookingContainerDelete = 'BOOKING_CONTAINER_DELETE',
  BookingRemarkCreate = 'BOOKING_REMARK_CREATE',
  BookingRemarkRead = 'BOOKING_REMARK_READ',
  BookingLogisticsRead = 'BOOKING_LOGISTICS_READ',
  BookingLogisticsUpdate = 'BOOKING_LOGISTICS_UPDATE',
  BookingRelatedPartiesOverride = 'BOOKING_RELATED_PARTIES_OVERRIDE',
  BookingMarkAsHot = 'BOOKING_MARK_AS_HOT',
  ContainerRead = 'CONTAINER_READ',
  ContainerVanPositionsReadAll = 'CONTAINER_VAN_POSITIONS_READ_ALL',
  ContainerVanPositionsReadOwn = 'CONTAINER_VAN_POSITIONS_READ_OWN',
  ContainerMarkAsHot = 'CONTAINER_MARK_AS_HOT',
  ConsolidationCreate = 'CONSOLIDATION_CREATE',
  ConsolidationRead = 'CONSOLIDATION_READ',
  ConsolidationUpdate = 'CONSOLIDATION_UPDATE',
  ConsolidationApprove = 'CONSOLIDATION_APPROVE',
  ConsolidationConfirm = 'CONSOLIDATION_CONFIRM',
  ConsolidationContainerCreate = 'CONSOLIDATION_CONTAINER_CREATE',
  ConsolidationContainerRead = 'CONSOLIDATION_CONTAINER_READ',
  ConsolidationContainerUpdate = 'CONSOLIDATION_CONTAINER_UPDATE',
  ConsolidationContainerDelete = 'CONSOLIDATION_CONTAINER_DELETE',
  ConsolidationContainerAssign = 'CONSOLIDATION_CONTAINER_ASSIGN',
  ConsolidationRemarkCreate = 'CONSOLIDATION_REMARK_CREATE',
  ConsolidationRemarkRead = 'CONSOLIDATION_REMARK_READ',
  ConsolidationLogisticsRead = 'CONSOLIDATION_LOGISTICS_READ',
  ConsolidationLogisticsUpdate = 'CONSOLIDATION_LOGISTICS_UPDATE',
  ConsolidationRelatedPartiesOverride = 'CONSOLIDATION_RELATED_PARTIES_OVERRIDE',
  HblRead = 'HBL_READ',
  HblRemarkCreate = 'HBL_REMARK_CREATE',
  HblRemarkRead = 'HBL_REMARK_READ',
  HblRelatedPartiesOverride = 'HBL_RELATED_PARTIES_OVERRIDE',
  MblRead = 'MBL_READ',
  MblRemarkCreate = 'MBL_REMARK_CREATE',
  MblRemarkRead = 'MBL_REMARK_READ',
  MblRelatedPartiesOverride = 'MBL_RELATED_PARTIES_OVERRIDE',
  GlobalUpdate = 'GLOBAL_UPDATE',
  DocumentModule = 'DOCUMENT_MODULE',
  DocumentUpload = 'DOCUMENT_UPLOAD',
  ReportsRead = 'REPORTS_READ',
  ReportsCreate = 'REPORTS_CREATE',
  ReportsUpdate = 'REPORTS_UPDATE',
  ReportsDelete = 'REPORTS_DELETE'
}

export interface GqlPoHistoryEntry {
  __typename?: 'PoHistoryEntry';
  id: Scalars['ID'];
  timestamp: Scalars['Timestamp'];
  recordType: HistoryRecordType;
  message: Scalars['String'];
  byUserName?: Maybe<Scalars['String']>;
  byPoUploadFileName?: Maybe<Scalars['String']>;
}

export interface GqlPoImportField {
  __typename?: 'PoImportField';
  key: Scalars['String'];
  name: Scalars['String'];
  group: GqlPoImportFieldGroup;
}

export interface GqlPoImportFieldGroup {
  __typename?: 'PoImportFieldGroup';
  key: Scalars['String'];
  name: Scalars['String'];
  fields: Array<GqlPoImportField>;
}

export interface GqlPoImportFile {
  __typename?: 'PoImportFile';
  id: Scalars['ID'];
  fileName: Scalars['String'];
  importFileName: Scalars['String'];
  extension: Scalars['String'];
  url: Scalars['String'];
  fileStatus: ImportFileStatus;
  uploadDate: Scalars['Timestamp'];
  totalPos: Scalars['Int'];
  totalLines: Scalars['Int'];
  poErrors: Scalars['Int'];
  lineErrors: Scalars['Int'];
  newPos: Scalars['Int'];
  updatedPos: Scalars['Int'];
  newLines: Scalars['Int'];
  updatedLines: Scalars['Int'];
  network: GqlNetwork;
  uploadedBy: GqlUser;
  headers: Array<GqlPoImportHeader>;
  rows: Array<Array<Scalars['String']>>;
  errors: Array<GqlPoImportFileError>;
}

export interface GqlPoImportFileError {
  __typename?: 'PoImportFileError';
  rowIndex: Scalars['Int'];
  headerIndex: Scalars['Int'];
  error: Scalars['String'];
}

export interface GqlPoImportHeader {
  __typename?: 'PoImportHeader';
  id: Scalars['ID'];
  name: Scalars['String'];
  headerIndex: Scalars['Int'];
  mapping?: Maybe<GqlPoImportField>;
}

export interface GqlPort {
  __typename?: 'Port';
  code: Scalars['String'];
  name: Scalars['String'];
  latitude?: Maybe<Scalars['Float']>;
  longitude?: Maybe<Scalars['Float']>;
  stateCode?: Maybe<Scalars['String']>;
  stateName?: Maybe<Scalars['String']>;
  countryCode?: Maybe<Scalars['String']>;
  countryName?: Maybe<Scalars['String']>;
}

/**
 * A Profile is an organization, entity, or group of people who perform a particular role in the
 * system. For example, Trademark or UWL.
 *
 * As a note, if a particular profile would be a part of multiple networks
 * (in the case of UWL for example) there will be a distinct profile in each network. This is to
 * allow a particular profile to have different actions in each network based on the workflow of the
 * controlling client.
 */
export interface GqlProfile {
  __typename?: 'Profile';
  id: Scalars['ID'];
  name: Scalars['String'];
  profileCode: Scalars['String'];
  nameAliases: Array<Scalars['String']>;
  profileCodeAliases: Array<Scalars['String']>;
  profileType?: Maybe<GqlProfileType>;
  isControllingClient: Scalars['Boolean'];
  logo?: Maybe<GqlImage>;
  needsUpdate: Scalars['Boolean'];
  locations: Array<GqlLocation>;
  contacts: Array<GqlContact>;
  relatedProfiles: Array<GqlRelatedProfile>;
  roles: Array<GqlRole>;
  network: GqlNetwork;
  cwOrgs: Array<GqlCwOrg>;
}

export interface GqlProfileInput {
  id?: Maybe<Scalars['ID']>;
  name?: Maybe<Scalars['String']>;
  profileCode?: Maybe<Scalars['String']>;
  profileTypeId?: Maybe<Scalars['ID']>;
  nameAliases?: Maybe<Array<Scalars['String']>>;
  profileCodeAliases?: Maybe<Array<Scalars['String']>>;
  logo?: Maybe<Scalars['Upload']>;
}

/**
 * A Profile type is a collection of permissions and alerts that can be assigned to a profile in a
 * network. By assigning a particular profile type to a profile, that profile assumes the functions
 * outlined in that profile type.
 *
 * For example, "Factory A" is created and assigned a profile type named Factory, it could be said that
 * "Factory A" is a Factory. A caveat to this is that profile types can be created and named anything
 * the user wants to allow ultimate flexibility. So they cannot safely be used for the purposes of
 * determining how a particular profile is related to any given record in the system. All profile types
 * do is drive which actions users/contacts that belong to the profile will be able to take in the
 * system.
 *
 * At the system level, there exists Profile Type Templates that can be configured to help with the
 * setup of a new network in the system.
 *
 * At the time a network is created, the user can select which templates they would like to be copied
 * in to the network as a starting point for configuration. Once the network has been created, the
 * profile types that were created inside the network are independent of the templates they were
 * based off of. The reason for this is so that a networks profile types can be customized to the
 * needs of that controlling client. Pushing down updates from the Template level could cause a lot
 * of issues in trying to ensure some customization isn't overwritten.
 */
export interface GqlProfileType {
  __typename?: 'ProfileType';
  id: Scalars['ID'];
  name: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  isTemplate: Scalars['Boolean'];
  defaultRoles: Array<GqlRole>;
  permissionCodes: Array<PermissionCode>;
  notificationCodes: Array<NotificationCode>;
  dashboardFeatureCodes: Array<DashboardFeatureCode>;
}

export interface GqlProfileTypeInput {
  id?: Maybe<Scalars['ID']>;
  name?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  permissionCodes?: Maybe<Array<PermissionCode>>;
  notificationCodes?: Maybe<Array<NotificationCode>>;
  dashboardFeatureCodes?: Maybe<Array<DashboardFeatureCode>>;
}

export interface GqlPurchaseOrder {
  __typename?: 'PurchaseOrder';
  id: Scalars['ID'];
  poNumber: Scalars['String'];
  poStatus: OrderStatus;
  poDate?: Maybe<Scalars['Date']>;
  poType?: Maybe<Scalars['String']>;
  altPoNumber?: Maybe<Scalars['String']>;
  customerPoNumber?: Maybe<Scalars['String']>;
  expectedCargoReadyDate?: Maybe<Scalars['Date']>;
  indcDate?: Maybe<Scalars['Date']>;
  dcOpenDate?: Maybe<Scalars['Date']>;
  dcCloseDate?: Maybe<Scalars['Date']>;
  customerStartShipDate?: Maybe<Scalars['Date']>;
  customerCancelDate?: Maybe<Scalars['Date']>;
  originCountry?: Maybe<Scalars['String']>;
  shipToLocation?: Maybe<GqlLocation>;
  pol?: Maybe<GqlPort>;
  pod?: Maybe<GqlPort>;
  incoTerm?: Maybe<IncoTerm>;
  htsCode?: Maybe<Scalars['String']>;
  orderLines: Array<GqlOrderLine>;
  relatedParties: Array<GqlRelatedParty>;
  poHistory: Array<GqlPoHistoryEntry>;
  isHot: Scalars['Boolean'];
  hotMarkedBy?: Maybe<Scalars['String']>;
  hotMarkedTimestamp?: Maybe<Scalars['Timestamp']>;
}

export interface GqlQuery {
  __typename?: 'Query';
  notifications: Array<GqlNotification>;
  notificationCodes: Array<GqlNotificationCodeInfo>;
  availableConsolidationBookings: Array<GqlBooking>;
  booking?: Maybe<GqlBooking>;
  carriers: Array<GqlCarrier>;
  consolidation?: Maybe<GqlConsolidation>;
  container?: Maybe<GqlContainer>;
  containers: Array<GqlContainer>;
  exceptions: Array<GqlException>;
  hbl?: Maybe<GqlHouseBill>;
  mbl?: Maybe<GqlMasterBill>;
  networks: Array<GqlNetwork>;
  network?: Maybe<GqlNetwork>;
  openOrders: Array<GqlOpenOrder>;
  permissions: Array<GqlPermission>;
  systemPermissions: Array<GqlSystemPermission>;
  locationTypes: Array<GqlLocationTypeInfo>;
  ruleFieldTypes: Array<GqlRuleFieldTypeInfo>;
  dashboardFeatureCodes: Array<GqlDashboardFeatureCodeInfo>;
  shipments: Array<GqlShipment>;
  poImportFiles: Array<GqlPoImportFile>;
  poImportFile?: Maybe<GqlPoImportFile>;
  poImportFieldGroups: Array<GqlPoImportFieldGroup>;
  profile?: Maybe<GqlProfile>;
  profiles: Array<GqlProfile>;
  profileType?: Maybe<GqlProfileType>;
  profileTypes: Array<GqlProfileType>;
  profileTypeTemplates: Array<GqlProfileType>;
  purchaseOrder?: Maybe<GqlPurchaseOrder>;
  purchaseOrders: Array<GqlPurchaseOrder>;
  user?: Maybe<GqlUser>;
  userContext?: Maybe<GqlUserContext>;
  users: Array<GqlUser>;
  vessels: Array<GqlVessel>;
  documentSearch: Array<GqlDocumentRecord>;
  globalUpdate: GqlGlobalUpdateResults;
  globalUpdateSearch: Array<GqlGlobalUpdateRecord>;
  newBookings: Array<GqlBooking>;
  newDocuments: Array<GqlDocumentRecord>;
  quickSearch: GqlQuickSearchResults;
  search: Array<GqlSearchResult>;
  searchPorts: Array<GqlPort>;
  dashboardPurchaseOrders: Array<GqlDashboardPurchaseOrder>;
  shipmentsInTransit: Array<GqlDashboardShipmentInTransit>;
  bookingSummary: Array<GqlDashboardBookingSummary>;
  report: GqlReport;
  reports: Array<GqlReport>;
  datasets: Array<GqlDataset>;
  /** @deprecated dev */
  allNetworks: Array<GqlNetwork>;
}


export interface GqlQueryBookingArgs {
  bookingId: Scalars['ID'];
}


export interface GqlQueryConsolidationArgs {
  consolId: Scalars['ID'];
}


export interface GqlQueryContainerArgs {
  containerId: Scalars['ID'];
}


export interface GqlQueryHblArgs {
  hblId: Scalars['ID'];
}


export interface GqlQueryMblArgs {
  mblId: Scalars['ID'];
}


export interface GqlQueryNetworkArgs {
  networkId?: Maybe<Scalars['ID']>;
}


export interface GqlQueryOpenOrdersArgs {
  orderLineIds?: Maybe<Array<Scalars['ID']>>;
}


export interface GqlQueryShipmentsArgs {
  shipment_filter: GqlShipmentFilter;
}


export interface GqlQueryPoImportFileArgs {
  poImportFileId: Scalars['ID'];
}


export interface GqlQueryProfileArgs {
  profileId: Scalars['ID'];
}


export interface GqlQueryProfileTypeArgs {
  profileTypeId: Scalars['ID'];
}


export interface GqlQueryPurchaseOrderArgs {
  purchaseOrderId: Scalars['ID'];
}


export interface GqlQueryUserArgs {
  userId: Scalars['ID'];
}


export interface GqlQueryDocumentSearchArgs {
  filter: GqlDocumentFilter;
}


export interface GqlQueryGlobalUpdateArgs {
  recordIds: Array<Scalars['ID']>;
}


export interface GqlQueryGlobalUpdateSearchArgs {
  searchType: SearchType;
  searchId: Scalars['ID'];
}


export interface GqlQueryQuickSearchArgs {
  searchType: SearchType;
  searchId: Scalars['ID'];
}


export interface GqlQuerySearchArgs {
  searchType: SearchType;
  query: Scalars['String'];
  numResults?: Maybe<Scalars['Int']>;
}


export interface GqlQuerySearchPortsArgs {
  query: Scalars['String'];
}


export interface GqlQueryBookingSummaryArgs {
  startDate?: Maybe<Scalars['Date']>;
  endDate?: Maybe<Scalars['Date']>;
}


export interface GqlQueryReportArgs {
  id: Scalars['ID'];
}

export interface GqlQuickSearchBooking {
  __typename?: 'QuickSearchBooking';
  shipmentId: Scalars['ID'];
  orderLineId: Scalars['ID'];
  referenceNumber?: Maybe<Scalars['String']>;
  mblId?: Maybe<Scalars['ID']>;
  mblNumber?: Maybe<Scalars['String']>;
  cargoReadyDate?: Maybe<Scalars['Date']>;
  shipToLocationName?: Maybe<Scalars['String']>;
  carrierName?: Maybe<Scalars['String']>;
  polCode?: Maybe<Scalars['String']>;
  polEtd?: Maybe<Scalars['Date']>;
  polAtd?: Maybe<Scalars['Date']>;
  podCode?: Maybe<Scalars['String']>;
  podEta?: Maybe<Scalars['Date']>;
  podAta?: Maybe<Scalars['Date']>;
}

export interface GqlQuickSearchContainer {
  __typename?: 'QuickSearchContainer';
  containerId: Scalars['ID'];
  orderLineId: Scalars['ID'];
  containerNumber?: Maybe<Scalars['String']>;
  railRamp?: Maybe<Scalars['String']>;
  railRampEta?: Maybe<Scalars['Date']>;
  railRampAta?: Maybe<Scalars['Date']>;
  deliveryLocation?: Maybe<Scalars['String']>;
  finalEta?: Maybe<Scalars['Date']>;
  finalAta?: Maybe<Scalars['Date']>;
}

export interface GqlQuickSearchPo {
  __typename?: 'QuickSearchPo';
  id: Scalars['ID'];
  orderLineId: Scalars['ID'];
  poNumber?: Maybe<Scalars['String']>;
  poDate?: Maybe<Scalars['Date']>;
  supplierName?: Maybe<Scalars['String']>;
  itemNumber?: Maybe<Scalars['String']>;
  cargoReadyDate?: Maybe<Scalars['Date']>;
  firstShipDate?: Maybe<Scalars['Date']>;
  lastShipDate?: Maybe<Scalars['Date']>;
  shipToLocationName?: Maybe<Scalars['String']>;
}

export interface GqlQuickSearchResults {
  __typename?: 'QuickSearchResults';
  searchType: SearchType;
  searchTerm: Scalars['String'];
  poCount: Scalars['Int'];
  purchaseOrders: Array<GqlQuickSearchPo>;
  containerCount: Scalars['Int'];
  containers: Array<GqlQuickSearchContainer>;
  bookingCount: Scalars['Int'];
  bookings: Array<GqlQuickSearchBooking>;
  shipmentCount: Scalars['Int'];
  shipments: Array<GqlQuickSearchShipment>;
}

export interface GqlQuickSearchShipment {
  __typename?: 'QuickSearchShipment';
  shipmentId: Scalars['ID'];
  orderLineId: Scalars['ID'];
  referenceNumber?: Maybe<Scalars['String']>;
  mblId?: Maybe<Scalars['ID']>;
  mblNumber?: Maybe<Scalars['String']>;
  cargoReadyDate?: Maybe<Scalars['Date']>;
  shipToLocationName?: Maybe<Scalars['String']>;
  carrierName?: Maybe<Scalars['String']>;
  polCode?: Maybe<Scalars['String']>;
  polEtd?: Maybe<Scalars['Date']>;
  polAtd?: Maybe<Scalars['Date']>;
  podCode?: Maybe<Scalars['String']>;
  podEta?: Maybe<Scalars['Date']>;
  podAta?: Maybe<Scalars['Date']>;
}

export interface GqlRelatedParty {
  __typename?: 'RelatedParty';
  id: Scalars['ID'];
  partyType: PartyType;
  party: GqlProfile;
}

export interface GqlRelatedPartyInput {
  id?: Maybe<Scalars['ID']>;
  partyType?: Maybe<PartyType>;
  partyId?: Maybe<Scalars['ID']>;
}

export interface GqlRelatedProfile {
  __typename?: 'RelatedProfile';
  id: Scalars['ID'];
  partyType: PartyType;
  profile: GqlProfile;
}

export enum ReleaseType {
  Tlx = 'TLX',
  Obo = 'OBO'
}

export interface GqlRemark {
  __typename?: 'Remark';
  id: Scalars['ID'];
  text: Scalars['String'];
  createDate: Scalars['Timestamp'];
  createdBy: GqlContact;
}

export interface GqlRemarkInput {
  id?: Maybe<Scalars['ID']>;
  text?: Maybe<Scalars['String']>;
}

export interface GqlRemoveBookingRelatedPartyInput {
  relatedPartyId: Scalars['ID'];
}

export interface GqlRemoveBookingRelatedPartyPayload extends GqlMutationPayload {
  __typename?: 'RemoveBookingRelatedPartyPayload';
  success: Scalars['Boolean'];
  message?: Maybe<Scalars['String']>;
  error?: Maybe<GqlMutationError>;
  relatedPartyId?: Maybe<Scalars['ID']>;
}

export interface GqlRemoveConsolidationRelatedPartyInput {
  relatedPartyId: Scalars['ID'];
}

export interface GqlRemoveConsolidationRelatedPartyPayload extends GqlMutationPayload {
  __typename?: 'RemoveConsolidationRelatedPartyPayload';
  success: Scalars['Boolean'];
  message?: Maybe<Scalars['String']>;
  error?: Maybe<GqlMutationError>;
  relatedPartyId?: Maybe<Scalars['ID']>;
}

export interface GqlRemoveHblRelatedPartyInput {
  relatedPartyId: Scalars['ID'];
}

export interface GqlRemoveHblRelatedPartyPayload extends GqlMutationPayload {
  __typename?: 'RemoveHblRelatedPartyPayload';
  success: Scalars['Boolean'];
  message?: Maybe<Scalars['String']>;
  error?: Maybe<GqlMutationError>;
  relatedPartyId?: Maybe<Scalars['ID']>;
}

export interface GqlRemoveMblRelatedPartyInput {
  relatedPartyId: Scalars['ID'];
}

export interface GqlRemoveMblRelatedPartyPayload extends GqlMutationPayload {
  __typename?: 'RemoveMblRelatedPartyPayload';
  success: Scalars['Boolean'];
  message?: Maybe<Scalars['String']>;
  error?: Maybe<GqlMutationError>;
  relatedPartyId?: Maybe<Scalars['ID']>;
}

export interface GqlRemoveProfileRelationInput {
  relationId: Scalars['ID'];
}

export interface GqlRemoveProfileRelationPayload extends GqlMutationPayload {
  __typename?: 'RemoveProfileRelationPayload';
  success: Scalars['Boolean'];
  message?: Maybe<Scalars['String']>;
  error?: Maybe<GqlMutationError>;
  relatedProfileId?: Maybe<Scalars['ID']>;
}

export interface GqlReport {
  __typename?: 'Report';
  id: Scalars['ID'];
  profileId: Scalars['ID'];
  name: Scalars['String'];
  scheduled: Scalars['Boolean'];
  period: Scalars['String'];
  weekdays: Array<Scalars['String']>;
  days: Array<Scalars['Int']>;
  timeHour: Scalars['Int'];
  timeMinute: Scalars['Int'];
  timezone: Scalars['String'];
  datasetId: Scalars['String'];
  fields: Array<Scalars['String']>;
  fieldAliases: Array<GqlReportFieldAlias>;
  filters: Array<GqlDatasetFilter>;
  recipientContactIds: Array<Scalars['ID']>;
  recipientEmails: Array<Scalars['String']>;
  nextRun?: Maybe<Scalars['Timestamp']>;
  lastRun?: Maybe<Scalars['Timestamp']>;
  lastError?: Maybe<Scalars['String']>;
}

export interface GqlReportFieldAlias {
  __typename?: 'ReportFieldAlias';
  field: Scalars['String'];
  name: Scalars['String'];
}

export interface GqlReportFieldAliasInput {
  field: Scalars['String'];
  name: Scalars['String'];
}

export interface GqlReportInput {
  id?: Maybe<Scalars['ID']>;
  name: Scalars['String'];
  scheduled: Scalars['Boolean'];
  period: Scalars['String'];
  weekdays: Array<Scalars['String']>;
  days: Array<Scalars['Int']>;
  timeHour: Scalars['Int'];
  timeMinute: Scalars['Int'];
  timezone: Scalars['String'];
  datasetId: Scalars['String'];
  fields: Array<Scalars['String']>;
  fieldAliases: Array<GqlReportFieldAliasInput>;
  filters: Array<GqlDatasetFilterInput>;
  recipientContactIds: Array<Scalars['ID']>;
  recipientEmails: Array<Scalars['String']>;
}

/**
 * A role is a subset of the permissions and alerts that have been assigned to a profile type. Roles
 * are assigned to a contact in order to define what actions a user is allowed to take.
 *
 * Custom roles can be created for a particular profile or a default role can be defined in the
 * profile type. Default roles are available for all profiles created using that profile type.
 */
export interface GqlRole {
  __typename?: 'Role';
  id: Scalars['ID'];
  name: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  isDefaultRole: Scalars['Boolean'];
  permissionCodes: Array<PermissionCode>;
  notificationCodes: Array<NotificationCode>;
  documentTypesCanView: Array<Scalars['String']>;
  dashboardFeatureCodes: Array<DashboardFeatureCode>;
}

export interface GqlRoleInput {
  id?: Maybe<Scalars['ID']>;
  name?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  permissionCodes?: Maybe<Array<PermissionCode>>;
  notificationCodes?: Maybe<Array<NotificationCode>>;
  documentTypesCanView?: Maybe<Array<Scalars['String']>>;
  dashboardFeatureCodes?: Maybe<Array<DashboardFeatureCode>>;
}

export enum RuleFieldType {
  PoDate = 'PO_DATE',
  PoExpectedCrd = 'PO_EXPECTED_CRD',
  PoRevisedCrd = 'PO_REVISED_CRD',
  PoIndc = 'PO_INDC',
  PoFirstShip = 'PO_FIRST_SHIP',
  PoLastShip = 'PO_LAST_SHIP',
  PoBookBy = 'PO_BOOK_BY',
  BookingCreate = 'BOOKING_CREATE',
  BookingCrd = 'BOOKING_CRD',
  BookingRevisedCrd = 'BOOKING_REVISED_CRD',
  BookingConfirmationDate = 'BOOKING_CONFIRMATION_DATE',
  LogisticsCyCutoff = 'LOGISTICS_CY_CUTOFF',
  LogisticsCfsCutoff = 'LOGISTICS_CFS_CUTOFF',
  LogisticsCfsReceived = 'LOGISTICS_CFS_RECEIVED',
  LogisticsVgmCutoff = 'LOGISTICS_VGM_CUTOFF',
  LogisticsSiCutoff = 'LOGISTICS_SI_CUTOFF',
  LogisticsPolEtd = 'LOGISTICS_POL_ETD',
  LogisticsPolAtd = 'LOGISTICS_POL_ATD',
  LogisticsPodEta = 'LOGISTICS_POD_ETA',
  LogisticsPodAta = 'LOGISTICS_POD_ATA',
  LogisticsRampEta = 'LOGISTICS_RAMP_ETA',
  LogisticsRampAta = 'LOGISTICS_RAMP_ATA',
  LogisticsDeliveryEta = 'LOGISTICS_DELIVERY_ETA',
  LogisticsDeliveryAta = 'LOGISTICS_DELIVERY_ATA',
  ContainerShipFromFactory = 'CONTAINER_SHIP_FROM_FACTORY',
  ContainerTerminalReceived = 'CONTAINER_TERMINAL_RECEIVED',
  ContainerRampEta = 'CONTAINER_RAMP_ETA',
  ContainerRampAta = 'CONTAINER_RAMP_ATA',
  ContainerRampOutGate = 'CONTAINER_RAMP_OUT_GATE',
  ContainerPortOutGate = 'CONTAINER_PORT_OUT_GATE',
  ContainerDeliveryEta = 'CONTAINER_DELIVERY_ETA',
  ContainerDeliveryAta = 'CONTAINER_DELIVERY_ATA',
  ContainerDeliveryUnload = 'CONTAINER_DELIVERY_UNLOAD',
  ContainerEmptyNotify = 'CONTAINER_EMPTY_NOTIFY',
  ContainerEmptyPickup = 'CONTAINER_EMPTY_PICKUP',
  ContainerEmptyReturned = 'CONTAINER_EMPTY_RETURNED',
  ContainerDemurrageLastFreeDate = 'CONTAINER_DEMURRAGE_LAST_FREE_DATE',
  ContainerDetentionLastFreeDate = 'CONTAINER_DETENTION_LAST_FREE_DATE',
  ContainerConsolidatorLastFreeDate = 'CONTAINER_CONSOLIDATOR_LAST_FREE_DATE'
}

export interface GqlRuleFieldTypeInfo {
  __typename?: 'RuleFieldTypeInfo';
  code: RuleFieldType;
  group: Scalars['String'];
  name: Scalars['String'];
  description: Scalars['String'];
}

export interface GqlSearchResult {
  __typename?: 'SearchResult';
  id: Scalars['ID'];
  value: Scalars['String'];
}

export enum SearchType {
  Hbl = 'HBL',
  Mbl = 'MBL',
  VesselVoyage = 'VESSEL_VOYAGE',
  Vessel = 'VESSEL',
  Voyage = 'VOYAGE',
  Container = 'CONTAINER',
  Booking = 'BOOKING',
  BookingByHblMbl = 'BOOKING_BY_HBL_MBL',
  Po = 'PO',
  Sku = 'SKU'
}

export interface GqlSetActiveUserContactInput {
  contactId: Scalars['ID'];
}

export interface GqlSetActiveUserContactPayload extends GqlMutationPayload {
  __typename?: 'SetActiveUserContactPayload';
  success: Scalars['Boolean'];
  message?: Maybe<Scalars['String']>;
  error?: Maybe<GqlMutationError>;
  contact?: Maybe<GqlContact>;
}

export interface GqlSetPoRelatedPartiesInput {
  poId: Scalars['ID'];
  parties: Array<GqlSetPoRelatedPartiesInputParty>;
}

export interface GqlSetPoRelatedPartiesInputParty {
  profileId: Scalars['ID'];
  partyType: PartyType;
}

export interface GqlSetPoRelatedPartiesPayload extends GqlMutationPayload {
  __typename?: 'SetPoRelatedPartiesPayload';
  success: Scalars['Boolean'];
  message?: Maybe<Scalars['String']>;
  error?: Maybe<GqlMutationError>;
  relatedParties: Array<GqlRelatedParty>;
}

export interface GqlShipment {
  id: Scalars['ID'];
  referenceNumber: Scalars['String'];
  status: ShipmentStatus;
  createDate: Scalars['Timestamp'];
  bookingParty: GqlContact;
  logistics: GqlLogistics;
  remarks: Array<GqlRemark>;
  containers: Array<GqlContainer>;
  documents: Array<GqlDocument>;
  relatedParties: Array<GqlRelatedParty>;
}

export interface GqlShipmentFilter {
  type: ShipmentType;
  statuses: Array<ShipmentStatus>;
}

export enum ShipmentInTransitMilestone {
  OnWater = 'ON_WATER',
  AtDischarge = 'AT_DISCHARGE',
  AtRamp = 'AT_RAMP',
  Delivered = 'DELIVERED'
}

export enum ShipmentInTransitStatus {
  Early = 'EARLY',
  OnTime = 'ON_TIME',
  Late = 'LATE'
}

export enum ShipmentStatus {
  Booked = 'BOOKED',
  Pending = 'PENDING',
  Confirmed = 'CONFIRMED',
  Consolidated = 'CONSOLIDATED',
  Assigned = 'ASSIGNED',
  Shipped = 'SHIPPED'
}

export enum ShipmentType {
  Booking = 'BOOKING',
  Consolidation = 'CONSOLIDATION'
}

export interface GqlSystemPermission {
  __typename?: 'SystemPermission';
  code: SystemPermissionCode;
  group: Scalars['String'];
  name: Scalars['String'];
  description: Scalars['String'];
}

export enum SystemPermissionCode {
  NetworkManageAll = 'NETWORK_MANAGE_ALL',
  NetworkCreate = 'NETWORK_CREATE',
  NetworkDelete = 'NETWORK_DELETE',
  UsersManageAll = 'USERS_MANAGE_ALL',
  ProfileTypeTemplatesRead = 'PROFILE_TYPE_TEMPLATES_READ',
  ProfileTypeTemplatesCreate = 'PROFILE_TYPE_TEMPLATES_CREATE',
  ProfileTypeTemplatesUpdate = 'PROFILE_TYPE_TEMPLATES_UPDATE',
  ProfileTypeTemplatesDelete = 'PROFILE_TYPE_TEMPLATES_DELETE',
  ProfileTypeTemplatesRoleRead = 'PROFILE_TYPE_TEMPLATES_ROLE_READ',
  ProfileTypeTemplatesRoleCreate = 'PROFILE_TYPE_TEMPLATES_ROLE_CREATE',
  ProfileTypeTemplatesRoleUpdate = 'PROFILE_TYPE_TEMPLATES_ROLE_UPDATE',
  ProfileTypeTemplatesRoleDelete = 'PROFILE_TYPE_TEMPLATES_ROLE_DELETE'
}


export enum TransportMode {
  Air = 'AIR',
  Sea = 'SEA',
  Rail = 'RAIL',
  Road = 'ROAD'
}

export interface GqlUpdateBookingInput {
  booking: GqlBookingInput;
}

/**
 * Note: When assigning a vessel and voyage, they should be assigned in pairs. (i.e. Mother vessel
 * and Mother voyage must be assigned at the same time)
 */
export interface GqlUpdateBookingLogisticsInput {
  bookingId: Scalars['ID'];
  logistics: GqlLogisticsInput;
  hbl?: Maybe<GqlHblInput>;
  mbl?: Maybe<GqlMblInput>;
  motherVessel?: Maybe<GqlVesselInput>;
  motherVoyage?: Maybe<GqlVoyageInput>;
  feederVessel?: Maybe<GqlVesselInput>;
  feederVoyage?: Maybe<GqlVoyageInput>;
}

export interface GqlUpdateBookingLogisticsPayload extends GqlMutationPayload {
  __typename?: 'UpdateBookingLogisticsPayload';
  success: Scalars['Boolean'];
  message?: Maybe<Scalars['String']>;
  error?: Maybe<GqlMutationError>;
  logistics?: Maybe<GqlLogistics>;
}

export interface GqlUpdateBookingPayload extends GqlMutationPayload {
  __typename?: 'UpdateBookingPayload';
  success: Scalars['Boolean'];
  message?: Maybe<Scalars['String']>;
  error?: Maybe<GqlMutationError>;
  booking?: Maybe<GqlBooking>;
}

export interface GqlUpdateBookingRelatedPartyInput {
  bookingId: Scalars['ID'];
  relatedPartyId: Scalars['ID'];
  profileId?: Maybe<Scalars['ID']>;
  partyType?: Maybe<PartyType>;
}

export interface GqlUpdateBookingRelatedPartyPayload extends GqlMutationPayload {
  __typename?: 'UpdateBookingRelatedPartyPayload';
  success: Scalars['Boolean'];
  message?: Maybe<Scalars['String']>;
  error?: Maybe<GqlMutationError>;
  relatedParty?: Maybe<GqlRelatedParty>;
}

export interface GqlUpdateConsolidationInput {
  consolidation: GqlConsolidationInput;
}

export interface GqlUpdateConsolidationLogisticsInput {
  consolId: Scalars['ID'];
  logistics: GqlLogisticsInput;
  mbl?: Maybe<GqlMblInput>;
  hbls?: Maybe<Array<GqlConsolidationBookingHblInput>>;
  motherVessel?: Maybe<GqlVesselInput>;
  motherVoyage?: Maybe<GqlVoyageInput>;
  feederVessel?: Maybe<GqlVesselInput>;
  feederVoyage?: Maybe<GqlVoyageInput>;
}

export interface GqlUpdateConsolidationLogisticsPayload extends GqlMutationPayload {
  __typename?: 'UpdateConsolidationLogisticsPayload';
  success: Scalars['Boolean'];
  message?: Maybe<Scalars['String']>;
  error?: Maybe<GqlMutationError>;
  logistics?: Maybe<GqlLogistics>;
}

export interface GqlUpdateConsolidationPayload extends GqlMutationPayload {
  __typename?: 'UpdateConsolidationPayload';
  success: Scalars['Boolean'];
  message?: Maybe<Scalars['String']>;
  error?: Maybe<GqlMutationError>;
  consolidation?: Maybe<GqlConsolidation>;
}

export interface GqlUpdateConsolidationRelatedPartyInput {
  consolId: Scalars['ID'];
  relatedPartyId: Scalars['ID'];
  profileId?: Maybe<Scalars['ID']>;
  partyType?: Maybe<PartyType>;
}

export interface GqlUpdateConsolidationRelatedPartyPayload extends GqlMutationPayload {
  __typename?: 'UpdateConsolidationRelatedPartyPayload';
  success: Scalars['Boolean'];
  message?: Maybe<Scalars['String']>;
  error?: Maybe<GqlMutationError>;
  relatedParty?: Maybe<GqlRelatedParty>;
}

export interface GqlUpdateContactInput {
  contact: GqlContactInput;
}

export interface GqlUpdateContactPayload extends GqlMutationPayload {
  __typename?: 'UpdateContactPayload';
  success: Scalars['Boolean'];
  message?: Maybe<Scalars['String']>;
  error?: Maybe<GqlMutationError>;
  contact?: Maybe<GqlContact>;
}

export interface GqlUpdateContainerInput {
  container?: Maybe<GqlContainerInput>;
  vanPositions?: Maybe<Array<GqlVanPositionInput>>;
}

export interface GqlUpdateContainerPayload extends GqlMutationPayload {
  __typename?: 'UpdateContainerPayload';
  success: Scalars['Boolean'];
  message?: Maybe<Scalars['String']>;
  error?: Maybe<GqlMutationError>;
  container?: Maybe<GqlContainer>;
}

export interface GqlUpdateHblRelatedPartyInput {
  hblId: Scalars['ID'];
  relatedPartyId: Scalars['ID'];
  profileId?: Maybe<Scalars['ID']>;
  partyType?: Maybe<PartyType>;
}

export interface GqlUpdateHblRelatedPartyPayload extends GqlMutationPayload {
  __typename?: 'UpdateHblRelatedPartyPayload';
  success: Scalars['Boolean'];
  message?: Maybe<Scalars['String']>;
  error?: Maybe<GqlMutationError>;
  relatedParty?: Maybe<GqlRelatedParty>;
}

export interface GqlUpdateLocationInput {
  location: GqlLocationInput;
}

export interface GqlUpdateLocationPayload extends GqlMutationPayload {
  __typename?: 'UpdateLocationPayload';
  success: Scalars['Boolean'];
  message?: Maybe<Scalars['String']>;
  error?: Maybe<GqlMutationError>;
  location?: Maybe<GqlLocation>;
}

export interface GqlUpdateMblRelatedPartyInput {
  mblId: Scalars['ID'];
  relatedPartyId: Scalars['ID'];
  profileId?: Maybe<Scalars['ID']>;
  partyType?: Maybe<PartyType>;
}

export interface GqlUpdateMblRelatedPartyPayload extends GqlMutationPayload {
  __typename?: 'UpdateMblRelatedPartyPayload';
  success: Scalars['Boolean'];
  message?: Maybe<Scalars['String']>;
  error?: Maybe<GqlMutationError>;
  relatedParty?: Maybe<GqlRelatedParty>;
}

export interface GqlUpdateNetworkContainerTypesInput {
  networkId: Scalars['ID'];
  containerTypes: Array<GqlNetworkContainerTypeInput>;
}

export interface GqlUpdateNetworkContainerTypesPayload extends GqlMutationPayload {
  __typename?: 'UpdateNetworkContainerTypesPayload';
  success: Scalars['Boolean'];
  message?: Maybe<Scalars['String']>;
  error?: Maybe<GqlMutationError>;
  containerTypes?: Maybe<Array<GqlNetworkContainerType>>;
}

export interface GqlUpdateNetworkInput {
  network: GqlNetworkInput;
}

export interface GqlUpdateNetworkPayload extends GqlMutationPayload {
  __typename?: 'UpdateNetworkPayload';
  success: Scalars['Boolean'];
  message?: Maybe<Scalars['String']>;
  error?: Maybe<GqlMutationError>;
  network?: Maybe<GqlNetwork>;
}

export interface GqlUpdateNetworkRulesInput {
  networkId: Scalars['ID'];
  rules: GqlNetworkRulesInput;
}

export interface GqlUpdateNetworkRulesPayload extends GqlMutationPayload {
  __typename?: 'UpdateNetworkRulesPayload';
  success: Scalars['Boolean'];
  message?: Maybe<Scalars['String']>;
  error?: Maybe<GqlMutationError>;
  rules?: Maybe<GqlNetworkRules>;
}

export interface GqlUpdateProfileInput {
  profile: GqlProfileInput;
  profileMainLocation?: Maybe<GqlLocationInput>;
  profileMainContact?: Maybe<GqlContactInput>;
}

export interface GqlUpdateProfilePayload extends GqlMutationPayload {
  __typename?: 'UpdateProfilePayload';
  success: Scalars['Boolean'];
  message?: Maybe<Scalars['String']>;
  error?: Maybe<GqlMutationError>;
  profile?: Maybe<GqlProfile>;
}

export interface GqlUpdateProfileRelationInput {
  relationId: Scalars['ID'];
  partyType: PartyType;
}

export interface GqlUpdateProfileRelationPayload extends GqlMutationPayload {
  __typename?: 'UpdateProfileRelationPayload';
  success: Scalars['Boolean'];
  message?: Maybe<Scalars['String']>;
  error?: Maybe<GqlMutationError>;
  relatedProfile?: Maybe<GqlRelatedProfile>;
}

export interface GqlUpdateProfileTypeInput {
  profileType: GqlProfileTypeInput;
}

export interface GqlUpdateProfileTypePayload extends GqlMutationPayload {
  __typename?: 'UpdateProfileTypePayload';
  success: Scalars['Boolean'];
  message?: Maybe<Scalars['String']>;
  error?: Maybe<GqlMutationError>;
  profileType?: Maybe<GqlProfileType>;
}

export interface GqlUpdateRoleInput {
  role: GqlRoleInput;
}

export interface GqlUpdateRolePayload extends GqlMutationPayload {
  __typename?: 'UpdateRolePayload';
  success: Scalars['Boolean'];
  message?: Maybe<Scalars['String']>;
  error?: Maybe<GqlMutationError>;
  role?: Maybe<GqlRole>;
}

export interface GqlUpdateUserInput {
  user: GqlUserInput;
}

export interface GqlUpdateUserPayload extends GqlMutationPayload {
  __typename?: 'UpdateUserPayload';
  success: Scalars['Boolean'];
  message?: Maybe<Scalars['String']>;
  error?: Maybe<GqlMutationError>;
  user?: Maybe<GqlUser>;
}


export interface GqlUploadBookingDocumentInput {
  bookingId: Scalars['ID'];
  document: GqlDocumentInput;
  file: Scalars['Upload'];
}

export interface GqlUploadBookingDocumentPayload {
  __typename?: 'UploadBookingDocumentPayload';
  success: Scalars['Boolean'];
  message?: Maybe<Scalars['String']>;
  error?: Maybe<GqlMutationError>;
  document?: Maybe<GqlDocument>;
}

/**
 * A User represents a particular person that will be logging in to the system.
 *
 * In order to remove the need to require someone to have individual logins for each network they
 * are part of, a user can be related to multiple contacts in the system. This allows us in the
 * background to link a contact to a user when we find a match of the same emails. When a user logs
 * in, they are able to switch which network they are viewing the system as and changes which actions
 * they can perform based on the role that was assigned to that contact.
 */
export interface GqlUser {
  __typename?: 'User';
  id: Scalars['ID'];
  fullName?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  email: Scalars['String'];
  phoneMain?: Maybe<Scalars['String']>;
  phoneCell?: Maybe<Scalars['String']>;
  phoneAlternative?: Maybe<Scalars['String']>;
  extension?: Maybe<Scalars['String']>;
  isActive: Scalars['Boolean'];
  defaultContact: GqlContact;
  profileContacts: Array<GqlContact>;
  createDate: Scalars['Timestamp'];
  systemPermissionCodes: Array<SystemPermissionCode>;
}

export interface GqlUserContext {
  __typename?: 'UserContext';
  user?: Maybe<GqlUser>;
  activeContact?: Maybe<GqlContact>;
}

export interface GqlUserInput {
  id: Scalars['ID'];
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  phoneMain?: Maybe<Scalars['String']>;
  extension?: Maybe<Scalars['String']>;
  phoneCell?: Maybe<Scalars['String']>;
  phoneAlternative?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  credentials?: Maybe<GqlCredentialsInput>;
  defaultContactId?: Maybe<Scalars['ID']>;
  systemPermissionCodes?: Maybe<Array<SystemPermissionCode>>;
}

export enum VanCode {
  A1 = 'A1',
  B1 = 'B1',
  C1 = 'C1',
  D1 = 'D1',
  E1 = 'E1',
  A2 = 'A2',
  B2 = 'B2',
  C2 = 'C2',
  D2 = 'D2',
  E2 = 'E2'
}

export interface GqlVanPosition {
  __typename?: 'VanPosition';
  id: Scalars['ID'];
  piecesQty: Scalars['Int'];
  cartonsQty: Scalars['Int'];
  volume?: Maybe<Scalars['Float']>;
  volumeUnit?: Maybe<VolumeUnit>;
  weight?: Maybe<Scalars['Float']>;
  weightUnit?: Maybe<WeightUnit>;
  commercialInvoiceTotal?: Maybe<Scalars['Float']>;
  vanCode?: Maybe<VanCode>;
  packLine: GqlPackLine;
  container: GqlContainer;
}

export interface GqlVanPositionInput {
  id?: Maybe<Scalars['ID']>;
  piecesQty?: Maybe<Scalars['Int']>;
  cartonsQty?: Maybe<Scalars['Int']>;
  volume?: Maybe<Scalars['Float']>;
  volumeUnit?: Maybe<VolumeUnit>;
  weight?: Maybe<Scalars['Float']>;
  weightUnit?: Maybe<WeightUnit>;
  vanCode?: Maybe<VanCode>;
  packLineId?: Maybe<Scalars['ID']>;
}

export interface GqlVessel {
  __typename?: 'Vessel';
  id?: Maybe<Scalars['ID']>;
  name?: Maybe<Scalars['String']>;
  imo?: Maybe<Scalars['Int']>;
  voyages: Array<GqlVoyage>;
  carrier?: Maybe<GqlCarrier>;
}

export interface GqlVesselInput {
  id?: Maybe<Scalars['ID']>;
  name?: Maybe<Scalars['String']>;
  imo?: Maybe<Scalars['Int']>;
}

export enum VolumeUnit {
  Cbm = 'CBM',
  Cbi = 'CBI'
}

export interface GqlVoyage {
  __typename?: 'Voyage';
  id?: Maybe<Scalars['ID']>;
  name?: Maybe<Scalars['String']>;
  vessel?: Maybe<GqlVessel>;
  logistices: Array<GqlLogistics>;
}

export interface GqlVoyageInput {
  id?: Maybe<Scalars['ID']>;
  name?: Maybe<Scalars['String']>;
}

export enum WeightUnit {
  Kg = 'KG',
  Lb = 'LB',
  Oz = 'OZ'
}

export type GqlBookingListQueryVariables = Exact<{ [key: string]: never; }>;


export type GqlBookingListQuery = (
  { __typename?: 'Query' }
  & { shipments: Array<(
    { __typename?: 'Booking' }
    & Pick<GqlBooking, 'cargoReadyDate' | 'revisedCargoReadyDate' | 'isHot' | 'hotMarkedBy' | 'hotMarkedTimestamp' | 'id' | 'referenceNumber' | 'status' | 'createDate'>
    & { logistics: (
      { __typename?: 'Logistics' }
      & Pick<GqlLogistics, 'id' | 'confirmationNumber' | 'confirmationDate' | 'moveType' | 'transportationMode' | 'carrierConfirmationNumber' | 'cyCutoffDate' | 'polEtd' | 'polAtd'>
      & { carrier?: Maybe<(
        { __typename?: 'Carrier' }
        & Pick<GqlCarrier, 'id' | 'name'>
      )>, pickupLocation?: Maybe<(
        { __typename?: 'Location' }
        & Pick<GqlLocation, 'id' | 'name'>
      )>, deliveryLocation?: Maybe<(
        { __typename?: 'Location' }
        & Pick<GqlLocation, 'id' | 'name'>
      )>, finalDestination?: Maybe<(
        { __typename?: 'Location' }
        & Pick<GqlLocation, 'id' | 'name'>
      )>, pod?: Maybe<(
        { __typename?: 'Port' }
        & GqlPortFragment
      )>, pol?: Maybe<(
        { __typename?: 'Port' }
        & GqlPortFragment
      )> }
    ), containers: Array<(
      { __typename?: 'Container' }
      & Pick<GqlContainer, 'id' | 'sequential' | 'containerNumber' | 'containerType' | 'volume' | 'volumeUnit' | 'weight' | 'weightUnit' | 'isHot'>
    )>, relatedParties: Array<(
      { __typename?: 'RelatedParty' }
      & Pick<GqlRelatedParty, 'id' | 'partyType'>
      & { party: (
        { __typename?: 'Profile' }
        & Pick<GqlProfile, 'id' | 'name'>
        & { locations: Array<(
          { __typename?: 'Location' }
          & Pick<GqlLocation, 'id' | 'name'>
        )>, relatedProfiles: Array<(
          { __typename?: 'RelatedProfile' }
          & Pick<GqlRelatedProfile, 'id' | 'partyType'>
          & { profile: (
            { __typename?: 'Profile' }
            & Pick<GqlProfile, 'id'>
          ) }
        )>, network: (
          { __typename?: 'Network' }
          & Pick<GqlNetwork, 'id'>
          & { controllingClient: (
            { __typename?: 'Profile' }
            & Pick<GqlProfile, 'id' | 'name'>
          ) }
        ) }
      ) }
    )> }
  ) | (
    { __typename?: 'Consolidation' }
    & Pick<GqlConsolidation, 'id' | 'referenceNumber' | 'status' | 'createDate'>
    & { logistics: (
      { __typename?: 'Logistics' }
      & Pick<GqlLogistics, 'id' | 'confirmationNumber' | 'confirmationDate' | 'moveType' | 'transportationMode' | 'carrierConfirmationNumber' | 'cyCutoffDate' | 'polEtd' | 'polAtd'>
      & { carrier?: Maybe<(
        { __typename?: 'Carrier' }
        & Pick<GqlCarrier, 'id' | 'name'>
      )>, pickupLocation?: Maybe<(
        { __typename?: 'Location' }
        & Pick<GqlLocation, 'id' | 'name'>
      )>, deliveryLocation?: Maybe<(
        { __typename?: 'Location' }
        & Pick<GqlLocation, 'id' | 'name'>
      )>, finalDestination?: Maybe<(
        { __typename?: 'Location' }
        & Pick<GqlLocation, 'id' | 'name'>
      )>, pod?: Maybe<(
        { __typename?: 'Port' }
        & GqlPortFragment
      )>, pol?: Maybe<(
        { __typename?: 'Port' }
        & GqlPortFragment
      )> }
    ), containers: Array<(
      { __typename?: 'Container' }
      & Pick<GqlContainer, 'id' | 'sequential' | 'containerNumber' | 'containerType' | 'volume' | 'volumeUnit' | 'weight' | 'weightUnit' | 'isHot'>
    )>, relatedParties: Array<(
      { __typename?: 'RelatedParty' }
      & Pick<GqlRelatedParty, 'id' | 'partyType'>
      & { party: (
        { __typename?: 'Profile' }
        & Pick<GqlProfile, 'id' | 'name'>
        & { locations: Array<(
          { __typename?: 'Location' }
          & Pick<GqlLocation, 'id' | 'name'>
        )>, relatedProfiles: Array<(
          { __typename?: 'RelatedProfile' }
          & Pick<GqlRelatedProfile, 'id' | 'partyType'>
          & { profile: (
            { __typename?: 'Profile' }
            & Pick<GqlProfile, 'id'>
          ) }
        )>, network: (
          { __typename?: 'Network' }
          & Pick<GqlNetwork, 'id'>
          & { controllingClient: (
            { __typename?: 'Profile' }
            & Pick<GqlProfile, 'id' | 'name'>
          ) }
        ) }
      ) }
    )> }
  )> }
);

export type GqlShippedListQueryVariables = Exact<{ [key: string]: never; }>;


export type GqlShippedListQuery = (
  { __typename?: 'Query' }
  & { shipments: Array<(
    { __typename?: 'Booking' }
    & Pick<GqlBooking, 'cargoReadyDate' | 'revisedCargoReadyDate' | 'isHot' | 'hotMarkedBy' | 'hotMarkedTimestamp' | 'id' | 'referenceNumber' | 'status' | 'createDate'>
    & { logistics: (
      { __typename?: 'Logistics' }
      & Pick<GqlLogistics, 'id' | 'confirmationNumber' | 'confirmationDate' | 'moveType' | 'transportationMode' | 'carrierConfirmationNumber' | 'cyCutoffDate' | 'polEtd' | 'polAtd' | 'podEta' | 'podAta' | 'deliveryEta' | 'deliveryAta'>
      & { carrier?: Maybe<(
        { __typename?: 'Carrier' }
        & Pick<GqlCarrier, 'id' | 'name'>
      )>, pickupLocation?: Maybe<(
        { __typename?: 'Location' }
        & Pick<GqlLocation, 'id' | 'name'>
      )>, deliveryLocation?: Maybe<(
        { __typename?: 'Location' }
        & Pick<GqlLocation, 'id' | 'name'>
      )>, pod?: Maybe<(
        { __typename?: 'Port' }
        & GqlPortFragment
      )>, pol?: Maybe<(
        { __typename?: 'Port' }
        & GqlPortFragment
      )> }
    ), containers: Array<(
      { __typename?: 'Container' }
      & Pick<GqlContainer, 'id' | 'sequential' | 'containerNumber' | 'containerType' | 'volume' | 'volumeUnit' | 'weight' | 'weightUnit' | 'isHot'>
    )>, relatedParties: Array<(
      { __typename?: 'RelatedParty' }
      & Pick<GqlRelatedParty, 'partyType'>
      & { party: (
        { __typename?: 'Profile' }
        & Pick<GqlProfile, 'name'>
      ) }
    )> }
  ) | (
    { __typename?: 'Consolidation' }
    & Pick<GqlConsolidation, 'id' | 'referenceNumber' | 'status' | 'createDate'>
    & { logistics: (
      { __typename?: 'Logistics' }
      & Pick<GqlLogistics, 'id' | 'confirmationNumber' | 'confirmationDate' | 'moveType' | 'transportationMode' | 'carrierConfirmationNumber' | 'cyCutoffDate' | 'polEtd' | 'polAtd' | 'podEta' | 'podAta' | 'deliveryEta' | 'deliveryAta'>
      & { carrier?: Maybe<(
        { __typename?: 'Carrier' }
        & Pick<GqlCarrier, 'id' | 'name'>
      )>, pickupLocation?: Maybe<(
        { __typename?: 'Location' }
        & Pick<GqlLocation, 'id' | 'name'>
      )>, deliveryLocation?: Maybe<(
        { __typename?: 'Location' }
        & Pick<GqlLocation, 'id' | 'name'>
      )>, pod?: Maybe<(
        { __typename?: 'Port' }
        & GqlPortFragment
      )>, pol?: Maybe<(
        { __typename?: 'Port' }
        & GqlPortFragment
      )> }
    ), containers: Array<(
      { __typename?: 'Container' }
      & Pick<GqlContainer, 'id' | 'sequential' | 'containerNumber' | 'containerType' | 'volume' | 'volumeUnit' | 'weight' | 'weightUnit' | 'isHot'>
    )>, relatedParties: Array<(
      { __typename?: 'RelatedParty' }
      & Pick<GqlRelatedParty, 'partyType'>
      & { party: (
        { __typename?: 'Profile' }
        & Pick<GqlProfile, 'name'>
      ) }
    )> }
  )> }
);

export type GqlBookingQueryVariables = Exact<{
  bookingId: Scalars['ID'];
}>;


export type GqlBookingQuery = (
  { __typename?: 'Query' }
  & { booking?: Maybe<(
    { __typename?: 'Booking' }
    & Pick<GqlBooking, 'id' | 'referenceNumber' | 'status' | 'createDate' | 'incoTerm' | 'cargoReadyDate' | 'revisedCargoReadyDate' | 'isHot' | 'hotMarkedBy' | 'hotMarkedTimestamp'>
    & { bookingParty: (
      { __typename?: 'Contact' }
      & Pick<GqlContact, 'id' | 'firstName' | 'lastName'>
    ), mbl?: Maybe<(
      { __typename?: 'MasterBill' }
      & Pick<GqlMasterBill, 'id' | 'referenceNumber' | 'releaseType' | 'paymentType'>
      & { remarks: Array<(
        { __typename?: 'Remark' }
        & Pick<GqlRemark, 'id' | 'text' | 'createDate'>
        & { createdBy: (
          { __typename?: 'Contact' }
          & Pick<GqlContact, 'id' | 'firstName' | 'lastName'>
          & { profile: (
            { __typename?: 'Profile' }
            & Pick<GqlProfile, 'name'>
          ) }
        ) }
      )> }
    )>, hbl?: Maybe<(
      { __typename?: 'HouseBill' }
      & Pick<GqlHouseBill, 'id' | 'referenceNumber' | 'releaseType' | 'paymentType'>
      & { remarks: Array<(
        { __typename?: 'Remark' }
        & Pick<GqlRemark, 'id' | 'text' | 'createDate'>
        & { createdBy: (
          { __typename?: 'Contact' }
          & Pick<GqlContact, 'id' | 'firstName' | 'lastName'>
          & { profile: (
            { __typename?: 'Profile' }
            & Pick<GqlProfile, 'name'>
          ) }
        ) }
      )>, documents: Array<(
        { __typename?: 'Document' }
        & Pick<GqlDocument, 'id' | 'documentType' | 'importFileName' | 'fileName' | 'fileType' | 'fileSize' | 'uploadDate' | 'description'>
      )> }
    )>, packLines: Array<(
      { __typename?: 'PackLine' }
      & Pick<GqlPackLine, 'id' | 'requestedDeliveryDate' | 'shippedQty' | 'shippedCartons' | 'volume' | 'weight'>
      & { orderLine: (
        { __typename?: 'OrderLine' }
        & Pick<GqlOrderLine, 'id' | 'lineNumber' | 'unitPrice' | 'itemNumber' | 'itemDescription' | 'indcDate' | 'lastShipDate'>
        & { purchaseOrder: (
          { __typename?: 'PurchaseOrder' }
          & Pick<GqlPurchaseOrder, 'id' | 'poNumber' | 'htsCode'>
        ), shipToLocation?: Maybe<(
          { __typename?: 'Location' }
          & Pick<GqlLocation, 'id' | 'name'>
        )> }
      ) }
    )>, remarks: Array<(
      { __typename?: 'Remark' }
      & Pick<GqlRemark, 'id' | 'text' | 'createDate'>
      & { createdBy: (
        { __typename?: 'Contact' }
        & Pick<GqlContact, 'id' | 'firstName' | 'lastName'>
        & { profile: (
          { __typename?: 'Profile' }
          & Pick<GqlProfile, 'name'>
        ) }
      ) }
    )>, relatedParties: Array<(
      { __typename?: 'RelatedParty' }
      & Pick<GqlRelatedParty, 'id' | 'partyType'>
      & { party: (
        { __typename?: 'Profile' }
        & Pick<GqlProfile, 'id' | 'name'>
        & { locations: Array<(
          { __typename?: 'Location' }
          & Pick<GqlLocation, 'id' | 'name'>
        )> }
      ) }
    )>, logistics: (
      { __typename?: 'Logistics' }
      & Pick<GqlLogistics, 'id' | 'confirmationNumber' | 'confirmationDate' | 'carrierConfirmationNumber' | 'transportationMode' | 'moveType' | 'contractType' | 'contractNumber' | 'cyCutoffDate' | 'cfsCutoffDate' | 'cfsReceivedDate' | 'vgmCutoffDate' | 'siCutoffDate' | 'transitPortEta' | 'transitPortAta' | 'transitPortEtd' | 'transitPortAtd' | 'polEtd' | 'polAtd' | 'podEta' | 'rampEta' | 'deliveryEta'>
      & { pickupLocation?: Maybe<(
        { __typename?: 'Location' }
        & Pick<GqlLocation, 'name'>
      )>, deliveryLocation?: Maybe<(
        { __typename?: 'Location' }
        & Pick<GqlLocation, 'name'>
      )>, carrier?: Maybe<(
        { __typename?: 'Carrier' }
        & Pick<GqlCarrier, 'id' | 'name'>
      )>, motherVessel?: Maybe<(
        { __typename?: 'Vessel' }
        & Pick<GqlVessel, 'id' | 'name'>
        & { voyages: Array<(
          { __typename?: 'Voyage' }
          & Pick<GqlVoyage, 'id' | 'name'>
        )> }
      )>, motherVoyage?: Maybe<(
        { __typename?: 'Voyage' }
        & Pick<GqlVoyage, 'id' | 'name'>
      )>, feederVessel?: Maybe<(
        { __typename?: 'Vessel' }
        & Pick<GqlVessel, 'id' | 'name'>
        & { voyages: Array<(
          { __typename?: 'Voyage' }
          & Pick<GqlVoyage, 'id' | 'name'>
        )> }
      )>, feederVoyage?: Maybe<(
        { __typename?: 'Voyage' }
        & Pick<GqlVoyage, 'id' | 'name'>
      )>, transitPort?: Maybe<(
        { __typename?: 'Port' }
        & GqlPortFragment
      )>, pol?: Maybe<(
        { __typename?: 'Port' }
        & GqlPortFragment
      )>, pod?: Maybe<(
        { __typename?: 'Port' }
        & GqlPortFragment
      )>, ramp?: Maybe<(
        { __typename?: 'Port' }
        & GqlPortFragment
      )>, finalDestination?: Maybe<(
        { __typename?: 'Location' }
        & Pick<GqlLocation, 'id' | 'name'>
      )> }
    ), containers: Array<(
      { __typename?: 'Container' }
      & Pick<GqlContainer, 'id' | 'containerNumber' | 'containerType' | 'sealNumber' | 'volume' | 'weight' | 'shipFromFactoryDate' | 'terminalReceivedDate' | 'isHot'>
      & { vanPositions: Array<(
        { __typename?: 'VanPosition' }
        & Pick<GqlVanPosition, 'id' | 'volume' | 'weight' | 'piecesQty' | 'cartonsQty' | 'vanCode'>
        & { packLine: (
          { __typename?: 'PackLine' }
          & Pick<GqlPackLine, 'id'>
          & { orderLine: (
            { __typename?: 'OrderLine' }
            & Pick<GqlOrderLine, 'lineNumber' | 'itemNumber' | 'itemDescription'>
            & { purchaseOrder: (
              { __typename?: 'PurchaseOrder' }
              & Pick<GqlPurchaseOrder, 'poNumber'>
            ) }
          ) }
        ) }
      )> }
    )>, documents: Array<(
      { __typename?: 'Document' }
      & Pick<GqlDocument, 'id' | 'documentType' | 'importFileName' | 'fileName' | 'fileType' | 'fileSize' | 'uploadDate' | 'description'>
    )> }
  )> }
);

export type GqlNewBookingContainersMutationVariables = Exact<{
  input: GqlNewBookingContainersInput;
}>;


export type GqlNewBookingContainersMutation = (
  { __typename?: 'Mutation' }
  & { newBookingContainers: (
    { __typename?: 'NewBookingContainersPayload' }
    & Pick<GqlNewBookingContainersPayload, 'success'>
  ) }
);

export type GqlAssignBookingContainerMutationVariables = Exact<{
  input: GqlAssignBookingContainerInput;
}>;


export type GqlAssignBookingContainerMutation = (
  { __typename?: 'Mutation' }
  & { assignBookingContainer: (
    { __typename?: 'AssignBookingContainerPayload' }
    & Pick<GqlAssignBookingContainerPayload, 'success'>
  ) }
);

export type GqlDeleteContainerMutationVariables = Exact<{
  input: GqlDeleteContainerInput;
}>;


export type GqlDeleteContainerMutation = (
  { __typename?: 'Mutation' }
  & { deleteContainer: (
    { __typename?: 'DeleteContainerPayload' }
    & Pick<GqlDeleteContainerPayload, 'success'>
  ) }
);

export type GqlUpdateBookingMutationVariables = Exact<{
  input: GqlUpdateBookingInput;
}>;


export type GqlUpdateBookingMutation = (
  { __typename?: 'Mutation' }
  & { updateBooking: (
    { __typename?: 'UpdateBookingPayload' }
    & Pick<GqlUpdateBookingPayload, 'success'>
  ) }
);

export type GqlUpdateBookingLogisticsMutationVariables = Exact<{
  input: GqlUpdateBookingLogisticsInput;
}>;


export type GqlUpdateBookingLogisticsMutation = (
  { __typename?: 'Mutation' }
  & { updateBookingLogistics: (
    { __typename?: 'UpdateBookingLogisticsPayload' }
    & Pick<GqlUpdateBookingLogisticsPayload, 'success'>
  ) }
);

export type GqlSaveBookingMutationVariables = Exact<{
  input: GqlNewBookingInput;
}>;


export type GqlSaveBookingMutation = (
  { __typename?: 'Mutation' }
  & { newBooking: (
    { __typename?: 'NewBookingPayload' }
    & Pick<GqlNewBookingPayload, 'success' | 'message'>
    & { booking?: Maybe<(
      { __typename?: 'Booking' }
      & Pick<GqlBooking, 'id' | 'referenceNumber'>
    )> }
  ) }
);

export type GqlAssignBookingRelatedPartyMutationVariables = Exact<{
  input: GqlAssignBookingRelatedPartyInput;
}>;


export type GqlAssignBookingRelatedPartyMutation = (
  { __typename?: 'Mutation' }
  & { assignBookingRelatedParty: (
    { __typename?: 'AssignBookingRelatedPartyPayload' }
    & Pick<GqlAssignBookingRelatedPartyPayload, 'success' | 'message'>
  ) }
);

export type GqlRemoveBookingRelatedPartyMutationVariables = Exact<{
  input: GqlRemoveBookingRelatedPartyInput;
}>;


export type GqlRemoveBookingRelatedPartyMutation = (
  { __typename?: 'Mutation' }
  & { removeBookingRelatedParty: (
    { __typename?: 'RemoveBookingRelatedPartyPayload' }
    & Pick<GqlRemoveBookingRelatedPartyPayload, 'success' | 'message'>
  ) }
);

export type GqlUpdateBookingRelatedPartyMutationVariables = Exact<{
  input: GqlUpdateBookingRelatedPartyInput;
}>;


export type GqlUpdateBookingRelatedPartyMutation = (
  { __typename?: 'Mutation' }
  & { updateBookingRelatedParty: (
    { __typename?: 'UpdateBookingRelatedPartyPayload' }
    & Pick<GqlUpdateBookingRelatedPartyPayload, 'success' | 'message'>
  ) }
);

export type GqlNewBookingRemarkMutationVariables = Exact<{
  input: GqlNewBookingRemarkInput;
}>;


export type GqlNewBookingRemarkMutation = (
  { __typename?: 'Mutation' }
  & { newBookingRemark: (
    { __typename?: 'NewBookingRemarkPayload' }
    & Pick<GqlNewBookingRemarkPayload, 'success' | 'message'>
    & { remark?: Maybe<(
      { __typename?: 'Remark' }
      & Pick<GqlRemark, 'id' | 'text' | 'createDate'>
      & { createdBy: (
        { __typename?: 'Contact' }
        & Pick<GqlContact, 'firstName' | 'lastName' | 'title'>
        & { profile: (
          { __typename?: 'Profile' }
          & Pick<GqlProfile, 'name'>
        ) }
      ) }
    )> }
  ) }
);

export type GqlMarkBookingAsHotMutationVariables = Exact<{
  input: GqlMarkBookingAsHotInput;
}>;


export type GqlMarkBookingAsHotMutation = (
  { __typename?: 'Mutation' }
  & { markBookingAsHot: (
    { __typename?: 'MarkBookingAsHotPayload' }
    & Pick<GqlMarkBookingAsHotPayload, 'success' | 'message'>
    & { booking?: Maybe<(
      { __typename?: 'Booking' }
      & Pick<GqlBooking, 'isHot' | 'hotMarkedBy' | 'hotMarkedTimestamp'>
    )> }
  ) }
);

export type GqlConsolidationListQueryVariables = Exact<{ [key: string]: never; }>;


export type GqlConsolidationListQuery = (
  { __typename?: 'Query' }
  & { shipments: Array<(
    { __typename?: 'Booking' }
    & Pick<GqlBooking, 'id' | 'referenceNumber' | 'status' | 'createDate'>
    & { logistics: (
      { __typename?: 'Logistics' }
      & Pick<GqlLogistics, 'id' | 'confirmationNumber' | 'confirmationDate' | 'moveType' | 'transportationMode' | 'carrierConfirmationNumber' | 'cyCutoffDate' | 'polEtd' | 'polAtd'>
      & { carrier?: Maybe<(
        { __typename?: 'Carrier' }
        & Pick<GqlCarrier, 'id' | 'name'>
      )>, pickupLocation?: Maybe<(
        { __typename?: 'Location' }
        & Pick<GqlLocation, 'id' | 'name'>
      )>, deliveryLocation?: Maybe<(
        { __typename?: 'Location' }
        & Pick<GqlLocation, 'id' | 'name'>
      )>, pod?: Maybe<(
        { __typename?: 'Port' }
        & GqlPortFragment
      )>, pol?: Maybe<(
        { __typename?: 'Port' }
        & GqlPortFragment
      )> }
    ), containers: Array<(
      { __typename?: 'Container' }
      & Pick<GqlContainer, 'id' | 'sequential' | 'containerNumber' | 'containerType' | 'volume' | 'volumeUnit' | 'weight' | 'weightUnit' | 'isHot'>
    )>, relatedParties: Array<(
      { __typename?: 'RelatedParty' }
      & Pick<GqlRelatedParty, 'id' | 'partyType'>
      & { party: (
        { __typename?: 'Profile' }
        & Pick<GqlProfile, 'id' | 'name'>
        & { locations: Array<(
          { __typename?: 'Location' }
          & Pick<GqlLocation, 'id' | 'name'>
        )>, relatedProfiles: Array<(
          { __typename?: 'RelatedProfile' }
          & Pick<GqlRelatedProfile, 'id' | 'partyType'>
          & { profile: (
            { __typename?: 'Profile' }
            & Pick<GqlProfile, 'id'>
          ) }
        )> }
      ) }
    )> }
  ) | (
    { __typename?: 'Consolidation' }
    & Pick<GqlConsolidation, 'id' | 'referenceNumber' | 'status' | 'createDate'>
    & { bookings: Array<(
      { __typename?: 'Booking' }
      & { packLines: Array<(
        { __typename?: 'PackLine' }
        & Pick<GqlPackLine, 'linePrice'>
      )>, containers: Array<(
        { __typename?: 'Container' }
        & Pick<GqlContainer, 'id' | 'sequential' | 'containerNumber' | 'containerType' | 'volume' | 'volumeUnit' | 'weight' | 'weightUnit' | 'isHot'>
        & { vanPositions: Array<(
          { __typename?: 'VanPosition' }
          & Pick<GqlVanPosition, 'commercialInvoiceTotal'>
        )> }
      )> }
    )>, logistics: (
      { __typename?: 'Logistics' }
      & Pick<GqlLogistics, 'id' | 'confirmationNumber' | 'confirmationDate' | 'moveType' | 'transportationMode' | 'carrierConfirmationNumber' | 'cyCutoffDate' | 'polEtd' | 'polAtd'>
      & { carrier?: Maybe<(
        { __typename?: 'Carrier' }
        & Pick<GqlCarrier, 'id' | 'name'>
      )>, pickupLocation?: Maybe<(
        { __typename?: 'Location' }
        & Pick<GqlLocation, 'id' | 'name'>
      )>, deliveryLocation?: Maybe<(
        { __typename?: 'Location' }
        & Pick<GqlLocation, 'id' | 'name'>
      )>, pod?: Maybe<(
        { __typename?: 'Port' }
        & GqlPortFragment
      )>, pol?: Maybe<(
        { __typename?: 'Port' }
        & GqlPortFragment
      )> }
    ), containers: Array<(
      { __typename?: 'Container' }
      & Pick<GqlContainer, 'id' | 'sequential' | 'containerNumber' | 'containerType' | 'volume' | 'volumeUnit' | 'weight' | 'weightUnit' | 'isHot'>
    )>, relatedParties: Array<(
      { __typename?: 'RelatedParty' }
      & Pick<GqlRelatedParty, 'id' | 'partyType'>
      & { party: (
        { __typename?: 'Profile' }
        & Pick<GqlProfile, 'id' | 'name'>
        & { locations: Array<(
          { __typename?: 'Location' }
          & Pick<GqlLocation, 'id' | 'name'>
        )>, relatedProfiles: Array<(
          { __typename?: 'RelatedProfile' }
          & Pick<GqlRelatedProfile, 'id' | 'partyType'>
          & { profile: (
            { __typename?: 'Profile' }
            & Pick<GqlProfile, 'id'>
          ) }
        )> }
      ) }
    )> }
  )> }
);

export type GqlShippedConsolidationListQueryVariables = Exact<{ [key: string]: never; }>;


export type GqlShippedConsolidationListQuery = (
  { __typename?: 'Query' }
  & { shipments: Array<(
    { __typename?: 'Booking' }
    & Pick<GqlBooking, 'cargoReadyDate' | 'revisedCargoReadyDate' | 'id' | 'referenceNumber' | 'status' | 'createDate'>
    & { logistics: (
      { __typename?: 'Logistics' }
      & Pick<GqlLogistics, 'id' | 'confirmationNumber' | 'confirmationDate' | 'moveType' | 'transportationMode' | 'carrierConfirmationNumber' | 'cyCutoffDate' | 'polEtd' | 'polAtd'>
      & { carrier?: Maybe<(
        { __typename?: 'Carrier' }
        & Pick<GqlCarrier, 'id' | 'name'>
      )>, pickupLocation?: Maybe<(
        { __typename?: 'Location' }
        & Pick<GqlLocation, 'id' | 'name'>
      )>, deliveryLocation?: Maybe<(
        { __typename?: 'Location' }
        & Pick<GqlLocation, 'id' | 'name'>
      )>, pod?: Maybe<(
        { __typename?: 'Port' }
        & GqlPortFragment
      )>, pol?: Maybe<(
        { __typename?: 'Port' }
        & GqlPortFragment
      )> }
    ), containers: Array<(
      { __typename?: 'Container' }
      & Pick<GqlContainer, 'id' | 'sequential' | 'containerNumber' | 'containerType' | 'volume' | 'volumeUnit' | 'weight' | 'weightUnit' | 'isHot'>
    )>, relatedParties: Array<(
      { __typename?: 'RelatedParty' }
      & Pick<GqlRelatedParty, 'id' | 'partyType'>
      & { party: (
        { __typename?: 'Profile' }
        & Pick<GqlProfile, 'id' | 'name'>
        & { locations: Array<(
          { __typename?: 'Location' }
          & Pick<GqlLocation, 'id' | 'name'>
        )>, relatedProfiles: Array<(
          { __typename?: 'RelatedProfile' }
          & Pick<GqlRelatedProfile, 'id' | 'partyType'>
          & { profile: (
            { __typename?: 'Profile' }
            & Pick<GqlProfile, 'id'>
          ) }
        )> }
      ) }
    )> }
  ) | (
    { __typename?: 'Consolidation' }
    & Pick<GqlConsolidation, 'id' | 'referenceNumber' | 'status' | 'createDate'>
    & { bookings: Array<(
      { __typename?: 'Booking' }
      & { packLines: Array<(
        { __typename?: 'PackLine' }
        & Pick<GqlPackLine, 'linePrice'>
      )>, containers: Array<(
        { __typename?: 'Container' }
        & Pick<GqlContainer, 'id' | 'sequential' | 'containerNumber' | 'containerType' | 'volume' | 'volumeUnit' | 'weight' | 'weightUnit' | 'isHot'>
        & { vanPositions: Array<(
          { __typename?: 'VanPosition' }
          & Pick<GqlVanPosition, 'commercialInvoiceTotal'>
        )> }
      )> }
    )>, logistics: (
      { __typename?: 'Logistics' }
      & Pick<GqlLogistics, 'id' | 'confirmationNumber' | 'confirmationDate' | 'moveType' | 'transportationMode' | 'carrierConfirmationNumber' | 'cyCutoffDate' | 'polEtd' | 'polAtd'>
      & { carrier?: Maybe<(
        { __typename?: 'Carrier' }
        & Pick<GqlCarrier, 'id' | 'name'>
      )>, pickupLocation?: Maybe<(
        { __typename?: 'Location' }
        & Pick<GqlLocation, 'id' | 'name'>
      )>, deliveryLocation?: Maybe<(
        { __typename?: 'Location' }
        & Pick<GqlLocation, 'id' | 'name'>
      )>, pod?: Maybe<(
        { __typename?: 'Port' }
        & GqlPortFragment
      )>, pol?: Maybe<(
        { __typename?: 'Port' }
        & GqlPortFragment
      )> }
    ), containers: Array<(
      { __typename?: 'Container' }
      & Pick<GqlContainer, 'id' | 'sequential' | 'containerNumber' | 'containerType' | 'volume' | 'volumeUnit' | 'weight' | 'weightUnit' | 'isHot'>
    )>, relatedParties: Array<(
      { __typename?: 'RelatedParty' }
      & Pick<GqlRelatedParty, 'id' | 'partyType'>
      & { party: (
        { __typename?: 'Profile' }
        & Pick<GqlProfile, 'id' | 'name'>
        & { locations: Array<(
          { __typename?: 'Location' }
          & Pick<GqlLocation, 'id' | 'name'>
        )>, relatedProfiles: Array<(
          { __typename?: 'RelatedProfile' }
          & Pick<GqlRelatedProfile, 'id' | 'partyType'>
          & { profile: (
            { __typename?: 'Profile' }
            & Pick<GqlProfile, 'id'>
          ) }
        )> }
      ) }
    )> }
  )> }
);

export type GqlAvailableConsolidationBookingListQueryVariables = Exact<{ [key: string]: never; }>;


export type GqlAvailableConsolidationBookingListQuery = (
  { __typename?: 'Query' }
  & { availableConsolidationBookings: Array<(
    { __typename?: 'Booking' }
    & Pick<GqlBooking, 'id' | 'referenceNumber' | 'status' | 'createDate' | 'cargoReadyDate'>
    & { logistics: (
      { __typename?: 'Logistics' }
      & Pick<GqlLogistics, 'id' | 'moveType' | 'polEtd' | 'polAtd'>
      & { finalDestination?: Maybe<(
        { __typename?: 'Location' }
        & Pick<GqlLocation, 'name'>
      )>, pod?: Maybe<(
        { __typename?: 'Port' }
        & GqlPortFragment
      )>, pol?: Maybe<(
        { __typename?: 'Port' }
        & GqlPortFragment
      )> }
    ), packLines: Array<(
      { __typename?: 'PackLine' }
      & Pick<GqlPackLine, 'weight' | 'volume'>
    )>, containers: Array<(
      { __typename?: 'Container' }
      & Pick<GqlContainer, 'id' | 'containerType' | 'volume' | 'weight'>
    )>, relatedParties: Array<(
      { __typename?: 'RelatedParty' }
      & Pick<GqlRelatedParty, 'id' | 'partyType'>
      & { party: (
        { __typename?: 'Profile' }
        & Pick<GqlProfile, 'id' | 'name'>
        & { locations: Array<(
          { __typename?: 'Location' }
          & Pick<GqlLocation, 'id' | 'name'>
        )>, relatedProfiles: Array<(
          { __typename?: 'RelatedProfile' }
          & Pick<GqlRelatedProfile, 'id' | 'partyType'>
          & { profile: (
            { __typename?: 'Profile' }
            & Pick<GqlProfile, 'id'>
          ) }
        )> }
      ) }
    )> }
  )> }
);

export type GqlConsolidationQueryVariables = Exact<{
  consolId: Scalars['ID'];
}>;


export type GqlConsolidationQuery = (
  { __typename?: 'Query' }
  & { consolidation?: Maybe<(
    { __typename?: 'Consolidation' }
    & Pick<GqlConsolidation, 'id' | 'referenceNumber' | 'status' | 'createDate'>
    & { bookingParty: (
      { __typename?: 'Contact' }
      & Pick<GqlContact, 'id' | 'firstName' | 'lastName'>
    ), mbl?: Maybe<(
      { __typename?: 'MasterBill' }
      & Pick<GqlMasterBill, 'id' | 'referenceNumber' | 'releaseType' | 'paymentType'>
    )>, bookings: Array<(
      { __typename?: 'Booking' }
      & Pick<GqlBooking, 'id' | 'referenceNumber' | 'status' | 'createDate' | 'cargoReadyDate'>
      & { packLines: Array<(
        { __typename?: 'PackLine' }
        & Pick<GqlPackLine, 'id' | 'requestedDeliveryDate' | 'shippedQty' | 'shippedCartons' | 'volume' | 'weight'>
        & { orderLine: (
          { __typename?: 'OrderLine' }
          & Pick<GqlOrderLine, 'id' | 'lineNumber' | 'unitPrice' | 'itemNumber' | 'itemDescription' | 'indcDate' | 'lastShipDate'>
          & { purchaseOrder: (
            { __typename?: 'PurchaseOrder' }
            & Pick<GqlPurchaseOrder, 'id' | 'poNumber' | 'htsCode'>
          ), shipToLocation?: Maybe<(
            { __typename?: 'Location' }
            & Pick<GqlLocation, 'id' | 'name'>
          )> }
        ), booking: (
          { __typename?: 'Booking' }
          & Pick<GqlBooking, 'referenceNumber'>
        ) }
      )>, logistics: (
        { __typename?: 'Logistics' }
        & Pick<GqlLogistics, 'id' | 'moveType' | 'polEtd'>
        & { finalDestination?: Maybe<(
          { __typename?: 'Location' }
          & Pick<GqlLocation, 'name'>
        )>, pol?: Maybe<(
          { __typename?: 'Port' }
          & Pick<GqlPort, 'code'>
        )>, pod?: Maybe<(
          { __typename?: 'Port' }
          & Pick<GqlPort, 'code'>
        )> }
      ), remarks: Array<(
        { __typename?: 'Remark' }
        & Pick<GqlRemark, 'id' | 'text' | 'createDate'>
        & { createdBy: (
          { __typename?: 'Contact' }
          & Pick<GqlContact, 'id' | 'firstName' | 'lastName'>
          & { profile: (
            { __typename?: 'Profile' }
            & Pick<GqlProfile, 'name'>
          ) }
        ) }
      )>, containers: Array<(
        { __typename?: 'Container' }
        & Pick<GqlContainer, 'id' | 'containerType' | 'weight' | 'volume'>
      )>, documents: Array<(
        { __typename?: 'Document' }
        & Pick<GqlDocument, 'id' | 'documentType' | 'importFileName' | 'fileName' | 'fileType' | 'fileSize' | 'uploadDate' | 'description'>
      )>, relatedParties: Array<(
        { __typename?: 'RelatedParty' }
        & Pick<GqlRelatedParty, 'id' | 'partyType'>
        & { party: (
          { __typename?: 'Profile' }
          & Pick<GqlProfile, 'id' | 'name'>
        ) }
      )>, hbl?: Maybe<(
        { __typename?: 'HouseBill' }
        & Pick<GqlHouseBill, 'id' | 'referenceNumber' | 'releaseType' | 'paymentType'>
        & { documents: Array<(
          { __typename?: 'Document' }
          & Pick<GqlDocument, 'id' | 'documentType' | 'importFileName' | 'fileName' | 'fileType' | 'fileSize' | 'uploadDate' | 'description'>
        )> }
      )>, mbl?: Maybe<(
        { __typename?: 'MasterBill' }
        & Pick<GqlMasterBill, 'id' | 'referenceNumber'>
        & { documents: Array<(
          { __typename?: 'Document' }
          & Pick<GqlDocument, 'id' | 'documentType' | 'importFileName' | 'fileName' | 'fileType' | 'fileSize' | 'uploadDate' | 'description'>
        )> }
      )> }
    )>, remarks: Array<(
      { __typename?: 'Remark' }
      & Pick<GqlRemark, 'id' | 'text' | 'createDate'>
      & { createdBy: (
        { __typename?: 'Contact' }
        & Pick<GqlContact, 'id' | 'firstName' | 'lastName'>
        & { profile: (
          { __typename?: 'Profile' }
          & Pick<GqlProfile, 'name'>
        ) }
      ) }
    )>, relatedParties: Array<(
      { __typename?: 'RelatedParty' }
      & Pick<GqlRelatedParty, 'id' | 'partyType'>
      & { party: (
        { __typename?: 'Profile' }
        & Pick<GqlProfile, 'id' | 'name'>
        & { locations: Array<(
          { __typename?: 'Location' }
          & Pick<GqlLocation, 'id' | 'name'>
        )> }
      ) }
    )>, logistics: (
      { __typename?: 'Logistics' }
      & Pick<GqlLogistics, 'id' | 'confirmationNumber' | 'confirmationDate' | 'carrierConfirmationNumber' | 'moveType' | 'transportationMode' | 'contractType' | 'contractNumber' | 'cyCutoffDate' | 'cfsCutoffDate' | 'cfsOpenDate' | 'vgmCutoffDate' | 'siCutoffDate' | 'transitPortEta' | 'transitPortAta' | 'transitPortEtd' | 'transitPortAtd' | 'polEtd' | 'polAtd' | 'podEta' | 'rampEta' | 'deliveryEta'>
      & { pickupLocation?: Maybe<(
        { __typename?: 'Location' }
        & Pick<GqlLocation, 'name'>
      )>, deliveryLocation?: Maybe<(
        { __typename?: 'Location' }
        & Pick<GqlLocation, 'name'>
      )>, carrier?: Maybe<(
        { __typename?: 'Carrier' }
        & Pick<GqlCarrier, 'name'>
      )>, motherVessel?: Maybe<(
        { __typename?: 'Vessel' }
        & Pick<GqlVessel, 'name'>
        & { voyages: Array<(
          { __typename?: 'Voyage' }
          & Pick<GqlVoyage, 'id' | 'name'>
        )> }
      )>, motherVoyage?: Maybe<(
        { __typename?: 'Voyage' }
        & Pick<GqlVoyage, 'name'>
      )>, feederVessel?: Maybe<(
        { __typename?: 'Vessel' }
        & Pick<GqlVessel, 'id' | 'name'>
        & { voyages: Array<(
          { __typename?: 'Voyage' }
          & Pick<GqlVoyage, 'id' | 'name'>
        )> }
      )>, feederVoyage?: Maybe<(
        { __typename?: 'Voyage' }
        & Pick<GqlVoyage, 'id' | 'name'>
      )>, transitPort?: Maybe<(
        { __typename?: 'Port' }
        & Pick<GqlPort, 'code' | 'name' | 'stateCode' | 'countryCode' | 'countryName' | 'latitude' | 'longitude' | 'stateName'>
      )>, pol?: Maybe<(
        { __typename?: 'Port' }
        & Pick<GqlPort, 'code' | 'name' | 'stateCode' | 'countryCode' | 'countryName' | 'latitude' | 'longitude' | 'stateName'>
      )>, pod?: Maybe<(
        { __typename?: 'Port' }
        & Pick<GqlPort, 'code' | 'name' | 'stateCode' | 'countryCode' | 'countryName' | 'latitude' | 'longitude' | 'stateName'>
      )>, ramp?: Maybe<(
        { __typename?: 'Port' }
        & Pick<GqlPort, 'code' | 'name' | 'stateCode' | 'countryCode' | 'countryName' | 'latitude' | 'longitude' | 'stateName'>
      )>, finalDestination?: Maybe<(
        { __typename?: 'Location' }
        & Pick<GqlLocation, 'id' | 'name'>
      )> }
    ), containers: Array<(
      { __typename?: 'Container' }
      & Pick<GqlContainer, 'id' | 'containerNumber' | 'containerType' | 'sealNumber' | 'volume' | 'weight' | 'shipFromFactoryDate' | 'terminalReceivedDate' | 'isHot'>
      & { vanPositions: Array<(
        { __typename?: 'VanPosition' }
        & Pick<GqlVanPosition, 'id' | 'volume' | 'weight' | 'piecesQty' | 'cartonsQty' | 'vanCode'>
        & { packLine: (
          { __typename?: 'PackLine' }
          & Pick<GqlPackLine, 'id'>
          & { orderLine: (
            { __typename?: 'OrderLine' }
            & Pick<GqlOrderLine, 'lineNumber' | 'itemNumber' | 'itemDescription'>
            & { purchaseOrder: (
              { __typename?: 'PurchaseOrder' }
              & Pick<GqlPurchaseOrder, 'poNumber'>
            ) }
          ) }
        ) }
      )> }
    )>, documents: Array<(
      { __typename?: 'Document' }
      & Pick<GqlDocument, 'id' | 'documentType' | 'importFileName' | 'fileName' | 'fileType' | 'fileSize' | 'uploadDate' | 'description'>
    )> }
  )> }
);

export type GqlNewConsolidationContainersMutationVariables = Exact<{
  input: GqlNewConsolidationContainersInput;
}>;


export type GqlNewConsolidationContainersMutation = (
  { __typename?: 'Mutation' }
  & { newConsolidationContainers: (
    { __typename?: 'NewConsolidationContainersPayload' }
    & Pick<GqlNewConsolidationContainersPayload, 'success'>
  ) }
);

export type GqlAssignConsolidationContainerMutationVariables = Exact<{
  input: GqlAssignConsolidationContainerInput;
}>;


export type GqlAssignConsolidationContainerMutation = (
  { __typename?: 'Mutation' }
  & { assignConsolidationContainer: (
    { __typename?: 'AssignConsolidationContainerPayload' }
    & Pick<GqlAssignConsolidationContainerPayload, 'success'>
  ) }
);

export type GqlUpdateConsolidationMutationVariables = Exact<{
  input: GqlUpdateConsolidationInput;
}>;


export type GqlUpdateConsolidationMutation = (
  { __typename?: 'Mutation' }
  & { updateConsolidation: (
    { __typename?: 'UpdateConsolidationPayload' }
    & Pick<GqlUpdateConsolidationPayload, 'success'>
  ) }
);

export type GqlUpdateConsolidationLogisticsMutationVariables = Exact<{
  input: GqlUpdateConsolidationLogisticsInput;
}>;


export type GqlUpdateConsolidationLogisticsMutation = (
  { __typename?: 'Mutation' }
  & { updateConsolidationLogistics: (
    { __typename?: 'UpdateConsolidationLogisticsPayload' }
    & Pick<GqlUpdateConsolidationLogisticsPayload, 'success' | 'message'>
  ) }
);

export type GqlSaveConsolidationMutationVariables = Exact<{
  input: GqlNewConsolidationInput;
}>;


export type GqlSaveConsolidationMutation = (
  { __typename?: 'Mutation' }
  & { newConsolidation: (
    { __typename?: 'NewConsolidationPayload' }
    & Pick<GqlNewConsolidationPayload, 'success' | 'message'>
    & { consolidation?: Maybe<(
      { __typename?: 'Consolidation' }
      & Pick<GqlConsolidation, 'id' | 'referenceNumber'>
    )> }
  ) }
);

export type GqlAssignConsolidationRelatedPartyMutationVariables = Exact<{
  input: GqlAssignConsolidationRelatedPartyInput;
}>;


export type GqlAssignConsolidationRelatedPartyMutation = (
  { __typename?: 'Mutation' }
  & { assignConsolidationRelatedParty: (
    { __typename?: 'AssignConsolidationRelatedPartyPayload' }
    & Pick<GqlAssignConsolidationRelatedPartyPayload, 'success' | 'message'>
  ) }
);

export type GqlRemoveConsolidationRelatedPartyMutationVariables = Exact<{
  input: GqlRemoveConsolidationRelatedPartyInput;
}>;


export type GqlRemoveConsolidationRelatedPartyMutation = (
  { __typename?: 'Mutation' }
  & { removeConsolidationRelatedParty: (
    { __typename?: 'RemoveConsolidationRelatedPartyPayload' }
    & Pick<GqlRemoveConsolidationRelatedPartyPayload, 'success' | 'message'>
  ) }
);

export type GqlUpdateConsolidationRelatedPartyMutationVariables = Exact<{
  input: GqlUpdateConsolidationRelatedPartyInput;
}>;


export type GqlUpdateConsolidationRelatedPartyMutation = (
  { __typename?: 'Mutation' }
  & { updateConsolidationRelatedParty: (
    { __typename?: 'UpdateConsolidationRelatedPartyPayload' }
    & Pick<GqlUpdateConsolidationRelatedPartyPayload, 'success' | 'message'>
  ) }
);

export type GqlNewConsolidationRemarkMutationVariables = Exact<{
  input: GqlNewConsolidationRemarkInput;
}>;


export type GqlNewConsolidationRemarkMutation = (
  { __typename?: 'Mutation' }
  & { newConsolidationRemark: (
    { __typename?: 'NewConsolidationRemarkPayload' }
    & Pick<GqlNewConsolidationRemarkPayload, 'success' | 'message'>
    & { remark?: Maybe<(
      { __typename?: 'Remark' }
      & Pick<GqlRemark, 'id' | 'text' | 'createDate'>
      & { createdBy: (
        { __typename?: 'Contact' }
        & Pick<GqlContact, 'firstName' | 'lastName' | 'title'>
        & { profile: (
          { __typename?: 'Profile' }
          & Pick<GqlProfile, 'name'>
        ) }
      ) }
    )> }
  ) }
);

export type GqlUpdateContactMutationVariables = Exact<{
  input: GqlUpdateContactInput;
}>;


export type GqlUpdateContactMutation = (
  { __typename?: 'Mutation' }
  & { updateContact: (
    { __typename?: 'UpdateContactPayload' }
    & Pick<GqlUpdateContactPayload, 'success'>
  ) }
);

export type GqlMyProfileContactsQueryVariables = Exact<{ [key: string]: never; }>;


export type GqlMyProfileContactsQuery = (
  { __typename?: 'Query' }
  & { userContext?: Maybe<(
    { __typename?: 'UserContext' }
    & { activeContact?: Maybe<(
      { __typename?: 'Contact' }
      & { profile: (
        { __typename?: 'Profile' }
        & { contacts: Array<(
          { __typename?: 'Contact' }
          & Pick<GqlContact, 'id' | 'firstName' | 'lastName' | 'email'>
        )> }
      ) }
    )> }
  )> }
);

export type GqlContainerQueryVariables = Exact<{
  containerId: Scalars['ID'];
}>;


export type GqlContainerQuery = (
  { __typename?: 'Query' }
  & { container?: Maybe<(
    { __typename?: 'Container' }
    & Pick<GqlContainer, 'id' | 'sequential' | 'containerNumber' | 'containerType' | 'sealNumber' | 'volume' | 'volumeUnit' | 'shipFromFactoryDate' | 'terminalReceivedDate' | 'portOutGateDate' | 'rampEta' | 'rampAta' | 'deliveryEta' | 'deliveryAta' | 'emptyReturnedDate' | 'demurrageLastFreeDayDate' | 'detentionLastFreeDayDate' | 'rampOutGateDate' | 'emptyNotifyDate' | 'isHot' | 'hotMarkedBy' | 'hotMarkedTimestamp'>
    & { ramp?: Maybe<(
      { __typename?: 'Port' }
      & GqlPortFragment
    )>, shipment: (
      { __typename?: 'Booking' }
      & Pick<GqlBooking, 'incoTerm' | 'id'>
      & { mbl?: Maybe<(
        { __typename?: 'MasterBill' }
        & Pick<GqlMasterBill, 'id' | 'referenceNumber'>
      )>, relatedParties: Array<(
        { __typename?: 'RelatedParty' }
        & Pick<GqlRelatedParty, 'partyType'>
        & { party: (
          { __typename?: 'Profile' }
          & Pick<GqlProfile, 'id' | 'name'>
        ) }
      )>, logistics: (
        { __typename?: 'Logistics' }
        & Pick<GqlLogistics, 'id' | 'moveType' | 'polEtd' | 'polAtd' | 'podEta' | 'podAta'>
        & { pickupLocation?: Maybe<(
          { __typename?: 'Location' }
          & Pick<GqlLocation, 'id' | 'name'>
        )>, pol?: Maybe<(
          { __typename?: 'Port' }
          & GqlPortFragment
        )>, pod?: Maybe<(
          { __typename?: 'Port' }
          & GqlPortFragment
        )>, carrier?: Maybe<(
          { __typename?: 'Carrier' }
          & Pick<GqlCarrier, 'id' | 'name'>
        )>, motherVessel?: Maybe<(
          { __typename?: 'Vessel' }
          & Pick<GqlVessel, 'id' | 'name'>
        )>, motherVoyage?: Maybe<(
          { __typename?: 'Voyage' }
          & Pick<GqlVoyage, 'id' | 'name'>
        )>, finalDestination?: Maybe<(
          { __typename?: 'Location' }
          & Pick<GqlLocation, 'id' | 'name'>
        )> }
      ) }
    ) | (
      { __typename?: 'Consolidation' }
      & Pick<GqlConsolidation, 'id'>
      & { mbl?: Maybe<(
        { __typename?: 'MasterBill' }
        & Pick<GqlMasterBill, 'id' | 'referenceNumber'>
      )>, relatedParties: Array<(
        { __typename?: 'RelatedParty' }
        & Pick<GqlRelatedParty, 'partyType'>
        & { party: (
          { __typename?: 'Profile' }
          & Pick<GqlProfile, 'id' | 'name'>
        ) }
      )>, logistics: (
        { __typename?: 'Logistics' }
        & Pick<GqlLogistics, 'id' | 'moveType' | 'polEtd' | 'polAtd' | 'podEta' | 'podAta'>
        & { pickupLocation?: Maybe<(
          { __typename?: 'Location' }
          & Pick<GqlLocation, 'id' | 'name'>
        )>, pol?: Maybe<(
          { __typename?: 'Port' }
          & GqlPortFragment
        )>, pod?: Maybe<(
          { __typename?: 'Port' }
          & GqlPortFragment
        )>, carrier?: Maybe<(
          { __typename?: 'Carrier' }
          & Pick<GqlCarrier, 'id' | 'name'>
        )>, motherVessel?: Maybe<(
          { __typename?: 'Vessel' }
          & Pick<GqlVessel, 'id' | 'name'>
        )>, motherVoyage?: Maybe<(
          { __typename?: 'Voyage' }
          & Pick<GqlVoyage, 'id' | 'name'>
        )>, finalDestination?: Maybe<(
          { __typename?: 'Location' }
          & Pick<GqlLocation, 'id' | 'name'>
        )> }
      ) }
    ), vanPositions: Array<(
      { __typename?: 'VanPosition' }
      & Pick<GqlVanPosition, 'id' | 'piecesQty' | 'cartonsQty' | 'volume' | 'weight' | 'commercialInvoiceTotal'>
      & { packLine: (
        { __typename?: 'PackLine' }
        & Pick<GqlPackLine, 'shippedQty' | 'shippedCartons'>
        & { booking: (
          { __typename?: 'Booking' }
          & { relatedParties: Array<(
            { __typename?: 'RelatedParty' }
            & Pick<GqlRelatedParty, 'partyType'>
            & { party: (
              { __typename?: 'Profile' }
              & Pick<GqlProfile, 'id' | 'name'>
            ) }
          )>, hbl?: Maybe<(
            { __typename?: 'HouseBill' }
            & Pick<GqlHouseBill, 'id' | 'referenceNumber'>
          )> }
        ), orderLine: (
          { __typename?: 'OrderLine' }
          & Pick<GqlOrderLine, 'unitPrice' | 'itemNumber' | 'itemDescription'>
          & { purchaseOrder: (
            { __typename?: 'PurchaseOrder' }
            & Pick<GqlPurchaseOrder, 'id' | 'poNumber'>
          ), openOrder: (
            { __typename?: 'OpenOrder' }
            & Pick<GqlOpenOrder, 'orderQty' | 'isHot'>
          ) }
        ) }
      ) }
    )> }
  )> }
);

export type GqlUpdateContainerMutationVariables = Exact<{
  input: GqlUpdateContainerInput;
}>;


export type GqlUpdateContainerMutation = (
  { __typename?: 'Mutation' }
  & { updateContainer: (
    { __typename?: 'UpdateContainerPayload' }
    & Pick<GqlUpdateContainerPayload, 'success'>
  ) }
);

export type GqlMarkContainerAsHotMutationVariables = Exact<{
  input: GqlMarkContainerAsHotInput;
}>;


export type GqlMarkContainerAsHotMutation = (
  { __typename?: 'Mutation' }
  & { markContainerAsHot: (
    { __typename?: 'MarkContainerAsHotPayload' }
    & Pick<GqlMarkContainerAsHotPayload, 'success' | 'message'>
    & { container?: Maybe<(
      { __typename?: 'Container' }
      & Pick<GqlContainer, 'isHot' | 'hotMarkedBy' | 'hotMarkedTimestamp'>
    )> }
  ) }
);

export type GqlNotificationsQueryVariables = Exact<{ [key: string]: never; }>;


export type GqlNotificationsQuery = (
  { __typename?: 'Query' }
  & { notifications: Array<(
    { __typename?: 'ArrivedAtFinalRampNotification' }
    & Pick<GqlArrivedAtFinalRampNotification, 'id' | 'notificationCode' | 'createTime'>
  ) | (
    { __typename?: 'ArrivedAtPodNotification' }
    & Pick<GqlArrivedAtPodNotification, 'id' | 'notificationCode' | 'createTime'>
  ) | (
    { __typename?: 'EmptyNotReturnedNotification' }
    & Pick<GqlEmptyNotReturnedNotification, 'id' | 'notificationCode' | 'createTime'>
  ) | (
    { __typename?: 'MissingDocumentNotification' }
    & Pick<GqlMissingDocumentNotification, 'id' | 'notificationCode' | 'createTime'>
  ) | (
    { __typename?: 'NewBookingNotification' }
    & Pick<GqlNewBookingNotification, 'id' | 'notificationCode' | 'createTime'>
    & { booking: (
      { __typename?: 'Booking' }
      & Pick<GqlBooking, 'id' | 'referenceNumber' | 'status' | 'createDate' | 'cargoReadyDate' | 'revisedCargoReadyDate'>
      & { logistics: (
        { __typename?: 'Logistics' }
        & Pick<GqlLogistics, 'confirmationDate'>
        & { pol?: Maybe<(
          { __typename?: 'Port' }
          & Pick<GqlPort, 'name'>
        )>, pod?: Maybe<(
          { __typename?: 'Port' }
          & Pick<GqlPort, 'name'>
        )>, finalDestination?: Maybe<(
          { __typename?: 'Location' }
          & Pick<GqlLocation, 'name'>
        )> }
      ), packLines: Array<(
        { __typename?: 'PackLine' }
        & { orderLine: (
          { __typename?: 'OrderLine' }
          & Pick<GqlOrderLine, 'expectedCargoReadyDate' | 'itemNumber'>
          & { purchaseOrder: (
            { __typename?: 'PurchaseOrder' }
            & Pick<GqlPurchaseOrder, 'id' | 'poNumber'>
          ) }
        ) }
      )>, containers: Array<(
        { __typename?: 'Container' }
        & Pick<GqlContainer, 'id' | 'containerType'>
      )>, relatedParties: Array<(
        { __typename?: 'RelatedParty' }
        & Pick<GqlRelatedParty, 'partyType'>
        & { party: (
          { __typename?: 'Profile' }
          & Pick<GqlProfile, 'name'>
        ) }
      )> }
    ) }
  ) | (
    { __typename?: 'NewDocumentNotification' }
    & Pick<GqlNewDocumentNotification, 'id' | 'notificationCode' | 'createTime'>
    & { document: (
      { __typename?: 'Document' }
      & Pick<GqlDocument, 'id' | 'documentType' | 'uploadDate'>
    ), booking: (
      { __typename?: 'Booking' }
      & Pick<GqlBooking, 'id' | 'referenceNumber'>
      & { relatedParties: Array<(
        { __typename?: 'RelatedParty' }
        & Pick<GqlRelatedParty, 'partyType'>
        & { party: (
          { __typename?: 'Profile' }
          & Pick<GqlProfile, 'name'>
        ) }
      )>, hbl?: Maybe<(
        { __typename?: 'HouseBill' }
        & Pick<GqlHouseBill, 'id' | 'referenceNumber'>
      )>, logistics: (
        { __typename?: 'Logistics' }
        & Pick<GqlLogistics, 'polEtd' | 'polAtd' | 'podEta'>
        & { pol?: Maybe<(
          { __typename?: 'Port' }
          & GqlPortFragment
        )>, pod?: Maybe<(
          { __typename?: 'Port' }
          & GqlPortFragment
        )>, deliveryLocation?: Maybe<(
          { __typename?: 'Location' }
          & Pick<GqlLocation, 'name'>
        )> }
      ) }
    ) }
  )> }
);

export type GqlMarkNotificationsAsReadMutationVariables = Exact<{
  input: GqlMarkNotificationsAsReadInput;
}>;


export type GqlMarkNotificationsAsReadMutation = (
  { __typename?: 'Mutation' }
  & { markNotificationsAsRead: (
    { __typename?: 'MarkNotificationsAsReadPayload' }
    & Pick<GqlMarkNotificationsAsReadPayload, 'success' | 'message'>
  ) }
);

export type GqlDashboardPOsQueryVariables = Exact<{ [key: string]: never; }>;


export type GqlDashboardPOsQuery = (
  { __typename?: 'Query' }
  & { dashboardPurchaseOrders: Array<(
    { __typename?: 'DashboardPurchaseOrder' }
    & { orderLine: (
      { __typename?: 'OrderLine' }
      & Pick<GqlOrderLine, 'id' | 'itemNumber' | 'lineStatus' | 'totalQty' | 'expectedCargoReadyDate' | 'indcDate' | 'unitPrice' | 'unitPriceCurrencyCode'>
      & { shipToLocation?: Maybe<(
        { __typename?: 'Location' }
        & Pick<GqlLocation, 'id' | 'name'>
      )>, purchaseOrder: (
        { __typename?: 'PurchaseOrder' }
        & Pick<GqlPurchaseOrder, 'id' | 'poNumber'>
        & { relatedParties: Array<(
          { __typename?: 'RelatedParty' }
          & Pick<GqlRelatedParty, 'partyType'>
          & { party: (
            { __typename?: 'Profile' }
            & Pick<GqlProfile, 'name'>
          ) }
        )> }
      ) }
    ) }
  )> }
);

export type GqlBookingSummaryQueryVariables = Exact<{
  startDate?: Maybe<Scalars['Date']>;
  endDate?: Maybe<Scalars['Date']>;
}>;


export type GqlBookingSummaryQuery = (
  { __typename?: 'Query' }
  & { bookingSummary: Array<(
    { __typename?: 'DashboardBookingSummary' }
    & Pick<GqlDashboardBookingSummary, 'start' | 'end' | 'weekNumber'>
    & { containers: Array<(
      { __typename?: 'Container' }
      & Pick<GqlContainer, 'id' | 'containerNumber' | 'containerType'>
      & { vanPositions: Array<(
        { __typename?: 'VanPosition' }
        & { packLine: (
          { __typename?: 'PackLine' }
          & { orderLine: (
            { __typename?: 'OrderLine' }
            & { purchaseOrder: (
              { __typename?: 'PurchaseOrder' }
              & Pick<GqlPurchaseOrder, 'id' | 'poNumber'>
            ) }
          ) }
        ) }
      )>, shipment: (
        { __typename?: 'Booking' }
        & Pick<GqlBooking, 'cargoReadyDate' | 'revisedCargoReadyDate' | 'id' | 'referenceNumber' | 'createDate' | 'status'>
        & { relatedParties: Array<(
          { __typename?: 'RelatedParty' }
          & Pick<GqlRelatedParty, 'partyType'>
          & { party: (
            { __typename?: 'Profile' }
            & Pick<GqlProfile, 'name'>
          ) }
        )>, logistics: (
          { __typename?: 'Logistics' }
          & { pol?: Maybe<(
            { __typename?: 'Port' }
            & Pick<GqlPort, 'name'>
          )>, pod?: Maybe<(
            { __typename?: 'Port' }
            & Pick<GqlPort, 'name'>
          )> }
        ) }
      ) | (
        { __typename?: 'Consolidation' }
        & Pick<GqlConsolidation, 'id' | 'referenceNumber' | 'createDate' | 'status'>
        & { relatedParties: Array<(
          { __typename?: 'RelatedParty' }
          & Pick<GqlRelatedParty, 'partyType'>
          & { party: (
            { __typename?: 'Profile' }
            & Pick<GqlProfile, 'name'>
          ) }
        )>, logistics: (
          { __typename?: 'Logistics' }
          & { pol?: Maybe<(
            { __typename?: 'Port' }
            & Pick<GqlPort, 'name'>
          )>, pod?: Maybe<(
            { __typename?: 'Port' }
            & Pick<GqlPort, 'name'>
          )> }
        ) }
      ) }
    )>, skuPackLines: Array<(
      { __typename?: 'PackLine' }
      & Pick<GqlPackLine, 'id' | 'lineNumber' | 'shippedQty'>
      & { orderLine: (
        { __typename?: 'OrderLine' }
        & Pick<GqlOrderLine, 'itemNumber'>
        & { purchaseOrder: (
          { __typename?: 'PurchaseOrder' }
          & Pick<GqlPurchaseOrder, 'id' | 'poNumber'>
        ) }
      ), booking: (
        { __typename?: 'Booking' }
        & Pick<GqlBooking, 'id' | 'referenceNumber' | 'createDate' | 'status' | 'cargoReadyDate' | 'revisedCargoReadyDate'>
        & { relatedParties: Array<(
          { __typename?: 'RelatedParty' }
          & Pick<GqlRelatedParty, 'partyType'>
          & { party: (
            { __typename?: 'Profile' }
            & Pick<GqlProfile, 'name'>
          ) }
        )>, logistics: (
          { __typename?: 'Logistics' }
          & { pol?: Maybe<(
            { __typename?: 'Port' }
            & Pick<GqlPort, 'name'>
          )>, pod?: Maybe<(
            { __typename?: 'Port' }
            & Pick<GqlPort, 'name'>
          )> }
        ) }
      ), vanPositions: Array<(
        { __typename?: 'VanPosition' }
        & { container: (
          { __typename?: 'Container' }
          & Pick<GqlContainer, 'id' | 'containerNumber'>
        ) }
      )> }
    )>, invoicePackLines: Array<(
      { __typename?: 'PackLine' }
      & Pick<GqlPackLine, 'id' | 'linePrice' | 'lineNumber' | 'shippedQty' | 'shippedCartons' | 'volume' | 'volumeUnit' | 'weight' | 'weightUnit'>
      & { orderLine: (
        { __typename?: 'OrderLine' }
        & Pick<GqlOrderLine, 'itemNumber'>
        & { purchaseOrder: (
          { __typename?: 'PurchaseOrder' }
          & Pick<GqlPurchaseOrder, 'id' | 'poNumber'>
        ) }
      ), booking: (
        { __typename?: 'Booking' }
        & Pick<GqlBooking, 'id' | 'referenceNumber' | 'createDate' | 'status' | 'cargoReadyDate' | 'revisedCargoReadyDate'>
        & { relatedParties: Array<(
          { __typename?: 'RelatedParty' }
          & Pick<GqlRelatedParty, 'partyType'>
          & { party: (
            { __typename?: 'Profile' }
            & Pick<GqlProfile, 'name'>
          ) }
        )>, logistics: (
          { __typename?: 'Logistics' }
          & { pol?: Maybe<(
            { __typename?: 'Port' }
            & Pick<GqlPort, 'name'>
          )>, pod?: Maybe<(
            { __typename?: 'Port' }
            & Pick<GqlPort, 'name'>
          )> }
        ) }
      ), vanPositions: Array<(
        { __typename?: 'VanPosition' }
        & { container: (
          { __typename?: 'Container' }
          & Pick<GqlContainer, 'id' | 'containerNumber' | 'containerType'>
        ) }
      )> }
    )>, bookings: Array<(
      { __typename?: 'Booking' }
      & Pick<GqlBooking, 'cargoReadyDate' | 'revisedCargoReadyDate' | 'id' | 'referenceNumber' | 'createDate' | 'status'>
      & { containers: Array<(
        { __typename?: 'Container' }
        & Pick<GqlContainer, 'id' | 'containerNumber' | 'containerType'>
      )>, relatedParties: Array<(
        { __typename?: 'RelatedParty' }
        & Pick<GqlRelatedParty, 'partyType'>
        & { party: (
          { __typename?: 'Profile' }
          & Pick<GqlProfile, 'name'>
        ) }
      )>, logistics: (
        { __typename?: 'Logistics' }
        & { pol?: Maybe<(
          { __typename?: 'Port' }
          & Pick<GqlPort, 'name'>
        )>, pod?: Maybe<(
          { __typename?: 'Port' }
          & Pick<GqlPort, 'name'>
        )> }
      ) }
    ) | (
      { __typename?: 'Consolidation' }
      & Pick<GqlConsolidation, 'id' | 'referenceNumber' | 'createDate' | 'status'>
      & { containers: Array<(
        { __typename?: 'Container' }
        & Pick<GqlContainer, 'id' | 'containerNumber' | 'containerType'>
      )>, relatedParties: Array<(
        { __typename?: 'RelatedParty' }
        & Pick<GqlRelatedParty, 'partyType'>
        & { party: (
          { __typename?: 'Profile' }
          & Pick<GqlProfile, 'name'>
        ) }
      )>, logistics: (
        { __typename?: 'Logistics' }
        & { pol?: Maybe<(
          { __typename?: 'Port' }
          & Pick<GqlPort, 'name'>
        )>, pod?: Maybe<(
          { __typename?: 'Port' }
          & Pick<GqlPort, 'name'>
        )> }
      ) }
    )> }
  )> }
);

export type GqlDashboardNewBookingsQueryVariables = Exact<{ [key: string]: never; }>;


export type GqlDashboardNewBookingsQuery = (
  { __typename?: 'Query' }
  & { newBookings: Array<(
    { __typename?: 'Booking' }
    & Pick<GqlBooking, 'id' | 'referenceNumber' | 'createDate' | 'status' | 'cargoReadyDate' | 'revisedCargoReadyDate'>
    & { relatedParties: Array<(
      { __typename?: 'RelatedParty' }
      & Pick<GqlRelatedParty, 'partyType'>
      & { party: (
        { __typename?: 'Profile' }
        & Pick<GqlProfile, 'name'>
      ) }
    )>, packLines: Array<(
      { __typename?: 'PackLine' }
      & { orderLine: (
        { __typename?: 'OrderLine' }
        & Pick<GqlOrderLine, 'id' | 'itemNumber'>
        & { purchaseOrder: (
          { __typename?: 'PurchaseOrder' }
          & Pick<GqlPurchaseOrder, 'id' | 'poNumber'>
        ) }
      ) }
    )>, containers: Array<(
      { __typename?: 'Container' }
      & Pick<GqlContainer, 'id' | 'containerNumber' | 'containerType'>
    )>, logistics: (
      { __typename?: 'Logistics' }
      & { pol?: Maybe<(
        { __typename?: 'Port' }
        & Pick<GqlPort, 'name'>
      )>, pod?: Maybe<(
        { __typename?: 'Port' }
        & Pick<GqlPort, 'name'>
      )> }
    ) }
  )> }
);

export type GqlDashboardNewDocumentsQueryVariables = Exact<{ [key: string]: never; }>;


export type GqlDashboardNewDocumentsQuery = (
  { __typename?: 'Query' }
  & { newDocuments: Array<(
    { __typename?: 'DocumentRecord' }
    & Pick<GqlDocumentRecord, 'id' | 'bookingId' | 'bookingNumber' | 'importFileName' | 'fileType' | 'documentType' | 'uploadDate' | 'shipperName' | 'hblId' | 'hblNumber' | 'polEtd' | 'polAtd' | 'podEta' | 'deliveryLocation'>
    & { pol?: Maybe<(
      { __typename?: 'Port' }
      & Pick<GqlPort, 'code' | 'name'>
    )>, pod?: Maybe<(
      { __typename?: 'Port' }
      & Pick<GqlPort, 'code' | 'name'>
    )> }
  )> }
);

export type GqlExceptionsQueryVariables = Exact<{ [key: string]: never; }>;


export type GqlExceptionsQuery = (
  { __typename?: 'Query' }
  & { exceptions: Array<(
    { __typename?: 'DelayedArrivalException' }
    & Pick<GqlDelayedArrivalException, 'type'>
    & { booking: (
      { __typename?: 'Booking' }
      & Pick<GqlBooking, 'id' | 'referenceNumber' | 'cargoReadyDate'>
      & { logistics: (
        { __typename?: 'Logistics' }
        & Pick<GqlLogistics, 'polEtd' | 'polAtd' | 'podEta' | 'podAta' | 'deliveryEta'>
        & { finalDestination?: Maybe<(
          { __typename?: 'Location' }
          & Pick<GqlLocation, 'name'>
        )> }
      ), relatedParties: Array<(
        { __typename?: 'RelatedParty' }
        & Pick<GqlRelatedParty, 'partyType'>
        & { party: (
          { __typename?: 'Profile' }
          & Pick<GqlProfile, 'name'>
        ) }
      )>, packLines: Array<(
        { __typename?: 'PackLine' }
        & { orderLine: (
          { __typename?: 'OrderLine' }
          & Pick<GqlOrderLine, 'expectedCargoReadyDate'>
          & { purchaseOrder: (
            { __typename?: 'PurchaseOrder' }
            & Pick<GqlPurchaseOrder, 'id' | 'poNumber'>
          ) }
        ) }
      )> }
    ) }
  ) | (
    { __typename?: 'PendingOutgateException' }
    & Pick<GqlPendingOutgateException, 'type' | 'notificationId' | 'markedAsRead'>
    & { container: (
      { __typename?: 'Container' }
      & Pick<GqlContainer, 'id' | 'containerNumber' | 'rampAta' | 'demurrageLastFreeDayDate' | 'deliveryEta'>
      & { shipment: (
        { __typename?: 'Booking' }
        & { logistics: (
          { __typename?: 'Logistics' }
          & { finalDestination?: Maybe<(
            { __typename?: 'Location' }
            & Pick<GqlLocation, 'name'>
          )> }
        ) }
      ) | (
        { __typename?: 'Consolidation' }
        & { logistics: (
          { __typename?: 'Logistics' }
          & { finalDestination?: Maybe<(
            { __typename?: 'Location' }
            & Pick<GqlLocation, 'name'>
          )> }
        ) }
      ) }
    ) }
  ) | (
    { __typename?: 'EmptyNotReturnedException' }
    & Pick<GqlEmptyNotReturnedException, 'type' | 'notificationId' | 'markedAsRead'>
    & { container: (
      { __typename?: 'Container' }
      & Pick<GqlContainer, 'id' | 'containerNumber' | 'rampAta' | 'portOutGateDate' | 'deliveryAta' | 'detentionLastFreeDayDate'>
      & { shipment: (
        { __typename?: 'Booking' }
        & { logistics: (
          { __typename?: 'Logistics' }
          & { finalDestination?: Maybe<(
            { __typename?: 'Location' }
            & Pick<GqlLocation, 'name'>
          )> }
        ) }
      ) | (
        { __typename?: 'Consolidation' }
        & { logistics: (
          { __typename?: 'Logistics' }
          & { finalDestination?: Maybe<(
            { __typename?: 'Location' }
            & Pick<GqlLocation, 'name'>
          )> }
        ) }
      ) }
    ) }
  ) | (
    { __typename?: 'HotPurchaseOrder' }
    & Pick<GqlHotPurchaseOrder, 'type'>
    & { openOrder: (
      { __typename?: 'OpenOrder' }
      & Pick<GqlOpenOrder, 'id' | 'isHot' | 'hotMarkedBy' | 'hotMarkedTimestamp'>
      & { orderLine: (
        { __typename?: 'OrderLine' }
        & Pick<GqlOrderLine, 'id' | 'lineStatus' | 'totalQty'>
        & { purchaseOrder: (
          { __typename?: 'PurchaseOrder' }
          & Pick<GqlPurchaseOrder, 'id' | 'poNumber'>
          & { relatedParties: Array<(
            { __typename?: 'RelatedParty' }
            & Pick<GqlRelatedParty, 'partyType'>
            & { party: (
              { __typename?: 'Profile' }
              & Pick<GqlProfile, 'name'>
            ) }
          )> }
        ), item: (
          { __typename?: 'Item' }
          & Pick<GqlItem, 'itemNumber'>
        ), shipToLocation?: Maybe<(
          { __typename?: 'Location' }
          & Pick<GqlLocation, 'name'>
        )> }
      ) }
    ) }
  ) | (
    { __typename?: 'HotShipment' }
    & Pick<GqlHotShipment, 'type'>
    & { shipment: (
      { __typename?: 'Booking' }
      & Pick<GqlBooking, 'cargoReadyDate' | 'revisedCargoReadyDate' | 'isHot' | 'hotMarkedBy' | 'hotMarkedTimestamp' | 'id' | 'referenceNumber'>
      & { hbl?: Maybe<(
        { __typename?: 'HouseBill' }
        & Pick<GqlHouseBill, 'id' | 'referenceNumber'>
      )>, mbl?: Maybe<(
        { __typename?: 'MasterBill' }
        & Pick<GqlMasterBill, 'id' | 'referenceNumber'>
      )>, logistics: (
        { __typename?: 'Logistics' }
        & Pick<GqlLogistics, 'polEtd' | 'polAtd' | 'podEta' | 'podAta' | 'rampEta' | 'rampAta' | 'deliveryEta' | 'deliveryAta'>
        & { carrier?: Maybe<(
          { __typename?: 'Carrier' }
          & Pick<GqlCarrier, 'name'>
        )>, pol?: Maybe<(
          { __typename?: 'Port' }
          & Pick<GqlPort, 'code'>
        )>, pod?: Maybe<(
          { __typename?: 'Port' }
          & Pick<GqlPort, 'code'>
        )>, ramp?: Maybe<(
          { __typename?: 'Port' }
          & Pick<GqlPort, 'code'>
        )>, finalDestination?: Maybe<(
          { __typename?: 'Location' }
          & Pick<GqlLocation, 'name'>
        )> }
      ), relatedParties: Array<(
        { __typename?: 'RelatedParty' }
        & Pick<GqlRelatedParty, 'partyType'>
        & { party: (
          { __typename?: 'Profile' }
          & Pick<GqlProfile, 'name'>
        ) }
      )>, containers: Array<(
        { __typename?: 'Container' }
        & Pick<GqlContainer, 'id' | 'containerType'>
        & { vanPositions: Array<(
          { __typename?: 'VanPosition' }
          & Pick<GqlVanPosition, 'commercialInvoiceTotal'>
        )> }
      )> }
    ) | (
      { __typename?: 'Consolidation' }
      & Pick<GqlConsolidation, 'id' | 'referenceNumber'>
      & { mbl?: Maybe<(
        { __typename?: 'MasterBill' }
        & Pick<GqlMasterBill, 'id' | 'referenceNumber'>
      )>, logistics: (
        { __typename?: 'Logistics' }
        & Pick<GqlLogistics, 'polEtd' | 'polAtd' | 'podEta' | 'podAta' | 'rampEta' | 'rampAta' | 'deliveryEta' | 'deliveryAta'>
        & { carrier?: Maybe<(
          { __typename?: 'Carrier' }
          & Pick<GqlCarrier, 'name'>
        )>, pol?: Maybe<(
          { __typename?: 'Port' }
          & Pick<GqlPort, 'code'>
        )>, pod?: Maybe<(
          { __typename?: 'Port' }
          & Pick<GqlPort, 'code'>
        )>, ramp?: Maybe<(
          { __typename?: 'Port' }
          & Pick<GqlPort, 'code'>
        )>, finalDestination?: Maybe<(
          { __typename?: 'Location' }
          & Pick<GqlLocation, 'name'>
        )> }
      ), relatedParties: Array<(
        { __typename?: 'RelatedParty' }
        & Pick<GqlRelatedParty, 'partyType'>
        & { party: (
          { __typename?: 'Profile' }
          & Pick<GqlProfile, 'name'>
        ) }
      )>, containers: Array<(
        { __typename?: 'Container' }
        & Pick<GqlContainer, 'id' | 'containerType'>
        & { vanPositions: Array<(
          { __typename?: 'VanPosition' }
          & Pick<GqlVanPosition, 'commercialInvoiceTotal'>
        )> }
      )> }
    ) }
  ) | (
    { __typename?: 'HotContainer' }
    & Pick<GqlHotContainer, 'type'>
    & { container: (
      { __typename?: 'Container' }
      & Pick<GqlContainer, 'id' | 'containerNumber' | 'isHot' | 'hotMarkedBy' | 'hotMarkedTimestamp'>
      & { shipment: (
        { __typename?: 'Booking' }
        & Pick<GqlBooking, 'cargoReadyDate' | 'revisedCargoReadyDate'>
        & { hbl?: Maybe<(
          { __typename?: 'HouseBill' }
          & Pick<GqlHouseBill, 'id' | 'referenceNumber'>
        )>, logistics: (
          { __typename?: 'Logistics' }
          & Pick<GqlLogistics, 'polEtd' | 'polAtd' | 'podEta' | 'podAta' | 'rampEta' | 'rampAta' | 'deliveryEta' | 'deliveryAta'>
          & { carrier?: Maybe<(
            { __typename?: 'Carrier' }
            & Pick<GqlCarrier, 'name'>
          )>, pol?: Maybe<(
            { __typename?: 'Port' }
            & Pick<GqlPort, 'code'>
          )>, pod?: Maybe<(
            { __typename?: 'Port' }
            & Pick<GqlPort, 'code'>
          )>, ramp?: Maybe<(
            { __typename?: 'Port' }
            & Pick<GqlPort, 'code'>
          )>, finalDestination?: Maybe<(
            { __typename?: 'Location' }
            & Pick<GqlLocation, 'name'>
          )> }
        ), relatedParties: Array<(
          { __typename?: 'RelatedParty' }
          & Pick<GqlRelatedParty, 'partyType'>
          & { party: (
            { __typename?: 'Profile' }
            & Pick<GqlProfile, 'name'>
          ) }
        )> }
      ) | (
        { __typename?: 'Consolidation' }
        & { mbl?: Maybe<(
          { __typename?: 'MasterBill' }
          & Pick<GqlMasterBill, 'id' | 'referenceNumber'>
        )>, logistics: (
          { __typename?: 'Logistics' }
          & Pick<GqlLogistics, 'polEtd' | 'polAtd' | 'podEta' | 'podAta' | 'rampEta' | 'rampAta' | 'deliveryEta' | 'deliveryAta'>
          & { carrier?: Maybe<(
            { __typename?: 'Carrier' }
            & Pick<GqlCarrier, 'name'>
          )>, pol?: Maybe<(
            { __typename?: 'Port' }
            & Pick<GqlPort, 'code'>
          )>, pod?: Maybe<(
            { __typename?: 'Port' }
            & Pick<GqlPort, 'code'>
          )>, ramp?: Maybe<(
            { __typename?: 'Port' }
            & Pick<GqlPort, 'code'>
          )>, finalDestination?: Maybe<(
            { __typename?: 'Location' }
            & Pick<GqlLocation, 'name'>
          )> }
        ), relatedParties: Array<(
          { __typename?: 'RelatedParty' }
          & Pick<GqlRelatedParty, 'partyType'>
          & { party: (
            { __typename?: 'Profile' }
            & Pick<GqlProfile, 'name'>
          ) }
        )> }
      ), vanPositions: Array<(
        { __typename?: 'VanPosition' }
        & Pick<GqlVanPosition, 'commercialInvoiceTotal'>
      )> }
    ) }
  ) | (
    { __typename?: 'MissingDocumentException' }
    & Pick<GqlMissingDocumentException, 'type' | 'notificationId' | 'markedAsRead' | 'documentType'>
    & { booking: (
      { __typename?: 'Booking' }
      & Pick<GqlBooking, 'id' | 'referenceNumber' | 'cargoReadyDate'>
      & { hbl?: Maybe<(
        { __typename?: 'HouseBill' }
        & Pick<GqlHouseBill, 'id' | 'referenceNumber'>
      )>, mbl?: Maybe<(
        { __typename?: 'MasterBill' }
        & Pick<GqlMasterBill, 'id' | 'referenceNumber'>
      )>, logistics: (
        { __typename?: 'Logistics' }
        & Pick<GqlLogistics, 'polEtd' | 'polAtd' | 'podEta' | 'podAta' | 'deliveryEta'>
        & { pol?: Maybe<(
          { __typename?: 'Port' }
          & GqlPortFragment
        )>, pod?: Maybe<(
          { __typename?: 'Port' }
          & GqlPortFragment
        )>, finalDestination?: Maybe<(
          { __typename?: 'Location' }
          & Pick<GqlLocation, 'name'>
        )> }
      ), relatedParties: Array<(
        { __typename?: 'RelatedParty' }
        & Pick<GqlRelatedParty, 'partyType'>
        & { party: (
          { __typename?: 'Profile' }
          & Pick<GqlProfile, 'name'>
        ) }
      )>, packLines: Array<(
        { __typename?: 'PackLine' }
        & { orderLine: (
          { __typename?: 'OrderLine' }
          & Pick<GqlOrderLine, 'expectedCargoReadyDate'>
          & { purchaseOrder: (
            { __typename?: 'PurchaseOrder' }
            & Pick<GqlPurchaseOrder, 'id' | 'poNumber'>
          ) }
        ) }
      )> }
    ) }
  ) | (
    { __typename?: 'LateBookingException' }
    & Pick<GqlLateBookingException, 'type' | 'notificationId' | 'markedAsRead'>
    & { booking: (
      { __typename?: 'Booking' }
      & Pick<GqlBooking, 'id' | 'referenceNumber' | 'status' | 'createDate' | 'cargoReadyDate' | 'revisedCargoReadyDate'>
      & { relatedParties: Array<(
        { __typename?: 'RelatedParty' }
        & Pick<GqlRelatedParty, 'partyType'>
        & { party: (
          { __typename?: 'Profile' }
          & Pick<GqlProfile, 'name'>
        ) }
      )>, logistics: (
        { __typename?: 'Logistics' }
        & { pol?: Maybe<(
          { __typename?: 'Port' }
          & GqlPortFragment
        )>, finalDestination?: Maybe<(
          { __typename?: 'Location' }
          & Pick<GqlLocation, 'name'>
        )> }
      ) }
    ), orderLine: (
      { __typename?: 'OrderLine' }
      & Pick<GqlOrderLine, 'id' | 'itemNumber' | 'bookByDate' | 'expectedCargoReadyDate'>
      & { purchaseOrder: (
        { __typename?: 'PurchaseOrder' }
        & Pick<GqlPurchaseOrder, 'id' | 'poNumber' | 'expectedCargoReadyDate'>
      ) }
    ) }
  )> }
);

export type GqlShipmentsInTransitQueryVariables = Exact<{ [key: string]: never; }>;


export type GqlShipmentsInTransitQuery = (
  { __typename?: 'Query' }
  & { shipmentsInTransit: Array<(
    { __typename?: 'DashboardShipmentInTransit' }
    & Pick<GqlDashboardShipmentInTransit, 'status' | 'milestone' | 'isDueToArrive'>
    & { container: (
      { __typename?: 'Container' }
      & Pick<GqlContainer, 'id' | 'containerNumber' | 'rampEta' | 'rampAta' | 'deliveryEta' | 'deliveryAta'>
      & { ramp?: Maybe<(
        { __typename?: 'Port' }
        & Pick<GqlPort, 'name'>
      )>, vanPositions: Array<(
        { __typename?: 'VanPosition' }
        & Pick<GqlVanPosition, 'commercialInvoiceTotal'>
      )>, shipment: (
        { __typename?: 'Booking' }
        & Pick<GqlBooking, 'cargoReadyDate' | 'revisedCargoReadyDate'>
        & { hbl?: Maybe<(
          { __typename?: 'HouseBill' }
          & Pick<GqlHouseBill, 'id' | 'referenceNumber'>
        )>, mbl?: Maybe<(
          { __typename?: 'MasterBill' }
          & Pick<GqlMasterBill, 'id' | 'referenceNumber'>
        )>, relatedParties: Array<(
          { __typename?: 'RelatedParty' }
          & Pick<GqlRelatedParty, 'partyType'>
          & { party: (
            { __typename?: 'Profile' }
            & Pick<GqlProfile, 'name'>
          ) }
        )>, logistics: (
          { __typename?: 'Logistics' }
          & Pick<GqlLogistics, 'polEtd' | 'polAtd' | 'podEta' | 'podAta'>
          & { pol?: Maybe<(
            { __typename?: 'Port' }
            & Pick<GqlPort, 'name'>
          )>, pod?: Maybe<(
            { __typename?: 'Port' }
            & Pick<GqlPort, 'name'>
          )>, finalDestination?: Maybe<(
            { __typename?: 'Location' }
            & Pick<GqlLocation, 'name'>
          )>, carrier?: Maybe<(
            { __typename?: 'Carrier' }
            & Pick<GqlCarrier, 'name'>
          )>, deliveryLocation?: Maybe<(
            { __typename?: 'Location' }
            & Pick<GqlLocation, 'name'>
          )> }
        ) }
      ) | (
        { __typename?: 'Consolidation' }
        & { mbl?: Maybe<(
          { __typename?: 'MasterBill' }
          & Pick<GqlMasterBill, 'id' | 'referenceNumber'>
        )>, relatedParties: Array<(
          { __typename?: 'RelatedParty' }
          & Pick<GqlRelatedParty, 'partyType'>
          & { party: (
            { __typename?: 'Profile' }
            & Pick<GqlProfile, 'name'>
          ) }
        )>, logistics: (
          { __typename?: 'Logistics' }
          & Pick<GqlLogistics, 'polEtd' | 'polAtd' | 'podEta' | 'podAta'>
          & { pol?: Maybe<(
            { __typename?: 'Port' }
            & Pick<GqlPort, 'name'>
          )>, pod?: Maybe<(
            { __typename?: 'Port' }
            & Pick<GqlPort, 'name'>
          )>, finalDestination?: Maybe<(
            { __typename?: 'Location' }
            & Pick<GqlLocation, 'name'>
          )>, carrier?: Maybe<(
            { __typename?: 'Carrier' }
            & Pick<GqlCarrier, 'name'>
          )>, deliveryLocation?: Maybe<(
            { __typename?: 'Location' }
            & Pick<GqlLocation, 'name'>
          )> }
        ) }
      ) }
    ) }
  )> }
);

export type GqlDocumentsQueryVariables = Exact<{
  filter: GqlDocumentFilter;
}>;


export type GqlDocumentsQuery = (
  { __typename?: 'Query' }
  & { documentSearch: Array<(
    { __typename?: 'DocumentRecord' }
    & Pick<GqlDocumentRecord, 'id' | 'bookingId' | 'bookingNumber' | 'importFileName' | 'fileType' | 'documentType' | 'uploadDate' | 'shipperName' | 'hblId' | 'hblNumber' | 'polEtd' | 'polAtd' | 'podEta' | 'deliveryLocation'>
    & { pol?: Maybe<(
      { __typename?: 'Port' }
      & GqlPortFragment
    )>, pod?: Maybe<(
      { __typename?: 'Port' }
      & GqlPortFragment
    )> }
  )> }
);

export type GqlNewDocumentsQueryVariables = Exact<{ [key: string]: never; }>;


export type GqlNewDocumentsQuery = (
  { __typename?: 'Query' }
  & { newDocuments: Array<(
    { __typename?: 'DocumentRecord' }
    & Pick<GqlDocumentRecord, 'id' | 'bookingId' | 'bookingNumber' | 'importFileName' | 'fileType' | 'documentType' | 'uploadDate' | 'shipperName' | 'hblId' | 'hblNumber' | 'polEtd' | 'polAtd' | 'podEta' | 'deliveryLocation'>
    & { pol?: Maybe<(
      { __typename?: 'Port' }
      & Pick<GqlPort, 'code' | 'name'>
    )>, pod?: Maybe<(
      { __typename?: 'Port' }
      & Pick<GqlPort, 'code' | 'name'>
    )> }
  )> }
);

export type GqlMarkDocumentsAsViewedMutationVariables = Exact<{
  input: GqlMarkDocumentsAsViewedInput;
}>;


export type GqlMarkDocumentsAsViewedMutation = (
  { __typename?: 'Mutation' }
  & { markDocumentsAsViewed: (
    { __typename?: 'MarkDocumentsAsViewedPayload' }
    & Pick<GqlMarkDocumentsAsViewedPayload, 'success' | 'message'>
  ) }
);

export type GqlUploadBookingDocumentMutationVariables = Exact<{
  input: GqlUploadBookingDocumentInput;
}>;


export type GqlUploadBookingDocumentMutation = (
  { __typename?: 'Mutation' }
  & { uploadBookingDocument: (
    { __typename?: 'UploadBookingDocumentPayload' }
    & Pick<GqlUploadBookingDocumentPayload, 'success' | 'message'>
  ) }
);

export type GqlGlobalUpdateMMutationVariables = Exact<{
  input: GqlGlobalUpdateInput;
}>;


export type GqlGlobalUpdateMMutation = (
  { __typename?: 'Mutation' }
  & { globalUpdate: (
    { __typename?: 'GlobalUpdatePayload' }
    & Pick<GqlGlobalUpdatePayload, 'success' | 'message'>
  ) }
);

export type GqlGlobalUpdateSearchQueryVariables = Exact<{
  searchType: SearchType;
  query: Scalars['String'];
  numResults?: Maybe<Scalars['Int']>;
}>;


export type GqlGlobalUpdateSearchQuery = (
  { __typename?: 'Query' }
  & { search: Array<(
    { __typename?: 'SearchResult' }
    & Pick<GqlSearchResult, 'id' | 'value'>
  )> }
);

export type GqlGlobalUpdateSearchResultsQueryVariables = Exact<{
  searchType: SearchType;
  searchId: Scalars['ID'];
}>;


export type GqlGlobalUpdateSearchResultsQuery = (
  { __typename?: 'Query' }
  & { globalUpdateSearch: Array<(
    { __typename?: 'GlobalUpdateRecord' }
    & Pick<GqlGlobalUpdateRecord, 'recordId' | 'shipmentId' | 'referenceNumber' | 'hblId' | 'hblNumber' | 'mblId' | 'mblNumber' | 'containerId' | 'containerNumber' | 'poId' | 'poNumber' | 'itemNumber' | 'vesselName' | 'voyageName'>
  )> }
);

export type GqlGlobalUpdateQueryVariables = Exact<{
  recordIds: Array<Scalars['ID']> | Scalars['ID'];
}>;


export type GqlGlobalUpdateQuery = (
  { __typename?: 'Query' }
  & { globalUpdate: (
    { __typename?: 'GlobalUpdateResults' }
    & { logistics: Array<(
      { __typename?: 'GlobalUpdateLogistics' }
      & Pick<GqlGlobalUpdateLogistics, 'logisticsId' | 'referenceNumber' | 'confirmationNumber' | 'confirmationDate' | 'polEtd' | 'polAtd' | 'transitPortEta' | 'transitPortAta' | 'transitPortEtd' | 'transitPortAtd' | 'podEta' | 'podAta' | 'rampEta' | 'rampAta'>
    )>, containers: Array<(
      { __typename?: 'GlobalUpdateContainer' }
      & Pick<GqlGlobalUpdateContainer, 'containerId' | 'containerNumber' | 'rampEta' | 'rampAta' | 'rampOutGateDate' | 'portOutGateDate' | 'deliveryEta' | 'deliveryAta' | 'deliveryUnload' | 'emptyNotifyDate' | 'emptyPickupDate' | 'emptyReturnedDate' | 'demurrageLastFreeDayDate' | 'detentionLastFreeDayDate' | 'consolidatorLastFreeDayDate'>
    )>, customs: Array<(
      { __typename?: 'GlobalUpdateCustoms' }
      & Pick<GqlGlobalUpdateCustoms, 'customsId' | 'customsBrokerRef' | 'isfResponseNumber' | 'isfFilingDate' | 'entryNumber' | 'entryDate' | 'entryValue' | 'mpf' | 'hmf' | 'duty' | 'customsExamType' | 'customsExamNotifyDate' | 'customsExamFee' | 'customsReleaseDate' | 'fdaExamType' | 'fdaExamNotifyDate' | 'fdaExamFee' | 'fdaReleaseDate' | 'entryFeeTotal'>
    )>, records: Array<(
      { __typename?: 'GlobalUpdateRecord' }
      & Pick<GqlGlobalUpdateRecord, 'recordId' | 'shipmentId' | 'referenceNumber' | 'hblId' | 'hblNumber' | 'mblId' | 'mblNumber' | 'containerId' | 'containerNumber' | 'poId' | 'poNumber' | 'itemNumber' | 'vesselName' | 'voyageName'>
    )> }
  ) }
);

export type GqlHblQueryVariables = Exact<{
  hblId: Scalars['ID'];
}>;


export type GqlHblQuery = (
  { __typename?: 'Query' }
  & { hbl?: Maybe<(
    { __typename?: 'HouseBill' }
    & Pick<GqlHouseBill, 'id' | 'referenceNumber' | 'description' | 'releaseType' | 'paymentType' | 'containerCount' | 'totalPieces' | 'totalCartons'>
    & { booking: (
      { __typename?: 'Booking' }
      & Pick<GqlBooking, 'id' | 'referenceNumber' | 'status' | 'isHot' | 'hotMarkedBy' | 'hotMarkedTimestamp'>
      & { logistics: (
        { __typename?: 'Logistics' }
        & Pick<GqlLogistics, 'contractNumber' | 'contractType' | 'moveType' | 'polEtd' | 'polAtd' | 'podEta' | 'podAta' | 'rampEta' | 'rampAta' | 'deliveryEta' | 'deliveryAta'>
        & { carrier?: Maybe<(
          { __typename?: 'Carrier' }
          & Pick<GqlCarrier, 'id' | 'name'>
        )>, motherVessel?: Maybe<(
          { __typename?: 'Vessel' }
          & Pick<GqlVessel, 'id' | 'name'>
        )>, motherVoyage?: Maybe<(
          { __typename?: 'Voyage' }
          & Pick<GqlVoyage, 'id' | 'name'>
        )>, pol?: Maybe<(
          { __typename?: 'Port' }
          & GqlPortFragment
        )>, pod?: Maybe<(
          { __typename?: 'Port' }
          & GqlPortFragment
        )>, ramp?: Maybe<(
          { __typename?: 'Port' }
          & GqlPortFragment
        )>, deliveryLocation?: Maybe<(
          { __typename?: 'Location' }
          & Pick<GqlLocation, 'id' | 'name'>
        )> }
      ), mbl?: Maybe<(
        { __typename?: 'MasterBill' }
        & Pick<GqlMasterBill, 'id' | 'referenceNumber'>
        & { remarks: Array<(
          { __typename?: 'Remark' }
          & Pick<GqlRemark, 'id' | 'text' | 'createDate'>
          & { createdBy: (
            { __typename?: 'Contact' }
            & Pick<GqlContact, 'id' | 'firstName' | 'lastName'>
            & { profile: (
              { __typename?: 'Profile' }
              & Pick<GqlProfile, 'id' | 'name'>
            ) }
          ) }
        )> }
      )>, packLines: Array<(
        { __typename?: 'PackLine' }
        & Pick<GqlPackLine, 'id'>
      )>, remarks: Array<(
        { __typename?: 'Remark' }
        & Pick<GqlRemark, 'id' | 'text' | 'createDate'>
        & { createdBy: (
          { __typename?: 'Contact' }
          & Pick<GqlContact, 'id' | 'firstName' | 'lastName'>
          & { profile: (
            { __typename?: 'Profile' }
            & Pick<GqlProfile, 'id' | 'name'>
          ) }
        ) }
      )>, containers: Array<(
        { __typename?: 'Container' }
        & Pick<GqlContainer, 'id' | 'containerNumber' | 'containerType' | 'sealNumber' | 'volume' | 'weight' | 'shipFromFactoryDate' | 'terminalReceivedDate' | 'isHot'>
      )>, documents: Array<(
        { __typename?: 'Document' }
        & Pick<GqlDocument, 'id' | 'documentType' | 'importFileName' | 'fileName' | 'fileType' | 'fileSize' | 'uploadDate' | 'description'>
      )> }
    ), remarks: Array<(
      { __typename?: 'Remark' }
      & Pick<GqlRemark, 'id' | 'text' | 'createDate'>
      & { createdBy: (
        { __typename?: 'Contact' }
        & Pick<GqlContact, 'id' | 'firstName' | 'lastName'>
        & { profile: (
          { __typename?: 'Profile' }
          & Pick<GqlProfile, 'id' | 'name'>
        ) }
      ) }
    )>, documents: Array<(
      { __typename?: 'Document' }
      & Pick<GqlDocument, 'id' | 'documentType' | 'importFileName' | 'fileName' | 'fileType' | 'fileSize' | 'uploadDate' | 'description'>
    )>, relatedParties: Array<(
      { __typename?: 'RelatedParty' }
      & Pick<GqlRelatedParty, 'id' | 'partyType'>
      & { party: (
        { __typename?: 'Profile' }
        & Pick<GqlProfile, 'id' | 'name'>
        & { locations: Array<(
          { __typename?: 'Location' }
          & Pick<GqlLocation, 'id' | 'addressLine1' | 'addressLine2' | 'city' | 'state' | 'postalCode' | 'country'>
        )> }
      ) }
    )> }
  )> }
);

export type GqlNewHblRemarkMutationVariables = Exact<{
  input: GqlNewHblRemarkInput;
}>;


export type GqlNewHblRemarkMutation = (
  { __typename?: 'Mutation' }
  & { newHblRemark: (
    { __typename?: 'NewHblRemarkPayload' }
    & Pick<GqlNewHblRemarkPayload, 'success' | 'message'>
    & { remark?: Maybe<(
      { __typename?: 'Remark' }
      & Pick<GqlRemark, 'id' | 'text' | 'createDate'>
      & { createdBy: (
        { __typename?: 'Contact' }
        & Pick<GqlContact, 'firstName' | 'lastName' | 'title'>
        & { profile: (
          { __typename?: 'Profile' }
          & Pick<GqlProfile, 'name'>
        ) }
      ) }
    )> }
  ) }
);

export type GqlAssignHblRelatedPartyMutationVariables = Exact<{
  input: GqlAssignHblRelatedPartyInput;
}>;


export type GqlAssignHblRelatedPartyMutation = (
  { __typename?: 'Mutation' }
  & { assignHblRelatedParty: (
    { __typename?: 'AssignHblRelatedPartyPayload' }
    & Pick<GqlAssignHblRelatedPartyPayload, 'success' | 'message'>
  ) }
);

export type GqlRemoveHblRelatedPartyMutationVariables = Exact<{
  input: GqlRemoveHblRelatedPartyInput;
}>;


export type GqlRemoveHblRelatedPartyMutation = (
  { __typename?: 'Mutation' }
  & { removeHblRelatedParty: (
    { __typename?: 'RemoveHblRelatedPartyPayload' }
    & Pick<GqlRemoveHblRelatedPartyPayload, 'success' | 'message'>
  ) }
);

export type GqlUpdateHblRelatedPartyMutationVariables = Exact<{
  input: GqlUpdateHblRelatedPartyInput;
}>;


export type GqlUpdateHblRelatedPartyMutation = (
  { __typename?: 'Mutation' }
  & { updateHblRelatedParty: (
    { __typename?: 'UpdateHblRelatedPartyPayload' }
    & Pick<GqlUpdateHblRelatedPartyPayload, 'success' | 'message'>
  ) }
);

export type GqlUpdateLocationMutationVariables = Exact<{
  input: GqlUpdateLocationInput;
}>;


export type GqlUpdateLocationMutation = (
  { __typename?: 'Mutation' }
  & { updateLocation: (
    { __typename?: 'UpdateLocationPayload' }
    & Pick<GqlUpdateLocationPayload, 'success' | 'message'>
  ) }
);

export type GqlMergeLocationMutationVariables = Exact<{
  input: GqlMergeLocationInput;
}>;


export type GqlMergeLocationMutation = (
  { __typename?: 'Mutation' }
  & { mergeLocation: (
    { __typename?: 'MergeLocationPayload' }
    & Pick<GqlMergeLocationPayload, 'success' | 'message'>
  ) }
);

export type GqlNewProfileLocationMutationVariables = Exact<{
  input: GqlNewProfileLocationInput;
}>;


export type GqlNewProfileLocationMutation = (
  { __typename?: 'Mutation' }
  & { newProfileLocation: (
    { __typename?: 'NewProfileLocationPayload' }
    & Pick<GqlNewProfileLocationPayload, 'success' | 'message'>
    & { location?: Maybe<(
      { __typename?: 'Location' }
      & Pick<GqlLocation, 'id' | 'name'>
    )> }
  ) }
);

export type GqlCarriersQueryVariables = Exact<{ [key: string]: never; }>;


export type GqlCarriersQuery = (
  { __typename?: 'Query' }
  & { carriers: Array<(
    { __typename?: 'Carrier' }
    & Pick<GqlCarrier, 'id' | 'scac' | 'name'>
  )> }
);

export type GqlVesselsQueryVariables = Exact<{ [key: string]: never; }>;


export type GqlVesselsQuery = (
  { __typename?: 'Query' }
  & { vessels: Array<(
    { __typename?: 'Vessel' }
    & Pick<GqlVessel, 'id' | 'name'>
    & { voyages: Array<(
      { __typename?: 'Voyage' }
      & Pick<GqlVoyage, 'id' | 'name'>
    )> }
  )> }
);

export type GqlMblQueryVariables = Exact<{
  mblId: Scalars['ID'];
}>;


export type GqlMblQuery = (
  { __typename?: 'Query' }
  & { mbl?: Maybe<(
    { __typename?: 'MasterBill' }
    & Pick<GqlMasterBill, 'id' | 'referenceNumber' | 'containerCount' | 'totalPieces' | 'totalCartons' | 'releaseType' | 'paymentType'>
    & { shipment: (
      { __typename?: 'Booking' }
      & Pick<GqlBooking, 'id' | 'status' | 'referenceNumber'>
      & { hbl?: Maybe<(
        { __typename?: 'HouseBill' }
        & Pick<GqlHouseBill, 'id' | 'referenceNumber' | 'releaseType'>
        & { booking: (
          { __typename?: 'Booking' }
          & Pick<GqlBooking, 'id' | 'referenceNumber'>
        ), remarks: Array<(
          { __typename?: 'Remark' }
          & Pick<GqlRemark, 'id' | 'text' | 'createDate'>
          & { createdBy: (
            { __typename?: 'Contact' }
            & Pick<GqlContact, 'id' | 'firstName' | 'lastName'>
            & { profile: (
              { __typename?: 'Profile' }
              & Pick<GqlProfile, 'id' | 'name'>
            ) }
          ) }
        )>, documents: Array<(
          { __typename?: 'Document' }
          & Pick<GqlDocument, 'id' | 'documentType' | 'importFileName' | 'fileName' | 'fileType' | 'fileSize' | 'uploadDate' | 'description'>
        )> }
      )>, logistics: (
        { __typename?: 'Logistics' }
        & Pick<GqlLogistics, 'contractNumber' | 'contractType' | 'moveType' | 'polEtd' | 'polAtd' | 'podEta' | 'podAta' | 'rampEta' | 'rampAta' | 'deliveryEta' | 'deliveryAta'>
        & { carrier?: Maybe<(
          { __typename?: 'Carrier' }
          & Pick<GqlCarrier, 'id' | 'name'>
        )>, motherVessel?: Maybe<(
          { __typename?: 'Vessel' }
          & Pick<GqlVessel, 'id' | 'name'>
        )>, motherVoyage?: Maybe<(
          { __typename?: 'Voyage' }
          & Pick<GqlVoyage, 'id' | 'name'>
        )>, pol?: Maybe<(
          { __typename?: 'Port' }
          & GqlPortFragment
        )>, pod?: Maybe<(
          { __typename?: 'Port' }
          & GqlPortFragment
        )>, ramp?: Maybe<(
          { __typename?: 'Port' }
          & GqlPortFragment
        )>, deliveryLocation?: Maybe<(
          { __typename?: 'Location' }
          & Pick<GqlLocation, 'id' | 'name'>
        )> }
      ), remarks: Array<(
        { __typename?: 'Remark' }
        & Pick<GqlRemark, 'id' | 'text' | 'createDate'>
        & { createdBy: (
          { __typename?: 'Contact' }
          & Pick<GqlContact, 'id' | 'firstName' | 'lastName'>
          & { profile: (
            { __typename?: 'Profile' }
            & Pick<GqlProfile, 'id' | 'name'>
          ) }
        ) }
      )>, containers: Array<(
        { __typename?: 'Container' }
        & Pick<GqlContainer, 'id' | 'containerNumber' | 'containerType' | 'sealNumber' | 'volume' | 'weight' | 'shipFromFactoryDate' | 'terminalReceivedDate' | 'isHot'>
      )>, documents: Array<(
        { __typename?: 'Document' }
        & Pick<GqlDocument, 'id' | 'documentType' | 'importFileName' | 'fileName' | 'fileType' | 'fileSize' | 'uploadDate' | 'description'>
      )> }
    ) | (
      { __typename?: 'Consolidation' }
      & Pick<GqlConsolidation, 'id' | 'status' | 'referenceNumber'>
      & { logistics: (
        { __typename?: 'Logistics' }
        & Pick<GqlLogistics, 'contractNumber' | 'contractType' | 'moveType' | 'polEtd' | 'polAtd' | 'podEta' | 'podAta' | 'rampEta' | 'rampAta' | 'deliveryEta' | 'deliveryAta'>
        & { carrier?: Maybe<(
          { __typename?: 'Carrier' }
          & Pick<GqlCarrier, 'id' | 'name'>
        )>, motherVessel?: Maybe<(
          { __typename?: 'Vessel' }
          & Pick<GqlVessel, 'id' | 'name'>
        )>, motherVoyage?: Maybe<(
          { __typename?: 'Voyage' }
          & Pick<GqlVoyage, 'id' | 'name'>
        )>, pol?: Maybe<(
          { __typename?: 'Port' }
          & GqlPortFragment
        )>, pod?: Maybe<(
          { __typename?: 'Port' }
          & GqlPortFragment
        )>, ramp?: Maybe<(
          { __typename?: 'Port' }
          & GqlPortFragment
        )>, deliveryLocation?: Maybe<(
          { __typename?: 'Location' }
          & Pick<GqlLocation, 'id' | 'name'>
        )> }
      ), remarks: Array<(
        { __typename?: 'Remark' }
        & Pick<GqlRemark, 'id' | 'text' | 'createDate'>
        & { createdBy: (
          { __typename?: 'Contact' }
          & Pick<GqlContact, 'id' | 'firstName' | 'lastName'>
          & { profile: (
            { __typename?: 'Profile' }
            & Pick<GqlProfile, 'id' | 'name'>
          ) }
        ) }
      )>, containers: Array<(
        { __typename?: 'Container' }
        & Pick<GqlContainer, 'id' | 'containerNumber' | 'containerType' | 'sealNumber' | 'volume' | 'weight' | 'shipFromFactoryDate' | 'terminalReceivedDate' | 'isHot'>
      )>, documents: Array<(
        { __typename?: 'Document' }
        & Pick<GqlDocument, 'id' | 'documentType' | 'importFileName' | 'fileName' | 'fileType' | 'fileSize' | 'uploadDate' | 'description'>
      )> }
    ), remarks: Array<(
      { __typename?: 'Remark' }
      & Pick<GqlRemark, 'id' | 'text' | 'createDate'>
      & { createdBy: (
        { __typename?: 'Contact' }
        & Pick<GqlContact, 'id' | 'firstName' | 'lastName'>
        & { profile: (
          { __typename?: 'Profile' }
          & Pick<GqlProfile, 'id' | 'name'>
        ) }
      ) }
    )>, documents: Array<(
      { __typename?: 'Document' }
      & Pick<GqlDocument, 'id' | 'documentType' | 'importFileName' | 'fileName' | 'fileType' | 'fileSize' | 'uploadDate' | 'description'>
    )>, relatedParties: Array<(
      { __typename?: 'RelatedParty' }
      & Pick<GqlRelatedParty, 'id' | 'partyType'>
      & { party: (
        { __typename?: 'Profile' }
        & Pick<GqlProfile, 'id' | 'name' | 'profileCode'>
        & { profileType?: Maybe<(
          { __typename?: 'ProfileType' }
          & Pick<GqlProfileType, 'name'>
        )> }
      ) }
    )> }
  )> }
);

export type GqlNewMblRemarkMutationVariables = Exact<{
  input: GqlNewMblRemarkInput;
}>;


export type GqlNewMblRemarkMutation = (
  { __typename?: 'Mutation' }
  & { newMblRemark: (
    { __typename?: 'NewMblRemarkPayload' }
    & Pick<GqlNewMblRemarkPayload, 'success'>
  ) }
);

export type GqlAssignMblRelatedPartyMutationVariables = Exact<{
  input: GqlAssignMblRelatedPartyInput;
}>;


export type GqlAssignMblRelatedPartyMutation = (
  { __typename?: 'Mutation' }
  & { assignMblRelatedParty: (
    { __typename?: 'AssignMblRelatedPartyPayload' }
    & Pick<GqlAssignMblRelatedPartyPayload, 'success' | 'message'>
  ) }
);

export type GqlRemoveMblRelatedPartyMutationVariables = Exact<{
  input: GqlRemoveMblRelatedPartyInput;
}>;


export type GqlRemoveMblRelatedPartyMutation = (
  { __typename?: 'Mutation' }
  & { removeMblRelatedParty: (
    { __typename?: 'RemoveMblRelatedPartyPayload' }
    & Pick<GqlRemoveMblRelatedPartyPayload, 'success' | 'message'>
  ) }
);

export type GqlUpdateMblRelatedPartyMutationVariables = Exact<{
  input: GqlUpdateMblRelatedPartyInput;
}>;


export type GqlUpdateMblRelatedPartyMutation = (
  { __typename?: 'Mutation' }
  & { updateMblRelatedParty: (
    { __typename?: 'UpdateMblRelatedPartyPayload' }
    & Pick<GqlUpdateMblRelatedPartyPayload, 'success' | 'message'>
  ) }
);

export type GqlNetworkPartiesQueryVariables = Exact<{
  networkId: Scalars['ID'];
}>;


export type GqlNetworkPartiesQuery = (
  { __typename?: 'Query' }
  & { network?: Maybe<(
    { __typename?: 'Network' }
    & { connectedProfiles: Array<(
      { __typename?: 'Profile' }
      & Pick<GqlProfile, 'id' | 'name'>
      & { profileType?: Maybe<(
        { __typename?: 'ProfileType' }
        & Pick<GqlProfileType, 'id' | 'name'>
      )>, locations: Array<(
        { __typename?: 'Location' }
        & Pick<GqlLocation, 'id' | 'name'>
      )> }
    )> }
  )> }
);

export type GqlAllNetworksQueryVariables = Exact<{ [key: string]: never; }>;


export type GqlAllNetworksQuery = (
  { __typename?: 'Query' }
  & { allNetworks: Array<(
    { __typename?: 'Network' }
    & Pick<GqlNetwork, 'id' | 'name'>
    & { connectedProfiles: Array<(
      { __typename?: 'Profile' }
      & Pick<GqlProfile, 'id' | 'name' | 'isControllingClient'>
    )> }
  )> }
);

export type GqlNetworkQueryVariables = Exact<{
  networkId: Scalars['ID'];
}>;


export type GqlNetworkQuery = (
  { __typename?: 'Query' }
  & { network?: Maybe<(
    { __typename?: 'Network' }
    & Pick<GqlNetwork, 'id' | 'name'>
    & { connectedProfiles: Array<(
      { __typename?: 'Profile' }
      & Pick<GqlProfile, 'id' | 'name' | 'isControllingClient' | 'needsUpdate'>
      & { profileType?: Maybe<(
        { __typename?: 'ProfileType' }
        & Pick<GqlProfileType, 'id' | 'name'>
      )>, logo?: Maybe<(
        { __typename?: 'Image' }
        & Pick<GqlImage, 'url'>
      )>, locations: Array<(
        { __typename?: 'Location' }
        & Pick<GqlLocation, 'id' | 'name' | 'code' | 'nameAliases' | 'codeAliases' | 'addressLine1' | 'addressLine2' | 'city' | 'state' | 'postalCode' | 'country' | 'companyPhone' | 'companyUrl' | 'locationTypes'>
        & { relatedPort?: Maybe<(
          { __typename?: 'Port' }
          & Pick<GqlPort, 'code' | 'name'>
        )> }
      )>, contacts: Array<(
        { __typename?: 'Contact' }
        & Pick<GqlContact, 'id' | 'firstName' | 'lastName' | 'title' | 'email' | 'phoneMain' | 'phoneAlternative' | 'extension' | 'phoneCell' | 'isMainContact' | 'isUserEnabled' | 'notificationFrequency'>
        & { role?: Maybe<(
          { __typename?: 'Role' }
          & Pick<GqlRole, 'id' | 'name'>
        )>, location?: Maybe<(
          { __typename?: 'Location' }
          & Pick<GqlLocation, 'id' | 'name'>
        )> }
      )>, relatedProfiles: Array<(
        { __typename?: 'RelatedProfile' }
        & Pick<GqlRelatedProfile, 'id'>
      )>, roles: Array<(
        { __typename?: 'Role' }
        & Pick<GqlRole, 'id' | 'name' | 'description' | 'isDefaultRole'>
      )>, network: (
        { __typename?: 'Network' }
        & Pick<GqlNetwork, 'id' | 'name'>
      ) }
    )>, profileTypes: Array<(
      { __typename?: 'ProfileType' }
      & Pick<GqlProfileType, 'id' | 'name' | 'description' | 'isTemplate' | 'permissionCodes'>
      & { defaultRoles: Array<(
        { __typename?: 'Role' }
        & Pick<GqlRole, 'id' | 'name' | 'description' | 'isDefaultRole' | 'permissionCodes'>
      )> }
    )>, items: Array<(
      { __typename?: 'Item' }
      & Pick<GqlItem, 'id' | 'itemNumber' | 'description' | 'htsCode'>
      & { supplier?: Maybe<(
        { __typename?: 'Profile' }
        & Pick<GqlProfile, 'name'>
      )> }
    )>, rules: (
      { __typename?: 'NetworkRules' }
      & Pick<GqlNetworkRules, 'poExpiredEnabled' | 'poExpiredDays' | 'poExpiredField' | 'poFirstShipEnabled' | 'poFirstShipDays' | 'poFirstShipField' | 'poLastShipEnabled' | 'poLastShipDays' | 'poLastShipField' | 'poBookByEnabled' | 'poBookByDays' | 'poBookByField' | 'poCriticalEnabled' | 'poCriticalDays' | 'poCriticalField' | 'bookingLateEnabled' | 'bookingLateDays' | 'bookingLateField' | 'bookingPendingConfEnabled' | 'bookingPendingConfDays' | 'bookingQtyToleranceEnabled' | 'bookingQtyTolerancePercentOver' | 'bookingQtyTolerancePercentUnder' | 'consolConsolableMoveTypesEnabled' | 'consolConsolableMoveTypes' | 'shipmentPolEtdChangeEnabled' | 'shipmentPolEtdChangeDays' | 'shipmentPodEtaChangeEnabled' | 'shipmentPodEtaChangeDays' | 'shipmentRevisedCrdChangeEnabled' | 'shipmentRevisedCrdChangeDays' | 'shipmentCriticalEnabled' | 'shipmentCriticalDays' | 'shipmentCriticalField1' | 'shipmentCriticalField2' | 'milestoneLateOnWaterEnabled' | 'milestoneLateOnWaterDays' | 'milestoneLateAtDischargeEnabled' | 'milestoneLateAtDischargeDays' | 'milestoneLateAtRampEnabled' | 'milestoneLateAtRampDays' | 'milestoneLateDeliveryEnabled' | 'milestoneLateDeliveryDays' | 'milestonePendingOutgateEnabled' | 'milestonePendingOutgateDays' | 'milestoneDelayedUnloadEnabled' | 'milestoneDelayedUnloadDays' | 'milestoneDelayedUnloadField' | 'milestoneEmptyNotReturnedEnabled' | 'milestoneEmptyNotReturnedDays'>
      & { documentTypes: Array<(
        { __typename?: 'NetworkDocumentTypeRule' }
        & Pick<GqlNetworkDocumentTypeRule, 'documentType' | 'missingEnabled' | 'missingDays' | 'missingField'>
      )> }
    ), containerTypes: Array<(
      { __typename?: 'NetworkContainerType' }
      & Pick<GqlNetworkContainerType, 'code' | 'name' | 'capacityVolumeM3' | 'capacityWeightKg' | 'ruleMinVolumeM3' | 'ruleMaxVolumeM3' | 'ruleMinWeightKg' | 'ruleMaxWeightKg'>
    )> }
  )> }
);

export type GqlNetworkProfilesQueryVariables = Exact<{
  networkId: Scalars['ID'];
}>;


export type GqlNetworkProfilesQuery = (
  { __typename?: 'Query' }
  & { network?: Maybe<(
    { __typename?: 'Network' }
    & { connectedProfiles: Array<(
      { __typename?: 'Profile' }
      & Pick<GqlProfile, 'id' | 'name'>
    )> }
  )> }
);

export type GqlNewNetworkMutationVariables = Exact<{
  input: GqlNewNetworkInput;
}>;


export type GqlNewNetworkMutation = (
  { __typename?: 'Mutation' }
  & { newNetwork: (
    { __typename?: 'NewNetworkPayload' }
    & Pick<GqlNewNetworkPayload, 'success' | 'message'>
    & { network?: Maybe<(
      { __typename?: 'Network' }
      & Pick<GqlNetwork, 'id'>
      & { connectedProfiles: Array<(
        { __typename?: 'Profile' }
        & Pick<GqlProfile, 'id' | 'name'>
      )> }
    )> }
  ) }
);

export type GqlUpdateNetworkRulesMutationVariables = Exact<{
  input: GqlUpdateNetworkRulesInput;
}>;


export type GqlUpdateNetworkRulesMutation = (
  { __typename?: 'Mutation' }
  & { updateNetworkRules: (
    { __typename?: 'UpdateNetworkRulesPayload' }
    & Pick<GqlUpdateNetworkRulesPayload, 'success' | 'message'>
  ) }
);

export type GqlUpdateNetworkContainerTypesMutationVariables = Exact<{
  input: GqlUpdateNetworkContainerTypesInput;
}>;


export type GqlUpdateNetworkContainerTypesMutation = (
  { __typename?: 'Mutation' }
  & { updateNetworkContainerTypes: (
    { __typename?: 'UpdateNetworkContainerTypesPayload' }
    & Pick<GqlUpdateNetworkContainerTypesPayload, 'success' | 'message'>
  ) }
);

export type GqlNewNetworkConnectedProfileMutationVariables = Exact<{
  input: GqlNewNetworkConnectedProfileInput;
}>;


export type GqlNewNetworkConnectedProfileMutation = (
  { __typename?: 'Mutation' }
  & { newNetworkConnectedProfile: (
    { __typename?: 'NewNetworkConnectedProfilePayload' }
    & Pick<GqlNewNetworkConnectedProfilePayload, 'success' | 'message'>
    & { profile?: Maybe<(
      { __typename?: 'Profile' }
      & Pick<GqlProfile, 'id' | 'name'>
    )> }
  ) }
);

export type GqlNewNetworkProfileTypeMutationVariables = Exact<{
  input: GqlNewNetworkProfileTypeInput;
}>;


export type GqlNewNetworkProfileTypeMutation = (
  { __typename?: 'Mutation' }
  & { newNetworkProfileType: (
    { __typename?: 'NewNetworkProfileTypePayload' }
    & Pick<GqlNewNetworkProfileTypePayload, 'success' | 'message'>
    & { profileType?: Maybe<(
      { __typename?: 'ProfileType' }
      & Pick<GqlProfileType, 'id'>
    )> }
  ) }
);

export type GqlOpenOrdersQueryVariables = Exact<{ [key: string]: never; }>;


export type GqlOpenOrdersQuery = (
  { __typename?: 'Query' }
  & { openOrders: Array<(
    { __typename?: 'OpenOrder' }
    & Pick<GqlOpenOrder, 'id' | 'orderQty' | 'approvedQty' | 'balanceQty' | 'shippedQty' | 'shippedCartons' | 'isHot'>
    & { orderLine: (
      { __typename?: 'OrderLine' }
      & Pick<GqlOrderLine, 'id' | 'lineNumber' | 'itemNumber' | 'totalQty' | 'innerQty' | 'outerQty' | 'weight' | 'weightUnit' | 'volume' | 'volumeUnit' | 'expectedCargoReadyDate' | 'revisedCargoReadyDate' | 'firstShipDate' | 'lastShipDate'>
      & { purchaseOrder: (
        { __typename?: 'PurchaseOrder' }
        & Pick<GqlPurchaseOrder, 'id' | 'poNumber'>
      ), shipToLocation?: Maybe<(
        { __typename?: 'Location' }
        & Pick<GqlLocation, 'id' | 'name'>
      )> }
    ) }
  )> }
);

export type GqlOpenOrdersRelatedPartiesQueryVariables = Exact<{
  orderLineIds: Array<Scalars['ID']> | Scalars['ID'];
}>;


export type GqlOpenOrdersRelatedPartiesQuery = (
  { __typename?: 'Query' }
  & { openOrders: Array<(
    { __typename?: 'OpenOrder' }
    & { orderLine: (
      { __typename?: 'OrderLine' }
      & Pick<GqlOrderLine, 'id'>
      & { purchaseOrder: (
        { __typename?: 'PurchaseOrder' }
        & { relatedParties: Array<(
          { __typename?: 'RelatedParty' }
          & Pick<GqlRelatedParty, 'partyType'>
          & { party: (
            { __typename?: 'Profile' }
            & Pick<GqlProfile, 'id' | 'name'>
            & { locations: Array<(
              { __typename?: 'Location' }
              & Pick<GqlLocation, 'id' | 'name'>
            )>, relatedProfiles: Array<(
              { __typename?: 'RelatedProfile' }
              & Pick<GqlRelatedProfile, 'id' | 'partyType'>
              & { profile: (
                { __typename?: 'Profile' }
                & Pick<GqlProfile, 'id'>
              ) }
            )> }
          ) }
        )> }
      ) }
    ) }
  )> }
);

export type GqlPurchaseOrdersQueryVariables = Exact<{ [key: string]: never; }>;


export type GqlPurchaseOrdersQuery = (
  { __typename?: 'Query' }
  & { purchaseOrders: Array<(
    { __typename?: 'PurchaseOrder' }
    & Pick<GqlPurchaseOrder, 'id' | 'poNumber' | 'poDate' | 'expectedCargoReadyDate' | 'poStatus'>
    & { shipToLocation?: Maybe<(
      { __typename?: 'Location' }
      & Pick<GqlLocation, 'id' | 'name' | 'addressLine1' | 'addressLine2' | 'city' | 'state' | 'country' | 'postalCode'>
    )>, relatedParties: Array<(
      { __typename?: 'RelatedParty' }
      & Pick<GqlRelatedParty, 'id' | 'partyType'>
      & { party: (
        { __typename?: 'Profile' }
        & Pick<GqlProfile, 'id' | 'name'>
      ) }
    )>, orderLines: Array<(
      { __typename?: 'OrderLine' }
      & Pick<GqlOrderLine, 'id' | 'itemNumber' | 'itemDescription' | 'unitPrice' | 'expectedCargoReadyDate' | 'revisedCargoReadyDate' | 'firstShipDate' | 'lastShipDate'>
      & { shipToLocation?: Maybe<(
        { __typename?: 'Location' }
        & Pick<GqlLocation, 'id' | 'name' | 'addressLine1' | 'addressLine2' | 'city' | 'state' | 'country' | 'postalCode'>
      )>, openOrder: (
        { __typename?: 'OpenOrder' }
        & Pick<GqlOpenOrder, 'balanceQty' | 'orderQty' | 'isHot'>
      ) }
    )> }
  )> }
);

export type GqlPurchaseOrderQueryVariables = Exact<{
  poId: Scalars['ID'];
}>;


export type GqlPurchaseOrderQuery = (
  { __typename?: 'Query' }
  & { purchaseOrder?: Maybe<(
    { __typename?: 'PurchaseOrder' }
    & Pick<GqlPurchaseOrder, 'id' | 'poNumber' | 'altPoNumber' | 'indcDate' | 'poDate' | 'poType' | 'poStatus' | 'customerPoNumber' | 'expectedCargoReadyDate' | 'htsCode' | 'originCountry' | 'customerCancelDate' | 'incoTerm' | 'customerStartShipDate' | 'isHot' | 'hotMarkedBy' | 'hotMarkedTimestamp'>
    & { shipToLocation?: Maybe<(
      { __typename?: 'Location' }
      & Pick<GqlLocation, 'id' | 'name' | 'addressLine1' | 'addressLine2' | 'city' | 'state' | 'country' | 'code' | 'postalCode'>
    )>, pol?: Maybe<(
      { __typename?: 'Port' }
      & GqlPortFragment
    )>, pod?: Maybe<(
      { __typename?: 'Port' }
      & GqlPortFragment
    )>, relatedParties: Array<(
      { __typename?: 'RelatedParty' }
      & Pick<GqlRelatedParty, 'id' | 'partyType'>
      & { party: (
        { __typename?: 'Profile' }
        & Pick<GqlProfile, 'id' | 'name' | 'profileCode'>
      ) }
    )>, orderLines: Array<(
      { __typename?: 'OrderLine' }
      & Pick<GqlOrderLine, 'id' | 'lineNumber' | 'lineStatus' | 'bookByDate' | 'expectedCargoReadyDate' | 'revisedCargoReadyDate' | 'totalQty' | 'outerQty' | 'innerQty' | 'indcDate' | 'weight' | 'weightUnit' | 'volume' | 'volumeUnit' | 'unitPrice' | 'unitPriceCurrencyCode' | 'firstShipDate' | 'lastShipDate' | 'itemNumber' | 'itemDescription' | 'itemHtsCode' | 'itemStyle' | 'itemSize' | 'itemColor' | 'itemMaterial' | 'itemCategoryCode' | 'itemLength' | 'itemWidth' | 'itemHeight' | 'itemDimsUnit' | 'itemWeight' | 'itemWeightUnit' | 'itemVolume' | 'itemVolumeUnit' | 'itemDuty' | 'itemDutyCurrencyCode'>
      & { openOrder: (
        { __typename?: 'OpenOrder' }
        & Pick<GqlOpenOrder, 'approvedQty' | 'balanceQty' | 'shippedQty' | 'isHot' | 'hotMarkedBy' | 'hotMarkedTimestamp'>
      ), shipToLocation?: Maybe<(
        { __typename?: 'Location' }
        & Pick<GqlLocation, 'id' | 'name' | 'code' | 'addressLine1' | 'addressLine2' | 'city' | 'state' | 'country' | 'postalCode'>
      )> }
    )>, poHistory: Array<(
      { __typename?: 'PoHistoryEntry' }
      & Pick<GqlPoHistoryEntry, 'id' | 'timestamp' | 'recordType' | 'message' | 'byUserName' | 'byPoUploadFileName'>
    )> }
  )> }
);

export type GqlSetPoRelatedPartiesMutationVariables = Exact<{
  input: GqlSetPoRelatedPartiesInput;
}>;


export type GqlSetPoRelatedPartiesMutation = (
  { __typename?: 'Mutation' }
  & { setPoRelatedParties: (
    { __typename?: 'SetPoRelatedPartiesPayload' }
    & Pick<GqlSetPoRelatedPartiesPayload, 'success' | 'message'>
  ) }
);

export type GqlMarkPoLineAsHotMutationVariables = Exact<{
  input: GqlMarkPoLineAsHotInput;
}>;


export type GqlMarkPoLineAsHotMutation = (
  { __typename?: 'Mutation' }
  & { markPoLineAsHot: (
    { __typename?: 'MarkPoLineAsHotPayload' }
    & Pick<GqlMarkPoLineAsHotPayload, 'success' | 'message'>
    & { openOrder?: Maybe<(
      { __typename?: 'OpenOrder' }
      & Pick<GqlOpenOrder, 'isHot' | 'hotMarkedBy' | 'hotMarkedTimestamp'>
    )> }
  ) }
);

export type GqlMarkPoAsHotMutationVariables = Exact<{
  input: GqlMarkPoAsHotInput;
}>;


export type GqlMarkPoAsHotMutation = (
  { __typename?: 'Mutation' }
  & { markPoAsHot: (
    { __typename?: 'MarkPoAsHotPayload' }
    & Pick<GqlMarkPoAsHotPayload, 'success' | 'message'>
    & { purchaseOrder?: Maybe<(
      { __typename?: 'PurchaseOrder' }
      & Pick<GqlPurchaseOrder, 'isHot' | 'hotMarkedBy' | 'hotMarkedTimestamp'>
    )> }
  ) }
);

export type GqlPoImportFileHeadersFragment = (
  { __typename?: 'PoImportHeader' }
  & Pick<GqlPoImportHeader, 'id' | 'name' | 'headerIndex'>
  & { mapping?: Maybe<(
    { __typename?: 'PoImportField' }
    & Pick<GqlPoImportField, 'key' | 'name'>
    & { group: (
      { __typename?: 'PoImportFieldGroup' }
      & Pick<GqlPoImportFieldGroup, 'name'>
    ) }
  )> }
);

export type GqlPoImportFileStatusFragment = (
  { __typename?: 'PoImportFile' }
  & Pick<GqlPoImportFile, 'totalPos' | 'totalLines' | 'updatedPos' | 'updatedLines' | 'poErrors' | 'lineErrors' | 'newPos' | 'newLines' | 'fileStatus'>
  & { headers: Array<(
    { __typename?: 'PoImportHeader' }
    & GqlPoImportFileHeadersFragment
  )>, errors: Array<(
    { __typename?: 'PoImportFileError' }
    & Pick<GqlPoImportFileError, 'rowIndex' | 'headerIndex' | 'error'>
  )> }
);

export type GqlPoImportFileQueryVariables = Exact<{
  fileId: Scalars['ID'];
}>;


export type GqlPoImportFileQuery = (
  { __typename?: 'Query' }
  & { poImportFile?: Maybe<(
    { __typename?: 'PoImportFile' }
    & Pick<GqlPoImportFile, 'id' | 'rows'>
    & { network: (
      { __typename?: 'Network' }
      & Pick<GqlNetwork, 'id'>
    ) }
    & GqlPoImportFileStatusFragment
  )> }
);

export type GqlPoImportFieldGroupsQueryVariables = Exact<{ [key: string]: never; }>;


export type GqlPoImportFieldGroupsQuery = (
  { __typename?: 'Query' }
  & { poImportFieldGroups: Array<(
    { __typename?: 'PoImportFieldGroup' }
    & Pick<GqlPoImportFieldGroup, 'key' | 'name'>
    & { fields: Array<(
      { __typename?: 'PoImportField' }
      & Pick<GqlPoImportField, 'key' | 'name'>
    )> }
  )> }
);

export type GqlPoImportFilesQueryVariables = Exact<{ [key: string]: never; }>;


export type GqlPoImportFilesQuery = (
  { __typename?: 'Query' }
  & { poImportFiles: Array<(
    { __typename?: 'PoImportFile' }
    & Pick<GqlPoImportFile, 'id' | 'fileName' | 'importFileName' | 'extension' | 'totalPos' | 'fileStatus' | 'uploadDate'>
    & { network: (
      { __typename?: 'Network' }
      & Pick<GqlNetwork, 'name'>
      & { controllingClient: (
        { __typename?: 'Profile' }
        & Pick<GqlProfile, 'name'>
      ) }
    ), uploadedBy: (
      { __typename?: 'User' }
      & Pick<GqlUser, 'firstName' | 'lastName'>
    ) }
  )> }
);

export type GqlSetHeaderMutationVariables = Exact<{
  headerId: Scalars['ID'];
  omsKey?: Maybe<Scalars['String']>;
}>;


export type GqlSetHeaderMutation = (
  { __typename?: 'Mutation' }
  & { setHeaderMapping: (
    { __typename?: 'PoImportFile' }
    & GqlPoImportFileStatusFragment
  ) }
);

export type GqlConfirmImportMutationVariables = Exact<{
  fileId: Scalars['ID'];
}>;


export type GqlConfirmImportMutation = (
  { __typename?: 'Mutation' }
  & { confirmImport: (
    { __typename?: 'MutationConfirmation' }
    & Pick<GqlMutationConfirmation, 'success' | 'message'>
  ) }
);

export type GqlCancelImportMutationVariables = Exact<{
  fileId: Scalars['ID'];
}>;


export type GqlCancelImportMutation = (
  { __typename?: 'Mutation' }
  & { cancelImport: (
    { __typename?: 'MutationConfirmation' }
    & Pick<GqlMutationConfirmation, 'success' | 'message'>
  ) }
);

export type GqlUploadPoFileMutationMutationVariables = Exact<{
  file: Scalars['Upload'];
  networkId: Scalars['ID'];
}>;


export type GqlUploadPoFileMutationMutation = (
  { __typename?: 'Mutation' }
  & { uploadPoFile: (
    { __typename?: 'PoImportFile' }
    & Pick<GqlPoImportFile, 'id'>
  ) }
);

export type GqlPortFragment = (
  { __typename?: 'Port' }
  & Pick<GqlPort, 'code' | 'name' | 'latitude' | 'longitude' | 'stateCode' | 'stateName' | 'countryCode' | 'countryName'>
);

export type GqlSearchPortsQueryVariables = Exact<{
  query: Scalars['String'];
}>;


export type GqlSearchPortsQuery = (
  { __typename?: 'Query' }
  & { searchPorts: Array<(
    { __typename?: 'Port' }
    & GqlPortFragment
  )> }
);

export type GqlProfileQueryVariables = Exact<{
  profileId: Scalars['ID'];
}>;


export type GqlProfileQuery = (
  { __typename?: 'Query' }
  & { profile?: Maybe<(
    { __typename?: 'Profile' }
    & Pick<GqlProfile, 'id' | 'name' | 'profileCode' | 'nameAliases' | 'profileCodeAliases' | 'isControllingClient' | 'needsUpdate'>
    & { logo?: Maybe<(
      { __typename?: 'Image' }
      & Pick<GqlImage, 'id' | 'imageType' | 'fileName' | 'fileType' | 'fileSize' | 'uploadDate' | 'url'>
    )>, network: (
      { __typename?: 'Network' }
      & Pick<GqlNetwork, 'id' | 'name'>
      & { connectedProfiles: Array<(
        { __typename?: 'Profile' }
        & Pick<GqlProfile, 'id' | 'name' | 'isControllingClient'>
      )>, profileTypes: Array<(
        { __typename?: 'ProfileType' }
        & Pick<GqlProfileType, 'id' | 'name'>
      )> }
    ), profileType?: Maybe<(
      { __typename?: 'ProfileType' }
      & Pick<GqlProfileType, 'id' | 'name' | 'permissionCodes' | 'notificationCodes' | 'dashboardFeatureCodes'>
    )>, locations: Array<(
      { __typename?: 'Location' }
      & Pick<GqlLocation, 'id' | 'name' | 'code' | 'nameAliases' | 'codeAliases' | 'addressLine1' | 'addressLine2' | 'city' | 'state' | 'postalCode' | 'country' | 'companyPhone' | 'companyUrl' | 'needsUpdate' | 'locationTypes'>
      & { relatedPort?: Maybe<(
        { __typename?: 'Port' }
        & Pick<GqlPort, 'code' | 'name'>
      )> }
    )>, contacts: Array<(
      { __typename?: 'Contact' }
      & Pick<GqlContact, 'id' | 'firstName' | 'lastName' | 'title' | 'email' | 'phoneMain' | 'extension' | 'phoneAlternative' | 'phoneCell' | 'isUserEnabled' | 'notificationFrequency' | 'isMainContact' | 'needsUpdate'>
      & { location?: Maybe<(
        { __typename?: 'Location' }
        & Pick<GqlLocation, 'id' | 'name'>
      )>, role?: Maybe<(
        { __typename?: 'Role' }
        & Pick<GqlRole, 'id' | 'name'>
      )> }
    )>, relatedProfiles: Array<(
      { __typename?: 'RelatedProfile' }
      & Pick<GqlRelatedProfile, 'id' | 'partyType'>
      & { profile: (
        { __typename?: 'Profile' }
        & Pick<GqlProfile, 'id' | 'name' | 'profileCode'>
        & { profileType?: Maybe<(
          { __typename?: 'ProfileType' }
          & Pick<GqlProfileType, 'id' | 'name'>
        )>, roles: Array<(
          { __typename?: 'Role' }
          & Pick<GqlRole, 'id'>
        )>, locations: Array<(
          { __typename?: 'Location' }
          & Pick<GqlLocation, 'id' | 'name'>
        )>, contacts: Array<(
          { __typename?: 'Contact' }
          & Pick<GqlContact, 'id' | 'firstName' | 'lastName'>
        )>, relatedProfiles: Array<(
          { __typename?: 'RelatedProfile' }
          & Pick<GqlRelatedProfile, 'id'>
        )>, network: (
          { __typename?: 'Network' }
          & Pick<GqlNetwork, 'name'>
        ) }
      ) }
    )>, roles: Array<(
      { __typename?: 'Role' }
      & Pick<GqlRole, 'id' | 'name' | 'description' | 'isDefaultRole' | 'permissionCodes' | 'notificationCodes' | 'dashboardFeatureCodes' | 'documentTypesCanView'>
    )> }
  )> }
);

export type GqlProfilesQueryVariables = Exact<{ [key: string]: never; }>;


export type GqlProfilesQuery = (
  { __typename?: 'Query' }
  & { profiles: Array<(
    { __typename?: 'Profile' }
    & Pick<GqlProfile, 'id' | 'name' | 'profileCode' | 'needsUpdate'>
    & { profileType?: Maybe<(
      { __typename?: 'ProfileType' }
      & Pick<GqlProfileType, 'name'>
    )>, logo?: Maybe<(
      { __typename?: 'Image' }
      & Pick<GqlImage, 'url'>
    )>, locations: Array<(
      { __typename?: 'Location' }
      & Pick<GqlLocation, 'id' | 'name'>
    )>, contacts: Array<(
      { __typename?: 'Contact' }
      & Pick<GqlContact, 'id' | 'firstName' | 'lastName'>
    )>, roles: Array<(
      { __typename?: 'Role' }
      & Pick<GqlRole, 'id' | 'name'>
    )>, network: (
      { __typename?: 'Network' }
      & Pick<GqlNetwork, 'name'>
    ) }
  )> }
);

export type GqlNewProfileContactMutationVariables = Exact<{
  input: GqlNewProfileContactInput;
}>;


export type GqlNewProfileContactMutation = (
  { __typename?: 'Mutation' }
  & { newProfileContact: (
    { __typename?: 'NewProfileContactPayload' }
    & Pick<GqlNewProfileContactPayload, 'success'>
  ) }
);

export type GqlNewProfileRoleMutationVariables = Exact<{
  input: GqlNewProfileRoleInput;
}>;


export type GqlNewProfileRoleMutation = (
  { __typename?: 'Mutation' }
  & { newProfileRole: (
    { __typename?: 'NewProfileRolePayload' }
    & Pick<GqlNewProfileRolePayload, 'success'>
  ) }
);

export type GqlNewProfileRelationMutationVariables = Exact<{
  input: GqlNewProfileRelationInput;
}>;


export type GqlNewProfileRelationMutation = (
  { __typename?: 'Mutation' }
  & { newProfileRelation: (
    { __typename?: 'NewProfileRelationPayload' }
    & Pick<GqlNewProfileRelationPayload, 'success'>
  ) }
);

export type GqlUpdateProfileRelationMutationVariables = Exact<{
  input: GqlUpdateProfileRelationInput;
}>;


export type GqlUpdateProfileRelationMutation = (
  { __typename?: 'Mutation' }
  & { updateProfileRelation: (
    { __typename?: 'UpdateProfileRelationPayload' }
    & Pick<GqlUpdateProfileRelationPayload, 'success'>
  ) }
);

export type GqlUpdateProfileMutationVariables = Exact<{
  input: GqlUpdateProfileInput;
}>;


export type GqlUpdateProfileMutation = (
  { __typename?: 'Mutation' }
  & { updateProfile: (
    { __typename?: 'UpdateProfilePayload' }
    & Pick<GqlUpdateProfilePayload, 'success' | 'message'>
  ) }
);

export type GqlRemoveProfileRelationMutationVariables = Exact<{
  input: GqlRemoveProfileRelationInput;
}>;


export type GqlRemoveProfileRelationMutation = (
  { __typename?: 'Mutation' }
  & { removeProfileRelation?: Maybe<(
    { __typename?: 'RemoveProfileRelationPayload' }
    & Pick<GqlRemoveProfileRelationPayload, 'success'>
  )> }
);

export type GqlMergeProfileMutationVariables = Exact<{
  input: GqlMergeProfileInput;
}>;


export type GqlMergeProfileMutation = (
  { __typename?: 'Mutation' }
  & { mergeProfile: (
    { __typename?: 'MergeProfilePayload' }
    & Pick<GqlMergeProfilePayload, 'success' | 'message'>
  ) }
);

export type GqlNetworkProfileTypeQueryVariables = Exact<{
  networkId: Scalars['ID'];
}>;


export type GqlNetworkProfileTypeQuery = (
  { __typename?: 'Query' }
  & { network?: Maybe<(
    { __typename?: 'Network' }
    & { profileTypes: Array<(
      { __typename?: 'ProfileType' }
      & Pick<GqlProfileType, 'id' | 'name'>
    )> }
  )> }
);

export type GqlProfileTypeTemplatesQueryVariables = Exact<{ [key: string]: never; }>;


export type GqlProfileTypeTemplatesQuery = (
  { __typename?: 'Query' }
  & { profileTypeTemplates: Array<(
    { __typename?: 'ProfileType' }
    & Pick<GqlProfileType, 'id' | 'name' | 'description' | 'isTemplate' | 'permissionCodes' | 'notificationCodes' | 'dashboardFeatureCodes'>
    & { defaultRoles: Array<(
      { __typename?: 'Role' }
      & Pick<GqlRole, 'id' | 'name' | 'description' | 'permissionCodes' | 'notificationCodes' | 'dashboardFeatureCodes'>
    )> }
  )> }
);

export type GqlProfileTypeQueryVariables = Exact<{
  profileTypeId: Scalars['ID'];
}>;


export type GqlProfileTypeQuery = (
  { __typename?: 'Query' }
  & { profileType?: Maybe<(
    { __typename?: 'ProfileType' }
    & Pick<GqlProfileType, 'id' | 'name' | 'description' | 'isTemplate' | 'permissionCodes' | 'notificationCodes' | 'dashboardFeatureCodes'>
    & { defaultRoles: Array<(
      { __typename?: 'Role' }
      & Pick<GqlRole, 'id' | 'name' | 'description' | 'isDefaultRole' | 'permissionCodes' | 'notificationCodes' | 'dashboardFeatureCodes' | 'documentTypesCanView'>
    )> }
  )> }
);

export type GqlNewProfileTypeMutationVariables = Exact<{
  input: GqlNewProfileTypeTemplateInput;
}>;


export type GqlNewProfileTypeMutation = (
  { __typename?: 'Mutation' }
  & { newProfileTypeTemplate: (
    { __typename?: 'NewProfileTypeTemplatePayload' }
    & Pick<GqlNewProfileTypeTemplatePayload, 'success'>
  ) }
);

export type GqlProfileTypeUpdateMutationVariables = Exact<{
  input: GqlUpdateProfileTypeInput;
}>;


export type GqlProfileTypeUpdateMutation = (
  { __typename?: 'Mutation' }
  & { updateProfileType: (
    { __typename?: 'UpdateProfileTypePayload' }
    & Pick<GqlUpdateProfileTypePayload, 'success'>
  ) }
);

export type GqlProfileTypeDeleteMutationVariables = Exact<{
  input: GqlDeleteProfileTypeInput;
}>;


export type GqlProfileTypeDeleteMutation = (
  { __typename?: 'Mutation' }
  & { deleteProfileType: (
    { __typename?: 'DeleteProfileTypePayload' }
    & Pick<GqlDeleteProfileTypePayload, 'success' | 'message'>
  ) }
);

export type GqlNewProfileTypeDefaultRoleMutationVariables = Exact<{
  input: GqlNewProfileTypeDefaultRoleInput;
}>;


export type GqlNewProfileTypeDefaultRoleMutation = (
  { __typename?: 'Mutation' }
  & { newProfileTypeDefaultRole: (
    { __typename?: 'NewProfileTypeDefaultRolePayload' }
    & Pick<GqlNewProfileTypeDefaultRolePayload, 'success'>
  ) }
);

export type GqlQuickSearchQueryVariables = Exact<{
  searchType: SearchType;
  searchId: Scalars['ID'];
}>;


export type GqlQuickSearchQuery = (
  { __typename?: 'Query' }
  & { quickSearch: (
    { __typename?: 'QuickSearchResults' }
    & Pick<GqlQuickSearchResults, 'searchType' | 'searchTerm' | 'poCount' | 'containerCount' | 'bookingCount' | 'shipmentCount'>
    & { purchaseOrders: Array<(
      { __typename?: 'QuickSearchPo' }
      & Pick<GqlQuickSearchPo, 'id' | 'orderLineId' | 'poNumber' | 'poDate' | 'supplierName' | 'itemNumber' | 'cargoReadyDate' | 'firstShipDate' | 'lastShipDate' | 'shipToLocationName'>
    )>, containers: Array<(
      { __typename?: 'QuickSearchContainer' }
      & Pick<GqlQuickSearchContainer, 'containerId' | 'orderLineId' | 'containerNumber' | 'railRamp' | 'railRampEta' | 'railRampAta' | 'deliveryLocation' | 'finalEta' | 'finalAta'>
    )>, bookings: Array<(
      { __typename?: 'QuickSearchBooking' }
      & Pick<GqlQuickSearchBooking, 'shipmentId' | 'orderLineId' | 'referenceNumber' | 'mblId' | 'mblNumber' | 'cargoReadyDate' | 'shipToLocationName' | 'carrierName' | 'polCode' | 'polEtd' | 'polAtd' | 'podCode' | 'podEta' | 'podAta'>
    )>, shipments: Array<(
      { __typename?: 'QuickSearchShipment' }
      & Pick<GqlQuickSearchShipment, 'shipmentId' | 'orderLineId' | 'referenceNumber' | 'mblId' | 'mblNumber' | 'cargoReadyDate' | 'shipToLocationName' | 'carrierName' | 'polCode' | 'polEtd' | 'polAtd' | 'podCode' | 'podEta' | 'podAta'>
    )> }
  ) }
);

export type GqlApiConstantsQueryVariables = Exact<{ [key: string]: never; }>;


export type GqlApiConstantsQuery = (
  { __typename?: 'Query' }
  & { notificationCodes: Array<(
    { __typename?: 'NotificationCodeInfo' }
    & Pick<GqlNotificationCodeInfo, 'code' | 'group' | 'name' | 'description'>
  )>, permissions: Array<(
    { __typename?: 'Permission' }
    & Pick<GqlPermission, 'code' | 'group' | 'name' | 'description'>
  )>, systemPermissions: Array<(
    { __typename?: 'SystemPermission' }
    & Pick<GqlSystemPermission, 'code' | 'group' | 'name' | 'description'>
  )>, locationTypes: Array<(
    { __typename?: 'LocationTypeInfo' }
    & Pick<GqlLocationTypeInfo, 'code' | 'group' | 'name' | 'description'>
  )>, ruleFieldTypes: Array<(
    { __typename?: 'RuleFieldTypeInfo' }
    & Pick<GqlRuleFieldTypeInfo, 'code' | 'group' | 'name' | 'description'>
  )>, dashboardFeatureCodes: Array<(
    { __typename?: 'DashboardFeatureCodeInfo' }
    & Pick<GqlDashboardFeatureCodeInfo, 'code' | 'group' | 'name' | 'description'>
  )>, network?: Maybe<(
    { __typename?: 'Network' }
    & { containerTypes: Array<(
      { __typename?: 'NetworkContainerType' }
      & Pick<GqlNetworkContainerType, 'code' | 'name' | 'capacityVolumeM3' | 'capacityWeightKg' | 'ruleMinVolumeM3' | 'ruleMaxVolumeM3' | 'ruleMinWeightKg' | 'ruleMaxWeightKg'>
    )>, documentTypes: Array<(
      { __typename?: 'NetworkDocumentType' }
      & Pick<GqlNetworkDocumentType, 'code' | 'name'>
    )>, rules: (
      { __typename?: 'NetworkRules' }
      & Pick<GqlNetworkRules, 'bookingQtyToleranceEnabled' | 'bookingQtyTolerancePercentOver' | 'bookingQtyTolerancePercentUnder'>
    ) }
  )>, datasets: Array<(
    { __typename?: 'Dataset' }
    & GqlFragment_DatasetFragment
  )> }
);

export type GqlFragment_DatasetFragment = (
  { __typename?: 'Dataset' }
  & Pick<GqlDataset, 'id' | 'name' | 'isReport' | 'isFinancial' | 'isRootAdminOnly' | 'defaultDisplayables'>
  & { filterables: Array<(
    { __typename?: 'DatasetFilterable' }
    & Pick<GqlDatasetFilterable, 'field' | 'label' | 'type' | 'required'>
    & { options?: Maybe<Array<(
      { __typename?: 'DatasetFilterableOption' }
      & Pick<GqlDatasetFilterableOption, 'value' | 'label'>
    )>> }
  )>, displayables: Array<(
    { __typename?: 'DatasetDisplayable' }
    & Pick<GqlDatasetDisplayable, 'field' | 'label'>
  )>, sortables: Array<(
    { __typename?: 'DatasetSortable' }
    & Pick<GqlDatasetSortable, 'field' | 'label'>
  )> }
);

export type GqlFragment_DatasetFilterFragment = (
  { __typename?: 'DatasetFilter' }
  & Pick<GqlDatasetFilter, 'field' | 'value' | 'applied'>
);

export type GqlFragment_ReportFragment = (
  { __typename?: 'Report' }
  & Pick<GqlReport, 'id' | 'profileId' | 'name' | 'scheduled' | 'period' | 'weekdays' | 'days' | 'timeHour' | 'timeMinute' | 'timezone' | 'datasetId' | 'fields' | 'recipientContactIds' | 'recipientEmails' | 'nextRun' | 'lastRun' | 'lastError'>
  & { fieldAliases: Array<(
    { __typename?: 'ReportFieldAlias' }
    & Pick<GqlReportFieldAlias, 'field' | 'name'>
  )>, filters: Array<(
    { __typename?: 'DatasetFilter' }
    & GqlFragment_DatasetFilterFragment
  )> }
);

export type GqlReportsQueryVariables = Exact<{ [key: string]: never; }>;


export type GqlReportsQuery = (
  { __typename?: 'Query' }
  & { reports: Array<(
    { __typename?: 'Report' }
    & GqlFragment_ReportFragment
  )> }
);

export type GqlReportQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type GqlReportQuery = (
  { __typename?: 'Query' }
  & { report: (
    { __typename?: 'Report' }
    & GqlFragment_ReportFragment
  ) }
);

export type GqlDeleteReportMutationVariables = Exact<{
  id: Scalars['ID'];
}>;


export type GqlDeleteReportMutation = (
  { __typename?: 'Mutation' }
  & Pick<GqlMutation, 'deleteReport'>
);

export type GqlPutReportMutationVariables = Exact<{
  report: GqlReportInput;
}>;


export type GqlPutReportMutation = (
  { __typename?: 'Mutation' }
  & { putReport: (
    { __typename?: 'Report' }
    & GqlFragment_ReportFragment
  ) }
);

export type GqlSetReportScheduledMutationVariables = Exact<{
  id: Scalars['ID'];
  scheduled: Scalars['Boolean'];
}>;


export type GqlSetReportScheduledMutation = (
  { __typename?: 'Mutation' }
  & { setReportScheduled: (
    { __typename?: 'Report' }
    & GqlFragment_ReportFragment
  ) }
);

export type GqlUpdateRoleMutationVariables = Exact<{
  input: GqlUpdateRoleInput;
}>;


export type GqlUpdateRoleMutation = (
  { __typename?: 'Mutation' }
  & { updateRole: (
    { __typename?: 'UpdateRolePayload' }
    & Pick<GqlUpdateRolePayload, 'success' | 'message'>
  ) }
);

export type GqlDeleteRoleMutationVariables = Exact<{
  input: GqlDeleteRoleInput;
}>;


export type GqlDeleteRoleMutation = (
  { __typename?: 'Mutation' }
  & { deleteRole: (
    { __typename?: 'DeleteRolePayload' }
    & Pick<GqlDeleteRolePayload, 'success' | 'message'>
  ) }
);

export type GqlUserContextQueryVariables = Exact<{ [key: string]: never; }>;


export type GqlUserContextQuery = (
  { __typename?: 'Query' }
  & { userContext?: Maybe<(
    { __typename?: 'UserContext' }
    & { user?: Maybe<(
      { __typename?: 'User' }
      & Pick<GqlUser, 'firstName' | 'lastName' | 'systemPermissionCodes'>
      & { profileContacts: Array<(
        { __typename?: 'Contact' }
        & Pick<GqlContact, 'id'>
        & { profile: (
          { __typename?: 'Profile' }
          & Pick<GqlProfile, 'id' | 'name'>
          & { network: (
            { __typename?: 'Network' }
            & Pick<GqlNetwork, 'id' | 'name'>
            & { controllingClient: (
              { __typename?: 'Profile' }
              & Pick<GqlProfile, 'id' | 'name'>
            ) }
          ) }
        ), role?: Maybe<(
          { __typename?: 'Role' }
          & Pick<GqlRole, 'id' | 'name' | 'permissionCodes'>
        )> }
      )> }
    )>, activeContact?: Maybe<(
      { __typename?: 'Contact' }
      & Pick<GqlContact, 'id'>
      & { profile: (
        { __typename?: 'Profile' }
        & Pick<GqlProfile, 'id' | 'name'>
        & { network: (
          { __typename?: 'Network' }
          & Pick<GqlNetwork, 'id' | 'name'>
        ) }
      ), role?: Maybe<(
        { __typename?: 'Role' }
        & Pick<GqlRole, 'id' | 'name' | 'permissionCodes' | 'dashboardFeatureCodes'>
      )> }
    )> }
  )> }
);

export type GqlAllUsersQueryVariables = Exact<{ [key: string]: never; }>;


export type GqlAllUsersQuery = (
  { __typename?: 'Query' }
  & { users: Array<(
    { __typename?: 'User' }
    & Pick<GqlUser, 'id' | 'fullName' | 'title' | 'isActive'>
    & { defaultContact: (
      { __typename?: 'Contact' }
      & Pick<GqlContact, 'id' | 'isUserEnabled'>
      & { profile: (
        { __typename?: 'Profile' }
        & Pick<GqlProfile, 'id' | 'name'>
        & { network: (
          { __typename?: 'Network' }
          & Pick<GqlNetwork, 'id' | 'name'>
        ) }
      ) }
    ), profileContacts: Array<(
      { __typename?: 'Contact' }
      & Pick<GqlContact, 'id'>
    )> }
  )> }
);

export type GqlUserQueryVariables = Exact<{
  userId: Scalars['ID'];
}>;


export type GqlUserQuery = (
  { __typename?: 'Query' }
  & { user?: Maybe<(
    { __typename?: 'User' }
    & Pick<GqlUser, 'id' | 'fullName' | 'firstName' | 'lastName' | 'title' | 'email' | 'phoneMain' | 'extension' | 'phoneCell' | 'phoneAlternative' | 'isActive' | 'systemPermissionCodes'>
    & { defaultContact: (
      { __typename?: 'Contact' }
      & Pick<GqlContact, 'id' | 'firstName' | 'lastName' | 'title' | 'email' | 'phoneMain' | 'phoneAlternative' | 'extension' | 'phoneCell' | 'isMainContact' | 'isUserEnabled'>
      & { profile: (
        { __typename?: 'Profile' }
        & Pick<GqlProfile, 'id' | 'name'>
        & { network: (
          { __typename?: 'Network' }
          & Pick<GqlNetwork, 'id' | 'name'>
        ) }
      ), location?: Maybe<(
        { __typename?: 'Location' }
        & Pick<GqlLocation, 'id' | 'name'>
      )> }
    ), profileContacts: Array<(
      { __typename?: 'Contact' }
      & Pick<GqlContact, 'id' | 'firstName' | 'lastName' | 'title' | 'email' | 'phoneMain' | 'extension' | 'phoneCell' | 'phoneAlternative' | 'isMainContact' | 'isUserEnabled' | 'notificationFrequency'>
      & { profile: (
        { __typename?: 'Profile' }
        & Pick<GqlProfile, 'id' | 'name'>
        & { roles: Array<(
          { __typename?: 'Role' }
          & Pick<GqlRole, 'id' | 'name'>
        )>, network: (
          { __typename?: 'Network' }
          & Pick<GqlNetwork, 'id' | 'name'>
          & { connectedProfiles: Array<(
            { __typename?: 'Profile' }
            & Pick<GqlProfile, 'id' | 'name' | 'profileCode'>
          )> }
        ) }
      ), location?: Maybe<(
        { __typename?: 'Location' }
        & Pick<GqlLocation, 'id' | 'name'>
      )>, role?: Maybe<(
        { __typename?: 'Role' }
        & Pick<GqlRole, 'id' | 'name'>
      )> }
    )> }
  )> }
);

export type GqlUpdateUserMutationVariables = Exact<{
  input: GqlUpdateUserInput;
}>;


export type GqlUpdateUserMutation = (
  { __typename?: 'Mutation' }
  & { updateUser: (
    { __typename?: 'UpdateUserPayload' }
    & Pick<GqlUpdateUserPayload, 'success' | 'message'>
    & { user?: Maybe<(
      { __typename?: 'User' }
      & Pick<GqlUser, 'id'>
      & { defaultContact: (
        { __typename?: 'Contact' }
        & Pick<GqlContact, 'id' | 'firstName' | 'lastName'>
        & { profile: (
          { __typename?: 'Profile' }
          & Pick<GqlProfile, 'id' | 'name'>
        ) }
      ) }
    )> }
  ) }
);

export type GqlSetActiveUserContactMutationVariables = Exact<{
  input: GqlSetActiveUserContactInput;
}>;


export type GqlSetActiveUserContactMutation = (
  { __typename?: 'Mutation' }
  & { setActiveUserContact: (
    { __typename?: 'SetActiveUserContactPayload' }
    & Pick<GqlSetActiveUserContactPayload, 'success'>
  ) }
);

export const PoImportFileHeadersFragmentDoc = gql`
    fragment PoImportFileHeaders on PoImportHeader {
  id
  name
  headerIndex
  mapping {
    key
    name
    group {
      name
    }
  }
}
    `;
export const PoImportFileStatusFragmentDoc = gql`
    fragment PoImportFileStatus on PoImportFile {
  totalPos
  totalLines
  updatedPos
  updatedLines
  poErrors
  lineErrors
  newPos
  newLines
  fileStatus
  headers {
    ...PoImportFileHeaders
  }
  errors {
    rowIndex
    headerIndex
    error
  }
}
    ${PoImportFileHeadersFragmentDoc}`;
export const PortFragmentDoc = gql`
    fragment Port on Port {
  code
  name
  latitude
  longitude
  stateCode
  stateName
  countryCode
  countryName
}
    `;
export const Fragment_DatasetFragmentDoc = gql`
    fragment fragment_Dataset on Dataset {
  id
  name
  isReport
  isFinancial
  isRootAdminOnly
  filterables {
    field
    label
    type
    options {
      value
      label
    }
    required
  }
  displayables {
    field
    label
  }
  sortables {
    field
    label
  }
  defaultDisplayables
}
    `;
export const Fragment_DatasetFilterFragmentDoc = gql`
    fragment fragment_DatasetFilter on DatasetFilter {
  field
  value
  applied
}
    `;
export const Fragment_ReportFragmentDoc = gql`
    fragment fragment_Report on Report {
  id
  profileId
  name
  scheduled
  period
  weekdays
  days
  timeHour
  timeMinute
  timezone
  datasetId
  fields
  fieldAliases {
    field
    name
  }
  filters {
    ...fragment_DatasetFilter
  }
  recipientContactIds
  recipientEmails
  nextRun
  lastRun
  lastError
}
    ${Fragment_DatasetFilterFragmentDoc}`;
export const BookingListDocument = gql`
    query BookingList {
  shipments(
    shipment_filter: {type: BOOKING, statuses: [BOOKED, PENDING, CONFIRMED, CONSOLIDATED, ASSIGNED]}
  ) {
    id
    referenceNumber
    status
    createDate
    ... on Booking {
      cargoReadyDate
      revisedCargoReadyDate
      isHot
      hotMarkedBy
      hotMarkedTimestamp
    }
    logistics {
      id
      confirmationNumber
      confirmationDate
      moveType
      transportationMode
      carrier {
        id
        name
      }
      carrierConfirmationNumber
      pickupLocation {
        id
        name
      }
      deliveryLocation {
        id
        name
      }
      finalDestination {
        id
        name
      }
      cyCutoffDate
      pod {
        ...Port
      }
      pol {
        ...Port
      }
      polEtd
      polAtd
    }
    containers {
      id
      sequential
      containerNumber
      containerType
      volume
      volumeUnit
      weight
      weightUnit
      isHot
    }
    relatedParties {
      id
      partyType
      party {
        id
        name
        locations {
          id
          name
        }
        relatedProfiles {
          id
          partyType
          profile {
            id
          }
        }
        network {
          id
          controllingClient {
            id
            name
          }
        }
      }
    }
  }
}
    ${PortFragmentDoc}`;

/**
 * __useBookingListQuery__
 *
 * To run a query within a React component, call `useBookingListQuery` and pass it any options that fit your needs.
 * When your component renders, `useBookingListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBookingListQuery({
 *   variables: {
 *   },
 * });
 */
export function useBookingListQuery(baseOptions?: Apollo.QueryHookOptions<GqlBookingListQuery, GqlBookingListQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GqlBookingListQuery, GqlBookingListQueryVariables>(BookingListDocument, options);
      }
export function useBookingListLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GqlBookingListQuery, GqlBookingListQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GqlBookingListQuery, GqlBookingListQueryVariables>(BookingListDocument, options);
        }
export type BookingListQueryHookResult = ReturnType<typeof useBookingListQuery>;
export type BookingListLazyQueryHookResult = ReturnType<typeof useBookingListLazyQuery>;
export const ShippedListDocument = gql`
    query ShippedList {
  shipments(shipment_filter: {type: BOOKING, statuses: [SHIPPED]}) {
    id
    referenceNumber
    status
    createDate
    ... on Booking {
      cargoReadyDate
      revisedCargoReadyDate
      isHot
      hotMarkedBy
      hotMarkedTimestamp
    }
    logistics {
      id
      confirmationNumber
      confirmationDate
      moveType
      transportationMode
      carrier {
        id
        name
      }
      carrierConfirmationNumber
      pickupLocation {
        id
        name
      }
      deliveryLocation {
        id
        name
      }
      cyCutoffDate
      pod {
        ...Port
      }
      pol {
        ...Port
      }
      polEtd
      polAtd
      podEta
      podAta
      deliveryEta
      deliveryAta
    }
    containers {
      id
      sequential
      containerNumber
      containerType
      volume
      volumeUnit
      weight
      weightUnit
      isHot
    }
    relatedParties {
      partyType
      party {
        name
      }
    }
  }
}
    ${PortFragmentDoc}`;

/**
 * __useShippedListQuery__
 *
 * To run a query within a React component, call `useShippedListQuery` and pass it any options that fit your needs.
 * When your component renders, `useShippedListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useShippedListQuery({
 *   variables: {
 *   },
 * });
 */
export function useShippedListQuery(baseOptions?: Apollo.QueryHookOptions<GqlShippedListQuery, GqlShippedListQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GqlShippedListQuery, GqlShippedListQueryVariables>(ShippedListDocument, options);
      }
export function useShippedListLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GqlShippedListQuery, GqlShippedListQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GqlShippedListQuery, GqlShippedListQueryVariables>(ShippedListDocument, options);
        }
export type ShippedListQueryHookResult = ReturnType<typeof useShippedListQuery>;
export type ShippedListLazyQueryHookResult = ReturnType<typeof useShippedListLazyQuery>;
export const BookingDocument = gql`
    query Booking($bookingId: ID!) {
  booking(bookingId: $bookingId) {
    id
    referenceNumber
    status
    createDate
    bookingParty {
      id
      firstName
      lastName
    }
    incoTerm
    cargoReadyDate
    revisedCargoReadyDate
    mbl {
      id
      referenceNumber
      releaseType
      paymentType
      remarks {
        id
        text
        createDate
        createdBy {
          id
          firstName
          lastName
          profile {
            name
          }
        }
      }
    }
    hbl {
      id
      referenceNumber
      releaseType
      paymentType
      remarks {
        id
        text
        createDate
        createdBy {
          id
          firstName
          lastName
          profile {
            name
          }
        }
      }
      documents {
        id
        documentType
        importFileName
        fileName
        fileType
        fileSize
        uploadDate
        description
      }
    }
    packLines {
      id
      requestedDeliveryDate
      shippedQty
      shippedCartons
      volume
      weight
      orderLine {
        id
        lineNumber
        unitPrice
        itemNumber
        itemDescription
        purchaseOrder {
          id
          poNumber
          htsCode
        }
        indcDate
        lastShipDate
        shipToLocation {
          id
          name
        }
      }
    }
    remarks {
      id
      text
      createDate
      createdBy {
        id
        firstName
        lastName
        profile {
          name
        }
      }
    }
    relatedParties {
      id
      partyType
      party {
        id
        name
        locations {
          id
          name
        }
      }
    }
    logistics {
      id
      confirmationNumber
      confirmationDate
      carrierConfirmationNumber
      transportationMode
      moveType
      pickupLocation {
        name
      }
      deliveryLocation {
        name
      }
      carrier {
        id
        name
      }
      contractType
      contractNumber
      cyCutoffDate
      cfsCutoffDate
      cfsReceivedDate
      vgmCutoffDate
      siCutoffDate
      motherVessel {
        id
        name
        voyages {
          id
          name
        }
      }
      motherVoyage {
        id
        name
      }
      feederVessel {
        id
        name
        voyages {
          id
          name
        }
      }
      feederVoyage {
        id
        name
      }
      transitPort {
        ...Port
      }
      transitPortEta
      transitPortAta
      transitPortEtd
      transitPortAtd
      pol {
        ...Port
      }
      polEtd
      polAtd
      pod {
        ...Port
      }
      podEta
      ramp {
        ...Port
      }
      rampEta
      finalDestination {
        id
        name
      }
      deliveryEta
    }
    containers {
      id
      containerNumber
      containerType
      sealNumber
      volume
      weight
      shipFromFactoryDate
      terminalReceivedDate
      vanPositions {
        id
        packLine {
          id
          orderLine {
            lineNumber
            itemNumber
            itemDescription
            purchaseOrder {
              poNumber
            }
          }
        }
        volume
        weight
        piecesQty
        cartonsQty
        vanCode
      }
      isHot
    }
    documents {
      id
      documentType
      importFileName
      fileName
      fileType
      fileSize
      uploadDate
      description
    }
    isHot
    hotMarkedBy
    hotMarkedTimestamp
  }
}
    ${PortFragmentDoc}`;

/**
 * __useBookingQuery__
 *
 * To run a query within a React component, call `useBookingQuery` and pass it any options that fit your needs.
 * When your component renders, `useBookingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBookingQuery({
 *   variables: {
 *      bookingId: // value for 'bookingId'
 *   },
 * });
 */
export function useBookingQuery(baseOptions: Apollo.QueryHookOptions<GqlBookingQuery, GqlBookingQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GqlBookingQuery, GqlBookingQueryVariables>(BookingDocument, options);
      }
export function useBookingLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GqlBookingQuery, GqlBookingQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GqlBookingQuery, GqlBookingQueryVariables>(BookingDocument, options);
        }
export type BookingQueryHookResult = ReturnType<typeof useBookingQuery>;
export type BookingLazyQueryHookResult = ReturnType<typeof useBookingLazyQuery>;
export const NewBookingContainersDocument = gql`
    mutation NewBookingContainers($input: NewBookingContainersInput!) {
  newBookingContainers(input: $input) {
    success
  }
}
    `;
export type GqlNewBookingContainersMutationFn = Apollo.MutationFunction<GqlNewBookingContainersMutation, GqlNewBookingContainersMutationVariables>;

/**
 * __useNewBookingContainersMutation__
 *
 * To run a mutation, you first call `useNewBookingContainersMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useNewBookingContainersMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [newBookingContainersMutation, { data, loading, error }] = useNewBookingContainersMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useNewBookingContainersMutation(baseOptions?: Apollo.MutationHookOptions<GqlNewBookingContainersMutation, GqlNewBookingContainersMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<GqlNewBookingContainersMutation, GqlNewBookingContainersMutationVariables>(NewBookingContainersDocument, options);
      }
export type NewBookingContainersMutationHookResult = ReturnType<typeof useNewBookingContainersMutation>;
export type NewBookingContainersMutationOptions = Apollo.BaseMutationOptions<GqlNewBookingContainersMutation, GqlNewBookingContainersMutationVariables>;
export const AssignBookingContainerDocument = gql`
    mutation AssignBookingContainer($input: AssignBookingContainerInput!) {
  assignBookingContainer(input: $input) {
    success
  }
}
    `;
export type GqlAssignBookingContainerMutationFn = Apollo.MutationFunction<GqlAssignBookingContainerMutation, GqlAssignBookingContainerMutationVariables>;

/**
 * __useAssignBookingContainerMutation__
 *
 * To run a mutation, you first call `useAssignBookingContainerMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAssignBookingContainerMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [assignBookingContainerMutation, { data, loading, error }] = useAssignBookingContainerMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAssignBookingContainerMutation(baseOptions?: Apollo.MutationHookOptions<GqlAssignBookingContainerMutation, GqlAssignBookingContainerMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<GqlAssignBookingContainerMutation, GqlAssignBookingContainerMutationVariables>(AssignBookingContainerDocument, options);
      }
export type AssignBookingContainerMutationHookResult = ReturnType<typeof useAssignBookingContainerMutation>;
export type AssignBookingContainerMutationOptions = Apollo.BaseMutationOptions<GqlAssignBookingContainerMutation, GqlAssignBookingContainerMutationVariables>;
export const DeleteContainerDocument = gql`
    mutation DeleteContainer($input: DeleteContainerInput!) {
  deleteContainer(input: $input) {
    success
  }
}
    `;
export type GqlDeleteContainerMutationFn = Apollo.MutationFunction<GqlDeleteContainerMutation, GqlDeleteContainerMutationVariables>;

/**
 * __useDeleteContainerMutation__
 *
 * To run a mutation, you first call `useDeleteContainerMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteContainerMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteContainerMutation, { data, loading, error }] = useDeleteContainerMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteContainerMutation(baseOptions?: Apollo.MutationHookOptions<GqlDeleteContainerMutation, GqlDeleteContainerMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<GqlDeleteContainerMutation, GqlDeleteContainerMutationVariables>(DeleteContainerDocument, options);
      }
export type DeleteContainerMutationHookResult = ReturnType<typeof useDeleteContainerMutation>;
export type DeleteContainerMutationOptions = Apollo.BaseMutationOptions<GqlDeleteContainerMutation, GqlDeleteContainerMutationVariables>;
export const UpdateBookingDocument = gql`
    mutation UpdateBooking($input: UpdateBookingInput!) {
  updateBooking(input: $input) {
    success
  }
}
    `;
export type GqlUpdateBookingMutationFn = Apollo.MutationFunction<GqlUpdateBookingMutation, GqlUpdateBookingMutationVariables>;

/**
 * __useUpdateBookingMutation__
 *
 * To run a mutation, you first call `useUpdateBookingMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateBookingMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateBookingMutation, { data, loading, error }] = useUpdateBookingMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateBookingMutation(baseOptions?: Apollo.MutationHookOptions<GqlUpdateBookingMutation, GqlUpdateBookingMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<GqlUpdateBookingMutation, GqlUpdateBookingMutationVariables>(UpdateBookingDocument, options);
      }
export type UpdateBookingMutationHookResult = ReturnType<typeof useUpdateBookingMutation>;
export type UpdateBookingMutationOptions = Apollo.BaseMutationOptions<GqlUpdateBookingMutation, GqlUpdateBookingMutationVariables>;
export const UpdateBookingLogisticsDocument = gql`
    mutation UpdateBookingLogistics($input: UpdateBookingLogisticsInput!) {
  updateBookingLogistics(input: $input) {
    success
  }
}
    `;
export type GqlUpdateBookingLogisticsMutationFn = Apollo.MutationFunction<GqlUpdateBookingLogisticsMutation, GqlUpdateBookingLogisticsMutationVariables>;

/**
 * __useUpdateBookingLogisticsMutation__
 *
 * To run a mutation, you first call `useUpdateBookingLogisticsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateBookingLogisticsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateBookingLogisticsMutation, { data, loading, error }] = useUpdateBookingLogisticsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateBookingLogisticsMutation(baseOptions?: Apollo.MutationHookOptions<GqlUpdateBookingLogisticsMutation, GqlUpdateBookingLogisticsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<GqlUpdateBookingLogisticsMutation, GqlUpdateBookingLogisticsMutationVariables>(UpdateBookingLogisticsDocument, options);
      }
export type UpdateBookingLogisticsMutationHookResult = ReturnType<typeof useUpdateBookingLogisticsMutation>;
export type UpdateBookingLogisticsMutationOptions = Apollo.BaseMutationOptions<GqlUpdateBookingLogisticsMutation, GqlUpdateBookingLogisticsMutationVariables>;
export const SaveBookingDocument = gql`
    mutation SaveBooking($input: NewBookingInput!) {
  newBooking(input: $input) {
    success
    message
    booking {
      id
      referenceNumber
    }
  }
}
    `;
export type GqlSaveBookingMutationFn = Apollo.MutationFunction<GqlSaveBookingMutation, GqlSaveBookingMutationVariables>;

/**
 * __useSaveBookingMutation__
 *
 * To run a mutation, you first call `useSaveBookingMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSaveBookingMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [saveBookingMutation, { data, loading, error }] = useSaveBookingMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSaveBookingMutation(baseOptions?: Apollo.MutationHookOptions<GqlSaveBookingMutation, GqlSaveBookingMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<GqlSaveBookingMutation, GqlSaveBookingMutationVariables>(SaveBookingDocument, options);
      }
export type SaveBookingMutationHookResult = ReturnType<typeof useSaveBookingMutation>;
export type SaveBookingMutationOptions = Apollo.BaseMutationOptions<GqlSaveBookingMutation, GqlSaveBookingMutationVariables>;
export const AssignBookingRelatedPartyDocument = gql`
    mutation AssignBookingRelatedParty($input: AssignBookingRelatedPartyInput!) {
  assignBookingRelatedParty(input: $input) {
    success
    message
  }
}
    `;
export type GqlAssignBookingRelatedPartyMutationFn = Apollo.MutationFunction<GqlAssignBookingRelatedPartyMutation, GqlAssignBookingRelatedPartyMutationVariables>;

/**
 * __useAssignBookingRelatedPartyMutation__
 *
 * To run a mutation, you first call `useAssignBookingRelatedPartyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAssignBookingRelatedPartyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [assignBookingRelatedPartyMutation, { data, loading, error }] = useAssignBookingRelatedPartyMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAssignBookingRelatedPartyMutation(baseOptions?: Apollo.MutationHookOptions<GqlAssignBookingRelatedPartyMutation, GqlAssignBookingRelatedPartyMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<GqlAssignBookingRelatedPartyMutation, GqlAssignBookingRelatedPartyMutationVariables>(AssignBookingRelatedPartyDocument, options);
      }
export type AssignBookingRelatedPartyMutationHookResult = ReturnType<typeof useAssignBookingRelatedPartyMutation>;
export type AssignBookingRelatedPartyMutationOptions = Apollo.BaseMutationOptions<GqlAssignBookingRelatedPartyMutation, GqlAssignBookingRelatedPartyMutationVariables>;
export const RemoveBookingRelatedPartyDocument = gql`
    mutation RemoveBookingRelatedParty($input: RemoveBookingRelatedPartyInput!) {
  removeBookingRelatedParty(input: $input) {
    success
    message
  }
}
    `;
export type GqlRemoveBookingRelatedPartyMutationFn = Apollo.MutationFunction<GqlRemoveBookingRelatedPartyMutation, GqlRemoveBookingRelatedPartyMutationVariables>;

/**
 * __useRemoveBookingRelatedPartyMutation__
 *
 * To run a mutation, you first call `useRemoveBookingRelatedPartyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveBookingRelatedPartyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeBookingRelatedPartyMutation, { data, loading, error }] = useRemoveBookingRelatedPartyMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRemoveBookingRelatedPartyMutation(baseOptions?: Apollo.MutationHookOptions<GqlRemoveBookingRelatedPartyMutation, GqlRemoveBookingRelatedPartyMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<GqlRemoveBookingRelatedPartyMutation, GqlRemoveBookingRelatedPartyMutationVariables>(RemoveBookingRelatedPartyDocument, options);
      }
export type RemoveBookingRelatedPartyMutationHookResult = ReturnType<typeof useRemoveBookingRelatedPartyMutation>;
export type RemoveBookingRelatedPartyMutationOptions = Apollo.BaseMutationOptions<GqlRemoveBookingRelatedPartyMutation, GqlRemoveBookingRelatedPartyMutationVariables>;
export const UpdateBookingRelatedPartyDocument = gql`
    mutation UpdateBookingRelatedParty($input: UpdateBookingRelatedPartyInput!) {
  updateBookingRelatedParty(input: $input) {
    success
    message
  }
}
    `;
export type GqlUpdateBookingRelatedPartyMutationFn = Apollo.MutationFunction<GqlUpdateBookingRelatedPartyMutation, GqlUpdateBookingRelatedPartyMutationVariables>;

/**
 * __useUpdateBookingRelatedPartyMutation__
 *
 * To run a mutation, you first call `useUpdateBookingRelatedPartyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateBookingRelatedPartyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateBookingRelatedPartyMutation, { data, loading, error }] = useUpdateBookingRelatedPartyMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateBookingRelatedPartyMutation(baseOptions?: Apollo.MutationHookOptions<GqlUpdateBookingRelatedPartyMutation, GqlUpdateBookingRelatedPartyMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<GqlUpdateBookingRelatedPartyMutation, GqlUpdateBookingRelatedPartyMutationVariables>(UpdateBookingRelatedPartyDocument, options);
      }
export type UpdateBookingRelatedPartyMutationHookResult = ReturnType<typeof useUpdateBookingRelatedPartyMutation>;
export type UpdateBookingRelatedPartyMutationOptions = Apollo.BaseMutationOptions<GqlUpdateBookingRelatedPartyMutation, GqlUpdateBookingRelatedPartyMutationVariables>;
export const NewBookingRemarkDocument = gql`
    mutation NewBookingRemark($input: NewBookingRemarkInput!) {
  newBookingRemark(input: $input) {
    success
    message
    remark {
      id
      text
      createDate
      createdBy {
        firstName
        lastName
        title
        profile {
          name
        }
      }
    }
  }
}
    `;
export type GqlNewBookingRemarkMutationFn = Apollo.MutationFunction<GqlNewBookingRemarkMutation, GqlNewBookingRemarkMutationVariables>;

/**
 * __useNewBookingRemarkMutation__
 *
 * To run a mutation, you first call `useNewBookingRemarkMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useNewBookingRemarkMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [newBookingRemarkMutation, { data, loading, error }] = useNewBookingRemarkMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useNewBookingRemarkMutation(baseOptions?: Apollo.MutationHookOptions<GqlNewBookingRemarkMutation, GqlNewBookingRemarkMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<GqlNewBookingRemarkMutation, GqlNewBookingRemarkMutationVariables>(NewBookingRemarkDocument, options);
      }
export type NewBookingRemarkMutationHookResult = ReturnType<typeof useNewBookingRemarkMutation>;
export type NewBookingRemarkMutationOptions = Apollo.BaseMutationOptions<GqlNewBookingRemarkMutation, GqlNewBookingRemarkMutationVariables>;
export const MarkBookingAsHotDocument = gql`
    mutation MarkBookingAsHot($input: MarkBookingAsHotInput!) {
  markBookingAsHot(input: $input) {
    success
    message
    booking {
      isHot
      hotMarkedBy
      hotMarkedTimestamp
    }
  }
}
    `;
export type GqlMarkBookingAsHotMutationFn = Apollo.MutationFunction<GqlMarkBookingAsHotMutation, GqlMarkBookingAsHotMutationVariables>;

/**
 * __useMarkBookingAsHotMutation__
 *
 * To run a mutation, you first call `useMarkBookingAsHotMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMarkBookingAsHotMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [markBookingAsHotMutation, { data, loading, error }] = useMarkBookingAsHotMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useMarkBookingAsHotMutation(baseOptions?: Apollo.MutationHookOptions<GqlMarkBookingAsHotMutation, GqlMarkBookingAsHotMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<GqlMarkBookingAsHotMutation, GqlMarkBookingAsHotMutationVariables>(MarkBookingAsHotDocument, options);
      }
export type MarkBookingAsHotMutationHookResult = ReturnType<typeof useMarkBookingAsHotMutation>;
export type MarkBookingAsHotMutationOptions = Apollo.BaseMutationOptions<GqlMarkBookingAsHotMutation, GqlMarkBookingAsHotMutationVariables>;
export const ConsolidationListDocument = gql`
    query ConsolidationList {
  shipments(
    shipment_filter: {type: CONSOLIDATION, statuses: [BOOKED, PENDING, CONFIRMED, CONSOLIDATED, ASSIGNED]}
  ) {
    id
    referenceNumber
    status
    createDate
    logistics {
      id
      confirmationNumber
      confirmationDate
      moveType
      transportationMode
      carrier {
        id
        name
      }
      carrierConfirmationNumber
      pickupLocation {
        id
        name
      }
      deliveryLocation {
        id
        name
      }
      cyCutoffDate
      pod {
        ...Port
      }
      pol {
        ...Port
      }
      polEtd
      polAtd
    }
    containers {
      id
      sequential
      containerNumber
      containerType
      volume
      volumeUnit
      weight
      weightUnit
      isHot
    }
    ... on Consolidation {
      bookings {
        packLines {
          linePrice
        }
        containers {
          id
          sequential
          containerNumber
          containerType
          volume
          volumeUnit
          weight
          weightUnit
          isHot
          vanPositions {
            commercialInvoiceTotal
          }
        }
      }
    }
    relatedParties {
      id
      partyType
      party {
        id
        name
        locations {
          id
          name
        }
        relatedProfiles {
          id
          partyType
          profile {
            id
          }
        }
      }
    }
  }
}
    ${PortFragmentDoc}`;

/**
 * __useConsolidationListQuery__
 *
 * To run a query within a React component, call `useConsolidationListQuery` and pass it any options that fit your needs.
 * When your component renders, `useConsolidationListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useConsolidationListQuery({
 *   variables: {
 *   },
 * });
 */
export function useConsolidationListQuery(baseOptions?: Apollo.QueryHookOptions<GqlConsolidationListQuery, GqlConsolidationListQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GqlConsolidationListQuery, GqlConsolidationListQueryVariables>(ConsolidationListDocument, options);
      }
export function useConsolidationListLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GqlConsolidationListQuery, GqlConsolidationListQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GqlConsolidationListQuery, GqlConsolidationListQueryVariables>(ConsolidationListDocument, options);
        }
export type ConsolidationListQueryHookResult = ReturnType<typeof useConsolidationListQuery>;
export type ConsolidationListLazyQueryHookResult = ReturnType<typeof useConsolidationListLazyQuery>;
export const ShippedConsolidationListDocument = gql`
    query ShippedConsolidationList {
  shipments(shipment_filter: {type: CONSOLIDATION, statuses: [SHIPPED]}) {
    id
    referenceNumber
    status
    createDate
    ... on Booking {
      cargoReadyDate
      revisedCargoReadyDate
    }
    logistics {
      id
      confirmationNumber
      confirmationDate
      moveType
      transportationMode
      carrier {
        id
        name
      }
      carrierConfirmationNumber
      pickupLocation {
        id
        name
      }
      deliveryLocation {
        id
        name
      }
      cyCutoffDate
      pod {
        ...Port
      }
      pol {
        ...Port
      }
      polEtd
      polAtd
    }
    containers {
      id
      sequential
      containerNumber
      containerType
      volume
      volumeUnit
      weight
      weightUnit
      isHot
    }
    ... on Consolidation {
      bookings {
        packLines {
          linePrice
        }
        containers {
          id
          sequential
          containerNumber
          containerType
          volume
          volumeUnit
          weight
          weightUnit
          isHot
          vanPositions {
            commercialInvoiceTotal
          }
        }
      }
    }
    relatedParties {
      id
      partyType
      party {
        id
        name
        locations {
          id
          name
        }
        relatedProfiles {
          id
          partyType
          profile {
            id
          }
        }
      }
    }
  }
}
    ${PortFragmentDoc}`;

/**
 * __useShippedConsolidationListQuery__
 *
 * To run a query within a React component, call `useShippedConsolidationListQuery` and pass it any options that fit your needs.
 * When your component renders, `useShippedConsolidationListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useShippedConsolidationListQuery({
 *   variables: {
 *   },
 * });
 */
export function useShippedConsolidationListQuery(baseOptions?: Apollo.QueryHookOptions<GqlShippedConsolidationListQuery, GqlShippedConsolidationListQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GqlShippedConsolidationListQuery, GqlShippedConsolidationListQueryVariables>(ShippedConsolidationListDocument, options);
      }
export function useShippedConsolidationListLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GqlShippedConsolidationListQuery, GqlShippedConsolidationListQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GqlShippedConsolidationListQuery, GqlShippedConsolidationListQueryVariables>(ShippedConsolidationListDocument, options);
        }
export type ShippedConsolidationListQueryHookResult = ReturnType<typeof useShippedConsolidationListQuery>;
export type ShippedConsolidationListLazyQueryHookResult = ReturnType<typeof useShippedConsolidationListLazyQuery>;
export const AvailableConsolidationBookingListDocument = gql`
    query AvailableConsolidationBookingList {
  availableConsolidationBookings {
    id
    referenceNumber
    status
    createDate
    cargoReadyDate
    logistics {
      id
      moveType
      finalDestination {
        name
      }
      pod {
        ...Port
      }
      pol {
        ...Port
      }
      polEtd
      polAtd
    }
    packLines {
      weight
      volume
    }
    containers {
      id
      containerType
      volume
      weight
    }
    relatedParties {
      id
      partyType
      party {
        id
        name
        locations {
          id
          name
        }
        relatedProfiles {
          id
          partyType
          profile {
            id
          }
        }
      }
    }
  }
}
    ${PortFragmentDoc}`;

/**
 * __useAvailableConsolidationBookingListQuery__
 *
 * To run a query within a React component, call `useAvailableConsolidationBookingListQuery` and pass it any options that fit your needs.
 * When your component renders, `useAvailableConsolidationBookingListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAvailableConsolidationBookingListQuery({
 *   variables: {
 *   },
 * });
 */
export function useAvailableConsolidationBookingListQuery(baseOptions?: Apollo.QueryHookOptions<GqlAvailableConsolidationBookingListQuery, GqlAvailableConsolidationBookingListQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GqlAvailableConsolidationBookingListQuery, GqlAvailableConsolidationBookingListQueryVariables>(AvailableConsolidationBookingListDocument, options);
      }
export function useAvailableConsolidationBookingListLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GqlAvailableConsolidationBookingListQuery, GqlAvailableConsolidationBookingListQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GqlAvailableConsolidationBookingListQuery, GqlAvailableConsolidationBookingListQueryVariables>(AvailableConsolidationBookingListDocument, options);
        }
export type AvailableConsolidationBookingListQueryHookResult = ReturnType<typeof useAvailableConsolidationBookingListQuery>;
export type AvailableConsolidationBookingListLazyQueryHookResult = ReturnType<typeof useAvailableConsolidationBookingListLazyQuery>;
export const ConsolidationDocument = gql`
    query Consolidation($consolId: ID!) {
  consolidation(consolId: $consolId) {
    id
    referenceNumber
    status
    createDate
    bookingParty {
      id
      firstName
      lastName
    }
    mbl {
      id
      referenceNumber
      releaseType
      paymentType
    }
    bookings {
      id
      referenceNumber
      status
      createDate
      cargoReadyDate
      packLines {
        id
        requestedDeliveryDate
        shippedQty
        shippedCartons
        volume
        weight
        orderLine {
          id
          lineNumber
          unitPrice
          itemNumber
          itemDescription
          purchaseOrder {
            id
            poNumber
            htsCode
          }
          indcDate
          lastShipDate
          shipToLocation {
            id
            name
          }
        }
        booking {
          referenceNumber
        }
      }
      logistics {
        id
        moveType
        finalDestination {
          name
        }
        pol {
          code
        }
        polEtd
        pod {
          code
        }
      }
      remarks {
        id
        text
        createDate
        createdBy {
          id
          firstName
          lastName
          profile {
            name
          }
        }
      }
      containers {
        id
        containerType
        weight
        volume
      }
      documents {
        id
        documentType
        importFileName
        fileName
        fileType
        fileSize
        uploadDate
        description
      }
      relatedParties {
        id
        partyType
        party {
          id
          name
        }
      }
      hbl {
        id
        referenceNumber
        releaseType
        paymentType
        documents {
          id
          documentType
          importFileName
          fileName
          fileType
          fileSize
          uploadDate
          description
        }
      }
      mbl {
        id
        referenceNumber
        documents {
          id
          documentType
          importFileName
          fileName
          fileType
          fileSize
          uploadDate
          description
        }
      }
    }
    remarks {
      id
      text
      createDate
      createdBy {
        id
        firstName
        lastName
        profile {
          name
        }
      }
    }
    relatedParties {
      id
      partyType
      party {
        id
        name
        locations {
          id
          name
        }
      }
    }
    logistics {
      id
      confirmationNumber
      confirmationDate
      carrierConfirmationNumber
      moveType
      transportationMode
      pickupLocation {
        name
      }
      deliveryLocation {
        name
      }
      carrier {
        name
      }
      contractType
      contractNumber
      cyCutoffDate
      cfsCutoffDate
      cfsOpenDate
      vgmCutoffDate
      siCutoffDate
      motherVessel {
        name
        voyages {
          id
          name
        }
      }
      motherVoyage {
        name
      }
      feederVessel {
        id
        name
        voyages {
          id
          name
        }
      }
      feederVoyage {
        id
        name
      }
      transitPort {
        code
        name
        stateCode
        countryCode
        countryName
        latitude
        longitude
        stateName
      }
      transitPortEta
      transitPortAta
      transitPortEtd
      transitPortAtd
      pol {
        code
        name
        stateCode
        countryCode
        countryName
        latitude
        longitude
        stateName
      }
      polEtd
      polAtd
      pod {
        code
        name
        stateCode
        countryCode
        countryName
        latitude
        longitude
        stateName
      }
      podEta
      ramp {
        code
        name
        stateCode
        countryCode
        countryName
        latitude
        longitude
        stateName
      }
      rampEta
      finalDestination {
        id
        name
      }
      deliveryEta
    }
    containers {
      id
      containerNumber
      containerType
      sealNumber
      volume
      weight
      shipFromFactoryDate
      terminalReceivedDate
      vanPositions {
        id
        packLine {
          id
          orderLine {
            lineNumber
            itemNumber
            itemDescription
            purchaseOrder {
              poNumber
            }
          }
        }
        volume
        weight
        piecesQty
        cartonsQty
        vanCode
      }
      isHot
    }
    documents {
      id
      documentType
      importFileName
      fileName
      fileType
      fileSize
      uploadDate
      description
    }
  }
}
    `;

/**
 * __useConsolidationQuery__
 *
 * To run a query within a React component, call `useConsolidationQuery` and pass it any options that fit your needs.
 * When your component renders, `useConsolidationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useConsolidationQuery({
 *   variables: {
 *      consolId: // value for 'consolId'
 *   },
 * });
 */
export function useConsolidationQuery(baseOptions: Apollo.QueryHookOptions<GqlConsolidationQuery, GqlConsolidationQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GqlConsolidationQuery, GqlConsolidationQueryVariables>(ConsolidationDocument, options);
      }
export function useConsolidationLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GqlConsolidationQuery, GqlConsolidationQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GqlConsolidationQuery, GqlConsolidationQueryVariables>(ConsolidationDocument, options);
        }
export type ConsolidationQueryHookResult = ReturnType<typeof useConsolidationQuery>;
export type ConsolidationLazyQueryHookResult = ReturnType<typeof useConsolidationLazyQuery>;
export const NewConsolidationContainersDocument = gql`
    mutation NewConsolidationContainers($input: NewConsolidationContainersInput!) {
  newConsolidationContainers(input: $input) {
    success
  }
}
    `;
export type GqlNewConsolidationContainersMutationFn = Apollo.MutationFunction<GqlNewConsolidationContainersMutation, GqlNewConsolidationContainersMutationVariables>;

/**
 * __useNewConsolidationContainersMutation__
 *
 * To run a mutation, you first call `useNewConsolidationContainersMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useNewConsolidationContainersMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [newConsolidationContainersMutation, { data, loading, error }] = useNewConsolidationContainersMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useNewConsolidationContainersMutation(baseOptions?: Apollo.MutationHookOptions<GqlNewConsolidationContainersMutation, GqlNewConsolidationContainersMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<GqlNewConsolidationContainersMutation, GqlNewConsolidationContainersMutationVariables>(NewConsolidationContainersDocument, options);
      }
export type NewConsolidationContainersMutationHookResult = ReturnType<typeof useNewConsolidationContainersMutation>;
export type NewConsolidationContainersMutationOptions = Apollo.BaseMutationOptions<GqlNewConsolidationContainersMutation, GqlNewConsolidationContainersMutationVariables>;
export const AssignConsolidationContainerDocument = gql`
    mutation AssignConsolidationContainer($input: AssignConsolidationContainerInput!) {
  assignConsolidationContainer(input: $input) {
    success
  }
}
    `;
export type GqlAssignConsolidationContainerMutationFn = Apollo.MutationFunction<GqlAssignConsolidationContainerMutation, GqlAssignConsolidationContainerMutationVariables>;

/**
 * __useAssignConsolidationContainerMutation__
 *
 * To run a mutation, you first call `useAssignConsolidationContainerMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAssignConsolidationContainerMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [assignConsolidationContainerMutation, { data, loading, error }] = useAssignConsolidationContainerMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAssignConsolidationContainerMutation(baseOptions?: Apollo.MutationHookOptions<GqlAssignConsolidationContainerMutation, GqlAssignConsolidationContainerMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<GqlAssignConsolidationContainerMutation, GqlAssignConsolidationContainerMutationVariables>(AssignConsolidationContainerDocument, options);
      }
export type AssignConsolidationContainerMutationHookResult = ReturnType<typeof useAssignConsolidationContainerMutation>;
export type AssignConsolidationContainerMutationOptions = Apollo.BaseMutationOptions<GqlAssignConsolidationContainerMutation, GqlAssignConsolidationContainerMutationVariables>;
export const UpdateConsolidationDocument = gql`
    mutation UpdateConsolidation($input: UpdateConsolidationInput!) {
  updateConsolidation(input: $input) {
    success
  }
}
    `;
export type GqlUpdateConsolidationMutationFn = Apollo.MutationFunction<GqlUpdateConsolidationMutation, GqlUpdateConsolidationMutationVariables>;

/**
 * __useUpdateConsolidationMutation__
 *
 * To run a mutation, you first call `useUpdateConsolidationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateConsolidationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateConsolidationMutation, { data, loading, error }] = useUpdateConsolidationMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateConsolidationMutation(baseOptions?: Apollo.MutationHookOptions<GqlUpdateConsolidationMutation, GqlUpdateConsolidationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<GqlUpdateConsolidationMutation, GqlUpdateConsolidationMutationVariables>(UpdateConsolidationDocument, options);
      }
export type UpdateConsolidationMutationHookResult = ReturnType<typeof useUpdateConsolidationMutation>;
export type UpdateConsolidationMutationOptions = Apollo.BaseMutationOptions<GqlUpdateConsolidationMutation, GqlUpdateConsolidationMutationVariables>;
export const UpdateConsolidationLogisticsDocument = gql`
    mutation UpdateConsolidationLogistics($input: UpdateConsolidationLogisticsInput!) {
  updateConsolidationLogistics(input: $input) {
    success
    message
  }
}
    `;
export type GqlUpdateConsolidationLogisticsMutationFn = Apollo.MutationFunction<GqlUpdateConsolidationLogisticsMutation, GqlUpdateConsolidationLogisticsMutationVariables>;

/**
 * __useUpdateConsolidationLogisticsMutation__
 *
 * To run a mutation, you first call `useUpdateConsolidationLogisticsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateConsolidationLogisticsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateConsolidationLogisticsMutation, { data, loading, error }] = useUpdateConsolidationLogisticsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateConsolidationLogisticsMutation(baseOptions?: Apollo.MutationHookOptions<GqlUpdateConsolidationLogisticsMutation, GqlUpdateConsolidationLogisticsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<GqlUpdateConsolidationLogisticsMutation, GqlUpdateConsolidationLogisticsMutationVariables>(UpdateConsolidationLogisticsDocument, options);
      }
export type UpdateConsolidationLogisticsMutationHookResult = ReturnType<typeof useUpdateConsolidationLogisticsMutation>;
export type UpdateConsolidationLogisticsMutationOptions = Apollo.BaseMutationOptions<GqlUpdateConsolidationLogisticsMutation, GqlUpdateConsolidationLogisticsMutationVariables>;
export const SaveConsolidationDocument = gql`
    mutation SaveConsolidation($input: NewConsolidationInput!) {
  newConsolidation(input: $input) {
    success
    message
    consolidation {
      id
      referenceNumber
    }
  }
}
    `;
export type GqlSaveConsolidationMutationFn = Apollo.MutationFunction<GqlSaveConsolidationMutation, GqlSaveConsolidationMutationVariables>;

/**
 * __useSaveConsolidationMutation__
 *
 * To run a mutation, you first call `useSaveConsolidationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSaveConsolidationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [saveConsolidationMutation, { data, loading, error }] = useSaveConsolidationMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSaveConsolidationMutation(baseOptions?: Apollo.MutationHookOptions<GqlSaveConsolidationMutation, GqlSaveConsolidationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<GqlSaveConsolidationMutation, GqlSaveConsolidationMutationVariables>(SaveConsolidationDocument, options);
      }
export type SaveConsolidationMutationHookResult = ReturnType<typeof useSaveConsolidationMutation>;
export type SaveConsolidationMutationOptions = Apollo.BaseMutationOptions<GqlSaveConsolidationMutation, GqlSaveConsolidationMutationVariables>;
export const AssignConsolidationRelatedPartyDocument = gql`
    mutation AssignConsolidationRelatedParty($input: AssignConsolidationRelatedPartyInput!) {
  assignConsolidationRelatedParty(input: $input) {
    success
    message
  }
}
    `;
export type GqlAssignConsolidationRelatedPartyMutationFn = Apollo.MutationFunction<GqlAssignConsolidationRelatedPartyMutation, GqlAssignConsolidationRelatedPartyMutationVariables>;

/**
 * __useAssignConsolidationRelatedPartyMutation__
 *
 * To run a mutation, you first call `useAssignConsolidationRelatedPartyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAssignConsolidationRelatedPartyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [assignConsolidationRelatedPartyMutation, { data, loading, error }] = useAssignConsolidationRelatedPartyMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAssignConsolidationRelatedPartyMutation(baseOptions?: Apollo.MutationHookOptions<GqlAssignConsolidationRelatedPartyMutation, GqlAssignConsolidationRelatedPartyMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<GqlAssignConsolidationRelatedPartyMutation, GqlAssignConsolidationRelatedPartyMutationVariables>(AssignConsolidationRelatedPartyDocument, options);
      }
export type AssignConsolidationRelatedPartyMutationHookResult = ReturnType<typeof useAssignConsolidationRelatedPartyMutation>;
export type AssignConsolidationRelatedPartyMutationOptions = Apollo.BaseMutationOptions<GqlAssignConsolidationRelatedPartyMutation, GqlAssignConsolidationRelatedPartyMutationVariables>;
export const RemoveConsolidationRelatedPartyDocument = gql`
    mutation RemoveConsolidationRelatedParty($input: RemoveConsolidationRelatedPartyInput!) {
  removeConsolidationRelatedParty(input: $input) {
    success
    message
  }
}
    `;
export type GqlRemoveConsolidationRelatedPartyMutationFn = Apollo.MutationFunction<GqlRemoveConsolidationRelatedPartyMutation, GqlRemoveConsolidationRelatedPartyMutationVariables>;

/**
 * __useRemoveConsolidationRelatedPartyMutation__
 *
 * To run a mutation, you first call `useRemoveConsolidationRelatedPartyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveConsolidationRelatedPartyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeConsolidationRelatedPartyMutation, { data, loading, error }] = useRemoveConsolidationRelatedPartyMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRemoveConsolidationRelatedPartyMutation(baseOptions?: Apollo.MutationHookOptions<GqlRemoveConsolidationRelatedPartyMutation, GqlRemoveConsolidationRelatedPartyMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<GqlRemoveConsolidationRelatedPartyMutation, GqlRemoveConsolidationRelatedPartyMutationVariables>(RemoveConsolidationRelatedPartyDocument, options);
      }
export type RemoveConsolidationRelatedPartyMutationHookResult = ReturnType<typeof useRemoveConsolidationRelatedPartyMutation>;
export type RemoveConsolidationRelatedPartyMutationOptions = Apollo.BaseMutationOptions<GqlRemoveConsolidationRelatedPartyMutation, GqlRemoveConsolidationRelatedPartyMutationVariables>;
export const UpdateConsolidationRelatedPartyDocument = gql`
    mutation UpdateConsolidationRelatedParty($input: UpdateConsolidationRelatedPartyInput!) {
  updateConsolidationRelatedParty(input: $input) {
    success
    message
  }
}
    `;
export type GqlUpdateConsolidationRelatedPartyMutationFn = Apollo.MutationFunction<GqlUpdateConsolidationRelatedPartyMutation, GqlUpdateConsolidationRelatedPartyMutationVariables>;

/**
 * __useUpdateConsolidationRelatedPartyMutation__
 *
 * To run a mutation, you first call `useUpdateConsolidationRelatedPartyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateConsolidationRelatedPartyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateConsolidationRelatedPartyMutation, { data, loading, error }] = useUpdateConsolidationRelatedPartyMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateConsolidationRelatedPartyMutation(baseOptions?: Apollo.MutationHookOptions<GqlUpdateConsolidationRelatedPartyMutation, GqlUpdateConsolidationRelatedPartyMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<GqlUpdateConsolidationRelatedPartyMutation, GqlUpdateConsolidationRelatedPartyMutationVariables>(UpdateConsolidationRelatedPartyDocument, options);
      }
export type UpdateConsolidationRelatedPartyMutationHookResult = ReturnType<typeof useUpdateConsolidationRelatedPartyMutation>;
export type UpdateConsolidationRelatedPartyMutationOptions = Apollo.BaseMutationOptions<GqlUpdateConsolidationRelatedPartyMutation, GqlUpdateConsolidationRelatedPartyMutationVariables>;
export const NewConsolidationRemarkDocument = gql`
    mutation NewConsolidationRemark($input: NewConsolidationRemarkInput!) {
  newConsolidationRemark(input: $input) {
    success
    message
    remark {
      id
      text
      createDate
      createdBy {
        firstName
        lastName
        title
        profile {
          name
        }
      }
    }
  }
}
    `;
export type GqlNewConsolidationRemarkMutationFn = Apollo.MutationFunction<GqlNewConsolidationRemarkMutation, GqlNewConsolidationRemarkMutationVariables>;

/**
 * __useNewConsolidationRemarkMutation__
 *
 * To run a mutation, you first call `useNewConsolidationRemarkMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useNewConsolidationRemarkMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [newConsolidationRemarkMutation, { data, loading, error }] = useNewConsolidationRemarkMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useNewConsolidationRemarkMutation(baseOptions?: Apollo.MutationHookOptions<GqlNewConsolidationRemarkMutation, GqlNewConsolidationRemarkMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<GqlNewConsolidationRemarkMutation, GqlNewConsolidationRemarkMutationVariables>(NewConsolidationRemarkDocument, options);
      }
export type NewConsolidationRemarkMutationHookResult = ReturnType<typeof useNewConsolidationRemarkMutation>;
export type NewConsolidationRemarkMutationOptions = Apollo.BaseMutationOptions<GqlNewConsolidationRemarkMutation, GqlNewConsolidationRemarkMutationVariables>;
export const UpdateContactDocument = gql`
    mutation UpdateContact($input: UpdateContactInput!) {
  updateContact(input: $input) {
    success
  }
}
    `;
export type GqlUpdateContactMutationFn = Apollo.MutationFunction<GqlUpdateContactMutation, GqlUpdateContactMutationVariables>;

/**
 * __useUpdateContactMutation__
 *
 * To run a mutation, you first call `useUpdateContactMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateContactMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateContactMutation, { data, loading, error }] = useUpdateContactMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateContactMutation(baseOptions?: Apollo.MutationHookOptions<GqlUpdateContactMutation, GqlUpdateContactMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<GqlUpdateContactMutation, GqlUpdateContactMutationVariables>(UpdateContactDocument, options);
      }
export type UpdateContactMutationHookResult = ReturnType<typeof useUpdateContactMutation>;
export type UpdateContactMutationOptions = Apollo.BaseMutationOptions<GqlUpdateContactMutation, GqlUpdateContactMutationVariables>;
export const MyProfileContactsDocument = gql`
    query MyProfileContacts {
  userContext {
    activeContact {
      profile {
        contacts {
          id
          firstName
          lastName
          email
        }
      }
    }
  }
}
    `;

/**
 * __useMyProfileContactsQuery__
 *
 * To run a query within a React component, call `useMyProfileContactsQuery` and pass it any options that fit your needs.
 * When your component renders, `useMyProfileContactsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMyProfileContactsQuery({
 *   variables: {
 *   },
 * });
 */
export function useMyProfileContactsQuery(baseOptions?: Apollo.QueryHookOptions<GqlMyProfileContactsQuery, GqlMyProfileContactsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GqlMyProfileContactsQuery, GqlMyProfileContactsQueryVariables>(MyProfileContactsDocument, options);
      }
export function useMyProfileContactsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GqlMyProfileContactsQuery, GqlMyProfileContactsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GqlMyProfileContactsQuery, GqlMyProfileContactsQueryVariables>(MyProfileContactsDocument, options);
        }
export type MyProfileContactsQueryHookResult = ReturnType<typeof useMyProfileContactsQuery>;
export type MyProfileContactsLazyQueryHookResult = ReturnType<typeof useMyProfileContactsLazyQuery>;
export const ContainerDocument = gql`
    query Container($containerId: ID!) {
  container(containerId: $containerId) {
    id
    sequential
    containerNumber
    containerType
    sealNumber
    volume
    volumeUnit
    shipFromFactoryDate
    terminalReceivedDate
    portOutGateDate
    ramp {
      ...Port
    }
    rampEta
    rampAta
    deliveryEta
    deliveryAta
    emptyReturnedDate
    demurrageLastFreeDayDate
    detentionLastFreeDayDate
    rampOutGateDate
    emptyNotifyDate
    shipment {
      id
      ... on Booking {
        incoTerm
      }
      relatedParties {
        party {
          id
          name
        }
        partyType
      }
      logistics {
        id
        moveType
        pickupLocation {
          id
          name
        }
        pol {
          ...Port
        }
        pod {
          ...Port
        }
        carrier {
          id
          name
        }
        motherVessel {
          id
          name
        }
        motherVoyage {
          id
          name
        }
        polEtd
        polAtd
        podEta
        podAta
        finalDestination {
          id
          name
        }
      }
      ... on Booking {
        mbl {
          id
          referenceNumber
        }
      }
      ... on Consolidation {
        mbl {
          id
          referenceNumber
        }
      }
    }
    vanPositions {
      id
      piecesQty
      cartonsQty
      volume
      weight
      commercialInvoiceTotal
      packLine {
        shippedQty
        shippedCartons
        booking {
          relatedParties {
            party {
              id
              name
            }
            partyType
          }
          hbl {
            id
            referenceNumber
          }
        }
        orderLine {
          unitPrice
          itemNumber
          itemDescription
          purchaseOrder {
            id
            poNumber
          }
          openOrder {
            orderQty
            isHot
          }
        }
      }
    }
    isHot
    hotMarkedBy
    hotMarkedTimestamp
  }
}
    ${PortFragmentDoc}`;

/**
 * __useContainerQuery__
 *
 * To run a query within a React component, call `useContainerQuery` and pass it any options that fit your needs.
 * When your component renders, `useContainerQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useContainerQuery({
 *   variables: {
 *      containerId: // value for 'containerId'
 *   },
 * });
 */
export function useContainerQuery(baseOptions: Apollo.QueryHookOptions<GqlContainerQuery, GqlContainerQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GqlContainerQuery, GqlContainerQueryVariables>(ContainerDocument, options);
      }
export function useContainerLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GqlContainerQuery, GqlContainerQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GqlContainerQuery, GqlContainerQueryVariables>(ContainerDocument, options);
        }
export type ContainerQueryHookResult = ReturnType<typeof useContainerQuery>;
export type ContainerLazyQueryHookResult = ReturnType<typeof useContainerLazyQuery>;
export const UpdateContainerDocument = gql`
    mutation UpdateContainer($input: UpdateContainerInput!) {
  updateContainer(input: $input) {
    success
  }
}
    `;
export type GqlUpdateContainerMutationFn = Apollo.MutationFunction<GqlUpdateContainerMutation, GqlUpdateContainerMutationVariables>;

/**
 * __useUpdateContainerMutation__
 *
 * To run a mutation, you first call `useUpdateContainerMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateContainerMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateContainerMutation, { data, loading, error }] = useUpdateContainerMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateContainerMutation(baseOptions?: Apollo.MutationHookOptions<GqlUpdateContainerMutation, GqlUpdateContainerMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<GqlUpdateContainerMutation, GqlUpdateContainerMutationVariables>(UpdateContainerDocument, options);
      }
export type UpdateContainerMutationHookResult = ReturnType<typeof useUpdateContainerMutation>;
export type UpdateContainerMutationOptions = Apollo.BaseMutationOptions<GqlUpdateContainerMutation, GqlUpdateContainerMutationVariables>;
export const MarkContainerAsHotDocument = gql`
    mutation MarkContainerAsHot($input: MarkContainerAsHotInput!) {
  markContainerAsHot(input: $input) {
    success
    message
    container {
      isHot
      hotMarkedBy
      hotMarkedTimestamp
    }
  }
}
    `;
export type GqlMarkContainerAsHotMutationFn = Apollo.MutationFunction<GqlMarkContainerAsHotMutation, GqlMarkContainerAsHotMutationVariables>;

/**
 * __useMarkContainerAsHotMutation__
 *
 * To run a mutation, you first call `useMarkContainerAsHotMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMarkContainerAsHotMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [markContainerAsHotMutation, { data, loading, error }] = useMarkContainerAsHotMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useMarkContainerAsHotMutation(baseOptions?: Apollo.MutationHookOptions<GqlMarkContainerAsHotMutation, GqlMarkContainerAsHotMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<GqlMarkContainerAsHotMutation, GqlMarkContainerAsHotMutationVariables>(MarkContainerAsHotDocument, options);
      }
export type MarkContainerAsHotMutationHookResult = ReturnType<typeof useMarkContainerAsHotMutation>;
export type MarkContainerAsHotMutationOptions = Apollo.BaseMutationOptions<GqlMarkContainerAsHotMutation, GqlMarkContainerAsHotMutationVariables>;
export const NotificationsDocument = gql`
    query Notifications {
  notifications {
    id
    notificationCode
    createTime
    ... on NewBookingNotification {
      booking {
        id
        referenceNumber
        status
        createDate
        cargoReadyDate
        revisedCargoReadyDate
        logistics {
          confirmationDate
          pol {
            name
          }
          pod {
            name
          }
          finalDestination {
            name
          }
        }
        packLines {
          orderLine {
            expectedCargoReadyDate
            purchaseOrder {
              id
              poNumber
            }
            itemNumber
          }
        }
        containers {
          id
          containerType
        }
        relatedParties {
          partyType
          party {
            name
          }
        }
      }
    }
    ... on NewDocumentNotification {
      document {
        id
        documentType
        uploadDate
      }
      booking {
        id
        referenceNumber
        relatedParties {
          partyType
          party {
            name
          }
        }
        hbl {
          id
          referenceNumber
        }
        logistics {
          pol {
            ...Port
          }
          polEtd
          polAtd
          pod {
            ...Port
          }
          podEta
          deliveryLocation {
            name
          }
        }
      }
    }
  }
}
    ${PortFragmentDoc}`;

/**
 * __useNotificationsQuery__
 *
 * To run a query within a React component, call `useNotificationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useNotificationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useNotificationsQuery({
 *   variables: {
 *   },
 * });
 */
export function useNotificationsQuery(baseOptions?: Apollo.QueryHookOptions<GqlNotificationsQuery, GqlNotificationsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GqlNotificationsQuery, GqlNotificationsQueryVariables>(NotificationsDocument, options);
      }
export function useNotificationsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GqlNotificationsQuery, GqlNotificationsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GqlNotificationsQuery, GqlNotificationsQueryVariables>(NotificationsDocument, options);
        }
export type NotificationsQueryHookResult = ReturnType<typeof useNotificationsQuery>;
export type NotificationsLazyQueryHookResult = ReturnType<typeof useNotificationsLazyQuery>;
export const MarkNotificationsAsReadDocument = gql`
    mutation MarkNotificationsAsRead($input: MarkNotificationsAsReadInput!) {
  markNotificationsAsRead(input: $input) {
    success
    message
  }
}
    `;
export type GqlMarkNotificationsAsReadMutationFn = Apollo.MutationFunction<GqlMarkNotificationsAsReadMutation, GqlMarkNotificationsAsReadMutationVariables>;

/**
 * __useMarkNotificationsAsReadMutation__
 *
 * To run a mutation, you first call `useMarkNotificationsAsReadMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMarkNotificationsAsReadMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [markNotificationsAsReadMutation, { data, loading, error }] = useMarkNotificationsAsReadMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useMarkNotificationsAsReadMutation(baseOptions?: Apollo.MutationHookOptions<GqlMarkNotificationsAsReadMutation, GqlMarkNotificationsAsReadMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<GqlMarkNotificationsAsReadMutation, GqlMarkNotificationsAsReadMutationVariables>(MarkNotificationsAsReadDocument, options);
      }
export type MarkNotificationsAsReadMutationHookResult = ReturnType<typeof useMarkNotificationsAsReadMutation>;
export type MarkNotificationsAsReadMutationOptions = Apollo.BaseMutationOptions<GqlMarkNotificationsAsReadMutation, GqlMarkNotificationsAsReadMutationVariables>;
export const DashboardPOsDocument = gql`
    query DashboardPOs {
  dashboardPurchaseOrders {
    orderLine {
      id
      itemNumber
      lineStatus
      totalQty
      expectedCargoReadyDate
      indcDate
      unitPrice
      unitPriceCurrencyCode
      shipToLocation {
        id
        name
      }
      purchaseOrder {
        id
        poNumber
        relatedParties {
          partyType
          party {
            name
          }
        }
      }
    }
  }
}
    `;

/**
 * __useDashboardPOsQuery__
 *
 * To run a query within a React component, call `useDashboardPOsQuery` and pass it any options that fit your needs.
 * When your component renders, `useDashboardPOsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDashboardPOsQuery({
 *   variables: {
 *   },
 * });
 */
export function useDashboardPOsQuery(baseOptions?: Apollo.QueryHookOptions<GqlDashboardPOsQuery, GqlDashboardPOsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GqlDashboardPOsQuery, GqlDashboardPOsQueryVariables>(DashboardPOsDocument, options);
      }
export function useDashboardPOsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GqlDashboardPOsQuery, GqlDashboardPOsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GqlDashboardPOsQuery, GqlDashboardPOsQueryVariables>(DashboardPOsDocument, options);
        }
export type DashboardPOsQueryHookResult = ReturnType<typeof useDashboardPOsQuery>;
export type DashboardPOsLazyQueryHookResult = ReturnType<typeof useDashboardPOsLazyQuery>;
export const BookingSummaryDocument = gql`
    query BookingSummary($startDate: Date, $endDate: Date) {
  bookingSummary(startDate: $startDate, endDate: $endDate) {
    start
    end
    weekNumber
    containers {
      id
      containerNumber
      containerType
      vanPositions {
        packLine {
          orderLine {
            purchaseOrder {
              id
              poNumber
            }
          }
        }
      }
      shipment {
        id
        referenceNumber
        createDate
        status
        ... on Booking {
          cargoReadyDate
          revisedCargoReadyDate
        }
        relatedParties {
          partyType
          party {
            name
          }
        }
        logistics {
          pol {
            name
          }
          pod {
            name
          }
        }
      }
    }
    skuPackLines {
      id
      orderLine {
        itemNumber
        purchaseOrder {
          id
          poNumber
        }
      }
      lineNumber
      shippedQty
      booking {
        id
        referenceNumber
        createDate
        status
        cargoReadyDate
        revisedCargoReadyDate
        relatedParties {
          partyType
          party {
            name
          }
        }
        logistics {
          pol {
            name
          }
          pod {
            name
          }
        }
      }
      vanPositions {
        container {
          id
          containerNumber
        }
      }
    }
    invoicePackLines {
      id
      orderLine {
        itemNumber
        purchaseOrder {
          id
          poNumber
        }
      }
      linePrice
      lineNumber
      shippedQty
      booking {
        id
        referenceNumber
        createDate
        status
        cargoReadyDate
        revisedCargoReadyDate
        relatedParties {
          partyType
          party {
            name
          }
        }
        logistics {
          pol {
            name
          }
          pod {
            name
          }
        }
      }
      vanPositions {
        container {
          id
          containerNumber
          containerType
        }
      }
      shippedCartons
      volume
      volumeUnit
      weight
      weightUnit
    }
    bookings {
      id
      referenceNumber
      createDate
      status
      ... on Booking {
        cargoReadyDate
        revisedCargoReadyDate
      }
      containers {
        id
        containerNumber
        containerType
      }
      relatedParties {
        partyType
        party {
          name
        }
      }
      logistics {
        pol {
          name
        }
        pod {
          name
        }
      }
    }
  }
}
    `;

/**
 * __useBookingSummaryQuery__
 *
 * To run a query within a React component, call `useBookingSummaryQuery` and pass it any options that fit your needs.
 * When your component renders, `useBookingSummaryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBookingSummaryQuery({
 *   variables: {
 *      startDate: // value for 'startDate'
 *      endDate: // value for 'endDate'
 *   },
 * });
 */
export function useBookingSummaryQuery(baseOptions?: Apollo.QueryHookOptions<GqlBookingSummaryQuery, GqlBookingSummaryQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GqlBookingSummaryQuery, GqlBookingSummaryQueryVariables>(BookingSummaryDocument, options);
      }
export function useBookingSummaryLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GqlBookingSummaryQuery, GqlBookingSummaryQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GqlBookingSummaryQuery, GqlBookingSummaryQueryVariables>(BookingSummaryDocument, options);
        }
export type BookingSummaryQueryHookResult = ReturnType<typeof useBookingSummaryQuery>;
export type BookingSummaryLazyQueryHookResult = ReturnType<typeof useBookingSummaryLazyQuery>;
export const DashboardNewBookingsDocument = gql`
    query DashboardNewBookings {
  newBookings {
    id
    referenceNumber
    createDate
    status
    cargoReadyDate
    revisedCargoReadyDate
    relatedParties {
      partyType
      party {
        name
      }
    }
    packLines {
      orderLine {
        id
        itemNumber
        purchaseOrder {
          id
          poNumber
        }
      }
    }
    containers {
      id
      containerNumber
      containerType
    }
    logistics {
      pol {
        name
      }
      pod {
        name
      }
    }
  }
}
    `;

/**
 * __useDashboardNewBookingsQuery__
 *
 * To run a query within a React component, call `useDashboardNewBookingsQuery` and pass it any options that fit your needs.
 * When your component renders, `useDashboardNewBookingsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDashboardNewBookingsQuery({
 *   variables: {
 *   },
 * });
 */
export function useDashboardNewBookingsQuery(baseOptions?: Apollo.QueryHookOptions<GqlDashboardNewBookingsQuery, GqlDashboardNewBookingsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GqlDashboardNewBookingsQuery, GqlDashboardNewBookingsQueryVariables>(DashboardNewBookingsDocument, options);
      }
export function useDashboardNewBookingsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GqlDashboardNewBookingsQuery, GqlDashboardNewBookingsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GqlDashboardNewBookingsQuery, GqlDashboardNewBookingsQueryVariables>(DashboardNewBookingsDocument, options);
        }
export type DashboardNewBookingsQueryHookResult = ReturnType<typeof useDashboardNewBookingsQuery>;
export type DashboardNewBookingsLazyQueryHookResult = ReturnType<typeof useDashboardNewBookingsLazyQuery>;
export const DashboardNewDocumentsDocument = gql`
    query DashboardNewDocuments {
  newDocuments {
    id
    bookingId
    bookingNumber
    importFileName
    fileType
    documentType
    uploadDate
    shipperName
    hblId
    hblNumber
    pol {
      code
      name
    }
    polEtd
    polAtd
    pod {
      code
      name
    }
    podEta
    deliveryLocation
  }
}
    `;

/**
 * __useDashboardNewDocumentsQuery__
 *
 * To run a query within a React component, call `useDashboardNewDocumentsQuery` and pass it any options that fit your needs.
 * When your component renders, `useDashboardNewDocumentsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDashboardNewDocumentsQuery({
 *   variables: {
 *   },
 * });
 */
export function useDashboardNewDocumentsQuery(baseOptions?: Apollo.QueryHookOptions<GqlDashboardNewDocumentsQuery, GqlDashboardNewDocumentsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GqlDashboardNewDocumentsQuery, GqlDashboardNewDocumentsQueryVariables>(DashboardNewDocumentsDocument, options);
      }
export function useDashboardNewDocumentsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GqlDashboardNewDocumentsQuery, GqlDashboardNewDocumentsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GqlDashboardNewDocumentsQuery, GqlDashboardNewDocumentsQueryVariables>(DashboardNewDocumentsDocument, options);
        }
export type DashboardNewDocumentsQueryHookResult = ReturnType<typeof useDashboardNewDocumentsQuery>;
export type DashboardNewDocumentsLazyQueryHookResult = ReturnType<typeof useDashboardNewDocumentsLazyQuery>;
export const ExceptionsDocument = gql`
    query Exceptions {
  exceptions {
    ... on DelayedArrivalException {
      type
      booking {
        id
        referenceNumber
        cargoReadyDate
        logistics {
          polEtd
          polAtd
          podEta
          podAta
          deliveryEta
          finalDestination {
            name
          }
        }
        relatedParties {
          partyType
          party {
            name
          }
        }
        packLines {
          orderLine {
            expectedCargoReadyDate
            purchaseOrder {
              id
              poNumber
            }
          }
        }
      }
    }
    ... on PendingOutgateException {
      type
      notificationId
      markedAsRead
      container {
        id
        containerNumber
        rampAta
        demurrageLastFreeDayDate
        deliveryEta
        shipment {
          logistics {
            finalDestination {
              name
            }
          }
        }
      }
    }
    ... on EmptyNotReturnedException {
      type
      notificationId
      markedAsRead
      container {
        id
        containerNumber
        rampAta
        portOutGateDate
        deliveryAta
        detentionLastFreeDayDate
        shipment {
          logistics {
            finalDestination {
              name
            }
          }
        }
      }
    }
    ... on HotPurchaseOrder {
      type
      openOrder {
        id
        orderLine {
          id
          lineStatus
          totalQty
          purchaseOrder {
            id
            poNumber
            relatedParties {
              partyType
              party {
                name
              }
            }
          }
          item {
            itemNumber
          }
          shipToLocation {
            name
          }
        }
        isHot
        hotMarkedBy
        hotMarkedTimestamp
      }
    }
    ... on HotShipment {
      type
      shipment {
        id
        referenceNumber
        ... on Booking {
          cargoReadyDate
          revisedCargoReadyDate
          hbl {
            id
            referenceNumber
          }
          mbl {
            id
            referenceNumber
          }
          isHot
          hotMarkedBy
          hotMarkedTimestamp
        }
        ... on Consolidation {
          mbl {
            id
            referenceNumber
          }
        }
        logistics {
          carrier {
            name
          }
          pol {
            code
          }
          polEtd
          polAtd
          pod {
            code
          }
          podEta
          podAta
          ramp {
            code
          }
          rampEta
          rampAta
          finalDestination {
            name
          }
          deliveryEta
          deliveryAta
        }
        relatedParties {
          partyType
          party {
            name
          }
        }
        containers {
          id
          containerType
          vanPositions {
            commercialInvoiceTotal
          }
        }
      }
    }
    ... on HotContainer {
      type
      container {
        id
        containerNumber
        isHot
        hotMarkedBy
        hotMarkedTimestamp
        shipment {
          ... on Booking {
            cargoReadyDate
            revisedCargoReadyDate
            hbl {
              id
              referenceNumber
            }
          }
          ... on Consolidation {
            mbl {
              id
              referenceNumber
            }
          }
          logistics {
            carrier {
              name
            }
            pol {
              code
            }
            polEtd
            polAtd
            pod {
              code
            }
            podEta
            podAta
            ramp {
              code
            }
            rampEta
            rampAta
            finalDestination {
              name
            }
            deliveryEta
            deliveryAta
          }
          relatedParties {
            partyType
            party {
              name
            }
          }
        }
        vanPositions {
          commercialInvoiceTotal
        }
      }
    }
    ... on MissingDocumentException {
      type
      notificationId
      markedAsRead
      documentType
      booking {
        id
        referenceNumber
        cargoReadyDate
        hbl {
          id
          referenceNumber
        }
        mbl {
          id
          referenceNumber
        }
        logistics {
          pol {
            ...Port
          }
          polEtd
          polAtd
          pod {
            ...Port
          }
          podEta
          podAta
          deliveryEta
          finalDestination {
            name
          }
        }
        relatedParties {
          partyType
          party {
            name
          }
        }
        packLines {
          orderLine {
            expectedCargoReadyDate
            purchaseOrder {
              id
              poNumber
            }
          }
        }
      }
    }
    ... on LateBookingException {
      type
      notificationId
      markedAsRead
      booking {
        id
        referenceNumber
        status
        createDate
        cargoReadyDate
        revisedCargoReadyDate
        relatedParties {
          partyType
          party {
            name
          }
        }
        logistics {
          pol {
            ...Port
          }
          finalDestination {
            name
          }
        }
      }
      orderLine {
        id
        itemNumber
        bookByDate
        expectedCargoReadyDate
        purchaseOrder {
          id
          poNumber
          expectedCargoReadyDate
        }
      }
    }
  }
}
    ${PortFragmentDoc}`;

/**
 * __useExceptionsQuery__
 *
 * To run a query within a React component, call `useExceptionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useExceptionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useExceptionsQuery({
 *   variables: {
 *   },
 * });
 */
export function useExceptionsQuery(baseOptions?: Apollo.QueryHookOptions<GqlExceptionsQuery, GqlExceptionsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GqlExceptionsQuery, GqlExceptionsQueryVariables>(ExceptionsDocument, options);
      }
export function useExceptionsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GqlExceptionsQuery, GqlExceptionsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GqlExceptionsQuery, GqlExceptionsQueryVariables>(ExceptionsDocument, options);
        }
export type ExceptionsQueryHookResult = ReturnType<typeof useExceptionsQuery>;
export type ExceptionsLazyQueryHookResult = ReturnType<typeof useExceptionsLazyQuery>;
export const ShipmentsInTransitDocument = gql`
    query ShipmentsInTransit {
  shipmentsInTransit {
    container {
      id
      containerNumber
      ramp {
        name
      }
      rampEta
      rampAta
      deliveryEta
      deliveryAta
      vanPositions {
        commercialInvoiceTotal
      }
      shipment {
        ... on Booking {
          cargoReadyDate
          revisedCargoReadyDate
          hbl {
            id
            referenceNumber
          }
          mbl {
            id
            referenceNumber
          }
        }
        ... on Consolidation {
          mbl {
            id
            referenceNumber
          }
        }
        relatedParties {
          partyType
          party {
            name
          }
        }
        logistics {
          pol {
            name
          }
          polEtd
          polAtd
          pod {
            name
          }
          podEta
          podAta
          finalDestination {
            name
          }
          carrier {
            name
          }
          deliveryLocation {
            name
          }
        }
      }
    }
    status
    milestone
    isDueToArrive
  }
}
    `;

/**
 * __useShipmentsInTransitQuery__
 *
 * To run a query within a React component, call `useShipmentsInTransitQuery` and pass it any options that fit your needs.
 * When your component renders, `useShipmentsInTransitQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useShipmentsInTransitQuery({
 *   variables: {
 *   },
 * });
 */
export function useShipmentsInTransitQuery(baseOptions?: Apollo.QueryHookOptions<GqlShipmentsInTransitQuery, GqlShipmentsInTransitQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GqlShipmentsInTransitQuery, GqlShipmentsInTransitQueryVariables>(ShipmentsInTransitDocument, options);
      }
export function useShipmentsInTransitLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GqlShipmentsInTransitQuery, GqlShipmentsInTransitQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GqlShipmentsInTransitQuery, GqlShipmentsInTransitQueryVariables>(ShipmentsInTransitDocument, options);
        }
export type ShipmentsInTransitQueryHookResult = ReturnType<typeof useShipmentsInTransitQuery>;
export type ShipmentsInTransitLazyQueryHookResult = ReturnType<typeof useShipmentsInTransitLazyQuery>;
export const DocumentsDocument = gql`
    query Documents($filter: DocumentFilter!) {
  documentSearch(filter: $filter) {
    id
    bookingId
    bookingNumber
    importFileName
    fileType
    documentType
    uploadDate
    shipperName
    hblId
    hblNumber
    pol {
      ...Port
    }
    polEtd
    polAtd
    pod {
      ...Port
    }
    podEta
    deliveryLocation
  }
}
    ${PortFragmentDoc}`;

/**
 * __useDocumentsQuery__
 *
 * To run a query within a React component, call `useDocumentsQuery` and pass it any options that fit your needs.
 * When your component renders, `useDocumentsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDocumentsQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useDocumentsQuery(baseOptions: Apollo.QueryHookOptions<GqlDocumentsQuery, GqlDocumentsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GqlDocumentsQuery, GqlDocumentsQueryVariables>(DocumentsDocument, options);
      }
export function useDocumentsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GqlDocumentsQuery, GqlDocumentsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GqlDocumentsQuery, GqlDocumentsQueryVariables>(DocumentsDocument, options);
        }
export type DocumentsQueryHookResult = ReturnType<typeof useDocumentsQuery>;
export type DocumentsLazyQueryHookResult = ReturnType<typeof useDocumentsLazyQuery>;
export const NewDocumentsDocument = gql`
    query NewDocuments {
  newDocuments {
    id
    bookingId
    bookingNumber
    importFileName
    fileType
    documentType
    uploadDate
    shipperName
    hblId
    hblNumber
    pol {
      code
      name
    }
    polEtd
    polAtd
    pod {
      code
      name
    }
    podEta
    deliveryLocation
  }
}
    `;

/**
 * __useNewDocumentsQuery__
 *
 * To run a query within a React component, call `useNewDocumentsQuery` and pass it any options that fit your needs.
 * When your component renders, `useNewDocumentsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useNewDocumentsQuery({
 *   variables: {
 *   },
 * });
 */
export function useNewDocumentsQuery(baseOptions?: Apollo.QueryHookOptions<GqlNewDocumentsQuery, GqlNewDocumentsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GqlNewDocumentsQuery, GqlNewDocumentsQueryVariables>(NewDocumentsDocument, options);
      }
export function useNewDocumentsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GqlNewDocumentsQuery, GqlNewDocumentsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GqlNewDocumentsQuery, GqlNewDocumentsQueryVariables>(NewDocumentsDocument, options);
        }
export type NewDocumentsQueryHookResult = ReturnType<typeof useNewDocumentsQuery>;
export type NewDocumentsLazyQueryHookResult = ReturnType<typeof useNewDocumentsLazyQuery>;
export const MarkDocumentsAsViewedDocument = gql`
    mutation MarkDocumentsAsViewed($input: MarkDocumentsAsViewedInput!) {
  markDocumentsAsViewed(input: $input) {
    success
    message
  }
}
    `;
export type GqlMarkDocumentsAsViewedMutationFn = Apollo.MutationFunction<GqlMarkDocumentsAsViewedMutation, GqlMarkDocumentsAsViewedMutationVariables>;

/**
 * __useMarkDocumentsAsViewedMutation__
 *
 * To run a mutation, you first call `useMarkDocumentsAsViewedMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMarkDocumentsAsViewedMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [markDocumentsAsViewedMutation, { data, loading, error }] = useMarkDocumentsAsViewedMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useMarkDocumentsAsViewedMutation(baseOptions?: Apollo.MutationHookOptions<GqlMarkDocumentsAsViewedMutation, GqlMarkDocumentsAsViewedMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<GqlMarkDocumentsAsViewedMutation, GqlMarkDocumentsAsViewedMutationVariables>(MarkDocumentsAsViewedDocument, options);
      }
export type MarkDocumentsAsViewedMutationHookResult = ReturnType<typeof useMarkDocumentsAsViewedMutation>;
export type MarkDocumentsAsViewedMutationOptions = Apollo.BaseMutationOptions<GqlMarkDocumentsAsViewedMutation, GqlMarkDocumentsAsViewedMutationVariables>;
export const UploadBookingDocumentDocument = gql`
    mutation UploadBookingDocument($input: UploadBookingDocumentInput!) {
  uploadBookingDocument(input: $input) {
    success
    message
  }
}
    `;
export type GqlUploadBookingDocumentMutationFn = Apollo.MutationFunction<GqlUploadBookingDocumentMutation, GqlUploadBookingDocumentMutationVariables>;

/**
 * __useUploadBookingDocumentMutation__
 *
 * To run a mutation, you first call `useUploadBookingDocumentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUploadBookingDocumentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [uploadBookingDocumentMutation, { data, loading, error }] = useUploadBookingDocumentMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUploadBookingDocumentMutation(baseOptions?: Apollo.MutationHookOptions<GqlUploadBookingDocumentMutation, GqlUploadBookingDocumentMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<GqlUploadBookingDocumentMutation, GqlUploadBookingDocumentMutationVariables>(UploadBookingDocumentDocument, options);
      }
export type UploadBookingDocumentMutationHookResult = ReturnType<typeof useUploadBookingDocumentMutation>;
export type UploadBookingDocumentMutationOptions = Apollo.BaseMutationOptions<GqlUploadBookingDocumentMutation, GqlUploadBookingDocumentMutationVariables>;
export const GlobalUpdateMDocument = gql`
    mutation GlobalUpdateM($input: GlobalUpdateInput!) {
  globalUpdate(input: $input) {
    success
    message
  }
}
    `;
export type GqlGlobalUpdateMMutationFn = Apollo.MutationFunction<GqlGlobalUpdateMMutation, GqlGlobalUpdateMMutationVariables>;

/**
 * __useGlobalUpdateMMutation__
 *
 * To run a mutation, you first call `useGlobalUpdateMMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useGlobalUpdateMMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [globalUpdateMMutation, { data, loading, error }] = useGlobalUpdateMMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGlobalUpdateMMutation(baseOptions?: Apollo.MutationHookOptions<GqlGlobalUpdateMMutation, GqlGlobalUpdateMMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<GqlGlobalUpdateMMutation, GqlGlobalUpdateMMutationVariables>(GlobalUpdateMDocument, options);
      }
export type GlobalUpdateMMutationHookResult = ReturnType<typeof useGlobalUpdateMMutation>;
export type GlobalUpdateMMutationOptions = Apollo.BaseMutationOptions<GqlGlobalUpdateMMutation, GqlGlobalUpdateMMutationVariables>;
export const GlobalUpdateSearchDocument = gql`
    query GlobalUpdateSearch($searchType: SearchType!, $query: String!, $numResults: Int) {
  search(searchType: $searchType, query: $query, numResults: $numResults) {
    id
    value
  }
}
    `;

/**
 * __useGlobalUpdateSearchQuery__
 *
 * To run a query within a React component, call `useGlobalUpdateSearchQuery` and pass it any options that fit your needs.
 * When your component renders, `useGlobalUpdateSearchQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGlobalUpdateSearchQuery({
 *   variables: {
 *      searchType: // value for 'searchType'
 *      query: // value for 'query'
 *      numResults: // value for 'numResults'
 *   },
 * });
 */
export function useGlobalUpdateSearchQuery(baseOptions: Apollo.QueryHookOptions<GqlGlobalUpdateSearchQuery, GqlGlobalUpdateSearchQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GqlGlobalUpdateSearchQuery, GqlGlobalUpdateSearchQueryVariables>(GlobalUpdateSearchDocument, options);
      }
export function useGlobalUpdateSearchLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GqlGlobalUpdateSearchQuery, GqlGlobalUpdateSearchQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GqlGlobalUpdateSearchQuery, GqlGlobalUpdateSearchQueryVariables>(GlobalUpdateSearchDocument, options);
        }
export type GlobalUpdateSearchQueryHookResult = ReturnType<typeof useGlobalUpdateSearchQuery>;
export type GlobalUpdateSearchLazyQueryHookResult = ReturnType<typeof useGlobalUpdateSearchLazyQuery>;
export const GlobalUpdateSearchResultsDocument = gql`
    query GlobalUpdateSearchResults($searchType: SearchType!, $searchId: ID!) {
  globalUpdateSearch(searchType: $searchType, searchId: $searchId) {
    recordId
    shipmentId
    referenceNumber
    hblId
    hblNumber
    mblId
    mblNumber
    containerId
    containerNumber
    poId
    poNumber
    itemNumber
    vesselName
    voyageName
  }
}
    `;

/**
 * __useGlobalUpdateSearchResultsQuery__
 *
 * To run a query within a React component, call `useGlobalUpdateSearchResultsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGlobalUpdateSearchResultsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGlobalUpdateSearchResultsQuery({
 *   variables: {
 *      searchType: // value for 'searchType'
 *      searchId: // value for 'searchId'
 *   },
 * });
 */
export function useGlobalUpdateSearchResultsQuery(baseOptions: Apollo.QueryHookOptions<GqlGlobalUpdateSearchResultsQuery, GqlGlobalUpdateSearchResultsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GqlGlobalUpdateSearchResultsQuery, GqlGlobalUpdateSearchResultsQueryVariables>(GlobalUpdateSearchResultsDocument, options);
      }
export function useGlobalUpdateSearchResultsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GqlGlobalUpdateSearchResultsQuery, GqlGlobalUpdateSearchResultsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GqlGlobalUpdateSearchResultsQuery, GqlGlobalUpdateSearchResultsQueryVariables>(GlobalUpdateSearchResultsDocument, options);
        }
export type GlobalUpdateSearchResultsQueryHookResult = ReturnType<typeof useGlobalUpdateSearchResultsQuery>;
export type GlobalUpdateSearchResultsLazyQueryHookResult = ReturnType<typeof useGlobalUpdateSearchResultsLazyQuery>;
export const GlobalUpdateDocument = gql`
    query GlobalUpdate($recordIds: [ID!]!) {
  globalUpdate(recordIds: $recordIds) {
    logistics {
      logisticsId
      referenceNumber
      confirmationNumber
      confirmationDate
      polEtd
      polAtd
      transitPortEta
      transitPortAta
      transitPortEtd
      transitPortAtd
      podEta
      podAta
      rampEta
      rampAta
    }
    containers {
      containerId
      containerNumber
      rampEta
      rampAta
      rampOutGateDate
      portOutGateDate
      deliveryEta
      deliveryAta
      deliveryUnload
      emptyNotifyDate
      emptyPickupDate
      emptyReturnedDate
      demurrageLastFreeDayDate
      detentionLastFreeDayDate
      consolidatorLastFreeDayDate
    }
    customs {
      customsId
      customsBrokerRef
      isfResponseNumber
      isfFilingDate
      entryNumber
      entryDate
      entryValue
      mpf
      hmf
      duty
      customsExamType
      customsExamNotifyDate
      customsExamFee
      customsReleaseDate
      fdaExamType
      fdaExamNotifyDate
      fdaExamFee
      fdaReleaseDate
      entryFeeTotal
    }
    records {
      recordId
      shipmentId
      referenceNumber
      hblId
      hblNumber
      mblId
      mblNumber
      containerId
      containerNumber
      poId
      poNumber
      itemNumber
      vesselName
      voyageName
    }
  }
}
    `;

/**
 * __useGlobalUpdateQuery__
 *
 * To run a query within a React component, call `useGlobalUpdateQuery` and pass it any options that fit your needs.
 * When your component renders, `useGlobalUpdateQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGlobalUpdateQuery({
 *   variables: {
 *      recordIds: // value for 'recordIds'
 *   },
 * });
 */
export function useGlobalUpdateQuery(baseOptions: Apollo.QueryHookOptions<GqlGlobalUpdateQuery, GqlGlobalUpdateQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GqlGlobalUpdateQuery, GqlGlobalUpdateQueryVariables>(GlobalUpdateDocument, options);
      }
export function useGlobalUpdateLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GqlGlobalUpdateQuery, GqlGlobalUpdateQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GqlGlobalUpdateQuery, GqlGlobalUpdateQueryVariables>(GlobalUpdateDocument, options);
        }
export type GlobalUpdateQueryHookResult = ReturnType<typeof useGlobalUpdateQuery>;
export type GlobalUpdateLazyQueryHookResult = ReturnType<typeof useGlobalUpdateLazyQuery>;
export const HblDocument = gql`
    query Hbl($hblId: ID!) {
  hbl(hblId: $hblId) {
    id
    referenceNumber
    description
    releaseType
    paymentType
    containerCount
    totalPieces
    totalCartons
    booking {
      id
      referenceNumber
      status
      logistics {
        contractNumber
        carrier {
          id
          name
        }
        motherVessel {
          id
          name
        }
        motherVoyage {
          id
          name
        }
        contractType
        moveType
        pol {
          ...Port
        }
        polEtd
        polAtd
        pod {
          ...Port
        }
        podEta
        podAta
        ramp {
          ...Port
        }
        rampEta
        rampAta
        deliveryLocation {
          id
          name
        }
        deliveryEta
        deliveryAta
      }
      mbl {
        id
        referenceNumber
        remarks {
          id
          text
          createDate
          createdBy {
            id
            firstName
            lastName
            profile {
              id
              name
            }
          }
        }
      }
      packLines {
        id
      }
      remarks {
        id
        text
        createDate
        createdBy {
          id
          firstName
          lastName
          profile {
            id
            name
          }
        }
      }
      containers {
        id
        containerNumber
        containerType
        sealNumber
        volume
        weight
        shipFromFactoryDate
        terminalReceivedDate
        isHot
      }
      documents {
        id
        documentType
        importFileName
        fileName
        fileType
        fileSize
        uploadDate
        description
      }
      isHot
      hotMarkedBy
      hotMarkedTimestamp
    }
    remarks {
      id
      text
      createDate
      createdBy {
        id
        firstName
        lastName
        profile {
          id
          name
        }
      }
    }
    documents {
      id
      documentType
      importFileName
      fileName
      fileType
      fileSize
      uploadDate
      description
    }
    relatedParties {
      id
      partyType
      party {
        id
        name
        locations {
          id
          addressLine1
          addressLine2
          city
          state
          postalCode
          country
        }
      }
    }
  }
}
    ${PortFragmentDoc}`;

/**
 * __useHblQuery__
 *
 * To run a query within a React component, call `useHblQuery` and pass it any options that fit your needs.
 * When your component renders, `useHblQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useHblQuery({
 *   variables: {
 *      hblId: // value for 'hblId'
 *   },
 * });
 */
export function useHblQuery(baseOptions: Apollo.QueryHookOptions<GqlHblQuery, GqlHblQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GqlHblQuery, GqlHblQueryVariables>(HblDocument, options);
      }
export function useHblLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GqlHblQuery, GqlHblQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GqlHblQuery, GqlHblQueryVariables>(HblDocument, options);
        }
export type HblQueryHookResult = ReturnType<typeof useHblQuery>;
export type HblLazyQueryHookResult = ReturnType<typeof useHblLazyQuery>;
export const NewHblRemarkDocument = gql`
    mutation NewHblRemark($input: NewHblRemarkInput!) {
  newHblRemark(input: $input) {
    success
    message
    remark {
      id
      text
      createDate
      createdBy {
        firstName
        lastName
        title
        profile {
          name
        }
      }
    }
  }
}
    `;
export type GqlNewHblRemarkMutationFn = Apollo.MutationFunction<GqlNewHblRemarkMutation, GqlNewHblRemarkMutationVariables>;

/**
 * __useNewHblRemarkMutation__
 *
 * To run a mutation, you first call `useNewHblRemarkMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useNewHblRemarkMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [newHblRemarkMutation, { data, loading, error }] = useNewHblRemarkMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useNewHblRemarkMutation(baseOptions?: Apollo.MutationHookOptions<GqlNewHblRemarkMutation, GqlNewHblRemarkMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<GqlNewHblRemarkMutation, GqlNewHblRemarkMutationVariables>(NewHblRemarkDocument, options);
      }
export type NewHblRemarkMutationHookResult = ReturnType<typeof useNewHblRemarkMutation>;
export type NewHblRemarkMutationOptions = Apollo.BaseMutationOptions<GqlNewHblRemarkMutation, GqlNewHblRemarkMutationVariables>;
export const AssignHblRelatedPartyDocument = gql`
    mutation AssignHblRelatedParty($input: AssignHblRelatedPartyInput!) {
  assignHblRelatedParty(input: $input) {
    success
    message
  }
}
    `;
export type GqlAssignHblRelatedPartyMutationFn = Apollo.MutationFunction<GqlAssignHblRelatedPartyMutation, GqlAssignHblRelatedPartyMutationVariables>;

/**
 * __useAssignHblRelatedPartyMutation__
 *
 * To run a mutation, you first call `useAssignHblRelatedPartyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAssignHblRelatedPartyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [assignHblRelatedPartyMutation, { data, loading, error }] = useAssignHblRelatedPartyMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAssignHblRelatedPartyMutation(baseOptions?: Apollo.MutationHookOptions<GqlAssignHblRelatedPartyMutation, GqlAssignHblRelatedPartyMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<GqlAssignHblRelatedPartyMutation, GqlAssignHblRelatedPartyMutationVariables>(AssignHblRelatedPartyDocument, options);
      }
export type AssignHblRelatedPartyMutationHookResult = ReturnType<typeof useAssignHblRelatedPartyMutation>;
export type AssignHblRelatedPartyMutationOptions = Apollo.BaseMutationOptions<GqlAssignHblRelatedPartyMutation, GqlAssignHblRelatedPartyMutationVariables>;
export const RemoveHblRelatedPartyDocument = gql`
    mutation RemoveHblRelatedParty($input: RemoveHblRelatedPartyInput!) {
  removeHblRelatedParty(input: $input) {
    success
    message
  }
}
    `;
export type GqlRemoveHblRelatedPartyMutationFn = Apollo.MutationFunction<GqlRemoveHblRelatedPartyMutation, GqlRemoveHblRelatedPartyMutationVariables>;

/**
 * __useRemoveHblRelatedPartyMutation__
 *
 * To run a mutation, you first call `useRemoveHblRelatedPartyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveHblRelatedPartyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeHblRelatedPartyMutation, { data, loading, error }] = useRemoveHblRelatedPartyMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRemoveHblRelatedPartyMutation(baseOptions?: Apollo.MutationHookOptions<GqlRemoveHblRelatedPartyMutation, GqlRemoveHblRelatedPartyMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<GqlRemoveHblRelatedPartyMutation, GqlRemoveHblRelatedPartyMutationVariables>(RemoveHblRelatedPartyDocument, options);
      }
export type RemoveHblRelatedPartyMutationHookResult = ReturnType<typeof useRemoveHblRelatedPartyMutation>;
export type RemoveHblRelatedPartyMutationOptions = Apollo.BaseMutationOptions<GqlRemoveHblRelatedPartyMutation, GqlRemoveHblRelatedPartyMutationVariables>;
export const UpdateHblRelatedPartyDocument = gql`
    mutation UpdateHblRelatedParty($input: UpdateHblRelatedPartyInput!) {
  updateHblRelatedParty(input: $input) {
    success
    message
  }
}
    `;
export type GqlUpdateHblRelatedPartyMutationFn = Apollo.MutationFunction<GqlUpdateHblRelatedPartyMutation, GqlUpdateHblRelatedPartyMutationVariables>;

/**
 * __useUpdateHblRelatedPartyMutation__
 *
 * To run a mutation, you first call `useUpdateHblRelatedPartyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateHblRelatedPartyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateHblRelatedPartyMutation, { data, loading, error }] = useUpdateHblRelatedPartyMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateHblRelatedPartyMutation(baseOptions?: Apollo.MutationHookOptions<GqlUpdateHblRelatedPartyMutation, GqlUpdateHblRelatedPartyMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<GqlUpdateHblRelatedPartyMutation, GqlUpdateHblRelatedPartyMutationVariables>(UpdateHblRelatedPartyDocument, options);
      }
export type UpdateHblRelatedPartyMutationHookResult = ReturnType<typeof useUpdateHblRelatedPartyMutation>;
export type UpdateHblRelatedPartyMutationOptions = Apollo.BaseMutationOptions<GqlUpdateHblRelatedPartyMutation, GqlUpdateHblRelatedPartyMutationVariables>;
export const UpdateLocationDocument = gql`
    mutation UpdateLocation($input: UpdateLocationInput!) {
  updateLocation(input: $input) {
    success
    message
  }
}
    `;
export type GqlUpdateLocationMutationFn = Apollo.MutationFunction<GqlUpdateLocationMutation, GqlUpdateLocationMutationVariables>;

/**
 * __useUpdateLocationMutation__
 *
 * To run a mutation, you first call `useUpdateLocationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateLocationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateLocationMutation, { data, loading, error }] = useUpdateLocationMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateLocationMutation(baseOptions?: Apollo.MutationHookOptions<GqlUpdateLocationMutation, GqlUpdateLocationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<GqlUpdateLocationMutation, GqlUpdateLocationMutationVariables>(UpdateLocationDocument, options);
      }
export type UpdateLocationMutationHookResult = ReturnType<typeof useUpdateLocationMutation>;
export type UpdateLocationMutationOptions = Apollo.BaseMutationOptions<GqlUpdateLocationMutation, GqlUpdateLocationMutationVariables>;
export const MergeLocationDocument = gql`
    mutation MergeLocation($input: MergeLocationInput!) {
  mergeLocation(input: $input) {
    success
    message
  }
}
    `;
export type GqlMergeLocationMutationFn = Apollo.MutationFunction<GqlMergeLocationMutation, GqlMergeLocationMutationVariables>;

/**
 * __useMergeLocationMutation__
 *
 * To run a mutation, you first call `useMergeLocationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMergeLocationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [mergeLocationMutation, { data, loading, error }] = useMergeLocationMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useMergeLocationMutation(baseOptions?: Apollo.MutationHookOptions<GqlMergeLocationMutation, GqlMergeLocationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<GqlMergeLocationMutation, GqlMergeLocationMutationVariables>(MergeLocationDocument, options);
      }
export type MergeLocationMutationHookResult = ReturnType<typeof useMergeLocationMutation>;
export type MergeLocationMutationOptions = Apollo.BaseMutationOptions<GqlMergeLocationMutation, GqlMergeLocationMutationVariables>;
export const NewProfileLocationDocument = gql`
    mutation NewProfileLocation($input: NewProfileLocationInput!) {
  newProfileLocation(input: $input) {
    success
    message
    location {
      id
      name
    }
  }
}
    `;
export type GqlNewProfileLocationMutationFn = Apollo.MutationFunction<GqlNewProfileLocationMutation, GqlNewProfileLocationMutationVariables>;

/**
 * __useNewProfileLocationMutation__
 *
 * To run a mutation, you first call `useNewProfileLocationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useNewProfileLocationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [newProfileLocationMutation, { data, loading, error }] = useNewProfileLocationMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useNewProfileLocationMutation(baseOptions?: Apollo.MutationHookOptions<GqlNewProfileLocationMutation, GqlNewProfileLocationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<GqlNewProfileLocationMutation, GqlNewProfileLocationMutationVariables>(NewProfileLocationDocument, options);
      }
export type NewProfileLocationMutationHookResult = ReturnType<typeof useNewProfileLocationMutation>;
export type NewProfileLocationMutationOptions = Apollo.BaseMutationOptions<GqlNewProfileLocationMutation, GqlNewProfileLocationMutationVariables>;
export const CarriersDocument = gql`
    query Carriers {
  carriers {
    id
    scac
    name
  }
}
    `;

/**
 * __useCarriersQuery__
 *
 * To run a query within a React component, call `useCarriersQuery` and pass it any options that fit your needs.
 * When your component renders, `useCarriersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCarriersQuery({
 *   variables: {
 *   },
 * });
 */
export function useCarriersQuery(baseOptions?: Apollo.QueryHookOptions<GqlCarriersQuery, GqlCarriersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GqlCarriersQuery, GqlCarriersQueryVariables>(CarriersDocument, options);
      }
export function useCarriersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GqlCarriersQuery, GqlCarriersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GqlCarriersQuery, GqlCarriersQueryVariables>(CarriersDocument, options);
        }
export type CarriersQueryHookResult = ReturnType<typeof useCarriersQuery>;
export type CarriersLazyQueryHookResult = ReturnType<typeof useCarriersLazyQuery>;
export const VesselsDocument = gql`
    query Vessels {
  vessels {
    id
    name
    voyages {
      id
      name
    }
  }
}
    `;

/**
 * __useVesselsQuery__
 *
 * To run a query within a React component, call `useVesselsQuery` and pass it any options that fit your needs.
 * When your component renders, `useVesselsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useVesselsQuery({
 *   variables: {
 *   },
 * });
 */
export function useVesselsQuery(baseOptions?: Apollo.QueryHookOptions<GqlVesselsQuery, GqlVesselsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GqlVesselsQuery, GqlVesselsQueryVariables>(VesselsDocument, options);
      }
export function useVesselsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GqlVesselsQuery, GqlVesselsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GqlVesselsQuery, GqlVesselsQueryVariables>(VesselsDocument, options);
        }
export type VesselsQueryHookResult = ReturnType<typeof useVesselsQuery>;
export type VesselsLazyQueryHookResult = ReturnType<typeof useVesselsLazyQuery>;
export const MblDocument = gql`
    query Mbl($mblId: ID!) {
  mbl(mblId: $mblId) {
    id
    referenceNumber
    containerCount
    totalPieces
    totalCartons
    releaseType
    paymentType
    shipment {
      ... on Booking {
        hbl {
          id
          referenceNumber
          releaseType
          booking {
            id
            referenceNumber
          }
          remarks {
            id
            text
            createDate
            createdBy {
              id
              firstName
              lastName
              profile {
                id
                name
              }
            }
          }
          documents {
            id
            documentType
            importFileName
            fileName
            fileType
            fileSize
            uploadDate
            description
          }
        }
      }
      id
      status
      referenceNumber
      logistics {
        carrier {
          id
          name
        }
        motherVessel {
          id
          name
        }
        motherVoyage {
          id
          name
        }
        contractNumber
        contractType
        moveType
        pol {
          ...Port
        }
        polEtd
        polAtd
        pod {
          ...Port
        }
        podEta
        podAta
        ramp {
          ...Port
        }
        rampEta
        rampAta
        deliveryLocation {
          id
          name
        }
        deliveryEta
        deliveryAta
      }
      remarks {
        id
        text
        createDate
        createdBy {
          id
          firstName
          lastName
          profile {
            id
            name
          }
        }
      }
      containers {
        id
        containerNumber
        containerType
        sealNumber
        volume
        weight
        shipFromFactoryDate
        terminalReceivedDate
        isHot
      }
      documents {
        id
        documentType
        importFileName
        fileName
        fileType
        fileSize
        uploadDate
        description
      }
    }
    remarks {
      id
      text
      createDate
      createdBy {
        id
        firstName
        lastName
        profile {
          id
          name
        }
      }
    }
    documents {
      id
      documentType
      importFileName
      fileName
      fileType
      fileSize
      uploadDate
      description
    }
    relatedParties {
      id
      partyType
      party {
        id
        name
        profileCode
        profileType {
          name
        }
      }
    }
  }
}
    ${PortFragmentDoc}`;

/**
 * __useMblQuery__
 *
 * To run a query within a React component, call `useMblQuery` and pass it any options that fit your needs.
 * When your component renders, `useMblQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMblQuery({
 *   variables: {
 *      mblId: // value for 'mblId'
 *   },
 * });
 */
export function useMblQuery(baseOptions: Apollo.QueryHookOptions<GqlMblQuery, GqlMblQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GqlMblQuery, GqlMblQueryVariables>(MblDocument, options);
      }
export function useMblLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GqlMblQuery, GqlMblQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GqlMblQuery, GqlMblQueryVariables>(MblDocument, options);
        }
export type MblQueryHookResult = ReturnType<typeof useMblQuery>;
export type MblLazyQueryHookResult = ReturnType<typeof useMblLazyQuery>;
export const NewMblRemarkDocument = gql`
    mutation NewMblRemark($input: NewMblRemarkInput!) {
  newMblRemark(input: $input) {
    success
  }
}
    `;
export type GqlNewMblRemarkMutationFn = Apollo.MutationFunction<GqlNewMblRemarkMutation, GqlNewMblRemarkMutationVariables>;

/**
 * __useNewMblRemarkMutation__
 *
 * To run a mutation, you first call `useNewMblRemarkMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useNewMblRemarkMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [newMblRemarkMutation, { data, loading, error }] = useNewMblRemarkMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useNewMblRemarkMutation(baseOptions?: Apollo.MutationHookOptions<GqlNewMblRemarkMutation, GqlNewMblRemarkMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<GqlNewMblRemarkMutation, GqlNewMblRemarkMutationVariables>(NewMblRemarkDocument, options);
      }
export type NewMblRemarkMutationHookResult = ReturnType<typeof useNewMblRemarkMutation>;
export type NewMblRemarkMutationOptions = Apollo.BaseMutationOptions<GqlNewMblRemarkMutation, GqlNewMblRemarkMutationVariables>;
export const AssignMblRelatedPartyDocument = gql`
    mutation AssignMblRelatedParty($input: AssignMblRelatedPartyInput!) {
  assignMblRelatedParty(input: $input) {
    success
    message
  }
}
    `;
export type GqlAssignMblRelatedPartyMutationFn = Apollo.MutationFunction<GqlAssignMblRelatedPartyMutation, GqlAssignMblRelatedPartyMutationVariables>;

/**
 * __useAssignMblRelatedPartyMutation__
 *
 * To run a mutation, you first call `useAssignMblRelatedPartyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAssignMblRelatedPartyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [assignMblRelatedPartyMutation, { data, loading, error }] = useAssignMblRelatedPartyMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAssignMblRelatedPartyMutation(baseOptions?: Apollo.MutationHookOptions<GqlAssignMblRelatedPartyMutation, GqlAssignMblRelatedPartyMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<GqlAssignMblRelatedPartyMutation, GqlAssignMblRelatedPartyMutationVariables>(AssignMblRelatedPartyDocument, options);
      }
export type AssignMblRelatedPartyMutationHookResult = ReturnType<typeof useAssignMblRelatedPartyMutation>;
export type AssignMblRelatedPartyMutationOptions = Apollo.BaseMutationOptions<GqlAssignMblRelatedPartyMutation, GqlAssignMblRelatedPartyMutationVariables>;
export const RemoveMblRelatedPartyDocument = gql`
    mutation RemoveMblRelatedParty($input: RemoveMblRelatedPartyInput!) {
  removeMblRelatedParty(input: $input) {
    success
    message
  }
}
    `;
export type GqlRemoveMblRelatedPartyMutationFn = Apollo.MutationFunction<GqlRemoveMblRelatedPartyMutation, GqlRemoveMblRelatedPartyMutationVariables>;

/**
 * __useRemoveMblRelatedPartyMutation__
 *
 * To run a mutation, you first call `useRemoveMblRelatedPartyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveMblRelatedPartyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeMblRelatedPartyMutation, { data, loading, error }] = useRemoveMblRelatedPartyMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRemoveMblRelatedPartyMutation(baseOptions?: Apollo.MutationHookOptions<GqlRemoveMblRelatedPartyMutation, GqlRemoveMblRelatedPartyMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<GqlRemoveMblRelatedPartyMutation, GqlRemoveMblRelatedPartyMutationVariables>(RemoveMblRelatedPartyDocument, options);
      }
export type RemoveMblRelatedPartyMutationHookResult = ReturnType<typeof useRemoveMblRelatedPartyMutation>;
export type RemoveMblRelatedPartyMutationOptions = Apollo.BaseMutationOptions<GqlRemoveMblRelatedPartyMutation, GqlRemoveMblRelatedPartyMutationVariables>;
export const UpdateMblRelatedPartyDocument = gql`
    mutation UpdateMblRelatedParty($input: UpdateMblRelatedPartyInput!) {
  updateMblRelatedParty(input: $input) {
    success
    message
  }
}
    `;
export type GqlUpdateMblRelatedPartyMutationFn = Apollo.MutationFunction<GqlUpdateMblRelatedPartyMutation, GqlUpdateMblRelatedPartyMutationVariables>;

/**
 * __useUpdateMblRelatedPartyMutation__
 *
 * To run a mutation, you first call `useUpdateMblRelatedPartyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateMblRelatedPartyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateMblRelatedPartyMutation, { data, loading, error }] = useUpdateMblRelatedPartyMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateMblRelatedPartyMutation(baseOptions?: Apollo.MutationHookOptions<GqlUpdateMblRelatedPartyMutation, GqlUpdateMblRelatedPartyMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<GqlUpdateMblRelatedPartyMutation, GqlUpdateMblRelatedPartyMutationVariables>(UpdateMblRelatedPartyDocument, options);
      }
export type UpdateMblRelatedPartyMutationHookResult = ReturnType<typeof useUpdateMblRelatedPartyMutation>;
export type UpdateMblRelatedPartyMutationOptions = Apollo.BaseMutationOptions<GqlUpdateMblRelatedPartyMutation, GqlUpdateMblRelatedPartyMutationVariables>;
export const NetworkPartiesDocument = gql`
    query NetworkParties($networkId: ID!) {
  network(networkId: $networkId) {
    connectedProfiles {
      id
      name
      profileType {
        id
        name
      }
      locations {
        id
        name
      }
    }
  }
}
    `;

/**
 * __useNetworkPartiesQuery__
 *
 * To run a query within a React component, call `useNetworkPartiesQuery` and pass it any options that fit your needs.
 * When your component renders, `useNetworkPartiesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useNetworkPartiesQuery({
 *   variables: {
 *      networkId: // value for 'networkId'
 *   },
 * });
 */
export function useNetworkPartiesQuery(baseOptions: Apollo.QueryHookOptions<GqlNetworkPartiesQuery, GqlNetworkPartiesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GqlNetworkPartiesQuery, GqlNetworkPartiesQueryVariables>(NetworkPartiesDocument, options);
      }
export function useNetworkPartiesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GqlNetworkPartiesQuery, GqlNetworkPartiesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GqlNetworkPartiesQuery, GqlNetworkPartiesQueryVariables>(NetworkPartiesDocument, options);
        }
export type NetworkPartiesQueryHookResult = ReturnType<typeof useNetworkPartiesQuery>;
export type NetworkPartiesLazyQueryHookResult = ReturnType<typeof useNetworkPartiesLazyQuery>;
export const AllNetworksDocument = gql`
    query AllNetworks {
  allNetworks {
    id
    name
    connectedProfiles {
      id
      name
      isControllingClient
    }
  }
}
    `;

/**
 * __useAllNetworksQuery__
 *
 * To run a query within a React component, call `useAllNetworksQuery` and pass it any options that fit your needs.
 * When your component renders, `useAllNetworksQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAllNetworksQuery({
 *   variables: {
 *   },
 * });
 */
export function useAllNetworksQuery(baseOptions?: Apollo.QueryHookOptions<GqlAllNetworksQuery, GqlAllNetworksQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GqlAllNetworksQuery, GqlAllNetworksQueryVariables>(AllNetworksDocument, options);
      }
export function useAllNetworksLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GqlAllNetworksQuery, GqlAllNetworksQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GqlAllNetworksQuery, GqlAllNetworksQueryVariables>(AllNetworksDocument, options);
        }
export type AllNetworksQueryHookResult = ReturnType<typeof useAllNetworksQuery>;
export type AllNetworksLazyQueryHookResult = ReturnType<typeof useAllNetworksLazyQuery>;
export const NetworkDocument = gql`
    query Network($networkId: ID!) {
  network(networkId: $networkId) {
    id
    name
    connectedProfiles {
      id
      name
      profileType {
        id
        name
      }
      isControllingClient
      logo {
        url
      }
      needsUpdate
      locations {
        id
        name
        code
        nameAliases
        codeAliases
        addressLine1
        addressLine2
        city
        state
        postalCode
        country
        companyPhone
        companyUrl
        relatedPort {
          code
          name
        }
        locationTypes
      }
      contacts {
        id
        firstName
        lastName
        title
        email
        phoneMain
        phoneAlternative
        extension
        phoneCell
        isMainContact
        isUserEnabled
        notificationFrequency
        role {
          id
          name
        }
        location {
          id
          name
        }
      }
      relatedProfiles {
        id
      }
      roles {
        id
        name
        description
        isDefaultRole
      }
      network {
        id
        name
      }
    }
    profileTypes {
      id
      name
      description
      isTemplate
      defaultRoles {
        id
        name
        description
        isDefaultRole
        permissionCodes
      }
      permissionCodes
    }
    items {
      id
      itemNumber
      description
      htsCode
      supplier {
        name
      }
    }
    rules {
      poExpiredEnabled
      poExpiredDays
      poExpiredField
      poFirstShipEnabled
      poFirstShipDays
      poFirstShipField
      poLastShipEnabled
      poLastShipDays
      poLastShipField
      poBookByEnabled
      poBookByDays
      poBookByField
      poCriticalEnabled
      poCriticalDays
      poCriticalField
      bookingLateEnabled
      bookingLateDays
      bookingLateField
      bookingPendingConfEnabled
      bookingPendingConfDays
      bookingQtyToleranceEnabled
      bookingQtyTolerancePercentOver
      bookingQtyTolerancePercentUnder
      consolConsolableMoveTypesEnabled
      consolConsolableMoveTypes
      shipmentPolEtdChangeEnabled
      shipmentPolEtdChangeDays
      shipmentPodEtaChangeEnabled
      shipmentPodEtaChangeDays
      shipmentRevisedCrdChangeEnabled
      shipmentRevisedCrdChangeDays
      shipmentCriticalEnabled
      shipmentCriticalDays
      shipmentCriticalField1
      shipmentCriticalField2
      milestoneLateOnWaterEnabled
      milestoneLateOnWaterDays
      milestoneLateAtDischargeEnabled
      milestoneLateAtDischargeDays
      milestoneLateAtRampEnabled
      milestoneLateAtRampDays
      milestoneLateDeliveryEnabled
      milestoneLateDeliveryDays
      milestonePendingOutgateEnabled
      milestonePendingOutgateDays
      milestoneDelayedUnloadEnabled
      milestoneDelayedUnloadDays
      milestoneDelayedUnloadField
      milestoneEmptyNotReturnedEnabled
      milestoneEmptyNotReturnedDays
      documentTypes {
        documentType
        missingEnabled
        missingDays
        missingField
      }
    }
    containerTypes {
      code
      name
      capacityVolumeM3
      capacityWeightKg
      ruleMinVolumeM3
      ruleMaxVolumeM3
      ruleMinWeightKg
      ruleMaxWeightKg
    }
  }
}
    `;

/**
 * __useNetworkQuery__
 *
 * To run a query within a React component, call `useNetworkQuery` and pass it any options that fit your needs.
 * When your component renders, `useNetworkQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useNetworkQuery({
 *   variables: {
 *      networkId: // value for 'networkId'
 *   },
 * });
 */
export function useNetworkQuery(baseOptions: Apollo.QueryHookOptions<GqlNetworkQuery, GqlNetworkQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GqlNetworkQuery, GqlNetworkQueryVariables>(NetworkDocument, options);
      }
export function useNetworkLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GqlNetworkQuery, GqlNetworkQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GqlNetworkQuery, GqlNetworkQueryVariables>(NetworkDocument, options);
        }
export type NetworkQueryHookResult = ReturnType<typeof useNetworkQuery>;
export type NetworkLazyQueryHookResult = ReturnType<typeof useNetworkLazyQuery>;
export const NetworkProfilesDocument = gql`
    query NetworkProfiles($networkId: ID!) {
  network(networkId: $networkId) {
    connectedProfiles {
      id
      name
    }
  }
}
    `;

/**
 * __useNetworkProfilesQuery__
 *
 * To run a query within a React component, call `useNetworkProfilesQuery` and pass it any options that fit your needs.
 * When your component renders, `useNetworkProfilesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useNetworkProfilesQuery({
 *   variables: {
 *      networkId: // value for 'networkId'
 *   },
 * });
 */
export function useNetworkProfilesQuery(baseOptions: Apollo.QueryHookOptions<GqlNetworkProfilesQuery, GqlNetworkProfilesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GqlNetworkProfilesQuery, GqlNetworkProfilesQueryVariables>(NetworkProfilesDocument, options);
      }
export function useNetworkProfilesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GqlNetworkProfilesQuery, GqlNetworkProfilesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GqlNetworkProfilesQuery, GqlNetworkProfilesQueryVariables>(NetworkProfilesDocument, options);
        }
export type NetworkProfilesQueryHookResult = ReturnType<typeof useNetworkProfilesQuery>;
export type NetworkProfilesLazyQueryHookResult = ReturnType<typeof useNetworkProfilesLazyQuery>;
export const NewNetworkDocument = gql`
    mutation NewNetwork($input: NewNetworkInput!) {
  newNetwork(input: $input) {
    success
    message
    network {
      id
      connectedProfiles {
        id
        name
      }
    }
  }
}
    `;
export type GqlNewNetworkMutationFn = Apollo.MutationFunction<GqlNewNetworkMutation, GqlNewNetworkMutationVariables>;

/**
 * __useNewNetworkMutation__
 *
 * To run a mutation, you first call `useNewNetworkMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useNewNetworkMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [newNetworkMutation, { data, loading, error }] = useNewNetworkMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useNewNetworkMutation(baseOptions?: Apollo.MutationHookOptions<GqlNewNetworkMutation, GqlNewNetworkMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<GqlNewNetworkMutation, GqlNewNetworkMutationVariables>(NewNetworkDocument, options);
      }
export type NewNetworkMutationHookResult = ReturnType<typeof useNewNetworkMutation>;
export type NewNetworkMutationOptions = Apollo.BaseMutationOptions<GqlNewNetworkMutation, GqlNewNetworkMutationVariables>;
export const UpdateNetworkRulesDocument = gql`
    mutation UpdateNetworkRules($input: UpdateNetworkRulesInput!) {
  updateNetworkRules(input: $input) {
    success
    message
  }
}
    `;
export type GqlUpdateNetworkRulesMutationFn = Apollo.MutationFunction<GqlUpdateNetworkRulesMutation, GqlUpdateNetworkRulesMutationVariables>;

/**
 * __useUpdateNetworkRulesMutation__
 *
 * To run a mutation, you first call `useUpdateNetworkRulesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateNetworkRulesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateNetworkRulesMutation, { data, loading, error }] = useUpdateNetworkRulesMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateNetworkRulesMutation(baseOptions?: Apollo.MutationHookOptions<GqlUpdateNetworkRulesMutation, GqlUpdateNetworkRulesMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<GqlUpdateNetworkRulesMutation, GqlUpdateNetworkRulesMutationVariables>(UpdateNetworkRulesDocument, options);
      }
export type UpdateNetworkRulesMutationHookResult = ReturnType<typeof useUpdateNetworkRulesMutation>;
export type UpdateNetworkRulesMutationOptions = Apollo.BaseMutationOptions<GqlUpdateNetworkRulesMutation, GqlUpdateNetworkRulesMutationVariables>;
export const UpdateNetworkContainerTypesDocument = gql`
    mutation UpdateNetworkContainerTypes($input: UpdateNetworkContainerTypesInput!) {
  updateNetworkContainerTypes(input: $input) {
    success
    message
  }
}
    `;
export type GqlUpdateNetworkContainerTypesMutationFn = Apollo.MutationFunction<GqlUpdateNetworkContainerTypesMutation, GqlUpdateNetworkContainerTypesMutationVariables>;

/**
 * __useUpdateNetworkContainerTypesMutation__
 *
 * To run a mutation, you first call `useUpdateNetworkContainerTypesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateNetworkContainerTypesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateNetworkContainerTypesMutation, { data, loading, error }] = useUpdateNetworkContainerTypesMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateNetworkContainerTypesMutation(baseOptions?: Apollo.MutationHookOptions<GqlUpdateNetworkContainerTypesMutation, GqlUpdateNetworkContainerTypesMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<GqlUpdateNetworkContainerTypesMutation, GqlUpdateNetworkContainerTypesMutationVariables>(UpdateNetworkContainerTypesDocument, options);
      }
export type UpdateNetworkContainerTypesMutationHookResult = ReturnType<typeof useUpdateNetworkContainerTypesMutation>;
export type UpdateNetworkContainerTypesMutationOptions = Apollo.BaseMutationOptions<GqlUpdateNetworkContainerTypesMutation, GqlUpdateNetworkContainerTypesMutationVariables>;
export const NewNetworkConnectedProfileDocument = gql`
    mutation NewNetworkConnectedProfile($input: NewNetworkConnectedProfileInput!) {
  newNetworkConnectedProfile(input: $input) {
    success
    message
    profile {
      id
      name
    }
  }
}
    `;
export type GqlNewNetworkConnectedProfileMutationFn = Apollo.MutationFunction<GqlNewNetworkConnectedProfileMutation, GqlNewNetworkConnectedProfileMutationVariables>;

/**
 * __useNewNetworkConnectedProfileMutation__
 *
 * To run a mutation, you first call `useNewNetworkConnectedProfileMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useNewNetworkConnectedProfileMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [newNetworkConnectedProfileMutation, { data, loading, error }] = useNewNetworkConnectedProfileMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useNewNetworkConnectedProfileMutation(baseOptions?: Apollo.MutationHookOptions<GqlNewNetworkConnectedProfileMutation, GqlNewNetworkConnectedProfileMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<GqlNewNetworkConnectedProfileMutation, GqlNewNetworkConnectedProfileMutationVariables>(NewNetworkConnectedProfileDocument, options);
      }
export type NewNetworkConnectedProfileMutationHookResult = ReturnType<typeof useNewNetworkConnectedProfileMutation>;
export type NewNetworkConnectedProfileMutationOptions = Apollo.BaseMutationOptions<GqlNewNetworkConnectedProfileMutation, GqlNewNetworkConnectedProfileMutationVariables>;
export const NewNetworkProfileTypeDocument = gql`
    mutation NewNetworkProfileType($input: NewNetworkProfileTypeInput!) {
  newNetworkProfileType(input: $input) {
    success
    message
    profileType {
      id
    }
  }
}
    `;
export type GqlNewNetworkProfileTypeMutationFn = Apollo.MutationFunction<GqlNewNetworkProfileTypeMutation, GqlNewNetworkProfileTypeMutationVariables>;

/**
 * __useNewNetworkProfileTypeMutation__
 *
 * To run a mutation, you first call `useNewNetworkProfileTypeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useNewNetworkProfileTypeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [newNetworkProfileTypeMutation, { data, loading, error }] = useNewNetworkProfileTypeMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useNewNetworkProfileTypeMutation(baseOptions?: Apollo.MutationHookOptions<GqlNewNetworkProfileTypeMutation, GqlNewNetworkProfileTypeMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<GqlNewNetworkProfileTypeMutation, GqlNewNetworkProfileTypeMutationVariables>(NewNetworkProfileTypeDocument, options);
      }
export type NewNetworkProfileTypeMutationHookResult = ReturnType<typeof useNewNetworkProfileTypeMutation>;
export type NewNetworkProfileTypeMutationOptions = Apollo.BaseMutationOptions<GqlNewNetworkProfileTypeMutation, GqlNewNetworkProfileTypeMutationVariables>;
export const OpenOrdersDocument = gql`
    query OpenOrders {
  openOrders {
    id
    orderQty
    approvedQty
    balanceQty
    shippedQty
    shippedCartons
    isHot
    orderLine {
      id
      lineNumber
      itemNumber
      purchaseOrder {
        id
        poNumber
      }
      totalQty
      innerQty
      outerQty
      weight
      weightUnit
      volume
      volumeUnit
      expectedCargoReadyDate
      revisedCargoReadyDate
      shipToLocation {
        id
        name
      }
      firstShipDate
      lastShipDate
    }
  }
}
    `;

/**
 * __useOpenOrdersQuery__
 *
 * To run a query within a React component, call `useOpenOrdersQuery` and pass it any options that fit your needs.
 * When your component renders, `useOpenOrdersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOpenOrdersQuery({
 *   variables: {
 *   },
 * });
 */
export function useOpenOrdersQuery(baseOptions?: Apollo.QueryHookOptions<GqlOpenOrdersQuery, GqlOpenOrdersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GqlOpenOrdersQuery, GqlOpenOrdersQueryVariables>(OpenOrdersDocument, options);
      }
export function useOpenOrdersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GqlOpenOrdersQuery, GqlOpenOrdersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GqlOpenOrdersQuery, GqlOpenOrdersQueryVariables>(OpenOrdersDocument, options);
        }
export type OpenOrdersQueryHookResult = ReturnType<typeof useOpenOrdersQuery>;
export type OpenOrdersLazyQueryHookResult = ReturnType<typeof useOpenOrdersLazyQuery>;
export const OpenOrdersRelatedPartiesDocument = gql`
    query OpenOrdersRelatedParties($orderLineIds: [ID!]!) {
  openOrders(orderLineIds: $orderLineIds) {
    orderLine {
      id
      purchaseOrder {
        relatedParties {
          partyType
          party {
            id
            name
            locations {
              id
              name
            }
            relatedProfiles {
              id
              partyType
              profile {
                id
              }
            }
          }
        }
      }
    }
  }
}
    `;

/**
 * __useOpenOrdersRelatedPartiesQuery__
 *
 * To run a query within a React component, call `useOpenOrdersRelatedPartiesQuery` and pass it any options that fit your needs.
 * When your component renders, `useOpenOrdersRelatedPartiesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOpenOrdersRelatedPartiesQuery({
 *   variables: {
 *      orderLineIds: // value for 'orderLineIds'
 *   },
 * });
 */
export function useOpenOrdersRelatedPartiesQuery(baseOptions: Apollo.QueryHookOptions<GqlOpenOrdersRelatedPartiesQuery, GqlOpenOrdersRelatedPartiesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GqlOpenOrdersRelatedPartiesQuery, GqlOpenOrdersRelatedPartiesQueryVariables>(OpenOrdersRelatedPartiesDocument, options);
      }
export function useOpenOrdersRelatedPartiesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GqlOpenOrdersRelatedPartiesQuery, GqlOpenOrdersRelatedPartiesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GqlOpenOrdersRelatedPartiesQuery, GqlOpenOrdersRelatedPartiesQueryVariables>(OpenOrdersRelatedPartiesDocument, options);
        }
export type OpenOrdersRelatedPartiesQueryHookResult = ReturnType<typeof useOpenOrdersRelatedPartiesQuery>;
export type OpenOrdersRelatedPartiesLazyQueryHookResult = ReturnType<typeof useOpenOrdersRelatedPartiesLazyQuery>;
export const PurchaseOrdersDocument = gql`
    query PurchaseOrders {
  purchaseOrders {
    id
    poNumber
    poDate
    expectedCargoReadyDate
    shipToLocation {
      id
      name
      addressLine1
      addressLine2
      city
      state
      country
      postalCode
    }
    poStatus
    relatedParties {
      id
      partyType
      party {
        id
        name
      }
    }
    orderLines {
      id
      itemNumber
      itemDescription
      unitPrice
      expectedCargoReadyDate
      revisedCargoReadyDate
      shipToLocation {
        id
        name
        addressLine1
        addressLine2
        city
        state
        country
        postalCode
      }
      firstShipDate
      lastShipDate
      openOrder {
        balanceQty
        orderQty
        isHot
      }
    }
  }
}
    `;

/**
 * __usePurchaseOrdersQuery__
 *
 * To run a query within a React component, call `usePurchaseOrdersQuery` and pass it any options that fit your needs.
 * When your component renders, `usePurchaseOrdersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePurchaseOrdersQuery({
 *   variables: {
 *   },
 * });
 */
export function usePurchaseOrdersQuery(baseOptions?: Apollo.QueryHookOptions<GqlPurchaseOrdersQuery, GqlPurchaseOrdersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GqlPurchaseOrdersQuery, GqlPurchaseOrdersQueryVariables>(PurchaseOrdersDocument, options);
      }
export function usePurchaseOrdersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GqlPurchaseOrdersQuery, GqlPurchaseOrdersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GqlPurchaseOrdersQuery, GqlPurchaseOrdersQueryVariables>(PurchaseOrdersDocument, options);
        }
export type PurchaseOrdersQueryHookResult = ReturnType<typeof usePurchaseOrdersQuery>;
export type PurchaseOrdersLazyQueryHookResult = ReturnType<typeof usePurchaseOrdersLazyQuery>;
export const PurchaseOrderDocument = gql`
    query PurchaseOrder($poId: ID!) {
  purchaseOrder(purchaseOrderId: $poId) {
    id
    poNumber
    altPoNumber
    indcDate
    poDate
    poType
    poStatus
    customerPoNumber
    expectedCargoReadyDate
    shipToLocation {
      id
      name
      addressLine1
      addressLine2
      city
      state
      country
      code
      postalCode
    }
    htsCode
    originCountry
    customerCancelDate
    incoTerm
    customerStartShipDate
    pol {
      ...Port
    }
    pod {
      ...Port
    }
    relatedParties {
      id
      partyType
      party {
        id
        name
        profileCode
      }
    }
    orderLines {
      id
      lineNumber
      lineStatus
      bookByDate
      expectedCargoReadyDate
      revisedCargoReadyDate
      totalQty
      outerQty
      innerQty
      indcDate
      weight
      weightUnit
      volume
      volumeUnit
      unitPrice
      unitPriceCurrencyCode
      firstShipDate
      lastShipDate
      itemNumber
      itemDescription
      itemHtsCode
      itemStyle
      itemSize
      itemColor
      itemMaterial
      itemCategoryCode
      itemLength
      itemWidth
      itemHeight
      itemDimsUnit
      itemWeight
      itemWeightUnit
      itemVolume
      itemVolumeUnit
      itemDuty
      itemDutyCurrencyCode
      openOrder {
        approvedQty
        balanceQty
        shippedQty
        isHot
        hotMarkedBy
        hotMarkedTimestamp
      }
      shipToLocation {
        id
        name
        code
        addressLine1
        addressLine2
        city
        state
        country
        postalCode
      }
    }
    poHistory {
      id
      timestamp
      recordType
      message
      byUserName
      byPoUploadFileName
    }
    isHot
    hotMarkedBy
    hotMarkedTimestamp
  }
}
    ${PortFragmentDoc}`;

/**
 * __usePurchaseOrderQuery__
 *
 * To run a query within a React component, call `usePurchaseOrderQuery` and pass it any options that fit your needs.
 * When your component renders, `usePurchaseOrderQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePurchaseOrderQuery({
 *   variables: {
 *      poId: // value for 'poId'
 *   },
 * });
 */
export function usePurchaseOrderQuery(baseOptions: Apollo.QueryHookOptions<GqlPurchaseOrderQuery, GqlPurchaseOrderQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GqlPurchaseOrderQuery, GqlPurchaseOrderQueryVariables>(PurchaseOrderDocument, options);
      }
export function usePurchaseOrderLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GqlPurchaseOrderQuery, GqlPurchaseOrderQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GqlPurchaseOrderQuery, GqlPurchaseOrderQueryVariables>(PurchaseOrderDocument, options);
        }
export type PurchaseOrderQueryHookResult = ReturnType<typeof usePurchaseOrderQuery>;
export type PurchaseOrderLazyQueryHookResult = ReturnType<typeof usePurchaseOrderLazyQuery>;
export const SetPoRelatedPartiesDocument = gql`
    mutation SetPoRelatedParties($input: SetPoRelatedPartiesInput!) {
  setPoRelatedParties(input: $input) {
    success
    message
  }
}
    `;
export type GqlSetPoRelatedPartiesMutationFn = Apollo.MutationFunction<GqlSetPoRelatedPartiesMutation, GqlSetPoRelatedPartiesMutationVariables>;

/**
 * __useSetPoRelatedPartiesMutation__
 *
 * To run a mutation, you first call `useSetPoRelatedPartiesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetPoRelatedPartiesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setPoRelatedPartiesMutation, { data, loading, error }] = useSetPoRelatedPartiesMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSetPoRelatedPartiesMutation(baseOptions?: Apollo.MutationHookOptions<GqlSetPoRelatedPartiesMutation, GqlSetPoRelatedPartiesMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<GqlSetPoRelatedPartiesMutation, GqlSetPoRelatedPartiesMutationVariables>(SetPoRelatedPartiesDocument, options);
      }
export type SetPoRelatedPartiesMutationHookResult = ReturnType<typeof useSetPoRelatedPartiesMutation>;
export type SetPoRelatedPartiesMutationOptions = Apollo.BaseMutationOptions<GqlSetPoRelatedPartiesMutation, GqlSetPoRelatedPartiesMutationVariables>;
export const MarkPoLineAsHotDocument = gql`
    mutation MarkPoLineAsHot($input: MarkPoLineAsHotInput!) {
  markPoLineAsHot(input: $input) {
    success
    message
    openOrder {
      isHot
      hotMarkedBy
      hotMarkedTimestamp
    }
  }
}
    `;
export type GqlMarkPoLineAsHotMutationFn = Apollo.MutationFunction<GqlMarkPoLineAsHotMutation, GqlMarkPoLineAsHotMutationVariables>;

/**
 * __useMarkPoLineAsHotMutation__
 *
 * To run a mutation, you first call `useMarkPoLineAsHotMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMarkPoLineAsHotMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [markPoLineAsHotMutation, { data, loading, error }] = useMarkPoLineAsHotMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useMarkPoLineAsHotMutation(baseOptions?: Apollo.MutationHookOptions<GqlMarkPoLineAsHotMutation, GqlMarkPoLineAsHotMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<GqlMarkPoLineAsHotMutation, GqlMarkPoLineAsHotMutationVariables>(MarkPoLineAsHotDocument, options);
      }
export type MarkPoLineAsHotMutationHookResult = ReturnType<typeof useMarkPoLineAsHotMutation>;
export type MarkPoLineAsHotMutationOptions = Apollo.BaseMutationOptions<GqlMarkPoLineAsHotMutation, GqlMarkPoLineAsHotMutationVariables>;
export const MarkPoAsHotDocument = gql`
    mutation MarkPoAsHot($input: MarkPoAsHotInput!) {
  markPoAsHot(input: $input) {
    success
    message
    purchaseOrder {
      isHot
      hotMarkedBy
      hotMarkedTimestamp
    }
  }
}
    `;
export type GqlMarkPoAsHotMutationFn = Apollo.MutationFunction<GqlMarkPoAsHotMutation, GqlMarkPoAsHotMutationVariables>;

/**
 * __useMarkPoAsHotMutation__
 *
 * To run a mutation, you first call `useMarkPoAsHotMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMarkPoAsHotMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [markPoAsHotMutation, { data, loading, error }] = useMarkPoAsHotMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useMarkPoAsHotMutation(baseOptions?: Apollo.MutationHookOptions<GqlMarkPoAsHotMutation, GqlMarkPoAsHotMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<GqlMarkPoAsHotMutation, GqlMarkPoAsHotMutationVariables>(MarkPoAsHotDocument, options);
      }
export type MarkPoAsHotMutationHookResult = ReturnType<typeof useMarkPoAsHotMutation>;
export type MarkPoAsHotMutationOptions = Apollo.BaseMutationOptions<GqlMarkPoAsHotMutation, GqlMarkPoAsHotMutationVariables>;
export const PoImportFileDocument = gql`
    query PoImportFile($fileId: ID!) {
  poImportFile(poImportFileId: $fileId) {
    id
    network {
      id
    }
    rows
    ...PoImportFileStatus
  }
}
    ${PoImportFileStatusFragmentDoc}`;

/**
 * __usePoImportFileQuery__
 *
 * To run a query within a React component, call `usePoImportFileQuery` and pass it any options that fit your needs.
 * When your component renders, `usePoImportFileQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePoImportFileQuery({
 *   variables: {
 *      fileId: // value for 'fileId'
 *   },
 * });
 */
export function usePoImportFileQuery(baseOptions: Apollo.QueryHookOptions<GqlPoImportFileQuery, GqlPoImportFileQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GqlPoImportFileQuery, GqlPoImportFileQueryVariables>(PoImportFileDocument, options);
      }
export function usePoImportFileLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GqlPoImportFileQuery, GqlPoImportFileQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GqlPoImportFileQuery, GqlPoImportFileQueryVariables>(PoImportFileDocument, options);
        }
export type PoImportFileQueryHookResult = ReturnType<typeof usePoImportFileQuery>;
export type PoImportFileLazyQueryHookResult = ReturnType<typeof usePoImportFileLazyQuery>;
export const PoImportFieldGroupsDocument = gql`
    query PoImportFieldGroups {
  poImportFieldGroups {
    key
    name
    fields {
      key
      name
    }
  }
}
    `;

/**
 * __usePoImportFieldGroupsQuery__
 *
 * To run a query within a React component, call `usePoImportFieldGroupsQuery` and pass it any options that fit your needs.
 * When your component renders, `usePoImportFieldGroupsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePoImportFieldGroupsQuery({
 *   variables: {
 *   },
 * });
 */
export function usePoImportFieldGroupsQuery(baseOptions?: Apollo.QueryHookOptions<GqlPoImportFieldGroupsQuery, GqlPoImportFieldGroupsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GqlPoImportFieldGroupsQuery, GqlPoImportFieldGroupsQueryVariables>(PoImportFieldGroupsDocument, options);
      }
export function usePoImportFieldGroupsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GqlPoImportFieldGroupsQuery, GqlPoImportFieldGroupsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GqlPoImportFieldGroupsQuery, GqlPoImportFieldGroupsQueryVariables>(PoImportFieldGroupsDocument, options);
        }
export type PoImportFieldGroupsQueryHookResult = ReturnType<typeof usePoImportFieldGroupsQuery>;
export type PoImportFieldGroupsLazyQueryHookResult = ReturnType<typeof usePoImportFieldGroupsLazyQuery>;
export const PoImportFilesDocument = gql`
    query PoImportFiles {
  poImportFiles {
    id
    fileName
    importFileName
    extension
    totalPos
    fileStatus
    uploadDate
    network {
      name
      controllingClient {
        name
      }
    }
    uploadedBy {
      firstName
      lastName
    }
  }
}
    `;

/**
 * __usePoImportFilesQuery__
 *
 * To run a query within a React component, call `usePoImportFilesQuery` and pass it any options that fit your needs.
 * When your component renders, `usePoImportFilesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePoImportFilesQuery({
 *   variables: {
 *   },
 * });
 */
export function usePoImportFilesQuery(baseOptions?: Apollo.QueryHookOptions<GqlPoImportFilesQuery, GqlPoImportFilesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GqlPoImportFilesQuery, GqlPoImportFilesQueryVariables>(PoImportFilesDocument, options);
      }
export function usePoImportFilesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GqlPoImportFilesQuery, GqlPoImportFilesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GqlPoImportFilesQuery, GqlPoImportFilesQueryVariables>(PoImportFilesDocument, options);
        }
export type PoImportFilesQueryHookResult = ReturnType<typeof usePoImportFilesQuery>;
export type PoImportFilesLazyQueryHookResult = ReturnType<typeof usePoImportFilesLazyQuery>;
export const SetHeaderDocument = gql`
    mutation SetHeader($headerId: ID!, $omsKey: String) {
  setHeaderMapping(headerId: $headerId, omsKey: $omsKey) {
    ...PoImportFileStatus
  }
}
    ${PoImportFileStatusFragmentDoc}`;
export type GqlSetHeaderMutationFn = Apollo.MutationFunction<GqlSetHeaderMutation, GqlSetHeaderMutationVariables>;

/**
 * __useSetHeaderMutation__
 *
 * To run a mutation, you first call `useSetHeaderMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetHeaderMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setHeaderMutation, { data, loading, error }] = useSetHeaderMutation({
 *   variables: {
 *      headerId: // value for 'headerId'
 *      omsKey: // value for 'omsKey'
 *   },
 * });
 */
export function useSetHeaderMutation(baseOptions?: Apollo.MutationHookOptions<GqlSetHeaderMutation, GqlSetHeaderMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<GqlSetHeaderMutation, GqlSetHeaderMutationVariables>(SetHeaderDocument, options);
      }
export type SetHeaderMutationHookResult = ReturnType<typeof useSetHeaderMutation>;
export type SetHeaderMutationOptions = Apollo.BaseMutationOptions<GqlSetHeaderMutation, GqlSetHeaderMutationVariables>;
export const ConfirmImportDocument = gql`
    mutation ConfirmImport($fileId: ID!) {
  confirmImport(fileId: $fileId) {
    success
    message
  }
}
    `;
export type GqlConfirmImportMutationFn = Apollo.MutationFunction<GqlConfirmImportMutation, GqlConfirmImportMutationVariables>;

/**
 * __useConfirmImportMutation__
 *
 * To run a mutation, you first call `useConfirmImportMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useConfirmImportMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [confirmImportMutation, { data, loading, error }] = useConfirmImportMutation({
 *   variables: {
 *      fileId: // value for 'fileId'
 *   },
 * });
 */
export function useConfirmImportMutation(baseOptions?: Apollo.MutationHookOptions<GqlConfirmImportMutation, GqlConfirmImportMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<GqlConfirmImportMutation, GqlConfirmImportMutationVariables>(ConfirmImportDocument, options);
      }
export type ConfirmImportMutationHookResult = ReturnType<typeof useConfirmImportMutation>;
export type ConfirmImportMutationOptions = Apollo.BaseMutationOptions<GqlConfirmImportMutation, GqlConfirmImportMutationVariables>;
export const CancelImportDocument = gql`
    mutation CancelImport($fileId: ID!) {
  cancelImport(fileId: $fileId) {
    success
    message
  }
}
    `;
export type GqlCancelImportMutationFn = Apollo.MutationFunction<GqlCancelImportMutation, GqlCancelImportMutationVariables>;

/**
 * __useCancelImportMutation__
 *
 * To run a mutation, you first call `useCancelImportMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCancelImportMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [cancelImportMutation, { data, loading, error }] = useCancelImportMutation({
 *   variables: {
 *      fileId: // value for 'fileId'
 *   },
 * });
 */
export function useCancelImportMutation(baseOptions?: Apollo.MutationHookOptions<GqlCancelImportMutation, GqlCancelImportMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<GqlCancelImportMutation, GqlCancelImportMutationVariables>(CancelImportDocument, options);
      }
export type CancelImportMutationHookResult = ReturnType<typeof useCancelImportMutation>;
export type CancelImportMutationOptions = Apollo.BaseMutationOptions<GqlCancelImportMutation, GqlCancelImportMutationVariables>;
export const UploadPoFileMutationDocument = gql`
    mutation UploadPoFileMutation($file: Upload!, $networkId: ID!) {
  uploadPoFile(file: $file, networkId: $networkId) {
    id
  }
}
    `;
export type GqlUploadPoFileMutationMutationFn = Apollo.MutationFunction<GqlUploadPoFileMutationMutation, GqlUploadPoFileMutationMutationVariables>;

/**
 * __useUploadPoFileMutationMutation__
 *
 * To run a mutation, you first call `useUploadPoFileMutationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUploadPoFileMutationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [uploadPoFileMutationMutation, { data, loading, error }] = useUploadPoFileMutationMutation({
 *   variables: {
 *      file: // value for 'file'
 *      networkId: // value for 'networkId'
 *   },
 * });
 */
export function useUploadPoFileMutationMutation(baseOptions?: Apollo.MutationHookOptions<GqlUploadPoFileMutationMutation, GqlUploadPoFileMutationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<GqlUploadPoFileMutationMutation, GqlUploadPoFileMutationMutationVariables>(UploadPoFileMutationDocument, options);
      }
export type UploadPoFileMutationMutationHookResult = ReturnType<typeof useUploadPoFileMutationMutation>;
export type UploadPoFileMutationMutationOptions = Apollo.BaseMutationOptions<GqlUploadPoFileMutationMutation, GqlUploadPoFileMutationMutationVariables>;
export const SearchPortsDocument = gql`
    query SearchPorts($query: String!) {
  searchPorts(query: $query) {
    ...Port
  }
}
    ${PortFragmentDoc}`;

/**
 * __useSearchPortsQuery__
 *
 * To run a query within a React component, call `useSearchPortsQuery` and pass it any options that fit your needs.
 * When your component renders, `useSearchPortsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSearchPortsQuery({
 *   variables: {
 *      query: // value for 'query'
 *   },
 * });
 */
export function useSearchPortsQuery(baseOptions: Apollo.QueryHookOptions<GqlSearchPortsQuery, GqlSearchPortsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GqlSearchPortsQuery, GqlSearchPortsQueryVariables>(SearchPortsDocument, options);
      }
export function useSearchPortsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GqlSearchPortsQuery, GqlSearchPortsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GqlSearchPortsQuery, GqlSearchPortsQueryVariables>(SearchPortsDocument, options);
        }
export type SearchPortsQueryHookResult = ReturnType<typeof useSearchPortsQuery>;
export type SearchPortsLazyQueryHookResult = ReturnType<typeof useSearchPortsLazyQuery>;
export const ProfileDocument = gql`
    query Profile($profileId: ID!) {
  profile(profileId: $profileId) {
    id
    name
    profileCode
    nameAliases
    profileCodeAliases
    isControllingClient
    logo {
      id
      imageType
      fileName
      fileType
      fileSize
      uploadDate
      url
    }
    needsUpdate
    network {
      id
      name
      connectedProfiles {
        id
        name
        isControllingClient
      }
      profileTypes {
        id
        name
      }
    }
    profileType {
      id
      name
      permissionCodes
      notificationCodes
      dashboardFeatureCodes
    }
    locations {
      id
      name
      code
      nameAliases
      codeAliases
      addressLine1
      addressLine2
      city
      state
      postalCode
      relatedPort {
        code
        name
      }
      country
      companyPhone
      companyUrl
      needsUpdate
      locationTypes
    }
    contacts {
      id
      firstName
      lastName
      title
      email
      phoneMain
      extension
      phoneAlternative
      phoneCell
      extension
      isUserEnabled
      notificationFrequency
      location {
        id
        name
      }
      role {
        id
        name
      }
      isMainContact
      needsUpdate
    }
    relatedProfiles {
      id
      partyType
      profile {
        id
        name
        profileCode
        profileType {
          id
          name
        }
        roles {
          id
        }
        locations {
          id
          name
        }
        contacts {
          id
          firstName
          lastName
        }
        relatedProfiles {
          id
        }
        network {
          name
        }
      }
    }
    roles {
      id
      name
      description
      isDefaultRole
      permissionCodes
      notificationCodes
      dashboardFeatureCodes
      documentTypesCanView
    }
  }
}
    `;

/**
 * __useProfileQuery__
 *
 * To run a query within a React component, call `useProfileQuery` and pass it any options that fit your needs.
 * When your component renders, `useProfileQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProfileQuery({
 *   variables: {
 *      profileId: // value for 'profileId'
 *   },
 * });
 */
export function useProfileQuery(baseOptions: Apollo.QueryHookOptions<GqlProfileQuery, GqlProfileQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GqlProfileQuery, GqlProfileQueryVariables>(ProfileDocument, options);
      }
export function useProfileLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GqlProfileQuery, GqlProfileQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GqlProfileQuery, GqlProfileQueryVariables>(ProfileDocument, options);
        }
export type ProfileQueryHookResult = ReturnType<typeof useProfileQuery>;
export type ProfileLazyQueryHookResult = ReturnType<typeof useProfileLazyQuery>;
export const ProfilesDocument = gql`
    query Profiles {
  profiles {
    id
    name
    profileCode
    profileType {
      name
    }
    logo {
      url
    }
    needsUpdate
    locations {
      id
      name
    }
    contacts {
      id
      firstName
      lastName
    }
    roles {
      id
      name
    }
    network {
      name
    }
  }
}
    `;

/**
 * __useProfilesQuery__
 *
 * To run a query within a React component, call `useProfilesQuery` and pass it any options that fit your needs.
 * When your component renders, `useProfilesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProfilesQuery({
 *   variables: {
 *   },
 * });
 */
export function useProfilesQuery(baseOptions?: Apollo.QueryHookOptions<GqlProfilesQuery, GqlProfilesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GqlProfilesQuery, GqlProfilesQueryVariables>(ProfilesDocument, options);
      }
export function useProfilesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GqlProfilesQuery, GqlProfilesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GqlProfilesQuery, GqlProfilesQueryVariables>(ProfilesDocument, options);
        }
export type ProfilesQueryHookResult = ReturnType<typeof useProfilesQuery>;
export type ProfilesLazyQueryHookResult = ReturnType<typeof useProfilesLazyQuery>;
export const NewProfileContactDocument = gql`
    mutation NewProfileContact($input: NewProfileContactInput!) {
  newProfileContact(input: $input) {
    success
  }
}
    `;
export type GqlNewProfileContactMutationFn = Apollo.MutationFunction<GqlNewProfileContactMutation, GqlNewProfileContactMutationVariables>;

/**
 * __useNewProfileContactMutation__
 *
 * To run a mutation, you first call `useNewProfileContactMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useNewProfileContactMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [newProfileContactMutation, { data, loading, error }] = useNewProfileContactMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useNewProfileContactMutation(baseOptions?: Apollo.MutationHookOptions<GqlNewProfileContactMutation, GqlNewProfileContactMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<GqlNewProfileContactMutation, GqlNewProfileContactMutationVariables>(NewProfileContactDocument, options);
      }
export type NewProfileContactMutationHookResult = ReturnType<typeof useNewProfileContactMutation>;
export type NewProfileContactMutationOptions = Apollo.BaseMutationOptions<GqlNewProfileContactMutation, GqlNewProfileContactMutationVariables>;
export const NewProfileRoleDocument = gql`
    mutation NewProfileRole($input: NewProfileRoleInput!) {
  newProfileRole(input: $input) {
    success
  }
}
    `;
export type GqlNewProfileRoleMutationFn = Apollo.MutationFunction<GqlNewProfileRoleMutation, GqlNewProfileRoleMutationVariables>;

/**
 * __useNewProfileRoleMutation__
 *
 * To run a mutation, you first call `useNewProfileRoleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useNewProfileRoleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [newProfileRoleMutation, { data, loading, error }] = useNewProfileRoleMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useNewProfileRoleMutation(baseOptions?: Apollo.MutationHookOptions<GqlNewProfileRoleMutation, GqlNewProfileRoleMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<GqlNewProfileRoleMutation, GqlNewProfileRoleMutationVariables>(NewProfileRoleDocument, options);
      }
export type NewProfileRoleMutationHookResult = ReturnType<typeof useNewProfileRoleMutation>;
export type NewProfileRoleMutationOptions = Apollo.BaseMutationOptions<GqlNewProfileRoleMutation, GqlNewProfileRoleMutationVariables>;
export const NewProfileRelationDocument = gql`
    mutation NewProfileRelation($input: NewProfileRelationInput!) {
  newProfileRelation(input: $input) {
    success
  }
}
    `;
export type GqlNewProfileRelationMutationFn = Apollo.MutationFunction<GqlNewProfileRelationMutation, GqlNewProfileRelationMutationVariables>;

/**
 * __useNewProfileRelationMutation__
 *
 * To run a mutation, you first call `useNewProfileRelationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useNewProfileRelationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [newProfileRelationMutation, { data, loading, error }] = useNewProfileRelationMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useNewProfileRelationMutation(baseOptions?: Apollo.MutationHookOptions<GqlNewProfileRelationMutation, GqlNewProfileRelationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<GqlNewProfileRelationMutation, GqlNewProfileRelationMutationVariables>(NewProfileRelationDocument, options);
      }
export type NewProfileRelationMutationHookResult = ReturnType<typeof useNewProfileRelationMutation>;
export type NewProfileRelationMutationOptions = Apollo.BaseMutationOptions<GqlNewProfileRelationMutation, GqlNewProfileRelationMutationVariables>;
export const UpdateProfileRelationDocument = gql`
    mutation UpdateProfileRelation($input: UpdateProfileRelationInput!) {
  updateProfileRelation(input: $input) {
    success
  }
}
    `;
export type GqlUpdateProfileRelationMutationFn = Apollo.MutationFunction<GqlUpdateProfileRelationMutation, GqlUpdateProfileRelationMutationVariables>;

/**
 * __useUpdateProfileRelationMutation__
 *
 * To run a mutation, you first call `useUpdateProfileRelationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateProfileRelationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateProfileRelationMutation, { data, loading, error }] = useUpdateProfileRelationMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateProfileRelationMutation(baseOptions?: Apollo.MutationHookOptions<GqlUpdateProfileRelationMutation, GqlUpdateProfileRelationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<GqlUpdateProfileRelationMutation, GqlUpdateProfileRelationMutationVariables>(UpdateProfileRelationDocument, options);
      }
export type UpdateProfileRelationMutationHookResult = ReturnType<typeof useUpdateProfileRelationMutation>;
export type UpdateProfileRelationMutationOptions = Apollo.BaseMutationOptions<GqlUpdateProfileRelationMutation, GqlUpdateProfileRelationMutationVariables>;
export const UpdateProfileDocument = gql`
    mutation UpdateProfile($input: UpdateProfileInput!) {
  updateProfile(input: $input) {
    success
    message
  }
}
    `;
export type GqlUpdateProfileMutationFn = Apollo.MutationFunction<GqlUpdateProfileMutation, GqlUpdateProfileMutationVariables>;

/**
 * __useUpdateProfileMutation__
 *
 * To run a mutation, you first call `useUpdateProfileMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateProfileMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateProfileMutation, { data, loading, error }] = useUpdateProfileMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateProfileMutation(baseOptions?: Apollo.MutationHookOptions<GqlUpdateProfileMutation, GqlUpdateProfileMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<GqlUpdateProfileMutation, GqlUpdateProfileMutationVariables>(UpdateProfileDocument, options);
      }
export type UpdateProfileMutationHookResult = ReturnType<typeof useUpdateProfileMutation>;
export type UpdateProfileMutationOptions = Apollo.BaseMutationOptions<GqlUpdateProfileMutation, GqlUpdateProfileMutationVariables>;
export const RemoveProfileRelationDocument = gql`
    mutation RemoveProfileRelation($input: RemoveProfileRelationInput!) {
  removeProfileRelation(input: $input) {
    success
  }
}
    `;
export type GqlRemoveProfileRelationMutationFn = Apollo.MutationFunction<GqlRemoveProfileRelationMutation, GqlRemoveProfileRelationMutationVariables>;

/**
 * __useRemoveProfileRelationMutation__
 *
 * To run a mutation, you first call `useRemoveProfileRelationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveProfileRelationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeProfileRelationMutation, { data, loading, error }] = useRemoveProfileRelationMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRemoveProfileRelationMutation(baseOptions?: Apollo.MutationHookOptions<GqlRemoveProfileRelationMutation, GqlRemoveProfileRelationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<GqlRemoveProfileRelationMutation, GqlRemoveProfileRelationMutationVariables>(RemoveProfileRelationDocument, options);
      }
export type RemoveProfileRelationMutationHookResult = ReturnType<typeof useRemoveProfileRelationMutation>;
export type RemoveProfileRelationMutationOptions = Apollo.BaseMutationOptions<GqlRemoveProfileRelationMutation, GqlRemoveProfileRelationMutationVariables>;
export const MergeProfileDocument = gql`
    mutation MergeProfile($input: MergeProfileInput!) {
  mergeProfile(input: $input) {
    success
    message
  }
}
    `;
export type GqlMergeProfileMutationFn = Apollo.MutationFunction<GqlMergeProfileMutation, GqlMergeProfileMutationVariables>;

/**
 * __useMergeProfileMutation__
 *
 * To run a mutation, you first call `useMergeProfileMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMergeProfileMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [mergeProfileMutation, { data, loading, error }] = useMergeProfileMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useMergeProfileMutation(baseOptions?: Apollo.MutationHookOptions<GqlMergeProfileMutation, GqlMergeProfileMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<GqlMergeProfileMutation, GqlMergeProfileMutationVariables>(MergeProfileDocument, options);
      }
export type MergeProfileMutationHookResult = ReturnType<typeof useMergeProfileMutation>;
export type MergeProfileMutationOptions = Apollo.BaseMutationOptions<GqlMergeProfileMutation, GqlMergeProfileMutationVariables>;
export const NetworkProfileTypeDocument = gql`
    query NetworkProfileType($networkId: ID!) {
  network(networkId: $networkId) {
    profileTypes {
      id
      name
    }
  }
}
    `;

/**
 * __useNetworkProfileTypeQuery__
 *
 * To run a query within a React component, call `useNetworkProfileTypeQuery` and pass it any options that fit your needs.
 * When your component renders, `useNetworkProfileTypeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useNetworkProfileTypeQuery({
 *   variables: {
 *      networkId: // value for 'networkId'
 *   },
 * });
 */
export function useNetworkProfileTypeQuery(baseOptions: Apollo.QueryHookOptions<GqlNetworkProfileTypeQuery, GqlNetworkProfileTypeQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GqlNetworkProfileTypeQuery, GqlNetworkProfileTypeQueryVariables>(NetworkProfileTypeDocument, options);
      }
export function useNetworkProfileTypeLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GqlNetworkProfileTypeQuery, GqlNetworkProfileTypeQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GqlNetworkProfileTypeQuery, GqlNetworkProfileTypeQueryVariables>(NetworkProfileTypeDocument, options);
        }
export type NetworkProfileTypeQueryHookResult = ReturnType<typeof useNetworkProfileTypeQuery>;
export type NetworkProfileTypeLazyQueryHookResult = ReturnType<typeof useNetworkProfileTypeLazyQuery>;
export const ProfileTypeTemplatesDocument = gql`
    query ProfileTypeTemplates {
  profileTypeTemplates {
    id
    name
    description
    isTemplate
    defaultRoles {
      id
      name
      description
      permissionCodes
      notificationCodes
      dashboardFeatureCodes
    }
    permissionCodes
    notificationCodes
    dashboardFeatureCodes
  }
}
    `;

/**
 * __useProfileTypeTemplatesQuery__
 *
 * To run a query within a React component, call `useProfileTypeTemplatesQuery` and pass it any options that fit your needs.
 * When your component renders, `useProfileTypeTemplatesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProfileTypeTemplatesQuery({
 *   variables: {
 *   },
 * });
 */
export function useProfileTypeTemplatesQuery(baseOptions?: Apollo.QueryHookOptions<GqlProfileTypeTemplatesQuery, GqlProfileTypeTemplatesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GqlProfileTypeTemplatesQuery, GqlProfileTypeTemplatesQueryVariables>(ProfileTypeTemplatesDocument, options);
      }
export function useProfileTypeTemplatesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GqlProfileTypeTemplatesQuery, GqlProfileTypeTemplatesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GqlProfileTypeTemplatesQuery, GqlProfileTypeTemplatesQueryVariables>(ProfileTypeTemplatesDocument, options);
        }
export type ProfileTypeTemplatesQueryHookResult = ReturnType<typeof useProfileTypeTemplatesQuery>;
export type ProfileTypeTemplatesLazyQueryHookResult = ReturnType<typeof useProfileTypeTemplatesLazyQuery>;
export const ProfileTypeDocument = gql`
    query ProfileType($profileTypeId: ID!) {
  profileType(profileTypeId: $profileTypeId) {
    id
    name
    description
    isTemplate
    defaultRoles {
      id
      name
      description
      isDefaultRole
      permissionCodes
      notificationCodes
      dashboardFeatureCodes
      documentTypesCanView
    }
    permissionCodes
    notificationCodes
    dashboardFeatureCodes
  }
}
    `;

/**
 * __useProfileTypeQuery__
 *
 * To run a query within a React component, call `useProfileTypeQuery` and pass it any options that fit your needs.
 * When your component renders, `useProfileTypeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProfileTypeQuery({
 *   variables: {
 *      profileTypeId: // value for 'profileTypeId'
 *   },
 * });
 */
export function useProfileTypeQuery(baseOptions: Apollo.QueryHookOptions<GqlProfileTypeQuery, GqlProfileTypeQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GqlProfileTypeQuery, GqlProfileTypeQueryVariables>(ProfileTypeDocument, options);
      }
export function useProfileTypeLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GqlProfileTypeQuery, GqlProfileTypeQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GqlProfileTypeQuery, GqlProfileTypeQueryVariables>(ProfileTypeDocument, options);
        }
export type ProfileTypeQueryHookResult = ReturnType<typeof useProfileTypeQuery>;
export type ProfileTypeLazyQueryHookResult = ReturnType<typeof useProfileTypeLazyQuery>;
export const NewProfileTypeDocument = gql`
    mutation NewProfileType($input: NewProfileTypeTemplateInput!) {
  newProfileTypeTemplate(input: $input) {
    success
  }
}
    `;
export type GqlNewProfileTypeMutationFn = Apollo.MutationFunction<GqlNewProfileTypeMutation, GqlNewProfileTypeMutationVariables>;

/**
 * __useNewProfileTypeMutation__
 *
 * To run a mutation, you first call `useNewProfileTypeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useNewProfileTypeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [newProfileTypeMutation, { data, loading, error }] = useNewProfileTypeMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useNewProfileTypeMutation(baseOptions?: Apollo.MutationHookOptions<GqlNewProfileTypeMutation, GqlNewProfileTypeMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<GqlNewProfileTypeMutation, GqlNewProfileTypeMutationVariables>(NewProfileTypeDocument, options);
      }
export type NewProfileTypeMutationHookResult = ReturnType<typeof useNewProfileTypeMutation>;
export type NewProfileTypeMutationOptions = Apollo.BaseMutationOptions<GqlNewProfileTypeMutation, GqlNewProfileTypeMutationVariables>;
export const ProfileTypeUpdateDocument = gql`
    mutation ProfileTypeUpdate($input: UpdateProfileTypeInput!) {
  updateProfileType(input: $input) {
    success
  }
}
    `;
export type GqlProfileTypeUpdateMutationFn = Apollo.MutationFunction<GqlProfileTypeUpdateMutation, GqlProfileTypeUpdateMutationVariables>;

/**
 * __useProfileTypeUpdateMutation__
 *
 * To run a mutation, you first call `useProfileTypeUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useProfileTypeUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [profileTypeUpdateMutation, { data, loading, error }] = useProfileTypeUpdateMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useProfileTypeUpdateMutation(baseOptions?: Apollo.MutationHookOptions<GqlProfileTypeUpdateMutation, GqlProfileTypeUpdateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<GqlProfileTypeUpdateMutation, GqlProfileTypeUpdateMutationVariables>(ProfileTypeUpdateDocument, options);
      }
export type ProfileTypeUpdateMutationHookResult = ReturnType<typeof useProfileTypeUpdateMutation>;
export type ProfileTypeUpdateMutationOptions = Apollo.BaseMutationOptions<GqlProfileTypeUpdateMutation, GqlProfileTypeUpdateMutationVariables>;
export const ProfileTypeDeleteDocument = gql`
    mutation ProfileTypeDelete($input: DeleteProfileTypeInput!) {
  deleteProfileType(input: $input) {
    success
    message
  }
}
    `;
export type GqlProfileTypeDeleteMutationFn = Apollo.MutationFunction<GqlProfileTypeDeleteMutation, GqlProfileTypeDeleteMutationVariables>;

/**
 * __useProfileTypeDeleteMutation__
 *
 * To run a mutation, you first call `useProfileTypeDeleteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useProfileTypeDeleteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [profileTypeDeleteMutation, { data, loading, error }] = useProfileTypeDeleteMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useProfileTypeDeleteMutation(baseOptions?: Apollo.MutationHookOptions<GqlProfileTypeDeleteMutation, GqlProfileTypeDeleteMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<GqlProfileTypeDeleteMutation, GqlProfileTypeDeleteMutationVariables>(ProfileTypeDeleteDocument, options);
      }
export type ProfileTypeDeleteMutationHookResult = ReturnType<typeof useProfileTypeDeleteMutation>;
export type ProfileTypeDeleteMutationOptions = Apollo.BaseMutationOptions<GqlProfileTypeDeleteMutation, GqlProfileTypeDeleteMutationVariables>;
export const NewProfileTypeDefaultRoleDocument = gql`
    mutation NewProfileTypeDefaultRole($input: NewProfileTypeDefaultRoleInput!) {
  newProfileTypeDefaultRole(input: $input) {
    success
  }
}
    `;
export type GqlNewProfileTypeDefaultRoleMutationFn = Apollo.MutationFunction<GqlNewProfileTypeDefaultRoleMutation, GqlNewProfileTypeDefaultRoleMutationVariables>;

/**
 * __useNewProfileTypeDefaultRoleMutation__
 *
 * To run a mutation, you first call `useNewProfileTypeDefaultRoleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useNewProfileTypeDefaultRoleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [newProfileTypeDefaultRoleMutation, { data, loading, error }] = useNewProfileTypeDefaultRoleMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useNewProfileTypeDefaultRoleMutation(baseOptions?: Apollo.MutationHookOptions<GqlNewProfileTypeDefaultRoleMutation, GqlNewProfileTypeDefaultRoleMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<GqlNewProfileTypeDefaultRoleMutation, GqlNewProfileTypeDefaultRoleMutationVariables>(NewProfileTypeDefaultRoleDocument, options);
      }
export type NewProfileTypeDefaultRoleMutationHookResult = ReturnType<typeof useNewProfileTypeDefaultRoleMutation>;
export type NewProfileTypeDefaultRoleMutationOptions = Apollo.BaseMutationOptions<GqlNewProfileTypeDefaultRoleMutation, GqlNewProfileTypeDefaultRoleMutationVariables>;
export const QuickSearchDocument = gql`
    query QuickSearch($searchType: SearchType!, $searchId: ID!) {
  quickSearch(searchType: $searchType, searchId: $searchId) {
    searchType
    searchTerm
    poCount
    purchaseOrders {
      id
      orderLineId
      poNumber
      poDate
      supplierName
      itemNumber
      cargoReadyDate
      firstShipDate
      lastShipDate
      shipToLocationName
    }
    containerCount
    containers {
      containerId
      orderLineId
      containerNumber
      railRamp
      railRampEta
      railRampAta
      deliveryLocation
      finalEta
      finalAta
    }
    bookingCount
    bookings {
      shipmentId
      orderLineId
      referenceNumber
      mblId
      mblNumber
      cargoReadyDate
      shipToLocationName
      carrierName
      polCode
      polEtd
      polAtd
      podCode
      podEta
      podAta
    }
    shipmentCount
    shipments {
      shipmentId
      orderLineId
      referenceNumber
      mblId
      mblNumber
      cargoReadyDate
      shipToLocationName
      carrierName
      polCode
      polEtd
      polAtd
      podCode
      podEta
      podAta
    }
  }
}
    `;

/**
 * __useQuickSearchQuery__
 *
 * To run a query within a React component, call `useQuickSearchQuery` and pass it any options that fit your needs.
 * When your component renders, `useQuickSearchQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useQuickSearchQuery({
 *   variables: {
 *      searchType: // value for 'searchType'
 *      searchId: // value for 'searchId'
 *   },
 * });
 */
export function useQuickSearchQuery(baseOptions: Apollo.QueryHookOptions<GqlQuickSearchQuery, GqlQuickSearchQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GqlQuickSearchQuery, GqlQuickSearchQueryVariables>(QuickSearchDocument, options);
      }
export function useQuickSearchLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GqlQuickSearchQuery, GqlQuickSearchQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GqlQuickSearchQuery, GqlQuickSearchQueryVariables>(QuickSearchDocument, options);
        }
export type QuickSearchQueryHookResult = ReturnType<typeof useQuickSearchQuery>;
export type QuickSearchLazyQueryHookResult = ReturnType<typeof useQuickSearchLazyQuery>;
export const ApiConstantsDocument = gql`
    query ApiConstants {
  notificationCodes {
    code
    group
    name
    description
  }
  permissions {
    code
    group
    name
    description
  }
  systemPermissions {
    code
    group
    name
    description
  }
  locationTypes {
    code
    group
    name
    description
  }
  ruleFieldTypes {
    code
    group
    name
    description
  }
  dashboardFeatureCodes {
    code
    group
    name
    description
  }
  network {
    containerTypes {
      code
      name
      capacityVolumeM3
      capacityWeightKg
      ruleMinVolumeM3
      ruleMaxVolumeM3
      ruleMinWeightKg
      ruleMaxWeightKg
    }
    documentTypes {
      code
      name
    }
    rules {
      bookingQtyToleranceEnabled
      bookingQtyTolerancePercentOver
      bookingQtyTolerancePercentUnder
    }
  }
  datasets {
    ...fragment_Dataset
  }
}
    ${Fragment_DatasetFragmentDoc}`;

/**
 * __useApiConstantsQuery__
 *
 * To run a query within a React component, call `useApiConstantsQuery` and pass it any options that fit your needs.
 * When your component renders, `useApiConstantsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useApiConstantsQuery({
 *   variables: {
 *   },
 * });
 */
export function useApiConstantsQuery(baseOptions?: Apollo.QueryHookOptions<GqlApiConstantsQuery, GqlApiConstantsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GqlApiConstantsQuery, GqlApiConstantsQueryVariables>(ApiConstantsDocument, options);
      }
export function useApiConstantsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GqlApiConstantsQuery, GqlApiConstantsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GqlApiConstantsQuery, GqlApiConstantsQueryVariables>(ApiConstantsDocument, options);
        }
export type ApiConstantsQueryHookResult = ReturnType<typeof useApiConstantsQuery>;
export type ApiConstantsLazyQueryHookResult = ReturnType<typeof useApiConstantsLazyQuery>;
export const ReportsDocument = gql`
    query Reports {
  reports {
    ...fragment_Report
  }
}
    ${Fragment_ReportFragmentDoc}`;

/**
 * __useReportsQuery__
 *
 * To run a query within a React component, call `useReportsQuery` and pass it any options that fit your needs.
 * When your component renders, `useReportsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useReportsQuery({
 *   variables: {
 *   },
 * });
 */
export function useReportsQuery(baseOptions?: Apollo.QueryHookOptions<GqlReportsQuery, GqlReportsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GqlReportsQuery, GqlReportsQueryVariables>(ReportsDocument, options);
      }
export function useReportsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GqlReportsQuery, GqlReportsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GqlReportsQuery, GqlReportsQueryVariables>(ReportsDocument, options);
        }
export type ReportsQueryHookResult = ReturnType<typeof useReportsQuery>;
export type ReportsLazyQueryHookResult = ReturnType<typeof useReportsLazyQuery>;
export const ReportDocument = gql`
    query Report($id: ID!) {
  report(id: $id) {
    ...fragment_Report
  }
}
    ${Fragment_ReportFragmentDoc}`;

/**
 * __useReportQuery__
 *
 * To run a query within a React component, call `useReportQuery` and pass it any options that fit your needs.
 * When your component renders, `useReportQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useReportQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useReportQuery(baseOptions: Apollo.QueryHookOptions<GqlReportQuery, GqlReportQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GqlReportQuery, GqlReportQueryVariables>(ReportDocument, options);
      }
export function useReportLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GqlReportQuery, GqlReportQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GqlReportQuery, GqlReportQueryVariables>(ReportDocument, options);
        }
export type ReportQueryHookResult = ReturnType<typeof useReportQuery>;
export type ReportLazyQueryHookResult = ReturnType<typeof useReportLazyQuery>;
export const DeleteReportDocument = gql`
    mutation deleteReport($id: ID!) {
  deleteReport(id: $id)
}
    `;
export type GqlDeleteReportMutationFn = Apollo.MutationFunction<GqlDeleteReportMutation, GqlDeleteReportMutationVariables>;

/**
 * __useDeleteReportMutation__
 *
 * To run a mutation, you first call `useDeleteReportMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteReportMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteReportMutation, { data, loading, error }] = useDeleteReportMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteReportMutation(baseOptions?: Apollo.MutationHookOptions<GqlDeleteReportMutation, GqlDeleteReportMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<GqlDeleteReportMutation, GqlDeleteReportMutationVariables>(DeleteReportDocument, options);
      }
export type DeleteReportMutationHookResult = ReturnType<typeof useDeleteReportMutation>;
export type DeleteReportMutationOptions = Apollo.BaseMutationOptions<GqlDeleteReportMutation, GqlDeleteReportMutationVariables>;
export const PutReportDocument = gql`
    mutation putReport($report: ReportInput!) {
  putReport(report: $report) {
    ...fragment_Report
  }
}
    ${Fragment_ReportFragmentDoc}`;
export type GqlPutReportMutationFn = Apollo.MutationFunction<GqlPutReportMutation, GqlPutReportMutationVariables>;

/**
 * __usePutReportMutation__
 *
 * To run a mutation, you first call `usePutReportMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePutReportMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [putReportMutation, { data, loading, error }] = usePutReportMutation({
 *   variables: {
 *      report: // value for 'report'
 *   },
 * });
 */
export function usePutReportMutation(baseOptions?: Apollo.MutationHookOptions<GqlPutReportMutation, GqlPutReportMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<GqlPutReportMutation, GqlPutReportMutationVariables>(PutReportDocument, options);
      }
export type PutReportMutationHookResult = ReturnType<typeof usePutReportMutation>;
export type PutReportMutationOptions = Apollo.BaseMutationOptions<GqlPutReportMutation, GqlPutReportMutationVariables>;
export const SetReportScheduledDocument = gql`
    mutation setReportScheduled($id: ID!, $scheduled: Boolean!) {
  setReportScheduled(id: $id, scheduled: $scheduled) {
    ...fragment_Report
  }
}
    ${Fragment_ReportFragmentDoc}`;
export type GqlSetReportScheduledMutationFn = Apollo.MutationFunction<GqlSetReportScheduledMutation, GqlSetReportScheduledMutationVariables>;

/**
 * __useSetReportScheduledMutation__
 *
 * To run a mutation, you first call `useSetReportScheduledMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetReportScheduledMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setReportScheduledMutation, { data, loading, error }] = useSetReportScheduledMutation({
 *   variables: {
 *      id: // value for 'id'
 *      scheduled: // value for 'scheduled'
 *   },
 * });
 */
export function useSetReportScheduledMutation(baseOptions?: Apollo.MutationHookOptions<GqlSetReportScheduledMutation, GqlSetReportScheduledMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<GqlSetReportScheduledMutation, GqlSetReportScheduledMutationVariables>(SetReportScheduledDocument, options);
      }
export type SetReportScheduledMutationHookResult = ReturnType<typeof useSetReportScheduledMutation>;
export type SetReportScheduledMutationOptions = Apollo.BaseMutationOptions<GqlSetReportScheduledMutation, GqlSetReportScheduledMutationVariables>;
export const UpdateRoleDocument = gql`
    mutation UpdateRole($input: UpdateRoleInput!) {
  updateRole(input: $input) {
    success
    message
  }
}
    `;
export type GqlUpdateRoleMutationFn = Apollo.MutationFunction<GqlUpdateRoleMutation, GqlUpdateRoleMutationVariables>;

/**
 * __useUpdateRoleMutation__
 *
 * To run a mutation, you first call `useUpdateRoleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateRoleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateRoleMutation, { data, loading, error }] = useUpdateRoleMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateRoleMutation(baseOptions?: Apollo.MutationHookOptions<GqlUpdateRoleMutation, GqlUpdateRoleMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<GqlUpdateRoleMutation, GqlUpdateRoleMutationVariables>(UpdateRoleDocument, options);
      }
export type UpdateRoleMutationHookResult = ReturnType<typeof useUpdateRoleMutation>;
export type UpdateRoleMutationOptions = Apollo.BaseMutationOptions<GqlUpdateRoleMutation, GqlUpdateRoleMutationVariables>;
export const DeleteRoleDocument = gql`
    mutation DeleteRole($input: DeleteRoleInput!) {
  deleteRole(input: $input) {
    success
    message
  }
}
    `;
export type GqlDeleteRoleMutationFn = Apollo.MutationFunction<GqlDeleteRoleMutation, GqlDeleteRoleMutationVariables>;

/**
 * __useDeleteRoleMutation__
 *
 * To run a mutation, you first call `useDeleteRoleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteRoleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteRoleMutation, { data, loading, error }] = useDeleteRoleMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteRoleMutation(baseOptions?: Apollo.MutationHookOptions<GqlDeleteRoleMutation, GqlDeleteRoleMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<GqlDeleteRoleMutation, GqlDeleteRoleMutationVariables>(DeleteRoleDocument, options);
      }
export type DeleteRoleMutationHookResult = ReturnType<typeof useDeleteRoleMutation>;
export type DeleteRoleMutationOptions = Apollo.BaseMutationOptions<GqlDeleteRoleMutation, GqlDeleteRoleMutationVariables>;
export const UserContextDocument = gql`
    query UserContext {
  userContext {
    user {
      firstName
      lastName
      profileContacts {
        id
        profile {
          id
          name
          network {
            id
            name
            controllingClient {
              id
              name
            }
          }
        }
        role {
          id
          name
          permissionCodes
        }
      }
      systemPermissionCodes
    }
    activeContact {
      id
      profile {
        id
        name
        network {
          id
          name
        }
      }
      role {
        id
        name
        permissionCodes
        dashboardFeatureCodes
      }
    }
  }
}
    `;

/**
 * __useUserContextQuery__
 *
 * To run a query within a React component, call `useUserContextQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserContextQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserContextQuery({
 *   variables: {
 *   },
 * });
 */
export function useUserContextQuery(baseOptions?: Apollo.QueryHookOptions<GqlUserContextQuery, GqlUserContextQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GqlUserContextQuery, GqlUserContextQueryVariables>(UserContextDocument, options);
      }
export function useUserContextLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GqlUserContextQuery, GqlUserContextQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GqlUserContextQuery, GqlUserContextQueryVariables>(UserContextDocument, options);
        }
export type UserContextQueryHookResult = ReturnType<typeof useUserContextQuery>;
export type UserContextLazyQueryHookResult = ReturnType<typeof useUserContextLazyQuery>;
export const AllUsersDocument = gql`
    query AllUsers {
  users {
    id
    fullName
    title
    isActive
    defaultContact {
      id
      isUserEnabled
      profile {
        id
        name
        network {
          id
          name
        }
      }
    }
    profileContacts {
      id
    }
  }
}
    `;

/**
 * __useAllUsersQuery__
 *
 * To run a query within a React component, call `useAllUsersQuery` and pass it any options that fit your needs.
 * When your component renders, `useAllUsersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAllUsersQuery({
 *   variables: {
 *   },
 * });
 */
export function useAllUsersQuery(baseOptions?: Apollo.QueryHookOptions<GqlAllUsersQuery, GqlAllUsersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GqlAllUsersQuery, GqlAllUsersQueryVariables>(AllUsersDocument, options);
      }
export function useAllUsersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GqlAllUsersQuery, GqlAllUsersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GqlAllUsersQuery, GqlAllUsersQueryVariables>(AllUsersDocument, options);
        }
export type AllUsersQueryHookResult = ReturnType<typeof useAllUsersQuery>;
export type AllUsersLazyQueryHookResult = ReturnType<typeof useAllUsersLazyQuery>;
export const UserDocument = gql`
    query User($userId: ID!) {
  user(userId: $userId) {
    id
    fullName
    firstName
    lastName
    title
    email
    phoneMain
    extension
    phoneCell
    phoneAlternative
    isActive
    systemPermissionCodes
    defaultContact {
      id
      firstName
      lastName
      title
      email
      phoneMain
      phoneAlternative
      extension
      phoneCell
      isMainContact
      isUserEnabled
      profile {
        id
        name
        network {
          id
          name
        }
      }
      location {
        id
        name
      }
    }
    profileContacts {
      id
      firstName
      lastName
      title
      email
      phoneMain
      extension
      phoneCell
      phoneAlternative
      isMainContact
      isUserEnabled
      notificationFrequency
      profile {
        id
        name
        roles {
          id
          name
        }
        network {
          id
          name
          connectedProfiles {
            id
            name
            profileCode
          }
        }
      }
      location {
        id
        name
      }
      role {
        id
        name
      }
    }
  }
}
    `;

/**
 * __useUserQuery__
 *
 * To run a query within a React component, call `useUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserQuery({
 *   variables: {
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useUserQuery(baseOptions: Apollo.QueryHookOptions<GqlUserQuery, GqlUserQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GqlUserQuery, GqlUserQueryVariables>(UserDocument, options);
      }
export function useUserLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GqlUserQuery, GqlUserQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GqlUserQuery, GqlUserQueryVariables>(UserDocument, options);
        }
export type UserQueryHookResult = ReturnType<typeof useUserQuery>;
export type UserLazyQueryHookResult = ReturnType<typeof useUserLazyQuery>;
export const UpdateUserDocument = gql`
    mutation UpdateUser($input: UpdateUserInput!) {
  updateUser(input: $input) {
    success
    message
    user {
      id
      defaultContact {
        id
        firstName
        lastName
        profile {
          id
          name
        }
      }
    }
  }
}
    `;
export type GqlUpdateUserMutationFn = Apollo.MutationFunction<GqlUpdateUserMutation, GqlUpdateUserMutationVariables>;

/**
 * __useUpdateUserMutation__
 *
 * To run a mutation, you first call `useUpdateUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateUserMutation, { data, loading, error }] = useUpdateUserMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateUserMutation(baseOptions?: Apollo.MutationHookOptions<GqlUpdateUserMutation, GqlUpdateUserMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<GqlUpdateUserMutation, GqlUpdateUserMutationVariables>(UpdateUserDocument, options);
      }
export type UpdateUserMutationHookResult = ReturnType<typeof useUpdateUserMutation>;
export type UpdateUserMutationOptions = Apollo.BaseMutationOptions<GqlUpdateUserMutation, GqlUpdateUserMutationVariables>;
export const SetActiveUserContactDocument = gql`
    mutation SetActiveUserContact($input: SetActiveUserContactInput!) {
  setActiveUserContact(input: $input) {
    success
  }
}
    `;
export type GqlSetActiveUserContactMutationFn = Apollo.MutationFunction<GqlSetActiveUserContactMutation, GqlSetActiveUserContactMutationVariables>;

/**
 * __useSetActiveUserContactMutation__
 *
 * To run a mutation, you first call `useSetActiveUserContactMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetActiveUserContactMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setActiveUserContactMutation, { data, loading, error }] = useSetActiveUserContactMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSetActiveUserContactMutation(baseOptions?: Apollo.MutationHookOptions<GqlSetActiveUserContactMutation, GqlSetActiveUserContactMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<GqlSetActiveUserContactMutation, GqlSetActiveUserContactMutationVariables>(SetActiveUserContactDocument, options);
      }
export type SetActiveUserContactMutationHookResult = ReturnType<typeof useSetActiveUserContactMutation>;
export type SetActiveUserContactMutationOptions = Apollo.BaseMutationOptions<GqlSetActiveUserContactMutation, GqlSetActiveUserContactMutationVariables>;

export type ScalarPathObj = { [path: string]: ScalarPathObj } | string;
export const OperationCustomScalarPaths: {[operationName: string]: ScalarPathObj} = {
  "BookingList": {
    "shipments": {
      "$": {
        "createDate": "Timestamp",
        "cargoReadyDate": "Date",
        "revisedCargoReadyDate": "Date",
        "hotMarkedTimestamp": "Timestamp",
        "logistics": {
          "confirmationDate": "Date",
          "cyCutoffDate": "Date",
          "polEtd": "Date",
          "polAtd": "Date"
        }
      }
    }
  },
  "ShippedList": {
    "shipments": {
      "$": {
        "createDate": "Timestamp",
        "cargoReadyDate": "Date",
        "revisedCargoReadyDate": "Date",
        "hotMarkedTimestamp": "Timestamp",
        "logistics": {
          "confirmationDate": "Date",
          "cyCutoffDate": "Date",
          "polEtd": "Date",
          "polAtd": "Date",
          "podEta": "Date",
          "podAta": "Date",
          "deliveryEta": "Date",
          "deliveryAta": "Date"
        }
      }
    }
  },
  "Booking": {
    "booking": {
      "createDate": "Timestamp",
      "cargoReadyDate": "Date",
      "revisedCargoReadyDate": "Date",
      "mbl": {
        "remarks": {
          "$": {
            "createDate": "Timestamp"
          }
        }
      },
      "hbl": {
        "remarks": {
          "$": {
            "createDate": "Timestamp"
          }
        },
        "documents": {
          "$": {
            "uploadDate": "Timestamp"
          }
        }
      },
      "packLines": {
        "$": {
          "requestedDeliveryDate": "Date",
          "orderLine": {
            "indcDate": "Date",
            "lastShipDate": "Date"
          }
        }
      },
      "remarks": {
        "$": {
          "createDate": "Timestamp"
        }
      },
      "logistics": {
        "confirmationDate": "Date",
        "cyCutoffDate": "Date",
        "cfsCutoffDate": "Date",
        "cfsReceivedDate": "Date",
        "vgmCutoffDate": "Date",
        "siCutoffDate": "Date",
        "transitPortEta": "Date",
        "transitPortAta": "Date",
        "transitPortEtd": "Date",
        "transitPortAtd": "Date",
        "polEtd": "Date",
        "polAtd": "Date",
        "podEta": "Date",
        "rampEta": "Date",
        "deliveryEta": "Date"
      },
      "containers": {
        "$": {
          "shipFromFactoryDate": "Date",
          "terminalReceivedDate": "Date"
        }
      },
      "documents": {
        "$": {
          "uploadDate": "Timestamp"
        }
      },
      "hotMarkedTimestamp": "Timestamp"
    }
  },
  "NewBookingRemark": {
    "newBookingRemark": {
      "remark": {
        "createDate": "Timestamp"
      }
    }
  },
  "MarkBookingAsHot": {
    "markBookingAsHot": {
      "booking": {
        "hotMarkedTimestamp": "Timestamp"
      }
    }
  },
  "ConsolidationList": {
    "shipments": {
      "$": {
        "createDate": "Timestamp",
        "logistics": {
          "confirmationDate": "Date",
          "cyCutoffDate": "Date",
          "polEtd": "Date",
          "polAtd": "Date"
        }
      }
    }
  },
  "ShippedConsolidationList": {
    "shipments": {
      "$": {
        "createDate": "Timestamp",
        "cargoReadyDate": "Date",
        "revisedCargoReadyDate": "Date",
        "logistics": {
          "confirmationDate": "Date",
          "cyCutoffDate": "Date",
          "polEtd": "Date",
          "polAtd": "Date"
        }
      }
    }
  },
  "AvailableConsolidationBookingList": {
    "availableConsolidationBookings": {
      "$": {
        "createDate": "Timestamp",
        "cargoReadyDate": "Date",
        "logistics": {
          "polEtd": "Date",
          "polAtd": "Date"
        }
      }
    }
  },
  "Consolidation": {
    "consolidation": {
      "createDate": "Timestamp",
      "bookings": {
        "$": {
          "createDate": "Timestamp",
          "cargoReadyDate": "Date",
          "packLines": {
            "$": {
              "requestedDeliveryDate": "Date",
              "orderLine": {
                "indcDate": "Date",
                "lastShipDate": "Date"
              }
            }
          },
          "logistics": {
            "polEtd": "Date"
          },
          "remarks": {
            "$": {
              "createDate": "Timestamp"
            }
          },
          "documents": {
            "$": {
              "uploadDate": "Timestamp"
            }
          },
          "hbl": {
            "documents": {
              "$": {
                "uploadDate": "Timestamp"
              }
            }
          },
          "mbl": {
            "documents": {
              "$": {
                "uploadDate": "Timestamp"
              }
            }
          }
        }
      },
      "remarks": {
        "$": {
          "createDate": "Timestamp"
        }
      },
      "logistics": {
        "confirmationDate": "Date",
        "cyCutoffDate": "Date",
        "cfsCutoffDate": "Date",
        "cfsOpenDate": "Date",
        "vgmCutoffDate": "Date",
        "siCutoffDate": "Date",
        "transitPortEta": "Date",
        "transitPortAta": "Date",
        "transitPortEtd": "Date",
        "transitPortAtd": "Date",
        "polEtd": "Date",
        "polAtd": "Date",
        "podEta": "Date",
        "rampEta": "Date",
        "deliveryEta": "Date"
      },
      "containers": {
        "$": {
          "shipFromFactoryDate": "Date",
          "terminalReceivedDate": "Date"
        }
      },
      "documents": {
        "$": {
          "uploadDate": "Timestamp"
        }
      }
    }
  },
  "NewConsolidationRemark": {
    "newConsolidationRemark": {
      "remark": {
        "createDate": "Timestamp"
      }
    }
  },
  "Container": {
    "container": {
      "shipFromFactoryDate": "Date",
      "terminalReceivedDate": "Date",
      "portOutGateDate": "Date",
      "rampEta": "Date",
      "rampAta": "Date",
      "deliveryEta": "Date",
      "deliveryAta": "Date",
      "emptyReturnedDate": "Date",
      "demurrageLastFreeDayDate": "Date",
      "detentionLastFreeDayDate": "Date",
      "rampOutGateDate": "Date",
      "emptyNotifyDate": "Date",
      "shipment": {
        "logistics": {
          "polEtd": "Date",
          "polAtd": "Date",
          "podEta": "Date",
          "podAta": "Date"
        }
      },
      "hotMarkedTimestamp": "Timestamp"
    }
  },
  "MarkContainerAsHot": {
    "markContainerAsHot": {
      "container": {
        "hotMarkedTimestamp": "Timestamp"
      }
    }
  },
  "Notifications": {
    "notifications": {
      "$": {
        "createTime": "Timestamp",
        "booking": {
          "createDate": "Timestamp",
          "cargoReadyDate": "Date",
          "revisedCargoReadyDate": "Date",
          "logistics": {
            "confirmationDate": "Date",
            "polEtd": "Date",
            "polAtd": "Date",
            "podEta": "Date"
          },
          "packLines": {
            "$": {
              "orderLine": {
                "expectedCargoReadyDate": "Date"
              }
            }
          }
        },
        "document": {
          "uploadDate": "Timestamp"
        }
      }
    }
  },
  "DashboardPOs": {
    "dashboardPurchaseOrders": {
      "$": {
        "orderLine": {
          "expectedCargoReadyDate": "Date",
          "indcDate": "Date"
        }
      }
    }
  },
  "BookingSummary": {
    "bookingSummary": {
      "$": {
        "start": "Date",
        "end": "Date",
        "containers": {
          "$": {
            "shipment": {
              "createDate": "Timestamp",
              "cargoReadyDate": "Date",
              "revisedCargoReadyDate": "Date"
            }
          }
        },
        "skuPackLines": {
          "$": {
            "booking": {
              "createDate": "Timestamp",
              "cargoReadyDate": "Date",
              "revisedCargoReadyDate": "Date"
            }
          }
        },
        "invoicePackLines": {
          "$": {
            "booking": {
              "createDate": "Timestamp",
              "cargoReadyDate": "Date",
              "revisedCargoReadyDate": "Date"
            }
          }
        },
        "bookings": {
          "$": {
            "createDate": "Timestamp",
            "cargoReadyDate": "Date",
            "revisedCargoReadyDate": "Date"
          }
        }
      }
    }
  },
  "DashboardNewBookings": {
    "newBookings": {
      "$": {
        "createDate": "Timestamp",
        "cargoReadyDate": "Date",
        "revisedCargoReadyDate": "Date"
      }
    }
  },
  "DashboardNewDocuments": {
    "newDocuments": {
      "$": {
        "uploadDate": "Timestamp",
        "polEtd": "Date",
        "polAtd": "Date",
        "podEta": "Date"
      }
    }
  },
  "Exceptions": {
    "exceptions": {
      "$": {
        "booking": {
          "cargoReadyDate": "Date",
          "logistics": {
            "polEtd": "Date",
            "polAtd": "Date",
            "podEta": "Date",
            "podAta": "Date",
            "deliveryEta": "Date"
          },
          "packLines": {
            "$": {
              "orderLine": {
                "expectedCargoReadyDate": "Date"
              }
            }
          },
          "createDate": "Timestamp",
          "revisedCargoReadyDate": "Date"
        },
        "markedAsRead": "Timestamp",
        "container": {
          "rampAta": "Date",
          "demurrageLastFreeDayDate": "Date",
          "deliveryEta": "Date",
          "portOutGateDate": "Date",
          "deliveryAta": "Date",
          "detentionLastFreeDayDate": "Date",
          "hotMarkedTimestamp": "Timestamp",
          "shipment": {
            "cargoReadyDate": "Date",
            "revisedCargoReadyDate": "Date",
            "logistics": {
              "polEtd": "Date",
              "polAtd": "Date",
              "podEta": "Date",
              "podAta": "Date",
              "rampEta": "Date",
              "rampAta": "Date",
              "deliveryEta": "Date",
              "deliveryAta": "Date"
            }
          }
        },
        "openOrder": {
          "hotMarkedTimestamp": "Timestamp"
        },
        "shipment": {
          "cargoReadyDate": "Date",
          "revisedCargoReadyDate": "Date",
          "hotMarkedTimestamp": "Timestamp",
          "logistics": {
            "polEtd": "Date",
            "polAtd": "Date",
            "podEta": "Date",
            "podAta": "Date",
            "rampEta": "Date",
            "rampAta": "Date",
            "deliveryEta": "Date",
            "deliveryAta": "Date"
          }
        },
        "orderLine": {
          "bookByDate": "Date",
          "expectedCargoReadyDate": "Date",
          "purchaseOrder": {
            "expectedCargoReadyDate": "Date"
          }
        }
      }
    }
  },
  "ShipmentsInTransit": {
    "shipmentsInTransit": {
      "$": {
        "container": {
          "rampEta": "Date",
          "rampAta": "Date",
          "deliveryEta": "Date",
          "deliveryAta": "Date",
          "shipment": {
            "cargoReadyDate": "Date",
            "revisedCargoReadyDate": "Date",
            "logistics": {
              "polEtd": "Date",
              "polAtd": "Date",
              "podEta": "Date",
              "podAta": "Date"
            }
          }
        }
      }
    }
  },
  "Documents": {
    "documentSearch": {
      "$": {
        "uploadDate": "Timestamp",
        "polEtd": "Date",
        "polAtd": "Date",
        "podEta": "Date"
      }
    }
  },
  "NewDocuments": {
    "newDocuments": {
      "$": {
        "uploadDate": "Timestamp",
        "polEtd": "Date",
        "polAtd": "Date",
        "podEta": "Date"
      }
    }
  },
  "GlobalUpdate": {
    "globalUpdate": {
      "logistics": {
        "$": {
          "confirmationDate": "Date",
          "polEtd": "Date",
          "polAtd": "Date",
          "transitPortEta": "Date",
          "transitPortAta": "Date",
          "transitPortEtd": "Date",
          "transitPortAtd": "Date",
          "podEta": "Date",
          "podAta": "Date",
          "rampEta": "Date",
          "rampAta": "Date"
        }
      },
      "containers": {
        "$": {
          "rampEta": "Date",
          "rampAta": "Date",
          "rampOutGateDate": "Date",
          "portOutGateDate": "Date",
          "deliveryEta": "Date",
          "deliveryAta": "Date",
          "deliveryUnload": "Date",
          "emptyNotifyDate": "Date",
          "emptyPickupDate": "Date",
          "emptyReturnedDate": "Date",
          "demurrageLastFreeDayDate": "Date",
          "detentionLastFreeDayDate": "Date",
          "consolidatorLastFreeDayDate": "Date"
        }
      },
      "customs": {
        "$": {
          "isfFilingDate": "Date",
          "entryDate": "Date",
          "customsExamNotifyDate": "Date",
          "customsReleaseDate": "Date",
          "fdaExamNotifyDate": "Date",
          "fdaReleaseDate": "Date"
        }
      }
    }
  },
  "Hbl": {
    "hbl": {
      "booking": {
        "logistics": {
          "polEtd": "Date",
          "polAtd": "Date",
          "podEta": "Date",
          "podAta": "Date",
          "rampEta": "Date",
          "rampAta": "Date",
          "deliveryEta": "Date",
          "deliveryAta": "Date"
        },
        "mbl": {
          "remarks": {
            "$": {
              "createDate": "Timestamp"
            }
          }
        },
        "remarks": {
          "$": {
            "createDate": "Timestamp"
          }
        },
        "containers": {
          "$": {
            "shipFromFactoryDate": "Date",
            "terminalReceivedDate": "Date"
          }
        },
        "documents": {
          "$": {
            "uploadDate": "Timestamp"
          }
        },
        "hotMarkedTimestamp": "Timestamp"
      },
      "remarks": {
        "$": {
          "createDate": "Timestamp"
        }
      },
      "documents": {
        "$": {
          "uploadDate": "Timestamp"
        }
      }
    }
  },
  "NewHblRemark": {
    "newHblRemark": {
      "remark": {
        "createDate": "Timestamp"
      }
    }
  },
  "Mbl": {
    "mbl": {
      "shipment": {
        "hbl": {
          "remarks": {
            "$": {
              "createDate": "Timestamp"
            }
          },
          "documents": {
            "$": {
              "uploadDate": "Timestamp"
            }
          }
        },
        "logistics": {
          "polEtd": "Date",
          "polAtd": "Date",
          "podEta": "Date",
          "podAta": "Date",
          "rampEta": "Date",
          "rampAta": "Date",
          "deliveryEta": "Date",
          "deliveryAta": "Date"
        },
        "remarks": {
          "$": {
            "createDate": "Timestamp"
          }
        },
        "containers": {
          "$": {
            "shipFromFactoryDate": "Date",
            "terminalReceivedDate": "Date"
          }
        },
        "documents": {
          "$": {
            "uploadDate": "Timestamp"
          }
        }
      },
      "remarks": {
        "$": {
          "createDate": "Timestamp"
        }
      },
      "documents": {
        "$": {
          "uploadDate": "Timestamp"
        }
      }
    }
  },
  "OpenOrders": {
    "openOrders": {
      "$": {
        "orderLine": {
          "expectedCargoReadyDate": "Date",
          "revisedCargoReadyDate": "Date",
          "firstShipDate": "Date",
          "lastShipDate": "Date"
        }
      }
    }
  },
  "PurchaseOrders": {
    "purchaseOrders": {
      "$": {
        "poDate": "Date",
        "expectedCargoReadyDate": "Date",
        "orderLines": {
          "$": {
            "expectedCargoReadyDate": "Date",
            "revisedCargoReadyDate": "Date",
            "firstShipDate": "Date",
            "lastShipDate": "Date"
          }
        }
      }
    }
  },
  "PurchaseOrder": {
    "purchaseOrder": {
      "indcDate": "Date",
      "poDate": "Date",
      "expectedCargoReadyDate": "Date",
      "customerCancelDate": "Date",
      "customerStartShipDate": "Date",
      "orderLines": {
        "$": {
          "bookByDate": "Date",
          "expectedCargoReadyDate": "Date",
          "revisedCargoReadyDate": "Date",
          "indcDate": "Date",
          "firstShipDate": "Date",
          "lastShipDate": "Date",
          "openOrder": {
            "hotMarkedTimestamp": "Timestamp"
          }
        }
      },
      "poHistory": {
        "$": {
          "timestamp": "Timestamp"
        }
      },
      "hotMarkedTimestamp": "Timestamp"
    }
  },
  "MarkPoLineAsHot": {
    "markPoLineAsHot": {
      "openOrder": {
        "hotMarkedTimestamp": "Timestamp"
      }
    }
  },
  "MarkPoAsHot": {
    "markPoAsHot": {
      "purchaseOrder": {
        "hotMarkedTimestamp": "Timestamp"
      }
    }
  },
  "PoImportFiles": {
    "poImportFiles": {
      "$": {
        "uploadDate": "Timestamp"
      }
    }
  },
  "Profile": {
    "profile": {
      "logo": {
        "uploadDate": "Timestamp"
      }
    }
  },
  "QuickSearch": {
    "quickSearch": {
      "purchaseOrders": {
        "$": {
          "poDate": "Date",
          "cargoReadyDate": "Date",
          "firstShipDate": "Date",
          "lastShipDate": "Date"
        }
      },
      "containers": {
        "$": {
          "railRampEta": "Date",
          "railRampAta": "Date",
          "finalEta": "Date",
          "finalAta": "Date"
        }
      },
      "bookings": {
        "$": {
          "cargoReadyDate": "Date",
          "polEtd": "Date",
          "polAtd": "Date",
          "podEta": "Date",
          "podAta": "Date"
        }
      },
      "shipments": {
        "$": {
          "cargoReadyDate": "Date",
          "polEtd": "Date",
          "polAtd": "Date",
          "podEta": "Date",
          "podAta": "Date"
        }
      }
    }
  },
  "Reports": {
    "reports": {
      "$": {
        "nextRun": "Timestamp",
        "lastRun": "Timestamp"
      }
    }
  },
  "Report": {
    "report": {
      "nextRun": "Timestamp",
      "lastRun": "Timestamp"
    }
  },
  "putReport": {
    "putReport": {
      "nextRun": "Timestamp",
      "lastRun": "Timestamp"
    }
  },
  "setReportScheduled": {
    "setReportScheduled": {
      "nextRun": "Timestamp",
      "lastRun": "Timestamp"
    }
  }
};
export const InputTypePaths: { [inputObjectName: string]: { [fieldName: string]: string } } = {
  "AssignBookingContainerInput": {
    "container": "ContainerInput",
    "vanPositions": "VanPositionInput"
  },
  "AssignConsolidationContainerInput": {
    "container": "ContainerInput",
    "vanPositions": "VanPositionInput"
  },
  "BookingInput": {
    "cargoReadyDate": "Date"
  },
  "ConsolidationBookingHblInput": {
    "hbl": "HblInput"
  },
  "ContactInput": {
    "credentials": "CredentialsInput"
  },
  "ContainerInput": {
    "shipFromFactoryDate": "Date",
    "terminalReceivedDate": "Date",
    "rampOutGateDate": "Date",
    "portOutGateDate": "Date",
    "emptyReturnedDate": "Date"
  },
  "CustomsInput": {
    "isfFilingDate": "Date",
    "entryDate": "Date",
    "customsExamNotifyDate": "Date",
    "customsReleaseDate": "Date",
    "fdaExamNotifyDate": "Date",
    "fdaReleaseDate": "Date"
  },
  "DocumentDateFilter": {
    "from": "Date",
    "to": "Date"
  },
  "DocumentFilter": {
    "dateFilter": "DocumentDateFilter"
  },
  "GlobalUpdateContainerInput": {
    "rampEta": "Date",
    "rampAta": "Date",
    "rampOutGateDate": "Date",
    "portOutGateDate": "Date",
    "deliveryEta": "Date",
    "deliveryAta": "Date",
    "deliveryUnload": "Date",
    "emptyNotifyDate": "Date",
    "emptyPickupDate": "Date",
    "emptyReturnedDate": "Date",
    "demurrageLastFreeDayDate": "Date",
    "detentionLastFreeDayDate": "Date",
    "consolidatorLastFreeDayDate": "Date"
  },
  "GlobalUpdateCustomsInput": {
    "isfFilingDate": "Date",
    "entryDate": "Date",
    "customsExamNotifyDate": "Date",
    "customsReleaseDate": "Date",
    "fdaExamNotifyDate": "Date",
    "fdaReleaseDate": "Date"
  },
  "GlobalUpdateInput": {
    "logistics": "GlobalUpdateLogisticsInput",
    "containers": "GlobalUpdateContainerInput",
    "customs": "GlobalUpdateCustomsInput"
  },
  "GlobalUpdateLogisticsInput": {
    "confirmationDate": "Date",
    "polEtd": "Date",
    "polAtd": "Date",
    "transitPortEta": "Date",
    "transitPortAta": "Date",
    "transitPortEtd": "Date",
    "transitPortAtd": "Date",
    "podEta": "Date",
    "podAta": "Date"
  },
  "LogisticsInput": {
    "confirmationDate": "Date",
    "cyCutoffDate": "Date",
    "cfsOpenDate": "Date",
    "cfsCutoffDate": "Date",
    "cfsReceivedDate": "Date",
    "vgmCutoffDate": "Date",
    "siCutoffDate": "Date",
    "polEtd": "Date",
    "polAtd": "Date",
    "transitPortEtd": "Date",
    "transitPortAtd": "Date",
    "transitPortEta": "Date",
    "transitPortAta": "Date",
    "podEta": "Date",
    "podAta": "Date",
    "rampEta": "Date",
    "rampAta": "Date",
    "deliveryEta": "Date",
    "deliveryAta": "Date"
  },
  "NetworkRulesInput": {
    "documentTypes": "NetworkDocumentTypeRuleInput"
  },
  "NewBookingContainersInput": {
    "containers": "ContainerInput"
  },
  "NewBookingInput": {
    "booking": "BookingInput",
    "logistics": "LogisticsInput",
    "packLines": "PackLineInput",
    "containers": "ContainerInput",
    "remark": "RemarkInput",
    "relatedParties": "RelatedPartyInput"
  },
  "NewBookingRemarkInput": {
    "remark": "RemarkInput"
  },
  "NewConsolidationContainersInput": {
    "containers": "ContainerInput"
  },
  "NewConsolidationInput": {
    "logistics": "LogisticsInput",
    "containers": "ContainerInput",
    "remark": "RemarkInput",
    "relatedParties": "RelatedPartyInput"
  },
  "NewConsolidationRemarkInput": {
    "remark": "RemarkInput"
  },
  "NewHblRemarkInput": {
    "remark": "RemarkInput"
  },
  "NewMblRemarkInput": {
    "remark": "RemarkInput"
  },
  "NewNetworkConnectedProfileInput": {
    "connectedProfile": "ProfileInput",
    "profileMainLocation": "LocationInput",
    "profileMainContact": "ContactInput"
  },
  "NewNetworkInput": {
    "network": "NetworkInput",
    "controllingClient": "ProfileInput",
    "controllingClientMainLocation": "LocationInput",
    "controllingClientMainContact": "ContactInput"
  },
  "NewNetworkProfileTypeInput": {
    "profileType": "ProfileTypeInput",
    "roles": "RoleInput"
  },
  "NewProfileContactInput": {
    "contact": "ContactInput"
  },
  "NewProfileLocationInput": {
    "location": "LocationInput"
  },
  "NewProfileRoleInput": {
    "role": "RoleInput"
  },
  "NewProfileTypeDefaultRoleInput": {
    "role": "RoleInput"
  },
  "NewProfileTypeTemplateInput": {
    "profileType": "ProfileTypeInput"
  },
  "PackLineInput": {
    "requestedDeliveryDate": "Date"
  },
  "ReportInput": {
    "fieldAliases": "ReportFieldAliasInput",
    "filters": "DatasetFilterInput"
  },
  "SetPoRelatedPartiesInput": {
    "parties": "SetPoRelatedPartiesInputParty"
  },
  "UpdateBookingInput": {
    "booking": "BookingInput"
  },
  "UpdateBookingLogisticsInput": {
    "logistics": "LogisticsInput",
    "hbl": "HblInput",
    "mbl": "MblInput",
    "motherVessel": "VesselInput",
    "motherVoyage": "VoyageInput",
    "feederVessel": "VesselInput",
    "feederVoyage": "VoyageInput"
  },
  "UpdateConsolidationInput": {
    "consolidation": "ConsolidationInput"
  },
  "UpdateConsolidationLogisticsInput": {
    "logistics": "LogisticsInput",
    "mbl": "MblInput",
    "hbls": "ConsolidationBookingHblInput",
    "motherVessel": "VesselInput",
    "motherVoyage": "VoyageInput",
    "feederVessel": "VesselInput",
    "feederVoyage": "VoyageInput"
  },
  "UpdateContactInput": {
    "contact": "ContactInput"
  },
  "UpdateContainerInput": {
    "container": "ContainerInput",
    "vanPositions": "VanPositionInput"
  },
  "UpdateLocationInput": {
    "location": "LocationInput"
  },
  "UpdateNetworkContainerTypesInput": {
    "containerTypes": "NetworkContainerTypeInput"
  },
  "UpdateNetworkInput": {
    "network": "NetworkInput"
  },
  "UpdateNetworkRulesInput": {
    "rules": "NetworkRulesInput"
  },
  "UpdateProfileInput": {
    "profile": "ProfileInput",
    "profileMainLocation": "LocationInput",
    "profileMainContact": "ContactInput"
  },
  "UpdateProfileTypeInput": {
    "profileType": "ProfileTypeInput"
  },
  "UpdateRoleInput": {
    "role": "RoleInput"
  },
  "UpdateUserInput": {
    "user": "UserInput"
  },
  "UploadBookingDocumentInput": {
    "document": "DocumentInput"
  },
  "UserInput": {
    "credentials": "CredentialsInput"
  }
};