import { gql } from '@apollo/client/core';
import { PortFragment } from './portQueries';

export const MblQuery = gql`
  query Mbl($mblId: ID!) {
    mbl(mblId: $mblId) {
      id
      referenceNumber
      containerCount
      totalPieces
      totalCartons
      releaseType
      paymentType
      shipment {
        ... on Booking {
          hbl {
            id
            referenceNumber
            releaseType
            booking {
              id
              referenceNumber
            }
            remarks {
              id
              text
              createDate
              createdBy {
                id
                firstName
                lastName
                profile {
                  id
                  name
                }
              }
            }
            documents {
              id
              documentType
              importFileName
              fileName
              fileType
              fileSize
              uploadDate
              description
            }
          }
        }
        id
        status
        referenceNumber
        logistics {
          carrier {
            id
            name
          }
          motherVessel {
            id
            name
          }
          motherVoyage {
            id
            name
          }
          contractNumber
          contractType
          moveType
          pol {
            ...Port
          }
          polEtd
          polAtd
          pod {
            ...Port
          }
          podEta
          podAta
          ramp {
            ...Port
          }
          rampEta
          rampAta
          deliveryLocation {
            id
            name
          }
          deliveryEta
          deliveryAta
        }
        remarks {
          id
          text
          createDate
          createdBy {
            id
            firstName
            lastName
            profile {
              id
              name
            }
          }
        }
        containers {
          id
          containerNumber
          containerType
          sealNumber
          volume
          weight
          shipFromFactoryDate
          terminalReceivedDate
          isHot
        }
        documents {
          id
          documentType
          importFileName
          fileName
          fileType
          fileSize
          uploadDate
          description
        }
      }
      remarks {
        id
        text
        createDate
        createdBy {
          id
          firstName
          lastName
          profile {
            id
            name
          }
        }
      }
      documents {
        id
        documentType
        importFileName
        fileName
        fileType
        fileSize
        uploadDate
        description
      }
      relatedParties {
        id
        partyType
        party {
          id
          name
          profileCode
          profileType {
            name
          }
        }
      }
    }
  }
  ${PortFragment}
`;

export const NewMblRemarkMutation = gql`
  mutation NewMblRemark($input: NewMblRemarkInput!) {
    newMblRemark(input: $input) {
      success
    }
  }
`;

export const AssignMblRelatedPartyMutation = gql`
  mutation AssignMblRelatedParty($input: AssignMblRelatedPartyInput!) {
    assignMblRelatedParty(input: $input) {
      success
      message
    }
  }
`;

export const RemoveMblRelatedPartyMutation = gql`
  mutation RemoveMblRelatedParty($input: RemoveMblRelatedPartyInput!) {
    removeMblRelatedParty(input: $input) {
      success
      message
    }
  }
`;

export const UpdateMblRelatedPartyMutation = gql`
  mutation UpdateMblRelatedParty($input: UpdateMblRelatedPartyInput!) {
    updateMblRelatedParty(input: $input) {
      success
      message
    }
  }
`;
