import { Button } from '@material-ui/core';
import React from 'react';
import { makeCss, theme } from 'styles';

const classes = makeCss({
  quantity: {
    fontWeight: 900,
    marginLeft: theme.spacing(0.75),
  },
});

interface Props {
  label: string;
  quantity: number;

  onClick?: React.MouseEventHandler<HTMLButtonElement>;
}

export const AlertButton: React.FC<Props> = ({ label, quantity, onClick }) => {
  return (
    <Button variant="outlined" color="secondary" onClick={onClick}>
      {label} <span className={classes.quantity}>({quantity})</span>
    </Button>
  );
};
