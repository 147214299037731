import { Box } from '@material-ui/core';
import Panel from 'components/Panel';
import { Line } from 'components/StyledComponents';
import { UWLTable } from 'components/UWLTable/UWLTable';
import { useRecoilValue } from 'recoil';
import { UWLTableColumn } from 'types/UWLTable';
import { ConsolidationBookingsTV, NewConsolidationStates } from '../states';

const bookingsHeaders: UWLTableColumn<ConsolidationBookingsTV>[] = [
  { id: 'referenceNumber', label: 'Booking', type: 'link', whiteSpace: 'nowrap' },
  { id: 'supplierName', label: 'Supplier', type: 'string', whiteSpace: 'nowrap' },
  { id: 'bookingDate', label: 'Booking Date', type: 'date' },
  { id: 'weight', label: 'Wgt', type: 'number', align: 'left' },
  { id: 'volume', label: 'CBM', type: 'number', align: 'left' },
  { id: 'bookingStatus', label: 'Booking Status', type: 'string', whiteSpace: 'nowrap' },
  { id: 'cargoReadyDate', label: 'Cgo Rdy Date', type: 'date' },
  { id: 'pol', label: 'POL', type: 'string', whiteSpace: 'nowrap' },
  { id: 'polEtd', label: 'ETD', type: 'date' },
  { id: 'pod', label: 'POD', type: 'string', whiteSpace: 'nowrap' },
  { id: 'finalDestinationName', label: 'Final Destination', type: 'string', whiteSpace: 'nowrap' },
  { id: 'moveType', label: 'Delivery Type', type: 'string', whiteSpace: 'nowrap' },
  { id: 'containers', label: 'Eqpt Type', type: 'string', whiteSpace: 'nowrap' },
];

export default function BookingsTable() {
  const bookings = useRecoilValue(NewConsolidationStates.step1.selectedBookings);

  return (
    <Panel title="Bookings" growPanel>
      <Line height={1} />
      <Box height="100%" paddingX={2} paddingBottom={2}>
        <UWLTable
          rowId="id"
          columns={bookingsHeaders}
          rows={bookings}
          emptyMessage="- No Bookings -"
          virtualize={{
            bufferHeight: 400,
            rowHeight(row) {
              return 24;
            },
          }}
        />
      </Box>
    </Panel>
  );
}
