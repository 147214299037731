import { Button, Typography } from '@material-ui/core';
import { GqlRelatedParty, MoveType, PartyType } from 'api/GQL_Types';
import { userContextAtom } from 'app';
import React from 'react';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { NewConsolidationStates, step1Validation } from '../states';

export const Step2Button: React.FC = () => {
  const validation = useRecoilValue(step1Validation);
  const userContext = useRecoilValue(userContextAtom);
  const partyName = userContext?.user?.firstName + ' ' + userContext?.user?.lastName;
  const networkControllingClientName =
    userContext?.user?.profileContacts[0].profile.network.controllingClient.name || '';
  const setBookingParty = useSetRecoilState(NewConsolidationStates.step2.formBookingParty);
  const setConsignee = useSetRecoilState(NewConsolidationStates.step2.formConsignee);
  const setConsigneeField = useSetRecoilState(NewConsolidationStates.step2.formConsigneeField);
  const setSupplier = useSetRecoilState(NewConsolidationStates.step2.formSupplier);
  const setSupplierField = useSetRecoilState(NewConsolidationStates.step2.formSupplierField);
  const setSupplierList = useSetRecoilState(NewConsolidationStates.step2.formSupplierList);
  const setManufacturer = useSetRecoilState(NewConsolidationStates.step2.formManufacturer);
  const setManufacturerField = useSetRecoilState(
    NewConsolidationStates.step2.formManufacturerField
  );
  const setManufacturerList = useSetRecoilState(NewConsolidationStates.step2.formManufacturerList);
  const setMoveType = useSetRecoilState(NewConsolidationStates.step2.formMoveType);
  const setActiveStep = useSetRecoilState(NewConsolidationStates.activeStep);
  const bookings = useRecoilValue(NewConsolidationStates.step1.selectedBookings);

  return (
    <div title={validation.whyCantGoToStep2}>
      <Button
        disabled={bookings.length === 0 || !validation.canGoToStep2}
        variant="contained"
        color="primary"
        style={{ padding: '8px 32px' }}
        onClick={() => {
          let consignee: GqlRelatedParty | null = null;
          let manufacturerList = [];
          let supplierList = [];
          for (const booking of bookings) {
            consignee =
              consignee ??
              booking.relatedParties.find((p) => p.partyType === PartyType.Consignee) ??
              null;
            const tempSupplierList = booking.relatedParties.filter(
              (p) => p.partyType === PartyType.Supplier
            );
            supplierList.push(tempSupplierList[0]);
            const tempManufacturerList = booking.relatedParties.filter(
              (p) => p.partyType === PartyType.Manufacturer
            );
            manufacturerList.push(tempManufacturerList[0]);
          }
          const supplierFilter: GqlRelatedParty[] = [];
          for (const s of supplierList) {
            if (!supplierFilter.some((a) => a.party.id === s.party.id)) {
              supplierFilter.push(s);
            }
          }

          const manufacturerFilter: GqlRelatedParty[] = [];
          for (const m of manufacturerList) {
            if (!manufacturerFilter.some((a) => a.party.id === m.party.id)) {
              manufacturerFilter.push(m);
            }
          }
          setBookingParty(partyName);
          setConsignee(consignee);
          setConsigneeField(networkControllingClientName);
          setSupplier(supplierFilter[0]);
          setSupplierField(supplierFilter.length > 1 ? 'Various' : supplierFilter[0].party.name);
          setSupplierList(supplierFilter);
          setManufacturer(manufacturerFilter[0]);
          setManufacturerField(
            manufacturerFilter.length > 1 ? 'Various' : manufacturerFilter[0].party.name
          );
          setManufacturerList(manufacturerFilter);
          setMoveType(MoveType.CfsCy);
          setActiveStep(1);
        }}
      >
        <Typography variant="h3" color="inherit" style={{ width: '100px' }}>
          To Step 2
        </Typography>
      </Button>
    </div>
  );
};
