import { faBox } from '@fortawesome/free-solid-svg-icons';
import { PartyType } from 'api/GQL_Types';
import { HotContainerSwitch } from 'app/components/HotContainerSwitch';
import { AlertIconButton } from 'app/dashboard/components/AlertIconButton';
import { HotState } from 'components/HotToggleSwitch';
import TableExportWindowGroup, { uwlColToGTable } from 'components/TableExportWindowGroup';
import { STANDARD_ROW_OVERALL_HEIGHT, UWLTable } from 'components/UWLTable/UWLTable';
import { useWindowSize } from 'lib/useWindowSize';
import React from 'react';
import { useRecoilValue } from 'recoil';
import { UWLTableColumn } from 'types/UWLTable';
import { formatNumber } from 'utils/Numbers';
import { ExceptionQueryResult } from '.';
import { PopoverTable } from '../../components/PopoverTable';
import { DashboardPageStates, TableLink } from '../../states';

export interface HotContainerException {
  id: string;
  hot: HotState;
  consignee: string;
  bl: TableLink;
  cargoReadyDate: Date | null | undefined;
  revisedCargoReadyDate: Date | null | undefined;
  shipToLocation: string;
  equipment: TableLink;
  invoice: number;
  carrier: string;
  pol: string;
  polEtd: Date | null | undefined;
  polAtd: Date | null | undefined;
  pod: string;
  podEta: Date | null | undefined;
  podAta: Date | null | undefined;
  ramp: string;
  rampEta: Date | null | undefined;
  rampAta: Date | null | undefined;
  deliveryLocation: string;
  deliveryEta: Date | null | undefined;
  deliveryAta: Date | null | undefined;
}

export function HotContainerException_toRow(
  exception: ExceptionQueryResult
): HotContainerException | null {
  if (exception.__typename !== 'HotContainer') {
    return null;
  }
  const container = exception.container;
  const shipment = container.shipment;
  const logistics = container.shipment.logistics;

  return {
    id: container.id,
    hot: {
      isHot: container.isHot,
      hotMarkedBy: container.hotMarkedBy,
      hotMarkedTimestamp: container.hotMarkedTimestamp,
    },
    consignee:
      shipment.relatedParties.find(
        (party: { partyType: PartyType }) => party.partyType === PartyType.Consignee
      )?.party.name ?? '',
    bl:
      shipment.__typename === 'Consolidation' && shipment.mbl
        ? {
            to: `/mbl/${shipment.mbl.id ?? ''}`,
            value: shipment.mbl.referenceNumber ?? '',
          }
        : shipment.__typename === 'Booking' && shipment.hbl
        ? {
            to: `/hbl/${shipment.hbl.id ?? ''}`,
            value: shipment.hbl.referenceNumber ?? '',
          }
        : { to: '', value: '' },
    cargoReadyDate: shipment.__typename === 'Booking' ? shipment.cargoReadyDate : null,
    revisedCargoReadyDate:
      shipment.__typename === 'Booking' ? shipment.revisedCargoReadyDate : null,
    shipToLocation: logistics.finalDestination?.name ?? '',
    equipment: {
      to: `/equipment/${container.id ?? ''}`,
      value: container.containerNumber ?? '',
    },
    invoice: container.vanPositions
      .map((p) => p.commercialInvoiceTotal ?? 0)
      .reduce((sum, val) => sum + val, 0),
    carrier: logistics.carrier?.name ?? '',
    pol: logistics.pol?.code ?? '',
    polEtd: logistics.polEtd,
    polAtd: logistics.polAtd,
    pod: logistics.pod?.code ?? '',
    podEta: logistics.podEta,
    podAta: logistics.podAta,
    ramp: logistics.ramp?.code ?? '',
    rampEta: logistics.rampEta,
    rampAta: logistics.rampAta,
    deliveryLocation: logistics.finalDestination?.name ?? '',
    deliveryEta: logistics.deliveryEta,
    deliveryAta: logistics.deliveryAta,
  };
}

const columns: UWLTableColumn<HotContainerException>[] = [
  { id: 'hot', label: '!', type: 'string' },
  { id: 'consignee', label: 'Consignee', type: 'string' },
  { id: 'bl', label: 'BOL', type: 'link' },
  { id: 'cargoReadyDate', label: 'CRG RDY Date', type: 'date' },
  { id: 'revisedCargoReadyDate', label: 'REV CRG RDY Date', type: 'date' },
  { id: 'shipToLocation', label: 'Ship To Location', type: 'string' },
  { id: 'equipment', label: 'Equipment', type: 'link' },
  { id: 'invoice', label: 'COMM INV Total', type: 'currency' }, // COMM INV TOTAL (THIS IS THE SUM TOTAL OF ALL PO/LINES IN THE CONTAINER).
  { id: 'carrier', label: 'Carrier', type: 'string' },
  { id: 'pol', label: 'POL', type: 'string' },
  { id: 'polEtd', label: 'POL ETD', type: 'date' },
  { id: 'polAtd', label: 'POL ATD', type: 'date' },
  { id: 'pod', label: 'POD', type: 'string' },
  { id: 'podEta', label: 'POD ETA', type: 'date' },
  { id: 'podAta', label: 'POD ATA', type: 'date' },
  { id: 'ramp', label: 'Rail Ramp', type: 'string' },
  { id: 'rampEta', label: 'Rail Ramp ETA', type: 'date' },
  { id: 'rampAta', label: 'Rail Ramp ATA', type: 'date' },
  { id: 'deliveryLocation', label: 'Delivery Location', type: 'string' },
  { id: 'deliveryEta', label: 'Final ETA', type: 'date' },
  { id: 'deliveryAta', label: 'Final ATA', type: 'date' },
  // TODO In DC Date
];

const exportColumns = columns.filter((c) => c.id !== 'hot').map(uwlColToGTable);

interface Props {}

export const HotContainerExceptionCmpt: React.FC<Props> = (props) => {
  const [open, setOpen] = React.useState(false);
  const refAnchor = React.useRef<HTMLDivElement>(null);
  const windowSize = useWindowSize();

  const exceptions = useRecoilValue(DashboardPageStates.exceptions);
  const rows = exceptions.hotContainers;

  return (
    <>
      <AlertIconButton
        innerRef={refAnchor}
        name="Hot Containers"
        faIcon={faBox}
        addHotIcon
        unreadCount={rows.length}
        onClick={() => setOpen(true)}
      />

      <PopoverTable
        anchorEl={refAnchor.current}
        open={open}
        onClose={() => setOpen(false)}
        title="Hot Containers"
        titleRight={
          <TableExportWindowGroup label="Hot Containers" rows={rows} columns={exportColumns} />
        }
        totalLabel="Total Hot Containers"
        totalValue={formatNumber(rows.length)}
        width={windowSize.width * 0.8}
        bodyHeight={rows.length * STANDARD_ROW_OVERALL_HEIGHT}
      >
        {open && (
          <UWLTable
            rowId={'id'}
            rows={rows}
            columns={columns}
            emptyMessage="No Hot Containers"
            virtualize={'single-line-cells'}
            renderCell={{
              hot(row) {
                return <HotContainerSwitch containerId={row.id} initialState={row.hot} small />;
              },
            }}
          />
        )}
      </PopoverTable>
    </>
  );
};
