import { Box, Button, Container } from '@material-ui/core';
import {
  GqlPort,
  LocationType,
  useNewProfileLocationMutation,
  useUpdateLocationMutation,
} from 'api/GQL_Types';
import { ProfileQuery } from 'api/queries/profileQueries';
import { AppWideStates, useLocationTypeNameLookup } from 'app/states';
import AtomicMultiSelectorV2 from 'components/atomic/AtomicMultiSelectorV2';
import { AtomicPhoneNumberV2 } from 'components/atomic/AtomicPhoneNumberV2';
import AtomicTextFieldV2 from 'components/atomic/AtomicTextFieldV2';
import { AtomicTextWithAliasesV2 } from 'components/atomic/AtomicTextWithAliasesV2';
import { FormInputPortAny } from 'components/form/FormInputPortAny';
import { FormItem } from 'components/form/FormItem';
import { FormRow } from 'components/form/FormRow';
import { Line } from 'components/StyledComponents';
import UniversalDialog from 'components/UniversalDialog';
import { useSnackbar } from 'notistack';
import React from 'react';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import { newAtom } from 'recoil-utils/utils';
import { theme } from 'styles';
import { ProfilePageLocation, ProfilePageStates } from '../states';

// Sets the Dialog to open/close
export const ProfileLocationsDialog_Open = newAtom(false);

//data coming in from the location cards. If location is null, it will be treated as a new location
export const LocationDialog_Data = newAtom<ProfilePageLocation | null>(null);
const overwriteWarningMessageState = newAtom('');

const locationState = {
  name: newAtom<string>(''),
  addressLine1: newAtom(''),
  addressLine2: newAtom(''),
  city: newAtom(''),
  state: newAtom(''),
  postalCode: newAtom(''),
  country: newAtom(''),
  code: newAtom<string>(''),
  relatedPortCode: newAtom<GqlPort | null>(null),
  companyPhone: newAtom(''),
  companyUrl: newAtom(''),
  nameAliases: newAtom<string[]>([]),
  codeAliases: newAtom<string[]>([]),
  locationTypes: newAtom<LocationType[]>([]),
};

export default function ProfileLocationsDialog() {
  const [openDialog, setOpenDialog] = useRecoilState(ProfileLocationsDialog_Open);
  const location = useRecoilValue(LocationDialog_Data);
  const profile = useRecoilValue(ProfilePageStates.profile);
  const locationTypeNameLookup = useLocationTypeNameLookup();
  const allLocationTypes = useRecoilValue(AppWideStates.locationTypes).all;

  const setName = useSetRecoilState(locationState.name);
  const setNameAliases = useSetRecoilState(locationState.nameAliases);
  const setAddressLine1 = useSetRecoilState(locationState.addressLine1);
  const setAddressLine2 = useSetRecoilState(locationState.addressLine2);
  const setCity = useSetRecoilState(locationState.city);
  const setState = useSetRecoilState(locationState.state);
  const setPostalCode = useSetRecoilState(locationState.postalCode);
  const setCountry = useSetRecoilState(locationState.country);
  const setCode = useSetRecoilState(locationState.code);
  const setCodeAliases = useSetRecoilState(locationState.codeAliases);
  const [relatedPortCode, setRelatedPortCode] = useRecoilState(locationState.relatedPortCode);
  const setCompanyPhone = useSetRecoilState(locationState.companyPhone);
  const setCompanyUrl = useSetRecoilState(locationState.companyUrl);
  const setLocationTypes = useSetRecoilState(locationState.locationTypes);

  const [overwriteWarningMessage, setOverwriteWarningMessage] = useRecoilState(
    overwriteWarningMessageState
  );

  React.useEffect(() => {
    setName(location?.name || '');
    setNameAliases(location?.nameAliases || []);
    setAddressLine1(location?.addressLine1 || '');
    setAddressLine2(location?.addressLine2 || '');
    setCity(location?.city || '');
    setState(location?.state || '');
    setPostalCode(location?.postalCode || '');
    setCountry(location?.country || '');
    setCode(location?.code || '');
    setCodeAliases(location?.codeAliases || []);
    setRelatedPortCode(location?.relatedPort || null);
    setCompanyPhone(location?.companyPhone || '');
    setCompanyUrl(location?.companyUrl || '');
    setLocationTypes(location?.locationTypes || []);
  }, [location]);

  React.useEffect(() => {
    let triggerPrimaryWarning = false;
    let triggerCustomsWarning = false;
    let warningMessage = '';

    const locationMol = profile?.locationList;
    const locationTypesSelected: LocationType[] = location?.locationTypes || [];

    if (locationMol) {
      locationMol.forEach((loc) => {
        // check if primary location already exists
        if (loc.locationTypes.find((type) => type === 'PRIMARY')) {
          // check if we are managing a location or creating a new one
          if (location) {
            // check if we are managing the location that is already the primary
            if (location.locationTypes.find((type: LocationType) => type === 'PRIMARY')) {
              triggerPrimaryWarning = false;
            } else {
              // not primary location, check to see if primary is selected as location type
              if (locationTypesSelected.find((type) => type === 'PRIMARY')) {
                triggerPrimaryWarning = true;
                warningMessage =
                  'This location will become the new primary location, replacing the previous one, if saved.';
              }
            }
          } else {
            // new location, check to see if primary is selected as location type
            if (locationTypesSelected.find((type) => type === 'PRIMARY')) {
              triggerPrimaryWarning = true;
              warningMessage =
                'This location will become the new primary location, replacing the previous one, if saved.';
            }
          }
        }

        // check if customs address of records already exists
        if (loc.locationTypes.find((type) => type === 'CUSTOMS')) {
          // check if we are managing a location or creating a new location
          if (location) {
            // check if we are managing the location that is already the customs address of records
            if (location.locationTypes.find((type: LocationType) => type === 'CUSTOMS')) {
              triggerCustomsWarning = false;
            } else {
              // not customs address of records location, check to see if customs address of records is selected as location type
              if (locationTypesSelected.find((type) => type === 'CUSTOMS')) {
                triggerCustomsWarning = true;
                warningMessage =
                  'This location will become the new customs address of records replacing, the previous one, if saved.';
              }
            }
          } else {
            // new location, check to see if customs address of records is selected as location type
            if (locationTypesSelected.find((type) => type === 'CUSTOMS')) {
              triggerCustomsWarning = true;
              warningMessage =
                'This location will become the new customs address of records replacing, the previous one, if saved.';
            }
          }
        }
      });
    }

    // check if primary/customs address of records are selected
    if (triggerPrimaryWarning && triggerCustomsWarning) {
      warningMessage =
        'This location will become the new primary location/customs address of records, replacing the previous one, if saved.';
    }

    setOverwriteWarningMessage(warningMessage);
  }, [location, profile]);

  return (
    <UniversalDialog
      open={openDialog}
      title={location ? 'Edit Location' : 'New Location'}
      subTitle={
        location
          ? 'Edit the location associated with the Profile'
          : 'Create a new location for this profile'
      }
      large
      setClose={() => {
        setOpenDialog(false);
      }}
    >
      <Line height={2} color={theme.palette.primary.main} />
      <Container>
        <Box marginBottom={2}>
          <FormRow>
            <AtomicTextWithAliasesV2
              required
              label="Location Name"
              textState={locationState.name}
              aliasesState={locationState.nameAliases}
            />
            <AtomicMultiSelectorV2<LocationType>
              state={locationState.locationTypes}
              label="Location Types"
              optionsList={allLocationTypes.map((l) => l.code)}
              valueResolver={(value) => value}
              displayResolver={locationTypeNameLookup}
              warningMessage={overwriteWarningMessage}
            />
          </FormRow>
          <FormRow>
            <AtomicTextFieldV2 state={locationState.addressLine1} label="Address 1" />
            <AtomicTextFieldV2 state={locationState.addressLine2} label="Address 2" />
          </FormRow>
          <FormRow>
            <AtomicTextFieldV2 state={locationState.city} label="City" />
            <AtomicTextFieldV2 state={locationState.state} label="State" />
            <AtomicTextFieldV2 state={locationState.postalCode} label="Postal Code" />
            <AtomicTextFieldV2 state={locationState.country} label="Country" />
          </FormRow>
          <FormRow>
            <AtomicTextWithAliasesV2
              label="Location Code"
              textState={locationState.code}
              aliasesState={locationState.codeAliases}
            />
            <FormItem>
              <FormInputPortAny
                label="UNLOCO"
                value={relatedPortCode}
                onValue={setRelatedPortCode}
              />
            </FormItem>
          </FormRow>
          <FormRow>
            <AtomicPhoneNumberV2 state={locationState.companyPhone} label="Company Phone" />
            <AtomicTextFieldV2 state={locationState.companyUrl} label="Company URL" />
          </FormRow>
        </Box>
      </Container>
      <Line height={1} />
      <Footer />
    </UniversalDialog>
  );
}

function Footer() {
  return (
    <Box display="flex" alignItems="center" justifyContent="flex-end">
      <CancelButton />
      <SaveLocation />
    </Box>
  );
}

function SaveLocation() {
  const profile = useRecoilValue(ProfilePageStates.profile);
  const profileId = profile?.id || '';
  const setOpenDialog = useSetRecoilState(ProfileLocationsDialog_Open);
  const location = useRecoilValue(LocationDialog_Data);
  const name = useRecoilValue(locationState.name);
  const addressLine1 = useRecoilValue(locationState.addressLine1);
  const addressLine2 = useRecoilValue(locationState.addressLine2);
  const city = useRecoilValue(locationState.city);
  const state = useRecoilValue(locationState.state);
  const postalCode = useRecoilValue(locationState.postalCode);
  const country = useRecoilValue(locationState.country);
  const code = useRecoilValue(locationState.code);
  const relatedPortCode = useRecoilValue(locationState.relatedPortCode);
  const companyPhone = useRecoilValue(locationState.companyPhone);
  const companyUrl = useRecoilValue(locationState.companyUrl);
  const locationTypes = useRecoilValue(locationState.locationTypes);
  const nameAliases = useRecoilValue(locationState.nameAliases);
  const codeAliases = useRecoilValue(locationState.codeAliases);

  const { enqueueSnackbar } = useSnackbar();

  const [updateLocation] = useUpdateLocationMutation({
    refetchQueries: () => [
      {
        query: ProfileQuery,
        variables: { profileId: profileId },
        fetchPolicy: 'cache-and-network',
      },
    ],
    onCompleted(data) {
      if (data.updateLocation.success) {
        setOpenDialog(false);
        enqueueSnackbar('Location Updated.', {
          variant: 'success',
          anchorOrigin: {
            vertical: 'top',
            horizontal: 'right',
          },
        });
      } else {
        enqueueSnackbar(data.updateLocation.message || 'Failed to update location.', {
          variant: 'error',
          anchorOrigin: {
            vertical: 'top',
            horizontal: 'right',
          },
        });
      }
    },
    onError(error) {
      enqueueSnackbar('Failed to update location. ' + error, {
        variant: 'error',
        anchorOrigin: {
          vertical: 'top',
          horizontal: 'right',
        },
      });
    },
  });

  const [saveLocation] = useNewProfileLocationMutation({
    refetchQueries: () => [
      {
        query: ProfileQuery,
        variables: { profileId: profileId },
        fetchPolicy: 'cache-and-network',
      },
    ],
    onCompleted(data) {
      if (data.newProfileLocation.success) {
        setOpenDialog(false);
        enqueueSnackbar('New Location Created.', {
          variant: 'success',
          anchorOrigin: {
            vertical: 'top',
            horizontal: 'right',
          },
        });
      } else {
        enqueueSnackbar(data.newProfileLocation.message || 'Failed to create location.', {
          variant: 'error',
          anchorOrigin: {
            vertical: 'top',
            horizontal: 'right',
          },
        });
      }
    },
    onError(error) {
      enqueueSnackbar('Failed to create location. ' + error, {
        variant: 'error',
        anchorOrigin: {
          vertical: 'top',
          horizontal: 'right',
        },
      });
    },
  });

  return (
    <Button
      variant="contained"
      color="primary"
      size="large"
      onClick={() => {
        if (location) {
          updateLocation({
            variables: {
              input: {
                location: {
                  id: location.id,
                  name: name,
                  nameAliases,
                  addressLine1: addressLine1,
                  addressLine2: addressLine2,
                  city: city,
                  state: state,
                  postalCode: postalCode,
                  country: country,
                  code: code,
                  codeAliases,
                  relatedPortCode: relatedPortCode?.code,
                  companyPhone: companyPhone,
                  companyUrl: companyUrl,
                  locationTypes,
                },
              },
            },
          });
        } else {
          saveLocation({
            variables: {
              input: {
                profileId: profileId,
                location: {
                  name: name,
                  nameAliases,
                  addressLine1: addressLine1,
                  addressLine2: addressLine2,
                  city: city,
                  state: state,
                  postalCode: postalCode,
                  country: country,
                  code: code,
                  codeAliases,
                  relatedPortCode: relatedPortCode?.code,
                  companyPhone: companyPhone,
                  companyUrl: companyUrl,
                  locationTypes,
                },
              },
            },
          });
        }
      }}
      style={{ marginRight: '12px' }}
    >
      {location ? 'Update Location' : 'Create Location'}
    </Button>
  );
}

function CancelButton() {
  const setOpenDialog = useSetRecoilState(ProfileLocationsDialog_Open);

  return (
    <Button
      variant="contained"
      color="default"
      size="large"
      onClick={() => {
        setOpenDialog(false);
      }}
      style={{ height: '40px', marginRight: '12px' }}
    >
      Cancel
    </Button>
  );
}
