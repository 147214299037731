import { Grid } from '@material-ui/core';
import { GUStates } from 'app/global-update/states';
import AtomicDatePickerV2 from 'components/atomic/AtomicDatePickerV2';
import AtomicNumberV2 from 'components/atomic/AtomicNumberV2';
import AtomicTextFieldV2 from 'components/atomic/AtomicTextFieldV2';
import SectionTitle from 'components/SectionTitle';
import TextFieldWarnDecorator from 'components/TextFieldWarnDecorator';
import { useRecoilValue } from 'recoil';

export default function GlobalUpdateStep2Customs() {
  const initCustomsBrokerRef = useRecoilValue(GUStates.initialState.initCustomsBrokerRef);
  const customsBrokerRef = useRecoilValue(GUStates.form.customsBrokerRef);
  const customsBrokerRefLabels = useRecoilValue(GUStates.form.customsBrokerRefLabels);

  const initIsfResponseNo = useRecoilValue(GUStates.initialState.initIsfResponseNo);
  const isfResponseNo = useRecoilValue(GUStates.form.isfResponseNo);
  const isfResponseNoLabels = useRecoilValue(GUStates.form.isfResponseNoLabels);

  const initIsfFilingDate = useRecoilValue(GUStates.initialState.initIsfFilingDate);
  const isfFilingDate = useRecoilValue(GUStates.form.isfFilingDate);
  const isfResponseFilingDateLabels = useRecoilValue(GUStates.form.isfFilingDateLabels);

  const initEntryNumber = useRecoilValue(GUStates.initialState.initEntryNumber);
  const entryNumber = useRecoilValue(GUStates.form.entryNumber);
  const entryNumberLabels = useRecoilValue(GUStates.form.entryNumberLabels);

  const initEntryDate = useRecoilValue(GUStates.initialState.initEntryDate);
  const entryDate = useRecoilValue(GUStates.form.entryDate);
  const entryDateLabels = useRecoilValue(GUStates.form.entryDateLabels);

  const initEntryValue = useRecoilValue(GUStates.initialState.initEntryValue);
  const entryValue = useRecoilValue(GUStates.form.entryValue);
  const entryValueLabels = useRecoilValue(GUStates.form.entryValueLabels);

  const initMpf = useRecoilValue(GUStates.initialState.initMpf);
  const mpf = useRecoilValue(GUStates.form.mpf);
  const mpfLabels = useRecoilValue(GUStates.form.mpfLabels);

  const initHmf = useRecoilValue(GUStates.initialState.initHmf);
  const hmf = useRecoilValue(GUStates.form.hmf);
  const hmfLabels = useRecoilValue(GUStates.form.hmfLabels);

  const initDuty = useRecoilValue(GUStates.initialState.initDuty);
  const duty = useRecoilValue(GUStates.form.duty);
  const dutyLabels = useRecoilValue(GUStates.form.dutyLabels);

  const initCustomsExamType = useRecoilValue(GUStates.initialState.initCustomsExamType);
  const customsExamType = useRecoilValue(GUStates.form.customsExamType);
  const customsExamTypeLabels = useRecoilValue(GUStates.form.customsExamTypeLabels);

  const initCustomsExamNotifyDate = useRecoilValue(GUStates.initialState.initCustomsExamNotifyDate);
  const customsExamNotifyDate = useRecoilValue(GUStates.form.customsExamNotifyDate);
  const customsExamNotifyDateLabels = useRecoilValue(GUStates.form.customsExamNotifyDateLabels);

  const initCustomsExamFee = useRecoilValue(GUStates.initialState.initCustomsExamFee);
  const customsExamFee = useRecoilValue(GUStates.form.customsExamFee);
  const customsExamFeeLabels = useRecoilValue(GUStates.form.customsExamFeeLabels);

  const initCustomsReleaseDate = useRecoilValue(GUStates.initialState.initCustomsReleaseDate);
  const customsReleaseDate = useRecoilValue(GUStates.form.customsReleaseDate);
  const customsReleaseDateLabels = useRecoilValue(GUStates.form.customsReleaseDateLabels);

  const initFdaExamType = useRecoilValue(GUStates.initialState.initFdaExamType);
  const fdaExamType = useRecoilValue(GUStates.form.fdaExamType);
  const fdaExamTypeLabels = useRecoilValue(GUStates.form.fdaExamTypeLabels);

  const initFdaExamNotifyDate = useRecoilValue(GUStates.initialState.initFdaExamNotifyDate);
  const fdaExamNotifyDate = useRecoilValue(GUStates.form.fdaExamNotifyDate);
  const fdaExamNotifyDateLabels = useRecoilValue(GUStates.form.fdaExamNotifyDateLabels);

  const initFdaExamFee = useRecoilValue(GUStates.initialState.initFdaExamFee);
  const fdaExamFee = useRecoilValue(GUStates.form.fdaExamFee);
  const fdaExamFeeLabels = useRecoilValue(GUStates.form.fdaExamFeeLabels);

  const initFdaReleaseDate = useRecoilValue(GUStates.initialState.initFdaReleaseDate);
  const fdaReleaseDate = useRecoilValue(GUStates.form.fdaReleaseDate);
  const fdaReleaseDateLabels = useRecoilValue(GUStates.form.fdaReleaseDateLabels);

  const initEntryFeeTotal = useRecoilValue(GUStates.initialState.initEntryFeeTotal);
  const entryFeeTotal = useRecoilValue(GUStates.form.entryFeeTotal);
  const entryFeeTotalLabels = useRecoilValue(GUStates.form.entryFeeTotalLabels);

  return (
    <>
      <SectionTitle title="Customs" />
      <Grid container>
        <Grid item xs={3}>
          <AtomicTextFieldV2 state={GUStates.form.customsBrokerRef} label="Customs Broker Ref" />
          <TextFieldWarnDecorator
            list={customsBrokerRefLabels}
            hasChanged={customsBrokerRef !== initCustomsBrokerRef}
            defaultValue={initCustomsBrokerRef}
            formState={GUStates.form.customsBrokerRef}
          />
        </Grid>
        <Grid item xs={3}>
          <AtomicTextFieldV2 state={GUStates.form.isfResponseNo} label="ISF Response No" />
          <TextFieldWarnDecorator
            list={isfResponseNoLabels}
            hasChanged={isfResponseNo !== initIsfResponseNo}
            defaultValue={initIsfResponseNo}
            formState={GUStates.form.isfResponseNo}
          />
        </Grid>
        <Grid item xs={3}>
          <AtomicDatePickerV2 state={GUStates.form.isfFilingDate} label="ISF Filing Date" />
          <TextFieldWarnDecorator
            list={isfResponseFilingDateLabels}
            hasChanged={isfFilingDate !== initIsfFilingDate}
            defaultValue={initIsfFilingDate}
            formState={GUStates.form.isfFilingDate}
          />
        </Grid>
        <Grid item xs={3}>
          <AtomicTextFieldV2 state={GUStates.form.entryNumber} label="Entry Number" />
          <TextFieldWarnDecorator
            list={entryNumberLabels}
            hasChanged={entryNumber !== initEntryNumber}
            defaultValue={initEntryNumber}
            formState={GUStates.form.entryNumber}
          />
        </Grid>
        <Grid item xs={3}>
          <AtomicDatePickerV2 state={GUStates.form.entryDate} label="Entry Date" />
          <TextFieldWarnDecorator
            list={entryDateLabels}
            hasChanged={entryDate !== initEntryDate}
            defaultValue={initEntryDate}
            formState={GUStates.form.entryDate}
          />
        </Grid>
        <Grid item xs={3}>
          <AtomicNumberV2 state={GUStates.form.entryValue} label="Entry Value" />
          <TextFieldWarnDecorator
            list={entryValueLabels}
            hasChanged={entryValue !== initEntryValue}
            defaultValue={initEntryValue}
            formState={GUStates.form.entryValue}
          />
        </Grid>
        <Grid item xs={3}>
          <AtomicNumberV2
            state={GUStates.form.mpf}
            label="MPF"
            startAdornment="$"
            minimumFractionDigits={2}
            maximumFractionDigits={2}
          />
          <TextFieldWarnDecorator
            list={mpfLabels}
            hasChanged={mpf !== initMpf}
            defaultValue={initMpf}
            formState={GUStates.form.mpf}
          />
        </Grid>
        <Grid item xs={3}>
          <AtomicNumberV2
            state={GUStates.form.hmf}
            label="HMF"
            startAdornment="$"
            minimumFractionDigits={2}
            maximumFractionDigits={2}
          />
          <TextFieldWarnDecorator
            list={hmfLabels}
            hasChanged={hmf !== initHmf}
            defaultValue={initHmf}
            formState={GUStates.form.hmf}
          />
        </Grid>
        <Grid item xs={3}>
          <AtomicNumberV2
            state={GUStates.form.duty}
            label="Duty"
            startAdornment="$"
            minimumFractionDigits={2}
            maximumFractionDigits={2}
          />
          <TextFieldWarnDecorator
            list={dutyLabels}
            hasChanged={duty !== initDuty}
            defaultValue={initDuty}
            formState={GUStates.form.duty}
          />
        </Grid>
        <Grid item xs={3}>
          <AtomicTextFieldV2 state={GUStates.form.customsExamType} label="Customs Exam Type" />
          <TextFieldWarnDecorator
            list={customsExamTypeLabels}
            hasChanged={customsExamType !== initCustomsExamType}
            defaultValue={initCustomsExamType}
            formState={GUStates.form.customsExamType}
          />
        </Grid>
        <Grid item xs={3}>
          <AtomicDatePickerV2
            state={GUStates.form.customsExamNotifyDate}
            label="Customs Exam Notify Date"
          />
          <TextFieldWarnDecorator
            list={customsExamNotifyDateLabels}
            hasChanged={customsExamNotifyDate !== initCustomsExamNotifyDate}
            defaultValue={initCustomsExamNotifyDate}
            formState={GUStates.form.customsExamNotifyDate}
          />
        </Grid>
        <Grid item xs={3}>
          <AtomicNumberV2
            state={GUStates.form.customsExamFee}
            label="Customs Exam Fee"
            startAdornment="$"
            minimumFractionDigits={2}
            maximumFractionDigits={2}
          />
          <TextFieldWarnDecorator
            list={customsExamFeeLabels}
            hasChanged={customsExamFee !== initCustomsExamFee}
            defaultValue={initCustomsExamFee}
            formState={GUStates.form.customsExamFee}
          />
        </Grid>
        <Grid item xs={3}>
          <AtomicDatePickerV2
            state={GUStates.form.customsReleaseDate}
            label="Customs Release Date"
          />
          <TextFieldWarnDecorator
            list={customsReleaseDateLabels}
            hasChanged={customsReleaseDate !== initCustomsReleaseDate}
            defaultValue={initCustomsReleaseDate}
            formState={GUStates.form.customsReleaseDate}
          />
        </Grid>
        <Grid item xs={3}>
          <AtomicTextFieldV2 state={GUStates.form.fdaExamType} label="FDA Exam Type" />
          <TextFieldWarnDecorator
            list={fdaExamTypeLabels}
            hasChanged={fdaExamType !== initFdaExamType}
            defaultValue={initFdaExamType}
            formState={GUStates.form.fdaExamType}
          />
        </Grid>
        <Grid item xs={3}>
          <AtomicDatePickerV2
            state={GUStates.form.fdaExamNotifyDate}
            label="FDA Exam Notify Date"
          />
          <TextFieldWarnDecorator
            list={fdaExamNotifyDateLabels}
            hasChanged={fdaExamNotifyDate !== initFdaExamNotifyDate}
            defaultValue={initFdaExamNotifyDate}
            formState={GUStates.form.fdaExamNotifyDate}
          />
        </Grid>
        <Grid item xs={3}>
          <AtomicNumberV2
            state={GUStates.form.fdaExamFee}
            label="FDA Exam Fee"
            startAdornment="$"
            minimumFractionDigits={2}
            maximumFractionDigits={2}
          />
          <TextFieldWarnDecorator
            list={fdaExamFeeLabels}
            hasChanged={fdaExamFee !== initFdaExamFee}
            defaultValue={initFdaExamFee}
            formState={GUStates.form.fdaExamFee}
          />
        </Grid>
        <Grid item xs={3}>
          <AtomicDatePickerV2 state={GUStates.form.fdaReleaseDate} label="FDA Release Date" />
          <TextFieldWarnDecorator
            list={fdaReleaseDateLabels}
            hasChanged={fdaReleaseDate !== initFdaReleaseDate}
            defaultValue={initFdaReleaseDate}
            formState={GUStates.form.fdaReleaseDate}
          />
        </Grid>
        <Grid item xs={3}>
          <AtomicNumberV2
            state={GUStates.form.entryFeeTotal}
            label="Entry Fee Total"
            startAdornment="$"
            minimumFractionDigits={2}
            maximumFractionDigits={2}
          />
          <TextFieldWarnDecorator
            list={entryFeeTotalLabels}
            hasChanged={entryFeeTotal !== initEntryFeeTotal}
            defaultValue={initEntryFeeTotal}
            formState={GUStates.form.entryFeeTotal}
          />
        </Grid>
      </Grid>
    </>
  );
}
