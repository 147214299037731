import { SortDirection, sortWithBy, stableSort } from '../lib/sort';

/**
 * This is how columns are defined
 */
interface UWLTableColumn_base<T extends {}> {
  id: Extract<keyof T, string>;
  label: string;
  labelTitle?: string;
  sortWith?: (a: T, b: T) => number;
  align?: 'left' | 'right' | 'center';
  whiteSpace?: 'nowrap' | 'pre-wrap';
}

interface UWLTableColumn_string<T extends {}> extends UWLTableColumn_base<T> {
  type: 'string';
  options?: { value: string; label: string }[];
}

interface UWLTableColumn_currency<T extends {}> extends UWLTableColumn_base<T> {
  type: 'currency';
  currencyField?: Extract<keyof T, string>;
}

interface UWLTableColumn_weight<T extends {}> extends UWLTableColumn_base<T> {
  type: 'weight';
  unitField?: Extract<keyof T, string>;
  unit?: string;
}

interface UWLTableColumn_volume<T extends {}> extends UWLTableColumn_base<T> {
  type: 'volume';
  unitField?: Extract<keyof T, string>;
  unit?: string;
}

interface UWLTableColumn_distance<T extends {}> extends UWLTableColumn_base<T> {
  type: 'distance';
  unitField?: Extract<keyof T, string>;
  unit?: string;
}

interface UWLTableColumn_date<T extends {}> extends UWLTableColumn_base<T> {
  type: 'date';
}

interface UWLTableColumn_datetime<T extends {}> extends UWLTableColumn_base<T> {
  type: 'datetime';
}

interface UWLTableColumn_number<T extends {}> extends UWLTableColumn_base<T> {
  type: 'number';
}

interface UWLTableColumn_bool<T extends {}> extends UWLTableColumn_base<T> {
  type: 'bool';
}

interface UWLTableColumn_link<T extends {}> extends UWLTableColumn_base<T> {
  type: 'link';
}

export type UWLTableColumn<T> =
  | UWLTableColumn_string<T>
  | UWLTableColumn_currency<T>
  | UWLTableColumn_weight<T>
  | UWLTableColumn_volume<T>
  | UWLTableColumn_distance<T>
  | UWLTableColumn_date<T>
  | UWLTableColumn_datetime<T>
  | UWLTableColumn_number<T>
  | UWLTableColumn_bool<T>
  | UWLTableColumn_link<T>;

export function isColumnNumeric(col: UWLTableColumn<any>): boolean {
  switch (col.type) {
    case 'currency':
    case 'weight':
    case 'volume':
    case 'distance':
    case 'number':
      return true;
  }
  return false;
}

export function toVisibleColumnsInOrder<T>(
  columns: UWLTableColumn<T>[],
  columnsDisplay?: string[]
): UWLTableColumn<T>[] {
  if (!columnsDisplay || !Array.isArray(columnsDisplay)) {
    return columns;
  }
  const result: UWLTableColumn<T>[] = [];
  for (const field of columnsDisplay) {
    const col = columns.find((col) => col.id === field);
    if (col) {
      result.push(col);
    }
  }
  return result;
}

export function sortedRows<T>(
  columns: UWLTableColumn<T>[],
  rows: T[],
  orderBy: keyof T | null = null,
  sortDirection: SortDirection = 'desc'
): T[] {
  if (orderBy) {
    const col = columns.find((col) => col.id === orderBy);
    const sortWith = (col && col.sortWith) || sortWithBy((row) => (row as any)[orderBy]);
    rows = stableSort(rows, sortWith, sortDirection);
  }
  return rows;
}
