import { faAddressCard, faLock } from '@fortawesome/free-solid-svg-icons';
import { Box, Typography } from '@material-ui/core';
import { RouteComponentProps } from '@reach/router';
import AdminCardList from 'app/admin/components/AdminCardList';
import SearchBar from 'components/SearchBar';
import { Line } from 'components/StyledComponents';
import React from 'react';
import { selector, useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import { genKey, newAtom } from 'recoil-utils/utils';
import { theme } from 'styles';
import { ManageUserPageStates, UserProfileContact } from '../states';
import UserProfileDialog, { UserProfileDialog_Data } from './UserProfileDialog';

const userProfileContactsSearchState = newAtom('');
const userProfileContactsListState = newAtom<ContactCardData[]>([]);
const filteredUserProfileContactsListState = selector({
  key: genKey(),
  get: ({ get }) => {
    const searchField = get<string>(userProfileContactsSearchState);
    const profileContacts = get(userProfileContactsListState);
    const lowerField = searchField.toLowerCase();

    if (searchField === '') {
      return profileContacts;
    } else {
      return profileContacts.filter(
        (profileContact: any) =>
          profileContact.name.toLowerCase().includes(lowerField) ||
          profileContact.roleName.toLowerCase().includes(lowerField)
      );
    }
  },
});

interface ContactCardData {
  id: string;
  name: string;
  roleName: string;
  userEnabled: string;
  profileContact: UserProfileContact;
}

interface Props extends RouteComponentProps {}
export default function UserProfiles(props: Props) {
  const user = useRecoilValue(ManageUserPageStates.user);
  const [searchField, setSearchField] = useRecoilState(userProfileContactsSearchState);
  const filteredProfileContacts = useRecoilValue(filteredUserProfileContactsListState);
  const setUserProfileContacts = useSetRecoilState<ContactCardData[]>(userProfileContactsListState);
  const setUserProfileDialog = useSetRecoilState(UserProfileDialog_Data);

  React.useEffect(() => {
    const userProfileContacts = user?.profileContacts ?? [];
    const profileContactsList = userProfileContacts.map((profileContact): ContactCardData => {
      const networkProfileName =
        profileContact.profile.network.name + ' : ' + profileContact.profile.name;
      return {
        id: profileContact.id,
        name: networkProfileName,
        roleName: profileContact.role?.name ?? '',
        userEnabled: profileContact.isUserEnabled ? 'True' : 'False',
        profileContact,
      };
    });
    setUserProfileContacts(profileContactsList);
  }, [user]);

  return (
    <div>
      <Box display="flex" justifyContent="space-between">
        <Box>
          <Typography variant="h2">User Profiles</Typography>
          <Typography variant="body1">
            List of profiles that this user is able to log in to
          </Typography>
        </Box>
        <Box width="25%" bgcolor={theme.palette.background.default} padding={1} alignSelf="center">
          <SearchBar
            placeholder="Search Network Connections"
            field={searchField}
            updateField={setSearchField}
          />
        </Box>
      </Box>
      <Line height={2} color={theme.palette.primary.main} />
      <AdminCardList<ContactCardData>
        list={filteredProfileContacts}
        iconList={[
          {
            name: 'Role',
            key: 'roleName',
            icon: faAddressCard,
            color: theme.palette.blueGreen.main,
          },
          {
            name: 'Enabled',
            key: 'userEnabled',
            icon: faLock,
            color: theme.palette.primary.light,
          },
        ]}
        onManageClicked={(item) => {
          setUserProfileDialog(item.profileContact);
        }}
      />
      <UserProfileDialog />
    </div>
  );
}
