import { faFileInvoiceDollar } from '@fortawesome/free-solid-svg-icons';
import { PartyType } from 'api/GQL_Types';
import { HotPoLineSwitch } from 'app/components/HotPoLineSwitch';
import { AlertIconButton } from 'app/dashboard/components/AlertIconButton';
import { HotState } from 'components/HotToggleSwitch';
import TableExportWindowGroup, { uwlColToGTable } from 'components/TableExportWindowGroup';
import { STANDARD_ROW_OVERALL_HEIGHT, UWLTable } from 'components/UWLTable/UWLTable';
import { useWindowSize } from 'lib/useWindowSize';
import React from 'react';
import { useRecoilValue } from 'recoil';
import { UWLTableColumn } from 'types/UWLTable';
import { mapLineStatus } from 'utils/Enums';
import { formatNumber } from 'utils/Numbers';
import { ExceptionQueryResult } from '.';
import { PopoverTable } from '../../components/PopoverTable';
import { DashboardPageStates, TableLink } from '../../states';

export interface HotPOException {
  id: string;
  hot: HotState;
  poNumber: TableLink;
  lineStatus: string;
  itemNumber: string | null;
  qty: number | null;
  vendor: string;
  shipToLocationName: string;
}

export function HotPOException_toRow(exception: ExceptionQueryResult): HotPOException | null {
  if (exception.__typename !== 'HotPurchaseOrder') {
    return null;
  }
  const openOrder = exception.openOrder;

  return {
    id: openOrder.orderLine.id,
    hot: {
      isHot: openOrder.isHot,
      hotMarkedBy: openOrder.hotMarkedBy,
      hotMarkedTimestamp: openOrder.hotMarkedTimestamp,
    },
    poNumber: {
      to: '/purchase-orders/' + openOrder.orderLine.purchaseOrder.id,
      value: openOrder.orderLine.purchaseOrder.poNumber,
    },
    lineStatus: mapLineStatus(openOrder.orderLine.lineStatus),
    itemNumber: openOrder.orderLine.item.itemNumber,
    qty: openOrder.orderLine.totalQty ?? 0,
    vendor:
      openOrder.orderLine.purchaseOrder.relatedParties.find(
        (party: { partyType: PartyType }) => party.partyType === PartyType.Supplier
      )?.party.name ?? '',
    shipToLocationName: openOrder.orderLine.shipToLocation?.name || '',
  };
}

const columns: UWLTableColumn<HotPOException>[] = [
  { id: 'hot', label: '!', type: 'string' },
  { id: 'poNumber', label: 'PO #', type: 'link' },
  { id: 'lineStatus', label: 'Status', type: 'string' },
  { id: 'itemNumber', label: 'Item / SKU', type: 'string' },
  { id: 'qty', label: 'Total Qty', type: 'number', align: 'left' },
  { id: 'vendor', label: 'Vendor', type: 'string' },
  { id: 'shipToLocationName', label: 'Destination', type: 'string' },
];

const exportColumns = columns.filter((c) => c.id !== 'hot').map(uwlColToGTable);

interface Props {}

export const HotPOExceptionCmpt: React.FC<Props> = (props) => {
  const [open, setOpen] = React.useState(false);
  const refAnchor = React.useRef<HTMLDivElement>(null);
  const windowSize = useWindowSize();

  const exceptions = useRecoilValue(DashboardPageStates.exceptions);
  const rows = exceptions.hotPurchaseOrders;

  return (
    <>
      <AlertIconButton
        innerRef={refAnchor}
        name="Hot Purchase Orders"
        faIcon={faFileInvoiceDollar}
        addHotIcon
        unreadCount={rows.length}
        onClick={() => setOpen(true)}
      />

      <PopoverTable
        anchorEl={refAnchor.current}
        open={open}
        onClose={() => setOpen(false)}
        title="Hot Purchase Orders"
        titleRight={
          <TableExportWindowGroup label="Hot Purchase Orders" rows={rows} columns={exportColumns} />
        }
        totalLabel="Total Hot Purchase Orders"
        totalValue={formatNumber(rows.length)}
        width={windowSize.width * 0.8}
        bodyHeight={rows.length * STANDARD_ROW_OVERALL_HEIGHT}
      >
        {open && (
          <UWLTable
            rowId={'id'}
            rows={rows}
            columns={columns}
            emptyMessage="No Hot Purchase Orders"
            virtualize={'single-line-cells'}
            renderCell={{
              hot(row) {
                return <HotPoLineSwitch orderLineId={row.id} initialState={row.hot} small />;
              },
            }}
          />
        )}
      </PopoverTable>
    </>
  );
};
