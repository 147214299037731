import { faUserCheck } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Avatar, Box, Button, Divider, Grid, Tooltip, Typography } from '@material-ui/core';
import WarningIcon from '@material-ui/icons/Warning';
import LineItem from 'components/LineItem';
import { ListCard } from 'components/ListCard';
import React from 'react';
import { theme } from 'styles';
import { getAccentColorFromUUID } from 'utils/Colors';
import { AdminPagesContact } from '../states';

interface Props {
  // Contact to display
  contact: AdminPagesContact;
  // Callback for when manage button is clicked
  onManageClicked?: (contact: AdminPagesContact) => void;
  // If provided the contact card will display the profile name
  profileName?: string;
}

export default function ContactCard(props: Props) {
  return (
    <Grid item xs={12} sm={6} md={4} xl={3}>
      <ListCard>
        <Box height={1} display="flex" flexDirection="column" paddingBottom={2}>
          <Box padding={2} display="flex" justifyContent="space-between" alignItems="center">
            <Box display="flex">
              <Avatar style={{ backgroundColor: getAccentColorFromUUID(props.contact.id) }}>
                {props.contact.firstName[0]}
                {props.contact.lastName[0]}
              </Avatar>
              <Box marginLeft={1}>
                <Typography variant="h4">
                  {props.contact.firstName} {props.contact.lastName}
                </Typography>
                <Box>{props.contact.title}</Box>
              </Box>
            </Box>
            <Box display="flex" alignItems="center">
              {props.contact.needsUpdate && (
                <Box marginRight={1}>
                  <Tooltip title="Needs Update" interactive>
                    <WarningIcon style={{ color: theme.palette.warning.main }} />
                  </Tooltip>
                </Box>
              )}
              {props.contact.isUserEnabled && (
                <FontAwesomeIcon
                  icon={faUserCheck}
                  size="lg"
                  color={theme.palette.secondary.main}
                />
              )}
            </Box>
          </Box>
          <Box flexGrow={1}>
            <Divider />
            {props.profileName && (
              <LineItem label="Profile" value={props.profileName} disableStripe />
            )}
            <LineItem label="Email" value={props.contact.email} disableStripe />
            <LineItem label="Phone (Main)" value={props.contact.phoneMain} disableStripe />
            <LineItem label="Phone (Cell)" value={props.contact.phoneCell} disableStripe />
            {props.contact.location && (
              <LineItem label="Location" value={props.contact.location.name} disableStripe />
            )}
          </Box>
          {props.onManageClicked && (
            <Box textAlign="center" marginTop={2}>
              <Button
                variant="contained"
                color="secondary"
                style={{ width: '50%' }}
                onClick={() => {
                  props.onManageClicked && props.onManageClicked(props.contact);
                }}
              >
                Manage
              </Button>
            </Box>
          )}
        </Box>
      </ListCard>
    </Grid>
  );
}
