import { faShieldAlt } from '@fortawesome/free-solid-svg-icons';
import { Box, Typography } from '@material-ui/core';
import { RouteComponentProps } from '@reach/router';
import { PermissionCode, SystemPermissionCode } from 'api/GQL_Types';
import { auth } from 'app';
import SearchBar from 'components/SearchBar';
import { Line } from 'components/StyledComponents';
import React from 'react';
import { selector, useRecoilState, useRecoilValue } from 'recoil';
import { genKey, newAtom } from 'recoil-utils/utils';
import { theme } from 'styles';
import RolesCardList, { RoleObj } from '../../components/RolesCardList';
import { ProfileTypePageStates } from './states';

const profileTypeRolesSearchState = newAtom<string>('');

const filteredProfileTypeRolesListState = selector<RoleObj[]>({
  key: genKey(),
  get: ({ get }) => {
    const searchField = get(profileTypeRolesSearchState);
    const profileType = get(ProfileTypePageStates.profileType);
    const lowerField = searchField.toLowerCase().trim();

    let roles = profileType?.defaultRoles || [];

    if (lowerField !== '') {
      roles = roles.filter((role) => {
        return role.name.toLowerCase().includes(lowerField);
      });
    }

    return roles.map((role): RoleObj => {
      return {
        id: role.id,
        name: role.name,
        description: role.description || '',
        permissionLength: role.permissionCodes.length,
        permissions: role.permissionCodes,
        notificationCodes: role.notificationCodes,
        dashboardFeatureCodes: role.dashboardFeatureCodes,
        documentTypesCanView: role.documentTypesCanView,
      };
    });
  },
});

interface RoleProps extends RouteComponentProps {}
export default function ProfileTypeRoles(props: RoleProps) {
  const { userContext } = auth.useAuthState();
  const profileType = useRecoilValue(ProfileTypePageStates.profileType);
  const [searchField, setSearchField] = useRecoilState(profileTypeRolesSearchState);
  const filteredProfileTypeRoles = useRecoilValue(filteredProfileTypeRolesListState);

  const canCreate = profileType?.isTemplate
    ? !!userContext?.systemPermissionCodes.has(SystemPermissionCode.ProfileTypeTemplatesRoleCreate)
    : !!userContext?.permissionCodes.has(PermissionCode.ProfileTypeRoleCreate);

  const canEdit = profileType?.isTemplate
    ? !!userContext?.systemPermissionCodes.has(SystemPermissionCode.ProfileTypeTemplatesRoleUpdate)
    : !!userContext?.permissionCodes.has(PermissionCode.ProfileTypeRoleUpdate);

  return (
    <div>
      <Box display="flex" justifyContent="space-between">
        <Box>
          <Typography variant="h2">Profile Type Default Roles</Typography>
          <Typography variant="body1">
            List of default roles that are connected to this profile type
          </Typography>
        </Box>
        <Box width="25%" bgcolor={theme.palette.background.default} padding={1} alignSelf="center">
          <SearchBar
            placeholder="Search Profile Type Roles"
            field={searchField}
            updateField={setSearchField}
          />
        </Box>
      </Box>
      <Line height={2} color={theme.palette.primary.main} />
      {filteredProfileTypeRoles.length === 0 && (
        <div>
          {searchField.length > 0 ? (
            <div>No roles matching search: "{searchField}"</div>
          ) : (
            <div>- none -</div>
          )}
        </div>
      )}
      <RolesCardList
        filteredList={filteredProfileTypeRoles}
        iconList={[
          {
            name: 'Permissions',
            key: 'permissionLength',
            icon: faShieldAlt,
            color: theme.palette.dodgerBlue.main,
          },
          {
            key: 'description',
          },
        ]}
        type="Profile Type"
        id={profileType?.id || ''}
        canCreate={canCreate}
        canEdit={canEdit}
      />
    </div>
  );
}
