import React from 'react';
import { makeCss, theme } from 'styles';

const classes = makeCss({
  root: {
    width: '-webkit-fill-available',
    margin: theme.spacing(1, 3, 1, 0),
    flexGrow: 1,
  },
});

interface Props {
  maxWidth?: number;
}

export const FormItem: React.FC<Props> = ({ maxWidth, children }) => {
  return (
    <div className={classes.root} style={maxWidth ? { maxWidth } : undefined}>
      {children}
    </div>
  );
};
