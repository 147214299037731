import { AppWideStates } from 'app/states';
import FormInputSelect from 'components/form/FormInputSelect';
import { FormItem } from 'components/form/FormItem';
import React from 'react';
import { RecoilState, useRecoilState, useRecoilValue } from 'recoil';

interface Props {
  state: RecoilState<string | null>;

  required?: boolean;

  // When set, allow the user to select null as a valid option.
  nullOptionLabel?: string;
}

export const AtomicDocumentType: React.FC<Props> = (props) => {
  const [documentType, setDocumentType] = useRecoilState(props.state);
  const documentTypes = useRecoilValue(AppWideStates.network.documentTypes).all;

  return (
    <FormItem>
      <FormInputSelect
        label="Document Type"
        options={documentTypes.map((dt) => {
          return { id: dt.code, label: `${dt.code} - ${dt.name}` };
        })}
        value={documentType}
        onValue={setDocumentType}
        error={props.required && !documentType}
        required={props.required}
        nullOptionLabel={props.nullOptionLabel}
      />
    </FormItem>
  );
};
