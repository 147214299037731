import { Box, Button, Grid, Typography } from '@material-ui/core';
import { Link } from '@reach/router';
import { GqlBooking, PermissionCode } from 'api/GQL_Types';
import { userContextAtom } from 'app';
import LineItem from 'components/LineItem';
import Panel from 'components/Panel';
import React from 'react';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { portToStringMaybe } from 'types/Port';
import { formatDate } from 'utils/Dates';
import { BookingPageStates, fromGQL_VesselOption, fromGQL_VoyageOption } from '../states';
import EditLogisticsDialog, { EditLogisticsDialog_Open } from './edit-logistics-dialog';

interface Props {
  bookingId: string;
}

export default function LogisticDetailsPanel(props: Props) {
  const userContext = useRecoilValue(userContextAtom);
  const setEditLogisticsDialog = useSetRecoilState(EditLogisticsDialog_Open);

  const booking = useRecoilValue(BookingPageStates.booking);

  const setConfirmationNumber = useSetRecoilState(
    BookingPageStates.editLogistics.confirmationNumber
  );
  const setConfirmationDate = useSetRecoilState(BookingPageStates.editLogistics.confirmationDate);

  const setCarrier = useSetRecoilState(BookingPageStates.editLogistics.carrier);
  const setCarrierConfirmation = useSetRecoilState(
    BookingPageStates.editLogistics.carrierConfirmationNumber
  );
  const setContract = useSetRecoilState(BookingPageStates.editLogistics.contract);
  const setContractType = useSetRecoilState(BookingPageStates.editLogistics.contractType);
  const setHbl = useSetRecoilState(BookingPageStates.editLogistics.hbl);
  const setMbl = useSetRecoilState(BookingPageStates.editLogistics.mbl);
  const setHblPaymentType = useSetRecoilState(BookingPageStates.editLogistics.hblPaymentType);
  const setMblPaymentType = useSetRecoilState(BookingPageStates.editLogistics.mblPaymentType);
  const setHblReleaseType = useSetRecoilState(BookingPageStates.editLogistics.hblReleaseType);
  const setMblReleaseType = useSetRecoilState(BookingPageStates.editLogistics.mblReleaseType);

  const setCfsCutoff = useSetRecoilState(BookingPageStates.editLogistics.cfsCutoff);
  const setCfsReceived = useSetRecoilState(BookingPageStates.editLogistics.cfsReceived);
  const setCyCutoff = useSetRecoilState(BookingPageStates.editLogistics.cyCutoff);
  const setVgmCutoff = useSetRecoilState(BookingPageStates.editLogistics.vgmCutoff);
  const setSiCutoff = useSetRecoilState(BookingPageStates.editLogistics.siCutoff);

  const setPol = useSetRecoilState(BookingPageStates.editLogistics.pol);
  const setPolEtd = useSetRecoilState(BookingPageStates.editLogistics.polEtd);
  const setPolAtd = useSetRecoilState(BookingPageStates.editLogistics.polAtd);

  const setTransitPort = useSetRecoilState(BookingPageStates.editLogistics.transitPort);
  const setTransitPortEta = useSetRecoilState(BookingPageStates.editLogistics.transitPortEta);
  const setTransitPortAta = useSetRecoilState(BookingPageStates.editLogistics.transitPortAta);
  const setTransitPortEtd = useSetRecoilState(BookingPageStates.editLogistics.transitPortEtd);
  const setTransitPortAtd = useSetRecoilState(BookingPageStates.editLogistics.transitPortAtd);

  const setPod = useSetRecoilState(BookingPageStates.editLogistics.pod);
  const setPodEta = useSetRecoilState(BookingPageStates.editLogistics.podEta);
  const setRamp = useSetRecoilState(BookingPageStates.editLogistics.ramp);
  const setRampEta = useSetRecoilState(BookingPageStates.editLogistics.rampEta);
  const setFinalDestination = useSetRecoilState(BookingPageStates.editLogistics.finalDestination);
  const setDeliveryEta = useSetRecoilState(BookingPageStates.editLogistics.deliveryEta);

  const setMotherVessel = useSetRecoilState(BookingPageStates.editLogistics.motherVessel);
  const setMotherVoyage = useSetRecoilState(BookingPageStates.editLogistics.motherVoyage);
  const setFeederVessel = useSetRecoilState(BookingPageStates.editLogistics.feederVessel);
  const setFeederVoyage = useSetRecoilState(BookingPageStates.editLogistics.feederVoyage);

  return (
    <Panel
      title="Logistics Details"
      topRight={
        ((bookingIsConfirmed(booking) &&
          userContext?.permissionCodes.has(PermissionCode.BookingLogisticsUpdate)) ||
          (!bookingIsConfirmed(booking) &&
            userContext?.permissionCodes.has(PermissionCode.BookingConfirm))) && (
          <Button
            variant="contained"
            color="secondary"
            onClick={() => {
              setConfirmationNumber(booking?.logistics.confirmationNumber ?? '');
              setConfirmationDate(booking?.logistics.confirmationDate);

              setCarrier(booking?.logistics.carrier ?? null);
              setCarrierConfirmation(booking?.logistics.carrierConfirmationNumber ?? '');
              setContract(booking?.logistics.contractNumber ?? '');
              setContractType(booking?.logistics.contractType ?? null);
              setHbl(booking?.hbl?.referenceNumber ?? '');
              setMbl(booking?.mbl?.referenceNumber ?? '');
              setHblPaymentType(booking?.hbl?.paymentType ?? null);
              setMblPaymentType(booking?.mbl?.paymentType ?? null);
              setHblReleaseType(booking?.hbl?.releaseType ?? null);
              setMblReleaseType(booking?.mbl?.releaseType ?? null);

              setCfsCutoff(booking?.logistics.cfsCutoffDate);
              setCfsReceived(booking?.logistics.cfsReceivedDate);
              setCyCutoff(booking?.logistics.cyCutoffDate);
              setVgmCutoff(booking?.logistics.vgmCutoffDate);
              setSiCutoff(booking?.logistics.siCutoffDate);

              setPol(booking?.logistics.pol ?? null);
              setPolEtd(booking?.logistics.polEtd);
              setPolAtd(booking?.logistics.polAtd);

              setTransitPort(booking?.logistics.transitPort ?? null);
              setTransitPortEta(booking?.logistics.transitPortEta ?? null);
              setTransitPortAta(booking?.logistics.transitPortAta ?? null);
              setTransitPortEtd(booking?.logistics.transitPortEtd ?? null);
              setTransitPortAtd(booking?.logistics.transitPortAtd ?? null);

              setPod(booking?.logistics.pod ?? null);
              setPodEta(booking?.logistics.podEta);
              setRamp(booking?.logistics.ramp ?? null);
              setRampEta(booking?.logistics.rampEta);
              setFinalDestination(booking?.logistics.finalDestination ?? null);
              setDeliveryEta(booking?.logistics.deliveryEta ?? null);

              setMotherVessel(
                booking?.logistics.motherVessel
                  ? fromGQL_VesselOption(booking?.logistics.motherVessel)
                  : null
              );
              setMotherVoyage(
                booking?.logistics.motherVoyage
                  ? fromGQL_VoyageOption(booking?.logistics.motherVoyage)
                  : null
              );
              setFeederVessel(
                booking?.logistics.feederVessel
                  ? fromGQL_VesselOption(booking?.logistics.feederVessel)
                  : null
              );
              setFeederVoyage(
                booking?.logistics.feederVoyage
                  ? fromGQL_VoyageOption(booking?.logistics.feederVoyage)
                  : null
              );

              setEditLogisticsDialog(true);
            }}
          >
            Edit
          </Button>
        )
      }
    >
      <Box padding={3}>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={6}>
            <LineItem value={booking?.logistics?.confirmationNumber} label="Forwarder Job" />
            <LineItem
              value={booking?.mbl?.referenceNumber}
              label="MBL"
              customValue={(value: any) => {
                return userContext?.permissionCodes.has(PermissionCode.MblRead) ? (
                  <Link to={'/mbl/' + booking?.mbl?.id} state={{ bookingId: props.bookingId }}>
                    {value}
                  </Link>
                ) : (
                  <Typography variant="body1">{value}</Typography>
                );
              }}
            />
            <LineItem
              value={booking?.hbl?.referenceNumber}
              label="HBL"
              customValue={(value: any) => {
                return (
                  <Link to={'/hbl/' + booking?.hbl?.id} state={{ bookingId: props.bookingId }}>
                    {value}
                  </Link>
                );
              }}
            />
            <LineItem
              value={booking?.logistics?.cyCutoffDate}
              decorator={formatDate}
              label="CY Cutoff"
            />
            <LineItem
              value={booking?.logistics?.cfsCutoffDate}
              label="CFS Cutoff"
              decorator={formatDate}
            />
            <LineItem
              value={booking?.logistics?.cfsReceivedDate}
              decorator={formatDate}
              label="CFS Received"
            />
            <LineItem
              value={booking?.logistics?.vgmCutoffDate}
              decorator={formatDate}
              label="VGM Cutoff"
            />
            <LineItem
              value={booking?.logistics?.siCutoffDate}
              decorator={formatDate}
              label="SI Cutoff"
            />
            <LineItem value={booking?.logistics?.motherVessel?.name} label="Mother Vessel" />
            <LineItem value={booking?.logistics?.motherVoyage?.name} label="Voyage" />
            <LineItem value={booking?.logistics?.feederVessel?.name} label="Feeder Vessel" />
          </Grid>
          <Grid item xs={12} sm={6}>
            <LineItem
              value={portToStringMaybe(booking?.logistics?.transitPort)}
              label="Transit Port"
            />
            <LineItem value={portToStringMaybe(booking?.logistics?.pol)} label="POL" />
            <LineItem value={booking?.logistics?.polEtd} decorator={formatDate} label="POL ETD" />
            <LineItem value={booking?.logistics?.polAtd} decorator={formatDate} label="POL ATD" />
            <LineItem value={portToStringMaybe(booking?.logistics?.pod)} label="POD" />
            <LineItem value={booking?.logistics?.podEta} decorator={formatDate} label="POD ETA" />
            <LineItem value={portToStringMaybe(booking?.logistics?.ramp)} label="Ramp" />
            <LineItem value={booking?.logistics?.rampEta} decorator={formatDate} label="Ramp ETA" />
            <LineItem
              value={booking?.logistics?.finalDestination?.name}
              label="Final Destination"
            />
            <LineItem
              value={booking?.logistics?.deliveryEta}
              decorator={formatDate}
              label="Final Destination ETA"
            />
          </Grid>
        </Grid>
      </Box>
      <EditLogisticsDialog />
    </Panel>
  );
}

function bookingIsConfirmed(booking: GqlBooking | null): boolean {
  return (
    Boolean(booking?.logistics.confirmationNumber) && Boolean(booking?.logistics.confirmationDate)
  );
}
