import { Box, Typography } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import { RouteComponentProps } from '@reach/router';
import { SystemPermissionCode, useUpdateUserMutation } from 'api/GQL_Types';
import { UserQuery } from 'api/queries/userQueries';
import { AppWideStates } from 'app/states';
import { Line } from 'components/StyledComponents';
import { SwitchListGroup } from 'components/SwitchListGroup';
import { useSnackbar } from 'notistack';
import React from 'react';
import { useRecoilValue } from 'recoil';
import { ManageUserPageStates } from '../states';

interface Props extends RouteComponentProps {}

export const SystemPermissions: React.FC<Props> = (props) => {
  const user = useRecoilValue(ManageUserPageStates.user);
  const systemPermissions = useRecoilValue(AppWideStates.systemPermissions);
  const [selectedCodes, setSelectedCodes] = React.useState<SystemPermissionCode[]>([]);
  const { enqueueSnackbar } = useSnackbar();

  React.useEffect(() => {
    setSelectedCodes(user?.systemPermissionCodes ?? []);
  }, [user]);

  const [updateUser, { loading }] = useUpdateUserMutation({
    refetchQueries: () => [
      {
        query: UserQuery,
        variables: { userId: user?.id },
        fetchPolicy: 'cache-and-network',
      },
    ],
    onCompleted(data) {
      if (data.updateUser.success) {
        enqueueSnackbar('User Updated.', {
          variant: 'success',
          anchorOrigin: {
            vertical: 'top',
            horizontal: 'right',
          },
        });
      }
    },
  });

  const groupsRendered: React.ReactNode[] = [];
  for (const groupName of systemPermissions.groupOrder) {
    const perms = systemPermissions.byGroup.get(groupName) || [];
    if (perms.length === 0) {
      continue;
    }
    groupsRendered.push(
      <SwitchListGroup
        key={groupName}
        title={groupName}
        possibleValues={perms.map((perm) => {
          return {
            key: perm.code,
            value: perm.code,
            label: perm.name,
            tooltip: perm.description,
          };
        })}
        values={selectedCodes}
        onToggle={(code, checked) => {
          if (checked) {
            setSelectedCodes(selectedCodes.concat([code]));
          } else {
            setSelectedCodes(selectedCodes.filter((c) => c !== code));
          }
        }}
        disabled={loading}
        defaultExpanded
      />
    );
  }

  return (
    <div>
      <Box display="flex" justifyContent="space-between">
        <Box>
          <Typography variant="h2">System Permissions</Typography>
          <Typography variant="body1">
            Permissions this user is allowed across the entire system.
          </Typography>
        </Box>
        <Box>
          <Button
            variant="contained"
            color="secondary"
            disabled={loading}
            onClick={() => {
              if (!user) return;
              updateUser({
                variables: {
                  input: {
                    user: {
                      id: user.id,
                      systemPermissionCodes: selectedCodes,
                    },
                  },
                },
              });
            }}
            style={{ width: '150px' }}
          >
            Save
          </Button>
        </Box>
      </Box>
      <Line height={1} />
      <div>{groupsRendered.length === 0 ? '- none -' : groupsRendered}</div>
    </div>
  );
};
