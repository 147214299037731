import { GqlExceptionsQuery } from 'api/GQL_Types';
import { DelayedUnloadException, DelayedUnloadException_toRow } from './DelayedUnloadException';
import {
  EmptyNotReturnedException,
  EmptyNotReturnedException_toRow,
} from './EmptyNotReturnedException';
import { HotContainerException, HotContainerException_toRow } from './HotContainerException';
import { HotPOException, HotPOException_toRow } from './HotPOException';
import { HotShipmentException, HotShipmentException_toRow } from './HotShipmentException';
import {
  LateBookingException,
  LateBookingExceptionGrouped,
  LateBookingException_toGroupedRows,
  LateBookingException_toRow,
} from './LateBookingException';
import {
  MissingDocumentDisplay,
  MissingDocumentsException_toRow,
} from './MissingDocumentsException';
import { PendingOutgateException, PendingOutgateException_toRow } from './PendingOutgateException';

export type ExceptionQueryResult = GqlExceptionsQuery['exceptions'][number];

export interface DashExceptions {
  hotShipments: HotShipmentException[];
  hotContainers: HotContainerException[];
  hotPurchaseOrders: HotPOException[];
  missingDocuments: MissingDocumentDisplay[];
  pendingOutgate: PendingOutgateException[];
  emptyNotReturned: EmptyNotReturnedException[];
  bookingLate: LateBookingException[];
  bookingLateGrouped: LateBookingExceptionGrouped[];
  delayedUnload: DelayedUnloadException[];
}

export function emptyState(): DashExceptions {
  return {
    hotShipments: [],
    hotContainers: [],
    hotPurchaseOrders: [],
    missingDocuments: [],
    pendingOutgate: [],
    emptyNotReturned: [],
    bookingLate: [],
    bookingLateGrouped: [],
    delayedUnload: [],
  };
}

export function initDashExceptions(data: GqlExceptionsQuery): DashExceptions {
  const state = emptyState();

  for (const result of data.exceptions) {
    let hotShipment = HotShipmentException_toRow(result);
    if (hotShipment) {
      state.hotShipments.push(hotShipment);
    }
    let hotContainer = HotContainerException_toRow(result);
    if (hotContainer) {
      state.hotContainers.push(hotContainer);
    }
    let hotPo = HotPOException_toRow(result);
    if (hotPo) {
      state.hotPurchaseOrders.push(hotPo);
    }
    let missingDoc = MissingDocumentsException_toRow(result);
    if (missingDoc) {
      state.missingDocuments.push(missingDoc);
    }
    let pendingOutgate = PendingOutgateException_toRow(result);
    if (pendingOutgate) {
      state.pendingOutgate.push(pendingOutgate);
    }
    let emptyNotReturned = EmptyNotReturnedException_toRow(result);
    if (emptyNotReturned) {
      state.emptyNotReturned.push(emptyNotReturned);
    }
    let bookingLate = LateBookingException_toRow(result);
    if (bookingLate) {
      state.bookingLate.push(bookingLate);
    }
    let delayedUnload = DelayedUnloadException_toRow(result);
    if (delayedUnload) {
      state.delayedUnload.push(delayedUnload);
    }
  }

  state.bookingLateGrouped = LateBookingException_toGroupedRows(state.bookingLate);

  return state;
}
