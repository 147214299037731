import { Box, PopoverActions, Typography } from '@material-ui/core';
import { GTableColumn } from 'components/g-table/types';
import UWLTableV2 from 'components/g-table/UWLTableV2';
import TableExportWindowGroup from 'components/TableExportWindowGroup';
import UniversalPopover from 'components/UniversalPopover';
import React from 'react';
import { useRecoilValue } from 'recoil';
import { DashboardPageStates, PoTable } from '../states';

const columns: GTableColumn<PoTable>[] = [
  { id: 'poLink', label: 'PO #', type: 'link', weight: 150 },
  { id: 'lineStatus', label: 'Status', type: 'string', weight: 100 },
  { id: 'itemNumber', label: 'Item / SKU', type: 'string', weight: 150 },
  { id: 'qty', label: 'Total Qty', type: 'number', weight: 100 },
  { id: 'vendor', label: 'Vendor', type: 'string', weight: 200 },
  { id: 'shipToLocationName', label: 'Destination', type: 'string', weight: 200 },
  { id: 'expectedCargoReadyDate', label: 'Expected CRD', type: 'date', weight: 100 },
  { id: 'indcDate', label: 'Expected InDC', type: 'date', weight: 100 },
  { id: 'poValue', label: 'PO Value', type: 'string', weight: 100 },
];

interface Props {
  anchorEl: any;
  onClose: Function;
}

export default function POButtonsPopover(props: Props) {
  const selectedOpenData = useRecoilValue(DashboardPageStates.po.selectedOpenData);
  const openPopover = useRecoilValue(DashboardPageStates.po.openPopover);
  const popoverLabel = useRecoilValue(DashboardPageStates.po.popoverLabel);
  const action: React.RefObject<PopoverActions> | null = React.useRef(null);
  const [tableHeight, setTableHeight] = React.useState(0);

  React.useEffect(() => {
    if (action && action.current) {
      action.current.updatePosition();
    }
  }, [tableHeight]);

  if (openPopover === null) {
    return null;
  }

  return (
    <UniversalPopover
      popoverId="po-buttons-popover"
      open={openPopover}
      action={action}
      anchorEl={props.anchorEl}
      onClose={() => {
        props.onClose();
      }}
      anchorOriginVertical={20}
      anchorOriginHorizontal={0}
    >
      <Box padding={3} width={window.innerWidth * 0.6} display="flex" flexDirection="column">
        <Box display="flex" justifyContent="space-between" marginBottom={2}>
          <Box marginRight={3} display="flex" alignItems="center">
            <Typography variant="h3">{popoverLabel}</Typography>
            <TableExportWindowGroup
              label="PurchaseOrders"
              rows={selectedOpenData}
              columns={columns}
            />
          </Box>
          <Box marginLeft={3}>
            <Box display="flex" alignItems="center">
              <Box marginRight={1}>
                <Typography variant="h4">
                  <i>Total Purchase Orders:</i>
                </Typography>
              </Box>
              <Typography variant="h3">{selectedOpenData?.length ?? 0}</Typography>
            </Box>
          </Box>
        </Box>
        <UWLTableV2
          columns={columns}
          rows={selectedOpenData}
          emptyMessage="No Purchase Orders"
          maxHeight={500}
          onHeightChange={(height) => {
            setTableHeight(height);
          }}
        />
      </Box>
    </UniversalPopover>
  );
}
