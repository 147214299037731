import { Box } from '@material-ui/core';
import { ShipmentInTransitMilestone, ShipmentInTransitStatus } from 'api/GQL_Types';
import CompositePanel from 'components/CompositePanel';
import React from 'react';
import { useRecoilValue } from 'recoil';
import { DashboardPageStates, ShipmentStatuses } from '../states';
import BarGraphs from './bar-graph';
import { ShipmentsInTransitHeading } from './ShipmentsInTransitHeading';

interface Props {
  containerHeight: number;
}

export default function ShipmentsInTransit(props: Props) {
  const inTransit = useRecoilValue(DashboardPageStates.inTransit);

  const [milestones, setMilestones] = React.useState({
    onWater: emptyStatuses(),
    atDischarge: emptyStatuses(),
    atRamp: emptyStatuses(),
    delivered: emptyStatuses(),
    totals: emptyStatuses(),
  });
  const [selectedDestination, setSelectedDestination] = React.useState('All');
  const [destinations, setDestinations] = React.useState<string[]>(['All']);

  React.useEffect(() => {
    if (inTransit) {
      const locations = inTransit
        .map((row) => {
          return row.container.shipment.logistics.finalDestination?.name;
        })
        .filter((location) => location);
      const transitDestinations = locations.filter(
        (value, index) => locations.indexOf(value) === index
      );
      setDestinations(Array.from(new Set([...destinations, ...transitDestinations])) as string[]);
      applyRules();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inTransit]);

  React.useEffect(() => {
    if (selectedDestination) {
      applyRules();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedDestination]);

  const applyRules = () => {
    const onWater = emptyStatuses();
    const atDischarge = emptyStatuses();
    const atRamp = emptyStatuses();
    const delivered = emptyStatuses();

    for (const transit of inTransit) {
      if (
        (selectedDestination !== 'All' &&
          selectedDestination !== transit.container.shipment.logistics.finalDestination?.name) ??
        ''
      ) {
        continue;
      }
      switch (transit.milestone) {
        case ShipmentInTransitMilestone.OnWater:
          switch (transit.status) {
            case ShipmentInTransitStatus.Early:
              onWater.early.push(transit.container);
              break;
            case ShipmentInTransitStatus.OnTime:
              onWater.onTime.push(transit.container);
              break;
            case ShipmentInTransitStatus.Late:
              onWater.late.push(transit.container);
              break;
          }
          break;
        case ShipmentInTransitMilestone.AtDischarge:
          switch (transit.status) {
            case ShipmentInTransitStatus.Early:
              atDischarge.early.push(transit.container);
              break;
            case ShipmentInTransitStatus.OnTime:
              atDischarge.onTime.push(transit.container);
              break;
            case ShipmentInTransitStatus.Late:
              atDischarge.late.push(transit.container);
              break;
          }
          break;
        case ShipmentInTransitMilestone.AtRamp:
          switch (transit.status) {
            case ShipmentInTransitStatus.Early:
              atRamp.early.push(transit.container);
              break;
            case ShipmentInTransitStatus.OnTime:
              atRamp.onTime.push(transit.container);
              break;
            case ShipmentInTransitStatus.Late:
              atRamp.late.push(transit.container);
              break;
          }
          break;
        case ShipmentInTransitMilestone.Delivered:
          switch (transit.status) {
            case ShipmentInTransitStatus.Early:
              delivered.early.push(transit.container);
              break;
            case ShipmentInTransitStatus.OnTime:
              delivered.onTime.push(transit.container);
              break;
            case ShipmentInTransitStatus.Late:
              delivered.late.push(transit.container);
              break;
          }
          break;
      }
    }

    setMilestones({
      onWater: onWater,
      atDischarge: atDischarge,
      atRamp: atRamp,
      delivered: delivered,
      totals: {
        early: [...onWater.early, ...atRamp.early, ...atDischarge.early, ...delivered.early],
        onTime: [...onWater.onTime, ...atRamp.onTime, ...atDischarge.onTime, ...delivered.onTime],
        late: [...onWater.late, ...atRamp.late, ...atDischarge.late, ...delivered.late],
      },
    });
  };

  return (
    <CompositePanel>
      <ShipmentsInTransitHeading
        destinations={destinations}
        selectedDestination={selectedDestination}
        onSelectedDestination={setSelectedDestination}
      />
      <Box padding={1}>
        <BarGraphs data={milestones} />
      </Box>
    </CompositePanel>
  );
}

const emptyStatuses = (): ShipmentStatuses => {
  return {
    early: [],
    onTime: [],
    late: [],
  };
};
