import { MoveType } from 'api/GQL_Types';
import { FormInputMultiSelect } from 'components/form/FormInputMultiSelect';
import React from 'react';
import { useRecoilState } from 'recoil';
import { mapMoveType } from 'utils/Enums';
import { NetworkAdminPageStates } from '../states';
import { InputItem, InputRow } from './components/InputLayout';
import { RuleCard } from './components/RuleCard';
import { RuleCardGroup } from './components/RuleCardGroup';

const MoveTypeOptions: { id: string; label: string }[] = Object.values(MoveType).map((t) => ({
  id: t + '',
  label: mapMoveType(t),
}));

interface Props {}

export const RulesConsolidation: React.FC<Props> = (props) => {
  const [rulesForm, setRulesForm] = useRecoilState(NetworkAdminPageStates.rulesForm);

  if (!rulesForm) {
    return <div></div>;
  }

  return (
    <RuleCardGroup title="Consolidation" bools={[rulesForm.consolConsolableMoveTypesEnabled]}>
      <RuleCard
        name="Move Type for Consolidation"
        description="Which booking move types are to be considered for consolidation."
        enabled={rulesForm.consolConsolableMoveTypesEnabled}
        onEnabled={(enabled) => {
          setRulesForm({ ...rulesForm, consolConsolableMoveTypesEnabled: enabled });
        }}
      >
        <InputRow>
          <InputItem>
            <FormInputMultiSelect
              label="Move Types"
              options={MoveTypeOptions}
              value={rulesForm.consolConsolableMoveTypes}
              onValue={(types: MoveType[]) =>
                setRulesForm({ ...rulesForm, consolConsolableMoveTypes: types })
              }
            />
          </InputItem>
        </InputRow>
      </RuleCard>
    </RuleCardGroup>
  );
};
