import { GqlReportFieldAliasInput, GqlReportInput } from 'api/GQL_Types';
import * as moment from 'moment-timezone';
import { NotifierHook } from '../lib/NotifierHook';
import { DatasetFilter } from '../types/Dataset';
import { isValidEmail } from '../types/Email';
import { Report } from '../types/Report';
import { Weekday } from '../types/Weekday';
import reportCategoriesStore from './reportCategoriesStore';

export interface Values {
  name: string;
  scheduled: boolean;
  period: null | 'weekly' | 'monthly';
  weekDays: Weekday[];
  days: number[];
  time: null | { hour: number; minute: number };
  timezone: string | null;
  datasetId: string | null;

  fields: string[];
  fieldAliases: { [field: string]: string };

  filters: DatasetFilter[];

  recipientContactIds: string[];
  recipientEmails: string[];
}

interface Validation {
  name: string | null;
  period: string | null;
  time: string | null;
  timezone: string | null;
  weekDays: string | null;
  days: string | null;
  datasetId: string | null;

  fields: string | null;

  filters: string | null;

  recipients: string | null;
}

interface State {
  values: Values;
  doValidation: boolean;
  validation: Validation;
  maxStep: 1 | 2 | 3 | 4 | 5;
  isTemplate: boolean;
}

const blankState: State = {
  values: {
    name: '',
    scheduled: true,
    period: 'weekly',
    weekDays: ['mon'],
    days: [1],
    time: { hour: 17, minute: 0 },
    timezone: moment.tz.guess(),
    datasetId: null,

    fields: [],
    fieldAliases: {},

    filters: [],

    recipientContactIds: [],
    recipientEmails: [],
  },
  doValidation: false, // don't start validating until they click `next`
  validation: {
    name: null,
    period: null,
    time: null,
    timezone: null,
    weekDays: null,
    days: null,
    datasetId: null,
    fields: null,
    filters: null,
    recipients: null,
  },
  maxStep: 1,
  isTemplate: false,
};

function validNotBlank(v: any): string | null {
  if (typeof v === 'string' && v.trim().length > 0) {
    return null;
  }
  return 'Required';
}

function validate(values: Values) {
  const validation: Validation = {
    name: validNotBlank(values.name),
    period: null,
    time: null,
    timezone: null,
    weekDays: null,
    days: null,
    datasetId: values.datasetId ? null : 'Required',
    fields: values.fields.length === 0 ? 'No columns selected' : null,
    filters: null,
    recipients: null,
  };

  for (const f of values.filters) {
    const vf = values.datasetId
      ? reportCategoriesStore.validateFilterInput(values.datasetId, f)
      : 'Category not selected';
    if (vf) {
      validation.filters = vf;
      break;
    }
  }

  if (values.scheduled) {
    validation.period = values.period ? null : 'Required';
    validation.time = values.time ? null : 'Required';
    validation.timezone = values.timezone ? null : 'Required';
    switch (values.period) {
      case 'weekly':
        validation.weekDays = values.weekDays.length === 0 ? 'No days selected' : null;
        break;
      case 'monthly':
        validation.days = values.days.length === 0 ? 'No days selected' : null;
        break;
    }
  }

  const validEmails = values.recipientEmails.filter(
    (email) => email.trim().length > 0 && isValidEmail(email)
  );
  validation.recipients =
    values.recipientContactIds.length + validEmails.length === 0 ? 'No recipients are set' : null;

  if (validation.recipients === null) {
    if (
      values.recipientEmails.filter((email) => !isValidEmail(email) && email.trim().length > 0)
        .length > 0
    ) {
      validation.recipients = 'Invalid email';
    }
  }

  return validation;
}

function getMaxStep(validation: Validation): 1 | 2 | 3 | 4 | 5 {
  if (
    validation.name !== null ||
    validation.period !== null ||
    validation.time !== null ||
    validation.timezone !== null ||
    validation.weekDays !== null ||
    validation.days !== null ||
    validation.datasetId !== null
  ) {
    return 1;
  }
  if (validation.fields !== null) {
    return 2;
  }
  if (validation.filters !== null) {
    return 3;
  }
  if (validation.recipients !== null) {
    return 4;
  }
  return 5;
}

export default (function reportFormStore() {
  let state: State = blankState;
  state.maxStep = getMaxStep(validate(state.values));

  const notifier = NotifierHook();

  function set(newState: State) {
    state = newState;
    notifier.notify();
  }

  function doValidation() {
    const validation = validate(state.values);
    set({ ...state, doValidation: true, validation, maxStep: getMaxStep(validation) });
  }

  return {
    use() {
      notifier.use();
      return state;
    },

    doValidation,

    startValidation() {
      if (!state.doValidation) {
        doValidation();
      }
    },

    put(v: Partial<Values>) {
      const values: Values = Object.assign({}, state.values, v);
      const validation = validate(values);
      set({
        ...state,
        values,
        validation: state.doValidation ? validation : state.validation,
        maxStep: getMaxStep(validation),
      });
    },

    init(report: Report | null) {
      if (report) {
        set({
          ...blankState,
          values: {
            name: report.name,
            scheduled: report.scheduled,
            period: report.period,
            weekDays: report.weekdays,
            days: report.days,
            time: { hour: report.timeHour, minute: report.timeMinute },
            timezone: report.timezone,

            datasetId: report.datasetId,
            fields: report.fields,
            fieldAliases: report.fieldAliases,
            filters: report.filters,

            recipientContactIds: report.recipientContactIds || [],
            recipientEmails: report.recipientEmails || [],
          },
          isTemplate: false,
        });
        doValidation();
      } else {
        set(blankState);
      }
    },

    toReport(id: string | null): GqlReportInput {
      doValidation();

      if (state.maxStep < 5) {
        throw new Error('Not valid');
      }

      const aliases: GqlReportFieldAliasInput[] = [];
      Object.keys(state.values.fieldAliases).forEach((key) => {
        aliases.push({
          field: key,
          name: state.values.fieldAliases[key],
        });
      });

      return {
        id: id,

        name: state.values.name,

        scheduled: state.values.scheduled,

        period: state.values.period === 'weekly' ? 'weekly' : 'monthly',
        weekdays: state.values.weekDays,
        days: state.values.days,
        timeHour: intOr(state.values.time && state.values.time.hour, 0),
        timeMinute: intOr(state.values.time && state.values.time.minute, 0),
        timezone:
          typeof state.values.timezone === 'string' ? state.values.timezone : moment.tz.guess(),

        datasetId: state.values.datasetId || '',
        fields: state.values.fields,
        fieldAliases: aliases,
        filters: state.values.filters,

        recipientContactIds: state.values.recipientContactIds,
        recipientEmails: state.values.recipientEmails.filter(
          (email) => email.trim().length > 0 && isValidEmail(email)
        ),
      };
    },
  };
})();

function intOr(val: any, fallback: number): number {
  if (typeof val === 'number' && (val ^ 0) === val) {
    return val;
  }
  throw fallback;
}
