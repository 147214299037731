import { faFire } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Box, Button, Grid, Typography } from '@material-ui/core';
import { navigate, RouteComponentProps } from '@reach/router';
import { GqlConsolidation, GqlProfile, PartyType, PermissionCode, useAssignConsolidationRelatedPartyMutation, useConsolidationLazyQuery, useConsolidationQuery, useNetworkProfilesQuery, useRemoveConsolidationRelatedPartyMutation, useUpdateConsolidationRelatedPartyMutation } from 'api/GQL_Types';
import { userContextAtom } from 'app';
import { DocumentsPanel } from 'app/components/DocumentsPanel';
import Loading from 'app/Loading';
import OverrideRelatedPartiesDialog from 'components/related-parties-override';
import { Remark, sortRemarks } from 'components/Remarks';
import { useSnackbar } from 'notistack';
import React from 'react';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import { newAtom } from 'recoil-utils/utils';
import ConsolidationBookingsPanel from './bookings';
import ConsolidationDetailsPanel from './consolidation-details';
import ConsolidationEquipment from './equipment';
import LogisticDetailsPanel from './logistics';
import ConsolidationRemarks from './remarks';
import { ConsolidationPageStates } from './states';

export const bookingRelatedPartiesState = newAtom([]);
const openRelatedPartiesDialog = newAtom(false);

export interface ConsolidationDetailProps extends RouteComponentProps {
  consolidationId?: string;
}

export default function ConsolidationDetails(props: ConsolidationDetailProps) {
  const userContext = useRecoilValue(userContextAtom);
  const consolidationId = props.consolidationId ?? '';
  const [consolidation, setConsolidation] = useRecoilState(ConsolidationPageStates.consolidation);
  const [allDocuments, setAllDocuments] = useRecoilState(ConsolidationPageStates.allDocuments);
  const setAllRemarks = useSetRecoilState(ConsolidationPageStates.allRemarks);
  const setRelatedParties = useSetRecoilState(bookingRelatedPartiesState);
  const setOpenDialog = useSetRecoilState(openRelatedPartiesDialog);
  const [isHot, setIsHot] = React.useState(false);
  const { enqueueSnackbar } = useSnackbar();

  const [assignConsolidationRelatedParty] = useAssignConsolidationRelatedPartyMutation();
  const [removeConsolidationRelatedParty] = useRemoveConsolidationRelatedPartyMutation();
  const [updateConsolidationRelatedParty] = useUpdateConsolidationRelatedPartyMutation();

  const [refetchConsolidationQuery] = useConsolidationLazyQuery({
    variables: { consolId: consolidation?.id ?? '' },
    fetchPolicy: 'network-only',
    onCompleted: (data) => {
      if (data.consolidation) {
        enqueueSnackbar('Consolidation Related Parties Updated!', {
          variant: 'success',
          anchorOrigin: {
            vertical: 'top',
            horizontal: 'right',
          },
        });
        setOpenDialog(false);
      }
    },
  });

  const { data: consolidationData, loading } = useConsolidationQuery({
    variables: { consolId: props.consolidationId ?? '' },
    fetchPolicy: 'network-only',
  });

  const { data: profilesData } = useNetworkProfilesQuery({
    variables: { networkId: userContext?.activeContact?.profile.network.id ?? '' },
    fetchPolicy: 'network-only',
  });

  const [profileOptions, setProfileOptions] = React.useState<GqlProfile[]>([]);

  React.useEffect(() => {
    if (profilesData?.network) {
      setProfileOptions(profilesData.network.connectedProfiles as GqlProfile[]);
    }
  }, [profilesData]);

  React.useEffect(() => {
    if (consolidationData) {
      const consolidation = consolidationData.consolidation;
      if (!consolidation) {
        navigate('/bookings');
      } else {
        if (consolidation.id) {
          let remarks: Remark[] = [];
          remarks = remarks.concat(consolidation.remarks ?? []);
          remarks = sortRemarks(remarks);
          setAllRemarks(remarks);

          let bookDocList: any = [];
          let hblDocList: any = [];
          for (const book of consolidation.bookings) {
            for (const doc of book.documents) {
              bookDocList.push({
                id: doc?.id,
                hblId: book.hbl?.id,
                hblNumber: book.hbl?.referenceNumber ?? '',
                documentType: doc?.documentType,
                importFileName: doc?.importFileName,
                fileType: doc?.fileType,
                fileSize: doc?.fileSize,
                uploadDate: doc?.uploadDate,
                description: doc?.description,
              });
            }
            for (const doc of book.hbl?.documents ?? []) {
              hblDocList.push({
                id: doc?.id,
                blId: book.hbl?.id,
                blNumber: book.hbl?.referenceNumber,
                documentType: doc?.documentType,
                importFileName: doc?.importFileName,
                fileType: doc?.fileType,
                fileSize: doc?.fileSize,
                uploadDate: doc?.uploadDate,
                description: doc?.description,
              });
            }
          }
          setAllDocuments([...bookDocList, ...hblDocList]);
        }
        setConsolidation(consolidation as GqlConsolidation);
        setRelatedParties(consolidation.relatedParties as any);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [consolidationData, loading]);

  React.useEffect(() => {
    if (consolidation?.containers) {
      for (const con of consolidation?.containers) {
        if (con.isHot) {
          setIsHot(true);
          return;
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [consolidation]);

  if (!consolidation || loading || !userContext || !Array.isArray(consolidation?.relatedParties)) {
    return <Loading />;
  }

  return (
    <Box>
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        marginBottom={2}
        marginLeft={1}
        marginTop={1}
      >
        <Box display="flex" alignItems="center">
          <Typography variant="h2" color="primary">
            Consolidation: {consolidation?.referenceNumber}
          </Typography>
          {isHot && (
            <FontAwesomeIcon
              icon={faFire}
              style={{ color: 'orange', marginLeft: '12px' }}
              size="lg"
            />
          )}
        </Box>
        {userContext?.permissionCodes.has(PermissionCode.ConsolidationRelatedPartiesOverride) && (
          <>
            <Button
              variant="contained"
              color="primary"
              size="large"
              onClick={() => {
                setOpenDialog(true);
              }}
            >
              Consolidation Party Override
            </Button>
            <OverrideRelatedPartiesDialog
              openState={openRelatedPartiesDialog}
              relatedParties={consolidation.relatedParties}
              profileOptions={profileOptions}
              onOverrideClicked={async (changes) => {
                await Promise.all(
                  changes.delete.map((item) =>
                    removeConsolidationRelatedParty({
                      variables: {
                        input: {
                          relatedPartyId: item.relationId,
                        },
                      },
                    })
                  )
                );
                await Promise.all(
                  changes.new.map((item) =>
                    assignConsolidationRelatedParty({
                      variables: {
                        input: {
                          consolidationId: consolidation?.id ?? '',
                          profileId: item.profile ? item.profile.id : '',
                          partyType: item.partyType ?? PartyType.Related,
                        },
                      },
                    })
                  )
                );
                await Promise.all(
                  changes.update.map((item) =>
                    updateConsolidationRelatedParty({
                      variables: {
                        input: {
                          consolId: consolidation?.id ?? '',
                          relatedPartyId: item.relationId,
                          profileId: item.profile ? item.profile.id : '',
                          partyType: item.partyType ?? PartyType.Related,
                        },
                      },
                    })
                  )
                );
                refetchConsolidationQuery();
              }}
            />
          </>
        )}
      </Box>
      <Grid container spacing={2} alignItems="stretch" direction="row">
        <Grid item xs={12} md={6}>
          <ConsolidationDetailsPanel />
        </Grid>
        <Grid item xs={12} md={6}>
          <LogisticDetailsPanel consolidationId={consolidationId} />
        </Grid>
        <Grid item xs={12}>
          <ConsolidationRemarks />
        </Grid>
        <Grid item xs={12}>
          <ConsolidationEquipment consolidationId={consolidationId} />
        </Grid>
        <Grid item xs={12}>
          <ConsolidationBookingsPanel consolidationId={consolidationId} />
        </Grid>
        <Grid item xs={12}>
          <DocumentsPanel documents={allDocuments} showBookingNumber showHblNumber />
        </Grid>
      </Grid>
    </Box>
  );
}
