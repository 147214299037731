import { Box, Button, Grid, IconButton, TextField, Typography } from '@material-ui/core';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import DeleteIcon from '@material-ui/icons/Close';
import RemoveCircleIcon from '@material-ui/icons/RemoveCircle';
import AtomicContainerType from 'components/atomic/AtomicContainerType';
import Panel from 'components/Panel';
import { Line } from 'components/StyledComponents';
import { UWLTable } from 'components/UWLTable/UWLTable';
import React from 'react';
import { useRecoilState } from 'recoil';
import { newAtom } from 'recoil-utils/utils';
import { theme } from 'styles';
import { UWLTableColumn } from 'types/UWLTable';
import { EquipmentQty, NewConsolidationStates } from '../states';

const selectedContainerTypeAtom = newAtom<string | null>(null);

const columns: UWLTableColumn<EquipmentQty>[] = [
  { id: 'code', label: 'Equipment Type', type: 'string' },
  { id: 'qty', label: 'Equipment Qty', type: 'number', align: 'left' },
];

export default function EquipmentSelecting() {
  const [equipmentList, setEquipmentList] = useRecoilState(
    NewConsolidationStates.step1.equipmentList
  );
  const [equipmentType, setEquipmentType] = useRecoilState(selectedContainerTypeAtom);
  const [equipmentQty, setEquipmentQty] = React.useState<number>(0);

  return (
    <Panel title="Equipment Selection">
      <Line height={1} />
      <Box height="300px" display="flex" flexDirection="column">
        <Box flexGrow={1} style={{ margin: theme.spacing(1, 2, 2, 2), overflow: 'auto' }}>
          <UWLTable
            rowId="code"
            rows={equipmentList}
            columns={columns}
            renderCell={{
              code(row) {
                return <Typography variant="body1">{row.code}</Typography>;
              },
            }}
            rowAction={(row) => {
              return (
                <IconButton
                  aria-label="delete"
                  style={{ width: '10px', height: '10px' }}
                  onClick={() => {
                    setEquipmentList(equipmentList.filter((item) => item.code !== row.code));
                  }}
                >
                  <DeleteIcon />
                </IconButton>
              );
            }}
          />
        </Box>
        <Box marginLeft={2} marginRight={2} marginBottom={1}>
          <Box display="flex" flexGrow={1} marginBottom={1}>
            <Typography variant="h3" color="secondary">
              Add Equipment
            </Typography>
          </Box>
          <Grid container alignItems="center">
            <Grid item xs={5}>
              <AtomicContainerType state={selectedContainerTypeAtom} showMinMax />
            </Grid>

            <Grid item xs={5}>
              <Box display="flex" justifyContent="center" alignItems="center">
                <Box marginRight={2}>
                  <Button
                    disabled={equipmentQty === 0 || !equipmentType}
                    onClick={() => {
                      setEquipmentQty(equipmentQty - 1);
                    }}
                    style={{ padding: '0', minWidth: 'auto' }}
                  >
                    <RemoveCircleIcon
                      color={!equipmentQty || !equipmentType ? 'disabled' : 'secondary'}
                      fontSize="default"
                    />
                  </Button>
                </Box>
                <Box>
                  <TextField
                    required
                    id="qty"
                    disabled={!equipmentType}
                    InputProps={{ inputProps: { min: 0 } }}
                    label="Qty"
                    variant="outlined"
                    size="small"
                    type="number"
                    value={equipmentQty}
                    onChange={(e) => {
                      parseInt(e.target.value) >= 0
                        ? setEquipmentQty(+e.target.value)
                        : setEquipmentQty(0);
                    }}
                  />
                </Box>
                <Box marginLeft={2}>
                  <Button
                    disabled={!equipmentType}
                    onClick={() => {
                      setEquipmentQty(equipmentQty + 1);
                    }}
                    style={{ padding: '0', minWidth: 'auto' }}
                  >
                    <AddCircleIcon
                      color={!equipmentType ? 'disabled' : 'secondary'}
                      fontSize="default"
                    />
                  </Button>
                </Box>
              </Box>
            </Grid>

            <Grid item xs={2}>
              <Box display="flex" flexGrow={1} justifyContent="flex-end">
                <Button
                  variant="contained"
                  color="secondary"
                  style={{ width: '96px' }}
                  onClick={() => {
                    if (!equipmentType) return;
                    let found = false;
                    const list = equipmentList.map((eqp) => {
                      if (eqp.code === equipmentType) {
                        found = true;
                        return {
                          ...eqp,
                          qty: eqp.qty + equipmentQty,
                        };
                      }
                      return eqp;
                    });
                    if (!found) {
                      list.push({ code: equipmentType, qty: equipmentQty });
                    }
                    setEquipmentList(list);
                    setEquipmentType(null);
                    setEquipmentQty(0);
                  }}
                  disabled={equipmentQty < 1}
                >
                  <Typography variant="h3" color="textSecondary">
                    ADD
                  </Typography>
                </Button>
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Box>
    </Panel>
  );
}
