import { faFire } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Box, Button, Grid, Typography } from '@material-ui/core';
import { navigate, RouteComponentProps } from '@reach/router';
import {
  GqlMasterBill,
  GqlProfile,
  PartyType,
  PermissionCode,
  useAssignMblRelatedPartyMutation,
  useMblLazyQuery,
  useMblQuery,
  useNetworkProfilesQuery,
  useNewMblRemarkMutation,
  useRemoveMblRelatedPartyMutation,
  useUpdateMblRelatedPartyMutation,
} from 'api/GQL_Types';
import { MblQuery } from 'api/queries/mblQueries';
import { userContextAtom } from 'app';
import { Document, DocumentsPanel } from 'app/components/DocumentsPanel';
import Loading from 'app/Loading';
import OverrideRelatedPartiesDialog from 'components/related-parties-override';
import Remarks, { Remark, sortRemarks } from 'components/Remarks';
import { useSnackbar } from 'notistack';
import React from 'react';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import { AtomGroupCleaner, newAtom } from 'recoil-utils/utils';
import EquipmentPanel from './equipment';
import MBLDetailsPanel from './mbl-details-panel';
import RelatedHblPanel from './related-hbl';
import { MblPageStates } from './states';

const openRelatedPartiesDialog = newAtom(false);

interface mblProps extends RouteComponentProps {
  mblId?: string;
}
export default function MblDetails(props: mblProps) {
  const mblId = props.mblId ?? '';
  const userContext = useRecoilValue(userContextAtom);
  const [mbl, setMbl] = useRecoilState(MblPageStates.mbl);
  const [allRemarks, setAllRemarks] = useRecoilState(MblPageStates.allRemarks);
  const [allDocuments, setAllDocuments] = useRecoilState(MblPageStates.allDocuments);
  const [isHot, setIsHot] = React.useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const setOpenOverrideDialog = useSetRecoilState(openRelatedPartiesDialog);

  const [assignMblRelatedParty] = useAssignMblRelatedPartyMutation();
  const [removeMblRelatedParty] = useRemoveMblRelatedPartyMutation();
  const [updateMblRelatedParty] = useUpdateMblRelatedPartyMutation();

  const [refetchMblQuery] = useMblLazyQuery({
    variables: { mblId: mblId },
    fetchPolicy: 'network-only',
    onCompleted: (data) => {
      if (data.mbl) {
        enqueueSnackbar('MBL Related Parties Updated!', {
          variant: 'success',
          anchorOrigin: {
            vertical: 'top',
            horizontal: 'right',
          },
        });
        setOpenOverrideDialog(false);
      }
    },
  });

  const { data: mblData } = useMblQuery({
    variables: { mblId: mblId },
    fetchPolicy: 'cache-and-network',
  });

  const [saveMblRemark] = useNewMblRemarkMutation({
    refetchQueries: () => [
      {
        query: MblQuery,
        variables: { mblId: mblId },
      },
    ],
    onCompleted(data) {
      if (data.newMblRemark.success) {
        enqueueSnackbar('New MBL Remark Created.', {
          variant: 'success',
          anchorOrigin: {
            vertical: 'top',
            horizontal: 'right',
          },
        });
      }
    },
  });

  const { data: profilesData } = useNetworkProfilesQuery({
    variables: { networkId: userContext?.activeContact?.profile.network.id ?? '' },
    fetchPolicy: 'network-only',
  });

  const [profileOptions, setProfileOptions] = React.useState<GqlProfile[]>([]);

  React.useEffect(() => {
    if (profilesData?.network) {
      setProfileOptions(profilesData.network.connectedProfiles as GqlProfile[]);
    }
  }, [profilesData]);

  React.useEffect(() => {
    if (mblData) {
      const mbl = mblData.mbl;
      if (!mbl) {
        navigate('/bookings');
      } else {
        if (mbl.id) {
          const hbl = mbl.shipment.__typename === 'Booking' ? mbl.shipment.hbl : null;

          let remarks: Remark[] = [];
          remarks = remarks.concat(hbl?.remarks ?? []);
          remarks = remarks.concat(mbl?.shipment.remarks ?? []);
          remarks = remarks.concat(mbl?.remarks ?? []);
          remarks = sortRemarks(remarks);
          setAllRemarks(remarks);

          let hblDocuments: Document[] = [];
          let bookingDocuments: Document[] = [];
          let mblDocuments: Document[] = [];
          for (const doc of mbl.documents) {
            mblDocuments.push({
              id: doc.id,
              bookingId: '',
              bookingNumber: '',
              hblId: '',
              hblNumber: '',
              documentType: doc.documentType,
              fileName: doc.fileName,
              importFileName: doc.importFileName,
              fileType: doc.fileType,
              fileSize: doc.fileSize,
              uploadDate: doc.uploadDate,
              description: doc.description || '',
            });
          }
          for (const doc of mbl.shipment.documents) {
            bookingDocuments.push({
              id: doc.id,
              bookingId: mbl.shipment.id,
              bookingNumber: mbl.shipment.referenceNumber,
              hblId: hbl?.id,
              hblNumber: hbl?.referenceNumber,
              documentType: doc.documentType,
              fileName: doc.fileName,
              importFileName: doc.importFileName,
              fileType: doc.fileType,
              fileSize: doc.fileSize,
              uploadDate: doc.uploadDate,
              description: doc.description || '',
            });
          }
          for (const doc of hbl?.documents ?? []) {
            hblDocuments.push({
              id: doc.id,
              bookingId: '',
              bookingNumber: '',
              hblId: hbl?.id,
              hblNumber: hbl?.referenceNumber,
              documentType: doc.documentType,
              fileName: doc.fileName,
              importFileName: doc.importFileName,
              fileType: doc.fileType,
              fileSize: doc.fileSize,
              uploadDate: doc.uploadDate,
              description: doc.description || '',
            });
          }

          setAllDocuments([...bookingDocuments, ...mblDocuments, ...hblDocuments]);
          setMbl(mbl as GqlMasterBill);
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mblData]);

  React.useEffect(() => {
    if (mbl?.shipment.containers) {
      for (const con of mbl?.shipment.containers) {
        if (con.isHot) {
          setIsHot(true);
          return;
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mbl]);

  if (!mbl) {
    return <Loading />;
  }

  return (
    <>
      <AtomGroupCleaner atomGroup={MblPageStates} />
      <Grid container alignItems="stretch" direction="column" spacing={2}>
        <Grid item xs={12}>
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            marginLeft={1}
            marginTop={1}
          >
            <Box display="flex" alignItems="center">
              <Typography variant="h2" color="primary">
                MBL: {mbl?.referenceNumber}
              </Typography>
              {isHot && (
                <FontAwesomeIcon
                  icon={faFire}
                  style={{ color: 'orange', marginLeft: '12px' }}
                  size="lg"
                />
              )}
            </Box>
            {userContext?.permissionCodes.has(PermissionCode.MblRelatedPartiesOverride) && (
              <>
                <Button
                  variant="contained"
                  color="primary"
                  size="large"
                  onClick={() => {
                    setOpenOverrideDialog(true);
                  }}
                >
                  Mbl Party Override
                </Button>
                <OverrideRelatedPartiesDialog
                  openState={openRelatedPartiesDialog}
                  relatedParties={mbl.relatedParties}
                  profileOptions={profileOptions}
                  onOverrideClicked={async (changes) => {
                    await Promise.all(
                      changes.delete.map((item) =>
                        removeMblRelatedParty({
                          variables: {
                            input: {
                              relatedPartyId: item.relationId,
                            },
                          },
                        })
                      )
                    );
                    await Promise.all(
                      changes.new.map((item) =>
                        assignMblRelatedParty({
                          variables: {
                            input: {
                              mblId: mblId,
                              profileId: item.profile ? item.profile.id : '',
                              partyType: item.partyType ?? PartyType.Related,
                            },
                          },
                        })
                      )
                    );
                    await Promise.all(
                      changes.update.map((item) =>
                        updateMblRelatedParty({
                          variables: {
                            input: {
                              mblId: mblId,
                              relatedPartyId: item.relationId,
                              profileId: item.profile ? item.profile.id : '',
                              partyType: item.partyType ?? PartyType.Related,
                            },
                          },
                        })
                      )
                    );
                    refetchMblQuery();
                  }}
                />
              </>
            )}
          </Box>
        </Grid>
        <Grid item xs={12}>
          <Grid container spacing={2} justify="center">
            <Grid item xs={9}>
              <MBLDetailsPanel />
            </Grid>
            <Grid item xs={3}>
              <RelatedHblPanel />
            </Grid>
          </Grid>
        </Grid>

        <Grid item xs={12}>
          <Remarks
            title="Remarks"
            allowComments
            remarks={allRemarks}
            makeRemark={(text) => {
              saveMblRemark({
                variables: {
                  input: {
                    mblId: mblId,
                    remark: {
                      text: text,
                    },
                  },
                },
              });
            }}
          />
        </Grid>
        <Grid item xs={12}>
          <EquipmentPanel />
        </Grid>
        <Grid item xs={12}>
          <DocumentsPanel documents={allDocuments} showBookingNumber showHblNumber />
        </Grid>
      </Grid>
    </>
  );
}
