import { Box, Grid, Typography } from '@material-ui/core';
import { RouteComponentProps } from '@reach/router';
import { useContainerQuery } from 'api/GQL_Types';
import { HotContainerSwitch } from 'app/components/HotContainerSwitch';
import Loading from 'app/Loading';
import { HotState } from 'components/HotToggleSwitch';
import React from 'react';
import { useRecoilState } from 'recoil';
import { AtomGroupCleaner } from 'recoil-utils/utils';
import EquipmentDetailsPanel from './DetailsPanel';
import EquipmentLineItems from './LineItemsPanel';
import { EquipmentPageStates, ContainerPageData } from './states';
import TransitDetailsPanel from './TransitDetailsPanel';

interface EquipmentDetailProps extends RouteComponentProps {
  equipmentId?: string;
}
export default function EquipmentDetails(props: EquipmentDetailProps) {
  const [container, setContainer] = useRecoilState(EquipmentPageStates.container);

  const { data: containerData, loading } = useContainerQuery({
    variables: { containerId: props.equipmentId || '' },
    fetchPolicy: 'network-only',
  });

  React.useEffect(() => {
    if (containerData) {
      const container = containerData.container;
      if (container) {
        const hotContainer: HotState = {
          isHot: container.isHot,
          hotMarkedBy: container.hotMarkedBy,
          hotMarkedTimestamp: container.hotMarkedTimestamp
        };
        const containerObj = {...containerData.container, hot: hotContainer};
        setContainer(containerObj as ContainerPageData);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [containerData]);

  if (loading) {
    return <Loading />;
  }

  return (
    <Box>
      <AtomGroupCleaner atomGroup={EquipmentPageStates} />
      <Box marginBottom={3} marginLeft={1} marginTop={1} display="flex" alignItems="center">
        <Typography variant="h2" color="primary">
          Equipment: {container?.containerNumber}
        </Typography>
        {container ?
          <HotContainerSwitch containerId={container.id} initialState={container.hot} showDetailsOnRight={true} />
          : null
        }
      </Box>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6}>
          <EquipmentDetailsPanel />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TransitDetailsPanel />
        </Grid>
      </Grid>
      <Box marginTop={2}>
        <EquipmentLineItems />
      </Box>
    </Box>
  );
}
