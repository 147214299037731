import { Button, Typography } from '@material-ui/core';
import {
  ContractType,
  GqlRelatedParty,
  MoveType,
  PartyType,
  useOpenOrdersRelatedPartiesLazyQuery,
} from 'api/GQL_Types';
import { userContextAtom } from 'app';
import React from 'react';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { NewBookingPageStates, PackLine } from '../states';

export const Step2Button: React.FC = () => {
  const userContext = useRecoilValue(userContextAtom);
  const partyName = userContext?.user?.firstName + ' ' + userContext?.user?.lastName;

  const selectedLines = useRecoilValue(NewBookingPageStates.step1.selectedLines);

  const setBookingParty = useSetRecoilState(NewBookingPageStates.step2.formBookingParty);
  const setConsignee = useSetRecoilState(NewBookingPageStates.step2.formConsignee);
  const setSupplier = useSetRecoilState(NewBookingPageStates.step2.formSupplier);
  const setManufacturer = useSetRecoilState(NewBookingPageStates.step2.formManufacturer);
  const setMoveType = useSetRecoilState(NewBookingPageStates.step2.formMoveType);
  const setContractType = useSetRecoilState(NewBookingPageStates.step2.formContractType);
  const setActiveStep = useSetRecoilState(NewBookingPageStates.activeStep);
  const setPackLines = useSetRecoilState(NewBookingPageStates.step2.packLines);

  const [getRelatedParties, { loading }] = useOpenOrdersRelatedPartiesLazyQuery({
    fetchPolicy: 'no-cache',
    onCompleted(data) {
      let consignee: GqlRelatedParty | null = null;
      let supplier: GqlRelatedParty | null = null;
      let manufacturer: GqlRelatedParty | null = null;
      const packLines: PackLine[] = [];
      for (const selectedLine of selectedLines) {
        const orderLine = data.openOrders.find((oo) => oo.orderLine.id === selectedLine.id);
        const relatedParties = (orderLine?.orderLine?.purchaseOrder?.relatedParties ??
          []) as GqlRelatedParty[];

        const packLine: PackLine = { ...selectedLine, relatedParties };
        packLines.push(packLine);

        consignee =
          consignee ??
          packLine.relatedParties.find((p) => p.partyType === PartyType.Consignee) ??
          null;
        supplier =
          supplier ??
          packLine.relatedParties.find((p) => p.partyType === PartyType.Supplier) ??
          null;
        manufacturer =
          manufacturer ??
          packLine.relatedParties.find((p) => p.partyType === PartyType.Manufacturer) ??
          null;
      }
      setPackLines(packLines);
      setBookingParty(partyName);
      setConsignee(consignee);
      setSupplier(supplier ?? manufacturer);
      setManufacturer(manufacturer ?? supplier);
      setMoveType(MoveType.CyCy);
      setContractType(ContractType.Nvo);
      setActiveStep(1);
    },
  });

  return (
    <Button
      disabled={selectedLines.length === 0 || loading}
      variant="contained"
      color="primary"
      onClick={() => {
        const orderLineIds = selectedLines.map((l) => l.id);
        if (orderLineIds.length === 0) return;
        getRelatedParties({ variables: { orderLineIds } });
      }}
      style={{ padding: '8px 32px' }}
    >
      <Typography variant="h3" color="inherit" style={{ width: '100px' }}>
        To Step 2
      </Typography>
    </Button>
  );
};
