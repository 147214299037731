import { Box, Grid } from '@material-ui/core';
import { navigate, RouteComponentProps } from '@reach/router';
import {
  DashboardFeatureCode,
  GqlDashboardShipmentInTransit,
  useNotificationsQuery,
  useShipmentsInTransitQuery,
} from 'api/GQL_Types';
import { userContextAtom } from 'app';
import Loading from 'app/Loading';
import CompositePanel from 'components/CompositePanel';
import ErrorMessage from 'components/ErrorMessage';
import { useDivDimensions } from 'lib/useDivDimensions';
import React from 'react';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import {
  NewBookingNotificationDisplay,
  NewBookingNotificationDisplay_toRow,
} from './alerts/buttons/NewBookingsButton';
import {
  NewDocumentNotificationDisplay,
  NewDocumentNotificationDisplay_toRow,
} from './alerts/buttons/NewDocumentsButton';
import DashboardBookings from './dashboard-bookings';
import DashboardExceptions from './dashboard-exceptions';
import DashboardPOButtons from './dashboard-po-buttons.tsx';
import ShipmentsInTransit from './shipments-in-transit';
import { DashboardPageStates } from './states';

interface Props extends RouteComponentProps {}
export default function Dashboard(props: Props) {
  const userContext = useRecoilValue(userContextAtom);
  const setInTransit = useSetRecoilState(DashboardPageStates.inTransit);
  const setNewDocuments = useSetRecoilState(DashboardPageStates.notifications.newDocuments);
  const setNewBookings = useSetRecoilState(DashboardPageStates.notifications.newBookings);

  const { divEl, width, height } = useDivDimensions();

  const { data: inTransitData, loading: inTransitLoading } = useShipmentsInTransitQuery({
    fetchPolicy: 'cache-and-network',
  });

  React.useEffect(() => {
    if (inTransitData) {
      setInTransit(inTransitData.shipmentsInTransit as GqlDashboardShipmentInTransit[]);
    }
  }, [inTransitData]);

  const { error: notificationsError } = useNotificationsQuery({
    fetchPolicy: 'no-cache',
    onCompleted(data) {
      const newBookings: NewBookingNotificationDisplay[] = [];
      const newDocs: NewDocumentNotificationDisplay[] = [];

      for (const notification of data.notifications) {
        const newBooking = NewBookingNotificationDisplay_toRow(notification);
        if (newBooking) {
          newBookings.push(newBooking);
        }

        const newDoc = NewDocumentNotificationDisplay_toRow(notification);
        if (newDoc) {
          newDocs.push(newDoc);
        }
      }
      setNewBookings(newBookings);
      setNewDocuments(newDocs);
    },
  });

  React.useEffect(() => {
    if (userContext?.activeContact?.role?.name === 'Factory') {
      navigate('/bookings/new');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const componentHeight = height / 2;
  const componentWidth = width;

  const showDashboardBookings =
    userContext?.dashboardFeatureCodes.has(DashboardFeatureCode.ChartBookingsByWeek) ||
    userContext?.dashboardFeatureCodes.has(
      DashboardFeatureCode.ChartBookingsByWeekDetailsContainers
    ) ||
    userContext?.dashboardFeatureCodes.has(DashboardFeatureCode.ChartBookingsByWeekDetailsSku) ||
    userContext?.dashboardFeatureCodes.has(
      DashboardFeatureCode.ChartBookingsByWeekDetailsBookings
    ) ||
    userContext?.dashboardFeatureCodes.has(
      DashboardFeatureCode.ChartBookingsByWeekDetailsCommercialValue
    );

  const showPOButtons =
    userContext?.dashboardFeatureCodes.has(DashboardFeatureCode.PoStatusOpen) ||
    userContext?.dashboardFeatureCodes.has(DashboardFeatureCode.PoStatusCritical) ||
    userContext?.dashboardFeatureCodes.has(DashboardFeatureCode.PoStatusExpired);

  return (
    <Grid
      container
      ref={divEl}
      spacing={2}
      style={{
        height: '100%',
        width: '100%',
      }}
    >
      {notificationsError && (
        <Grid item xs={12}>
          <ErrorMessage error={notificationsError + ''} />
        </Grid>
      )}
      {showDashboardBookings && (
        <Grid item xs={12} style={{ minHeight: '400px' }}>
          <Box width="100%" height="100%">
            <DashboardBookings containerHeight={componentHeight} containerWidth={componentWidth} />
          </Box>
        </Grid>
      )}
      <Grid item sm={12} lg={7}>
        <Box width="100%" height="100%">
          {inTransitLoading ? (
            <CompositePanel>
              <Loading />
            </CompositePanel>
          ) : (
            <ShipmentsInTransit containerHeight={componentHeight} />
          )}
        </Box>
      </Grid>
      <Grid item sm={12} lg={5}>
        <Box display="flex" flexDirection="column" height={1}>
          {showPOButtons && (
            <Box width={1} marginBottom={2}>
              <DashboardPOButtons />
            </Box>
          )}
          <Box width={1} flexGrow={1}>
            <DashboardExceptions />
          </Box>
        </Box>
      </Grid>
    </Grid>
  );
}
