import React from 'react';
import { formatNumberDecimalPlaces, isNumber, roundToDecimalPlaces } from 'utils/Numbers';
import { FormInputText } from './FormInputText';

export interface Props {
  value: number | null;
  onValue(value: number | null): void;

  label?: string;
  disabled?: boolean;
  required?: boolean;
  placeholder?: string;
  error?: boolean; // When true, indicate error status i.e. red border
  helperText?: string | null;
  minimumFractionDigits?: number;
  maximumFractionDigits?: number;

  // Adornments at the start or end of the input
  startAdornment?: string | React.ReactNode;
  endAdornment?: string | React.ReactNode;

  className?: string;

  size?: 'xs';
  selectAllOnFocus?: boolean;

  // When they use the up/down keys, how much should it increase/decrease the number
  step?: number;

  onFocusChange?: (isFocused: boolean) => void;
}

export const FormInputNumber: React.FC<Props> = (props) => {
  const [text, setText] = React.useState<string>('');
  const [isFocused, setIsFocused] = React.useState<boolean>(false);

  const minimumFractionDigits = isNumber(props.minimumFractionDigits)
    ? props.minimumFractionDigits
    : 0;
  const maximumFractionDigits = isNumber(props.maximumFractionDigits)
    ? props.maximumFractionDigits
    : 2;

  function formatText(n: number | null): string {
    if (n === null || n === undefined) {
      return '';
    }
    return formatNumberDecimalPlaces(n, minimumFractionDigits, maximumFractionDigits);
  }

  React.useEffect(() => {
    if (isFocused) {
      // don't mess with the input while they are typing
    } else {
      setText(formatText(props.value));
    }
    if (props.onFocusChange) {
      props.onFocusChange(isFocused);
    }
  }, [props.value, isFocused]);

  const step = props.step || 1;

  return (
    <FormInputText
      value={text}
      onValue={(value) => {
        setText(value);
        let num = parseFloat(value.replace(/[^0-9.-]+/g, ''));
        if (isNumber(num)) {
          num = roundToDecimalPlaces(num, maximumFractionDigits);
          props.onValue(num);
        } else {
          props.onValue(null);
        }
      }}
      onFocus={(e) => {
        setIsFocused(true);
        if (props.selectAllOnFocus) {
          e.currentTarget.select();
        }
      }}
      onBlur={(e) => {
        setIsFocused(false);
      }}
      onKeyDown={(e) => {
        switch (e.code) {
          case 'ArrowUp': {
            e.preventDefault();
            const num = (props.value || 0) + step;
            props.onValue(num);
            setText(formatText(num));
            break;
          }
          case 'ArrowDown': {
            e.preventDefault();
            const num = (props.value || 0) - step;
            props.onValue(num);
            setText(formatText(num));
            break;
          }
        }
      }}
      className={props.className}
      label={props.label}
      disabled={props.disabled}
      required={props.required}
      placeholder={props.placeholder}
      error={props.error}
      helperText={props.helperText}
      startAdornment={props.startAdornment}
      endAdornment={props.endAdornment}
      size={props.size}
    />
  );
};
