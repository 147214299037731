import { SystemPermissionCode } from 'api/GQL_Types';
import { newAtom } from 'recoil-utils/utils';

export interface ManageUserPageData {
  id: string;
  fullName?: string | null | undefined;
  firstName?: string | null | undefined;
  lastName?: string | null | undefined;
  title?: string | null | undefined;
  email?: string | null | undefined;
  phoneMain?: string | null | undefined;
  extension?: string | null | undefined;
  phoneCell?: string | null | undefined;
  phoneAlternative?: string | null | undefined;
  isActive: boolean;
  systemPermissionCodes: SystemPermissionCode[];
  defaultContact: {
    id: string;
    firstName?: string | null | undefined;
    lastName?: string | null | undefined;
    title?: string | null | undefined;
    email?: string | null | undefined;
    phoneMain?: string | null | undefined;
    phoneAlternative?: string | null | undefined;
    extension?: string | null | undefined;
    phoneCell?: string | null | undefined;
    isMainContact: boolean;
    isUserEnabled: boolean;
    profile: {
      id: string;
      name: string;
      network: {
        id: string;
        name: string;
      };
    };
    location?:
      | {
          id: string;
          name: string;
        }
      | null
      | undefined;
  };
  profileContacts: UserProfileContact[];
}

export interface UserProfileContact {
  id: string;
  firstName?: string | null | undefined;
  lastName?: string | null | undefined;
  title?: string | null | undefined;
  email?: string | null | undefined;
  phoneMain?: string | null | undefined;
  extension?: string | null | undefined;
  phoneCell?: string | null | undefined;
  phoneAlternative?: string | null | undefined;
  isMainContact: boolean;
  isUserEnabled: boolean;
  profile: {
    id: string;
    name: string;
    roles: {
      id: string;
      name: string;
    }[];
    network: {
      id: string;
      name: string;
      connectedProfiles: {
        id: string;
        name: string;
        profileCode: string;
      }[];
    };
  };
  location?:
    | {
        id: string;
        name: string;
      }
    | null
    | undefined;
  role?:
    | {
        id: string;
        name: string;
      }
    | null
    | undefined;
}

export const ManageUserPageStates = {
  user: newAtom<ManageUserPageData | null>(null),
};
