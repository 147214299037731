import {
  NotificationCode,
  DashboardFeatureCode,
  GqlProfileTypeQuery,
  PermissionCode,
} from 'api/GQL_Types';
import { useSetRecoilState } from 'recoil';
import { newAtom } from 'recoil-utils/utils';

interface ProfileTypePageData {
  id: string;
  name: string;
  description: string;
  isTemplate: boolean;
  permissionCodes: PermissionCode[];
  notificationCodes: NotificationCode[];
  dashboardFeatureCodes: DashboardFeatureCode[];

  defaultRoles: {
    id: string;
    name: string;
    description: string;
    permissionCodes: PermissionCode[];
    notificationCodes: NotificationCode[];
    dashboardFeatureCodes: DashboardFeatureCode[];
    documentTypesCanView: string[];
  }[];
}

export const ProfileTypePageStates = {
  profileType: newAtom<ProfileTypePageData | null>(null),
  formPermissionCodes: newAtom<PermissionCode[]>([]),
  formNotificationCodes: newAtom<NotificationCode[]>([]),
  formDashboardFeatures: newAtom<DashboardFeatureCode[]>([]),
};

export function useSetProfileTypePageState() {
  const setProfileType = useSetRecoilState(ProfileTypePageStates.profileType);
  const setFormPermissionCodes = useSetRecoilState(ProfileTypePageStates.formPermissionCodes);
  const setFormNotificationCodes = useSetRecoilState(ProfileTypePageStates.formNotificationCodes);
  const setFormDashboardFeatures = useSetRecoilState(ProfileTypePageStates.formDashboardFeatures);

  return (data: GqlProfileTypeQuery) => {
    if (data.profileType) {
      setProfileType({
        id: data.profileType.id,
        name: data.profileType.name,
        description: data.profileType.description || '',
        isTemplate: data.profileType.isTemplate,
        permissionCodes: data.profileType.permissionCodes,
        notificationCodes: data.profileType.notificationCodes,
        dashboardFeatureCodes: data.profileType.dashboardFeatureCodes,

        defaultRoles: data.profileType.defaultRoles.map((role) => {
          return {
            id: role.id,
            name: role.name,
            description: role.description || '',
            permissionCodes: role.permissionCodes,
            notificationCodes: role.notificationCodes,
            dashboardFeatureCodes: role.dashboardFeatureCodes,
            documentTypesCanView: role.documentTypesCanView,
          };
        }),
      });
      setFormPermissionCodes(data.profileType.permissionCodes);
      setFormNotificationCodes(data.profileType.notificationCodes);
      setFormDashboardFeatures(data.profileType.dashboardFeatureCodes);
    } else {
      setProfileType(null);
      setFormPermissionCodes([]);
      setFormNotificationCodes([]);
      setFormDashboardFeatures([]);
    }
  };
}
