import { Box } from '@material-ui/core';
import { GqlContainer } from 'api/GQL_Types';
import UniversalPopover from 'components/UniversalPopover';
import React from 'react';
import {
  XYPlot,
  HorizontalGridLines,
  VerticalGridLines,
  XAxis,
  LineSeries,
  CustomSVGSeries,
  LabelSeries,
  MarkSeries,
} from 'react-vis';
import ContainersPopoverContent from '../dashboard-bookings-popovers/ContainersPopoverContent';

interface GraphPointData {
  x: string;
  y: number;
}

export interface ContainerGraphDataPoint extends GraphPointData {
  containers?: GqlContainer[];
  popoverLabel?: string;
}

interface Props {
  height: number;
  width: number;
  containerData: any[];
  currentWeek: string;
  tickValues: number[];
  yData: ContainerGraphDataPoint[];
  labelSeriesY: number;
}

export const GraphPlot: React.FC<Props> = (props: Props) => {
  const [selectedContainerData, setSelectedContainerData] =
    React.useState<ContainerGraphDataPoint>();
  const [anchorEl, setAnchorEl] = React.useState<EventTarget | null>(null);
  const [openPopover, setOpenPopover] = React.useState(false);

  const segment = props.width / 9;

  return (
    <Box width={props.width} style={{ msOverflowX: 'auto', overflowX: 'auto' }} marginLeft={1}>
      <XYPlot
        width={props.containerData.length < 10 ? props.width : segment * props.containerData.length}
        height={props.height < 0 ? 0 : props.height - 8}
        xType="ordinal"
        margin={{ left: 0, right: 0, top: 30, bottom: 80 }}
      >
        <HorizontalGridLines
          tickValues={props.tickValues}
          style={{ stroke: '#3c3c3f', strokeDasharray: '1px' }}
        />
        {props.currentWeek !== '' && (
          <VerticalGridLines
            tickValues={[props.currentWeek]}
            style={{ stroke: '#FFA333', strokeWidth: '2px' }}
          />
        )}
        <XAxis
          tickPadding={22}
          style={{ fontWeight: 800, fontSize: 14 }}
          tickFormat={(d) => {
            return d.split('_')[0];
          }}
        />
        <XAxis
          tickPadding={37}
          style={{ fontWeight: 600, fontSize: 14 }}
          tickFormat={(d) => {
            return d.split('_')[1];
          }}
        />
        <XAxis
          tickPadding={52}
          style={{ fontWeight: 600, fontSize: 14 }}
          tickFormat={(d) => {
            return d.split('_')[2];
          }}
        />
        <LineSeries
          style={{
            strokeWidth: '3px',
            stroke: '#028ADE',
          }}
          data={props.containerData}
        />
        <CustomSVGSeries
          className="custom-marking"
          customComponent={(row) => {
            return (
              <g
                cursor="pointer"
                onClick={(e) => {
                  setOpenPopover(true);
                  setAnchorEl(e.currentTarget);
                  setSelectedContainerData(row);
                }}
              >
                <circle x={0} y={0} r={20} fill="#026bad" stroke="#fff" strokeWidth="4" />

                <text x={0} y={7} fill="#fff" fontWeight={900} fontSize={20} textAnchor="middle">
                  {row.y}
                </text>
              </g>
            );
          }}
          data={props.containerData}
        />
        {props.currentWeek !== '' && (
          <LabelSeries
            xType="ordinal"
            data={
              [
                {
                  x: props.currentWeek,
                  y: props.labelSeriesY,
                  label: 'Today',
                  rotation: -90,
                  style: {
                    fill: '#FFA333',
                    textAnchor: 'middle',
                    fontSize: 20,
                    fontWeight: 700,
                  },
                },
              ] as any[]
            }
          />
        )}
        <MarkSeries style={{ display: 'none', stroke: '#027DCA' }} data={props.yData} />
      </XYPlot>
      <UniversalPopover
        popoverId="booking-graph-popover"
        open={openPopover}
        anchorEl={anchorEl}
        onClose={() => {
          setAnchorEl(null);
          setOpenPopover(false);
        }}
        anchorOriginVertical={20}
        anchorOriginHorizontal={0}
      >
        <ContainersPopoverContent
          popoverLabel={selectedContainerData?.popoverLabel ?? ''}
          data={selectedContainerData?.containers ?? []}
        />
      </UniversalPopover>
    </Box>
  );
};
