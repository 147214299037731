import { FormInputAutocomplete } from 'components/form/FormInputAutocomplete';
import { FormItem } from 'components/form/FormItem';
import { HighlightMatches } from 'components/HighlightMatches';
import React from 'react';
import { RecoilState, useRecoilState } from 'recoil';

interface Props<T> {
  // State that holds the selected value
  state: RecoilState<T | null>;
  // Label for input
  label: string;
  // List of options for autocomplete
  optionsList: T[];
  // Resolver of object to a string. The string is rendered on option list and input field
  displayResolver: (value: T) => string;
  // How to render an option
  renderOption?: (value: T, query: string) => any;
  // To disable input field
  disabled?: boolean;
  required?: boolean;

  // When set, offer them the option to add a new item when nothing matches. This is called when the "add" button is clicked.
  onAddNew?: (
    query: string,
    event: React.KeyboardEvent<HTMLDivElement> | React.MouseEvent<HTMLButtonElement>
  ) => void;

  inputRef?: React.RefObject<HTMLInputElement>;
}

export default function AtomicAutocompleteV2<T>(props: Props<T>) {
  const [state, setState] = useRecoilState(props.state);

  return (
    <FormItem>
      <FormInputAutocomplete
        value={state}
        onValue={setState}
        label={props.label}
        options={props.optionsList}
        displayResolver={props.displayResolver}
        disabled={props.disabled}
        required={props.required}
        onAddNew={props.onAddNew}
        inputRef={props.inputRef}
        localFilter={(query, value) => {
          return props
            .displayResolver(value)
            .trim()
            .toLowerCase()
            .includes(query.trim().toLowerCase());
        }}
        renderOption={
          props.renderOption ??
          ((value, query) => {
            const str = props.displayResolver(value);
            if (str.toLowerCase().trim() === query) {
              return str;
            }
            return <HighlightMatches str={str} searchStr={query} />;
          })
        }
      />
    </FormItem>
  );
}
