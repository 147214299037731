import { gql } from '@apollo/client/core';
import { PortFragment } from './portQueries';

export const BookingListQuery = gql`
  query BookingList {
    shipments(
      shipment_filter: {
        type: BOOKING
        statuses: [BOOKED, PENDING, CONFIRMED, CONSOLIDATED, ASSIGNED]
      }
    ) {
      id
      referenceNumber
      status
      createDate
      ... on Booking {
        cargoReadyDate
        revisedCargoReadyDate
        isHot
        hotMarkedBy
        hotMarkedTimestamp
      }
      logistics {
        id
        confirmationNumber
        confirmationDate
        moveType
        transportationMode
        carrier {
          id
          name
        }
        carrierConfirmationNumber
        pickupLocation {
          id
          name
        }
        deliveryLocation {
          id
          name
        }
        finalDestination {
          id
          name
        }
        cyCutoffDate
        pod {
          ...Port
        }
        pol {
          ...Port
        }
        polEtd
        polAtd
      }
      containers {
        id
        sequential
        containerNumber
        containerType
        volume
        volumeUnit
        weight
        weightUnit
        isHot
      }
      relatedParties {
        id
        partyType
        party {
          id
          name
          locations {
            id
            name
          }
          relatedProfiles {
            id
            partyType
            profile {
              id
            }
          }
          network {
            id
            controllingClient {
              id
              name
            }
          }
        }
      }
    }
  }
  ${PortFragment}
`;

export const ShippedListQuery = gql`
  query ShippedList {
    shipments(shipment_filter: { type: BOOKING, statuses: [SHIPPED] }) {
      id
      referenceNumber
      status
      createDate
      ... on Booking {
        cargoReadyDate
        revisedCargoReadyDate
        isHot
        hotMarkedBy
        hotMarkedTimestamp
      }
      logistics {
        id
        confirmationNumber
        confirmationDate
        moveType
        transportationMode
        carrier {
          id
          name
        }
        carrierConfirmationNumber
        pickupLocation {
          id
          name
        }
        deliveryLocation {
          id
          name
        }
        cyCutoffDate
        pod {
          ...Port
        }
        pol {
          ...Port
        }
        polEtd
        polAtd
        podEta
        podAta
        deliveryEta
        deliveryAta
      }
      containers {
        id
        sequential
        containerNumber
        containerType
        volume
        volumeUnit
        weight
        weightUnit
        isHot
      }
      relatedParties {
        partyType
        party {
          name
        }
      }
    }
  }
  ${PortFragment}
`;

export const BookingQuery = gql`
  query Booking($bookingId: ID!) {
    booking(bookingId: $bookingId) {
      id
      referenceNumber
      status
      createDate
      bookingParty {
        id
        firstName
        lastName
      }
      incoTerm
      cargoReadyDate
      revisedCargoReadyDate
      mbl {
        id
        referenceNumber
        releaseType
        paymentType
        remarks {
          id
          text
          createDate
          createdBy {
            id
            firstName
            lastName
            profile {
              name
            }
          }
        }
      }
      hbl {
        id
        referenceNumber
        releaseType
        paymentType
        remarks {
          id
          text
          createDate
          createdBy {
            id
            firstName
            lastName
            profile {
              name
            }
          }
        }
        documents {
          id
          documentType
          importFileName
          fileName
          fileType
          fileSize
          uploadDate
          description
        }
      }
      packLines {
        id
        requestedDeliveryDate
        shippedQty
        shippedCartons
        volume
        weight
        orderLine {
          id
          lineNumber
          unitPrice
          itemNumber
          itemDescription
          purchaseOrder {
            id
            poNumber
            htsCode
          }
          indcDate
          lastShipDate
          shipToLocation {
            id
            name
          }
        }
      }
      remarks {
        id
        text
        createDate
        createdBy {
          id
          firstName
          lastName
          profile {
            name
          }
        }
      }
      relatedParties {
        id
        partyType
        party {
          id
          name
          locations {
            id
            name
          }
        }
      }
      logistics {
        id
        confirmationNumber
        confirmationDate
        carrierConfirmationNumber
        transportationMode
        moveType
        pickupLocation {
          name
        }
        deliveryLocation {
          name
        }
        carrier {
          id
          name
        }
        contractType
        contractNumber
        cyCutoffDate
        cfsCutoffDate
        cfsReceivedDate
        vgmCutoffDate
        siCutoffDate
        motherVessel {
          id
          name
          voyages {
            id
            name
          }
        }
        motherVoyage {
          id
          name
        }
        feederVessel {
          id
          name
          voyages {
            id
            name
          }
        }
        feederVoyage {
          id
          name
        }
        transitPort {
          ...Port
        }
        transitPortEta
        transitPortAta
        transitPortEtd
        transitPortAtd
        pol {
          ...Port
        }
        polEtd
        polAtd
        pod {
          ...Port
        }
        podEta
        ramp {
          ...Port
        }
        rampEta
        finalDestination {
          id
          name
        }
        deliveryEta
      }
      containers {
        id
        containerNumber
        containerType
        sealNumber
        volume
        weight
        shipFromFactoryDate
        terminalReceivedDate
        vanPositions {
          id
          packLine {
            id
            orderLine {
              lineNumber
              itemNumber
              itemDescription
              purchaseOrder {
                poNumber
              }
            }
          }
          volume
          weight
          piecesQty
          cartonsQty
          vanCode
        }
        isHot
      }
      documents {
        id
        documentType
        importFileName
        fileName
        fileType
        fileSize
        uploadDate
        description
      }
      isHot
      hotMarkedBy
      hotMarkedTimestamp
    }
  }
  ${PortFragment}
`;

export const NewBookingContainersMutation = gql`
  mutation NewBookingContainers($input: NewBookingContainersInput!) {
    newBookingContainers(input: $input) {
      success
    }
  }
`;

export const AssignBookingContainerMutation = gql`
  mutation AssignBookingContainer($input: AssignBookingContainerInput!) {
    assignBookingContainer(input: $input) {
      success
    }
  }
`;

export const DeleteContainerMutation = gql`
  mutation DeleteContainer($input: DeleteContainerInput!) {
    deleteContainer(input: $input) {
      success
    }
  }
`;

export const UpdateBookingMutation = gql`
  mutation UpdateBooking($input: UpdateBookingInput!) {
    updateBooking(input: $input) {
      success
    }
  }
`;

export const UpdateBookingLogisticsMutation = gql`
  mutation UpdateBookingLogistics($input: UpdateBookingLogisticsInput!) {
    updateBookingLogistics(input: $input) {
      success
    }
  }
`;

export const SaveBookingMutation = gql`
  mutation SaveBooking($input: NewBookingInput!) {
    newBooking(input: $input) {
      success
      message
      booking {
        id
        referenceNumber
      }
    }
  }
`;

export const AssignBookingRelatedPartyMutation = gql`
  mutation AssignBookingRelatedParty($input: AssignBookingRelatedPartyInput!) {
    assignBookingRelatedParty(input: $input) {
      success
      message
    }
  }
`;

export const RemoveBookingRelatedPartyMutation = gql`
  mutation RemoveBookingRelatedParty($input: RemoveBookingRelatedPartyInput!) {
    removeBookingRelatedParty(input: $input) {
      success
      message
    }
  }
`;

export const UpdateBookingRelatedPartyMutation = gql`
  mutation UpdateBookingRelatedParty($input: UpdateBookingRelatedPartyInput!) {
    updateBookingRelatedParty(input: $input) {
      success
      message
    }
  }
`;

export const NewBookingRemarkMutation = gql`
  mutation NewBookingRemark($input: NewBookingRemarkInput!) {
    newBookingRemark(input: $input) {
      success
      message
      remark {
        id
        text
        createDate
        createdBy {
          firstName
          lastName
          title
          profile {
            name
          }
        }
      }
    }
  }
`;

export const MarkBookingAsHotMutation = gql`
  mutation MarkBookingAsHot($input: MarkBookingAsHotInput!) {
    markBookingAsHot(input: $input) {
      success
      message
      booking {
        isHot
        hotMarkedBy
        hotMarkedTimestamp
      }
    }
  }
`;
