import { Box, Typography } from '@material-ui/core';
import { DashboardFeatureCode } from 'api/GQL_Types';
import { userContextAtom } from 'app';
import { DashboardPageStates } from 'app/dashboard/states';
import React from 'react';
import { useRecoilValue } from 'recoil';
import { theme } from 'styles';
import { formatTime } from 'utils/Dates';
import { formatNumberDecimalPlaces } from 'utils/Numbers';
import { addPackLineTotals } from '.';
import BookingsDetailsPopover from './BookingsDetailsPopover';

interface Props {
  rowHeight: number;
}

export default function BookingBarLabels(props: Props) {
  const userContext = useRecoilValue(userContextAtom);
  const weeks = useRecoilValue(DashboardPageStates.booking.bookingSummaries);

  return (
    <Box display="flex" style={{ overflowX: 'auto' }} height={props.rowHeight * 8}>
      {weeks &&
        weeks.map((week, index) => {
          const year1 = formatTime(week.start, 'yyyy');
          const year2 = formatTime(week.end, 'yyyy');
          const yearLabel: string = year1 === year2 ? year1 : year1 + ' - ' + year2;
          const weekLabel = 'WK' + week.weekNumber;
          const popoverLabel = `WEEK ${week.weekNumber}: (${
            formatTime(week.start, 'LLL dd') + ' - ' + formatTime(week.end, 'LLL dd')
          }) ${yearLabel}`;

          return (
            <Box display="flex" flexGrow={1} flexDirection="column" key={index}>
              {userContext?.dashboardFeatureCodes.has(DashboardFeatureCode.ChartBookingsByWeekDetailsContainers) && (
                <CenterLabelBox
                  rowHeight={props.rowHeight}
                  index={index}
                  color={theme.palette.secondary.main}
                  data={{ type: 'containers', data: week.containers, popoverLabel }}
                >
                  {week.containers.length}
                </CenterLabelBox>
              )}
              {userContext?.dashboardFeatureCodes.has(DashboardFeatureCode.ChartBookingsByWeekDetailsSku) && (
                <CenterLabelBox
                  rowHeight={props.rowHeight}
                  index={index}
                  color={theme.palette.secondary.main}
                  data={{ type: 'sku/lineItems', data: week.skuPackLines, popoverLabel }}
                >
                  {week.skuPackLines.length}
                </CenterLabelBox>
              )}
              {userContext?.dashboardFeatureCodes.has(DashboardFeatureCode.ChartBookingsByWeekDetailsBookings) && (
                <CenterLabelBox
                  rowHeight={props.rowHeight}
                  index={index}
                  color={theme.palette.secondary.main}
                  data={{ type: 'bookings', data: week.bookings, popoverLabel }}
                >
                  {week.bookings.length}
                </CenterLabelBox>
              )}
              {userContext?.dashboardFeatureCodes.has(DashboardFeatureCode.ChartBookingsByWeekDetailsCommercialValue) && (
                <CenterLabelBox
                  rowHeight={props.rowHeight}
                  index={index}
                  color={theme.palette.secondary.main}
                  data={{ type: 'invoice', data: week.invoicePackLines, popoverLabel }}
                >
                  ${' ' + formatNumberDecimalPlaces(addPackLineTotals(week.invoicePackLines), 2, 2)}
                </CenterLabelBox>
              )}
              <Box
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  minWidth: props.rowHeight * 5 + 'px',
                  height: props.rowHeight * 2 + 'px',
                }}
              >
                <Box display="flex" flexDirection="column" alignItems="center">
                  <span
                    style={{
                      color: '#63636d',
                      fontSize: 16,
                      fontWeight: 800,
                    }}
                  >
                    {weekLabel}
                  </span>
                  <span
                    style={{
                      color: theme.palette.text.primary,
                      fontSize: 16,
                      fontWeight: 400,
                    }}
                  >
                    {formatTime(week.start, 'LL/dd') + ' - ' + formatTime(week.end, 'LL/dd')}
                  </span>
                  <span
                    style={{
                      color: theme.palette.text.primary,
                      fontSize: 16,
                      fontWeight: 400,
                    }}
                  >
                    {yearLabel}
                  </span>
                </Box>
              </Box>
            </Box>
          );
        })}
    </Box>
  );
}

interface CenterLabelBoxProps {
  children?: any;
  color: string;
  data: {
    type: 'containers' | 'sku/lineItems' | 'bookings' | 'invoice';
    data: any[];
    popoverLabel: string;
  };
  index: number;
  rowHeight: number;
}

function CenterLabelBox(props: CenterLabelBoxProps) {
  const [anchorEl, setAnchorEl] = React.useState<(EventTarget & HTMLElement) | null>(null);
  const [openPopover, setOpenPopover] = React.useState(false);

  return (
    <>
      <Box
        onClick={(e: any) => {
          setOpenPopover(true);
          setAnchorEl(e.currentTarget);
          // setSelectedItemData(props.data);
        }}
        bgcolor={props.color}
        //borderRight="1px solid #FFF"
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          opacity: props.index % 2 === 0 ? 1 : 0.8,
          //backgroundColor: props.index % 2 === 0 ? props.color : props.color + '1F',
          height: props.rowHeight + 'px',
          minWidth: props.rowHeight * 3 + 'px',
          marginTop: props.rowHeight * 0.15 + 'px',
          marginBottom: props.rowHeight * 0.15 + 'px',
          cursor: 'pointer',
        }}
      >
        <Typography variant="h2" color="textSecondary">
          {props.children}
        </Typography>
      </Box>
      <BookingsDetailsPopover
        open={openPopover}
        anchorEl={anchorEl}
        onClose={() => {
          setAnchorEl(null);
          setOpenPopover(false);
        }}
        data={props.data}
      />
    </>
  );
}
