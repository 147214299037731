import { PaymentType, ReleaseType } from 'api/GQL_Types';
import { FormInputAutocomplete } from 'components/form/FormInputAutocomplete';
import { FormInputText } from 'components/form/FormInputText';
import { HighlightMatches } from 'components/HighlightMatches';
import { UWLTable } from 'components/UWLTable/UWLTable';
import React from 'react';
import { useRecoilState } from 'recoil';
import { UWLTableColumn } from 'types/UWLTable';
import { mapPaymentType, mapReleaseType } from 'utils/Enums';
import { BookingPerHbl, ConsolidationPageStates } from '../../states';

const columns: UWLTableColumn<BookingPerHbl>[] = [
  { id: 'bookingReferenceNumber', label: 'Booking', type: 'string' },
  { id: 'hblReferenceNumber', label: 'HBL', type: 'string' },
  { id: 'hblPaymentType', label: 'HBL Payment Type', type: 'string' },
  { id: 'hblReleaseType', label: 'HBL Release Type', type: 'string' },
];

const ALL_ReleaseTypes = Object.values(ReleaseType);
const ALL_PaymentTypes = Object.values(PaymentType);

export const HBLsInput: React.FC<{}> = () => {
  const [hblPerBooking, setHblPerBooking] = useRecoilState(
    ConsolidationPageStates.editLogistics.hblPerBooking
  );

  function updateItem(bookingId: string, update: (orig: BookingPerHbl) => BookingPerHbl) {
    setHblPerBooking(
      hblPerBooking.map((item) => {
        return bookingId === item.bookingId ? update(item) : item;
      })
    );
  }

  return (
    <UWLTable
      rowId="bookingId"
      columns={columns}
      rows={hblPerBooking}
      renderCell={{
        hblReferenceNumber(row) {
          return (
            <div style={{ marginTop: 1 }}>
              <FormInputText
                value={row.hblReferenceNumber}
                onValue={(hblReferenceNumber) => {
                  updateItem(row.bookingId, (item) => ({ ...item, hblReferenceNumber }));
                }}
                size="xs"
              />
            </div>
          );
        },
        hblPaymentType(row) {
          return (
            <div>
              <AutocompleteInputCell
                value={row.hblPaymentType}
                onValue={(hblPaymentType) => {
                  updateItem(row.bookingId, (item) => ({ ...item, hblPaymentType }));
                }}
                options={ALL_PaymentTypes}
                displayResolver={mapPaymentType}
                disabled={!Boolean(row.hblReferenceNumber)}
              />
            </div>
          );
        },
        hblReleaseType(row) {
          return (
            <div>
              <AutocompleteInputCell
                value={row.hblReleaseType}
                onValue={(hblReleaseType) => {
                  updateItem(row.bookingId, (item) => ({ ...item, hblReleaseType }));
                }}
                options={ALL_ReleaseTypes}
                displayResolver={mapReleaseType}
                disabled={!Boolean(row.hblReferenceNumber)}
              />
            </div>
          );
        },
      }}
    />
  );
};

function AutocompleteInputCell<T>({
  value,
  onValue,
  options,
  displayResolver,
  disabled,
}: {
  value: T | null;
  onValue(v: T | null): void;
  options: T[];
  displayResolver: (value: T) => string;
  disabled: boolean;
}) {
  return (
    <div>
      <FormInputAutocomplete<T>
        size="xs"
        value={value}
        onValue={onValue}
        options={options}
        displayResolver={displayResolver}
        disabled={disabled}
        localFilter={(query, value) => {
          return displayResolver(value).trim().toLowerCase().includes(query.trim().toLowerCase());
        }}
        renderOption={(value, query) => {
          const str = displayResolver(value);
          if (str.toLowerCase().trim() === query) {
            return str;
          }
          return <HighlightMatches str={str} searchStr={query} />;
        }}
      />
    </div>
  );
}
