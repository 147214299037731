import { Box, Button, Container } from '@material-ui/core';
import { PermissionCode, SystemPermissionCode, useProfileTypeUpdateMutation } from 'api/GQL_Types';
import { ProfileTypeQuery } from 'api/queries/profileTypeQueries';
import { auth } from 'app';
import AtomicTextFieldV2 from 'components/atomic/AtomicTextFieldV2';
import { DeleteButton } from 'components/DeleteButton';
import { Line, DialogForm } from 'components/StyledComponents';
import UniversalDialog from 'components/UniversalDialog';
import { useSnackbar } from 'notistack';
import React from 'react';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import { newAtom } from 'recoil-utils/utils';
import RemoveConfirmationDialog, {
  removeConfirmationDialog_Open,
} from './RemoveConfirmationDialog';

interface ProfileTypeDetails {
  id: string;
  name: string;
  description: string;
  isTemplate: boolean;
}

export const ProfileTypeDetailsDialog_Data = newAtom<ProfileTypeDetails | null>(null);

const profileTypeNameState = newAtom('');
const profileTypeDescriptionState = newAtom('');

export default function EditProfileTypeDetailsDialog() {
  const { userContext } = auth.useAuthState();
  const [profileType, setProfileType] = useRecoilState(ProfileTypeDetailsDialog_Data);
  const setProfileTypeName = useSetRecoilState(profileTypeNameState);
  const setProfileTypeDescription = useSetRecoilState(profileTypeDescriptionState);

  React.useEffect(() => {
    setProfileTypeName(profileType?.name || '');
    setProfileTypeDescription(profileType?.description || '');
  }, [profileType]);

  let canDelete = profileType?.isTemplate
    ? userContext?.systemPermissionCodes.has(SystemPermissionCode.ProfileTypeTemplatesDelete)
    : userContext?.permissionCodes.has(PermissionCode.ProfileTypeDelete) && false; // can't currently do this

  return (
    <UniversalDialog
      open={!!profileType}
      title="Edit Profile Type Template"
      setClose={() => {
        setProfileType(null);
      }}
    >
      <Line height={1} />
      <Container>
        <Box width={700} marginBottom={2}>
          <DialogForm>
            <AtomicTextFieldV2 state={profileTypeNameState} label="Name" />
          </DialogForm>
          <DialogForm>
            <AtomicTextFieldV2
              state={profileTypeDescriptionState}
              label="Description"
              multiline
              rows={5}
            />
          </DialogForm>
        </Box>
      </Container>
      <Line height={1} />
      <Box display="flex" justifyContent="space-between">
        <Box>{canDelete && <DeleteProfileTypeButton />}</Box>
        <Box>
          <CancelButton />
          <UpdateProfileTypeDetailsButton />
        </Box>
      </Box>
      <RemoveConfirmationDialog />
    </UniversalDialog>
  );
}

function UpdateProfileTypeDetailsButton() {
  const [profileType, setProfileType] = useRecoilState(ProfileTypeDetailsDialog_Data);
  const nameForm = useRecoilValue(profileTypeNameState);
  const descriptionForm = useRecoilValue(profileTypeDescriptionState);
  const { enqueueSnackbar } = useSnackbar();

  const [updateProfileType] = useProfileTypeUpdateMutation({
    refetchQueries: () => [
      {
        query: ProfileTypeQuery,
        variables: { profileTypeId: profileType?.id },
      },
    ],
    onCompleted(data) {
      if (data.updateProfileType.success) {
        setProfileType(null);
        enqueueSnackbar('Profile Type Template Updated.', {
          variant: 'success',
          anchorOrigin: {
            vertical: 'top',
            horizontal: 'right',
          },
        });
      }
    },
  });

  if (!profileType) {
    return null;
  }

  return (
    <Button
      variant="contained"
      color="primary"
      size="large"
      onClick={() => {
        updateProfileType({
          variables: {
            input: {
              profileType: {
                id: profileType.id,
                name: nameForm.trim(),
                description: descriptionForm.trim(),
              },
            },
          },
        });
        setProfileType(null);
      }}
      disabled={
        profileType.name.trim() === nameForm.trim() &&
        profileType.description.trim() === descriptionForm.trim()
      }
    >
      Update Details
    </Button>
  );
}

function CancelButton() {
  const setProfileType = useSetRecoilState(ProfileTypeDetailsDialog_Data);

  return (
    <Button
      variant="contained"
      color="default"
      size="large"
      style={{ marginRight: '12px' }}
      onClick={() => {
        setProfileType(null);
      }}
    >
      Cancel
    </Button>
  );
}

function DeleteProfileTypeButton() {
  const setOpenDeleteDialog = useSetRecoilState(removeConfirmationDialog_Open);

  return (
    <DeleteButton
      size="large"
      onClick={() => {
        setOpenDeleteDialog(true);
      }}
    >
      Delete Profile Type
    </DeleteButton>
  );
}
