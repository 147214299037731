import { Box } from '@material-ui/core';
import { RouteComponentProps } from '@reach/router';
import React from 'react';
import { useRecoilValue } from 'recoil';
import { useResetAtomGroupState } from 'recoil-utils/utils';
import NewBookingHeader from './header';
import { NewBookingPageStates } from './states';
import NewBookingStep1 from './step-1';
import NewBookingStep2 from './step-2';

interface NewBookingProps extends RouteComponentProps {}

export default function NewBooking(props: NewBookingProps) {
  const activeStep = useRecoilValue(NewBookingPageStates.activeStep);
  const resetState = useResetAtomGroupState(NewBookingPageStates);

  React.useEffect(() => {
    resetState();
  }, []);

  return (
    <Box height={1} display="flex" flexDirection="column">
      <NewBookingHeader />
      <Box flexGrow={1}>
        {activeStep === 0 && <NewBookingStep1 />}
        {activeStep === 1 && <NewBookingStep2 />}
      </Box>
    </Box>
  );
}
