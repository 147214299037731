import { PartyType } from 'api/GQL_Types';
import { DashboardPageStates, TableLink } from 'app/dashboard/states';
import { GTableColumn } from 'components/g-table/types';
import React from 'react';
import { useRecoilValue } from 'recoil';
import { formatNumber } from 'utils/Numbers';
import { AlertButton } from '../../components/AlertButton';
import AlertsPopover from '../AlertsPopover';
import { NotificationResult, BaseAlert } from '../states';

export interface NewBookingNotificationDisplay extends BaseAlert {
  id: string;
  unread: boolean;
  bookingNumber: TableLink;
  bookingDate: Date | null | undefined;
  status: string;
  cargoReadyDate: Date | null | undefined;
  revisedCargoReadyDate: Date | null | undefined;
  expectedCargoReadyDate: Date | null | undefined;
  pos: TableLink[];
  items: string;
  equipment: string;
  shipperName: string;
  origin: string;
  destination: string;
  confirmationDate: Date | null | undefined;
}

const columns: GTableColumn<NewBookingNotificationDisplay>[] = [
  { id: 'bookingNumber', label: 'Booking #', type: 'link', weight: 1 },
  { id: 'bookingDate', label: 'Booking Date', type: 'date', weight: 1 },
  { id: 'status', label: 'Status', type: 'string', weight: 1 },
  { id: 'cargoReadyDate', label: 'CGO RDY Date', type: 'date', weight: 1 },
  { id: 'revisedCargoReadyDate', label: 'REV CGO RDY Date', type: 'date', weight: 1 },
  { id: 'expectedCargoReadyDate', label: 'EXP CGO RDY Date', type: 'date', weight: 1 },
  { id: 'pos', label: 'PO #', type: 'link', weight: 1 },
  { id: 'items', label: 'Items', type: 'string', weight: 1 },
  { id: 'equipment', label: 'Equipment', type: 'link', weight: 1 },
  { id: 'shipperName', label: 'Shipper', type: 'string', weight: 1 },
  { id: 'origin', label: 'Origin', type: 'string', weight: 1 },
  { id: 'destination', label: 'Destination', type: 'string', weight: 1 },
  { id: 'confirmationDate', label: 'Confirmation Date', type: 'date', weight: 1 },
];

export default function NewBookingsButton() {
  const newBookingAlerts = useRecoilValue(DashboardPageStates.notifications.newBookings);
  const [anchorEl, setAnchorEl] = React.useState<(EventTarget & HTMLElement) | null>(null);
  const [openPopover, setOpenPopover] = React.useState(false);

  return (
    <>
      <AlertButton
        label="New Bookings"
        quantity={newBookingAlerts.length}
        onClick={(e) => {
          setAnchorEl(e.currentTarget);
          setOpenPopover(true);
        }}
      />
      <AlertsPopover
        id="new-bookings-popover"
        emptyMessage="No new bookings"
        headingLabel="New Bookings"
        exportLabel="NewBookings"
        totalLabel="Total New Bookings"
        anchorEl={anchorEl}
        openPopover={openPopover}
        onClose={() => {
          setAnchorEl(null);
          setOpenPopover(false);
        }}
        items={newBookingAlerts}
        columns={columns}
      />
    </>
  );
}

export function NewBookingNotificationDisplay_toRow(
  alert: NotificationResult
): NewBookingNotificationDisplay | null {
  if (alert.__typename !== 'NewBookingNotification') {
    return null;
  }

  let containers = '';
  if (alert.booking.containers) {
    const nContainersByType = new Map<string, number>();
    alert.booking.containers.forEach((container) => {
      const n = nContainersByType.get(container.containerType) || 0;
      nContainersByType.set(container.containerType, n + 1);
    });
    const lines: string[] = [];
    nContainersByType.forEach((nContainers, type) => {
      lines.push(`${type} (QTY ${formatNumber(nContainers)})`);
    });
    containers = lines.join(',\n');
  }

  let shipperName = '';
  alert.booking.relatedParties.forEach((rp) => {
    if (rp.partyType === PartyType.Shipper) {
      shipperName = rp.party.name;
    }
  });

  let expectedCargoReadyDate: Date | null | undefined;
  for (const pl of alert.booking.packLines) {
    const ecrd = pl.orderLine.expectedCargoReadyDate;
    if (ecrd) {
      if (!expectedCargoReadyDate || ecrd < expectedCargoReadyDate) {
        expectedCargoReadyDate = ecrd;
      }
    }
  }

  return {
    id: alert.id,
    unread: true,
    bookingNumber: {
      to: '/bookings/' + alert.id,
      value: alert.booking.referenceNumber ?? '',
    },
    bookingDate: alert.booking.createDate,
    status: alert.booking.status,
    cargoReadyDate: alert.booking.cargoReadyDate,
    revisedCargoReadyDate: alert.booking.revisedCargoReadyDate,
    expectedCargoReadyDate,
    pos: alert.booking.packLines.map((pl) => {
      return {
        to: '/purchase-orders/' + pl.orderLine.purchaseOrder.id,
        value: pl.orderLine.purchaseOrder.poNumber,
      };
    }),
    items: alert.booking.packLines.map((pl) => pl.orderLine.itemNumber).join(''),
    equipment: containers,
    shipperName,
    origin: alert.booking.logistics.pol?.name ?? '',
    destination: alert.booking.logistics.pod?.name ?? '',
    confirmationDate: alert.booking.logistics.confirmationDate,
  };
}
