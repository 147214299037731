import { Box, InputAdornment, TextField, Typography } from '@material-ui/core';
import { Link } from '@reach/router';
import { GTableColumn } from 'components/g-table/types';
import UWLTableV2 from 'components/g-table/UWLTableV2';
import Panel from 'components/Panel';
import React from 'react';
import { useRecoilValue } from 'recoil';
import { roundNumberDecimalPlacesWithCommas } from 'utils/Numbers';
import { BookingPageStates, LineItems } from './states';

const columns: GTableColumn<LineItems>[] = [
  { id: 'poNumber', label: 'PO', type: 'string', weight: 1 },
  { id: 'itemNumber', label: 'Item / Sku', type: 'string', weight: 1 },
  { id: 'description', label: 'Description', type: 'string', weight: 1 },
  { id: 'lastShipDate', label: 'Last Ship', type: 'date', weight: 1 },
  { id: 'requestedDeliveryDate', label: 'Req Del Date', type: 'date', weight: 1 },
  { id: 'shipToLocation', label: 'Ship To Loc', type: 'string', weight: 1 },
  { id: 'unitPrice', label: 'Unit Cost', type: 'string', weight: 1 },
  { id: 'htsCode', label: 'HTS Code', type: 'string', weight: 1 },
  { id: 'shippedQty', label: 'Shipped QTY', type: 'number', weight: 1 },
  { id: 'shippedCartons', label: 'Shipped CTNS', type: 'number', weight: 1 },
  { id: 'volume', label: 'Volume', type: 'number', weight: 1 },
  { id: 'weight', label: 'Weight', type: 'number', weight: 1 },
];

interface Props {
  bookingId: string;
}

export default function BookingLineItemsPanel(props: Props) {
  const [lineItems, setLineItems] = React.useState<LineItems[]>([]);
  const booking = useRecoilValue(BookingPageStates.booking);

  React.useEffect(() => {
    if (booking) {
      setLineItems(
        booking.packLines.map((line): LineItems => {
          return {
            id: line.id,
            poId: line.orderLine.purchaseOrder.id,
            poNumber: line.orderLine.purchaseOrder.poNumber,
            itemNumber: line.orderLine.itemNumber,
            description: line.orderLine.itemDescription,
            lastShipDate: line.orderLine.lastShipDate,
            requestedDeliveryDate: line.requestedDeliveryDate, // TODO FIXME HACK ask why the old logic used `line.orderLine.indcDate` was it indented to be a fallback?
            shipToLocation: line.orderLine?.shipToLocation?.name || '',
            unitPrice: line.orderLine.unitPrice || 0,
            htsCode: line.orderLine.itemHtsCode || '',
            shippedQty: line.shippedQty,
            shippedCartons: line.shippedCartons,
            volume: roundNumberDecimalPlacesWithCommas(line.volume, 2) || '',
            weight: roundNumberDecimalPlacesWithCommas(line.weight, 2) || '',
          };
        })
      );
    }
  }, [booking]);

  return (
    <Panel title="Line Items">
      <Box margin={2}>
        <UWLTableV2
          columns={columns}
          rows={lineItems}
          emptyMessage="No Items Available"
          maxHeight={300}
          renderCell={{
            poNumber(row) {
              return <Link to={'/purchase-orders/' + row.poId}>{row.poNumber}</Link>;
            },
            unitPrice(row) {
              return (
                <TextField
                  id={row.id + 'unitPrice'}
                  value={row.unitPrice.toFixed(2)}
                  disabled
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <Typography variant="caption">$</Typography>
                      </InputAdornment>
                    ),
                    endAdornment: (
                      <InputAdornment position="end">
                        <Typography variant="caption">USD</Typography>
                      </InputAdornment>
                    ),
                    disableUnderline: true,
                    style: {
                      color: '#333',
                      maxWidth: '100px',
                    },
                  }}
                  inputProps={{
                    style: {
                      textAlign: 'left',
                      marginRight: '-16px',
                    },
                  }}
                  type="number"
                />
              );
            },
            volume(row) {
              return (
                <>
                  {row.volume ? (
                    <Typography variant="body1">
                      {row.volume}
                      <Box
                        component="span"
                        fontWeight="caption.fontWeight"
                        fontStyle="italic"
                        marginLeft={1}
                      >
                        M3
                      </Box>
                    </Typography>
                  ) : (
                    <Box></Box>
                  )}
                </>
              );
            },
            weight(row) {
              return (
                <>
                  {row.weight ? (
                    <Typography variant="body1">
                      {row.weight}
                      <Box
                        component="span"
                        fontWeight="caption.fontWeight"
                        fontStyle="italic"
                        marginLeft={1}
                      >
                        KG
                      </Box>
                    </Typography>
                  ) : (
                    <Box></Box>
                  )}
                </>
              );
            },
          }}
        />
      </Box>
    </Panel>
  );
}
