import { Box, Grid, Typography } from '@material-ui/core';
import {
  ContractType,
  GqlCarrier,
  MoveType,
  PaymentType,
  ReleaseType,
  useCarriersQuery,
} from 'api/GQL_Types';
import AtomicAutocompleteV2 from 'components/atomic/AtomicAutocompleteV2';
import AtomicDatePickerV2 from 'components/atomic/AtomicDatePickerV2';
import AtomicRadioGroupV2 from 'components/atomic/AtomicRadioGroupV2';
import AtomicTextFieldV2 from 'components/atomic/AtomicTextFieldV2';
import SectionTitle from 'components/SectionTitle';
import React from 'react';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { mapContractType, mapMoveType, mapPaymentType, mapReleaseType } from 'utils/Enums';
import { ConsolidationPageStates } from '../../states';
import { HBLsInput } from './HBLsInput';

export default function CarrierInformation() {
  const consolidation = useRecoilValue(ConsolidationPageStates.consolidation);
  const mbl = useRecoilValue(ConsolidationPageStates.editLogistics.mbl);
  const { data: carriersData } = useCarriersQuery();
  const moveTypeOrigin = mapMoveType(consolidation?.logistics.moveType as MoveType).split(' ');

  const setMblPaymentType = useSetRecoilState(ConsolidationPageStates.editLogistics.mblPaymentType);
  const setMblReleaseType = useSetRecoilState(ConsolidationPageStates.editLogistics.mblReleaseType);

  React.useEffect(() => {
    setMblPaymentType(
      mbl && !consolidation?.mbl?.paymentType
        ? PaymentType.Prepaid
        : consolidation?.mbl?.paymentType || null
    );
    setMblReleaseType(
      mbl && !consolidation?.mbl?.releaseType
        ? ReleaseType.Tlx
        : consolidation?.mbl?.releaseType || null
    );
  }, [consolidation, mbl]);

  return (
    <>
      <SectionTitle title="Carrier Information" />
      <Grid container spacing={3} alignItems="flex-start">
        <Grid item xs={4}>
          <AtomicAutocompleteV2<GqlCarrier>
            state={ConsolidationPageStates.editLogistics.carrier}
            label={'Carrier'}
            optionsList={carriersData ? (carriersData.carriers as GqlCarrier[]) : []}
            displayResolver={(value) => value.name}
          />
        </Grid>
        <Grid item xs={4}>
          <AtomicTextFieldV2
            state={ConsolidationPageStates.editLogistics.carrierConfirmationNumber}
            label="Carrier Confirmation"
          />
        </Grid>
        <Grid item xs={4}>
          <AtomicTextFieldV2
            state={ConsolidationPageStates.editLogistics.contract}
            label="Contract No."
          />
        </Grid>
      </Grid>
      <Grid container spacing={3} alignItems="flex-start">
        <Grid item xs={4}>
          <AtomicRadioGroupV2<ContractType>
            state={ConsolidationPageStates.editLogistics.contractType}
            label="Contract Type"
            options={Object.values(ContractType)}
            displayResolver={(value) => mapContractType(value)}
          />
        </Grid>
        <Grid item xs={4}></Grid>
        <Grid item xs={4}>
          <AtomicTextFieldV2 state={ConsolidationPageStates.editLogistics.mbl} label="MBL" />
        </Grid>
      </Grid>
      <Grid container spacing={3} alignItems="flex-start">
        <Grid item xs={4}></Grid>
        <Grid item xs={4}></Grid>
        <Grid item xs={4}>
          <MBLPaymentType />
        </Grid>
      </Grid>
      <Grid container spacing={3} alignItems="flex-start">
        <Grid item xs={4}></Grid>
        <Grid item xs={4}></Grid>
        <Grid item xs={4}>
          <MBLReleaseType />
        </Grid>
      </Grid>
      <Grid container spacing={3} alignItems="flex-start">
        <Grid item xs={12}>
          <HBLsInput />
        </Grid>
      </Grid>
      <hr />

      <Box padding={1} color="#1897A0">
        <Typography variant="h4" color="inherit">
          Cutoff Dates
        </Typography>
      </Box>

      <Grid container spacing={3} alignItems="flex-start">
        <Grid item xs={4}>
          <AtomicDatePickerV2
            state={ConsolidationPageStates.editLogistics.cfsCutoff}
            label="CFS Cutoff"
            disabled={moveTypeOrigin[0] === 'CY'}
          />
        </Grid>

        <Grid item xs={4}>
          <AtomicDatePickerV2
            state={ConsolidationPageStates.editLogistics.cfsReceived}
            label="CFS Rec'd"
            disabled={moveTypeOrigin[0] === 'CY'}
          />
        </Grid>

        <Grid item xs={4}>
          <AtomicDatePickerV2
            state={ConsolidationPageStates.editLogistics.cyCutoff}
            label="CY Cutoff"
            disabled={moveTypeOrigin[0] === 'CFS'}
          />
        </Grid>
      </Grid>

      <Grid container spacing={3} alignItems="flex-start" direction="row">
        <Grid item xs={4}>
          <AtomicDatePickerV2
            state={ConsolidationPageStates.editLogistics.vgmCutoff}
            label="VGM Cutoff"
          />
        </Grid>

        <Grid item xs={4}>
          <AtomicDatePickerV2
            state={ConsolidationPageStates.editLogistics.siCutoff}
            label="SI Cufoff"
          />
        </Grid>
      </Grid>
    </>
  );
}

function MBLPaymentType() {
  const mbl = useRecoilValue(ConsolidationPageStates.editLogistics.mbl);

  return (
    <AtomicAutocompleteV2<PaymentType>
      state={ConsolidationPageStates.editLogistics.mblPaymentType}
      label={'MBL Payment Type'}
      disabled={!Boolean(mbl)}
      optionsList={Object.values(PaymentType)}
      displayResolver={mapPaymentType}
    />
  );
}

function MBLReleaseType() {
  const mbl = useRecoilValue(ConsolidationPageStates.editLogistics.mbl);

  return (
    <AtomicAutocompleteV2<ReleaseType>
      state={ConsolidationPageStates.editLogistics.mblReleaseType}
      label={'MBL Release Type'}
      disabled={!Boolean(mbl)}
      optionsList={Object.values(ReleaseType)}
      displayResolver={mapReleaseType}
    />
  );
}
