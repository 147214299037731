import {
  ContractType,
  GqlCarrier,
  GqlConsolidation,
  GqlContainer,
  GqlLocation,
  GqlPackLine,
  GqlPort,
  GqlRelatedParty,
  GqlVessel,
  GqlVoyage,
  PaymentType,
  ReleaseType,
  VanCode,
} from 'api/GQL_Types';
import { Document } from 'app/components/DocumentsPanel';
import { Remark } from 'components/Remarks';
import { DateAtomType, newAtom } from 'recoil-utils/utils';

export interface OpenLineItem extends GqlPackLine {
  itemDescription: string;
  orderNumber: string;
  itemNumber: string;
  bookingNumber: string;
  quantityLeft: number;
  ctnsLeft: number;
}

export interface ItemPosition {
  id: string;
  packLine: GqlPackLine;
  piecesQty: number;
  cartonsQty: number;
  volume: number;
  weight: number;
  vanPositionCode: VanCode | null;
}

export interface Bookings {
  id: string;
  referenceNumber: { to: string; value: string };
  bookingDate: Date | null;
  weight: number;
  volume: number;
  bookingStatus: string;
  cargoReadyDate: Date | null | undefined;
  pol: string;
  polEtd: Date | null | undefined;
  pod: string;
  finalDestinationName: string;
  supplierName: string;
  moveType: string;
  containers: string;
  relatedParties: GqlRelatedParty[];
}

export interface Containers {
  id: string;
  isHot: boolean;
  containerNumber: { to: string; value: string };
  containerType: string;
  sealNumber: string;
  volume: number;
  weight: number;
  terminalReceivedDate: Date | null | undefined;
}

export interface VesselOption {
  id: string | null; // null if it's a new vessel
  name: string;
  voyages: VoyageOption[];
}

export interface VoyageOption {
  id: string | null; // null if it's a new voyage
  name: string;
}

export function fromGQL_VesselOption(
  vessel: Pick<GqlVessel, 'id' | 'name'> & { voyages: Pick<GqlVoyage, 'id' | 'name'>[] }
): VesselOption {
  return {
    id: vessel.id || '',
    name: vessel.name || '',
    voyages: vessel.voyages.map(fromGQL_VoyageOption),
  };
}

export function fromGQL_VoyageOption(vessel: Pick<GqlVoyage, 'id' | 'name'>): VoyageOption {
  return {
    id: vessel.id || '',
    name: vessel.name || '',
  };
}

export const ConsolidationPageStates = {
  consolidation: newAtom<GqlConsolidation | null>(null),
  allRemarks: newAtom<Remark[]>([]),
  allDocuments: newAtom<Document[]>([]),
  editLogistics: {
    confirmationNumber: newAtom<string>(''),
    confirmationDate: newAtom<DateAtomType>(null),
    carrier: newAtom<GqlCarrier | null>(null),
    carrierConfirmationNumber: newAtom<string>(''),
    mbl: newAtom<string>(''),
    mblPaymentType: newAtom<PaymentType | null>(null),
    mblReleaseType: newAtom<ReleaseType | null>(null),
    hblPerBooking: newAtom<BookingPerHbl[]>([]),
    contractType: newAtom<ContractType | null>(null),
    contract: newAtom<string>(''),
    cyCutoff: newAtom<DateAtomType>(null),
    cfsCutoff: newAtom<DateAtomType>(null),
    cfsReceived: newAtom<DateAtomType>(null),
    vgmCutoff: newAtom<DateAtomType>(null),
    siCutoff: newAtom<DateAtomType>(null),
    motherVessel: newAtom<VesselOption | null>(null),
    motherVoyage: newAtom<VoyageOption | null>(null),
    voyage: newAtom<string>(''),
    feederVessel: newAtom<VesselOption | null>(null),
    feederVoyage: newAtom<VoyageOption | null>(null),
    transitPort: newAtom<GqlPort | null>(null),
    transitPortEtd: newAtom<DateAtomType>(null),
    transitPortAtd: newAtom<DateAtomType>(null),
    transitPortEta: newAtom<DateAtomType>(null),
    transitPortAta: newAtom<DateAtomType>(null),
    pol: newAtom<GqlPort | null>(null),
    polEtd: newAtom<DateAtomType>(null),
    polAtd: newAtom<DateAtomType>(null),
    pod: newAtom<GqlPort | null>(null),
    podEta: newAtom<DateAtomType>(null),
    ramp: newAtom<GqlPort | null>(null),
    rampEta: newAtom<DateAtomType>(null),
    finalDestination: newAtom<GqlLocation | null>(null),
    deliveryEta: newAtom<DateAtomType>(null),
  },
  containerAssignment: {
    selectedContainerType: newAtom<string | null>(null),
    selectedContainer: newAtom<GqlContainer | null>(null),
    editContainer: {
      containerNumber: newAtom<string>(''),
      containerType: newAtom<string | null>(null),
      sealNumber: newAtom<string>(''),
      shipFromFactory: newAtom<DateAtomType>(null),
      terminalReceived: newAtom<DateAtomType>(null),
      selectedConsolidationPackLines: newAtom<ItemPosition[]>([]),
      allPackLines: newAtom<GqlPackLine[]>([]),
      availableConsolidationPackLines: newAtom<OpenLineItem[]>([]),
    },
  },
};

export interface BookingPerHbl {
  bookingId: string;
  bookingReferenceNumber: string;
  hblId: string | null;
  hblReferenceNumber: string;
  hblPaymentType: PaymentType | null;
  hblReleaseType: ReleaseType | null;
}
