import { Box, Grid } from '@material-ui/core';
import { PartyType } from 'api/GQL_Types';
import LineItem from 'components/LineItem';
import Panel from 'components/Panel';
import React from 'react';
import { portToString } from 'types/Port';
import { formatDate } from 'utils/Dates';
import { PoPageData } from './states';

interface Props {
  data: PoPageData;
}

export const PoDetails: React.FC<Props> = ({ data }) => {
  const details = data.details;

  const partyByType = details.relatedPartyByType;

  return (
    <Panel title="Purchase Order Details">
      <Box margin={2}>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={3}>
            <LineItem label="Consignee" value={partyByType.get(PartyType.Consignee)?.name || ''} />
            <LineItem label="Supplier" value={partyByType.get(PartyType.Supplier)?.name || ''} />
            <LineItem label="Exp Cgo Rdy Date" value={details.expectedCargoReadyDate} />
            <LineItem label="First Ship" value={details.firstShipDate} />
            <LineItem label="INCO Term" value={details.incoTerm} />
            <LineItem label="Buyer" value={partyByType.get(PartyType.Buyer)?.name || ''} />
            <LineItem label="Customer Start Ship Date" value={details.customerStartShipDate} />
            <LineItem label="POL" value={details.pol ? portToString(details.pol) : ''} />
          </Grid>
          <Grid item xs={12} sm={3}>
            <LineItem label="PO" value={details.poNumber} />
            <LineItem
              label="Supplier Code"
              value={partyByType.get(PartyType.Supplier)?.code || ''}
            />
            <LineItem label="Rev Cgo Rdy Date" value={details.revisedCargoReadyDate} />
            <LineItem label="Last Ship" value={details.lastShipDate} />
            <LineItem label="Country of Origin" value={details.countryOfOrigin} />
            <LineItem label="Buyer Code" value={partyByType.get(PartyType.Buyer)?.code} />
            <LineItem label="Customer Cancel Date" value={details.customerCancelDate} />
            <LineItem label="POD" value={details.pod ? portToString(details.pod) : ''} />
          </Grid>
          <Grid item xs={12} sm={3}>
            <LineItem label="PO Date" value={formatDate(details.poDate)} />
            <LineItem label="Manufacturer" value={partyByType.get(PartyType.Manufacturer)?.name} />
            <LineItem label="Book By Date" value={details.bookByDate} />
            <LineItem label="Ship to Loc" value={details.shipToLocationName} />
            <LineItem label="Customer" value={partyByType.get(PartyType.Customer)?.name || ''} />
            <LineItem label="HTS Code" value={details.htsCode} />
            <LineItem label="Customer PO" value={details.customerPoNumber} />
          </Grid>
          <Grid item xs={12} sm={3}>
            <LineItem label="Alt PO" value={details.altPoNumber} />
            <LineItem
              label="Buying Agent"
              value={partyByType.get(PartyType.BuyingAgent)?.name || ''}
            />
            <LineItem label="INDC Date" value={details.indcDate} />
            <LineItem label="Order Status" value={details.poStatus} />
            <LineItem label="Ship to Loc Code" value={details.shipToLocationCode} />
            <LineItem
              label="Customer Code"
              value={partyByType.get(PartyType.Customer)?.code || ''}
            />
            <LineItem label="Order Type" value={details.poType} />
          </Grid>
        </Grid>
      </Box>
    </Panel>
  );
};
