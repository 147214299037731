import React from 'react';
import { RouteComponentProps } from '@reach/router';
import UWLTableV2 from 'components/g-table/UWLTableV2';

interface Props extends RouteComponentProps {}
export default function NewWindowTable(props: Props) {
  const data = JSON.parse(localStorage.getItem('table-data-window') ?? '{}');

  return (
    <div style={{ width: '100%', height: '100%', padding: '12px' }}>
      <UWLTableV2
        rows={[...data.data]}
        columns={[...data.columns]}
        emptyMessage="No Data"
        linkInTab
      />
    </div>
  );
}

export function openInNewTableWindow(tableName: string, columns: any[], data: any[]) {
  localStorage.setItem(
    'table-data-window',
    JSON.stringify({
      columns: columns,
      data: data,
    })
  );
  window.open('/' + tableName + '/table-view', '', 'fullscreen=yes,resizable=no');
}
