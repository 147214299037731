import {
  ContainerMode,
  ContractType,
  GqlCarrier,
  GqlLocation,
  GqlPort,
  GqlRelatedParty,
  IncoTerm,
  MoveType,
  TransportMode,
  VolumeUnit,
  WeightUnit,
} from 'api/GQL_Types';
import { useSetRecoilState } from 'recoil';
import { DateAtomType, newAtom } from 'recoil-utils/utils';

export interface OpenOrderLine {
  id: string;
  poId: string;
  orderNumber: string;
  itemNumber: string;
  lineNumber: string;
  orderQty: number;
  balanceQty: number;
  shippedQty: number;
  shippedCartons: number;
  weight: number;
  weightUnit: WeightUnit;
  volume: number;
  volumeUnit: VolumeUnit;
  expectedCargoReadyDate: Date | null | undefined;
  revisedCargoReadyDate: Date | null | undefined;
  firstShipDate: Date | null | undefined;
  lastShipDate: Date | null | undefined;
  requestedDeliveryDate: Date | null | undefined; //check
  shipToLocationName: string;
}

export interface PackLine extends OpenOrderLine {
  relatedParties: GqlRelatedParty[];
}

export interface EquipmentSelectionRow {
  containerType: string;
  qty: number;
}

export const NewBookingPageStates = {
  activeStep: newAtom<0 | 1>(0),

  step1: {
    allLines: newAtom<OpenOrderLine[]>([]),

    selectedLines: newAtom<OpenOrderLine[]>([]),
    selectedLinesChecked: newAtom<string[]>([]),

    availableLines: newAtom<OpenOrderLine[]>([]),
    availableLinesChecked: newAtom<string[]>([]),
  },

  step2: {
    // Step 2 form fields
    formBookingParty: newAtom<string>(''),
    formSupplier: newAtom<GqlRelatedParty | null>(null),
    formConsignee: newAtom<GqlRelatedParty | null>(null),
    formManufacturer: newAtom<GqlRelatedParty | null>(null),
    formTransportationMode: newAtom<TransportMode | null>(TransportMode.Sea),
    formContainerMode: newAtom<ContainerMode | null>(ContainerMode.Fcl),
    formIncoTerm: newAtom<IncoTerm | null>(null),
    formMoveType: newAtom<MoveType | null>(MoveType.CyCy),
    formPickupLocation: newAtom<GqlLocation | null>(null),
    formDeliveryLocation: newAtom<GqlLocation | null>(null),
    formCargoReadyDate: newAtom<DateAtomType>(null),
    formCarrier: newAtom<GqlCarrier | null>(null),
    formContract: newAtom<string>(''),
    formContractType: newAtom<ContractType | null>(ContractType.Nvo),
    formEtd: newAtom<DateAtomType>(null),
    formEta: newAtom<DateAtomType>(null),
    formPol: newAtom<GqlPort | null>(null),
    formPod: newAtom<GqlPort | null>(null),

    equipmentList: newAtom<EquipmentSelectionRow[]>([]),

    bookingRemark: newAtom(''),

    packLines: newAtom<PackLine[]>([]),
  },

  newBooking: newAtom<{
    bookingId: string;
    referenceNumber: string;
  } | null>(null),
};

export function useSetupStep1() {
  const setAllLines = useSetRecoilState(NewBookingPageStates.step1.allLines);
  const setSelectedLines = useSetRecoilState(NewBookingPageStates.step1.selectedLines);
  const setSelectedLinesChecked = useSetRecoilState(
    NewBookingPageStates.step1.selectedLinesChecked
  );
  const setAvailableLines = useSetRecoilState(NewBookingPageStates.step1.availableLines);
  const setAvailableLinesChecked = useSetRecoilState(
    NewBookingPageStates.step1.availableLinesChecked
  );

  return (openOrders: OpenOrderLine[]) => {
    setAllLines(openOrders);
    setSelectedLines([]);
    setSelectedLinesChecked([]);
    setAvailableLines(openOrders);
    setAvailableLinesChecked([]);
  };
}
