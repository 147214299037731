import React from 'react';
import { Divider, Drawer, List } from '@material-ui/core';
import clsx from 'clsx';
import { sidenavStyle } from './navstyles';
import NavLogo from './NavLogo';
import NavItems from './NavItems';
import DrawerBtn from './DrawerBtn';
import UserAvatar from './UserAvatar';

export default function SideNavigation() {
  const [drawerOpen, setDrawerOpen] = React.useState<boolean>(getDrawerState());
  function getDrawerState(): boolean {
    return localStorage.getItem('drawerOpen') === 'true';
  }

  const classes = sidenavStyle();

  React.useEffect(() => {
    localStorage.setItem('drawerOpen', JSON.stringify(drawerOpen));
  }, [drawerOpen]);
  return (
    <Drawer
      variant="permanent"
      className={clsx(classes.drawer, classes.drawer, {
        [classes.drawerOpen]: drawerOpen,
        [classes.drawerClose]: !drawerOpen,
      })}
      classes={{
        paper: clsx(classes.drawerPaper, classes.drawerBackground, {
          [classes.drawerOpen]: drawerOpen,
          [classes.drawerClose]: !drawerOpen,
        }),
      }}
      open={drawerOpen}
    >
      <NavLogo
        open={drawerOpen}
        onChange={(newValue: boolean) => {
          setDrawerOpen(newValue);
        }}
      />
      <NavItems open={drawerOpen} />

      <Divider />
      <List className={classes.drawerSpacer} />
      <DrawerBtn
        open={drawerOpen}
        onChange={(newValue: boolean) => {
          setDrawerOpen(newValue);
        }}
      />
      <UserAvatar />
    </Drawer>
  );
}
