import React, { CSSProperties } from 'react';
import { CellMeasurer, CellMeasurerCache, Grid, Index, OnScrollParams } from 'react-virtualized';
import { getRenderedRows, indexCols } from '../core';
import { ExpandableRow, GTableColumn, RenderCell, RenderCells } from '../types';
import { HeaderSortDecorator } from './HeaderSortDecorator';

interface Props<T> {
  width: number;
  height: number;
  rows: any[];
  columns: GTableColumn<T>[];
  styles?: CSSProperties;
  renderCell?: RenderCell<T>;
  renderCells?: RenderCells<T>;
  expandable?: ExpandableRow<T>;
  onRowClick?: (data: T) => void;
  onCellClick?: (data: T) => void;
  scrollLeft?: number;
  onScroll?: (params: OnScrollParams) => void;
  noContentRenderer?: () => React.ReactNode;
  onHeightEstimated?: (height: number) => void;
  onSort?: (column: GTableColumn<T>, direction: 'asc' | 'desc') => void;
  contentHeight?: number;
}

const defaultHeight = 30;

export default function HeaderGrid<T extends {}>(props: Props<T>) {
  const [realHeightCache, setRealHeightCache] = React.useState<{ [key: string]: number }>({});
  const [cache] = React.useState(
    new CellMeasurerCache({
      fixedWidth: true,
      defaultHeight: defaultHeight,
    })
  );
  const [grid, setGrid] = React.useState<Grid | null>(null);
  const [sortedCol, setSortedCol] = React.useState<null | GTableColumn<T>>(null);
  const [sortDirection, setSortDirection] = React.useState<'asc' | 'desc'>('desc');
  const [renderedRows, setRenderedRows] = React.useState<any[]>(props.rows);
  const [expandedRows] = React.useState<number[]>([]);

  const totalWeight = props.columns.reduce((sum, current) => sum + current.weight, 0);
  const indexedCols = indexCols(props.columns);

  React.useEffect(() => {
    cache.clearAll();
    grid?.forceUpdate();
    calculateRHC();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.width]);

  React.useEffect(() => {
    // cache.clearAll();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.rows]);

  React.useEffect(() => {
    const renderedRows: any[] = getRenderedRows(props.rows, expandedRows);
    setRenderedRows(renderedRows);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [expandedRows, props.rows]);

  React.useEffect(() => {
    calculateRHC();
    cache.clearAll();
    grid?.forceUpdate();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [renderedRows]);

  React.useEffect(() => {
    if (props.onHeightEstimated) {
      const estimate = Object.values(realHeightCache).reduce(
        (prev: number, current: number) => prev + current,
        0
      );
      if (estimate !== 0 && estimate !== defaultHeight) {
        props.onHeightEstimated(estimate);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [realHeightCache]);

  React.useEffect(() => {
    if (sortedCol && props.onSort) {
      props.onSort(sortedCol, sortDirection);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sortedCol, sortDirection]);

  function calculateRHC() {
    const realHC: any = { ...realHeightCache };

    for (let i = 0; i < renderedRows.length; i++) {
      const h = cache.rowHeight({ index: i });
      if (!Object.keys(realHeightCache).includes(renderedRows[i].id) && h !== defaultHeight) {
        realHC[renderedRows[i].id] = h;
      }
    }

    if (Object.keys(realHeightCache).length !== Object.keys(realHC).length) {
      setRealHeightCache(realHC);
    }
  }

  calculateRHC();

  return (
    <Grid
      ref={(refGrid) => {
        setGrid(refGrid);
      }}
      noContentRenderer={props.noContentRenderer}
      rowHeight={(row: Index) => {
        const currentItem = renderedRows[row.index];

        if (!currentItem) {
          return 1;
        }
        if (currentItem)
          if (Object.keys(currentItem).includes('_isCollapsibleContent')) {
            return props.contentHeight ?? 1000;
          }

        if (!realHeightCache[currentItem.id]) {
          return defaultHeight;
        }
        return realHeightCache[currentItem.id];
      }}
      columnCount={props.columns.length}
      height={props.height}
      width={props.width}
      onScroll={props.onScroll ?? undefined}
      scrollLeft={props.onScroll ? undefined : props.scrollLeft}
      style={{
        overflowX: 'hidden',
      }}
      overscanIndicesGetter={(data) => {
        if (data.direction === 'horizontal') {
          return { overscanStartIndex: 0, overscanStopIndex: data.stopIndex };
        }
        let start = data.startIndex;
        let end = data.stopIndex;

        if (end < data.cellCount - 1) {
          let diff = data.cellCount - 1 - end;
          if (diff > 1) {
            diff = 1;
          }
          end = end + diff;
        }

        if (start > 0) {
          let diff = start - 1;
          if (diff < 0) {
            start = 0;
          } else {
            start = diff;
          }
        }

        return {
          overscanStartIndex: start,
          overscanStopIndex: end,
        };
      }}
      columnWidth={(params: Index) => {
        return totalWeight < props.width
          ? (indexedCols[params.index].weight / totalWeight) * props.width
          : indexedCols[params.index].weight;
      }}
      cellRenderer={(cellProps) => {
        const gColumn = indexedCols[cellProps.columnIndex];
        let renderCell = props.renderCell && props.renderCell[gColumn.id];
        let renderCells = props.renderCells;

        const controlRenderCell =
          props.expandable &&
          gColumn.id === props.expandable.id &&
          props.expandable.controlRenderCell;

        if (controlRenderCell) {
          renderCell = undefined;
          renderCells = undefined;
        }

        const rowValue = renderedRows[cellProps.rowIndex] as any;

        return (
          <CellMeasurer
            cache={cache}
            columnIndex={cellProps.columnIndex}
            key={cellProps.key}
            parent={cellProps.parent}
            rowIndex={cellProps.rowIndex}
          >
            <div
              style={{
                ...cellProps.style,
                ...props.styles,
                width:
                  totalWeight < props.width
                    ? props.width * (gColumn.weight / totalWeight)
                    : gColumn.weight,
              }}
              onClick={() => {
                if (props.onRowClick) {
                  props.onRowClick(rowValue);
                }
                if (props.onCellClick) {
                  props.onCellClick(rowValue[gColumn.id]);
                }
              }}
            >
              {renderCell ? (
                renderCell(rowValue)
              ) : (
                <HeaderSortDecorator
                  isSortedCol={sortedCol?.id === gColumn.id}
                  direction={sortDirection}
                  onChange={(direction: 'asc' | 'desc') => {
                    setSortedCol(gColumn);
                    setSortDirection(direction);
                  }}
                >
                  {renderCells ? renderCells(gColumn, rowValue) : rowValue[gColumn.id]}
                </HeaderSortDecorator>
              )}
            </div>
          </CellMeasurer>
        );
      }}
      deferredMeasurementCache={cache}
      rowCount={renderedRows.length}
    />
  );
}
