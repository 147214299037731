import { Box, Button } from '@material-ui/core';
import { useRecoilState, useRecoilValue } from 'recoil';
import {
  GqlVanPositionInput,
  useAssignConsolidationContainerMutation,
  useUpdateContainerMutation,
} from 'api/GQL_Types';
import { useSnackbar } from 'notistack';
import { isUUID } from 'utils/UUID';
import { ConsolidationPageStates, ItemPosition } from 'app/consolidations/details/states';
import { ConsolidationQuery } from 'api/queries/consolidationQueries';

export default function SubmitFooter() {
  const consolidation = useRecoilValue(ConsolidationPageStates.consolidation);
  const [selectedContainer, setSelectedContainer] = useRecoilState(
    ConsolidationPageStates.containerAssignment.selectedContainer
  );
  // TODO Make this all of the pack lines
  const selectedItems = useRecoilValue(
    ConsolidationPageStates.containerAssignment.editContainer.selectedConsolidationPackLines
  );
  const containerNumber = useRecoilValue(
    ConsolidationPageStates.containerAssignment.editContainer.containerNumber
  );
  const containerType = useRecoilValue(
    ConsolidationPageStates.containerAssignment.editContainer.containerType
  );
  const sealNumber = useRecoilValue(
    ConsolidationPageStates.containerAssignment.editContainer.sealNumber
  );
  const shipFromFactory = useRecoilValue(
    ConsolidationPageStates.containerAssignment.editContainer.shipFromFactory
  );
  const terminalReceived = useRecoilValue(
    ConsolidationPageStates.containerAssignment.editContainer.terminalReceived
  );

  const { enqueueSnackbar } = useSnackbar();

  const [updateConsolidationContainer, { loading: updateLoading }] = useUpdateContainerMutation({
    onCompleted: (data) => {
      if (data.updateContainer.success) {
        setSelectedContainer(null);
        enqueueSnackbar('Container Update!', {
          variant: 'success',
          anchorOrigin: {
            vertical: 'top',
            horizontal: 'right',
          },
        });
      }
    },
    refetchQueries: () => [
      {
        query: ConsolidationQuery,
        variables: { consolId: consolidation?.id },
      },
    ],
  });

  const [assignConsolidationContainer, { loading: assignLoading }] =
    useAssignConsolidationContainerMutation({
      onCompleted: (data) => {
        if (data.assignConsolidationContainer.success) {
          setSelectedContainer(null);
          enqueueSnackbar('Container Assigned!', {
            variant: 'success',
            anchorOrigin: {
              vertical: 'top',
              horizontal: 'right',
            },
          });
        }
      },
      refetchQueries: () => [
        {
          query: ConsolidationQuery,
          variables: { consolId: consolidation?.id },
        },
      ],
    });

  return (
    <Box borderTop="1px solid #EDEDED" display="flex" justifyContent="flex-end" padding={2}>
      <Button
        variant="contained"
        onClick={() => {
          setSelectedContainer(null);
        }}
        style={{
          marginRight: '24px',
          width: '100px',
        }}
      >
        Cancel
      </Button>
      <Button
        variant="contained"
        color="primary"
        style={{ width: '100px' }}
        disabled={hasIncompleteItems(selectedItems) || updateLoading || assignLoading}
        onClick={() => {
          const vanPos: GqlVanPositionInput[] = [];
          for (const itemPos of selectedItems) {
            vanPos.push({
              id: isUUID(itemPos.id) ? itemPos.id : undefined,
              piecesQty: itemPos.piecesQty,
              cartonsQty: itemPos.cartonsQty,
              volume: itemPos.volume,
              weight: itemPos.weight,
              vanCode: itemPos.vanPositionCode,
              packLineId: itemPos.packLine.id,
            });
          }
          if (selectedContainer?.containerNumber) {
            updateConsolidationContainer({
              variables: {
                input: {
                  container: {
                    id: selectedContainer?.id,
                    containerNumber: containerNumber,
                    containerType: containerType,
                    sealNumber: sealNumber,
                    shipFromFactoryDate: shipFromFactory,
                    terminalReceivedDate: terminalReceived,
                  },
                  vanPositions: vanPos,
                },
              },
            });
          } else {
            assignConsolidationContainer({
              variables: {
                input: {
                  consolidationId: consolidation?.id || '',
                  container: {
                    id: selectedContainer?.id,
                    containerNumber: containerNumber,
                    containerType: containerType,
                    sealNumber: sealNumber,
                    shipFromFactoryDate: shipFromFactory,
                    terminalReceivedDate: terminalReceived,
                  },
                  vanPositions: vanPos,
                },
              },
            });
          }
        }}
      >
        Save
      </Button>
    </Box>
  );
}

function hasIncompleteItems(selectedItems: ItemPosition[]) {
  for (const item of selectedItems) {
    if (!item.packLine.id) {
      return true;
    }
  }
  return false;
}
