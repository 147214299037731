import { Box, Button } from '@material-ui/core';
import { useRemoveProfileRelationMutation } from 'api/GQL_Types';
import { ProfileQuery } from 'api/queries/profileQueries';
import { Line } from 'components/StyledComponents';
import UniversalDialog from 'components/UniversalDialog';
import { useSnackbar } from 'notistack';
import React from 'react';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import { AtomCleaner, newAtom } from 'recoil-utils/utils';
import { ProfilePageRelatedProfile } from '../states';

export const RemoveRelatedProfileDialog_Open = newAtom(false);
export const RemoveRelatedProfileDialog_Data = newAtom<ProfilePageRelatedProfile | null>(null);

interface Props {
  profileId: string;
  profileName: string;
}

export default function RemoveRelatedProfileDialog(props: Props) {
  const [openDialog, setOpenDialog] = useRecoilState(RemoveRelatedProfileDialog_Open);
  const relatedProfile = useRecoilValue(RemoveRelatedProfileDialog_Data);

  return (
    <UniversalDialog
      open={openDialog}
      title="Remove Related Profile"
      subTitle={
        'Remove relation between ' +
        relatedProfile?.profile.name +
        ' profile and ' +
        props.profileName +
        ' profile?'
      }
      setClose={() => {
        setOpenDialog(false);
      }}
    >
      <AtomCleaner atoms={[RemoveRelatedProfileDialog_Data]} />
      <Line height={1} />
      <DialogFooter profileId={props.profileId} />
    </UniversalDialog>
  );
}

interface DialogFooterProps {
  profileId: string;
}

function DialogFooter(props: DialogFooterProps) {
  const setOpenDialog = useSetRecoilState(RemoveRelatedProfileDialog_Open);
  const relatedProfile = useRecoilValue(RemoveRelatedProfileDialog_Data);
  const { enqueueSnackbar } = useSnackbar();

  const [removeProfileRelation, { data }] = useRemoveProfileRelationMutation({
    refetchQueries: () => [
      {
        query: ProfileQuery,
        variables: { profileId: props.profileId },
        fetchPolicy: 'cache-and-network',
      },
    ],
  });

  React.useEffect(() => {
    if (data) {
      if (data.removeProfileRelation?.success) {
        setOpenDialog(false);
        enqueueSnackbar('Profile Relation Removed.', {
          variant: 'success',
          anchorOrigin: {
            vertical: 'top',
            horizontal: 'right',
          },
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  return (
    <Box display="flex" alignItems="center" justifyContent="center">
      <Button
        variant="contained"
        color="default"
        size="large"
        onClick={() => {
          setOpenDialog(false);
        }}
        style={{ height: '40px', marginRight: '12px' }}
      >
        Cancel
      </Button>
      <Button
        variant="contained"
        size="large"
        style={{ backgroundColor: '#E6001F', color: '#FFFFFF', height: '40px' }}
        onClick={() => {
          if (!relatedProfile) return;

          removeProfileRelation({
            variables: {
              input: {
                relationId: relatedProfile.id,
              },
            },
          });
        }}
      >
        Remove Profile Relation
      </Button>
    </Box>
  );
}
