import { AppWideStates } from 'app/states';
import { HighlightMatches } from 'components/HighlightMatches';
import React from 'react';
import { RecoilState, useRecoilValue } from 'recoil';
import { makeCss, theme } from 'styles';
import { formatNumberDecimalPlaces } from 'utils/Numbers';
import AtomicAutocompleteV2 from './AtomicAutocompleteV2';

const classes = makeCss({
  option: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: '100%',
  },
  volumeTag: {
    borderRadius: theme.shape.borderRadius,
    padding: theme.spacing(0, 0.5),
    background: theme.palette.grayscale.main,
    fontSize: '.8em',
  },
});

interface Props {
  // State that holds the selected value
  state: RecoilState<string | null>;
  showMinMax?: boolean;
}

export default function AtomicContainerType(props: Props) {
  const containerTypes = useRecoilValue(AppWideStates.network.containerTypes);

  return (
    <AtomicAutocompleteV2<string>
      state={props.state}
      label={'Equipment Type'}
      optionsList={containerTypes.codes}
      displayResolver={(value) => {
        return value;
      }}
      renderOption={
        props.showMinMax
          ? (value, query) => {
              const containerType = containerTypes.byCode.get(value);
              return (
                <div className={classes.option}>
                  <div>
                    <HighlightMatches str={value} searchStr={query} />
                  </div>
                  {containerType && (
                    <div className={classes.volumeTag}>
                      {formatNumberDecimalPlaces(containerType.ruleMinVolumeM3, 0, 1)} -{' '}
                      {formatNumberDecimalPlaces(containerType.ruleMaxVolumeM3, 0, 1)} M3
                    </div>
                  )}
                </div>
              );
            }
          : undefined
      }
    />
  );
}
