import { Box, Button, Grid, Typography } from '@material-ui/core';
import { Link } from '@reach/router';
import { GqlConsolidation, PermissionCode } from 'api/GQL_Types';
import { userContextAtom } from 'app';
import LineItem from 'components/LineItem';
import Panel from 'components/Panel';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { formatDate } from 'utils/Dates';
import { ConsolidationPageStates, fromGQL_VesselOption, fromGQL_VoyageOption } from '../states';
import EditLogisticsDialog, { EditLogisticsDialog_Open } from './edit-logistics-dialog';

interface Props {
  consolidationId: string;
}

export default function LogisticDetailsPanel(props: Props) {
  const userContext = useRecoilValue(userContextAtom);
  const setEditLogisticsDialog = useSetRecoilState(EditLogisticsDialog_Open);

  const consolidation = useRecoilValue(ConsolidationPageStates.consolidation);

  const setConfirmationNumber = useSetRecoilState(
    ConsolidationPageStates.editLogistics.confirmationNumber
  );
  const setConfirmationDate = useSetRecoilState(
    ConsolidationPageStates.editLogistics.confirmationDate
  );

  const setCarrier = useSetRecoilState(ConsolidationPageStates.editLogistics.carrier);
  const setCarrierConfirmation = useSetRecoilState(
    ConsolidationPageStates.editLogistics.carrierConfirmationNumber
  );
  const setContract = useSetRecoilState(ConsolidationPageStates.editLogistics.contract);
  const setContractType = useSetRecoilState(ConsolidationPageStates.editLogistics.contractType);
  const setHblPerBooking = useSetRecoilState(ConsolidationPageStates.editLogistics.hblPerBooking);
  const setMbl = useSetRecoilState(ConsolidationPageStates.editLogistics.mbl);
  const setMblPaymentType = useSetRecoilState(ConsolidationPageStates.editLogistics.mblPaymentType);
  const setMblReleaseType = useSetRecoilState(ConsolidationPageStates.editLogistics.mblReleaseType);

  const setCfsCutoff = useSetRecoilState(ConsolidationPageStates.editLogistics.cfsCutoff);
  const setCfsReceived = useSetRecoilState(ConsolidationPageStates.editLogistics.cfsReceived);
  const setCyCutoff = useSetRecoilState(ConsolidationPageStates.editLogistics.cyCutoff);
  const setVgmCutoff = useSetRecoilState(ConsolidationPageStates.editLogistics.vgmCutoff);
  const setSiCutoff = useSetRecoilState(ConsolidationPageStates.editLogistics.siCutoff);

  const setPol = useSetRecoilState(ConsolidationPageStates.editLogistics.pol);
  const setPolEtd = useSetRecoilState(ConsolidationPageStates.editLogistics.polEtd);
  const setPolAtd = useSetRecoilState(ConsolidationPageStates.editLogistics.polAtd);

  const setTransitPort = useSetRecoilState(ConsolidationPageStates.editLogistics.transitPort);
  const setTransitPortEta = useSetRecoilState(ConsolidationPageStates.editLogistics.transitPortEta);
  const setTransitPortAta = useSetRecoilState(ConsolidationPageStates.editLogistics.transitPortAta);
  const setTransitPortEtd = useSetRecoilState(ConsolidationPageStates.editLogistics.transitPortEtd);
  const setTransitPortAtd = useSetRecoilState(ConsolidationPageStates.editLogistics.transitPortAtd);

  const setPod = useSetRecoilState(ConsolidationPageStates.editLogistics.pod);
  const setPodEta = useSetRecoilState(ConsolidationPageStates.editLogistics.podEta);
  const setRamp = useSetRecoilState(ConsolidationPageStates.editLogistics.ramp);
  const setRampEta = useSetRecoilState(ConsolidationPageStates.editLogistics.rampEta);
  const setFinalDestination = useSetRecoilState(
    ConsolidationPageStates.editLogistics.finalDestination
  );
  const setDeliveryEta = useSetRecoilState(ConsolidationPageStates.editLogistics.deliveryEta);

  const setMotherVessel = useSetRecoilState(ConsolidationPageStates.editLogistics.motherVessel);
  const setMotherVoyage = useSetRecoilState(ConsolidationPageStates.editLogistics.motherVoyage);
  const setFeederVessel = useSetRecoilState(ConsolidationPageStates.editLogistics.feederVessel);
  const setFeederVoyage = useSetRecoilState(ConsolidationPageStates.editLogistics.feederVoyage);

  return (
    <Panel
      title="Logistics Details"
      topRight={
        ((consolidationIsConfirmed(consolidation) &&
          userContext?.permissionCodes.has(PermissionCode.ConsolidationLogisticsUpdate)) ||
          (!consolidationIsConfirmed(consolidation) &&
            userContext?.permissionCodes.has(PermissionCode.ConsolidationConfirm))) && (
          <Button
            variant="contained"
            color="secondary"
            onClick={() => {
              setConfirmationNumber(consolidation?.logistics.confirmationNumber ?? '');
              setConfirmationDate(consolidation?.logistics.confirmationDate);

              setCarrier(consolidation?.logistics.carrier ?? null);
              setCarrierConfirmation(consolidation?.logistics.carrierConfirmationNumber ?? '');
              setContract(consolidation?.logistics.contractNumber ?? '');
              setContractType(consolidation?.logistics.contractType ?? null);
              setHblPerBooking(
                consolidation
                  ? consolidation.bookings.map((booking) => {
                      return {
                        bookingId: booking.id,
                        bookingReferenceNumber: booking.referenceNumber,
                        hblId: booking.hbl?.id || null,
                        hblReferenceNumber: booking.hbl?.referenceNumber || '',
                        hblPaymentType: booking.hbl?.paymentType || null,
                        hblReleaseType: booking.hbl?.releaseType || null,
                      };
                    })
                  : []
              );
              setMbl(consolidation?.mbl?.referenceNumber ?? '');
              setMblPaymentType(consolidation?.mbl?.paymentType ?? null);
              setMblReleaseType(consolidation?.mbl?.releaseType ?? null);

              setCfsCutoff(consolidation?.logistics.cfsCutoffDate);
              setCfsReceived(consolidation?.logistics.cfsReceivedDate);
              setCyCutoff(consolidation?.logistics.cyCutoffDate);
              setVgmCutoff(consolidation?.logistics.vgmCutoffDate);
              setSiCutoff(consolidation?.logistics.siCutoffDate);

              setPol(consolidation?.logistics.pol ?? null);
              setPolEtd(consolidation?.logistics.polEtd);
              setPolAtd(consolidation?.logistics.polAtd);

              setTransitPort(consolidation?.logistics.transitPort ?? null);
              setTransitPortEta(consolidation?.logistics.transitPortEta ?? null);
              setTransitPortAta(consolidation?.logistics.transitPortAta ?? null);
              setTransitPortEtd(consolidation?.logistics.transitPortEtd ?? null);
              setTransitPortAtd(consolidation?.logistics.transitPortAtd ?? null);

              setPod(consolidation?.logistics.pod ?? null);
              setPodEta(consolidation?.logistics.podEta);
              setRamp(consolidation?.logistics.ramp ?? null);
              setRampEta(consolidation?.logistics.rampEta);
              setFinalDestination(consolidation?.logistics.finalDestination ?? null);
              setDeliveryEta(consolidation?.logistics.deliveryEta ?? null);

              setMotherVessel(
                consolidation?.logistics.motherVessel
                  ? fromGQL_VesselOption(consolidation?.logistics.motherVessel)
                  : null
              );
              setMotherVoyage(
                consolidation?.logistics.motherVoyage
                  ? fromGQL_VoyageOption(consolidation?.logistics.motherVoyage)
                  : null
              );
              setFeederVessel(
                consolidation?.logistics.feederVessel
                  ? fromGQL_VesselOption(consolidation?.logistics.feederVessel)
                  : null
              );
              setFeederVoyage(
                consolidation?.logistics.feederVoyage
                  ? fromGQL_VoyageOption(consolidation?.logistics.feederVoyage)
                  : null
              );

              setEditLogisticsDialog(true);
            }}
          >
            Edit
          </Button>
        )
      }
    >
      <Box padding={3}>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={6}>
            <LineItem value={consolidation?.logistics?.confirmationNumber} label="Forwarder Job" />
            <LineItem
              value={consolidation?.mbl?.referenceNumber}
              label="MBL"
              customValue={(value: any) => {
                return userContext?.permissionCodes.has(PermissionCode.MblRead) ? (
                  <Link
                    to={'/mbl/' + consolidation?.mbl?.id}
                    state={{ consolidationId: props.consolidationId }}
                  >
                    {value}
                  </Link>
                ) : (
                  <Typography variant="body1">{value}</Typography>
                );
              }}
            />
            <LineItem
              label="HBL"
              value={consolidation /* hack so customValue will work */}
              customValue={() => {
                if (!consolidation) {
                  return <span />;
                }
                const links: any[] = [];
                for (const booking of consolidation.bookings) {
                  if (booking.hbl && booking.hbl.id) {
                    if (links.length > 0) {
                      links.push(', ');
                    }
                    links.push(
                      <Link
                        key={booking.hbl.id}
                        to={'/hbl/' + booking.hbl.id}
                        state={{ consolidationId: props.consolidationId }}
                      >
                        {booking.hbl.referenceNumber}
                      </Link>
                    );
                  }
                }
                return <span>{links}</span>;
              }}
            />
            <LineItem
              value={consolidation?.logistics?.cyCutoffDate}
              decorator={formatDate}
              label="CY Cutoff"
            />
            <LineItem
              value={consolidation?.logistics?.vgmCutoffDate}
              decorator={formatDate}
              label="VGM Cutoff"
            />
            <LineItem
              value={consolidation?.logistics?.siCutoffDate}
              decorator={formatDate}
              label="SI Cutoff"
            />
            <LineItem value={consolidation?.logistics?.motherVessel?.name} label="Mother Vessel" />
            <LineItem value={consolidation?.logistics?.motherVoyage?.name} label="Voyage" />
            <LineItem value={consolidation?.logistics?.feederVessel?.name} label="Feeder Vessel" />
          </Grid>
          <Grid item xs={12} sm={6}>
            <LineItem value={consolidation?.logistics?.transitPort?.code} label="Transit Port" />
            <LineItem value={consolidation?.logistics?.pol?.code} label="POL" />
            <LineItem
              value={consolidation?.logistics?.polEtd}
              decorator={formatDate}
              label="POL ETD"
            />
            <LineItem
              value={consolidation?.logistics?.polAtd}
              decorator={formatDate}
              label="POL ATD"
            />
            <LineItem value={consolidation?.logistics?.pod?.code} label="POD" />
            <LineItem
              value={consolidation?.logistics?.podEta}
              decorator={formatDate}
              label="POD ETA"
            />
            <LineItem value={consolidation?.logistics?.ramp?.code} label="Ramp" />
            <LineItem
              value={consolidation?.logistics?.rampEta}
              decorator={formatDate}
              label="Ramp ETA"
            />
            <LineItem
              value={consolidation?.logistics?.finalDestination?.name}
              label="Final Destination"
            />
            <LineItem
              value={consolidation?.logistics?.deliveryEta}
              decorator={formatDate}
              label="Final Destination ETA"
            />
          </Grid>
        </Grid>
      </Box>
      <EditLogisticsDialog />
    </Panel>
  );
}

function consolidationIsConfirmed(consolidation: GqlConsolidation | null): boolean {
  return (
    Boolean(consolidation?.logistics.confirmationNumber) &&
    Boolean(consolidation?.logistics.confirmationDate)
  );
}
