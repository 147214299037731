import { RuleFieldType } from 'api/GQL_Types';
import { FormInputNumber } from 'components/form/FormInputNumber';
import React from 'react';
import { useRecoilState } from 'recoil';
import { NetworkAdminPageStates } from '../states';
import { InputItem, InputRow } from './components/InputLayout';
import { RuleCard } from './components/RuleCard';
import { RuleCardDayField } from './components/RuleCardDayField';
import { RuleCardGroup } from './components/RuleCardGroup';
import { RuleNumDaysInput } from './components/RuleNumDaysInput';

const ALL_poDateFields: RuleFieldType[] = [
  RuleFieldType.PoDate,
  RuleFieldType.PoExpectedCrd,
  RuleFieldType.PoRevisedCrd,
  RuleFieldType.PoIndc,
  RuleFieldType.PoFirstShip,
  RuleFieldType.PoLastShip,
  RuleFieldType.PoBookBy,
];

interface Props {}

export const RulesBooking: React.FC<Props> = (props) => {
  const [rulesForm, setRulesForm] = useRecoilState(NetworkAdminPageStates.rulesForm);

  if (!rulesForm) {
    return <div></div>;
  }

  return (
    <RuleCardGroup
      title="Booking"
      bools={[
        rulesForm.bookingLateEnabled,
        rulesForm.bookingPendingConfEnabled,
        rulesForm.bookingQtyToleranceEnabled,
      ]}
    >
      <RuleCardDayField
        name="Late Booking"
        description="Consider the booking late if it's created after # days since the selected field."
        enabled={rulesForm.bookingLateEnabled}
        onEnabled={(enabled) => {
          setRulesForm({ ...rulesForm, bookingLateEnabled: enabled });
        }}
        days={rulesForm.bookingLateDays}
        onDays={(v) => {
          setRulesForm({ ...rulesForm, bookingLateDays: v });
        }}
        fieldOptions={ALL_poDateFields}
        field={rulesForm.bookingLateField}
        onField={(v) => {
          setRulesForm({ ...rulesForm, bookingLateField: v });
        }}
      />
      <RuleCard
        notYetSupported
        name="Bookings Pending Confirmation"
        description="If the booking has not been confirmed after # days since the booking was created."
        enabled={rulesForm.bookingPendingConfEnabled}
        onEnabled={(enabled) => {
          setRulesForm({ ...rulesForm, bookingPendingConfEnabled: enabled });
        }}
      >
        <InputRow>
          <InputItem small>
            <RuleNumDaysInput
              value={rulesForm.bookingPendingConfDays}
              onValue={(v) => {
                setRulesForm({ ...rulesForm, bookingPendingConfDays: v });
              }}
            />
          </InputItem>
        </InputRow>
      </RuleCard>
      <RuleCard
        name="Tolerance Over/Under Quantity on PO/Line"
        description="Percentage Over/Under a vendor can book against a PO while it still has a balance."
        enabled={rulesForm.bookingQtyToleranceEnabled}
        onEnabled={(enabled) => {
          setRulesForm({ ...rulesForm, bookingQtyToleranceEnabled: enabled });
        }}
      >
        <InputRow>
          <InputItem small>
            <FormInputNumber
              label="Percent Over"
              value={Math.round(rulesForm.bookingQtyTolerancePercentOver * 100)}
              onValue={(v) => {
                setRulesForm({
                  ...rulesForm,
                  bookingQtyTolerancePercentOver: Math.min(100, Math.abs(v || 0)) / 100,
                });
              }}
              endAdornment="%"
              selectAllOnFocus
              maximumFractionDigits={0}
            />
          </InputItem>
          <InputItem small>
            <FormInputNumber
              label="Percent Under"
              value={Math.round(rulesForm.bookingQtyTolerancePercentUnder * 100)}
              onValue={(v) => {
                setRulesForm({
                  ...rulesForm,
                  bookingQtyTolerancePercentUnder: Math.min(100, Math.abs(v || 0)) / 100,
                });
              }}
              endAdornment="%"
              selectAllOnFocus
              maximumFractionDigits={0}
            />
          </InputItem>
          <InputItem></InputItem>
        </InputRow>
      </RuleCard>
    </RuleCardGroup>
  );
};
