import { Box, IconButton, Typography } from '@material-ui/core';
import { navigate, RouteComponentProps, useLocation } from '@reach/router';
import { ManageHeader, ManageLink } from 'components/StyledComponents';
import { LinkItem } from 'types/LinkItem';
import React from 'react';
import { useRecoilState } from 'recoil';
import { newAtom } from 'recoil-utils/utils';
import { makeCss, theme } from 'styles';
import { isUUID } from 'utils/UUID';

// Atom that holds the stack for the back button. Each item in the stack has a display label and the path to navigate to.
export const ManagePageHeader_BackStack = newAtom<{ label: string; path: string }[]>([]);

const classes = makeCss({
  selected: {
    color: theme.palette.blueGreen.main,
    '&:hover': {
      background: '#FFFFFF',
    },
  },

  unSelected: {
    color: theme.palette.grayscale.main,
  },
});

interface Props extends RouteComponentProps {
  title: string | null | undefined;
  links: LinkItem[];
}

export default function ManagePageHeader(props: Props) {
  const [backStack, setBackStack] = useRecoilState(ManagePageHeader_BackStack);
  const location = useLocation();

  React.useEffect(() => {
    // This should handle when browser back is used.
    if (
      backStack.length > 1 &&
      (location.pathname.startsWith(backStack[backStack.length - 1].path) ||
        location.pathname.startsWith('/' + backStack[backStack.length - 1].path))
    ) {
      const copy = [...backStack];
      copy.pop();
      setBackStack(copy);
    }
  }, [location]);

  return (
    <ManageHeader>
      <Box display="flex" alignItems="flex-start" flexWrap="wrap">
        <Box flexGrow={1} width={1} marginRight={1}>
          <Typography
            variant="h1"
            style={{
              minWidth: 'fit-content',
              marginRight: '16px',
              marginBottom: '5px',
            }}
          >
            {props.title}
          </Typography>
          {backStack.length > 0 && (
            <Box
              display="flex"
              justifyContent="flex-start"
              style={{ cursor: 'pointer' }}
              onClick={() => {
                if (backStack.length > 0) {
                  const copy = [...backStack];
                  const to = copy.pop() ?? { label: '', path: '' };
                  setBackStack(copy);
                  navigate(to.path);
                }
              }}
            >
              <IconButton
                style={{ padding: 0, marginRight: '8px', color: theme.palette.blueGreen.main }}
              >
                <i className="material-icons">{'keyboard_backspace'}</i>
              </IconButton>
              <Typography
                variant="subtitle2"
                style={{
                  minWidth: 'fit-content',
                  marginRight: '16px',
                  textAlign: 'end',
                  color: theme.palette.blueGreen.main,
                }}
              >
                Back to {backStack[backStack.length - 1].label}
              </Typography>
            </Box>
          )}
        </Box>
      </Box>
      <Box display="flex" alignItems="flex-start" width={1} marginRight={2}>
        {props.links.map((item, index) => {
          const selected = isSelected(item.path, location.pathname);
          return (
            <Box display="flex" flexDirection="column" width="100%" key={index}>
              <ManageLink to={item.path} selected={selected}>
                <IconButton
                  className={selected ? classes.selected : classes.unSelected}
                  style={{ padding: 0, marginBottom: '4px' }}
                >
                  <i className="material-icons">{item.icon}</i>
                </IconButton>
                <Box style={{ marginBottom: '4px' }}>{item.name}</Box>
              </ManageLink>
            </Box>
          );
        })}
      </Box>
    </ManageHeader>
  );
}

function isSelected(tabPath: string, currentPath: string): boolean {
  const splitPath = currentPath.split('/');
  return (
    (tabPath !== '' && currentPath.endsWith(tabPath)) ||
    (tabPath === '' && isUUID(splitPath[splitPath.length - 1]))
  );
}
