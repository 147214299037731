import { Box, Button, Paper, Typography } from '@material-ui/core';
import { navigate, RouteComponentProps } from '@reach/router';
import { useMyProfileContactsQuery } from 'api/GQL_Types';
import { getReport, saveReport } from 'api/queries/reportQueries';
import ErrorMessage from 'components/ErrorMessage';
import LoadingProgress from 'components/LoadingProgress';
import StepNav from 'components/StepNav';
import { useSnackbar } from 'notistack';
import React from 'react';
import reportCategoriesStore from 'stores/reportCategoriesStore';
import reportFormStore from 'stores/reportFormStore';
import { Report } from 'types/Report';
import { UserNameEmail } from 'types/UserNameEmail';
import DownloadDynamicReportButton from './DownloadDynamicReportButton';
import Step1 from './Step1';
import Step2 from './Step2';
import Step3 from './Step3';
import Step4 from './Step4';
import Step5 from './Step5';

const steps: {
  id: string;
  label: string;
}[] = [
  { id: '1', label: 'Report Details' },
  { id: '2', label: 'Report Columns' },
  { id: '3', label: 'Filters' },
  { id: '4', label: 'Recipients' },
  { id: '5', label: 'Publish' },
];

interface Props extends RouteComponentProps {
  id?: string;
  step?: string;
}

export const ReportPage: React.FC<Props> = (props) => {
  const id: string = props.id || '';
  const step = Math.min(Math.max(parseInt(props.step || '0', 10) || 0, 1), 5);
  const isNew = id === 'new';
  const { enqueueSnackbar } = useSnackbar();

  const { maxStep, values, validation } = reportFormStore.use();

  const { data: datasets } = reportCategoriesStore.use(); // so that we re-render when it loads

  const [report, setReport] = React.useState<Report | null>(null);
  const [isLoading, setIsLoading] = React.useState<boolean>(false);
  const [loadError, setLoadError] = React.useState<string | null>(null);

  const [isSaving, setIsSaving] = React.useState<boolean>(false);
  const [saveError, setSaveError] = React.useState<string | null>(null);

  const [availableContacts, setAvailableContacts] = React.useState<UserNameEmail[]>([]);

  const isInitialSetupDone = !isLoading && (isNew || !!report || loadError);
  React.useEffect(() => {
    if (step > maxStep && isInitialSetupDone) {
      reportFormStore.startValidation();
      enqueueSnackbar('Cannot move to the next step yet. Fill in the required fields.', {
        variant: 'error',
        anchorOrigin: {
          vertical: 'top',
          horizontal: 'right',
        },
      });
      goToStep(maxStep);
    }
  }, [step, maxStep, isInitialSetupDone]);

  React.useEffect(() => {
    reportFormStore.doValidation();
  }, [datasets]);

  React.useEffect(() => {
    if (isNew) {
      setReport(null);
      reportFormStore.init(null);
    } else {
      loadReport();
    }
  }, [id]);

  useMyProfileContactsQuery({
    fetchPolicy: 'no-cache',
    onCompleted(data) {
      const contacts = data.userContext?.activeContact?.profile?.contacts || [];
      const availableContacts = contacts.map((c): UserNameEmail => {
        return {
          id: c.id,
          firstName: c.firstName || '',
          lastName: c.lastName || '',
          email: c.email || '',
        };
      });
      setAvailableContacts(availableContacts);
    },
  });

  async function loadReport() {
    if (report) {
      reportFormStore.init(null);
    }
    setIsLoading(true);
    setLoadError(null);
    setReport(null);
    try {
      const report = await getReport(id);
      reportFormStore.init(report);
      setReport(report);
    } catch (err) {
      setLoadError(err + '');
    } finally {
      setIsLoading(false);
    }
  }

  async function saveAndPublish() {
    setIsSaving(true);
    setSaveError(null);
    try {
      const report = reportFormStore.toReport(isNew ? null : id);
      await saveReport(report);
      navigate('/reports');
    } catch (err) {
      setSaveError(err + '');
      setIsSaving(false);
    }
  }

  return (
    <div>
      <Box display="flex" justifyContent="space-between" paddingBottom={4}>
        <Typography variant="h1">{isNew ? 'New' : 'Edit'} Report</Typography>
      </Box>

      <ErrorMessage error={loadError} />
      <ErrorMessage error={saveError} />

      {(isLoading || isSaving) && <LoadingProgress />}

      {!isLoading && !loadError && !isSaving && (
        <Paper>
          <Box display="flex">
            <StepNav
              steps={steps}
              currentId={step + ''}
              onClickStepId={(id) => goToStep(parseInt(id) || 0)}
            />

            <Box flex={1} padding={3} overflow="hidden">
              <Box minHeight={430}>
                {(function () {
                  switch (step) {
                    case 1:
                      return <Step1 />;
                    case 2:
                      return <Step2 />;
                    case 3:
                      return <Step3 />;
                    case 4:
                      return <Step4 availableContacts={availableContacts} />;
                    case 5:
                      return (
                        <Step5
                          availableContacts={availableContacts}
                          saveAndPublish={saveAndPublish}
                        />
                      );
                  }
                  return null;
                })()}
              </Box>

              {step < 5 ? (
                <Box
                  marginTop={3}
                  onClick={() => reportFormStore.startValidation()}
                  display="flex"
                  justifyContent="space-between"
                >
                  <Button
                    variant="contained"
                    color="primary"
                    size="large"
                    onClick={(e) => goToStep(step + 1)}
                    disabled={step === maxStep}
                  >
                    Next
                  </Button>

                  {step === 3 && (
                    <DownloadDynamicReportButton
                      req={{
                        name: values.name,
                        datasetId: values.datasetId || '',
                        fields: values.fields,
                        filters: values.filters,
                        fieldAliases: values.fieldAliases,
                        timezone: values.timezone,
                      }}
                      disabled={!!validation.filters}
                    />
                  )}
                </Box>
              ) : null}
            </Box>
          </Box>
        </Paper>
      )}
      <br />
      <br />
      <br />
    </div>
  );

  function goToStep(step: number) {
    navigate(`/reports/${id}/${step}`);
  }
};
