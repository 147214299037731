import {
  Box,
  Button,
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  Typography,
} from '@material-ui/core';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import DeleteIcon from '@material-ui/icons/Close';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import { navigate, RouteComponentProps } from '@reach/router';
import { gqlClient } from 'api/ApolloClient';
import {
  GqlUploadPoFileMutationMutation,
  GqlUploadPoFileMutationMutationVariables,
  PermissionCode,
} from 'api/GQL_Types';
import { UploadPoFileMutation } from 'api/queries/poUploadQueries';
import { auth } from 'app';
import ErrorMessage from 'components/ErrorMessage';
import FakeProgressBar from 'components/FakeProgressBar';
import Panel from 'components/Panel';
import UniversalDialog from 'components/UniversalDialog';
import { useAsyncAction } from 'lib/useAsyncAction';
import React from 'react';
import { useDropzone } from 'react-dropzone';
import styled from 'styled-components';

const getColor = (props: any) => {
  if (props.isDragAccept) {
    return '#00e676';
  }
  if (props.isDragReject) {
    return '#ff1744';
  }
  if (props.isDragActive) {
    return '#2196f3';
  }
  return '#ccc';
};

const Container = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  border-width: 2px;
  border-radius: 2px;
  border-color: ${(props) => getColor(props)};
  border-style: dashed;
  background-color: #fafafa;
  color: #bdbdbd;
  outline: none;
  transition: border 0.24s ease-in-out;
  cursor: pointer;
  user-select: none;
`;

interface Props extends RouteComponentProps {}

async function uploadFile(variables: GqlUploadPoFileMutationMutationVariables): Promise<string> {
  const res = await gqlClient.mutate<
    GqlUploadPoFileMutationMutation,
    GqlUploadPoFileMutationMutationVariables
  >({
    mutation: UploadPoFileMutation,
    variables,
  });
  if (!res.data) {
    throw new Error('Upload failed');
  }
  return res.data.uploadPoFile.id;
}

interface NetworkOption {
  id: string;
  name: string;
  controllingClient: {
    id: string;
    name: string;
  };
}

export default function UploadPOPage(props: Props) {
  const { userContext } = auth.useAuthState();

  const actionUploadFile = useAsyncAction(uploadFile);
  const [uploadedPoFileId, setUploadedPoFileId] = React.useState<string | null>(null);

  const [networks, setNetworks] = React.useState<NetworkOption[]>([]);
  const [selectedNetwork, setSelectedNetwork] = React.useState<NetworkOption | null>(null);

  const [selectedFile, setSelectedFile] = React.useState<File | null>(null);

  React.useEffect(() => {
    const networks: NetworkOption[] = [];
    for (const contact of userContext?.user?.profileContacts || []) {
      const permissionCodes = contact.role?.permissionCodes || [];
      if (!permissionCodes.includes(PermissionCode.PoImportUpload)) {
        continue;
      }
      networks.push({
        id: contact.profile.network.id,
        name: contact.profile.network.name,
        controllingClient: {
          id: contact.profile.network.controllingClient.id,
          name: contact.profile.network.controllingClient.name,
        },
      });
    }
    setNetworks(networks);
    setSelectedNetwork(networks.length > 0 ? networks[0] : null);
  }, [userContext?.user]);

  const {
    getRootProps,
    getInputProps,
    isDragActive,
    isDragAccept,
    isDragReject,
    open,
    acceptedFiles,
  } = useDropzone({
    accept: '.xlsx, .xls, .csv',
    noClick: true,
    noKeyboard: true,
    multiple: false,
  });

  React.useEffect(() => {
    setSelectedFile(acceptedFiles.length > 0 ? acceptedFiles[0] : null);
  }, [acceptedFiles]);

  return (
    <Panel title="Upload Purchase Orders">
      <Box padding={2}>
        <Box margin={3} padding={2}>
          <Typography variant="h3">Select Controlling Client</Typography>

          <Box width={'50%'} style={{ margin: '16px 16px 16px 0' }}>
            <FormControl variant="outlined" fullWidth>
              <InputLabel
                shrink // See #OMS-54
              >
                Network
              </InputLabel>
              <Select
                autoWidth
                fullWidth
                label="Network"
                value={selectedNetwork ? selectedNetwork.id : '-none-'}
                onChange={(e) => {
                  for (const company of networks) {
                    if (company.id === e.target.value) {
                      setSelectedNetwork(company);
                      break;
                    }
                  }
                }}
              >
                {networks.map((network, index) => {
                  return (
                    <MenuItem key={index} value={network.id}>
                      {network.name}: {network.controllingClient.name}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
          </Box>
        </Box>

        <Box margin={3} padding={2}>
          {selectedFile ? (
            <Box width={'50%'}>
              <Typography variant="h3" style={{ marginBottom: '8px' }}>
                Select File
              </Typography>
              <Grid
                container
                key={selectedFile.name}
                direction="row"
                justify="space-between"
                alignItems="center"
              >
                <Grid item>
                  <Typography variant="h4">{selectedFile.name}</Typography>
                </Grid>
                <Grid item>
                  <IconButton aria-label="delete" onClick={() => setSelectedFile(null)}>
                    <DeleteIcon />
                  </IconButton>
                </Grid>
              </Grid>
            </Box>
          ) : (
            <Box width={'50%'}>
              <Container
                {...getRootProps({ isDragActive, isDragAccept, isDragReject })}
                onClick={open}
              >
                <input {...getInputProps()} />
                <CloudUploadIcon style={{ fontSize: 50, color: '#0288d1' }} />
                <Box fontSize="34px" fontWeight={300}>
                  Drag file to upload
                </Box>
                <Box margin={1} fontSize="24px" display="flex" width="50%">
                  <hr
                    style={{
                      borderColor: '#EDEDED',
                      width: '33%',
                      height: 0,
                      alignSelf: 'center',
                    }}
                  />
                  or
                  <hr
                    style={{
                      borderColor: '#EDEDED',
                      width: '33%',
                      height: 0,
                      alignSelf: 'center',
                    }}
                  />
                </Box>
                <Button variant="contained" color="secondary" size="large">
                  <Typography variant="h4" color="textSecondary">
                    Browse
                  </Typography>
                </Button>
              </Container>
            </Box>
          )}

          <Box marginTop={5}>
            <hr style={{ border: '0.5px solid #ededed' }} />
            <Box display="flex" justifyContent="flex-end">
              <Button
                variant="contained"
                color="primary"
                size="large"
                disabled={!selectedFile}
                onClick={() => {
                  if (selectedFile && selectedNetwork) {
                    actionUploadFile
                      .act({
                        file: selectedFile,
                        networkId: selectedNetwork.id,
                      })
                      .then((res) => {
                        setUploadedPoFileId(res.type === 'data' ? res.data : null);
                      });
                  }
                }}
              >
                <Typography variant="h3" color="textSecondary">
                  upload
                </Typography>
              </Button>
            </Box>
          </Box>
        </Box>
      </Box>
      <UniversalDialog
        open={actionUploadFile.called}
        small
        setClose={() => {
          actionUploadFile.reset();
          setUploadedPoFileId(null);
        }}
      >
        <Box padding={3} textAlign="center">
          {actionUploadFile.finished ? (
            <>
              <Box display="flex" justifyContent="center">
                <CheckCircleIcon style={{ fontSize: 90, color: '#009600' }} />
              </Box>
              <Typography variant="h1">UPLOAD COMPLETE</Typography>
              <Typography variant="body1">File is now available to review</Typography>
              <Grid
                container
                spacing={3}
                direction="row"
                justify="center"
                alignItems="center"
                style={{ marginTop: '24px' }}
              >
                <Grid item xs={6}>
                  <Button
                    variant="contained"
                    color="secondary"
                    fullWidth
                    onClick={() => {
                      actionUploadFile.reset();
                      setUploadedPoFileId(null);
                      setSelectedFile(null);
                    }}
                  >
                    New Upload
                  </Button>
                </Grid>
                <Grid item xs={6}>
                  <Button
                    onClick={() => {
                      if (uploadedPoFileId) {
                        navigate('/purchase-orders/uploads/' + uploadedPoFileId);
                      }
                    }}
                    variant="contained"
                    color="primary"
                    fullWidth
                  >
                    Review Now
                  </Button>
                </Grid>
              </Grid>
            </>
          ) : actionUploadFile.error ? (
            <div>
              <Box marginBottom={3}>
                <ErrorMessage error={actionUploadFile.error} />
              </Box>
              <Button
                variant="contained"
                onClick={() => {
                  actionUploadFile.reset();
                  setUploadedPoFileId(null);
                }}
              >
                Close
              </Button>
            </div>
          ) : (
            <>
              <Box marginBottom={3}>
                <Typography variant="h1">UPLOADING...</Typography>
              </Box>
              <Box marginBottom={2}>
                <FakeProgressBar
                  start={true}
                  finished={actionUploadFile.waiting}
                  onFinished={() => {}}
                />
              </Box>
              <Typography variant="body1">Upload time depends on file size.</Typography>
            </>
          )}
        </Box>
      </UniversalDialog>
    </Panel>
  );
}
