import UniversalPopover from 'components/UniversalPopover';
import React from 'react';
import BookingsPopoverContent from '../dashboard-bookings-popovers/BookingsPopoverContent';
import ContainersPopoverContent from '../dashboard-bookings-popovers/ContainersPopoverContent';
import InvoicePopoverContent from '../dashboard-bookings-popovers/InvoicePopoverContent';
import SkuLineItemPopoverContent from '../dashboard-bookings-popovers/SkuLineItemPopoverContent';

interface Props {
  open: boolean;
  anchorEl: any;
  onClose: Function;
  data: any;
}

export default function BookingsDetailsPopover(props: Props) {
  return (
    <UniversalPopover
      popoverId="booking-details-popover"
      open={props.open}
      anchorEl={props.anchorEl}
      onClose={() => {
        props.onClose();
      }}
      anchorOriginVertical={20}
      anchorOriginHorizontal={0}
    >
      {props.data.type === 'containers' && (
        <ContainersPopoverContent popoverLabel={props.data.popoverLabel} data={props.data.data} />
      )}
      {props.data.type === 'sku/lineItems' && (
        <SkuLineItemPopoverContent popoverLabel={props.data.popoverLabel} data={props.data.data} />
      )}
      {props.data.type === 'bookings' && (
        <BookingsPopoverContent popoverLabel={props.data.popoverLabel} data={props.data.data} />
      )}
      {props.data.type === 'invoice' && (
        <InvoicePopoverContent popoverLabel={props.data.popoverLabel} data={props.data.data} />
      )}
    </UniversalPopover>
  );
}
