import { Box, Button, Container } from '@material-ui/core';
import { useUpdateContactMutation, useUpdateUserMutation } from 'api/GQL_Types';
import { UserQuery } from 'api/queries/userQueries';
import AtomicSelectorV2 from 'components/atomic/AtomicSelectorV2';
import AtomicSwitchV2 from 'components/atomic/AtomicSwitchV2';
import { FormItem } from 'components/form/FormItem';
import { FormRow } from 'components/form/FormRow';
import { Line } from 'components/StyledComponents';
import UniversalDialog from 'components/UniversalDialog';
import { useSnackbar } from 'notistack';
import React from 'react';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import { newAtom } from 'recoil-utils/utils';
import { theme } from 'styles';
import { ManageUserPageStates, UserProfileContact } from '../states';

export const UserProfileDialog_Data = newAtom<UserProfileContact | null>(null);
const UserProfileRoleState = newAtom({ id: '', name: '' });
const UserProfileIsUserEnabledState = newAtom(false);
const UserProfileIsDefaultContactState = newAtom(false);

export default function UserProfileDialog() {
  const [userProfile, setUserProfile] = useRecoilState(UserProfileDialog_Data);
  const setUserProfileRoleState = useSetRecoilState(UserProfileRoleState);
  const setUserProfileIsUserEnabledState = useSetRecoilState(UserProfileIsUserEnabledState);
  const setUserProfileIsDefaultContactState = useSetRecoilState(UserProfileIsDefaultContactState);

  React.useEffect(() => {
    setUserProfileRoleState({
      id: userProfile?.role?.id || '',
      name: userProfile?.role?.name || '',
    });
    setUserProfileIsUserEnabledState(!!userProfile?.isUserEnabled);
    setUserProfileIsDefaultContactState(!!userProfile?.isMainContact);
  }, [userProfile]);

  return (
    <UniversalDialog
      open={!!userProfile}
      title="Update User Profile"
      small
      setClose={() => {
        setUserProfile(null);
      }}
    >
      <Line color={theme.palette.primary.main} height={2} />
      <Container>
        <Box marginBottom={2}>
          <FormRow>
            <AtomicSelectorV2<any>
              label="Role"
              state={UserProfileRoleState}
              optionsList={userProfile?.profile?.roles ?? []}
              controllingField="id"
              displayField="name"
            />
          </FormRow>
          <FormRow>
            <FormItem>
              <AtomicSwitchV2 state={UserProfileIsUserEnabledState} label="Is User Active" />
            </FormItem>
            <FormItem>
              <AtomicSwitchV2 state={UserProfileIsDefaultContactState} label="Is Default Contact" />
            </FormItem>
          </FormRow>
        </Box>
      </Container>
      <Line height={1} />
      <DialogFooter />
    </UniversalDialog>
  );
}

function DialogFooter() {
  const [userProfile, setUserProfile] = useRecoilState(UserProfileDialog_Data);
  const user = useRecoilValue(ManageUserPageStates.user);
  const userProfileRole = useRecoilValue(UserProfileRoleState);
  const isUserEnabled = useRecoilValue(UserProfileIsUserEnabledState);
  const isDefaultContact = useRecoilValue(UserProfileIsDefaultContactState);
  const { enqueueSnackbar } = useSnackbar();

  const [updateUserContact, { data: userContactData }] = useUpdateContactMutation({
    refetchQueries: () => [
      {
        query: UserQuery,
        variables: { userId: user?.id ?? '' },
        fetchPolicy: 'cache-and-network',
      },
    ],
  });

  const [updateUser, { data: userData }] = useUpdateUserMutation({
    refetchQueries: () => [
      {
        query: UserQuery,
        variables: { userId: user?.id ?? '' },
        fetchPolicy: 'cache-and-network',
      },
    ],
  });

  React.useEffect(() => {
    if (userContactData || userData) {
      if (userContactData?.updateContact.success || userData?.updateUser.success) {
        setUserProfile(null);
        enqueueSnackbar('User Profile Updated.', {
          variant: 'success',
          anchorOrigin: {
            vertical: 'top',
            horizontal: 'right',
          },
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userContactData, userData]);

  return (
    <Box display="flex" alignItems="center" justifyContent="flex-end">
      <Button
        variant="contained"
        color="default"
        size="large"
        onClick={() => setUserProfile(null)}
        style={{ height: '40px', marginRight: '12px' }}
      >
        Cancel
      </Button>
      <Button
        variant="contained"
        color="primary"
        size="large"
        onClick={() => {
          if (!userProfile) {
            return;
          }
          if (!userProfile.role) {
            return;
          }
          if (
            userProfileRole.id !== userProfile.role.id ||
            isUserEnabled !== userProfile.isUserEnabled
          ) {
            updateUserContact({
              variables: {
                input: {
                  contact: {
                    id: userProfile.id,
                    firstName: userProfile.firstName,
                    lastName: userProfile.lastName,
                    title: userProfile.title,
                    email: userProfile.email,
                    phoneMain: userProfile.phoneMain,
                    extension: userProfile.extension,
                    phoneCell: userProfile.phoneCell,
                    phoneAlternative: userProfile.phoneAlternative,
                    isMainContact: userProfile.isMainContact,
                    isUserEnabled: isUserEnabled,
                    locationId: userProfile.location?.id,
                    roleId: userProfileRole.id,
                  },
                },
              },
            });
          }
          if (isDefaultContact !== userProfile.isMainContact && user) {
            updateUser({
              variables: {
                input: {
                  user: {
                    id: user.id,
                    firstName: user.firstName,
                    lastName: user.lastName,
                    title: user.title,
                    email: user.email,
                    phoneMain: user.phoneMain,
                    extension: user.extension,
                    phoneCell: user.phoneCell,
                    phoneAlternative: user.phoneAlternative,
                    isActive: user.isActive,
                    defaultContactId: userProfile.id,
                  },
                },
              },
            });
          }
        }}
        style={{ height: '40px', marginRight: '12px' }}
      >
        Update User Profile
      </Button>
    </Box>
  );
}
