
import React from 'react';
import { Box, Button, Chip, Link, Tooltip, Typography, Divider } from '@material-ui/core';
import LocationIcon from '@material-ui/icons/Place';
import WarningIcon from '@material-ui/icons/Warning';
import { LocationType } from 'api/GQL_Types';
import { useLocationTypeNameLookup } from 'app/states';
import { ListCard } from 'components/ListCard';
import { theme } from 'styles';
import { getAccentColorFromUUID } from 'utils/Colors';
import { ProfilePageLocation } from '../states';

interface Props {
  // Location to display
  location: ProfilePageLocation;
  // should it show the "manage" button?
  canManage: boolean;
  // Callback for when manage button is clicked
  onManageClicked: (location: ProfilePageLocation) => void;
}

export default function LocationCard(props: Props) {
  const locationTypeNameLookup = useLocationTypeNameLookup();

  return (
    <ListCard accentColor={getAccentColorFromUUID(props.location.id)}>
      <Box height={340} display="flex" flexDirection="column" justifyContent="space-between">
        <Box display="flex" alignItems="center" justifyContent="space-between" padding={1}>
          {props.location.needsUpdate && (
            <Tooltip
              title={
                <Typography color="textSecondary">
                  Location was auto generated. Click manage button to merge with existing location
                  or make it a new location.
                </Typography>
              }
              interactive
            >
              <WarningIcon style={{ color: theme.palette.warning.main }} />
            </Tooltip>
          )}
          <Typography variant="h3">{props.location.name}</Typography>
          <Typography variant="caption">[{props.location.code}]</Typography>
          {Boolean(
            props.location.locationTypes.find((locType) => locType === LocationType.Primary)
          ) && (
            <Box className="material-icons" color={theme.palette.warning.main}>
              grade
            </Box>
          )}
        </Box>

        <Box>
          {props.location.locationTypes.length > 0 ? (
            props.location.locationTypes.map((locType) => {
              return (
                <Chip
                  color="secondary"
                  label={locationTypeNameLookup(locType)}
                  key={locType}
                  size="small"
                  variant="outlined"
                  icon={<LocationIcon />}
                  style={{ marginLeft: '8px', marginRight: '8px' }}
                />
              );
            })
          ) : (
            <NoteLabel>No Location Tags</NoteLabel>
          )}
        </Box>
        <Box flexGrow={1}>
          <Box marginTop={1}>
            {props.location.addressLine1 &&
            props.location.city &&
            props.location.state &&
            props.location.postalCode ? (
              <>
                <Divider />

                <LabelValue
                  label="Address"
                  value={`${props.location.addressLine1 ? props.location.addressLine1 + '\n' : ''}${
                    props.location.addressLine2 ? props.location.addressLine2 + '\n' : ''
                  }${props.location.city}, ${props.location.state} ${props.location.postalCode}`}
                />
              </>
            ) : (
              <NoteLabel>Missing Address Info</NoteLabel>
            )}

            {props.location.country ? (
              <LabelValue label="Country" value={props.location.country} />
            ) : (
              <NoteLabel>Missing Country</NoteLabel>
            )}
          </Box>
          {props.location.companyPhone ? (
            <LabelValue label="Phone" value={props.location.companyPhone} />
          ) : (
            <NoteLabel>Missing Phone Number</NoteLabel>
          )}
          {props.location.companyUrl ? (
            <LabelValue label="Web" value={props.location.companyUrl} link />
          ) : (
            <NoteLabel>Missing Company URL</NoteLabel>
          )}
        </Box>

        {props.canManage && (
          <Box textAlign="center" margin={2}>
            <Button
              variant="contained"
              style={{ width: '50%' }}
              color="secondary"
              onClick={() => {
                props.onManageClicked(props.location);
              }}
            >
              Manage
            </Button>
          </Box>
        )}
      </Box>
    </ListCard>
  );
}

interface LabelValueProps {
  label: string;
  value: any;
  link?: boolean;
}
function LabelValue(props: LabelValueProps) {
  const preventDefault = (event: React.SyntheticEvent) => event.preventDefault();
  return (
    <>
      <Box display="flex" justifyContent="space-between" margin={1} alignItems="center">
        <Typography variant="h4">{props.label}:</Typography>
        {props.link ? (
          <Link href="#" onClick={preventDefault}>
            {props.value}
          </Link>
        ) : (
          <Box
            marginLeft={1}
            textAlign="right"
            overflow="hidden"
            textOverflow="ellipsis"
            whiteSpace="pre-wrap"
          >
            {props.value}
          </Box>
        )}
      </Box>
      <Divider />
    </>
  );
}

interface NoteLabelProps {
  children: any;
}

function NoteLabel(props: NoteLabelProps) {
  return (
    <Box
      component="div"
      fontWeight="200"
      fontStyle="italic"
      color={theme.palette.grayscale.dark}
      padding={1}
    >
      {props.children}
    </Box>
  );
}
