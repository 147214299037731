import { Box, Grid } from '@material-ui/core';
import { RouteComponentProps } from '@reach/router';
import { LocationType } from 'api/GQL_Types';
import { useLocationTypeNameLookup } from 'app/states';
import LineItem from 'components/LineItem';
import SectionTitle from 'components/SectionTitle';
import React from 'react';
import { useRecoilValue } from 'recoil';
import { newAtom } from 'recoil-utils/utils';
import { NetworkAdminPageStates } from '../states';

export const openAddProfileDialogAtom = newAtom(false);

interface Props extends RouteComponentProps {}
export default function NetworkDetails(props: Props) {
  const network = useRecoilValue(NetworkAdminPageStates.network);
  const controllingClient = network?.connectedProfiles.find((client) => client.isControllingClient);

  const locationTypeNameLookup = useLocationTypeNameLookup();

  const controllingLocation = controllingClient?.locations?.find((l) =>
    l.locationTypes.find((t) => t === LocationType.Primary)
  );

  const controllingContact =
    controllingClient && controllingClient.contacts && controllingClient.contacts[0];

  return (
    <>
      <SectionTitle title="Controlling Client" />
      <Box padding={1}>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={6}>
            <LineItem value={controllingClient?.name || ''} label="Profile" />
          </Grid>
          <Grid item xs={12} sm={6}>
            <LineItem value={controllingClient?.profileType?.name || ''} label="Profile Type" />
          </Grid>
        </Grid>
      </Box>
      <SectionTitle title="Main Location" />
      <Box padding={1}>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={6}>
            <LineItem value={controllingLocation?.name} label="Location Name" />
            <LineItem value={controllingLocation?.addressLine1} label="Address 1" />
            <LineItem value={controllingLocation?.city} label="City" />
            <LineItem value={controllingLocation?.postalCode} label="Postal Code" />
            <LineItem
              value={controllingLocation?.locationTypes.map(locationTypeNameLookup).join(', ')}
              label="Location Types"
            />
            <LineItem value={controllingLocation?.code} label="Location Code" />
          </Grid>
          <Grid item xs={12} sm={6}>
            <LineItem value={controllingLocation?.relatedPort.code} label="UNLOCO" />
            <LineItem value={controllingLocation?.addressLine2} label="Address 2" />
            <LineItem value={controllingLocation?.state} label="State" />
            <LineItem value={controllingLocation?.country} label="Country" />
            <LineItem value={controllingLocation?.companyPhone} label="Company Phone" />
            <LineItem value={controllingLocation?.companyUrl} label="Company URL" />
          </Grid>
        </Grid>
      </Box>
      <SectionTitle title="Main Contact" />
      <Box padding={1}>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={6}>
            <LineItem value={controllingContact?.firstName} label="First Name" />
            <LineItem value={controllingContact?.title} label="Title" />
            <LineItem value={controllingContact?.phoneMain} label="Phone (Main)" />
            <LineItem value={controllingContact?.phoneAlternative} label="Phone (Alt)" />
          </Grid>
          <Grid item xs={12} sm={6}>
            <LineItem value={controllingContact?.lastName} label="Last Name" />
            <LineItem value={controllingContact?.email} label="Email" />
            <LineItem value={controllingContact?.extension} label="Extension" />
            <LineItem value={controllingContact?.phoneCell} label="Phone (Cell)" />
          </Grid>
        </Grid>
      </Box>
    </>
  );
}
