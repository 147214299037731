import { Box } from '@material-ui/core';
import { UWLTable } from 'components/UWLTable/UWLTable';
import React from 'react';
import { useRecoilValue } from 'recoil';
import { theme } from 'styles';
import { UWLTableColumn } from 'types/UWLTable';
import { NewBookingPageStates, OpenOrderLine } from '../states';

const columns: UWLTableColumn<OpenOrderLine>[] = [
  { id: 'orderNumber', label: 'PO', type: 'string', whiteSpace: 'nowrap' },
  { id: 'itemNumber', label: 'Item/SKU', type: 'string', whiteSpace: 'nowrap' },
  { id: 'lineNumber', label: 'Line', type: 'string', whiteSpace: 'nowrap' },
  { id: 'balanceQty', label: 'Balance Qty', type: 'number', align: 'left' },
  { id: 'orderQty', label: 'Ordered Qty', type: 'number', align: 'left' },
  { id: 'shippedQty', label: 'Shipped Qty', type: 'number', align: 'left' },
  { id: 'shippedCartons', label: 'Shipped Ctns', type: 'number', align: 'left' },
  { id: 'volume', label: 'Volume', type: 'number', align: 'left' },
  { id: 'weight', label: 'Weight', type: 'number', align: 'left' },
  { id: 'expectedCargoReadyDate', label: 'Exp Cgo Rdy Date', type: 'date' },
  { id: 'revisedCargoReadyDate', label: 'Rev Cgo Rdy Date', type: 'date' },
  { id: 'firstShipDate', label: 'First Ship', type: 'date' },
  { id: 'lastShipDate', label: 'Last Ship', type: 'date' },
  { id: 'shipToLocationName', label: 'Ship to Loc', type: 'string', whiteSpace: 'nowrap' },
];

interface Props {}

export default function BookingLineItemsTables(props: Props) {
  const packLines = useRecoilValue(NewBookingPageStates.step2.packLines);

  return (
    <Box height="100%" width="100%" paddingX={1} paddingBottom={1}>
      <UWLTable
        rowId="id"
        columns={columns}
        rows={packLines}
        emptyMessage="- No Line Items -"
        renderCell={{
          orderNumber(row) {
            return <div style={{ marginRight: theme.spacing(2) }}>{row.orderNumber}</div>;
          },
        }}
      />
    </Box>
  );
}
