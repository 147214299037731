import React from 'react';
import { Button } from '@material-ui/core';
import { BookingQuery } from 'api/queries/bookingQueries';
import { BookingPageStates } from '../../states';
import { useRecoilValue } from 'recoil';
import { useUpdateBookingMutation } from 'api/GQL_Types';
import { useSnackbar } from 'notistack';

interface Props {
  onClick: Function;
}

export default function EditBookingSaveButton(props: Props) {
  const { enqueueSnackbar } = useSnackbar();
  const booking = useRecoilValue(BookingPageStates.booking);
  const incoTerm = useRecoilValue(BookingPageStates.editBooking.incoTerm);
  const revisedCargoReadyDate = useRecoilValue(BookingPageStates.editBooking.revisedCargoReadyDate);

  const [updateBooking] = useUpdateBookingMutation({
    onCompleted: (data) => {
      if (data.updateBooking.success) {
        enqueueSnackbar('Booking Updated', {
          variant: 'success',
          anchorOrigin: {
            vertical: 'top',
            horizontal: 'right',
          },
        });
      }
    },
    refetchQueries: () => [
      {
        query: BookingQuery,
        variables: { bookingId: booking?.id ?? '' },
        fetchPolicy: 'cache-and-network',
      },
    ],
  });

  return (
    <Button
      variant="contained"
      color="primary"
      onClick={() => {
        updateBooking({
          variables: {
            input: {
              booking: {
                id: booking?.id,
                incoTerm: incoTerm,
                cargoReadyDate: revisedCargoReadyDate,
              },
            },
          },
        });

        props.onClick();
      }}
    >
      Save
    </Button>
  );
}
