import { faFire } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Box, Button, Typography } from '@material-ui/core';
import { GqlContainer, PermissionCode } from 'api/GQL_Types';
import { userContextAtom } from 'app';
import Panel from 'components/Panel';
import { Line } from 'components/StyledComponents';
import { UWLTable } from 'components/UWLTable/UWLTable';
import React from 'react';
import { useRecoilValue } from 'recoil';
import { UWLTableColumn } from 'types/UWLTable';
import { formatNumberDecimalPlaces } from 'utils/Numbers';
import { ConsolidationPageStates, Containers } from '../states';
import EquipmentAssignmentDialog from './equipment-assignment-dialog';

const columns: UWLTableColumn<Containers>[] = [
  { id: 'isHot', label: '', type: 'string', whiteSpace: 'nowrap' },
  { id: 'containerNumber', label: 'Container', type: 'link', whiteSpace: 'nowrap' },
  { id: 'containerType', label: 'Container Type', type: 'string', whiteSpace: 'nowrap' },
  { id: 'sealNumber', label: 'Seal', type: 'string', whiteSpace: 'nowrap' },
  { id: 'volume', label: 'Volume', type: 'number', align: 'left' },
  { id: 'weight', label: 'Weight', type: 'number', align: 'left' },
  { id: 'terminalReceivedDate', label: "Terminal Rec'd", type: 'date' },
];
const columnsAlt: UWLTableColumn<Containers>[] = [
  { id: 'containerNumber', label: 'Container', type: 'link', whiteSpace: 'nowrap' },
  { id: 'containerType', label: 'Container Type', type: 'string', whiteSpace: 'nowrap' },
  { id: 'sealNumber', label: 'Seal', type: 'string', whiteSpace: 'nowrap' },
  { id: 'volume', label: 'Volume', type: 'number', align: 'left' },
  { id: 'weight', label: 'Weight', type: 'number', align: 'left' },
  { id: 'terminalReceivedDate', label: "Terminal Rec'd", type: 'date' },
];

interface Props {
  consolidationId: string;
}
export default function ConsolidationEquipment(props: Props) {
  const userContext = useRecoilValue(userContextAtom);
  const [open, setOpen] = React.useState(false);
  const consolidation = useRecoilValue(ConsolidationPageStates.consolidation);
  const [containers, setContainers] = React.useState<Containers[]>([]);

  React.useEffect(() => {
    if (consolidation) {
      setContainers(
        consolidation.containers.map((container): Containers => {
          return {
            id: container.id,
            isHot: container.isHot,
            containerNumber: {
              to: '/equipment/' + container.id,
              value: container.containerNumber ?? '',
            },
            containerType: container.containerType,
            sealNumber: container.sealNumber ?? '',
            volume: container.volume ?? 0,
            weight: container.weight ?? 0,
            terminalReceivedDate: container.terminalReceivedDate,
          };
        })
      );
    }
  }, [consolidation]);

  return (
    <>
      <Panel
        title="Container/Equipment"
        topRight={
          userContext?.permissionCodes.has(PermissionCode.ConsolidationContainerUpdate) && (
            <Button
              variant="contained"
              color="secondary"
              onClick={() => {
                setOpen(true);
              }}
            >
              Edit
            </Button>
          )
        }
      >
        <Line height={1} />
        <Box height="100%" paddingX={2} paddingBottom={2}>
          <UWLTable
            rowId="id"
            columns={hasHotContainers(consolidation?.containers ?? []) ? columns : columnsAlt}
            rows={containers}
            emptyMessage="- No Containers -"
            virtualize={{
              bufferHeight: 400,
              rowHeight(row) {
                return 24;
              },
            }}
            renderCell={{
              isHot(row) {
                return row.isHot ? (
                  <FontAwesomeIcon icon={faFire} style={{ color: 'orange' }} size="lg" />
                ) : null;
              },
              volume(row) {
                return (
                  <>
                    {row.volume ? (
                      <Typography variant="body1">
                        {formatNumberDecimalPlaces(row.volume, 0, 2)}
                        <Box
                          component="span"
                          fontWeight="caption.fontWeight"
                          fontStyle="italic"
                          marginLeft={1}
                        >
                          M3
                        </Box>
                      </Typography>
                    ) : (
                      <Box>0</Box>
                    )}
                  </>
                );
              },
              weight(row) {
                return (
                  <>
                    {row.weight ? (
                      <Typography variant="body1">
                        {formatNumberDecimalPlaces(row.weight, 0, 2)}
                        <Box
                          component="span"
                          fontWeight="caption.fontWeight"
                          fontStyle="italic"
                          marginLeft={1}
                        >
                          KG
                        </Box>
                      </Typography>
                    ) : (
                      <Box>0</Box>
                    )}
                  </>
                );
              },
            }}
          />
        </Box>
      </Panel>
      <EquipmentAssignmentDialog
        open={open}
        onClose={() => {
          setOpen(false);
        }}
      />
    </>
  );
}

function hasHotContainers(containers: GqlContainer[]) {
  return Boolean(containers.find((container) => container.isHot));
}
