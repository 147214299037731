import { Button } from '@material-ui/core';
import { useDocumentsLazyQuery } from 'api/GQL_Types';
import { calculateDateRange } from 'components/form/FormInputDateRange';
import React from 'react';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import { portToStringMaybe } from 'types/Port';
import { DocumentBrowserRow, DocumentBrowserStates } from '../states';

export default function ApplyFiltersButton() {
  const setDocuments = useSetRecoilState(DocumentBrowserStates.documents);
  const setLoading = useSetRecoilState(DocumentBrowserStates.loading);
  const setError = useSetRecoilState(DocumentBrowserStates.error);
  const dateRange = useRecoilValue(DocumentBrowserStates.dateRange);
  const documentEventCode = useRecoilValue(DocumentBrowserStates.dateField);
  const documentTypeCode = useRecoilValue(DocumentBrowserStates.documentType);
  const shipper = useRecoilValue(DocumentBrowserStates.shipper);
  const deliveryLocation = useRecoilValue(DocumentBrowserStates.destination);
  const selectedPo = useRecoilValue(DocumentBrowserStates.poNumber);
  const selectedItem = useRecoilValue(DocumentBrowserStates.itemNumber);
  const [onlyNew, setOnlyNew] = useRecoilState(DocumentBrowserStates.newDocuments);

  const [gotInitialResponse, setGotInitialResponse] = React.useState<boolean>(false);

  const [filterDocs] = useDocumentsLazyQuery({
    fetchPolicy: 'no-cache',
    onCompleted(data) {
      setDocuments(
        data.documentSearch.map((row): DocumentBrowserRow => {
          return {
            id: row.id,
            bookingId: row.bookingId ?? null,
            bookingNumber: row.bookingNumber ?? null,
            importFileName: row.importFileName ?? '',
            fileType: row.fileType ?? '',
            documentType: row.documentType,
            uploadDate: row.uploadDate,
            shipperName: row.shipperName ?? null,
            hblId: row.hblId ?? null,
            hblNumber: row.hblNumber ?? null,
            pol: portToStringMaybe(row.pol),
            polEtd: row.polEtd,
            polAtd: row.polAtd,
            pod: portToStringMaybe(row.pod),
            podEta: row.podEta,
            deliveryLocation: row.deliveryLocation ?? null,
          };
        })
      );
      setError(null);
      setLoading(false);
      setGotInitialResponse(true);

      if (!gotInitialResponse && data.documentSearch.length === 0 && onlyNew) {
        // re-fetch if there are no new documents on the initial search - OMS-77
        applyFilters(true);
        setOnlyNew(false);
      }
    },
    onError(error) {
      setError(error + '');
      setLoading(false);
      setGotInitialResponse(true);
    },
  });

  function applyFilters(forceNotOnlyNew: boolean = false) {
    setDocuments([]);
    setLoading(true);
    setError(null);

    const range = dateRange ? calculateDateRange(dateRange) : null;

    filterDocs({
      variables: {
        filter: {
          dateFilter: range
            ? {
                documentEventCode,
                from: range.from,
                to: range.to,
              }
            : null,
          documentTypeCode,
          shipper,
          deliveryLocation,
          poId: selectedPo?.id,
          itemId: selectedItem?.id,
          onlyNew: forceNotOnlyNew ? false : onlyNew,
        },
      },
    });
  }

  React.useEffect(() => {
    applyFilters();
  }, []);

  return (
    <Button variant="contained" color="primary" onClick={() => applyFilters()}>
      Apply Filters
    </Button>
  );
}
