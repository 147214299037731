import { faBox } from '@fortawesome/free-solid-svg-icons';
import { markNotificationsAsRead } from 'api/queries/dashboardQueries';
import { AlertIconButton } from 'app/dashboard/components/AlertIconButton';
import { MarkAllAsReadButton } from 'app/dashboard/components/MarkAllAsReadButton';
import TableExportWindowGroup, { uwlColToGTable } from 'components/TableExportWindowGroup';
import { STANDARD_ROW_OVERALL_HEIGHT, UWLTable } from 'components/UWLTable/UWLTable';
import { useWindowSize } from 'lib/useWindowSize';
import React from 'react';
import { useRecoilState } from 'recoil';
import { UWLTableColumn } from 'types/UWLTable';
import { formatNumber } from 'utils/Numbers';
import { ExceptionQueryResult } from '.';
import { MarkAsReadCell } from '../../components/MarkAsReadCell';
import { PopoverTable } from '../../components/PopoverTable';
import { DashboardPageStates, TableLink } from '../../states';

export interface PendingOutgateException {
  id: string;
  notificationId: string | null;
  isUnread: boolean;
  containerNumber: TableLink;
  rampAta: Date | null | undefined;
  demurrageLastFreeDayDate: Date | null | undefined;
  deliveryEta: Date | null | undefined;
  destination: string;
}

export function PendingOutgateException_toRow(
  exception: ExceptionQueryResult
): PendingOutgateException | null {
  if (exception.__typename !== 'PendingOutgateException') {
    return null;
  }

  const container = exception.container;

  return {
    id: container.id,
    notificationId: exception.notificationId || null,
    isUnread: exception.notificationId ? !exception.markedAsRead : false,
    containerNumber: { to: '/equipment/' + container.id, value: container.containerNumber || '' },
    rampAta: container.rampAta,
    demurrageLastFreeDayDate: container.demurrageLastFreeDayDate,
    deliveryEta: container.deliveryEta,
    destination: container.shipment.logistics.finalDestination?.name || '',
  };
}

const columns: UWLTableColumn<PendingOutgateException>[] = [
  { id: 'isUnread', label: '!', type: 'string' },
  { id: 'containerNumber', label: 'Container #', type: 'link' },
  { id: 'rampAta', label: 'Ramp ATA', type: 'date' },
  { id: 'demurrageLastFreeDayDate', label: 'Demurrage LFD Date', type: 'date' },
  { id: 'deliveryEta', label: 'Final Destination ETA', type: 'date' },
  { id: 'destination', label: 'Destination', type: 'string' },
];

const displayColumnsUnread = columns.map((c) => c.id);
const displayColumnsAllRead = displayColumnsUnread.filter((id) => id !== 'isUnread');

const exportColumns = columns.filter((c) => c.id !== 'isUnread').map(uwlColToGTable);

interface Props {}

export const PendingOutgateExceptionCmpt: React.FC<Props> = (props) => {
  const [open, setOpen] = React.useState(false);
  const refAnchor = React.useRef<HTMLDivElement>(null);
  const windowSize = useWindowSize();

  const [exceptions, setExceptions] = useRecoilState(DashboardPageStates.exceptions);
  const rows = exceptions.pendingOutgate;
  const unreadCount = rows.filter((row) => row.isUnread).length;

  return (
    <>
      <AlertIconButton
        innerRef={refAnchor}
        name="Pending Outgate"
        unreadCount={unreadCount}
        resultCount={rows.length}
        faIcon={faBox}
        onClick={() => setOpen(true)}
      />

      <PopoverTable
        anchorEl={refAnchor.current}
        open={open}
        onClose={() => setOpen(false)}
        title="Pending Outgate"
        titleRight={
          <>
            <TableExportWindowGroup label="Pending Outgate" rows={rows} columns={exportColumns} />
            {unreadCount > 0 && (
              <MarkAllAsReadButton
                onClick={() => {
                  const unreadAlertIds: string[] = [];
                  for (const row of rows) {
                    if (row.isUnread && row.notificationId) {
                      unreadAlertIds.push(row.notificationId);
                    }
                  }
                  markAsRead(unreadAlertIds);
                }}
              />
            )}
          </>
        }
        totalLabel="Total Pending Outgate"
        totalValue={formatNumber(rows.length)}
        width={windowSize.width * 0.8}
        bodyHeight={rows.length * STANDARD_ROW_OVERALL_HEIGHT}
      >
        {open && (
          <UWLTable
            rowId={'id'}
            rows={rows}
            columns={columns}
            columnsDisplay={unreadCount > 0 ? displayColumnsUnread : displayColumnsAllRead}
            emptyMessage="No Pending Outgate"
            virtualize={'single-line-cells'}
            renderCell={{
              isUnread(row) {
                return (
                  <MarkAsReadCell
                    isUnread={row.isUnread}
                    notificationId={row.notificationId}
                    onClick={(notificationId) => {
                      markAsRead([notificationId]);
                    }}
                  />
                );
              },
            }}
          />
        )}
      </PopoverTable>
    </>
  );

  function markAsRead(notificationIds: string[]) {
    markNotificationsAsRead(notificationIds);
    setExceptions((e) => {
      return {
        ...e,
        pendingOutgate: e.pendingOutgate.map((d) => {
          if (notificationIds.includes(d.notificationId || '')) {
            return { ...d, isUnread: false };
          }
          return d;
        }),
      };
    });
  }
};
