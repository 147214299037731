import { Box, Button, Grid, Typography } from '@material-ui/core';
import { HotBookingSwitch } from 'app/components/HotBookingSwitch';
import { navigate, RouteComponentProps } from '@reach/router';
import {
  GqlProfile,
  PartyType,
  PermissionCode,
  useAssignBookingRelatedPartyMutation,
  useBookingLazyQuery,
  useBookingQuery,
  useNetworkProfilesQuery,
  useRemoveBookingRelatedPartyMutation,
  useUpdateBookingRelatedPartyMutation,
} from 'api/GQL_Types';
import { userContextAtom } from 'app';
import Loading from 'app/Loading';
import OverrideRelatedPartiesDialog from 'components/related-parties-override';
import { Remark, sortRemarks } from 'components/Remarks';
import { useSnackbar } from 'notistack';
import React from 'react';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import { newAtom } from 'recoil-utils/utils';
import BookingDetailsPanel from './booking-details';
import BookingDocuments from './documents';
import BookingEquipment from './equipment';
import BookingLineItemsPanel from './LineItems';
import LogisticDetailsPanel from './logistics';
import BookingRemarks from './remarks';
import { BookingPageData, BookingPageStates } from './states';
import { HotState } from 'components/HotToggleSwitch';

export const bookingRelatedPartiesState = newAtom([]);
const openRelatedPartiesDialog = newAtom(false);

export interface BookingDetailProps extends RouteComponentProps {
  bookingId?: string;
}

export default function BookingDetails(props: BookingDetailProps) {
  const userContext = useRecoilValue(userContextAtom);
  const bookingId = props.bookingId ?? '';
  const [booking, setBooking] = useRecoilState(BookingPageStates.booking);
  const setAllRemarks = useSetRecoilState(BookingPageStates.allRemarks);
  const setRelatedParties = useSetRecoilState(bookingRelatedPartiesState);
  const setOpenDialog = useSetRecoilState(openRelatedPartiesDialog);
  const { enqueueSnackbar } = useSnackbar();

  const [assignBookingRelatedParty] = useAssignBookingRelatedPartyMutation();
  const [removeBookingRelatedParty] = useRemoveBookingRelatedPartyMutation();
  const [updateBookingRelatedParty] = useUpdateBookingRelatedPartyMutation();

  const [refetchBookingQuery] = useBookingLazyQuery({
    variables: { bookingId: booking?.id ?? '' },
    fetchPolicy: 'network-only',
    onCompleted: (data) => {
      if (data.booking) {
        enqueueSnackbar('Booking Related Parties Updated!', {
          variant: 'success',
          anchorOrigin: {
            vertical: 'top',
            horizontal: 'right',
          },
        });
        setOpenDialog(false);
      }
    },
  });

  const { data: bookingData, loading } = useBookingQuery({
    variables: { bookingId: props.bookingId ?? '' },
    fetchPolicy: 'network-only',
  });

  const { data: profilesData } = useNetworkProfilesQuery({
    variables: { networkId: userContext?.activeContact?.profile.network.id ?? '' },
    fetchPolicy: 'network-only',
  });

  const [profileOptions, setProfileOptions] = React.useState<GqlProfile[]>([]);

  React.useEffect(() => {
    if (profilesData?.network) {
      setProfileOptions(profilesData.network.connectedProfiles as GqlProfile[]);
    }
  }, [profilesData]);

  React.useEffect(() => {
    if (bookingData) {
      const booking = bookingData.booking;
      if (!booking) {
        navigate('/bookings');
      } else {
        if (booking.id) {
          let remarks: Remark[] = [];
          remarks = remarks.concat(booking.mbl?.remarks ?? []);
          remarks = remarks.concat(booking.hbl?.remarks ?? []);
          remarks = remarks.concat(booking.remarks ?? []);
          remarks = sortRemarks(remarks);
          setAllRemarks(remarks);
        }
        const hotBooking: HotState = {
          isHot: booking.isHot,
          hotMarkedBy: booking.hotMarkedBy,
          hotMarkedTimestamp: booking.hotMarkedTimestamp
        };
        const bookingObj = {...booking, hot: hotBooking};
        setBooking(bookingObj as BookingPageData);
        setRelatedParties(booking.relatedParties as any);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [bookingData, loading]);

  if (!booking || loading || !userContext || !Array.isArray(booking?.relatedParties)) {
    return <Loading />;
  }

  return (
    <Box>
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        marginBottom={2}
        marginLeft={1}
        marginTop={1}
      >
        <Box display="flex" alignItems="center">
          <Typography variant="h2" color="primary">
            Booking: {booking.referenceNumber}
          </Typography>
          <HotBookingSwitch bookingId={booking.id} initialState={booking.hot} showDetailsOnRight={true} />
        </Box>
        {userContext?.permissionCodes.has(PermissionCode.BookingRelatedPartiesOverride) && (
          <>
            <Button
              variant="contained"
              color="primary"
              size="large"
              onClick={() => {
                setOpenDialog(true);
              }}
            >
              Booking Party Override
            </Button>
            <OverrideRelatedPartiesDialog
              openState={openRelatedPartiesDialog}
              relatedParties={booking.relatedParties}
              profileOptions={profileOptions}
              onOverrideClicked={async (changes) => {
                await Promise.all(
                  changes.delete.map((item) =>
                    removeBookingRelatedParty({
                      variables: {
                        input: {
                          relatedPartyId: item.relationId,
                        },
                      },
                    })
                  )
                );
                await Promise.all(
                  changes.new.map((item) =>
                    assignBookingRelatedParty({
                      variables: {
                        input: {
                          bookingId: booking?.id ?? '',
                          profileId: item.profile ? item.profile.id : '',
                          partyType: item.partyType ?? PartyType.Related,
                        },
                      },
                    })
                  )
                );
                await Promise.all(
                  changes.update.map((item) =>
                    updateBookingRelatedParty({
                      variables: {
                        input: {
                          bookingId: booking?.id ?? '',
                          relatedPartyId: item.relationId,
                          profileId: item.profile ? item.profile.id : '',
                          partyType: item.partyType ?? PartyType.Related,
                        },
                      },
                    })
                  )
                );
                refetchBookingQuery();
              }}
            />
          </>
        )}
      </Box>
      <Grid container spacing={2} alignItems="stretch" direction="row">
        <Grid item xs={12} md={6}>
          <BookingDetailsPanel />
        </Grid>
        <Grid item xs={12} md={6}>
          <LogisticDetailsPanel bookingId={bookingId} />
        </Grid>
        <Grid item xs={12}>
          <BookingRemarks />
        </Grid>
        <Grid item xs={12}>
          <BookingEquipment bookingId={bookingId} />
        </Grid>
        <Grid item xs={12}>
          <BookingLineItemsPanel bookingId={bookingId} />
        </Grid>
        <Grid item xs={12}>
          <BookingDocuments bookingId={bookingId} />
        </Grid>
      </Grid>
    </Box>
  );
}
