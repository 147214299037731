import React from 'react';
import { Box, Button } from '@material-ui/core';
import UniversalDialog from 'components/UniversalDialog';
import { RecoilState, useRecoilState } from 'recoil';
import { genKey } from 'recoil-utils/utils';
import { GqlProfile, GqlRelatedParty, PartyType } from 'api/GQL_Types';
import RelationItem from './RelationItem';
import Footer, { PartyChanges } from './Footer';

export type RelatedPartyItem = {
  relationId: string;
  profile: { id: string; name: string } | null;
  partyType: PartyType | null;
};

interface Props {
  openState: RecoilState<boolean>;
  relatedParties: GqlRelatedParty[];
  profileOptions: GqlProfile[];
  onOverrideClicked: (changes: PartyChanges) => void;
}

export default function OverrideRelatedPartiesDialog(props: Props) {
  const [open, setOpen] = useRecoilState(props.openState);

  return (
    <UniversalDialog
      open={open}
      title={'Related Parties'}
      small
      setClose={() => {
        setOpen(false);
      }}
    >
      <Content {...props} />
    </UniversalDialog>
  );
}

function Content(props: Props) {
  const [items, setItems] = React.useState<{ [selectorId: string]: RelatedPartyItem }>({});

  React.useEffect(() => {
    for (const rp of props.relatedParties) {
      items[genKey()] = {
        relationId: rp.id,
        profile: { id: rp.party.id, name: rp.party.name },
        partyType: rp.partyType,
      };
    }
    setItems({ ...items });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      {Object.keys(items).map((key, index) => {
        return (
          <RelationItem
            key={index}
            itemSelectorId={key}
            items={items}
            profileOptions={props.profileOptions}
            relationOptions={Object.values(PartyType)}
            onItemChanged={(itemId, item) => {
              items[itemId].profile = item.profile;
              items[itemId].partyType = item.partyType;
              setItems({ ...items });
            }}
            onDeleteClicked={(itemId) => {
              const tempItems = { ...items };
              delete tempItems[itemId];
              setItems(tempItems);
            }}
          />
        );
      })}
      <Box display="flex" justifyContent="center" marginBottom={2}>
        <Button
          variant="contained"
          color="secondary"
          onClick={() => {
            items[genKey()] = {
              relationId: '',
              profile: null,
              partyType: null,
            };
            setItems({ ...items });
          }}
        >
          Add New Party
        </Button>
      </Box>

      <hr />
      <Footer
        openState={props.openState}
        items={items}
        relatedParties={props.relatedParties}
        onOverrideClicked={props.onOverrideClicked}
      />
    </>
  );
}
