import { Box, Button, Grid, Typography } from '@material-ui/core';
import {
  PartyType,
  useNewProfileRelationMutation,
  useUpdateProfileRelationMutation,
} from 'api/GQL_Types';
import { ProfileQuery } from 'api/queries/profileQueries';
import AtomicSelectorV2 from 'components/atomic/AtomicSelectorV2';
import { Line } from 'components/StyledComponents';
import UniversalDialog from 'components/UniversalDialog';
import React from 'react';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import { AtomCleaner, newAtom } from 'recoil-utils/utils';
import styled from 'styled-components';
import { theme } from 'styles';
import { mapPartyType } from 'utils/Enums';
import { ProfilePageConnectedProfile, ProfilePageRelatedProfile } from '../states';

const ProfileForm = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

// Interface atom to open and close dialog.
export const RelatedProfilesDialog_Open = newAtom(false);
// Interface atom to set initial data state of dialog. If left null it is treated as a new dialog. (Idk about this but it works.)
export const RelatedProfilesDialog_Data = newAtom<ProfilePageRelatedProfile | null>(null);

const ALL_partyTypes: { code: PartyType; label: string }[] = Object.values(PartyType).map(
  (type) => ({
    code: type,
    label: mapPartyType(type),
  })
);

const selectedProfileState = newAtom({
  id: '',
  name: '',
});

const selectedProfileRelationTypeState = newAtom<{ code: PartyType | ''; label: string }>({
  code: '',
  label: '',
});

interface Props {
  profileId: string;
  // List of options to display for the profile selector
  profileOptions: ProfilePageConnectedProfile[];
}

export default function RelatedProfilesDialog(props: Props) {
  const relatedProfile = useRecoilValue(RelatedProfilesDialog_Data);
  const [openDialog, setOpenDialog] = useRecoilState(RelatedProfilesDialog_Open);

  const setSelectedProfile = useSetRecoilState(selectedProfileState);
  const setSelectedProfileRelationType = useSetRecoilState(selectedProfileRelationTypeState);

  React.useEffect(() => {
    setSelectedProfileRelationType(
      relatedProfile?.partyType
        ? { code: relatedProfile.partyType, label: relatedProfile.partyType + '' }
        : { code: '', label: '' }
    );
    setSelectedProfile({ id: '', name: '' });
  }, [relatedProfile]);

  return (
    <UniversalDialog
      open={openDialog}
      title={relatedProfile ? 'Update Related Profile' : 'New Related Profile'}
      subTitle={
        relatedProfile
          ? 'Update relation type for this profile'
          : 'Create a new relation for this profile'
      }
      large
      setClose={() => {
        setOpenDialog(false);
      }}
    >
      <AtomCleaner
        atoms={[selectedProfileState, selectedProfileRelationTypeState, RelatedProfilesDialog_Data]}
      />
      <Line height={2} color={theme.palette.primary.main} />
      <Box marginBottom={2}>
        <ProfileForm>
          {Boolean(relatedProfile) ? (
            <Grid container alignItems="center" justify="center">
              <Grid item xs={6}>
                <Box display="flex" justifyContent="space-around">
                  <Box display="flex" alignItems="center">
                    <Typography variant="h2">{relatedProfile?.profile.name}</Typography>
                  </Box>
                  <Box display="flex" alignItems="center" marginRight={1}>
                    <Typography variant="h3">is</Typography>
                  </Box>
                </Box>
              </Grid>
              <Grid item xs={6}>
                <AtomicSelectorV2
                  label="Relationship Type"
                  state={selectedProfileRelationTypeState}
                  optionsList={ALL_partyTypes}
                  controllingField="code"
                  displayField="label"
                />
              </Grid>
            </Grid>
          ) : (
            <Grid container alignItems="center" justify="center">
              <Grid item xs={6}>
                <AtomicSelectorV2
                  label="Relate Profile"
                  state={selectedProfileState}
                  optionsList={props.profileOptions}
                  controllingField="id"
                  displayField="name"
                />
              </Grid>
              <Grid item xs={1}>
                <Typography variant="h3">is</Typography>
              </Grid>
              <Grid item xs={5}>
                <AtomicSelectorV2
                  label="Relationship Type"
                  state={selectedProfileRelationTypeState}
                  optionsList={ALL_partyTypes}
                  controllingField="code"
                  displayField="label"
                />
              </Grid>
            </Grid>
          )}
        </ProfileForm>
      </Box>
      <DialogFooter profileId={props.profileId} />
    </UniversalDialog>
  );
}

interface DialogFooterProps {
  profileId: string;
}

function DialogFooter(props: DialogFooterProps) {
  const setOpenDialog = useSetRecoilState(RelatedProfilesDialog_Open);
  const selectedProfile = useRecoilValue(selectedProfileState);
  const selectedRelationType = useRecoilValue(selectedProfileRelationTypeState);
  const relatedProfile = useRecoilValue(RelatedProfilesDialog_Data);

  const [newProfileRelation] = useNewProfileRelationMutation({
    refetchQueries: () => [
      {
        query: ProfileQuery,
        variables: { profileId: props.profileId },
        fetchPolicy: 'cache-and-network',
      },
    ],
  });

  const [updateProfileRelation] = useUpdateProfileRelationMutation({
    refetchQueries: () => [
      {
        query: ProfileQuery,
        variables: { profileId: props.profileId },
        fetchPolicy: 'cache-and-network',
      },
    ],
  });

  return (
    <Box display="flex" alignItems="center" justifyContent="flex-end">
      <Button
        variant="contained"
        color="default"
        size="large"
        onClick={() => {
          setOpenDialog(false);
        }}
        style={{ height: '40px', marginRight: '12px' }}
      >
        Cancel
      </Button>
      <Button
        variant="contained"
        color="primary"
        size="large"
        onClick={() => {
          if (!selectedRelationType.code) {
            return;
          }
          if (relatedProfile) {
            updateProfileRelation({
              variables: {
                input: {
                  relationId: relatedProfile.id,
                  partyType: selectedRelationType.code,
                },
              },
            });
          } else {
            newProfileRelation({
              variables: {
                input: {
                  profileId: props.profileId,
                  relatedProfileId: selectedProfile.id,
                  partyType: selectedRelationType.code,
                },
              },
            });
          }
          setOpenDialog(false);
        }}
        disabled={
          !Boolean(relatedProfile)
            ? selectedProfile.id === '' || selectedRelationType.code === ''
            : relatedProfile?.partyType === selectedRelationType.code
        }
        style={{ height: '40px', marginRight: '12px' }}
      >
        {relatedProfile ? 'Updated Profile Relation' : 'Create Profile Relation'}
      </Button>
    </Box>
  );
}
