import {
  Box,
  Button,
  CircularProgress,
  IconButton,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Paper,
  Typography,
} from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import DescriptionIcon from '@material-ui/icons/Description';
import EditIcon from '@material-ui/icons/Edit';
import ListAltIcon from '@material-ui/icons/ListAlt';
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';
import { navigate, RouteComponentProps } from '@reach/router';
import { PermissionCode } from 'api/GQL_Types';
import { apiDownloadReport, deleteReport, getReports } from 'api/queries/reportQueries';
import { auth } from 'app';
import DeleteDialog from 'components/DeleteDialog';
import { UWLTable } from 'components/UWLTable/UWLTable';
import { sortWithBy } from 'lib/sort';
import useAsyncLoader from 'lib/useAsyncLoader';
import { useSnackbar } from 'notistack';
import React from 'react';
import { Report } from 'types/Report';
import { UWLTableColumn } from 'types/UWLTable';
import { getWeekDayAbbr, getWeekDayFull } from 'types/Weekday';
import { formatDateTime } from 'utils/Dates';
import ReportScheduledSwitch from './ReportScheduledSwitch';

const columns: UWLTableColumn<Report>[] = [
  {
    id: 'name',
    label: 'Report Name',
    type: 'string',
  },
  {
    id: 'period',
    label: 'Frequency',
    type: 'string',
    sortWith: sortWithBy((row) => {
      switch (row.period) {
        case 'weekly':
          return 100 + row.weekdays.length;
        case 'monthly':
          return 200 + row.days.length;
      }
      return 0;
    }),
  },
  {
    id: 'lastRun',
    label: 'Last Report',
    type: 'string',
    sortWith: sortWithBy((row) => {
      return row.lastRun ? row.lastRun.getTime() : 0;
    }),
  },
  {
    id: 'nextRun',
    label: 'Next Report',
    type: 'string',
    sortWith: sortWithBy((row) => {
      return row.nextRun ? row.nextRun.getTime() : 0;
    }),
  },
  {
    id: 'scheduled',
    label: 'Scheduled',
    type: 'string',
    sortWith(a, b) {
      let cmp = (a.scheduled ? 1 : 0) - (b.scheduled ? 1 : 0);
      if (cmp === 0) {
        cmp = a.name > b.name ? -1 : a.name < b.name ? 1 : 0;
      }
      return cmp;
    },
  },
];

interface Props extends RouteComponentProps {}

export const ReportsPage: React.FC<Props> = () => {
  const { userContext } = auth.useAuthState();
  const { enqueueSnackbar } = useSnackbar();
  const [rowMenuAnchorEl, setRowMenuAnchorEl] = React.useState(null);
  const [rowMenuId, setRowMenuId] = React.useState<null | string>(null);
  const [confirmDeleteId, setConfirmDeleteId] = React.useState<null | string>(null);
  const [downloadStatus, setDownloadStatus] = React.useState<{
    [id: string]: { waiting: boolean; error: string | null };
  }>({});

  const dataLoader = useAsyncLoader([], getReports);

  React.useEffect(() => {
    dataLoader.load();
  }, []);

  function closeRowMenu() {
    setRowMenuId(null);
    setRowMenuAnchorEl(null);
  }

  async function downloadReport(reportId: string, file_type: 'xlsx' | 'pdf') {
    setDownloadStatus({ ...downloadStatus, [reportId]: { waiting: true, error: null } });
    try {
      await apiDownloadReport(reportId, file_type);
      setDownloadStatus({
        ...downloadStatus,
        [reportId]: { waiting: false, error: null },
      });
    } catch (err) {
      setDownloadStatus({
        ...downloadStatus,
        [reportId]: { waiting: false, error: err + '' },
      });
      enqueueSnackbar('Report download failed: ' + err, {
        variant: 'error',
        anchorOrigin: {
          vertical: 'top',
          horizontal: 'right',
        },
      });
    }
  }

  const reports = dataLoader.data;

  const deletingReport: Report | null = reports.find((r) => r.id === confirmDeleteId) || null;

  const canWriteReports = !!userContext?.permissionCodes.has(PermissionCode.ReportsUpdate);

  return (
    <div>
      <Box display="flex" justifyContent="space-between" paddingBottom={4}>
        <Typography variant="h1">Report Management</Typography>
        {userContext?.permissionCodes.has(PermissionCode.ReportsCreate) && (
          <Button
            variant="contained"
            color="primary"
            onClick={() => {
              navigate('/reports/new');
            }}
          >
            New Report
          </Button>
        )}
      </Box>
      <Paper>
        <Box paddingTop={2} overflow="auto">
          <UWLTable
            rowId="id"
            columns={
              userContext?.permissionCodes.has(PermissionCode.ReportsUpdate)
                ? columns
                : columns.filter((col) => col.id !== 'scheduled')
            }
            rows={reports}
            isLoading={dataLoader.waiting}
            error={dataLoader.error}
            sizeMedium
            emptyMessage="- no reports -"
            renderCell={{
              period(row) {
                switch (row.period) {
                  case 'weekly':
                    let days = row.weekdays;
                    if (days.length === 1) {
                      return <div>Weekly - {getWeekDayFull(days[0])}</div>;
                    } else if (days.length > 1) {
                      return <div>{days.map(getWeekDayAbbr).join(',')}</div>;
                    }
                    break; // days.length === 0

                  case 'monthly':
                    if (row.days.length > 0) {
                      return <div>Monthly - {row.days.join(',')}</div>;
                    }
                    break;
                }

                return <Typography variant="caption">None</Typography>;
              },
              lastRun(row) {
                const str = formatDateTime(row.lastRun) + ' ' + row.timezone;
                if (row.lastError) {
                  return (
                    <Typography color="error" title={row.lastError}>
                      {str}
                    </Typography>
                  );
                }
                return str;
              },
              nextRun(row) {
                if (!row.nextRun) {
                  return <Typography variant="caption">Not Scheduled</Typography>;
                }
                return formatDateTime(row.nextRun) + ' ' + row.timezone;
              },
              scheduled(row) {
                return (
                  <ReportScheduledSwitch
                    id={row.id}
                    scheduled={row.scheduled}
                    onReport={(report) => {
                      dataLoader.setData(
                        dataLoader.data.map((r) => (r.id === report.id ? report : r))
                      );
                    }}
                  />
                );
              },
            }}
            rowAction={(row) => {
              const status = downloadStatus[row.id];
              if (status && status.waiting) {
                return <CircularProgress />;
              }
              return (
                <IconButton
                  onClick={(e: any) => {
                    setRowMenuId(row.id);
                    setRowMenuAnchorEl(e.currentTarget);
                  }}
                >
                  <MoreHorizIcon />
                </IconButton>
              );
            }}
          />
        </Box>
      </Paper>
      <Menu
        anchorEl={rowMenuAnchorEl}
        keepMounted
        open={Boolean(rowMenuAnchorEl)}
        onClose={() => closeRowMenu()}
        getContentAnchorEl={null}
        anchorOrigin={{
          vertical: 'center',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        {canWriteReports && (
          <MenuItem
            onClick={() => {
              navigate('/reports/' + rowMenuId);
              closeRowMenu();
            }}
          >
            <ListItemIcon style={{ minWidth: 36 }}>
              <EditIcon />
            </ListItemIcon>
            <ListItemText primary="Modify" />
          </MenuItem>
        )}
        <MenuItem
          onClick={() => {
            if (rowMenuId) {
              downloadReport(rowMenuId, 'xlsx');
            }
            closeRowMenu();
          }}
        >
          <ListItemIcon style={{ minWidth: 36 }}>
            <ListAltIcon />
          </ListItemIcon>
          <ListItemText primary="Download Sheet" />
        </MenuItem>
        <MenuItem
          onClick={() => {
            if (rowMenuId) {
              downloadReport(rowMenuId, 'pdf');
            }
            closeRowMenu();
          }}
        >
          <ListItemIcon style={{ minWidth: 36 }}>
            <DescriptionIcon />
          </ListItemIcon>
          <ListItemText primary="Download PDF" />
        </MenuItem>
        {canWriteReports && (
          <MenuItem
            onClick={() => {
              setConfirmDeleteId(rowMenuId);
              closeRowMenu();
            }}
          >
            <ListItemIcon style={{ minWidth: 36 }}>
              <DeleteIcon />
            </ListItemIcon>
            <ListItemText primary="Delete" />
          </MenuItem>
        )}
      </Menu>
      {deletingReport && (
        <DeleteDialog
          title="Delete Report"
          doDelete={() => deleteReport(deletingReport.id)}
          onClose={(deleted) => {
            if (deleted) {
              dataLoader.loadUpdate();
            }
            setConfirmDeleteId(null);
          }}
        >
          Are you sure you want to delete this report: <strong>{deletingReport.name}</strong>?
        </DeleteDialog>
      )}
    </div>
  );
};
