import { faAddressCard, faShieldAlt } from '@fortawesome/free-solid-svg-icons';
import { Box, Fab, Typography } from '@material-ui/core';
import { navigate, RouteComponentProps } from '@reach/router';
import { SystemPermissionCode, useProfileTypeTemplatesQuery } from 'api/GQL_Types';
import { auth } from 'app';
import AdminCardList from 'app/admin/components/AdminCardList';
import { ManagePageHeader_BackStack } from 'app/admin/components/ManagePageHeader';
import ErrorMessage from 'components/ErrorMessage';
import SearchBar from 'components/SearchBar';
import React from 'react';
import { selector, useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import { newAtom } from 'recoil-utils/utils';
import { theme } from 'styles';
import NewProfileTypeTemplateDialog, { AddProfileTypeDialog_Open } from './new-profile-type-dialog';

interface AdminObj {
  id: string;
  name: string;
  description: string;
  isTemplate: boolean;
  roles: number;
  permissions: number;
}

const profileTypesSearchState = newAtom<string>('');
const profileTypesListState = newAtom<AdminObj[]>([]);
const filteredProfileTypesListState = selector({
  key: 'filteredProfileTypesListState',
  get: ({ get }) => {
    const searchField = get(profileTypesSearchState);
    const profileTypes = get(profileTypesListState);
    const lowerField = searchField.toLowerCase();

    if (searchField === '') {
      return profileTypes;
    } else {
      return profileTypes.filter((profileType) =>
        profileType.name.toLowerCase().includes(lowerField)
      );
    }
  },
});

interface Props extends RouteComponentProps {}
export default function ProfileTypeList(props: Props) {
  const { userContext } = auth.useAuthState();
  const setBackStack = useSetRecoilState(ManagePageHeader_BackStack);
  const setProfileTypes = useSetRecoilState(profileTypesListState);
  const [searchField, setSearchField] = useRecoilState(profileTypesSearchState);
  const filteredProfileTypes = useRecoilValue(filteredProfileTypesListState);

  const { error } = useProfileTypeTemplatesQuery({
    fetchPolicy: 'cache-and-network',
    onCompleted(data) {
      const profileTypesList = data.profileTypeTemplates.map((profileType) => {
        return {
          id: profileType.id,
          name: profileType.name,
          description: profileType.description || '',
          isTemplate: profileType.isTemplate,
          roles: profileType.defaultRoles.length,
          permissions: profileType.permissionCodes.length,
        };
      });
      setProfileTypes(profileTypesList);
    },
  });

  return (
    <div>
      <Box margin="16px 24px">
        <Box display="flex" justifyContent="space-between" marginBottom={3}>
          <Box>
            <Typography variant="h2">Profile Type Templates</Typography>
            <Typography variant="body1">{filteredProfileTypes.length} Total</Typography>
          </Box>
          <Box width="25%" bgcolor="#FFFFFF" padding={1} alignSelf="center" borderRadius="4px">
            <SearchBar
              placeholder="Search Profile Types"
              field={searchField}
              updateField={setSearchField}
            />
          </Box>
        </Box>
        <ErrorMessage error={error ? error + '' : null} />
        <AdminCardList<AdminObj>
          list={filteredProfileTypes}
          iconList={[
            {
              name: 'Permissions',
              key: 'permissions',
              icon: faShieldAlt,
              color: theme.palette.dodgerBlue.main,
            },
            {
              name: 'Roles',
              key: 'roles',
              icon: faAddressCard,
              color: theme.palette.blueGreen.main,
            },
          ]}
          onManageClicked={(item) => {
            setBackStack([{ label: 'Profile Type List', path: '/admin/profile-types' }]);
            navigate('admin/profile-types/' + item.id);
          }}
        />
      </Box>
      {userContext?.systemPermissionCodes.has(SystemPermissionCode.ProfileTypeTemplatesCreate) && (
        <Box position="absolute" bottom="48px" right="36px">
          <NewProfileTypeButton />
        </Box>
      )}
      <NewProfileTypeTemplateDialog />
    </div>
  );
}

function NewProfileTypeButton() {
  const setOpenDialog = useSetRecoilState(AddProfileTypeDialog_Open);

  return (
    <Fab
      color="primary"
      aria-label="add"
      variant="extended"
      onClick={() => {
        setOpenDialog(true);
      }}
    >
      <i className="material-icons" style={{ marginRight: '8px' }}>
        {'add'}
      </i>
      Create New Profile Type
    </Fab>
  );
}
