import { Box, Button, Grid, Typography } from '@material-ui/core';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import { navigate } from '@reach/router';
import ErrorMessage from 'components/ErrorMessage';
import FakeProgressBar from 'components/FakeProgressBar';
import UniversalDialog from 'components/UniversalDialog';
import React from 'react';

interface Props {
  open: boolean;
  onClose(): void;
  finished: boolean;
  error: string | null;
}

export const ConfirmUploadDialog: React.FC<Props> = ({ open, onClose, finished, error }) => {
  return (
    <UniversalDialog open={open} small setClose={onClose}>
      <Box padding={3} textAlign="center">
        {error ? (
          <>
            <Typography variant="h1">FILE CONFIRMATION FAILED</Typography>
            <ErrorMessage error={error} />
            <Box paddingTop={3}>
              <Button onClick={onClose} variant="contained" color="default">
                Close
              </Button>
            </Box>
          </>
        ) : finished ? (
          <>
            <Box display="flex" justifyContent="center">
              <CheckCircleIcon style={{ fontSize: 90, color: '#009600' }} />
            </Box>
            <Typography variant="h1">FILE CONFIRMATION COMPLETE</Typography>
            <Typography variant="body1">Purchase Orders are now ready to view</Typography>
            <Grid
              container
              spacing={3}
              direction="row"
              justify="center"
              alignItems="center"
              style={{ marginTop: '24px' }}
            >
              <Grid item xs={6}>
                <Button
                  onClick={() => {
                    onClose();
                    navigate('/purchase-orders/uploads/new');
                  }}
                  variant="contained"
                  color="primary"
                  fullWidth
                >
                  Back To PO Upload
                </Button>
              </Grid>
              <Grid item xs={6}>
                <Button
                  onClick={() => {
                    onClose();
                    navigate('/purchase-orders/uploads');
                  }}
                  variant="contained"
                  color="secondary"
                  fullWidth
                >
                  Go To Uploaded PO List
                </Button>
              </Grid>
            </Grid>
          </>
        ) : (
          <>
            <Box marginBottom={2}>
              <FakeProgressBar start={true} finished={false} onFinished={() => {}} />
            </Box>
            <Typography variant="h1">CONFIRMING...</Typography>
            <Typography variant="body1">Purchase Order is being confirmed.</Typography>
          </>
        )}
      </Box>
    </UniversalDialog>
  );
};
