import { Grid } from '@material-ui/core';
import { GqlPoImportFileStatusFragment } from 'api/GQL_Types';
import React from 'react';
import { DonutSummary } from './DonutSummary';
import { ImportWarningSummary } from './ImportWarningSummary';

interface MappingsNotFoundProps {
  fileStatusData: GqlPoImportFileStatusFragment;
}

export const FileSummary: React.FC<MappingsNotFoundProps> = ({ fileStatusData }) => {
  if (!fileStatusData) {
    return <div></div>;
  }

  const fileErrors: string[] = [];
  for (const error of fileStatusData.errors) {
    if (error.headerIndex < 0 && error.rowIndex < 0) {
      fileErrors.push(error.error);
    }
  }

  if (fileErrors.length > 0) {
    return (
      <div style={{ marginBottom: '12px', width: '100%', whiteSpace: 'pre-wrap' }}>
        {fileErrors.map((error, index) => {
          return <div key={index}>{error}</div>;
        })}
      </div>
    );
  }

  return (
    <div
      style={{
        display: 'flex',
        width: '100%',
        justifyContent: 'center',
        marginBottom: '12px',
      }}
    >
      <Grid item xs={4}>
        <DonutSummary
          title="PURCHASE ORDERS"
          new={fileStatusData.newPos || 0}
          updated={fileStatusData.updatedPos || 0}
          total={fileStatusData.totalPos || 0}
        />
      </Grid>
      <Grid item xs={4}>
        <DonutSummary
          title="ORDER LINES"
          new={fileStatusData.newLines || 0}
          updated={fileStatusData.updatedLines || 0}
          total={fileStatusData.totalLines || 0}
        />
      </Grid>
      <Grid item xs={4}>
        <ImportWarningSummary fileStatusData={fileStatusData} />
      </Grid>
    </div>
  );
};
