import { Box, Grid } from '@material-ui/core';
import Panel from 'components/Panel';
import React from 'react';
import BookingLineItemsTables from './BookingLineItemsTables';
import BookingFields from './BookingFields';
import EquipmentRemark from './equipment-remarks';

export default function NewBookingStep2() {
  return (
    <Box width="100%">
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <BookingFields />
        </Grid>
        <Grid item xs={12}>
          <EquipmentRemark />
        </Grid>
        <Grid item xs={12} style={{ display: 'flex', height: 400 }}>
          <Panel title="Line Items" growPanel>
            <BookingLineItemsTables />
          </Panel>
        </Grid>
      </Grid>
    </Box>
  );
}
