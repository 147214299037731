import { Box, Button, Grid } from '@material-ui/core';
import { GqlRelatedParty, PartyType, PermissionCode } from 'api/GQL_Types';
import { userContextAtom } from 'app';
import LineItem from 'components/LineItem';
import Panel from 'components/Panel';
import React from 'react';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { formatDate } from 'utils/Dates';
import {
  mapContractType,
  mapIncoTerm,
  mapMoveType,
  mapShipmentStatus,
  mapTransportMode,
} from 'utils/Enums';
import { BookingPageStates } from '../states';
import EditBookingDialog, { EditBookingDialog_Open } from './edit-booking-dialog';

interface Props {}

export default function BookingDetailsPanel(props: Props) {
  const userContext = useRecoilValue(userContextAtom);
  const setOpen = useSetRecoilState(EditBookingDialog_Open);
  const booking = useRecoilValue(BookingPageStates.booking);
  const setRevisedCargoReadyDate = useSetRecoilState(
    BookingPageStates.editBooking.revisedCargoReadyDate
  );
  const setIncoTerm = useSetRecoilState(BookingPageStates.editBooking.incoTerm);

  return (
    <Box width="100%" height="100%" display="flex">
      <Panel
        title="Booking Details"
        growPanel
        topRight={
          userContext?.permissionCodes.has(PermissionCode.BookingUpdate) && (
            <Button
              variant="contained"
              color="secondary"
              onClick={() => {
                setRevisedCargoReadyDate(booking?.revisedCargoReadyDate);
                setIncoTerm(booking?.incoTerm ?? null);
                setOpen(true);
              }}
            >
              Edit
            </Button>
          )
        }
      >
        <Box padding={3}>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={6}>
              <LineItem label="Booking" value={booking?.referenceNumber} />
              <LineItem value={formatDate(booking?.createDate || null)} label="Booking Date" />
              <LineItem
                value={booking?.status}
                label="Booking Status"
                decorator={mapShipmentStatus}
              />
              <LineItem
                value={booking?.logistics.transportationMode}
                label="Transportation Mode"
                decorator={mapTransportMode}
              />
              <LineItem value={booking?.incoTerm} label="INCO Term" decorator={mapIncoTerm} />
              <LineItem
                value={booking?.logistics.moveType}
                label="Move Type"
                decorator={mapMoveType}
              />
              <LineItem value={booking?.logistics?.pickupLocation?.name} label="Place of Receipt" />
              <LineItem
                value={booking?.logistics?.deliveryLocation?.name}
                label="Place of Delivery"
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <LineItem value={booking?.bookingParty.firstName} label="Booking Party" />
              <LineItem
                value={booking?.relatedParties}
                label="Manufacturer"
                decorator={(parties: GqlRelatedParty[]) =>
                  parties.find((p) => p.partyType === PartyType.Manufacturer)?.party.name
                }
              />
              <LineItem
                value={booking?.relatedParties}
                label="Shipper"
                decorator={(parties: GqlRelatedParty[]) =>
                  parties.find((p) => p.partyType === PartyType.Shipper)?.party.name
                }
              />
              <LineItem
                value={booking?.relatedParties}
                label="Consignee"
                decorator={(parties: GqlRelatedParty[]) =>
                  parties.find((p) => p.partyType === PartyType.Consignee)?.party.name
                }
              />
              <LineItem
                value={booking?.cargoReadyDate}
                decorator={formatDate}
                label="Cgo Rdy Date"
              />
              <LineItem
                value={booking?.revisedCargoReadyDate}
                decorator={formatDate}
                label="Rev Cgo Rdy Date"
              />
              <LineItem value={booking?.logistics?.carrier?.name} label="Carrier" />
              <LineItem
                value={booking?.logistics.contractType}
                label="Contract Type"
                decorator={mapContractType}
              />
              <LineItem value={booking?.logistics.contractNumber} label="Contract" />
            </Grid>
          </Grid>
        </Box>
      </Panel>
      <EditBookingDialog />
    </Box>
  );
}
