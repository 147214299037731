import { gql } from '@apollo/client/core';

export const NetworkPartiesQuery = gql`
  query NetworkParties($networkId: ID!) {
    network(networkId: $networkId) {
      connectedProfiles {
        id
        name
        profileType {
          id
          name
        }
        locations {
          id
          name
        }
      }
    }
  }
`;

export const AllNetworksQuery = gql`
  query AllNetworks {
    allNetworks {
      id
      name
      connectedProfiles {
        id
        name
        isControllingClient
      }
    }
  }
`;

export const NetworkQuery = gql`
  query Network($networkId: ID!) {
    network(networkId: $networkId) {
      id
      name
      connectedProfiles {
        id
        name
        profileType {
          id
          name
        }
        isControllingClient
        logo {
          url
        }
        needsUpdate
        locations {
          id
          name
          code
          nameAliases
          codeAliases
          addressLine1
          addressLine2
          city
          state
          postalCode
          country
          companyPhone
          companyUrl
          relatedPort {
            code
            name
          }
          locationTypes
        }
        contacts {
          id
          firstName
          lastName
          title
          email
          phoneMain
          phoneAlternative
          extension
          phoneCell
          isMainContact
          isUserEnabled
          notificationFrequency
          role {
            id
            name
          }
          location {
            id
            name
          }
        }
        relatedProfiles {
          id
        }
        roles {
          id
          name
          description
          isDefaultRole
        }
        network {
          id
          name
        }
      }
      profileTypes {
        id
        name
        description
        isTemplate
        defaultRoles {
          id
          name
          description
          isDefaultRole
          permissionCodes
        }
        permissionCodes
      }
      items {
        id
        itemNumber
        description
        htsCode
        supplier {
          name
        }
      }
      rules {
        poExpiredEnabled
        poExpiredDays
        poExpiredField

        poFirstShipEnabled
        poFirstShipDays
        poFirstShipField

        poLastShipEnabled
        poLastShipDays
        poLastShipField

        poBookByEnabled
        poBookByDays
        poBookByField

        poCriticalEnabled
        poCriticalDays
        poCriticalField

        bookingLateEnabled
        bookingLateDays
        bookingLateField

        bookingPendingConfEnabled
        bookingPendingConfDays

        bookingQtyToleranceEnabled
        bookingQtyTolerancePercentOver
        bookingQtyTolerancePercentUnder

        consolConsolableMoveTypesEnabled
        consolConsolableMoveTypes

        shipmentPolEtdChangeEnabled
        shipmentPolEtdChangeDays

        shipmentPodEtaChangeEnabled
        shipmentPodEtaChangeDays

        shipmentRevisedCrdChangeEnabled
        shipmentRevisedCrdChangeDays

        shipmentCriticalEnabled
        shipmentCriticalDays
        shipmentCriticalField1
        shipmentCriticalField2

        milestoneLateOnWaterEnabled
        milestoneLateOnWaterDays

        milestoneLateAtDischargeEnabled
        milestoneLateAtDischargeDays

        milestoneLateAtRampEnabled
        milestoneLateAtRampDays

        milestoneLateDeliveryEnabled
        milestoneLateDeliveryDays

        milestonePendingOutgateEnabled
        milestonePendingOutgateDays

        milestoneDelayedUnloadEnabled
        milestoneDelayedUnloadDays
        milestoneDelayedUnloadField

        milestoneEmptyNotReturnedEnabled
        milestoneEmptyNotReturnedDays

        documentTypes {
          documentType
          missingEnabled
          missingDays
          missingField
        }
      }
      containerTypes {
        code
        name
        capacityVolumeM3
        capacityWeightKg
        ruleMinVolumeM3
        ruleMaxVolumeM3
        ruleMinWeightKg
        ruleMaxWeightKg
      }
    }
  }
`;

export const NetworkProfilesQuery = gql`
  query NetworkProfiles($networkId: ID!) {
    network(networkId: $networkId) {
      connectedProfiles {
        id
        name
      }
    }
  }
`;

export const NewNetworkMutation = gql`
  mutation NewNetwork($input: NewNetworkInput!) {
    newNetwork(input: $input) {
      success
      message
      network {
        id
        connectedProfiles {
          id
          name
        }
      }
    }
  }
`;

export const UpdateNetworkRulesMutation = gql`
  mutation UpdateNetworkRules($input: UpdateNetworkRulesInput!) {
    updateNetworkRules(input: $input) {
      success
      message
    }
  }
`;

export const UpdateNetworkContainerTypes = gql`
  mutation UpdateNetworkContainerTypes($input: UpdateNetworkContainerTypesInput!) {
    updateNetworkContainerTypes(input: $input) {
      success
      message
    }
  }
`;

export const NewNetworkConnectedProfileMutation = gql`
  mutation NewNetworkConnectedProfile($input: NewNetworkConnectedProfileInput!) {
    newNetworkConnectedProfile(input: $input) {
      success
      message
      profile {
        id
        name
      }
    }
  }
`;

export const NewNetworkProfileTypeMutation = gql`
  mutation NewNetworkProfileType($input: NewNetworkProfileTypeInput!) {
    newNetworkProfileType(input: $input) {
      success
      message
      profileType {
        id
      }
    }
  }
`;
