import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Grow from '@material-ui/core/Grow';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import MenuItem from '@material-ui/core/MenuItem';
import MenuList from '@material-ui/core/MenuList';
import Paper from '@material-ui/core/Paper';
import Popper from '@material-ui/core/Popper';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import DescriptionIcon from '@material-ui/icons/Description';
import ListAltIcon from '@material-ui/icons/ListAlt';
import { apiDownloadDynamicReport } from 'api/queries/reportQueries';
import { useSnackbar } from 'notistack';
import * as React from 'react';
import { DatasetRequest } from 'types/Dataset';

interface Props {
  req: DatasetRequest;
  disabled: boolean;
}

export default function DownloadDynamicReportButton({ req, disabled }: Props) {
  const { enqueueSnackbar } = useSnackbar();
  const [open, setOpen] = React.useState(false);
  const anchorRef = React.useRef(null);

  const [isDownloading, setIsDownloading] = React.useState<boolean>(false);

  async function downloadDynamicReport(file_type: 'xlsx' | 'pdf') {
    if (isDownloading) return;
    setIsDownloading(true);
    try {
      await apiDownloadDynamicReport(file_type, req);
    } catch (err) {
      enqueueSnackbar('Failed to download report: ' + err, {
        variant: 'error',
        anchorOrigin: {
          vertical: 'top',
          horizontal: 'right',
        },
      });
    } finally {
      setIsDownloading(false);
    }
  }

  return (
    <div>
      <Button
        variant="contained"
        color="secondary"
        size="large"
        ref={anchorRef}
        onClick={() => setOpen((prevOpen) => !prevOpen)}
        disabled={disabled || isDownloading}
      >
        {isDownloading ? (
          <>
            Downloading... <CircularProgress size={20} />
          </>
        ) : (
          <>
            Preview Report <ArrowDropDownIcon />
          </>
        )}
      </Button>
      <Popper
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
        disablePortal
        style={{ zIndex: 10 }}
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom',
            }}
          >
            <Paper>
              <ClickAwayListener
                onClickAway={(e) => {
                  if (anchorRef.current && (anchorRef as any).current.contains(e.target)) return;
                  setOpen(false);
                }}
              >
                <MenuList id="split-button-menu">
                  <MenuItem
                    onClick={() => {
                      downloadDynamicReport('xlsx');
                      setOpen(false);
                    }}
                  >
                    <ListItemIcon style={{ minWidth: 36 }}>
                      <ListAltIcon />
                    </ListItemIcon>
                    <ListItemText primary="Download Sheet" />
                  </MenuItem>
                  <MenuItem
                    onClick={() => {
                      downloadDynamicReport('pdf');
                      setOpen(false);
                    }}
                  >
                    <ListItemIcon style={{ minWidth: 36 }}>
                      <DescriptionIcon />
                    </ListItemIcon>
                    <ListItemText primary="Download PDF" />
                  </MenuItem>
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </div>
  );
}
