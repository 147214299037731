import { useDivDimensions } from 'lib/useDivDimensions';
import BookingsTable from './BookingsTable';
import EquipmentSelecting from './EquipmentSelection';
import EquipmentUtilization from './equipment-utilization';
import { Box, Grid } from '@material-ui/core';
import {
  GqlRelatedParty,
  PartyType,
  useAvailableConsolidationBookingListLazyQuery,
} from 'api/GQL_Types';
import { ConsolidationBookingsTV, NewConsolidationStates, useSetupStep1 } from '../states';
import { useRecoilValue } from 'recoil';
import { mapMoveType, mapShipmentStatus } from 'utils/Enums';
import { formatNumber } from 'utils/Numbers';
import Loading from 'app/Loading';
import React from 'react';

export default function NewConsolidationStep1() {
  const setupStep1 = useSetupStep1();
  const bookings = useRecoilValue(NewConsolidationStates.step1.bookings);
  const { divEl, height } = useDivDimensions();

  const [getBookings, { loading }] = useAvailableConsolidationBookingListLazyQuery({
    fetchPolicy: 'no-cache',
    onCompleted(bookingsData) {
      let bookingsList: ConsolidationBookingsTV[] = [];
      for (const booking of bookingsData.availableConsolidationBookings) {
        let containers = '';
        let weight = 0;
        let volume = 0;
        if (booking.packLines) {
          [weight, volume] = booking.packLines.reduce(
            (count, currentValue) => {
              return [count[0] + currentValue.weight, count[1] + currentValue.volume];
            },
            [0, 0]
          );
        }
        if (booking.containers) {
          const nContainersByType = new Map<string, number>();
          booking.containers.forEach((container) => {
            const n = nContainersByType.get(container.containerType) || 0;
            nContainersByType.set(container.containerType, n + 1);
          });
          const lines: string[] = [];
          nContainersByType.forEach((nContainers, type) => {
            lines.push(`${type} (QTY ${formatNumber(nContainers)})`);
          });
          containers = lines.join('\n');
        }

        let supplierName = '';
        booking.relatedParties.forEach((rp) => {
          if (rp.partyType === PartyType.Supplier) {
            supplierName = rp.party.name;
          }
        });

        bookingsList.push({
          id: booking.id,
          referenceNumber: { to: '/bookings/' + booking.id, value: booking.referenceNumber },
          bookingDate: booking.createDate,
          weight: weight,
          volume: volume,
          bookingStatus: mapShipmentStatus(booking.status),
          cargoReadyDate: booking.cargoReadyDate,
          pol: booking.logistics.pol ? booking.logistics.pol.code : '',
          polEtd: booking.logistics.polEtd,
          pod: booking.logistics.pod ? booking.logistics.pod.code : '',
          finalDestinationName: booking.logistics.finalDestination?.name ?? '',
          supplierName: supplierName,
          moveType: booking.logistics.moveType ? mapMoveType(booking.logistics.moveType) : '',
          containers: containers,
          relatedParties: booking.relatedParties as GqlRelatedParty[],
        });
      }
      setupStep1(bookingsList);
    },
  });

  React.useEffect(() => {
    if (bookings.length === 0) {
      getBookings();
    }
  }, [bookings.length]);

  if (loading) {
    return <Loading />;
  }

  return (
    <Box width="100%">
      <Grid container spacing={2}>
        <Grid item xs={5} ref={divEl}>
          <Box display="flex" height={height}>
            <EquipmentSelecting />
          </Box>
        </Grid>
        <Grid item xs={7}>
          <Box display="flex" height={height}>
            <EquipmentUtilization />
          </Box>
        </Grid>
        <Grid item xs={12} style={{ display: 'flex', height: 400 }}>
          <BookingsTable />
        </Grid>
      </Grid>
    </Box>
  );
}
