import React, { useRef } from 'react';
import { selector, useRecoilState, useRecoilValue } from 'recoil';
import { RouteComponentProps } from '@reach/router';
import { Box, Typography } from '@material-ui/core';

import { networkItemListState } from './index';
import UWLTableV2 from 'components/g-table/UWLTableV2';
import { GTableColumn } from 'components/g-table/types';
import SearchBar from 'components/SearchBar';
import { newAtom, genKey } from 'recoil-utils/utils';
import { Line } from 'components/StyledComponents';
import { theme } from 'styles';

const columns: GTableColumn<any>[] = [
  { id: 'itemNumber', label: 'Item Number', type: 'string', weight: 150 },
  { id: 'description', label: 'Description', type: 'string', weight: 100 },
  { id: 'htsCode', label: 'HTS Code', type: 'string', weight: 100 },
  { id: 'supplier', label: 'Supplier', type: 'string', weight: 100 },
];

const networkItemsSearchState = newAtom('');

export const filteredNetworkItemsListState = selector({
  key: genKey(),
  get: ({ get }) => {
    const searchField = get<string>(networkItemsSearchState);
    const items = get<any>(networkItemListState);
    const lowerField = searchField.toLowerCase();

    if (searchField === '') {
      return items;
    } else {
      return items.filter(
        (item: any) =>
          item.description.toLowerCase().includes(lowerField) ||
          item.itemNumber.toLowerCase().includes(lowerField) ||
          (item.supplier != null && item.supplier.toLowerCase().includes(lowerField)) ||
          (item.htsCode != null && item.htsCode.toLowerCase().includes(lowerField))
      );
    }
  },
});

interface ItemsProps extends RouteComponentProps {}
function NetworkItems(props: ItemsProps) {
  const [searchField, setSearchField] = useRecoilState(networkItemsSearchState);
  const filteredItems = useRecoilValue(filteredNetworkItemsListState);
  const divContainer = useRef<HTMLDivElement>(null);
  const [tableHeight, setTableHeight] = React.useState(300);

  React.useEffect(() => {
    if (divContainer.current && divContainer.current.offsetParent) {
      setTableHeight(divContainer.current.offsetParent.clientHeight - 260);
    }
  }, [divContainer]);

  return (
    <div ref={divContainer}>
      <Box className="networkItemsTable" display="flex" justifyContent="space-between">
        <Box>
          <Typography variant="h2">Network Items</Typography>
          <Typography variant="body1">Items in this network</Typography>
        </Box>
        <Box width="25%" bgcolor={theme.palette.background.default} padding={1} alignSelf="center">
          <SearchBar
            placeholder="Search Network Items"
            field={searchField}
            updateField={setSearchField}
          />
        </Box>
      </Box>
      <Line height={2} color={theme.palette.primary.main} />

      <Box paddingX={2} paddingBottom={2} height={tableHeight}>
        <UWLTableV2 columns={columns} rows={filteredItems} emptyMessage="No Items" />
      </Box>
    </div>
  );
}

export default NetworkItems;
