import { SearchType } from 'api/GQL_Types';
import { SearchState } from 'components/form/FormInputSearch';
import { newAtom } from 'recoil-utils/utils';

export const HeaderStates = {
  searchType: newAtom<SearchType>(SearchType.Hbl),
  searchText: newAtom<string>(''),
  search: newAtom<SearchState>({
    waiting: false,
    error: null,
    results: [],
  }),
  searchResult: newAtom<{ id: string; value: string } | null>(null),
};
