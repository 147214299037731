import { navigate, RouteComponentProps, Router } from '@reach/router';
import { PermissionCode, useNetworkLazyQuery } from 'api/GQL_Types';
import { auth } from 'app';
import Loading from 'app/Loading';
import ErrorMessage from 'components/ErrorMessage';
import { ManageBody, ManageContainer, ManageContent } from 'components/StyledComponents';
import React from 'react';
import { useRecoilState, useRecoilValue } from 'recoil';
import { AtomGroupCleaner, newAtom } from 'recoil-utils/utils';
import { LinkItem } from 'types/LinkItem';
import ManagePageHeader from '../../components/ManagePageHeader';
import NetworkConnections from './connections';
import NetworkContacts from './contacts';
import EquipmentTypes from './container-types';
import NetworkDetails from './details';
import NetworkItems from './NetworkItems';
import NetworkProfileTypes from './profile-types';
import NetworkRules from './rules';
import { NetworkAdminPageStates, useSetNetworkAdminPageState } from './states';

export interface NetworkOmsMapping {
  key: string;
  displayFieldName: string;
  fieldName: string;
  displayTypeName: string;
  typeName: string;
}

export interface NetworkItem {
  id: string;
  itemNumber: string;
  description: string;
  htsCode: string;
  supplier: string;
}

export const networkItemListState = newAtom<NetworkItem[]>([]);

interface Props extends RouteComponentProps {
  networkId?: string;
}
export default function ManageNetwork(props: Props) {
  const { userContext } = auth.useAuthState();
  const currentNetwork = useRecoilValue(NetworkAdminPageStates.network);
  const setNetworkPage = useSetNetworkAdminPageState();
  const [errorLoadingNetwork, setErrorLoadingNetwork] = useRecoilState(
    NetworkAdminPageStates.errorLoadingNetwork
  );

  const [getNetwork, { data: networkData }] = useNetworkLazyQuery({
    variables: { networkId: props.networkId ?? '' },
    fetchPolicy: 'cache-and-network',
    onCompleted(data) {
      setNetworkPage(data);
      if (!data.network) {
        setErrorLoadingNetwork('Network not found');
        navigate('/admin/networks');
      }
    },
    onError(error) {
      setErrorLoadingNetwork(error + '');
    },
  });

  React.useEffect(() => {
    setNetworkPage(null);
    setErrorLoadingNetwork(null);
    getNetwork();
  }, []);

  if (errorLoadingNetwork) {
    return <ErrorMessage error={errorLoadingNetwork} />;
  }

  if (!currentNetwork) {
    return <Loading />;
  }

  const navLinks: LinkItem[] = [];
  navLinks.push({ name: 'Details', path: '', icon: 'list_alt', subNav: [] });
  navLinks.push({ name: 'Connections', path: 'connections', icon: 'power', subNav: [] });
  if (userContext?.permissionCodes.has(PermissionCode.ProfileTypeRead)) {
    navLinks.push({ name: 'Profile Types', path: 'profile-types', icon: 'person', subNav: [] });
  }
  navLinks.push({ name: 'Contacts', path: 'contacts', icon: 'people', subNav: [] });
  navLinks.push({ name: 'Rules', path: 'rules', icon: 'playlist_add_check', subNav: [] });
  navLinks.push({
    name: 'Equipment Types',
    path: 'container-types',
    icon: 'dns',
    subNav: [],
  });
  navLinks.push({ name: 'Items', path: 'items', icon: 'format_list_numbered', subNav: [] });
  return (
    <ManageContainer>
      <AtomGroupCleaner atomGroup={NetworkAdminPageStates} />
      <ManagePageHeader title={networkData?.network?.name} links={navLinks} />
      <ManageContent>
        <ManageBody>
          <Router>
            <NetworkDetails default path="/" />
            <NetworkConnections path="connections" />
            <NetworkProfileTypes path="profile-types" />
            <NetworkContacts path="contacts" />
            <NetworkRules path="rules" />
            <EquipmentTypes path="container-types" />
            <NetworkItems path="items" />
          </Router>
        </ManageBody>
      </ManageContent>
    </ManageContainer>
  );
}
