import * as React from 'react';
import { DatasetFilterable } from 'types/Dataset';
import { BooleanFilter } from './filter/BooleanFilter';
import { CityStateCountryFilter } from './filter/CityStateCountryFilter';
import { DateFilter } from './filter/DateFilter';
import { SelectFilter } from './filter/SelectFilter';
import { SelectOneOfFilter } from './filter/SelectOneOfFilter';
import { StringFilter } from './filter/StringFilter';

interface DatasetFilterProps<T> {
  filterable: DatasetFilterable;
  value: T;
  disabled: boolean | undefined;
  onValue(v: T): void;
}

export interface DatasetFilterType<T> {
  deserializeValue(filterable: DatasetFilterable, str: string | null | undefined): T;
  serializeValue(filterable: DatasetFilterable, value: T): string | null;

  defaultValue(filterable: DatasetFilterable): T;
  validate(filterable: DatasetFilterable, value: T): string | null;

  RenderInput: React.FC<DatasetFilterProps<T>>;
}

const filterTypes: { [type: string]: DatasetFilterType<any> } = {
  boolean: BooleanFilter,
  'city-state-country': CityStateCountryFilter,
  date: DateFilter,
  select: SelectFilter,
  'select-one-of': SelectOneOfFilter,
  string: StringFilter,
};

export function getFilterType<T = any>(type: string): DatasetFilterType<T> | null {
  return (filterTypes as any)[type] || null;
}
