import { faAddressCard, faShieldAlt } from '@fortawesome/free-solid-svg-icons';
import { Box, Fab, Typography } from '@material-ui/core';
import { navigate, RouteComponentProps } from '@reach/router';
import { PermissionCode } from 'api/GQL_Types';
import { auth } from 'app';
import AdminCardList from 'app/admin/components/AdminCardList';
import { ManagePageHeader_BackStack } from 'app/admin/components/ManagePageHeader';
import SearchBar from 'components/SearchBar';
import { Line } from 'components/StyledComponents';
import React from 'react';
import { selector, useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import { genKey, newAtom } from 'recoil-utils/utils';
import { theme } from 'styles';
import { NetworkAdminPageStates, NetworkAdminProfileType } from '../states';
import { NewProfileTypeDialog } from './NewProfileTypeDialog';
import { useSetupNewProfileTypeDialog } from './NewProfileTypeDialog/states';

const networkProfileTypesSearchState = newAtom<string>('');
const networkProfileTypesListState = newAtom<AdminObj[]>([]);
const filteredNetworkProfileTypesListState = selector({
  key: genKey(),
  get: ({ get }) => {
    const searchField = get(networkProfileTypesSearchState);
    const profileTypes = get(networkProfileTypesListState);
    const lowerField = searchField.toLowerCase();

    if (searchField === '') {
      return profileTypes;
    } else {
      return profileTypes.filter((profileType) =>
        profileType.name.toLowerCase().includes(lowerField)
      );
    }
  },
});

interface AdminObj extends NetworkAdminProfileType {
  permissions: number;
  roles: number;
}

interface Props extends RouteComponentProps {}
export default function NetworkProfileTypes(props: Props) {
  const { userContext } = auth.useAuthState();
  const network = useRecoilValue(NetworkAdminPageStates.network);

  const [backStack, setBackStack] = useRecoilState(ManagePageHeader_BackStack);
  const setNetworkProfileTypes = useSetRecoilState(networkProfileTypesListState);
  const [searchField, setSearchField] = useRecoilState(networkProfileTypesSearchState);
  const filteredProfileTypes = useRecoilValue(filteredNetworkProfileTypesListState);

  React.useEffect(() => {
    if (network?.profileTypes) {
      setNetworkProfileTypes(
        network.profileTypes.map((pt) => {
          return {
            ...pt,
            permissions: pt.permissionCodes.length,
            roles: pt.defaultRoles.length,
          };
        })
      );
    }
  }, [network]);

  return (
    <div>
      <Box display="flex" justifyContent="space-between">
        <Box>
          <Typography variant="h2">Profile Types</Typography>
          <Typography variant="body1">Profile types available to this network</Typography>
        </Box>
        <Box width="25%" bgcolor={theme.palette.background.default} padding={1} alignSelf="center">
          <SearchBar
            placeholder="Search Profile Types"
            field={searchField}
            updateField={setSearchField}
          />
        </Box>
      </Box>
      <Line height={1} />
      <Box paddingBottom={4}>
        <AdminCardList<AdminObj>
          list={filteredProfileTypes}
          iconList={[
            {
              name: 'Permissions',
              key: 'permissions',
              icon: faShieldAlt,
              color: theme.palette.secondary.light,
            },
            {
              name: 'Roles',
              key: 'roles',
              icon: faAddressCard,
              color: theme.palette.blueGreen.main,
            },
          ]}
          onManageClicked={(item) => {
            const copy = [...backStack];
            copy.push({ label: network?.name + '', path: 'admin/networks/' + network?.id });
            setBackStack(copy);
            navigate('admin/profile-types/' + item.id);
          }}
        />
      </Box>
      <NewProfileTypeDialog />
      {userContext?.permissionCodes.has(PermissionCode.ProfileTypeCreate) && (
        <Box position="absolute" bottom="48px" right="36px">
          <CreateButton />
        </Box>
      )}
    </div>
  );
}

function CreateButton() {
  const setDialog = useSetupNewProfileTypeDialog();
  const network = useRecoilValue(NetworkAdminPageStates.network);

  return (
    <Fab
      color="primary"
      aria-label="add"
      variant="extended"
      disabled={!network}
      onClick={() => {
        if (!network) return;
        setDialog({
          networkId: network.id,
          name: '',
          description: '',
          permissionCodes: [],
          notificationCodes: [],
          dashFeatureCodes: [],
        });
      }}
    >
      <i className="material-icons" style={{ marginRight: '8px' }}>
        {'add'}
      </i>
      Create New Profile Type
    </Fab>
  );
}
