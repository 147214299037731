import { gql } from '@apollo/client/core';

export const ProfileQuery = gql`
  query Profile($profileId: ID!) {
    profile(profileId: $profileId) {
      id
      name
      profileCode
      nameAliases
      profileCodeAliases
      isControllingClient
      logo {
        id
        imageType
        fileName
        fileType
        fileSize
        uploadDate
        url
      }
      needsUpdate
      network {
        id
        name
        connectedProfiles {
          id
          name
          isControllingClient
        }
        profileTypes {
          id
          name
        }
      }
      profileType {
        id
        name
        permissionCodes
        notificationCodes
        dashboardFeatureCodes
      }
      locations {
        id
        name
        code
        nameAliases
        codeAliases
        addressLine1
        addressLine2
        city
        state
        postalCode
        relatedPort {
          code
          name
        }
        country
        companyPhone
        companyUrl
        needsUpdate
        locationTypes
      }
      contacts {
        id
        firstName
        lastName
        title
        email
        phoneMain
        extension
        phoneAlternative
        phoneCell
        extension
        isUserEnabled
        notificationFrequency
        location {
          id
          name
        }
        role {
          id
          name
        }
        isMainContact
        needsUpdate
      }
      relatedProfiles {
        id
        partyType
        profile {
          id
          name
          profileCode
          profileType {
            id
            name
          }
          roles {
            id
          }
          locations {
            id
            name
          }
          contacts {
            id
            firstName
            lastName
          }
          relatedProfiles {
            id
          }
          network {
            name
          }
        }
      }
      roles {
        id
        name
        description
        isDefaultRole
        permissionCodes
        notificationCodes
        dashboardFeatureCodes
        documentTypesCanView
      }
    }
  }
`;

export const ProfilesQuery = gql`
  query Profiles {
    profiles {
      id
      name
      profileCode
      profileType {
        name
      }
      logo {
        url
      }
      needsUpdate
      locations {
        id
        name
      }
      contacts {
        id
        firstName
        lastName
      }
      roles {
        id
        name
      }
      network {
        name
      }
    }
  }
`;

export const NewProfileContactMutation = gql`
  mutation NewProfileContact($input: NewProfileContactInput!) {
    newProfileContact(input: $input) {
      success
    }
  }
`;

export const NewProfileRoleMutation = gql`
  mutation NewProfileRole($input: NewProfileRoleInput!) {
    newProfileRole(input: $input) {
      success
    }
  }
`;

export const NewProfileRelationMutation = gql`
  mutation NewProfileRelation($input: NewProfileRelationInput!) {
    newProfileRelation(input: $input) {
      success
    }
  }
`;

export const UpdateProfileRelationMutation = gql`
  mutation UpdateProfileRelation($input: UpdateProfileRelationInput!) {
    updateProfileRelation(input: $input) {
      success
    }
  }
`;

export const UpdateProfileMutation = gql`
  mutation UpdateProfile($input: UpdateProfileInput!) {
    updateProfile(input: $input) {
      success
      message
    }
  }
`;

export const RemoveProfileRelationMutation = gql`
  mutation RemoveProfileRelation($input: RemoveProfileRelationInput!) {
    removeProfileRelation(input: $input) {
      success
    }
  }
`;

export const MergeProfileMutation = gql`
  mutation MergeProfile($input: MergeProfileInput!) {
    mergeProfile(input: $input) {
      success
      message
    }
  }
`;
