import { Link } from '@reach/router';
import scrollbarSize from 'dom-helpers/scrollbarSize';
import React, { CSSProperties } from 'react';
import { CellMeasurer, CellMeasurerCache, Grid, Index, OnScrollParams } from 'react-virtualized';
import { atom, useRecoilValue, useResetRecoilState } from 'recoil';
import { formatDate } from 'utils/Dates';
import { formatNumberDecimalPlaces } from 'utils/Numbers';
import {
  fromDataIndicesToTableIndices,
  fromTableIndexToDataIndex,
  fromTableIndicesToDataIndices,
  getRenderedRows,
  indexCols,
} from '../core';
import { ExpandableRow, GTableColumn, GTableColumnType, RenderCell, RenderCells } from '../types';

interface Props<T> {
  width: number;
  height: number;
  rows: any[];
  columns: GTableColumn<T>[];
  styles?: CSSProperties;
  renderCell?: RenderCell<T>;
  renderCells?: RenderCells<T>;
  expandable?: ExpandableRow<T>;
  onRowClick?: (data: T) => void;
  onCellClick?: (data: T) => void;
  scrollLeft?: number;
  scrollTop?: number;
  onScroll?: (params: OnScrollParams) => void;
  noContentRenderer?: () => React.ReactNode;
  isHeader?: boolean;
  debugId?: string;
  linkInTab?: boolean;
  onHeightEstimated?: (height: number) => void;
}

const defaultHeight = 44.44;

export const contentHeightAtom = atom({
  key: 'contentHeightAtom',
  default: {
    id: '',
    height: 0,
  },
});

export default function BodyGrid<T extends {}>(props: Props<T>) {
  const contentHeight = useRecoilValue(contentHeightAtom);
  const resetContentHeight = useResetRecoilState(contentHeightAtom);
  const [realHeightCache, setHeightCache] = React.useState<{ [key: string]: number }>({});
  const [cache] = React.useState(
    new CellMeasurerCache({
      fixedWidth: true,
      defaultHeight: defaultHeight,
    })
  );
  const [grid, setGrid] = React.useState<Grid | null>(null);
  const [renderedRows, setRenderedRows] = React.useState<any[]>(props.rows);
  const [expandedRows, setExpandedRows] = React.useState<number[]>([]);

  const totalWeight = props.columns.reduce((sum, current) => sum + current.weight, 0);
  const indexedCols = indexCols(props.columns);
  const [count, setCount] = React.useState(props.rows.length);

  React.useEffect(() => {
    cache.clearAll();
    grid?.forceUpdate();
    calculateRHC();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.width]);

  React.useEffect(() => {
    if (contentHeight.id) {
      realHeightCache[contentHeight.id] = contentHeight.height + 24;
      resetContentHeight();
      cache.clearAll();
      grid?.forceUpdate();
      calculateRHC();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [contentHeight]);

  React.useEffect(() => {
    setCount(props.rows.length);
    setExpandedRows([]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.rows]);

  React.useEffect(() => {
    const renderedRows: any[] = getRenderedRows(props.rows, expandedRows);
    setCount(renderedRows.length);
    setRenderedRows(renderedRows);
    cache.clearAll();
    grid?.forceUpdate();
    calculateRHC();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [expandedRows, props.rows]);

  React.useEffect(() => {
    calculateRHC();
    cache.clearAll();
    grid?.forceUpdate();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [renderedRows]);

  React.useEffect(() => {
    if (props.onHeightEstimated) {
      const estimate = Object.values(realHeightCache).reduce(
        (prev: number, current: number) => prev + current,
        0
      );
      if (estimate !== 0 && estimate !== defaultHeight) {
        props.onHeightEstimated(estimate);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [realHeightCache]);

  function calculateRHC() {
    const realHC: any = { ...realHeightCache };

    for (let i = 0; i < renderedRows.length; i++) {
      const h = cache.rowHeight({ index: i });
      if (!Object.keys(realHeightCache).includes(renderedRows[i].id) && h !== defaultHeight) {
        realHC[renderedRows[i].id] = h;
      }
    }
    if (Object.keys(realHeightCache).length !== Object.keys(realHC).length) {
      setHeightCache(realHC);
    }
  }

  calculateRHC();

  return (
    <Grid
      ref={(refGrid) => {
        setGrid(refGrid);
      }}
      scrollTop={props.scrollTop}
      noContentRenderer={props.noContentRenderer}
      rowHeight={(row: Index) => {
        const currentItem = renderedRows[row.index];

        if (!currentItem) {
          return 1;
        }
        if (!realHeightCache[currentItem.id]) {
          return defaultHeight;
        }
        if (currentItem.id.includes('-content')) {
          return realHeightCache[currentItem.id];
        }
        return realHeightCache[currentItem.id];
      }}
      columnCount={props.columns.length}
      height={totalWeight < props.width ? props.height : props.height + scrollbarSize()}
      width={props.width}
      onScroll={props.onScroll ?? undefined}
      scrollLeft={props.onScroll ? undefined : props.scrollLeft}
      style={{
        overflowX: totalWeight < props.width ? 'hidden' : undefined,
        overflowY: 'auto',
      }}
      overscanIndicesGetter={(data) => {
        if (data.direction === 'horizontal') {
          return { overscanStartIndex: 0, overscanStopIndex: data.stopIndex };
        }
        let start = data.startIndex;
        let end = data.stopIndex;

        if (end < data.cellCount - 1) {
          let diff = data.cellCount - 1 - end;
          if (diff > 1) {
            diff = 1;
          }
          end = end + diff;
        }

        if (start > 0) {
          let diff = start - 1;
          if (diff < 0) {
            start = 0;
          } else {
            start = diff;
          }
        }

        return {
          overscanStartIndex: start,
          overscanStopIndex: end,
        };
      }}
      columnWidth={(params: Index) => {
        return totalWeight < props.width
          ? (indexedCols[params.index].weight / totalWeight) * props.width
          : indexedCols[params.index].weight;
      }}
      cellRenderer={(cellProps) => {
        const gColumn = indexedCols[cellProps.columnIndex];
        let renderCell = props.renderCell && props.renderCell[gColumn.id];
        let renderCells = props.renderCells;

        const controlRenderCell =
          props.expandable &&
          gColumn.id === props.expandable.id &&
          props.expandable.controlRenderCell;

        if (controlRenderCell) {
          renderCell = undefined;
          renderCells = undefined;
        }

        const rowValue = renderedRows[cellProps.rowIndex] as any;
        if (!rowValue) {
          return null;
        }

        if (Object.keys(rowValue).includes('_isCollapsibleContent')) {
          if (cellProps.columnIndex === 0) {
            return (
              <CellMeasurer
                cache={cache}
                columnIndex={cellProps.columnIndex}
                key={cellProps.key}
                parent={cellProps.parent}
                rowIndex={cellProps.rowIndex}
              >
                <div
                  key={cellProps.key}
                  style={{
                    ...cellProps.style,
                    width: totalWeight < props.width ? props.width - scrollbarSize() : totalWeight,
                    left: 0,
                    backgroundColor: '#F0F0F0',
                  }}
                >
                  {props.expandable && props.expandable.content(rowValue)}
                </div>
              </CellMeasurer>
            );
          } else {
            return null;
          }
        }

        return (
          <CellMeasurer
            cache={cache}
            columnIndex={cellProps.columnIndex}
            key={cellProps.key}
            parent={cellProps.parent}
            rowIndex={cellProps.rowIndex}
          >
            <div
              key={cellProps.key}
              style={{
                ...cellProps.style,
                ...props.styles,
                width:
                  totalWeight < props.width
                    ? props.width * (gColumn.weight / totalWeight)
                    : gColumn.weight,
              }}
              onClick={() => {
                if (props.onRowClick) {
                  props.onRowClick(rowValue);
                }
                if (props.onCellClick) {
                  props.onCellClick(rowValue[gColumn.id]);
                }
              }}
            >
              {/* Render Control Cell */}

              {controlRenderCell &&
                controlRenderCell({
                  ...rowValue,

                  _isCollapsed: !fromDataIndicesToTableIndices(expandedRows).includes(
                    cellProps.rowIndex
                  ),

                  _toggleCollapse: () => {
                    let currentExpandedRows = [...expandedRows];
                    // TO ACTUAL INDICES
                    currentExpandedRows = fromDataIndicesToTableIndices(currentExpandedRows);

                    if (currentExpandedRows.includes(cellProps.rowIndex)) {
                      // REMOVE COLLAPSE COLUMN

                      const dataIndex = fromTableIndexToDataIndex(
                        cellProps.rowIndex,
                        currentExpandedRows
                      );
                      const dataIndices = fromTableIndicesToDataIndices(currentExpandedRows);
                      const index = dataIndices.indexOf(dataIndex, 0);
                      if (index > -1) {
                        dataIndices.splice(index, 1);
                        const currentExpandedRowsD = [...dataIndices].sort((n1, n2) => n2 - n1);
                        setExpandedRows(currentExpandedRowsD);
                      }
                    } else {
                      // ADD COLLAPSE COLUMN
                      const dataIndex = fromTableIndexToDataIndex(
                        cellProps.rowIndex,
                        currentExpandedRows
                      );
                      const currentExpandedRowsD = [
                        ...fromTableIndicesToDataIndices(currentExpandedRows),
                        dataIndex,
                      ].sort((n1, n2) => n2 - n1);
                      setExpandedRows(currentExpandedRowsD);
                    }
                  },
                })}
              {/* Regular RenderCell */}
              {renderCell
                ? renderCell(rowValue)
                : renderCells
                ? renderCells(gColumn, rowValue)
                : handleTypeRender(rowValue[gColumn.id], gColumn.type, gColumn.id, props.linkInTab)}
            </div>
          </CellMeasurer>
        );
      }}
      deferredMeasurementCache={cache}
      rowCount={count}
    />
  );
}

function handleTypeRender(value: any, type: GTableColumnType, id: string, tabs?: boolean) {
  switch (type) {
    case 'date':
      if (Array.isArray(value)) {
        let fmt = formatDate(value[0]);
        for (let i = 1; i < value.length; i++) {
          if (fmt !== formatDate(value[i])) {
            return 'Various';
          }
        }
        return fmt;
      }
      return formatDate(value);

    case 'string':
      if (typeof value !== 'string') {
        if (value === null || value === undefined) {
          return '';
        }
        if (process.env.NODE_ENV === 'production') {
          console.error(`Value ${value} in key ${id} is not of type string`);
          return value + '';
        } else {
          throw new Error(`Value ${value} in key ${id} is not of type string`);
        }
      }
      return value;
    case 'link':
      return createLink(value, tabs);
    case 'currency':
      return (
        <div style={{ width: 'inherit', textAlign: 'right' }}>
          $ {formatNumberDecimalPlaces(value, 2, 2)}
        </div>
      );
    default:
      return value;
  }
}

function createLink(
  link: { to: string; value: string } | [{ to: string; value: string }],
  tabs: boolean | undefined
) {
  if (Array.isArray(link)) {
    return (
      <div style={{ display: 'flex', flexDirection: 'column' }}>
        {link.map((v: any, i: number) => {
          return (
            <div key={i} style={{ marginBottom: '4px' }}>
              {tabs && v.to ? (
                <a href={v.to} target="_blank" rel="noopener noreferrer">
                  {v.value}
                </a>
              ) : !tabs && v.to ? (
                <Link to={v.to}>{v.value}</Link>
              ) : (
                <>{v.value}</>
              )}
            </div>
          );
        })}
      </div>
    );
  } else {
    return (
      <>
        {tabs && link.to ? (
          <a href={link.to} target="_blank" rel="noopener noreferrer">
            {link.value}
          </a>
        ) : !tabs && link.to ? (
          <Link to={link.to}>{link.value}</Link>
        ) : (
          <>{link.value}</>
        )}
      </>
    );
  }
}
