import { Box } from '@material-ui/core';
import CompositePanel from 'components/CompositePanel';
import React from 'react';
import BookingTabs from './booking-tabs';
import DashboardBookingsHeading from './heading';

interface Props {
  containerHeight: number;
  containerWidth: number;
  rowHeight?: number;
}

export default function DashboardBookings(props: Props) {
  return (
    <CompositePanel>
      <DashboardBookingsHeading />
      <Box display="flex" flexGrow={1}>
        <BookingTabs
          containerHeight={props.containerHeight}
          containerWidth={props.containerWidth}
        />
      </Box>
    </CompositePanel>
  );
}
