import { Box, Grid } from '@material-ui/core';
import React from 'react';
import NewBookingStep2Equipment from './NewBookingStep2Equipment';
import NewBookingStep2Remarks from './NewBookingStep2Remarks';

interface Props {}

export default function EquipmentRemark(props: Props) {
  return (
    <Grid container spacing={2}>
      <Grid item sm={12} md={6}>
        <Box display="flex">
          <NewBookingStep2Equipment />
        </Box>
      </Grid>
      <Grid item sm={12} md={6}>
        <Box display="flex" height={'100%'}>
          <NewBookingStep2Remarks />
        </Box>
      </Grid>
    </Grid>
  );
}
