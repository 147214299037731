import React from 'react';
import { RouteComponentProps } from '@reach/router';

import AdminDashboard from 'app/admin/Dashboard';
import Profiles from 'app/admin/profiles';
import ProfilesTypes from 'app/admin/profile-types';
import Users from 'app/admin/users';
import Networks from './networks';
import { FlexGrowRouter } from 'components/StyledComponents';

interface AdminProps extends RouteComponentProps {}
function Admin(props: AdminProps) {
  return (
    <FlexGrowRouter>
      <AdminDashboard path="/" />
      <ProfilesTypes path="profile-types/*" />
      <Profiles path="profiles/*" />
      <Users path="users/*" />
      <Networks path="networks/*" />
    </FlexGrowRouter>
  );
}

export default Admin;
