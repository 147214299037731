import { Box, InputAdornment, TextField, Typography } from '@material-ui/core';
import { Link } from '@reach/router';
import { GqlVanPosition, PartyType } from 'api/GQL_Types';
import Panel from 'components/Panel';
import { UWLTable } from 'components/UWLTable/UWLTable';
import React from 'react';
import { useRecoilValue } from 'recoil';
import { UWLTableColumn } from 'types/UWLTable';
import { EquipmentPageStates } from './states';

interface EquipmentLineItem {
  id: string;
  poId: string;
  hbl: string;
  hblId: string;
  supplier: string;
  orderNumber: string;
  itemNumber: string;
  description: string;
  quantity: number;
  shippedQty: number;
  shippedCtns: number;
  unitPrice: number;
  weight: number;
  volume: number;
  commInvTotal: number;
  freightCost: number;
}

const columns: UWLTableColumn<any>[] = [
  { id: 'hbl', label: 'HBL', type: 'string' },
  { id: 'supplier', label: 'Supplier', type: 'string' },
  { id: 'orderNumber', label: 'PO', type: 'string' },
  { id: 'itemNumber', label: 'Item / Sku', type: 'string' },
  { id: 'description', label: 'Description', type: 'string' },
  { id: 'quantity', label: 'Order QTY', type: 'number' },
  { id: 'shippedQty', label: 'Shipped QTY', type: 'number' },
  { id: 'shippedCtns', label: 'Shipped CTNS', type: 'number' },
  { id: 'unitPrice', label: 'Unit Cost', type: 'number' },
  { id: 'volume', label: 'Volume', type: 'number' },
  { id: 'weight', label: 'Weight', type: 'number' },
  { id: 'commInvTotal', label: 'Comm Inv Total', type: 'number' },
  { id: 'freightCost', label: 'Freight Cost', type: 'number' },
];

export default function LineItemsPanel() {
  const container = useRecoilValue(EquipmentPageStates.container);
  const [lineItems, setLineItems] = React.useState<any[]>([]);

  React.useEffect(() => {
    // Combine line items together
    const linesObject: { [id: string]: EquipmentLineItem } = {};
    const lines: EquipmentLineItem[] = [];
    const vanPositions: GqlVanPosition[] = container?.vanPositions ?? [];
    for (const vanPosition of vanPositions) {
      const uniqueId = vanPosition.id;

      if (!linesObject.hasOwnProperty(uniqueId)) {
        linesObject[uniqueId] = {
          id: uniqueId,
          poId: vanPosition.packLine.orderLine.purchaseOrder.id,
          hbl: vanPosition.packLine.booking.hbl?.referenceNumber ?? '',
          hblId: vanPosition.packLine.booking.hbl?.id ?? '',
          supplier:
            vanPosition.packLine.booking.relatedParties.find(
              (rp) => rp.partyType === PartyType.Supplier
            )?.party.name ?? '',
          orderNumber: vanPosition.packLine.orderLine.purchaseOrder.poNumber ?? '',
          itemNumber: vanPosition.packLine.orderLine.itemNumber ?? '',
          description: vanPosition.packLine.orderLine.itemDescription,
          quantity: vanPosition.packLine.orderLine.openOrder.orderQty,
          shippedQty: vanPosition.piecesQty,
          shippedCtns: vanPosition.cartonsQty,
          unitPrice: vanPosition.packLine.orderLine.unitPrice ?? 0,
          weight: vanPosition.weight ?? 0,
          volume: vanPosition.volume ?? 0,
          commInvTotal: vanPosition.commercialInvoiceTotal ?? 0,
          freightCost: 0, // tbd
        };
      } else {
        linesObject[uniqueId].quantity += vanPosition.piecesQty ?? 0;
        linesObject[uniqueId].shippedCtns += 0; // For now
        linesObject[uniqueId].weight += vanPosition.weight ?? 0;
        linesObject[uniqueId].volume += vanPosition.volume ?? 0;
      }
    }
    for (let value of Object.values(linesObject)) {
      lines.push(value);
    }
    setLineItems(lines);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [container]);

  return (
    <Panel title="Line Items">
      <Box margin={2} maxHeight={300} overflow="auto">
        <UWLTable
          rowId="id"
          columns={columns}
          sizeMedium
          isLoading={!container}
          rows={lineItems}
          emptyMessage="No Items Available"
          renderCell={{
            orderNumber(row) {
              return <Link to={'/purchase-orders/' + row.poId}>{row.orderNumber}</Link>;
            },
            hbl(row) {
              if (row.hbl) {
                return <Link to={'/hbl/' + row.hblId}>{row.hbl}</Link>;
              } else {
                return <p>Not Assigned</p>;
              }
            },
            unitPrice(row) {
              return (
                <TextField
                  id={row.id + 'unitPrice'}
                  value={row.unitPrice.toFixed(2)}
                  disabled
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <Typography variant="caption">$</Typography>
                      </InputAdornment>
                    ),
                    endAdornment: (
                      <InputAdornment position="start">
                        <Typography
                          variant="caption"
                          style={{ marginRight: '-8px', marginLeft: '-16px' }}
                        >
                          USD
                        </Typography>
                      </InputAdornment>
                    ),
                    disableUnderline: true,
                    style: {
                      color: '#000',
                      maxWidth: '100%',
                    },
                  }}
                  inputProps={{
                    style: {
                      textAlign: 'start',
                    },
                  }}
                  type="number"
                />
              );
            },
            volume(row) {
              return (
                <TextField
                  id={row.id + 'volume'}
                  value={row.volume.toFixed(2)}
                  disabled
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <Typography variant="caption" style={{ lineHeight: '1.5' }}>
                          M3
                        </Typography>
                      </InputAdornment>
                    ),
                    disableUnderline: true,
                    style: {
                      color: '#000',
                      maxWidth: '100px',
                    },
                  }}
                  inputProps={{
                    style: {
                      textAlign: 'end',
                      marginRight: '-16px',
                    },
                  }}
                  type="number"
                />
              );
            },
            weight(row) {
              return (
                <TextField
                  id={row.id + 'weight'}
                  value={row.weight.toFixed(2)}
                  disabled
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <Typography variant="caption" style={{ lineHeight: '1.5' }}>
                          KG
                        </Typography>
                      </InputAdornment>
                    ),
                    disableUnderline: true,
                    style: {
                      color: '#000',
                      maxWidth: '100px',
                    },
                  }}
                  inputProps={{
                    style: {
                      textAlign: 'end',
                      marginRight: '-16px',
                    },
                  }}
                  type="number"
                />
              );
            },
            commInvTotal(row) {
              return (
                <TextField
                  id={row.id + 'commInvTotal'}
                  value={row.commInvTotal.toFixed(2)}
                  disabled
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <Typography variant="caption">$</Typography>
                      </InputAdornment>
                    ),
                    endAdornment: (
                      <InputAdornment position="end">
                        <Typography variant="caption" style={{ marginRight: '24px' }}>
                          USD
                        </Typography>
                      </InputAdornment>
                    ),
                    disableUnderline: true,
                    style: {
                      color: '#000',
                      maxWidth: '100%',
                    },
                  }}
                  inputProps={{
                    style: {
                      textAlign: 'start',
                    },
                  }}
                  type="number"
                />
              );
            },
            freightCost(row) {
              return (
                <TextField
                  id={row.id + 'freightCost'}
                  value={row.freightCost.toFixed(2)}
                  disabled
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <Typography variant="caption">$</Typography>
                      </InputAdornment>
                    ),
                    endAdornment: (
                      <InputAdornment position="end">
                        <Typography variant="caption" style={{ marginRight: '8px' }}>
                          USD
                        </Typography>
                      </InputAdornment>
                    ),
                    disableUnderline: true,
                    style: {
                      color: '#000',
                      maxWidth: '100%',
                    },
                  }}
                  inputProps={{
                    style: {
                      textAlign: 'start',
                    },
                  }}
                  type="number"
                />
              );
            },
          }}
        />
      </Box>
    </Panel>
  );
}
