import { Box, Typography } from '@material-ui/core';
import { GqlLocation, GqlRelatedParty } from 'api/GQL_Types';
import AtomicAutocompleteV2 from 'components/atomic/AtomicAutocompleteV2';
import { DateRangePreset, FormInputDateRange } from 'components/form/FormInputDateRange';
import { FormItem } from 'components/form/FormItem';
import UniversalPopover from 'components/UniversalPopover';
import { useRecoilState, useRecoilValue } from 'recoil';
import { NewConsolidationStates } from '../../states';
import ApplyFiltersButton from './ApplyFiltersButton';
import ClearFiltersButton from './ClearFiltersButton';

interface Props {
  anchorEl: any;
  openPopover: boolean;
  onClose: () => void;
}

export default function BookingsFiltersPopover(props: Props) {
  const bookings = useRecoilValue(NewConsolidationStates.step1.bookings);
  const filters = useRecoilValue(NewConsolidationStates.filters.filtersLength);
  const [filterCargoReadyDate, setFilterCargoReadyDate] = useRecoilState(
    NewConsolidationStates.filters.filterCargoReadyDate
  );
  const [filterEtd, setFilterEtd] = useRecoilState(NewConsolidationStates.filters.filterEtd);
  const relatedPartiesMap: { [key: string]: GqlRelatedParty } = {};
  const supplierList: string[] = [];
  const polList: string[] = [];
  const deliveryTypeList: string[] = [];
  const equipmentTypeList: string[] = [];

  for (const booking of bookings) {
    if (!supplierList.includes(booking.supplierName)) {
      supplierList.push(booking.supplierName);
    }
    if (!polList.includes(booking.pol)) {
      polList.push(booking.pol);
    }
    if (!deliveryTypeList.includes(booking.moveType)) {
      deliveryTypeList.push(booking.moveType);
    }
    if (!equipmentTypeList.includes(booking.containers)) {
      const containers = booking.containers.split(' ');
      equipmentTypeList.push(containers[0]);
    }
    for (const rp of booking.relatedParties) {
      relatedPartiesMap[rp.party.id] = rp;
    }
  }

  const locationNamesInPackLines: string[] = [];
  for (const booking of bookings) {
    locationNamesInPackLines.push(booking.finalDestinationName);
    for (const rp of booking.relatedParties) {
      relatedPartiesMap[rp.party.id] = rp;
    }
  }

  const supplierOptions = Object.values(relatedPartiesMap).filter((rp) =>
    supplierList.includes(rp.party.name ?? '')
  );

  const locations: GqlLocation[] = Object.values(relatedPartiesMap).reduce(
    (locationArray: GqlLocation[], rp) => {
      return locationArray.concat(rp.party.locations);
    },
    []
  );

  return (
    <UniversalPopover
      popoverId="new-booking-popover"
      open={props.openPopover}
      anchorEl={props.anchorEl}
      onClose={() => {
        props.onClose();
      }}
      anchorOriginVertical={20}
      anchorOriginHorizontal={0}
    >
      <Box padding={1} width={window.innerWidth * 0.25} display="flex" flexDirection="column">
        <Box display="flex" justifyContent="space-between" margin={2}>
          <Box marginRight={3} display="flex" alignItems="center">
            <Typography variant="h3">Filters</Typography>
          </Box>
          <Box marginLeft={3}>
            <Box display="flex" alignItems="center">
              <Box marginRight={1}>
                <Typography variant="h4">
                  <i>Filters Applied:</i>
                </Typography>
              </Box>
              <Typography variant="h3">{filters}</Typography>
            </Box>
          </Box>
        </Box>
        <Box paddingLeft={3} paddingY={2}>
          <AtomicAutocompleteV2<GqlLocation>
            state={NewConsolidationStates.filters.filterDestination}
            label={'Destination'}
            optionsList={locations.filter((loc) =>
              locationNamesInPackLines.includes(loc.name ?? '')
            )}
            displayResolver={(value: GqlLocation) => {
              return value.name ?? 'N/A';
            }}
          />
          <FormItem>
            <FormInputDateRange
              label="Cgo Rdy Date"
              presets={dateRangePresets}
              clearable
              value={filterCargoReadyDate}
              onValue={setFilterCargoReadyDate}
            />
          </FormItem>
          <AtomicAutocompleteV2
            state={NewConsolidationStates.filters.filterPol}
            label={'POL'}
            optionsList={polList}
            displayResolver={(value: string) => {
              return value;
            }}
          />
          <FormItem>
            <FormInputDateRange
              label="ETD"
              presets={dateRangePresets}
              clearable
              value={filterEtd}
              onValue={setFilterEtd}
            />
          </FormItem>
          <AtomicAutocompleteV2
            state={NewConsolidationStates.filters.filterEqptType}
            label={'Equipment Type'}
            optionsList={equipmentTypeList}
            displayResolver={(value: string) => {
              return value;
            }}
          />
          <AtomicAutocompleteV2
            state={NewConsolidationStates.filters.filterDeliveryType}
            label={'Delivery Type'}
            optionsList={deliveryTypeList}
            displayResolver={(value: string) => {
              return value;
            }}
          />
          <AtomicAutocompleteV2<GqlRelatedParty>
            state={NewConsolidationStates.filters.filterSupplier}
            label={'Supplier'}
            optionsList={supplierOptions}
            displayResolver={(value: GqlRelatedParty) => {
              return value.party.name;
            }}
          />
          <Box
            display="flex"
            flexDirection="row"
            justifyContent="space-between"
            margin="8px 24px 0 0"
          >
            <ClearFiltersButton />
            <ApplyFiltersButton />
          </Box>
        </Box>
      </Box>
    </UniversalPopover>
  );
}

export const dateRangePresets: DateRangePreset[] = [
  { type: 'mtd', label: 'This Month' },
  { type: 'next-n-days', label: 'This Week (+7d)', nDays: 7 },
  { type: 'next-n-days', label: 'Next 30 Days', nDays: 30 },
];
