import React from 'react';
import { Box, Typography } from '@material-ui/core';
import { GTableColumn } from 'components/g-table/types';
import UWLTableV2 from 'components/g-table/UWLTableV2';
import TableExportWindowGroup from 'components/TableExportWindowGroup';
import {
  DashboardContainer,
  transformContainerToDashboardContainerDisplay,
} from 'app/dashboard/states';
import { GqlContainer } from 'api/GQL_Types';

const containerColumns: GTableColumn<DashboardContainer>[] = [
  { id: 'equipmentNumber', label: 'Equipment #', type: 'link', weight: 1 },
  { id: 'equipmentType', label: 'Equipment Type', type: 'string', weight: 1 },
  { id: 'bookingNumber', label: 'Booking #', type: 'link', weight: 1 },
  { id: 'bookingDate', label: 'Booking Date', type: 'date', weight: 1 },
  { id: 'status', label: 'Status', type: 'string', weight: 1 },
  { id: 'cargoReadyDate', label: 'CGO RDY Date', type: 'date', weight: 1 },
  { id: 'revisedCargoReadyDate', label: 'REV CGO RDY Date', type: 'date', weight: 1 },
  { id: 'pos', label: 'PO #', type: 'link', weight: 1 },
  { id: 'shipper', label: 'Shipper', type: 'string', weight: 1 },
  { id: 'origin', label: 'Origin', type: 'string', weight: 1 },
  { id: 'destination', label: 'Destination', type: 'string', weight: 1 },
];
interface Props {
  popoverLabel: string;
  data: GqlContainer[];
}

export default function ContainersPopoverContent(props: Props) {
  const displayData = transformContainerToDashboardContainerDisplay(props.data);

  return (
    <Box padding={3} width={window.innerWidth * 0.9} display="flex" flexDirection="column">
      <Box display="flex" justifyContent="space-between" marginBottom={2}>
        <Box marginRight={3} display="flex" alignItems="center">
          <Typography variant="h3">{props.popoverLabel}</Typography>
          <TableExportWindowGroup
            label="Containers"
            rows={displayData}
            columns={containerColumns}
          />
        </Box>
        <Box display="flex" alignItems="center">
          <Box marginRight={1}>
            <Typography variant="h4">
              <i>Total Containers:</i>
            </Typography>
          </Box>
          <Typography variant="h3">{props.data.length}</Typography>
        </Box>
      </Box>
      <UWLTableV2
        rows={displayData}
        columns={containerColumns}
        emptyMessage="No Containers"
        maxHeight={500}
      />
    </Box>
  );
}
