import React from 'react';
import { Button } from '@material-ui/core';
import { GqlUpdateBookingLogisticsInput, useUpdateBookingLogisticsMutation } from 'api/GQL_Types';
import { useRecoilValue } from 'recoil';
import { BookingQuery } from 'api/queries/bookingQueries';
import { VesselsQuery } from 'api/queries/logisticsQueries';
import { useSnackbar } from 'notistack';
import { BookingPageStates } from '../../states';

interface Props {
  onClick: Function;
}

export default function EditLogisticsSaveButton(props: Props) {
  const { enqueueSnackbar } = useSnackbar();
  const booking = useRecoilValue(BookingPageStates.booking);
  const confirmationNumber = useRecoilValue(BookingPageStates.editLogistics.confirmationNumber);
  const confirmationDate = useRecoilValue(BookingPageStates.editLogistics.confirmationDate);
  const carrier = useRecoilValue(BookingPageStates.editLogistics.carrier);
  const carrierConfirmationNumber = useRecoilValue(
    BookingPageStates.editLogistics.carrierConfirmationNumber
  );
  const contract = useRecoilValue(BookingPageStates.editLogistics.contract);
  const contractType = useRecoilValue(BookingPageStates.editLogistics.contractType);

  //Hbl Mbl
  const hblNumber = useRecoilValue(BookingPageStates.editLogistics.hbl);
  const hblPaymentType = useRecoilValue(BookingPageStates.editLogistics.hblPaymentType);
  const hblReleaseType = useRecoilValue(BookingPageStates.editLogistics.hblReleaseType);
  const mblNumber = useRecoilValue(BookingPageStates.editLogistics.mbl);
  const mblPaymentType = useRecoilValue(BookingPageStates.editLogistics.mblPaymentType);
  const mblReleaseType = useRecoilValue(BookingPageStates.editLogistics.mblReleaseType);

  const cfsCutoff = useRecoilValue(BookingPageStates.editLogistics.cfsCutoff);
  const cfsReceived = useRecoilValue(BookingPageStates.editLogistics.cfsReceived);
  const cyCutoff = useRecoilValue(BookingPageStates.editLogistics.cyCutoff);
  const vgmCutoff = useRecoilValue(BookingPageStates.editLogistics.vgmCutoff);
  const siCutoff = useRecoilValue(BookingPageStates.editLogistics.siCutoff);

  const pol = useRecoilValue(BookingPageStates.editLogistics.pol);
  const polEtd = useRecoilValue(BookingPageStates.editLogistics.polEtd);
  const polAtd = useRecoilValue(BookingPageStates.editLogistics.polAtd);

  const transitPort = useRecoilValue(BookingPageStates.editLogistics.transitPort);
  const transitPortEta = useRecoilValue(BookingPageStates.editLogistics.transitPortEta);
  const transitPortAta = useRecoilValue(BookingPageStates.editLogistics.transitPortAta);
  const transitPortEtd = useRecoilValue(BookingPageStates.editLogistics.transitPortEtd);
  const transitPortAtd = useRecoilValue(BookingPageStates.editLogistics.transitPortAtd);

  const pod = useRecoilValue(BookingPageStates.editLogistics.pod);
  const podEta = useRecoilValue(BookingPageStates.editLogistics.podEta);
  const ramp = useRecoilValue(BookingPageStates.editLogistics.ramp);
  const rampEta = useRecoilValue(BookingPageStates.editLogistics.rampEta);
  const finalDestination = useRecoilValue(BookingPageStates.editLogistics.finalDestination);
  const deliveryEta = useRecoilValue(BookingPageStates.editLogistics.deliveryEta);

  const motherVessel = useRecoilValue(BookingPageStates.editLogistics.motherVessel);
  const motherVoyage = useRecoilValue(BookingPageStates.editLogistics.motherVoyage);
  const feederVessel = useRecoilValue(BookingPageStates.editLogistics.feederVessel);
  const feederVoyage = useRecoilValue(BookingPageStates.editLogistics.feederVoyage);

  const [updateLogistics] = useUpdateBookingLogisticsMutation({
    onCompleted: (data) => {
      if (data.updateBookingLogistics.success) {
        enqueueSnackbar('Booking Updated', {
          variant: 'success',
          anchorOrigin: {
            vertical: 'top',
            horizontal: 'right',
          },
        });
      }
    },
    refetchQueries: () => [
      {
        query: BookingQuery,
        variables: { bookingId: booking?.id ?? '' },
        fetchPolicy: 'cache-and-network',
      },
      {
        query: VesselsQuery,
        fetchPolicy: 'cache-and-network',
      },
    ],
  });

  return (
    <Button
      variant="contained"
      color="primary"
      disabled={
        (!Boolean(motherVessel) && Boolean(motherVoyage)) ||
        (Boolean(motherVessel) && !Boolean(motherVoyage)) ||
        (!Boolean(feederVessel) && Boolean(feederVoyage)) ||
        (Boolean(feederVessel) && !Boolean(feederVoyage)) ||
        (!Boolean(confirmationNumber) && Boolean(confirmationDate)) ||
        (Boolean(confirmationNumber) && !Boolean(confirmationDate))
      }
      onClick={() => {
        const input: GqlUpdateBookingLogisticsInput = {
          bookingId: booking?.id ?? '',
          logistics: {
            id: booking?.logistics.id,
            confirmationNumber: confirmationNumber,
            confirmationDate: confirmationDate,
            carrierId: carrier?.id,
            carrierConfirmationNumber: carrierConfirmationNumber,
            contractNumber: contract,
            contractType: contractType,
            cfsCutoffDate: cfsCutoff,
            cfsReceivedDate: cfsReceived,
            cyCutoffDate: cyCutoff,
            vgmCutoffDate: vgmCutoff,
            siCutoffDate: siCutoff,
            polCode: pol?.code,
            polEtd: polEtd,
            polAtd: polAtd,
            transitPortCode: transitPort?.code,
            transitPortEta: transitPortEta,
            transitPortAta: transitPortAta,
            transitPortEtd: transitPortEtd,
            transitPortAtd: transitPortAtd,
            podCode: pod?.code,
            podEta: podEta,
            rampCode: ramp?.code,
            rampEta: rampEta,
            finalDestinationId: finalDestination?.id,
            deliveryEta: deliveryEta,
          },
          hbl: hblNumber
            ? {
                id: booking?.hbl?.id,
                referenceNumber: hblNumber,
                releaseType: hblReleaseType,
                paymentType: hblPaymentType,
              }
            : undefined,
          mbl: mblNumber
            ? {
                id: booking?.mbl?.id,
                referenceNumber: mblNumber,
                releaseType: mblReleaseType,
                paymentType: mblPaymentType,
              }
            : undefined,
          motherVessel: {
            // always send up an id, even if it's null. This way the backend can know when to remove it - OMS-104
            id: motherVessel?.id,
            name: motherVessel?.name,
          },
          motherVoyage: {
            id: motherVoyage?.id,
            name: motherVoyage?.name,
          },
          feederVessel: {
            id: feederVessel?.id,
            name: feederVessel?.name,
          },
          feederVoyage: {
            id: feederVoyage?.id,
            name: feederVoyage?.name,
          },
        };

        updateLogistics({
          variables: {
            input: input,
          },
        });

        props.onClick();
      }}
    >
      Save
    </Button>
  );
}
