import { Box, Fab, Typography } from '@material-ui/core';
import { navigate, RouteComponentProps } from '@reach/router';
import { PermissionCode } from 'api/GQL_Types';
import { auth } from 'app';
import { ManagePageHeader_BackStack } from 'app/admin/components/ManagePageHeader';
import ProfileListItem from 'app/admin/components/ProfileListItem';
import SearchBar from 'components/SearchBar';
import { Line } from 'components/StyledComponents';
import React from 'react';
import { selector, useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import { genKey, newAtom } from 'recoil-utils/utils';
import styled from 'styled-components';
import { theme } from 'styles';
import { getAccentColorFromIndex } from 'utils/Colors';
import { NetworkAdminConnectedProfiles, NetworkAdminPageStates } from '../states';
import NetworkConnectionDialog, { NetworkConnectionDialog_Open } from './NetworkConnectionDialog';

const Thumb = styled.div`
  display: 'inline-flex';
  border-radius: 2;
  border: '1px solid #eaeaea';
  margin-bottom: 8;
  margin-right: 8;
  width: 100;
  height: 100;
  padding: 4;
  box-sizing: 'border-box';
`;

const networkConnectionsSearchState = newAtom<string>('');

const filteredNetworkConnectionsListState = selector<NetworkAdminConnectedProfiles[]>({
  key: genKey(),
  get: ({ get }) => {
    const searchField = get(networkConnectionsSearchState);
    const connections = get(NetworkAdminPageStates.network)?.connectedProfiles || [];
    const lowerField = searchField.toLowerCase();

    if (searchField === '') {
      return connections;
    } else {
      return connections.filter((connection) => connection.name.toLowerCase().includes(lowerField));
    }
  },
});

interface Props extends RouteComponentProps {}

export default function NetworkConnections(props: Props) {
  const { userContext } = auth.useAuthState();
  const network = useRecoilValue(NetworkAdminPageStates.network);
  const setOpen = useSetRecoilState(NetworkConnectionDialog_Open);
  const [backStack, setBackStack] = useRecoilState(ManagePageHeader_BackStack);
  const [searchField, setSearchField] = useRecoilState(networkConnectionsSearchState);
  const filteredConnections = useRecoilValue(filteredNetworkConnectionsListState);

  return (
    <div>
      <Box display="flex" justifyContent="space-between">
        <Box>
          <Typography variant="h2">Network Connections</Typography>
          <Typography variant="body1">
            List of profiles that are connected to this network
          </Typography>
        </Box>
        <Box width="25%" bgcolor={theme.palette.background.default} padding={1} alignSelf="center">
          <SearchBar
            placeholder="Search Network Connections"
            field={searchField}
            updateField={setSearchField}
          />
        </Box>
      </Box>
      <Line color={theme.palette.primary.main} height={2} />
      <Box paddingBottom={4}>
        {filteredConnections.map((connection, index: number) => {
          return (
            <ProfileListItem
              key={connection.id}
              accentColor={getAccentColorFromIndex(index)}
              name={connection.name}
              nRoles={connection.roles.length}
              nContacts={connection.contacts.length}
              nLocations={connection.locations.length}
              needsUpdate={connection.needsUpdate}
              onManageClicked={() => {
                const copy = [...backStack];
                if (network) {
                  copy.push({ label: network.name, path: '/admin/networks/' + network.id });
                }
                setBackStack(copy);
                navigate('/admin/profiles/' + connection.id);
              }}
              extraDisplays={[
                connection.logo && (
                  <Thumb>
                    <Box style={{ display: 'flex', minWidth: 0, overflow: 'hidden' }}>
                      <img
                        alt={connection.name}
                        src={connection.logo.url}
                        style={{
                          display: 'block',
                          maxWidth: '175px',
                          minWidth: '34px',
                          height: '34px',
                        }}
                      />
                    </Box>
                  </Thumb>
                ),
              ]}
            />
          );
        })}
      </Box>
      {userContext?.permissionCodes.has(PermissionCode.ProfileCreate) && (
        <Box position="absolute" bottom="48px" right="36px">
          <Fab
            color="primary"
            aria-label="add"
            variant="extended"
            onClick={() => {
              setOpen(true);
            }}
          >
            <i className="material-icons" style={{ marginRight: '8px' }}>
              {'add'}
            </i>
            Create New Connection
          </Fab>
        </Box>
      )}
      <NetworkConnectionDialog />
    </div>
  );
}
