import { ApolloLink } from '@apollo/client/core';
import { TypeNode } from 'graphql/language/ast';
import {
  fromAPI_Date,
  fromAPI_DateTime,
  fromAPI_Timestamp,
  isDate,
  toAPI_Date,
  toAPI_DateTime,
  toAPI_Timestamp,
} from 'types/Date';
import { InputTypePaths, OperationCustomScalarPaths, ScalarPathObj } from './GQL_Types';

/**
 * The scalars by name and how to transform the value.
 *
 * When adding a new scalar, you need to add it to these 2 places as well:
 * - codegen.yml
 * - codegen-custom-scalar.ts
 */
const scalarTransforms: {
  [scalarName: string]: {
    serialize?: (input: unknown) => any;
    deserialize?: (input: unknown) => any;
  };
} = {
  Timestamp: {
    serialize(v) {
      return isDate(v) ? toAPI_Timestamp(v) : null;
    },
    deserialize(v) {
      return fromAPI_Timestamp(v);
    },
  },
  DateTime: {
    serialize(v) {
      return isDate(v) ? toAPI_DateTime(v) : null;
    },
    deserialize(v) {
      return fromAPI_DateTime(v);
    },
  },
  Date: {
    serialize(v) {
      return isDate(v) ? toAPI_Date(v) : null;
    },
    deserialize(v) {
      return fromAPI_Date(v);
    },
  },
};

/**
 * Apollo currently does not have good support for automatic parsing of custom scalars.
 * There's been no good solution since 2016.
 * https://github.com/apollographql/apollo-feature-requests/issues/2
 *
 * Other options
 * new InMemoryCache({ typePolicies: { ... } })
 * This doesn't work for queries that have `no-cache` set. This is common enough, that this cannot be relied upon.
 *
 * https://github.com/eturino/apollo-link-scalars
 * This requires you to bundle the entire schema AST in your build.
 * This also incurs a non-trivial performance hit as it introspects every single response.
 *
 * This link depends on codegen-custom-scalar.ts which produces OperationCustomScalarPaths.
 *
 */
export const customScalarLink = new ApolloLink((operation, forward) => {
  const variableTypes: { [fieldName: string]: string } = {};
  for (const def of operation.query.definitions) {
    if (def.kind === 'OperationDefinition') {
      if (def.variableDefinitions) {
        for (const varDef of def.variableDefinitions) {
          const fieldName = varDef.variable.name.value;
          variableTypes[fieldName] = unwrapTypeName(varDef.type);
        }
      }
    }
  }
  operation.variables = serializeVariables(operation.variables, variableTypes);

  const scalarPaths = OperationCustomScalarPaths[operation.operationName] || null;
  if (!scalarPaths) {
    return forward(operation);
  }
  return forward(operation).map((data) => {
    data.data = transformAtPath(data.data, scalarPaths);
    return data;
  });
});

function transformAtPath<T>(data: T, pathObj: ScalarPathObj): T {
  if (typeof pathObj === 'string') {
    const deserialize = scalarTransforms[pathObj]?.deserialize;
    return deserialize ? deserialize(data) : data;
  }
  for (const key of Object.keys(pathObj)) {
    if (key === '$') {
      if (Array.isArray(data)) {
        data = data.map((v) => transformAtPath(v, pathObj[key])) as any;
      }
    } else {
      if (data) {
        (data as any)[key] = transformAtPath((data as any)[key], pathObj[key]);
      }
    }
  }
  return data;
}

function serializeVariables(variables: any, types: { [fieldName: string]: string }): any {
  if (!variables) {
    return variables;
  }
  if (Array.isArray(variables)) {
    return variables.map((v) => serializeVariables(v, types));
  }
  for (const key of Object.keys(variables)) {
    const type = types[key];
    if (type) {
      const inputType = InputTypePaths[type];
      if (inputType) {
        variables[key] = serializeVariables(variables[key], inputType);
      } else {
        const serialize = scalarTransforms[type]?.serialize;
        if (serialize) {
          variables[key] = serializeThing(variables[key], serialize);
        }
      }
    }
  }
  return variables;
}

function serializeThing(thing: any, serialize: (input: unknown) => any): any {
  if (Array.isArray(thing)) {
    return thing.map((t) => serializeThing(t, serialize));
  }
  return serialize(thing);
}

function unwrapTypeName(type: TypeNode): string {
  switch (type.kind) {
    case 'NonNullType':
      return unwrapTypeName(type.type);
    case 'ListType':
      return unwrapTypeName(type.type);
    case 'NamedType':
      return type.name.value;
  }
}
