import { faMapMarkerAlt, faShippingFast } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Box, ButtonBase, Grid, Hidden, Menu, MenuItem, Typography } from '@material-ui/core';
import React from 'react';
import { theme } from 'styles';
import DueToArriveButton from './DueToArriveButton';

interface Props {
  destinations: string[];
  selectedDestination: string;
  onSelectedDestination: (destination: string) => void;
}
export const ShipmentsInTransitHeading: React.FC<Props> = (props: Props) => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  return (
    <Box paddingX={2} paddingTop={1}>
      <Grid container justify="center" alignItems="center">
        <Grid item xs={4}>
          <Box display="flex" justifyContent="flex-start" alignItems="center">
            <Typography variant="h2">
              <Box component="span" marginRight={2}>
                <FontAwesomeIcon icon={faShippingFast} color={theme.palette.indigoBlue.main} />
              </Box>
              Shipments in Transit
            </Typography>
          </Box>
        </Grid>
        <Grid item xs={4}>
          <Box display="flex">
            <DueToArriveButton />
          </Box>
        </Grid>
        <Grid item xs={4}>
          <Box display="flex" justifyContent="flex-end">
            <ButtonBase onClick={(e) => setAnchorEl(e.currentTarget)}>
              <Box component="span" fontSize="32px" color="primary">
                <FontAwesomeIcon icon={faMapMarkerAlt} color={theme.palette.indigoBlue.main} />
              </Box>
              <Hidden smDown>
                <Box
                  display="flex"
                  flexDirection="column"
                  alignItems="flex-start"
                  marginLeft={1}
                  marginRight={2}
                >
                  <Typography variant="h4">Destination</Typography>
                  <Typography variant="body1">{props.selectedDestination}</Typography>
                </Box>
                <span className="material-icons">keyboard_arrow_down</span>
              </Hidden>
            </ButtonBase>
            <Menu
              id="user-menu"
              anchorEl={anchorEl}
              keepMounted
              open={!!anchorEl}
              onClose={() => setAnchorEl(null)}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
              }}
              getContentAnchorEl={null}
            >
              {props.destinations.map((des: string, index: number) => {
                return (
                  <MenuItem
                    key={index}
                    onClick={(e: any) => {
                      setAnchorEl(null);
                      props.onSelectedDestination(des);
                    }}
                  >
                    {des}
                  </MenuItem>
                );
              })}
            </Menu>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};
