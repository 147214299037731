import {
  ContainerMode,
  GqlCarrier,
  GqlLocation,
  GqlPort,
  GqlRelatedParty,
  MoveType,
} from 'api/GQL_Types';
import { selector, useSetRecoilState } from 'recoil';
import { DateAtomType, genKey, newAtom } from 'recoil-utils/utils';
import { DateRangeValue } from 'components/form/FormInputDateRange';

export interface EquipmentQty {
  code: string;
  qty: number;
}

export interface ConsolidationBookingsTV {
  id: string;
  referenceNumber: { to: string; value: string };
  bookingDate: Date | null;
  weight: number;
  volume: number;
  bookingStatus: string;
  cargoReadyDate: Date | null | undefined;
  pol: string;
  polEtd: Date | null | undefined;
  pod: string;
  finalDestinationName: string;
  supplierName: string;
  moveType: string;
  containers: string;
  relatedParties: GqlRelatedParty[];
}

export const NewConsolidationStates = {
  activeStep: newAtom<0 | 1>(0),
  newConsolidation: newAtom<{
    consolidationId: string;
    referenceNumber: string;
  } | null>(null),

  step1: {
    bookings: newAtom<ConsolidationBookingsTV[]>([]),
    filteredBookings: newAtom<ConsolidationBookingsTV[]>([]),
    bookingsChecked: newAtom<string[]>([]),
    selectedBookings: newAtom<ConsolidationBookingsTV[]>([]),
    equipmentList: newAtom<EquipmentQty[]>([]),
  },

  step2: {
    formBookingParty: newAtom<string>(''),
    formConsignee: newAtom<GqlRelatedParty | null>(null),
    formConsigneeField: newAtom<string>(''),
    formSupplier: newAtom<GqlRelatedParty | null>(null),
    formSupplierField: newAtom<string>(''),
    formSupplierList: newAtom<GqlRelatedParty[]>([]),
    formManufacturer: newAtom<GqlRelatedParty | null>(null),
    formManufacturerField: newAtom<string>(''),
    formManufacturerList: newAtom<GqlRelatedParty[]>([]),
    formMoveType: newAtom<MoveType | null>(null),
    formContainerMode: newAtom<ContainerMode | null>(ContainerMode.Fcl),
    formPickupLocation: newAtom<GqlLocation | null>(null),
    formDeliveryLocation: newAtom<GqlLocation | null>(null),
    formPol: newAtom<GqlPort | null>(null),
    formCarrier: newAtom<GqlCarrier | null>(null),
    formCfsOpenDate: newAtom<DateAtomType>(null),
    formCfsCutoffDate: newAtom<DateAtomType>(null),
    formEtd: newAtom<DateAtomType>(null),
    formPod: newAtom<GqlPort | null>(null),
    formEta: newAtom<DateAtomType>(null),
    consolidationRemark: newAtom(''),
  },

  filters: {
    filterDestination: newAtom<GqlLocation | null>(null),
    filterCargoReadyDate: newAtom<DateRangeValue | null>(null),
    filterPol: newAtom<string | null>(null),
    filterEtd: newAtom<DateRangeValue | null>(null),
    filterEqptType: newAtom<string | null>(null),
    filterDeliveryType: newAtom<string | null>(null),
    filterSupplier: newAtom<GqlRelatedParty | null>(null),
    filtersLength: newAtom(0),
  },
};

export function useSetupStep1() {
  const setBookings = useSetRecoilState(NewConsolidationStates.step1.bookings);
  const setFilteredBookings = useSetRecoilState(NewConsolidationStates.step1.filteredBookings);
  const setBookingChecked = useSetRecoilState(NewConsolidationStates.step1.bookingsChecked);
  const setSelectedBooking = useSetRecoilState(NewConsolidationStates.step1.selectedBookings);

  return (bookings: ConsolidationBookingsTV[]) => {
    setBookings(bookings);
    setFilteredBookings(bookings);
    setBookingChecked([]);
    setSelectedBooking([]);
  };
}

export interface Step1Validation {
  canGoToStep2: boolean;
  whyCantGoToStep2?: string;
}

export const step1Validation = selector<Step1Validation>({
  key: genKey(),
  get: ({ get }) => {
    const bookings = get(NewConsolidationStates.step1.selectedBookings);

    if (bookings.length < 1) {
      return {
        canGoToStep2: false,
        whyCantGoToStep2: 'You need to select at least one booking',
      };
    }

    return { canGoToStep2: true };
  },
});
