import React from 'react';
import { Router, RouteComponentProps } from '@reach/router';

import EquipmentDetails from './details';

interface EquipmentProps extends RouteComponentProps {}
function Equipment(props: EquipmentProps) {
  return (
    <Router>
      <EquipmentDetails path="/:equipmentId" />
    </Router>
  );
}

export default Equipment;
