import { IconButton, InputAdornment } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import clsx from 'clsx';
import { FormInputText } from 'components/form/FormInputText';
import { FormItem } from 'components/form/FormItem';
import { RecoilState, useRecoilState } from 'recoil';
import { makeCss } from 'styles';

const classes = makeCss({
  default: {
    '&:hover $clearButtonDirty, & .Mui-focused $clearButtonDirty': {
      visibility: 'visible',
    },
  },
  clearButtonDirty: {},
  clearButton: {
    padding: '2px',
    visibility: 'hidden',
  },
});

interface Props {
  // Atom state that holds the value being worked with
  state: RecoilState<string>;
  // Label of text field
  label: string;

  // If flag provided the text field is disabled
  disabled?: boolean;
  endAdornment?: string;
  // HTML type of text field Ex. password, number, etc.
  type?: string;
  required?: boolean;
  error?: boolean; // When true, indicate error status i.e. red border
  helperText?: string | null;
  startAdornment?: string;
  multiline?: boolean;
  rows?: number;
  // Text field placeholder
  placeholder?: string;

  autoComplete?: 'on' | 'off';
  onChange?: (text: string | null) => void;
}

export default function AtomicTextFieldV2(props: Props) {
  const [state, setState] = useRecoilState(props.state);

  return (
    <FormItem>
      <FormInputText
        className={classes.default}
        value={state}
        onValue={(value) => {
          setState(value);
          if (props.onChange) {
            props.onChange(value);
          }
        }}
        label={props.label}
        disabled={props.disabled}
        type={props.type}
        required={props.required}
        multiline={props.multiline}
        rows={props.rows}
        placeholder={props.placeholder}
        helperText={props.helperText}
        error={props.error}
        startAdornment={props.startAdornment}
        endAdornment={
          !props.multiline && (
            <InputAdornment position="end" style={{ marginRight: '-8px' }}>
              <IconButton
                title="Clear"
                className={clsx(classes.clearButton, {
                  [classes.clearButtonDirty]: state !== '',
                })}
                onClick={() => {
                  setState('');
                  if (props.onChange) {
                    props.onChange('');
                  }
                }}
                tabIndex={-1}
              >
                <CloseIcon fontSize="small" />
              </IconButton>
            </InputAdornment>
          )
        }
        autoComplete={props.autoComplete}
      />
    </FormItem>
  );
}
