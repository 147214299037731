import React from 'react';
import { Box, Button, Grid } from '@material-ui/core';
import EditBookingSaveButton from './EditBookingSaveButton';
import { useRecoilState } from 'recoil';
import { BookingPageStates } from '../../states';
import AtomicDatePickerV2 from 'components/atomic/AtomicDatePickerV2';
import { AtomGroupCleaner, newAtom } from 'recoil-utils/utils';
import UniversalDialog from 'components/UniversalDialog';
import AtomicAutocompleteV2 from 'components/atomic/AtomicAutocompleteV2';
import { IncoTerm, ShipmentStatus } from 'api/GQL_Types';
import { mapIncoTerm } from 'utils/Enums';
import { useRecoilValue } from 'recoil';

export const EditBookingDialog_Open = newAtom(false);

interface Props {}
export default function EditBookingDialog(props: Props) {
  const [open, setOpen] = useRecoilState(EditBookingDialog_Open);
  const booking = useRecoilValue(BookingPageStates.booking);

  return (
    <UniversalDialog
      open={open}
      title="<Edit Booking>"
      subTitle="Fill in fields to update booking"
      setClose={() => {
        setOpen(false);
      }}
      large
    >
      <AtomGroupCleaner atomGroup={BookingPageStates.editBooking} />
      <Box padding={1}>
        <Grid container spacing={3} alignItems="flex-start">
          <Grid item xs={6}>
            <AtomicDatePickerV2
              state={BookingPageStates.editBooking.revisedCargoReadyDate}
              label="Revised Cargo Ready Date"
              disabled={booking?.status === ShipmentStatus.Shipped}
            />
          </Grid>

          <Grid item xs={6}>
            <AtomicAutocompleteV2<IncoTerm>
              state={BookingPageStates.editBooking.incoTerm}
              label={'Inco Term'}
              optionsList={Object.values(IncoTerm)}
              displayResolver={(value) => mapIncoTerm(value)}
            />
          </Grid>
        </Grid>

        <hr />
        <Box>
          <Box display="flex" justifyContent="flex-end">
            <Button
              onClick={() => {
                setOpen(false);
              }}
              style={{ marginRight: '24px' }}
            >
              Cancel
            </Button>
            <EditBookingSaveButton
              onClick={() => {
                setOpen(false);
              }}
            />
          </Box>
        </Box>
      </Box>
    </UniversalDialog>
  );
}
