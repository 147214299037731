import { Box, Button } from '@material-ui/core';
import {
  NotificationFrequency,
  GqlContactInput,
  useNewProfileContactMutation,
  useUpdateContactMutation,
} from 'api/GQL_Types';
import { AdminPagesContact } from 'app/admin/states';
import { AtomicPhoneNumberV2 } from 'components/atomic/AtomicPhoneNumberV2';
import AtomicSelectorV2 from 'components/atomic/AtomicSelectorV2';
import AtomicSwitchV2 from 'components/atomic/AtomicSwitchV2';
import AtomicTextFieldV2 from 'components/atomic/AtomicTextFieldV2';
import FormInputSelect from 'components/form/FormInputSelect';
import { FormItem } from 'components/form/FormItem';
import { FormRow } from 'components/form/FormRow';
import UniversalDialog from 'components/UniversalDialog';
import React from 'react';
import { useRecoilState } from 'recoil';
import { newAtom } from 'recoil-utils/utils';
import { isValidEmail } from 'types/Email';

interface ContactDialogSetupData {
  profileId: string;
  contact: AdminPagesContact | null; // set to null if you are creating a new contact
  roles: { id: string; name: string }[];
  locations: { id: string; name: string }[];
}

export const openContactDialog_Data = newAtom<ContactDialogSetupData | null>(null);

const form = {
  firstName: newAtom(''),
  lastName: newAtom(''),
  title: newAtom(''),
  email: newAtom(''),
  mainPhoneNumber: newAtom(''),
  cellPhoneNumber: newAtom(''),
  altPhoneNumber: newAtom(''),
  extension: newAtom(''),
  location: newAtom<{ id: string; name: string }>({ id: '', name: '' }),
  role: newAtom<{ id: string; name: string }>({ id: '', name: '' }),
  notificationFrequency: newAtom<NotificationFrequency>(NotificationFrequency.Immediately),
  isUserEnabled: newAtom(false),
  pass1: newAtom(''),
  pass2: newAtom(''),
};

interface FormValidationState {
  hasChanged: boolean;
  isValid: boolean;
  firstName: string | null;
  lastName: string | null;
  email: string | null;
  role: string | null;
  pass1: string | null;
  pass2: string | null;
}

interface Props {
  refetchQuery: any;
}

export default function ProfileContactDialog(props: Props) {
  const [dialogData, setDialogData] = useRecoilState(openContactDialog_Data);

  const [firstName, setFirstName] = useRecoilState(form.firstName);
  const [lastName, setLastName] = useRecoilState(form.lastName);
  const [title, setTitle] = useRecoilState(form.title);
  const [email, setEmail] = useRecoilState(form.email);
  const [mainPhoneNumber, setMainPhoneNumber] = useRecoilState(form.mainPhoneNumber);
  const [cellPhoneNumber, setCellPhoneNumber] = useRecoilState(form.cellPhoneNumber);
  const [altPhoneNumber, setAltPhoneNumber] = useRecoilState(form.altPhoneNumber);
  const [extension, setExtension] = useRecoilState(form.extension);
  const [location, setLocation] = useRecoilState(form.location);
  const [role, setRole] = useRecoilState(form.role);
  const [notificationFrequency, setNotificationFrequency] = useRecoilState(
    form.notificationFrequency
  );
  const [isUserEnabled, setIsUserEnabled] = useRecoilState(form.isUserEnabled);
  const [pass1, setPass1] = useRecoilState(form.pass1);
  const [pass2, setPass2] = useRecoilState(form.pass2);

  const contact = dialogData?.contact;
  const profileId = dialogData?.profileId || '';

  React.useEffect(() => {
    setFirstName(contact?.firstName ?? '');
    setLastName(contact?.lastName ?? '');
    setTitle(contact?.title ?? '');
    setEmail(contact?.email ?? '');
    setMainPhoneNumber(contact?.phoneMain ?? '');
    setCellPhoneNumber(contact?.phoneCell ?? '');
    setAltPhoneNumber(contact?.phoneAlternative ?? '');
    setExtension(contact?.extension ?? '');
    setLocation(contact?.location ?? { id: '', name: '' });
    setRole(contact?.role ?? { id: '', name: '' });
    setNotificationFrequency(contact?.notificationFrequency ?? NotificationFrequency.Immediately);
    setIsUserEnabled(!!contact?.isUserEnabled);
    setPass1('');
    setPass2('');
  }, [contact]);

  const [newProfileContact] = useNewProfileContactMutation({
    refetchQueries: () => [props.refetchQuery],
  });
  const [updateProfileContact] = useUpdateContactMutation({
    refetchQueries: () => [props.refetchQuery],
  });

  if (!dialogData) {
    return null;
  }

  const validation = validateForm();

  return (
    <UniversalDialog
      open={true}
      title={contact ? 'Manage Contact' : 'New Contact'}
      small
      setClose={() => {
        setDialogData(null);
      }}
    >
      <hr />
      <Box marginY={1}>
        <FormRow>
          <AtomicTextFieldV2
            state={form.firstName}
            label="First Name"
            error={!!validation.firstName}
            helperText={validation.firstName}
          />
          <AtomicTextFieldV2
            state={form.lastName}
            label="Last Name"
            error={!!validation.lastName}
            helperText={validation.lastName}
          />
        </FormRow>
        <FormRow>
          <AtomicTextFieldV2 state={form.title} label="Title" />
        </FormRow>
        <FormRow>
          <AtomicTextFieldV2
            state={form.email}
            label="Email"
            autoComplete="off"
            type="email"
            error={!!validation.email}
            helperText={validation.email}
          />
        </FormRow>
        <FormRow>
          <AtomicPhoneNumberV2 state={form.mainPhoneNumber} label="Main Phone Number" />
          <AtomicPhoneNumberV2 state={form.cellPhoneNumber} label="Cell Phone Number" />
        </FormRow>
        <FormRow>
          <AtomicPhoneNumberV2 state={form.altPhoneNumber} label="Alt. Phone Number" />
          <AtomicTextFieldV2 state={form.extension} label="Extension" />
        </FormRow>
        <FormRow>
          <AtomicSelectorV2
            state={form.location}
            label="Location"
            optionsList={dialogData.locations}
            controllingField="id"
            displayField="name"
          />
        </FormRow>
        <Box marginX={1.5} marginBottom={2}>
          <AtomicSwitchV2 state={form.isUserEnabled} label="User Enabled" />
        </Box>
        {isUserEnabled && (
          <FormRow>
            <AtomicSelectorV2
              state={form.role}
              label="Role"
              optionsList={dialogData.roles}
              controllingField="id"
              displayField="name"
              error={!!validation.role}
              helperText={validation.role}
            />
          </FormRow>
        )}
        {isUserEnabled && (
          <FormRow>
            <FormItem>
              <FormInputSelect
                label="Notification Frequency"
                options={[
                  { id: NotificationFrequency.Immediately, label: 'Immediately' },
                  { id: NotificationFrequency.Daily, label: 'Daily' },
                  { id: NotificationFrequency.Weekly, label: 'Weekly' },
                ]}
                value={notificationFrequency}
                onValue={(value) => {
                  switch (value) {
                    case NotificationFrequency.Immediately:
                    case NotificationFrequency.Daily:
                    case NotificationFrequency.Weekly:
                      setNotificationFrequency(value);
                      break;
                  }
                }}
                // required={props.required}
                // disabled={props.disabled}
                // error={props.error || (props.required && !state[props.controllingField])}
                // helperText={props.helperText}
              />
            </FormItem>
          </FormRow>
        )}
        {isUserEnabled && !contact?.isUserEnabled && (
          <FormRow>
            <AtomicTextFieldV2
              state={form.pass1}
              label="Enter Password"
              type="password"
              autoComplete="off"
              error={!!validation.pass1}
              helperText={validation.pass1}
            />
            <AtomicTextFieldV2
              state={form.pass2}
              label="Re-enter Password"
              type="password"
              autoComplete="off"
              error={!!validation.pass2}
              helperText={validation.pass2}
            />
          </FormRow>
        )}
      </Box>
      <hr />
      <Box display="flex" justifyContent="flex-end">
        <Button
          variant="contained"
          color="default"
          size="large"
          onClick={() => {
            setDialogData(null);
          }}
          style={{ marginRight: '12px' }}
        >
          Cancel
        </Button>
        <Button
          variant="contained"
          color="primary"
          size="large"
          disabled={!validation.hasChanged || !validation.isValid}
          onClick={() => {
            const contactInput: GqlContactInput = {
              id: contact?.id,
              firstName: firstName,
              lastName: lastName,
              title: title,
              email: email,
              phoneMain: mainPhoneNumber,
              phoneCell: cellPhoneNumber,
              phoneAlternative: altPhoneNumber,
              extension: extension,
              locationId: location.id ? location.id : undefined,
              roleId: role.id ? role.id : undefined,
              notificationFrequency: notificationFrequency,

              isUserEnabled: isUserEnabled,
              credentials:
                isUserEnabled && pass1.length > 0
                  ? {
                      email: email,
                      username: email,
                      password: pass1,
                    }
                  : undefined,
            };
            if (contact) {
              updateProfileContact({
                variables: {
                  input: {
                    contact: contactInput,
                  },
                },
              });
            } else {
              newProfileContact({
                variables: {
                  input: {
                    profileId: profileId,
                    contact: contactInput,
                  },
                },
              });
            }
            setDialogData(null);
          }}
        >
          Save
        </Button>
      </Box>
    </UniversalDialog>
  );

  function validateForm(): FormValidationState {
    const validation: FormValidationState = {
      hasChanged: false,
      isValid: false,
      firstName: null,
      lastName: null,
      email: null,
      role: null,
      pass1: null,
      pass2: null,
    };

    const name = (firstName + ' ' + lastName).trim();
    if (name === '') {
      validation.firstName = 'Please provide a name';
      validation.lastName = 'Please provide a name';
    }

    if (contact) {
      const currLocationId = contact.location ? contact.location.id : '';
      const currRoleId = contact.role ? contact.role.id : '';

      validation.hasChanged =
        contact.firstName !== firstName ||
        contact.lastName !== lastName ||
        contact.email !== email ||
        contact.title !== title ||
        contact.phoneMain !== mainPhoneNumber ||
        contact.phoneCell !== cellPhoneNumber ||
        contact.phoneAlternative !== altPhoneNumber ||
        contact.extension !== extension ||
        contact.isUserEnabled !== isUserEnabled ||
        contact.notificationFrequency !== notificationFrequency ||
        currLocationId !== location.id ||
        currRoleId !== role.id;
    } else {
      // New contact
      validation.hasChanged = true;
    }

    if (isUserEnabled) {
      if (email.trim() === '') {
        validation.email = 'Required for user login.';
      } else if (!isValidEmail(email)) {
        validation.email = 'Invalid email.';
      }
      if (role.id === '') {
        validation.role = 'Required for user.';
      }
      if (!contact?.isUserEnabled) {
        // New user
        if (pass1.length < 1) {
          validation.pass1 = 'Please enter a password.';
        } else if (pass1.length < 8) {
          validation.pass1 = 'Password must be at least 8 characters long.';
        } else if (!/[0-9]/.test(pass1)) {
          validation.pass1 = 'Password must contain at least one number.';
        } else if (pass1 !== pass2) {
          validation.pass2 = 'Passwords do not match.';
        }
      }
    }

    validation.isValid =
      validation.firstName == null &&
      validation.lastName == null &&
      validation.email == null &&
      validation.role == null &&
      validation.pass1 == null &&
      validation.pass2 == null;

    return validation;
  }
}
