import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import * as React from 'react';

const nullOptionId = '-$$nothing$$-';

interface Props {
  options: { id: string; label: string }[];
  value: string | null;
  onValue(v: string | null): void;

  label: string;
  required?: boolean;
  disabled?: boolean;
  error?: boolean; // When true, indicate error status i.e. red border
  helperText?: string | null;

  // When set, allow the user to select null as a valid option.
  nullOptionLabel?: string;
}

export default function FormInputSelect({
  options,
  value,
  onValue,
  label,
  required,
  disabled,
  error,
  helperText,
  nullOptionLabel,
}: Props) {
  const inputLabel = React.useRef<HTMLLabelElement>(null);
  const [labelWidth, setLabelWidth] = React.useState(0);

  React.useEffect(() => {
    if (inputLabel.current) {
      setLabelWidth(inputLabel.current.offsetWidth);
    }
  }, []);

  return (
    <FormControl
      variant="outlined"
      error={!!error}
      fullWidth
      required={required}
      disabled={disabled}
      size="small"
    >
      <InputLabel
        ref={inputLabel}
        disabled={disabled}
        shrink // See #OMS-54
      >
        {label}
      </InputLabel>
      <Select
        value={value || nullOptionId}
        onChange={(e) => {
          const id = e.target.value as string;
          if (options.find((o) => o.id === id)) {
            onValue(id);
          } else {
            onValue(null);
          }
          const aEl = document.activeElement as any;
          if (aEl && typeof aEl.blur === 'function') {
            aEl.blur();
          }
        }}
        labelWidth={labelWidth}
        disabled={disabled}
        fullWidth
      >
        {nullOptionLabel && (
          <MenuItem value={nullOptionId}>
            <em>{nullOptionLabel}</em>
          </MenuItem>
        )}
        {options.map((option) => {
          return (
            <MenuItem key={option.id} value={option.id}>
              {option.label}
            </MenuItem>
          );
        })}
      </Select>

      {helperText && <FormHelperText>{helperText}</FormHelperText>}
    </FormControl>
  );
}
