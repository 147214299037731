import FormControl from '@material-ui/core/FormControl';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import { FormInputDate } from 'components/form/FormInputDate';
import { FormInputNumber } from 'components/form/FormInputNumber';
import * as React from 'react';
import { isNumber } from 'utils/Numbers';
import { DatasetFilterType } from '..';
import { makeCss, theme } from '../../../styles';
import { isDate, parseDate } from '../../../types/Date';

type DateFilterValueType = 'range' | 'after' | 'before' | 'ytd' | 'mtd' | 'qtd' | 'past-n-days';
const dateFilterTypes = new Set<DateFilterValueType>([
  'range',
  'after',
  'before',
  'ytd',
  'mtd',
  'qtd',
  'past-n-days',
]);

interface DateFilterValue {
  type: DateFilterValueType;
  from: Date | null;
  to: Date | null;
  n?: number | null;
}

const classes = makeCss({
  root: {
    display: 'flex',
  },
  operatorSelect: {
    width: 180,
  },
  dateInputWrap: {
    maxWidth: 180,
    marginLeft: theme.spacing(0.5),
  },
});

export const DateFilter: DatasetFilterType<DateFilterValue> = {
  deserializeValue(filterable, jsonString) {
    try {
      const val = JSON.parse(jsonString as any);
      return {
        // use parseDate NOT fromGQL_Date b/c we just want the date, not the time component
        type: dateFilterTypes.has(val.type) ? val.type : 'range',
        from: parseDate(val.from),
        to: parseDate(val.to),
        n: isNumber(val.n) ? val.n : undefined,
      };
    } catch (err) {
      return {
        type: 'range',
        from: null,
        to: null,
      };
    }
  },
  serializeValue(filterable, value) {
    return JSON.stringify({
      type: value.type,
      from: isDate(value.from) ? toIsoDateNoTZ(value.from) : null,
      to: isDate(value.to) ? toIsoDateNoTZ(value.to) : null,
      n: isNumber(value.n) ? Math.floor(value.n) : undefined,
    });
  },

  defaultValue(filterable) {
    return {
      type: 'range',
      from: null,
      to: null,
    };
  },

  validate(filterable, value) {
    switch (value.type) {
      case 'range': {
        const hasFrom = isDate(value.from);
        const hasTo = isDate(value.to);
        if (!hasFrom && !hasTo) {
          return 'Please enter a date range';
        } else if (!hasFrom) {
          return 'Please enter a "From" date';
        } else if (!hasTo) {
          return 'Please enter a "To" date';
        }
        return null;
      }
      case 'before': {
        if (!isDate(value.to)) {
          return 'Please enter a date';
        }
        return null;
      }
      case 'after': {
        if (!isDate(value.from)) {
          return 'Please enter a date';
        }
        return null;
      }
      case 'past-n-days': {
        if (!isNumber(value.n) || value.n < 1) {
          return 'Please enter a number of days';
        }
        return null;
      }
    }
    return null;
  },

  RenderInput: ({ value, disabled, onValue }) => {
    const valueType: DateFilterValueType = dateFilterTypes.has(value.type) ? value.type : 'range';
    return (
      <div className={classes.root}>
        <FormControl variant="outlined" disabled={disabled}>
          <Select
            className={classes.operatorSelect}
            value={valueType}
            onChange={(e) => {
              const selected = typeof e.target.value === 'string' ? e.target.value : '';
              if (selected === 'past-n-days') {
                onValue({ type: selected, from: null, to: null, n: 30 });
              } else if (dateFilterTypes.has(selected as any)) {
                onValue({ ...value, type: selected as any });
              }
              const aEl = document.activeElement as any;
              if (aEl && typeof aEl.blur === 'function') {
                aEl.blur();
              }
            }}
            disabled={disabled}
            fullWidth
          >
            <MenuItem value="range">date range</MenuItem>
            <MenuItem value="after">after</MenuItem>
            <MenuItem value="before">before</MenuItem>
            <MenuItem value="ytd">YTD (year to date)</MenuItem>
            <MenuItem value="mtd">MTD (month to date)</MenuItem>
            <MenuItem value="qtd">QTD (quarter to date)</MenuItem>
            <MenuItem value="past-n-days">past N days</MenuItem>
          </Select>
        </FormControl>
        <RenderInputBase
          valueType={valueType}
          value={value}
          disabled={disabled}
          onValue={onValue}
        />
      </div>
    );
  },
};

const RenderInputBase: React.FC<{
  valueType: DateFilterValueType;
  value: DateFilterValue;
  disabled: boolean | undefined;
  onValue(v: DateFilterValue): void;
}> = ({ valueType, value, disabled, onValue }) => {
  switch (valueType) {
    case 'before':
      return (
        <div className={classes.dateInputWrap}>
          <FormInputDate
            value={isDate(value.to) ? value.to : null}
            onValue={(d) => {
              onValue({
                type: 'before',
                from: null,
                to: d,
              });
            }}
            label="Before"
            disabled={disabled}
          />
        </div>
      );
    case 'after':
      return (
        <div className={classes.dateInputWrap}>
          <FormInputDate
            value={isDate(value.from) ? value.from : null}
            onValue={(d) => {
              onValue({
                type: 'after',
                from: d,
                to: null,
              });
            }}
            label="After"
            disabled={disabled}
          />
        </div>
      );
    case 'ytd':
      return <div />;
    case 'mtd':
      return <div />;
    case 'qtd':
      return <div />;
    case 'past-n-days':
      return (
        <div className={classes.dateInputWrap}>
          <FormInputNumber
            value={value.n || null}
            onValue={(n) => {
              onValue({
                type: 'past-n-days',
                from: null,
                to: null,
                n,
              });
            }}
            label="N"
            disabled={disabled}
          />
        </div>
      );
  }

  return (
    <>
      <div className={classes.dateInputWrap}>
        <FormInputDate
          value={isDate(value.from) ? value.from : null}
          onValue={(fromDate) => {
            let toDate: Date | null = isDate(value.to) ? value.to : null;
            if (fromDate && toDate) {
              if (toIsoDateNoTZ(toDate) < toIsoDateNoTZ(fromDate)) {
                toDate = fromDate;
              }
            }
            onValue({
              type: 'range',
              from: fromDate,
              to: toDate,
            });
          }}
          label="From"
          disabled={disabled}
        />
      </div>
      <div className={classes.dateInputWrap}>
        <FormInputDate
          value={isDate(value.to) ? value.to : null}
          onValue={(toDate) => {
            let fromDate: Date | null = isDate(value.from) ? value.from : null;
            if (fromDate && toDate) {
              if (toIsoDateNoTZ(fromDate) > toIsoDateNoTZ(toDate)) {
                fromDate = toDate;
              }
            }
            onValue({
              type: 'range',
              from: fromDate,
              to: toDate,
            });
          }}
          label="To"
          disabled={disabled}
        />
      </div>
    </>
  );
};

function toIsoDateNoTZ(d: Date): string {
  // Not using .toISOString() b/c it converts the datetime to UTC
  return `${d.getFullYear()}-${(d.getMonth() + 1 + '').padStart(2, '0')}-${(
    d.getDate() + ''
  ).padStart(2, '0')}`;
}
