import React from 'react';
import { Box, Button } from '@material-ui/core';
import { useRecoilState, useRecoilValue } from 'recoil';
import { BookingPageStates, ItemPosition } from 'app/bookings/details/states';
import {
  GqlVanPositionInput,
  useAssignBookingContainerMutation,
  useUpdateContainerMutation,
} from 'api/GQL_Types';
import { BookingQuery } from 'api/queries/bookingQueries';
import { useSnackbar } from 'notistack';
import { isUUID } from 'utils/UUID';

export default function SubmitFooter() {
  const booking = useRecoilValue(BookingPageStates.booking);
  const [selectedContainer, setSelectedContainer] = useRecoilState(
    BookingPageStates.containerAssignment.selectedContainer
  );
  const selectedItems = useRecoilValue(
    BookingPageStates.containerAssignment.editContainer.selectedBookingPackLines
  );
  const containerNumber = useRecoilValue(
    BookingPageStates.containerAssignment.editContainer.containerNumber
  );
  const containerType = useRecoilValue(
    BookingPageStates.containerAssignment.editContainer.containerType
  );
  const sealNumber = useRecoilValue(BookingPageStates.containerAssignment.editContainer.sealNumber);
  const shipFromFactory = useRecoilValue(
    BookingPageStates.containerAssignment.editContainer.shipFromFactory
  );
  const terminalReceived = useRecoilValue(
    BookingPageStates.containerAssignment.editContainer.terminalReceived
  );

  const { enqueueSnackbar } = useSnackbar();

  const [updateBookingContainer, { loading: updateLoading }] = useUpdateContainerMutation({
    onCompleted: (data) => {
      if (data.updateContainer.success) {
        setSelectedContainer(null);
        enqueueSnackbar('Container Update!', {
          variant: 'success',
          anchorOrigin: {
            vertical: 'top',
            horizontal: 'right',
          },
        });
      }
    },
    refetchQueries: () => [
      {
        query: BookingQuery,
        variables: { bookingId: booking?.id },
      },
    ],
  });

  const [assignBookingContainer, { loading: assignLoading }] = useAssignBookingContainerMutation({
    onCompleted: (data) => {
      if (data.assignBookingContainer.success) {
        setSelectedContainer(null);
        enqueueSnackbar('Container Assigned!', {
          variant: 'success',
          anchorOrigin: {
            vertical: 'top',
            horizontal: 'right',
          },
        });
      }
    },
    refetchQueries: () => [
      {
        query: BookingQuery,
        variables: { bookingId: booking?.id },
      },
    ],
  });

  return (
    <Box borderTop="1px solid #EDEDED" display="flex" justifyContent="flex-end" padding={2}>
      <Button
        variant="contained"
        onClick={() => {
          setSelectedContainer(null);
        }}
        style={{
          marginRight: '24px',
          width: '100px',
        }}
      >
        Cancel
      </Button>
      <Button
        variant="contained"
        color="primary"
        style={{ width: '100px' }}
        disabled={hasIncompleteItems(selectedItems) || updateLoading || assignLoading}
        onClick={() => {
          const vanPos: GqlVanPositionInput[] = [];
          for (const itemPos of selectedItems) {
            vanPos.push({
              id: isUUID(itemPos.id) ? itemPos.id : undefined,
              piecesQty: itemPos.piecesQty,
              cartonsQty: itemPos.cartonsQty,
              volume: itemPos.volume,
              weight: itemPos.weight,
              vanCode: itemPos.vanPositionCode,
              packLineId: itemPos.packLine.id,
            });
          }
          if (selectedContainer?.containerNumber) {
            updateBookingContainer({
              variables: {
                input: {
                  container: {
                    id: selectedContainer?.id,
                    containerNumber: containerNumber,
                    containerType: containerType,
                    sealNumber: sealNumber,
                    shipFromFactoryDate: shipFromFactory,
                    terminalReceivedDate: terminalReceived,
                  },
                  vanPositions: vanPos,
                },
              },
            });
          } else {
            assignBookingContainer({
              variables: {
                input: {
                  bookingId: booking?.id || '',
                  container: {
                    id: selectedContainer?.id,
                    containerNumber: containerNumber,
                    containerType: containerType,
                    sealNumber: sealNumber,
                    shipFromFactoryDate: shipFromFactory,
                    terminalReceivedDate: terminalReceived,
                  },
                  vanPositions: vanPos,
                },
              },
            });
          }
        }}
      >
        Save
      </Button>
    </Box>
  );
}

function hasIncompleteItems(selectedItems: ItemPosition[]) {
  for (const item of selectedItems) {
    if (!item.packLine.id) {
      return true;
    }
  }
  return false;
}
