import { gqlClient } from 'api/ApolloClient';
import {
  GqlGlobalUpdateSearchQuery,
  GqlGlobalUpdateSearchQueryVariables,
  SearchType,
} from 'api/GQL_Types';
import { GlobalUpdateSearchQuery } from 'api/queries/globalSearchQueries';
import { FormInputAutocomplete } from 'components/form/FormInputAutocomplete';
import React from 'react';
import { mapSearchType } from 'utils/Enums';

export interface SearchResult {
  id: string;
  value: string;
}

export interface SearchState {
  waiting: boolean;
  error: string | null;
  results: SearchResult[];
}

export function useSearchAutocomplete({
  setSearch,
  searchType,
  searchText,
  numResults,
  result,
}: {
  setSearch: (state: SearchState) => void;
  searchType: SearchType;
  searchText: string;
  numResults: number;
  result: SearchResult | null;
}) {
  const searchQuery = searchText.trim();
  const isQueryLongEnough = searchQuery.length >= 2;
  const isResultSelected = searchText === result?.value;

  React.useEffect(() => {
    let canceled = false;
    let timeoutHandle: any;
    if (isQueryLongEnough && !isResultSelected) {
      timeoutHandle = setTimeout(() => {
        setSearch({
          waiting: true,
          error: null,
          results: [],
        });

        gqlClient
          .query<GqlGlobalUpdateSearchQuery, GqlGlobalUpdateSearchQueryVariables>({
            query: GlobalUpdateSearchQuery,
            variables: {
              searchType: searchType,
              query: searchQuery,
              numResults,
            },
            fetchPolicy: 'no-cache',
          })
          .then((data) => {
            if (canceled) return;
            setSearch({
              waiting: false,
              error: null,
              results: data.data.search,
            });
          })
          .catch((error) => {
            if (canceled) return;
            setSearch({
              waiting: false,
              error: error + '',
              results: [],
            });
          });
      }, 200);
    } else {
      setSearch({
        waiting: false,
        error: null,
        results: [],
      });
    }
    return () => {
      canceled = true;
      clearTimeout(timeoutHandle);
    };
  }, [searchType, searchQuery]);
}

interface Props {
  label?: string;
  searchType: SearchType;
  value: SearchResult | null;
  onValue(result: SearchResult | null): void;
  // If provided it will filter out results that don't have this id
  matchId?: string;
  required?: boolean;
}

export const FormInputSearch: React.FC<Props> = ({
  label,
  searchType,
  value,
  onValue,
  matchId,
  required,
}) => {
  label = label || mapSearchType(searchType);
  const [searchText, setSearchText] = React.useState<string>('');
  const [search, setSearch] = React.useState<SearchState>({
    waiting: false,
    error: null,
    results: [],
  });

  useSearchAutocomplete({
    setSearch,
    searchType,
    searchText,
    numResults: 10,
    result: value,
  });

  return (
    <FormInputAutocomplete<SearchResult>
      options={matchId ? search.results.filter((result) => result.id === matchId) : search.results}
      label={label}
      value={value}
      onValue={onValue}
      waiting={search.waiting}
      error={search.error}
      noValueMessage={'Search ' + label}
      onFilterQuery={setSearchText}
      displayResolver={(result) => result.value}
      required={required}
    />
  );
};
