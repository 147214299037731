import React from 'react';
import { Button } from '@material-ui/core';
import {
  GqlUpdateConsolidationLogisticsInput,
  useUpdateConsolidationLogisticsMutation,
} from 'api/GQL_Types';
import { useRecoilValue } from 'recoil';
import { VesselsQuery } from 'api/queries/logisticsQueries';
import { useSnackbar } from 'notistack';
import { ConsolidationPageStates } from '../../states';
import { ConsolidationQuery } from 'api/queries/consolidationQueries';

interface Props {
  onClick: Function;
}

export default function EditLogisticsSaveButton(props: Props) {
  const { enqueueSnackbar } = useSnackbar();
  const consolidation = useRecoilValue(ConsolidationPageStates.consolidation);
  const confirmationNumber = useRecoilValue(
    ConsolidationPageStates.editLogistics.confirmationNumber
  );
  const confirmationDate = useRecoilValue(ConsolidationPageStates.editLogistics.confirmationDate);
  const carrier = useRecoilValue(ConsolidationPageStates.editLogistics.carrier);
  const carrierConfirmationNumber = useRecoilValue(
    ConsolidationPageStates.editLogistics.carrierConfirmationNumber
  );
  const contract = useRecoilValue(ConsolidationPageStates.editLogistics.contract);
  const contractType = useRecoilValue(ConsolidationPageStates.editLogistics.contractType);

  //Hbl Mbl
  const hblPerBooking = useRecoilValue(ConsolidationPageStates.editLogistics.hblPerBooking);
  const mblNumber = useRecoilValue(ConsolidationPageStates.editLogistics.mbl);
  const mblPaymentType = useRecoilValue(ConsolidationPageStates.editLogistics.mblPaymentType);
  const mblReleaseType = useRecoilValue(ConsolidationPageStates.editLogistics.mblReleaseType);

  const cfsCutoff = useRecoilValue(ConsolidationPageStates.editLogistics.cfsCutoff);
  const cfsReceived = useRecoilValue(ConsolidationPageStates.editLogistics.cfsReceived);
  const cyCutoff = useRecoilValue(ConsolidationPageStates.editLogistics.cyCutoff);
  const vgmCutoff = useRecoilValue(ConsolidationPageStates.editLogistics.vgmCutoff);
  const siCutoff = useRecoilValue(ConsolidationPageStates.editLogistics.siCutoff);

  const pol = useRecoilValue(ConsolidationPageStates.editLogistics.pol);
  const polEtd = useRecoilValue(ConsolidationPageStates.editLogistics.polEtd);
  const polAtd = useRecoilValue(ConsolidationPageStates.editLogistics.polAtd);

  const transitPort = useRecoilValue(ConsolidationPageStates.editLogistics.transitPort);
  const transitPortEta = useRecoilValue(ConsolidationPageStates.editLogistics.transitPortEta);
  const transitPortAta = useRecoilValue(ConsolidationPageStates.editLogistics.transitPortAta);
  const transitPortEtd = useRecoilValue(ConsolidationPageStates.editLogistics.transitPortEtd);
  const transitPortAtd = useRecoilValue(ConsolidationPageStates.editLogistics.transitPortAtd);

  const pod = useRecoilValue(ConsolidationPageStates.editLogistics.pod);
  const podEta = useRecoilValue(ConsolidationPageStates.editLogistics.podEta);
  const ramp = useRecoilValue(ConsolidationPageStates.editLogistics.ramp);
  const rampEta = useRecoilValue(ConsolidationPageStates.editLogistics.rampEta);
  const finalDestination = useRecoilValue(ConsolidationPageStates.editLogistics.finalDestination);
  const deliveryEta = useRecoilValue(ConsolidationPageStates.editLogistics.deliveryEta);

  const motherVessel = useRecoilValue(ConsolidationPageStates.editLogistics.motherVessel);
  const motherVoyage = useRecoilValue(ConsolidationPageStates.editLogistics.motherVoyage);
  const feederVessel = useRecoilValue(ConsolidationPageStates.editLogistics.feederVessel);
  const feederVoyage = useRecoilValue(ConsolidationPageStates.editLogistics.feederVoyage);

  const [updateLogistics] = useUpdateConsolidationLogisticsMutation({
    onCompleted: (data) => {
      if (data.updateConsolidationLogistics.success) {
        enqueueSnackbar('Consolidation Updated', {
          variant: 'success',
          anchorOrigin: {
            vertical: 'top',
            horizontal: 'right',
          },
        });
      } else {
        enqueueSnackbar(
          'Failed to save logistics. ' + (data.updateConsolidationLogistics.message || ''),
          {
            variant: 'error',
            anchorOrigin: {
              vertical: 'top',
              horizontal: 'right',
            },
          }
        );
      }
    },
    onError(error) {
      enqueueSnackbar('Failed to save logistics. ' + error, {
        variant: 'error',
        anchorOrigin: {
          vertical: 'top',
          horizontal: 'right',
        },
      });
    },
    refetchQueries: () => [
      {
        query: ConsolidationQuery,
        variables: { consolId: consolidation?.id ?? '' },
        fetchPolicy: 'cache-and-network',
      },
      {
        query: VesselsQuery,
        fetchPolicy: 'cache-and-network',
      },
    ],
  });

  return (
    <Button
      variant="contained"
      color="primary"
      disabled={
        (!Boolean(motherVessel) && Boolean(motherVoyage)) ||
        (Boolean(motherVessel) && !Boolean(motherVoyage)) ||
        (!Boolean(feederVessel) && Boolean(feederVoyage)) ||
        (Boolean(feederVessel) && !Boolean(feederVoyage)) ||
        (!Boolean(confirmationNumber) && Boolean(confirmationDate)) ||
        (Boolean(confirmationNumber) && !Boolean(confirmationDate))
      }
      onClick={() => {
        const input: GqlUpdateConsolidationLogisticsInput = {
          consolId: consolidation?.id ?? '',
          logistics: {
            id: consolidation?.logistics.id,
            confirmationNumber: confirmationNumber,
            confirmationDate: confirmationDate,
            carrierId: carrier?.id,
            carrierConfirmationNumber: carrierConfirmationNumber,
            contractNumber: contract,
            contractType: contractType,
            cfsCutoffDate: cfsCutoff,
            cfsReceivedDate: cfsReceived,
            cyCutoffDate: cyCutoff,
            vgmCutoffDate: vgmCutoff,
            siCutoffDate: siCutoff,
            polCode: pol?.code,
            polEtd: polEtd,
            polAtd: polAtd,
            transitPortCode: transitPort?.code,
            transitPortEta: transitPortEta,
            transitPortAta: transitPortAta,
            transitPortEtd: transitPortEtd,
            transitPortAtd: transitPortAtd,
            podCode: pod?.code,
            podEta: podEta,
            rampCode: ramp?.code,
            rampEta: rampEta,
            finalDestinationId: finalDestination?.id,
            deliveryEta: deliveryEta,
          },
          hbls: hblPerBooking.map((row) => {
            return {
              bookingId: row.bookingId,
              hbl: row.hblReferenceNumber
                ? {
                    id: row.hblId,
                    referenceNumber: row.hblReferenceNumber,
                    releaseType: row.hblReleaseType,
                    paymentType: row.hblPaymentType,
                  }
                : undefined,
            };
          }),
          mbl: mblNumber
            ? {
                id: consolidation?.mbl?.id,
                referenceNumber: mblNumber,
                releaseType: mblReleaseType,
                paymentType: mblPaymentType,
              }
            : undefined,
          motherVessel: {
            // always send up an id, even if it's null. This way the backend can know when to remove it - OMS-104
            id: motherVessel?.id,
            name: motherVessel?.name,
          },
          motherVoyage: {
            id: motherVoyage?.id,
            name: motherVoyage?.name,
          },
          feederVessel: {
            id: feederVessel?.id,
            name: feederVessel?.name,
          },
          feederVoyage: {
            id: feederVoyage?.id,
            name: feederVoyage?.name,
          },
        };
        updateLogistics({
          variables: {
            input: input,
          },
        });

        props.onClick();
      }}
    >
      Save
    </Button>
  );
}
