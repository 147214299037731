import { Box, Button, Container } from '@material-ui/core';
import { useNetworkProfileTypeQuery, useNewNetworkConnectedProfileMutation } from 'api/GQL_Types';
import { NetworkQuery } from 'api/queries/networkQueries';
import { NewProfileForm } from 'app/admin/components/NewProfileForm';
import {
  useNewProfileFormState,
  useSetupNewProfileForm,
} from 'app/admin/components/NewProfileForm/states';
import ErrorMessage from 'components/ErrorMessage';
import UniversalDialog from 'components/UniversalDialog';
import { useSnackbar } from 'notistack';
import React from 'react';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import { newAtom } from 'recoil-utils/utils';
import { NetworkAdminPageStates } from '../states';

export const NetworkConnectionDialog_Open = newAtom(false);

export default function NetworkConnectionDialog() {
  const network = useRecoilValue(NetworkAdminPageStates.network);
  const [openDialog, setOpenDialog] = useRecoilState(NetworkConnectionDialog_Open);
  const [profileTypes, setProfileTypes] = React.useState<{ id: string; name: string }[]>([]);

  const { error: errorGettingProfileTypes } = useNetworkProfileTypeQuery({
    variables: {
      networkId: network?.id ?? '',
    },
    fetchPolicy: 'no-cache',
    onCompleted(data) {
      setProfileTypes(data.network?.profileTypes || []);
    },
  });

  const setupNewProfileForm = useSetupNewProfileForm();

  React.useEffect(() => {
    setupNewProfileForm();
  }, [openDialog]);

  return (
    <UniversalDialog
      open={openDialog}
      title="New Connection"
      large
      setClose={() => {
        setOpenDialog(false);
      }}
    >
      <Container>
        <Box marginBottom={2}>
          <ErrorMessage error={errorGettingProfileTypes ? 'Failed to get Profile Types' : null} />
          <NewProfileForm profileTypes={profileTypes} />
        </Box>
      </Container>
      <Box display="flex" alignItems="center" justifyContent="flex-end">
        <Button
          variant="contained"
          color="default"
          size="large"
          onClick={() => {
            setOpenDialog(false);
          }}
          style={{ marginRight: '12px' }}
        >
          Cancel
        </Button>
        <NewConnectionButton />
      </Box>
    </UniversalDialog>
  );
}

const NewConnectionButton: React.FC = () => {
  // NewConnectionButton is a separate component so only the button re-renders every time the form state changes
  const formState = useNewProfileFormState();

  const setOpen = useSetRecoilState(NetworkConnectionDialog_Open);
  const network = useRecoilValue(NetworkAdminPageStates.network);

  const { enqueueSnackbar } = useSnackbar();

  const [saveNewConnection, { loading }] = useNewNetworkConnectedProfileMutation({
    refetchQueries: () => [
      {
        query: NetworkQuery,
        variables: { networkId: network?.id },
      },
    ],
    onCompleted(data) {
      if (data.newNetworkConnectedProfile.success) {
        setOpen(false);
        enqueueSnackbar('Network Connection Created!', {
          variant: 'success',
          anchorOrigin: {
            vertical: 'top',
            horizontal: 'right',
          },
        });
      } else {
        enqueueSnackbar(data.newNetworkConnectedProfile.message || 'Failed to create profile.', {
          variant: 'error',
          anchorOrigin: {
            vertical: 'top',
            horizontal: 'right',
          },
        });
      }
    },
    onError(error) {
      enqueueSnackbar('Failed to create profile. ' + error, {
        variant: 'error',
        anchorOrigin: {
          vertical: 'top',
          horizontal: 'right',
        },
      });
    },
  });

  const profileTypeId = formState.profile.type.id || null;

  return (
    <Box>
      <Button
        variant="contained"
        color="primary"
        size="large"
        onClick={() => {
          saveNewConnection({
            variables: {
              input: {
                networkId: network?.id ?? '',
                connectedProfile: {
                  id: formState.profile.id,
                  name: formState.profile.name,
                  profileCode: formState.profile.code,
                  profileTypeId: profileTypeId,
                  logo: formState.profile.logo,
                },
                profileMainLocation: {
                  id: formState.location.id,
                  name: formState.location.name,
                  code: formState.location.code,
                  addressLine1: formState.location.addressLine1,
                  addressLine2: formState.location.addressLine2,
                  city: formState.location.city,
                  state: formState.location.state,
                  postalCode: formState.location.postalCode,
                  country: formState.location.country,
                  relatedPortCode: formState.location.relatedPortCode?.code,
                  companyPhone: formState.location.companyPhone,
                  companyUrl: formState.location.companyUrl,
                  locationTypes: formState.location.locationTypes,
                },
                profileMainContact: {
                  id: formState.contact.id,
                  firstName: formState.contact.firstName,
                  lastName: formState.contact.lastName,
                  title: formState.contact.title,
                  email: formState.contact.email,
                  phoneMain: formState.contact.phoneMain,
                  extension: formState.contact.extension,
                  phoneCell: formState.contact.phoneCell,
                  phoneAlternative: formState.contact.phoneAlternative,
                  isMainContact: true,
                  locationId: formState.location.id,
                },
              },
            },
          });
        }}
        disabled={
          loading ||
          !formState.profile.name ||
          !formState.profile.code ||
          !profileTypeId ||
          !formState.location.name ||
          !formState.location.locationTypes.length ||
          !formState.location.country
        }
      >
        Create Connection
      </Button>
    </Box>
  );
};
