import { PopoverActions, Box, Typography } from '@material-ui/core';
import {
  ContainersInTransitDisplay,
  containersInTransitDisplayColumns,
} from 'app/dashboard/states';
import UWLTableV2 from 'components/g-table/UWLTableV2';
import TableExportWindowGroup from 'components/TableExportWindowGroup';
import UniversalPopover from 'components/UniversalPopover';
import React from 'react';
import { theme } from 'styles';

interface Props {
  children?: any;
  data: ContainersInTransitDisplay[];
  rowHeight: number;
}

export const LeftLabelBox = (props: Props) => {
  const [selectedItemData, setSelectedItemData] = React.useState<any>();
  const [anchorEl, setAnchorEl] = React.useState<(EventTarget & HTMLElement) | null>(null);
  const [openPopover, setOpenPopover2] = React.useState(false);
  const action: React.RefObject<PopoverActions> | null = React.useRef(null);
  const [tableHeight, setTableHeight] = React.useState(0);

  React.useEffect(() => {
    if (action && action.current) {
      action.current.updatePosition();
    }
  }, [tableHeight]);

  return (
    <Box
      bgcolor={theme.palette.grayscale.light}
      style={{
        display: 'flex',
        padding: '0 24px',
        alignItems: 'center',
        height: props.rowHeight + 'px',
        marginTop: props.rowHeight * 0.2 + 'px',
        marginBottom: props.rowHeight * 0.2 + 'px',
      }}
    >
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        width="100%"
        style={{ cursor: 'pointer' }}
        onClick={(e: any) => {
          setOpenPopover2(true);
          setAnchorEl(e.currentTarget);
          setSelectedItemData(props.data);
        }}
      >
        <Typography variant="h4">{props.children}</Typography>
        <Typography variant="h2" color="textPrimary">
          {props.data.length}
        </Typography>
      </Box>
      <UniversalPopover
        popoverId="in-transit-total-graph-popover"
        open={openPopover}
        anchorEl={anchorEl}
        action={action}
        onClose={() => {
          setAnchorEl(null);
          setOpenPopover2(false);
        }}
        anchorOriginVertical={20}
        anchorOriginHorizontal={0}
      >
        <Box padding={3} width={window.innerWidth * 0.9} display="flex" flexDirection="column">
          <Box display="flex" justifyContent="space-between" marginBottom={2}>
            <Box marginRight={3} display="flex" alignItems="center">
              <Typography variant="h3" style={{ alignSelf: 'center' }}>
                Total {props.children}
              </Typography>
              <TableExportWindowGroup
                label="DueToArrive"
                rows={selectedItemData}
                columns={containersInTransitDisplayColumns}
              />
            </Box>

            <Box marginLeft={3}>
              <Box display="flex" alignItems="center">
                <Box marginRight={1}>
                  <Typography variant="h4">
                    <i>Total Shipments:</i>
                  </Typography>
                </Box>
                <Typography variant="h3">{selectedItemData?.length ?? 0}</Typography>
              </Box>
            </Box>
          </Box>
          <UWLTableV2
            rows={selectedItemData ?? []}
            columns={containersInTransitDisplayColumns}
            maxHeight={500}
            onHeightChange={(height) => {
              setTableHeight(height);
            }}
            emptyMessage={'EMPTY ' + (props.children as string).toUpperCase()}
          />
        </Box>
      </UniversalPopover>
    </Box>
  );
};
