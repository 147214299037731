import { Button } from '@material-ui/core';
import React from 'react';

interface Props {
  size?: 'small' | 'medium' | 'large';
  disabled?: boolean;
  onClick?: () => void;
}
export const DeleteButton: React.FC<Props> = ({ size, disabled, onClick, children }) => {
  return (
    <Button
      variant="outlined"
      size={size}
      disabled={disabled}
      style={{ borderColor: '#E6001F', color: '#E6001F' }}
      onClick={onClick}
    >
      {children}
    </Button>
  );
};
