import { Avatar, Box, IconButton, InputBase, Typography } from '@material-ui/core';
import SendIcon from '@material-ui/icons/Send';
import { sortBy } from 'lib/sort';
import React from 'react';
import { makeCss, theme } from 'styles';
import { formatDateTime } from 'utils/Dates';
import Panel from './Panel';

const classes = makeCss({
  root: {
    display: 'flex',
    '& > *': {
      margin: theme.spacing(1),
    },
  },
  systemUser: {
    color: '#ccc',
    fontStyle: 'italic',
  },
  avatarMain: {
    color: '#fff',
    backgroundColor: theme.palette.primary.main,
  },

  remarksInput: {
    margin: theme.spacing(1, 2),
    backgroundColor: '#F8F8F8',
  },

  inputBase: {
    padding: theme.spacing(1),
    flexGrow: 1,
  },

  sendBtn: {
    borderRadius: '0 4px 4px 0',
    color: '#fff',
  },
});

export interface Remark {
  id: string;
  text: string;
  createDate: Date | null;
  createdBy: {
    firstName?: string | null;
    lastName?: string | null;
    profile: { name: string };
  };
}

export function sortRemarks(remarks: Remark[]): Remark[] {
  return sortBy(
    remarks,
    (r) => {
      return r.createDate ? r.createDate.getTime() : 0;
    },
    'desc'
  );
}

interface Props {
  allowComments: boolean;
  title: string;
  remarks: Remark[];
  makeRemark: (text: string) => void;
}

export default function Remarks(props: Props) {
  const [newRemarkText, setNewRemarkText] = React.useState<string>('');
  const [isCollapsed, setIsCollapsed] = React.useState<boolean>(false);

  return (
    <Panel
      title={props.title}
      collapsible
      isCollapsed={isCollapsed}
      setIsCollapsed={setIsCollapsed}
    >
      {props.allowComments && (
        <Box className={classes.remarksInput} display="flex">
          <InputBase
            className={classes.inputBase}
            placeholder="Add Remark ..."
            inputProps={{ 'aria-label': 'search' }}
            fullWidth
            value={newRemarkText}
            onChange={(e) => setNewRemarkText(e.target.value)}
          />
          <Box
            className={classes.sendBtn}
            bgcolor={!newRemarkText ? theme.palette.grayscale.main : theme.palette.primary.main}
          >
            <IconButton
              aria-label="send"
              size="medium"
              color="primary"
              onClick={() => {
                props.makeRemark(newRemarkText);
                setNewRemarkText('');
              }}
              disabled={!newRemarkText ? true : false}
            >
              <SendIcon
                fontSize="default"
                style={{
                  color: !newRemarkText
                    ? theme.palette.grayscale.light
                    : theme.palette.common.white,
                }}
              />
            </IconButton>
          </Box>
        </Box>
      )}
      <Box marginTop={1} marginLeft={3} marginRight={3} maxHeight={200} overflow="auto">
        <RemarksList remarks={props.remarks} />
      </Box>
    </Panel>
  );
}

interface RemarksListProps {
  remarks: Remark[];
}

function RemarksList(props: RemarksListProps) {
  return (
    <>
      {props.remarks.map((r, index) => {
        const user = r.createdBy.firstName + ' ' + r.createdBy.lastName;
        return (
          <div key={index}>
            <Box key={index} display="flex" borderTop="1px solid #F2F2F2" padding={1}>
              <Avatar className={classes.avatarMain}>
                {user
                  .split(' ')
                  .map((n: any) => n[0])
                  .join('')}
              </Avatar>
              <Box paddingLeft={2} flexGrow={1}>
                <Box display="flex">
                  <Typography variant="h3" style={{ paddingRight: '8px' }}>
                    {user}
                  </Typography>
                  <Typography variant="h4">({r.createdBy.profile.name})</Typography>
                </Box>
                <Typography variant="body1">{r.text}</Typography>
              </Box>
              <Box minWidth="fit-content">
                <Typography variant="h4">{formatDateTime(r.createDate)}</Typography>
              </Box>
            </Box>
          </div>
        );
      })}
    </>
  );
}
