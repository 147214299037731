import React from 'react';
import { makeCss, theme } from 'styles';

const classes = makeCss({
  root: {
    display: 'flex',
    justifyContent: 'center',
    margin: theme.spacing(1.5),
  },
});

export const FormRow: React.FC = (props) => {
  return <div className={classes.root}>{props.children}</div>;
};
