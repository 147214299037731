import * as React from 'react';
import useIsMountedRef from './useIsMountedRef';

export interface AsyncActionState {
  called: boolean;
  waiting: boolean;
  error: string | null;
  finished: boolean;
}

export type AsyncActionResult<R> = { type: 'error'; error: string } | { type: 'data'; data: R };

export interface AsyncAction<P, R> extends AsyncActionState {
  act(params: P): Promise<AsyncActionResult<R>>;
  reset(): void;
}

export function useAsyncAction<P, R>(
  action: (params: P) => Promise<R>,
  finishedTimeout?: number
): AsyncAction<P, R> {
  const isMountedRef = useIsMountedRef();

  const [state, setState] = React.useState<AsyncActionState>({
    called: false,
    waiting: false,
    error: null,
    finished: false,
  });

  return { ...state, act, reset };

  async function act(params: P): Promise<AsyncActionResult<R>> {
    try {
      setState({ called: true, waiting: true, error: null, finished: false });
      let ret = await action(params);
      if (isMountedRef.current) {
        setState({ called: true, waiting: false, error: null, finished: true });

        if (
          typeof finishedTimeout === 'number' &&
          // eslint-disable-next-line
          finishedTimeout === finishedTimeout && // NaN check
          finishedTimeout > 0
        ) {
          setTimeout(() => {
            if (isMountedRef.current && !state.waiting && !state.error) {
              reset();
            }
          }, finishedTimeout);
        }
      }
      return { type: 'data', data: ret };
    } catch (err) {
      const error = err + '';
      if (isMountedRef.current) {
        setState({ called: true, waiting: false, error, finished: false });
      }
      // NOTE: Not throwing an error.
      // b/c most times you don't want to `.act().catch(()=>null)` just to avoid Unhandled Promise Rejection error.
      return { type: 'error', error };
    }
  }

  async function reset() {
    if (isMountedRef.current) {
      setState({ called: false, waiting: false, error: null, finished: false });
    }
  }
}
