import CircularProgress from '@material-ui/core/CircularProgress';
import Tooltip from '@material-ui/core/Tooltip';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import WarningIcon from '@material-ui/icons/Warning';
import { GqlPoImportFileStatusFragment, useSetHeaderMutation } from 'api/GQL_Types';
import { useSnackbar } from 'notistack';
import React from 'react';
import { makeCss } from 'styles';
import { ColMappingPopover } from './ColMappingPopover';
import { Header, PoImportFieldGroup } from './reviewUploadStore';

const classes = makeCss({
  cell: {
    display: 'flex',
    alignItems: 'center',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
  },
  mappingIconWrap: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '24px',
  },
  name: {
    textTransform: 'none',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
  },
});

interface Props {
  header: Header;
  poImportFieldGroups: PoImportFieldGroup[];
  keysAlreadyMapped: string[];
  fileStatusData: GqlPoImportFileStatusFragment;
  setFileStatusData(data: GqlPoImportFileStatusFragment): void;
  allowedToMapFields: boolean;
}

export const TableHeaderCell: React.FC<Props> = ({
  header,
  poImportFieldGroups,
  keysAlreadyMapped,
  fileStatusData,
  setFileStatusData,
  allowedToMapFields,
}) => {
  const [anchorEl, setAnchorEl] = React.useState<Element | null>(null);
  const popoverId = anchorEl ? 'popover-' + header.id : undefined;
  const { enqueueSnackbar } = useSnackbar();

  const [setHeader, { loading }] = useSetHeaderMutation({
    onCompleted(data) {
      setFileStatusData(data.setHeaderMapping);
    },
    onError(error) {
      enqueueSnackbar('Failed to map column: ' + error.message, {
        variant: 'error',
        anchorOrigin: {
          vertical: 'top',
          horizontal: 'right',
        },
      });
    },
  });

  return (
    <div>
      <div className={classes.cell}>
        <div
          aria-describedby={popoverId}
          className={classes.mappingIconWrap}
          onClick={(e) => {
            e.stopPropagation();
            if (!allowedToMapFields) {
              return;
            }
            if (loading) {
              return;
            }
            if (header.mapping) {
              setHeader({
                variables: {
                  headerId: header.id,
                  omsKey: null,
                },
              });
            } else {
              setAnchorEl(e.currentTarget);
            }
          }}
        >
          <Tooltip
            placement="top"
            arrow
            title={
              loading ? (
                'Mapping...'
              ) : header.mapping ? (
                <div>
                  Mapped to:{' '}
                  <b>
                    {header.mapping.group.name} - {header.mapping.name}
                  </b>
                  <div>Click to undo mapping.</div>
                </div>
              ) : (
                'Not Mapped'
              )
            }
          >
            {loading ? (
              <CircularProgress size="16px" />
            ) : !header.mapping ? (
              <WarningIcon htmlColor="#ffd758" style={{ height: '20px' }} />
            ) : (
              <CheckCircleIcon style={{ height: '20px' }} />
            )}
          </Tooltip>
        </div>

        <div className={classes.name}>{header.name}</div>

        {header.errors.length > 0 && (
          <Tooltip
            placement="top"
            arrow
            title={header.errors.length === 1 ? '1 Error' : `${header.errors.length} Errors`}
          >
            <WarningIcon htmlColor="#ff4e4e" style={{ height: '20px' }} />
          </Tooltip>
        )}
      </div>

      {anchorEl && (
        <ColMappingPopover
          popoverId={popoverId}
          open={true}
          anchorEl={anchorEl}
          onClose={() => setAnchorEl(null)}
          onSelected={(omsKey) => {
            setHeader({
              variables: {
                headerId: header.id,
                omsKey: omsKey,
              },
            });
            setAnchorEl(null);
          }}
          keysAlreadyMapped={keysAlreadyMapped}
          poImportFieldGroups={poImportFieldGroups}
        />
      )}
    </div>
  );
};
