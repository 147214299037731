import { faCalendarAlt } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Box, Typography } from '@material-ui/core';
import { GqlDashboardBookingSummary, useBookingSummaryLazyQuery } from 'api/GQL_Types';
import { DashboardPageStates } from 'app/dashboard/states';
import {
  calculateDateRange,
  FormInputDateRange,
  presetsDashboard,
} from 'components/form/FormInputDateRange';
import React from 'react';
import { useRecoilState, useSetRecoilState } from 'recoil';
import { theme } from 'styles';
import NewBookingsButton from '../../alerts/buttons/NewBookingsButton';
import NewDocumentsButton from '../../alerts/buttons/NewDocumentsButton';

interface Props {}

export default function DashboardBookingsHeading(props: Props) {
  const [dateRange, setDateRange] = useRecoilState(DashboardPageStates.booking.dateRange);
  const setBookings = useSetRecoilState(DashboardPageStates.booking.bookingSummaries);

  const [refetchSummary] = useBookingSummaryLazyQuery({
    fetchPolicy: 'no-cache',
    onCompleted(data) {
      setBookings(data.bookingSummary as GqlDashboardBookingSummary[]);
    },
  });

  const { from: startDate, to: endDate } = calculateDateRange(dateRange);

  React.useEffect(() => {
    refetchSummary({
      variables: {
        startDate,
        endDate,
      },
    });
  }, [startDate.toISOString(), endDate.toISOString()]);

  return (
    <Box
      paddingX={2}
      paddingY={1}
      display="flex"
      justifyContent="space-between"
      alignItems="center"
      width="100%"
    >
      <Box display="flex" justifyContent="flex-start" alignItems="center">
        <Typography variant="h2" color="primary">
          <Box component="span" marginRight={2}>
            <FontAwesomeIcon icon={faCalendarAlt} />
          </Box>
          Bookings
        </Typography>
        <div style={{ margin: theme.spacing(0, 2) }}>
          <NewBookingsButton />
        </div>
        <NewDocumentsButton />
      </Box>

      <Box width={250}>
        <FormInputDateRange
          label="Date Range"
          presets={presetsDashboard}
          value={dateRange}
          onValue={(range) => {
            if (range) {
              setDateRange(range);
            }
          }}
        />
      </Box>
    </Box>
  );
}
