import React from 'react';
import { Box, Typography, Button } from '@material-ui/core';
import { GqlPackLine } from 'api/GQL_Types';
import { BookingPageStates } from 'app/bookings/details/states';
import { useRecoilState } from 'recoil';
import AddIcon from '@material-ui/icons/Add';

let itemId = 0;

export default function AddItemButton() {
  const [selectedItems, setSelectedItems] = useRecoilState(
    BookingPageStates.containerAssignment.editContainer.selectedBookingPackLines
  );

  return (
    <Box marginBottom={1} display="flex" justifyContent="space-between" alignItems="center">
      <Typography variant="h3" color="secondary">
        ITEMS
      </Typography>
      <Button
        onClick={() => {
          const newItems = Array.from(selectedItems);
          newItems.push({
            id: itemId++ + '',
            packLine: {} as GqlPackLine,
            piecesQty: 0,
            cartonsQty: 0,
            volume: 0,
            weight: 0,
            vanPositionCode: null,
          });
          setSelectedItems(newItems);
        }}
      >
        <AddIcon style={{ color: '#043c5f', width: '40px', height: '40px' }} />
        Add Item
      </Button>
    </Box>
  );
}
