import { DashboardFeatureCode, GqlDashboardBookingSummary } from 'api/GQL_Types';
import { userContextAtom } from 'app';
import React from 'react';
import { useRecoilValue } from 'recoil';
import { formatTime } from 'utils/Dates';
import { ContainerGraphDataPoint, GraphPlot } from './GraphPlot';
import RangeXYPlot from './RangeXYPlot';

interface Props {
  weeks: GqlDashboardBookingSummary[];
  containerWidth: number;
  containerHeight: number;
  rowHeight?: number;
}

const defaultYMax = 10;

export default function DashboardBookingsSummary(props: Props) {
  const userContext = useRecoilValue(userContextAtom);
  const [containerData, setContainerData] = React.useState<ContainerGraphDataPoint[]>([]);
  const [yData, setYData] = React.useState<ContainerGraphDataPoint[]>([]);
  const [tickValues] = React.useState<number[]>([]);
  const [yMax, setYMax] = React.useState<number>(0);
  const [todayY, setTodayY] = React.useState<number>(0);
  const [currentWeek, setCurrentWeek] = React.useState<string>('');

  React.useEffect(() => {
    if (props.weeks.length > 0) {
      let max = 0;
      const newContainerData: ContainerGraphDataPoint[] = [];
      let todayLabel = '';
      for (const week of props.weeks) {
        const year1 = formatTime(week.start, 'yyyy');
        const year2 = formatTime(week.end, 'yyyy');
        const yearLabel = year1 === year2 ? year1 : year1 + ' - ' + year2;
        const weekLabel = `WK ${week.weekNumber}_${
          formatTime(week.start, 'LL/dd') + ' - ' + formatTime(week.end, 'LL/dd')
        }_${yearLabel}`;
        const popoverLabel = `WEEK ${week.weekNumber}: (${
          formatTime(week.start, 'LLL dd') + ' - ' + formatTime(week.end, 'LLL dd')
        }) ${yearLabel}`;
        const number = week.containers.length;
        const today = Date.now();
        const start = week.start?.getTime() || 0;
        const end = week.end?.getTime() || 0;
        if (today >= start && today <= end) {
          todayLabel = weekLabel + '_Today';
          newContainerData.push({
            x: todayLabel,
            y: number,
            containers: week.containers,
            popoverLabel,
          });
          setTodayY(number);
        } else {
          newContainerData.push({
            x: weekLabel,
            y: number,
            containers: week.containers,
            popoverLabel,
          });
        }
        if (max < number) {
          max = number;
        }
      }
      setCurrentWeek(todayLabel);
      setYMax(max > defaultYMax ? max + max / defaultYMax : defaultYMax);
      setContainerData(newContainerData);
    }
  }, [props.weeks]);

  React.useEffect(() => {
    if (yMax >= 0) {
      tickValues.length = 0;
      const yTickOffset = yMax / 5;
      let currentTick = 0;

      while (currentTick < yMax) {
        tickValues.push(currentTick);
        currentTick += yTickOffset;
      }
      tickValues.push(yMax);

      setYData([
        {
          x: containerData[0]?.x ?? '',
          y: 0,
        },
        {
          x: containerData[0]?.x ?? '',
          y: yMax,
        },
      ]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [yMax]);

  return (
    <>
      {userContext?.dashboardFeatureCodes.has(DashboardFeatureCode.ChartBookingsByWeek) && (
        <div style={{ display: 'flex', height: '100%' }}>
          <RangeXYPlot containerHeight={props.containerHeight} yData={yData} tickValues={tickValues} />
          <GraphPlot
            width={props.containerWidth - 138}
            height={props.containerHeight}
            containerData={containerData}
            currentWeek={currentWeek}
            tickValues={tickValues}
            yData={yData}
            labelSeriesY={todayY < yMax / 2 ? yMax * (3 / 4) : yMax / 4}
          />
      </div>
      )}
    </>
  );
}
