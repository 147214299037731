import { Box, Grid, Typography } from '@material-ui/core';
import { Link } from '@reach/router';
import {
  GqlBooking,
  GqlConsolidation,
  GqlMasterBill,
  PartyType,
  PermissionCode,
} from 'api/GQL_Types';
import { userContextAtom } from 'app';
import LineItem from 'components/LineItem';
import Panel from 'components/Panel';
import { useRecoilValue } from 'recoil';
import { mapIncoTerm, mapMoveType } from 'utils/Enums';
import { EquipmentPageStates } from './states';

export default function EquipmentDetailsPanel() {
  const userContext = useRecoilValue(userContextAtom);
  const container = useRecoilValue(EquipmentPageStates.container);

  if (!container) {
    return null;
  }

  const incoTerm = (container?.shipment as GqlBooking).incoTerm;

  return (
    <Box width="100%" height="100%" display="flex">
      <Panel title="Equipment Details" growPanel>
        <Box padding={3}>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={6}>
              <LineItem value={container?.containerNumber} label="Container" />
              <LineItem value={container?.sealNumber} label="Seal" />
              <LineItem
                value={
                  container?.shipment.relatedParties.find(
                    (rp) => rp.partyType === PartyType.Supplier
                  )?.party.name
                }
                label="Supplier"
              />
              <LineItem value={container?.shipment.logistics.motherVessel?.name} label="Vessel" />
              <LineItem<GqlMasterBill>
                value={(container?.shipment as GqlBooking | GqlConsolidation)?.mbl}
                label="MBL"
                customValue={(value) => {
                  return userContext?.permissionCodes.has(PermissionCode.MblRead) ? (
                    <Link to={'/mbl/' + value?.id}>{value?.referenceNumber}</Link>
                  ) : (
                    <Typography variant="body1">{value?.referenceNumber}</Typography>
                  );
                }}
              />
              <LineItem
                value={
                  container?.shipment.logistics.moveType
                    ? mapMoveType(container?.shipment.logistics.moveType)
                    : ''
                }
                label="Delivery Type"
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <LineItem value={container?.containerType ?? ''} label="Equipment" />
              <LineItem
                value={container?.shipment.logistics.pickupLocation?.name}
                label="Place of Receipt"
              />
              <LineItem
                value={
                  container?.shipment.relatedParties.find(
                    (rp) => rp.partyType === PartyType.Consignee
                  )?.party.name
                }
                label="Consignee"
              />
              <LineItem value={container?.shipment.logistics.carrier?.name} label="Carrier" />
              <LineItem value={container?.shipment.logistics.motherVoyage?.name} label="Voyage" />
              {(container?.shipment as GqlBooking | GqlConsolidation).__typename === 'Booking' &&
                incoTerm && <LineItem value={mapIncoTerm(incoTerm)} label="INCO Term" />}
            </Grid>
          </Grid>
        </Box>
      </Panel>
    </Box>
  );
}
