import {
  Box,
  Collapse,
  Divider,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Typography,
} from '@material-ui/core';
import { Link, useLocation } from '@reach/router';
import { auth } from 'app';
import { ManagePageHeader_BackStack } from 'app/admin/components/ManagePageHeader';
import { LinkItem } from 'types/LinkItem';
import React from 'react';
import { useSetRecoilState } from 'recoil';
import styled from 'styled-components';
import { availableLinks } from '../states';
import { sidenavStyle } from './navstyles';

interface Props {
  open: boolean;
}

const NavLink = styled(Link)`
  display: flex;
  color: #ffffff;
  text-decoration: none;
`;

export default function NavItems(props: Props) {
  const authState = auth.useAuthState();
  const location = useLocation();
  const [linksAvailable, setLinksAvailable] = React.useState<LinkItem[]>([]);
  const [current, setCurrent] = React.useState<string>('');
  const setBackStack = useSetRecoilState(ManagePageHeader_BackStack);

  const classes = sidenavStyle();

  React.useEffect(() => {
    setLinksAvailable(availableLinks(authState?.userContext || null));
  }, [authState?.userContext]);

  React.useEffect(() => {
    if (location.pathname) {
      setCurrent(location.pathname);
    }
  }, [location]);

  return (
    <List className={classes.listMenu}>
      <Box className={classes.listItems} onClick={() => setBackStack([])}>
        {linksAvailable.map((item, index) => {
          return (
            <div key={index}>
              <NavLink to={item.path}>
                <ListItem
                  button
                  dense
                  className={
                    current === item.path
                      ? classes.selected
                      : item.path !== '/' && current.startsWith(item.path)
                      ? classes.selected
                      : classes.unSelected
                  }
                >
                  <ListItemIcon>
                    <Box
                      component="span"
                      fontSize="30px"
                      marginTop={1}
                      marginBottom={1}
                      paddingLeft="4px"
                      className="material-icons"
                      color="#fff"
                    >
                      {item.icon}
                    </Box>
                  </ListItemIcon>
                  <Typography variant="h3" color="textSecondary" style={{ alignSelf: 'center' }}>
                    {item.name}
                  </Typography>
                </ListItem>
              </NavLink>
              {item.subNav.length > 0 && (
                <Collapse in={props.open} timeout="auto" unmountOnExit>
                  <Divider />

                  <List component="div" disablePadding>
                    {item.subNav.map((subNav, index) => {
                      return (
                        <NavLink key={subNav.name} to={subNav.path}>
                          <ListItem
                            dense
                            key={subNav.name}
                            button
                            className={
                              current === subNav.path ? classes.selected : classes.unSelected
                            }
                          >
                            <ListItemText inset>
                              <Typography variant="h4" color="textSecondary">
                                {subNav.name}
                              </Typography>
                            </ListItemText>
                          </ListItem>
                        </NavLink>
                      );
                    })}
                  </List>
                </Collapse>
              )}
            </div>
          );
        })}
      </Box>
    </List>
  );
}
