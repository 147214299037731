import { PopoverActions, Box, Typography } from '@material-ui/core';
import {
  ContainersInTransitDisplay,
  containersInTransitDisplayColumns,
} from 'app/dashboard/states';
import UWLTableV2 from 'components/g-table/UWLTableV2';
import TableExportWindowGroup from 'components/TableExportWindowGroup';
import UniversalPopover from 'components/UniversalPopover';
import React from 'react';

interface Props {
  children?: any;
  popoverLabel: string;
  data: ContainersInTransitDisplay[];
  color: string;
  delivery?: boolean;
  rowHeight: number;
}

export const CenterLabelBox = (props: Props) => {
  const [selectedItemData, setSelectedItemData] = React.useState<any>();
  const [anchorEl, setAnchorEl] = React.useState<(EventTarget & HTMLElement) | null>(null);
  const [openPopover, setOpenPopover] = React.useState(false);
  const action: React.RefObject<PopoverActions> | null = React.useRef(null);
  const [tableHeight, setTableHeight] = React.useState(0);

  React.useEffect(() => {
    if (action && action.current) {
      action.current.updatePosition();
    }
  }, [tableHeight]);

  return (
    <>
      <Box
        onClick={(e) => {
          setOpenPopover(true);
          setAnchorEl(e.currentTarget);
          setSelectedItemData(props.data);
        }}
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          backgroundColor: props.color,
          height: props.rowHeight + 'px',
          minWidth: !props.delivery ? '33.3%' : '100%',
          marginTop: props.rowHeight * 0.2 + 'px',
          marginBottom: props.rowHeight * 0.2 + 'px',
          cursor: 'pointer',
        }}
      >
        <Typography variant="h2" color="textSecondary">
          {props.children}
        </Typography>
      </Box>
      <UniversalPopover
        popoverId="booking-graph-popover"
        open={openPopover}
        action={action}
        anchorEl={anchorEl}
        onClose={() => {
          setAnchorEl(null);
          setOpenPopover(false);
        }}
        anchorOriginVertical={20}
        anchorOriginHorizontal={0}
      >
        <Box padding={3} width={window.innerWidth * 0.9} display="flex" flexDirection="column">
          <Box display="flex" justifyContent="space-between" marginBottom={2}>
            <Box marginRight={3} display="flex" alignItems="center">
              <Typography variant="h3">{props.popoverLabel}</Typography>
              <TableExportWindowGroup
                label={props.popoverLabel}
                rows={selectedItemData}
                columns={containersInTransitDisplayColumns}
              />
            </Box>
            <Box marginLeft={3}>
              <Box display="flex" alignItems="center">
                <Box marginRight={1}>
                  <Typography variant="h4">
                    <i>Total Shipments:</i>
                  </Typography>
                </Box>
                <Typography variant="h3">{selectedItemData?.length ?? 0}</Typography>
              </Box>
            </Box>
          </Box>
          <UWLTableV2
            rows={selectedItemData ?? []}
            columns={containersInTransitDisplayColumns}
            maxHeight={500}
            onHeightChange={(height) => {
              setTableHeight(height);
            }}
            emptyMessage={'EMPTY ' + props.popoverLabel.toUpperCase()}
          />
        </Box>
      </UniversalPopover>
    </>
  );
};
