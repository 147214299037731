import { MutationFunction } from '@apollo/client';
import { Box, Button, Container } from '@material-ui/core';
import {
  NotificationCode,
  DashboardFeatureCode,
  GqlNewNetworkProfileTypeMutation,
  GqlNewNetworkProfileTypeMutationVariables,
  PermissionCode,
  useNewNetworkProfileTypeMutation,
} from 'api/GQL_Types';
import { NetworkQuery } from 'api/queries/networkQueries';
import NotificationCodeSwitches from 'app/admin/components/NotificationCodeSwitches';
import { DashboardFeatureSwitches } from 'app/admin/components/DashboardFeatureSwitches';
import PermissionsComponent from 'app/admin/components/PermissionsComponent';
import { AppWideStates } from 'app/states';
import AtomicTextFieldV2 from 'components/atomic/AtomicTextFieldV2';
import { FormRow } from 'components/form/FormRow';
import { Line } from 'components/StyledComponents';
import UniversalDialog from 'components/UniversalDialog';
import { useSnackbar } from 'notistack';
import React from 'react';
import { useRecoilState, useRecoilValue } from 'recoil';
import { NewProfileTypeDialogStates, useNewProfileTypeFormValues } from './states';

const ALL_PermissionCodes = Object.values(PermissionCode);
const ALL_NotificationCodes = Object.values(NotificationCode);
const ALL_DashboardFeatureCode = Object.values(DashboardFeatureCode);

export const NewProfileTypeDialog: React.FC<{}> = () => {
  const [dialog, setDialog] = useRecoilState(NewProfileTypeDialogStates.dialog);

  const [permissionCodes, setPermissionCodes] = useRecoilState(
    NewProfileTypeDialogStates.form.permissionCodes
  );
  const [notificationCodes, setNotificationCodes] = useRecoilState(
    NewProfileTypeDialogStates.form.notificationCodes
  );
  const [dashFeatureCodes, setDashFeatureCodes] = useRecoilState(
    NewProfileTypeDialogStates.form.dashFeatureCodes
  );

  const { enqueueSnackbar } = useSnackbar();

  const [createNetworkProfileType, { loading }] = useNewNetworkProfileTypeMutation({
    refetchQueries: () => [
      {
        query: NetworkQuery,
        variables: { networkId: dialog?.networkId },
      },
    ],
    onCompleted(data) {
      if (data.newNetworkProfileType.success) {
        setDialog(null);
      } else {
        enqueueSnackbar(data.newNetworkProfileType.message, {
          variant: 'error',
          anchorOrigin: {
            vertical: 'top',
            horizontal: 'right',
          },
        });
      }
    },
    onError(error) {
      enqueueSnackbar(error + '', {
        variant: 'error',
        anchorOrigin: {
          vertical: 'top',
          horizontal: 'right',
        },
      });
    },
  });

  if (!dialog) {
    return null;
  }

  return (
    <UniversalDialog
      open
      title="New Profile Type"
      large
      setClose={() => {
        setDialog(null);
      }}
    >
      <Container>
        <Box marginBottom={2}>
          <FormRow>
            <AtomicTextFieldV2
              state={NewProfileTypeDialogStates.form.name}
              required
              label="Profile Type Name"
            />
          </FormRow>
          <FormRow>
            <AtomicTextFieldV2
              state={NewProfileTypeDialogStates.form.description}
              label="Description"
              multiline
              rows={5}
            />
          </FormRow>
          <FormRow>
            <PermissionsComponent
              permissionsToDisplay={ALL_PermissionCodes}
              permissionsSet={permissionCodes}
              onPermissionToggle={(perm, checked) => {
                let newPermissions = [...permissionCodes];
                if (checked) {
                  newPermissions.push(perm);
                } else {
                  newPermissions = permissionCodes.filter((p) => p !== perm);
                }
                setPermissionCodes(newPermissions);
              }}
            />
          </FormRow>
          <FormRow>
            <NotificationCodeSwitches
              options={ALL_NotificationCodes}
              values={notificationCodes}
              onValues={setNotificationCodes}
            />
          </FormRow>
          <FormRow>
            <DashboardFeatureSwitches
              options={ALL_DashboardFeatureCode}
              values={dashFeatureCodes}
              onValues={setDashFeatureCodes}
            />
          </FormRow>
        </Box>
      </Container>
      <Line height={1} />
      <Box display="flex" alignItems="center" justifyContent="flex-end">
        <Button
          color="default"
          disabled={loading}
          onClick={() => setDialog(null)}
          style={{ marginRight: '12px' }}
        >
          Cancel
        </Button>
        <SaveButton
          loading={loading}
          networkId={dialog.networkId}
          createNetworkProfileType={createNetworkProfileType}
        />
      </Box>
    </UniversalDialog>
  );
};

const SaveButton: React.FC<{
  loading: boolean;
  networkId: string;
  createNetworkProfileType: MutationFunction<
    GqlNewNetworkProfileTypeMutation,
    GqlNewNetworkProfileTypeMutationVariables
  >;
}> = ({ loading, networkId, createNetworkProfileType }) => {
  const values = useNewProfileTypeFormValues();
  const documentTypes = useRecoilValue(AppWideStates.network.documentTypes).all;
  return (
    <Button
      variant="contained"
      color="primary"
      disabled={
        loading ||
        !values.name ||
        values.permissionCodes.length === 0 ||
        values.notificationCodes.length === 0 ||
        values.dashFeatureCodes.length === 0
      }
      onClick={() => {
        createNetworkProfileType({
          variables: {
            input: {
              networkId: networkId,
              profileType: {
                name: values.name,
                description: values.description,
                permissionCodes: values.permissionCodes,
                notificationCodes: values.notificationCodes,
                dashboardFeatureCodes: values.dashFeatureCodes,
              },
              roles: [
                {
                  name: values.name,
                  description: values.description,
                  permissionCodes: values.permissionCodes,
                  notificationCodes: values.notificationCodes,
                  dashboardFeatureCodes: values.dashFeatureCodes,
                  documentTypesCanView: documentTypes.map((dt) => dt.code),
                },
              ],
            },
          },
        });
      }}
    >
      Create Profile Type
    </Button>
  );
};
