import { NetworkQuery } from 'api/queries/networkQueries';
import ContactCard from 'app/admin/components/ContactCard';
import ProfileContactDialog, {
  openContactDialog_Data,
} from 'app/admin/components/dialogs/ProfileContactsDialog';
import { contactFilter } from 'app/admin/filters';
import { AdminPagesContact } from 'app/admin/states';
import { RouteComponentProps } from '@reach/router';
import { Box, Grid, Typography } from '@material-ui/core';
import SearchBar from 'components/SearchBar';
import React from 'react';
import { selector, useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import { genKey, newAtom } from 'recoil-utils/utils';
import { theme } from 'styles';
import { NetworkAdminPageStates } from '../states';
import { Line } from 'components/StyledComponents';

const contactSearchState = newAtom('');
const contactsListState = newAtom<ContactCardData[]>([]);

interface ContactCardData {
  contact: AdminPagesContact;
  profileId: string;
  profileName: string;
  roles: { id: string; name: string }[];
  locations: { id: string; name: string }[];
}

const filteredNContactsListState = selector({
  key: genKey(),
  get: ({ get }) => {
    const searchField = get<string>(contactSearchState);
    const contacts = get(contactsListState);

    if (searchField === '') {
      return contacts;
    } else {
      const filterFn = contactFilter(searchField);
      return contacts.filter((c) => {
        return filterFn(c.contact);
      });
    }
  },
});

interface Props extends RouteComponentProps {}
export default function NetworkContacts(props: Props) {
  const setContactDialog = useSetRecoilState(openContactDialog_Data);
  const network = useRecoilValue(NetworkAdminPageStates.network);
  const [searchField, setSearchField] = useRecoilState(contactSearchState);
  const setContacts = useSetRecoilState<any>(contactsListState);
  const filteredContacts = useRecoilValue(filteredNContactsListState);

  React.useEffect(() => {
    if (network?.connectedProfiles) {
      const contacts = (network?.connectedProfiles).reduce((contactArray: any[], profile) => {
        return contactArray.concat(
          profile.contacts?.map((contact: any) => {
            return {
              contact,
              locations: profile.locations,
              roles: profile.roles,
              profileId: profile.id,
              profileName: profile.name,
            };
          })
        );
      }, []);
      const sorted = contacts.sort((value) => {
        return value.isUserEnabled ? -1 : 1;
      });
      setContacts(sorted);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [network]);

  return (
    <div>
      <Box display="flex" justifyContent="space-between">
        <Box>
          <Typography variant="h2">Network Contacts</Typography>
          <Typography variant="body1">
            List of contacts that are connected to this network
          </Typography>
        </Box>
        <Box width="25%" bgcolor={theme.palette.background.default} padding={1} alignSelf="center">
          <SearchBar
            placeholder="Search Network Contacts"
            field={searchField}
            updateField={setSearchField}
          />
        </Box>
      </Box>
      <Line height={2} color={theme.palette.primary.main} />
      <Grid container spacing={3}>
        {filteredContacts.map((contact, index) => {
          return (
            <ContactCard
              key={contact.contact.id}
              contact={contact.contact}
              profileName={contact.profileName}
              onManageClicked={() => {
                // TODO VT: Check the permission to update like in `ProfileContactCards.tsx`
                setContactDialog({
                  profileId: contact.profileId,
                  contact: contact.contact,
                  roles: contact.roles,
                  locations: contact.locations,
                });
              }}
            />
          );
        })}
        <ProfileContactDialog
          refetchQuery={{
            query: NetworkQuery,
            variables: { networkId: network?.id },
            fetchPolicy: 'cache-and-network',
          }}
        />
      </Grid>
    </div>
  );
}
