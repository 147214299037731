import { Box, Typography } from '@material-ui/core';
import { GqlRelatedParty, PartyType } from 'api/GQL_Types';
import Panel from 'components/Panel';
import { Line } from 'components/StyledComponents';
import { UWLTable } from 'components/UWLTable/UWLTable';
import React from 'react';
import { useRecoilValue } from 'recoil';
import { UWLTableColumn } from 'types/UWLTable';
import { mapMoveType, mapShipmentStatus } from 'utils/Enums';
import { formatNumber, formatNumberDecimalPlaces } from 'utils/Numbers';
import { Bookings, ConsolidationPageStates } from '../states';

const columns: UWLTableColumn<Bookings>[] = [
  { id: 'referenceNumber', label: 'Booking', type: 'link', whiteSpace: 'nowrap' },
  { id: 'supplierName', label: 'Supplier', type: 'string', whiteSpace: 'nowrap' },
  { id: 'bookingDate', label: 'Booking Date', type: 'date' },
  { id: 'weight', label: 'Wgt', type: 'number', align: 'left' },
  { id: 'volume', label: 'CBM', type: 'number', align: 'left' },
  { id: 'bookingStatus', label: 'Booking Status', type: 'string', whiteSpace: 'nowrap' },
  { id: 'cargoReadyDate', label: 'Cgo Rdy Date', type: 'date' },
  { id: 'pol', label: 'POL', type: 'string', whiteSpace: 'nowrap' },
  { id: 'polEtd', label: 'ETD', type: 'date' },
  { id: 'pod', label: 'POD', type: 'string', whiteSpace: 'nowrap' },
  { id: 'finalDestinationName', label: 'Final Destination', type: 'string', whiteSpace: 'nowrap' },
  { id: 'moveType', label: 'Delivery Type', type: 'string', whiteSpace: 'nowrap' },
  { id: 'containers', label: 'Eqpt Type', type: 'string', whiteSpace: 'nowrap' },
];

interface Props {
  consolidationId: string;
}

export default function ConsolidationBookingsPanel(props: Props) {
  const [bookings, setBookings] = React.useState<Bookings[]>([]);
  const consolidation = useRecoilValue(ConsolidationPageStates.consolidation);

  React.useEffect(() => {
    if (consolidation) {
      setBookings(
        consolidation.bookings.map((booking): Bookings => {
          let containers = '';
          let weight = 0;
          let volume = 0;
          if (booking.containers) {
            const nContainersByType = new Map<string, number>();
            booking.containers.forEach((container) => {
              const n = nContainersByType.get(container.containerType) || 0;
              nContainersByType.set(container.containerType, n + 1);
              weight += container.weight ? container.weight : 0;
              volume += container.volume ? container.volume : 0;
            });
            const lines: string[] = [];
            nContainersByType.forEach((nContainers, type) => {
              lines.push(`${type} (QTY ${formatNumber(nContainers)})`);
            });
            containers = lines.join('\n');
          }

          let supplierName = '';
          booking.relatedParties.forEach((rp) => {
            if (rp.partyType === PartyType.Supplier) {
              supplierName = rp.party.name;
            }
          });

          return {
            id: booking.id,
            referenceNumber: { to: '/bookings/' + booking.id, value: booking.referenceNumber },
            bookingDate: booking.createDate,
            weight: weight,
            volume: volume,
            bookingStatus: mapShipmentStatus(booking.status),
            cargoReadyDate: booking.cargoReadyDate,
            pol: booking.logistics.pol ? booking.logistics.pol.code : '',
            polEtd: booking.logistics.polEtd,
            pod: booking.logistics.pod ? booking.logistics.pod.code : '',
            finalDestinationName: booking.logistics.finalDestination?.name ?? '',
            supplierName: supplierName,
            moveType: booking.logistics.moveType ? mapMoveType(booking.logistics.moveType) : '',
            containers: containers,
            relatedParties: booking.relatedParties as GqlRelatedParty[],
          };
        })
      );
    }
  }, [consolidation]);

  return (
    <Panel title="Bookings">
      <Line height={1} />
      <Box height="100%" paddingX={2} paddingBottom={2}>
        <UWLTable
          rowId="id"
          columns={columns}
          rows={bookings}
          emptyMessage="- No Bookings -"
          virtualize={{
            bufferHeight: 400,
            rowHeight(row) {
              return 24;
            },
          }}
          renderCell={{
            volume(row) {
              return (
                <>
                  {row.volume ? (
                    <Typography variant="body1">
                      {formatNumberDecimalPlaces(row.volume, 0, 2)}
                      <Box
                        component="span"
                        fontWeight="caption.fontWeight"
                        fontStyle="italic"
                        marginLeft={1}
                      >
                        M3
                      </Box>
                    </Typography>
                  ) : (
                    <Box>0</Box>
                  )}
                </>
              );
            },
            weight(row) {
              return (
                <>
                  {row.weight ? (
                    <Typography variant="body1">
                      {formatNumberDecimalPlaces(row.weight, 0, 2)}
                      <Box
                        component="span"
                        fontWeight="caption.fontWeight"
                        fontStyle="italic"
                        marginLeft={1}
                      >
                        KG
                      </Box>
                    </Typography>
                  ) : (
                    <Box>0</Box>
                  )}
                </>
              );
            },
          }}
        />
      </Box>
    </Panel>
  );
}
