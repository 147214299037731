import { Box, Button, Grid, Typography } from '@material-ui/core';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import { navigate } from '@reach/router';
import {
  GqlContainerInput,
  GqlNewConsolidationInput,
  PartyType,
  TransportMode,
  useSaveConsolidationMutation,
} from 'api/GQL_Types';
import { AvailableConsolidationBookingListQuery } from 'api/queries/consolidationQueries';
import { userContextAtom } from 'app';
import UniversalDialog from 'components/UniversalDialog';
import { useSnackbar } from 'notistack';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { AtomGroupCleaner, useResetAtomGroupState } from 'recoil-utils/utils';
import { NewConsolidationStates } from '../states';

export default function CompleteConsolidationButton() {
  const setActiveStep = useSetRecoilState(NewConsolidationStates.activeStep);
  const newConsolidation = useRecoilValue(NewConsolidationStates.newConsolidation);
  const resetState = useResetAtomGroupState(NewConsolidationStates);

  return (
    <>
      <Button
        onClick={() => {
          setActiveStep(0);
        }}
        style={{
          marginRight: '24px',
          backgroundColor: 'rgba(0, 0, 0, 0.12)',
          color: '#043c5f',
          padding: '8px 32px',
        }}
      >
        <Typography variant="h3" color="inherit" style={{ width: '112px' }}>
          Back
        </Typography>
      </Button>
      <SubmitButton />
      {newConsolidation && (
        <UniversalDialog
          open
          small
          setClose={() => {
            resetState();
          }}
        >
          <AtomGroupCleaner atomGroup={NewConsolidationStates} />
          <Box padding={3} textAlign="center">
            <Box display="flex" justifyContent="center">
              <CheckCircleIcon style={{ fontSize: 90, color: '#009600' }} />
            </Box>
            <Typography variant="h1" style={{ paddingBottom: '16px' }}>
              Consolidation Created
            </Typography>
            <Box display="flex" justifyContent="center">
              <Typography variant="h4" style={{ paddingRight: '4px' }}>
                Consolidation # {newConsolidation.referenceNumber}
              </Typography>
              <Typography variant="body1" style={{ lineHeight: '1.2' }}>
                {' '}
                was successfully created and ready to view.
              </Typography>
            </Box>
            <Grid
              container
              spacing={3}
              direction="row"
              justify="center"
              alignItems="center"
              style={{ marginTop: '24px' }}
            >
              <Grid item xs={4}>
                <Button
                  variant="contained"
                  color="secondary"
                  fullWidth
                  onClick={() => {
                    setActiveStep(0);
                    resetState();
                  }}
                >
                  New Consolidation
                </Button>
              </Grid>
              <Grid item xs={4}>
                <Button
                  variant="contained"
                  color="primary"
                  fullWidth
                  onClick={() => {
                    resetState();
                    navigate('/consolidations/' + newConsolidation.consolidationId);
                  }}
                >
                  View Consolidation
                </Button>
              </Grid>
            </Grid>
          </Box>
        </UniversalDialog>
      )}
    </>
  );
}

function SubmitButton() {
  const userContext = useRecoilValue(userContextAtom);
  const cfsOpenDate = useRecoilValue(NewConsolidationStates.step2.formCfsOpenDate);
  const cfsCutoffDate = useRecoilValue(NewConsolidationStates.step2.formCfsCutoffDate);
  const moveType = useRecoilValue(NewConsolidationStates.step2.formMoveType);
  const containerMode = useRecoilValue(NewConsolidationStates.step2.formContainerMode);
  const remark = useRecoilValue(NewConsolidationStates.step2.consolidationRemark);
  const carrier = useRecoilValue(NewConsolidationStates.step2.formCarrier);
  const pickupLocation = useRecoilValue(NewConsolidationStates.step2.formPickupLocation);
  const deliveryLocation = useRecoilValue(NewConsolidationStates.step2.formDeliveryLocation);
  const etd = useRecoilValue(NewConsolidationStates.step2.formEtd);
  const eta = useRecoilValue(NewConsolidationStates.step2.formEta);
  const pod = useRecoilValue(NewConsolidationStates.step2.formPod);
  const pol = useRecoilValue(NewConsolidationStates.step2.formPol);
  const equipmentList = useRecoilValue(NewConsolidationStates.step1.equipmentList);
  const selectedBookings = useRecoilValue(NewConsolidationStates.step1.selectedBookings);
  const consignee = useRecoilValue(NewConsolidationStates.step2.formConsignee);
  const supplier = useRecoilValue(NewConsolidationStates.step2.formSupplier);
  const manufacturer = useRecoilValue(NewConsolidationStates.step2.formManufacturer);
  const manufacturerList = useRecoilValue(NewConsolidationStates.step2.formManufacturerList);
  const setNewConsolidation = useSetRecoilState(NewConsolidationStates.newConsolidation);
  const { enqueueSnackbar } = useSnackbar();

  const [saveConsolidation, { loading }] = useSaveConsolidationMutation({
    refetchQueries: () => [
      {
        query: AvailableConsolidationBookingListQuery,
        variables: { profileId: userContext?.activeContact?.profile.id },
      },
    ],
    onCompleted(data) {
      const newConsolidation = data.newConsolidation?.consolidation;
      if (data.newConsolidation.success && newConsolidation) {
        setNewConsolidation({
          consolidationId: newConsolidation.id,
          referenceNumber: newConsolidation.referenceNumber,
        });
      } else {
        enqueueSnackbar(
          'Failed to create consolidation: ' +
            (data.newConsolidation.message || 'Unexpected Error'),
          {
            variant: 'error',
            anchorOrigin: {
              vertical: 'top',
              horizontal: 'right',
            },
          }
        );
      }
    },
    onError(error) {
      enqueueSnackbar('Failed to create consolidation: ' + error, {
        variant: 'error',
        anchorOrigin: {
          vertical: 'top',
          horizontal: 'right',
        },
      });
    },
  });

  return (
    <Button
      disabled={cfsOpenDate === null || cfsCutoffDate === null || loading}
      variant="contained"
      color="primary"
      onClick={() => {
        if (cfsOpenDate === null || cfsCutoffDate === null) {
          return;
        }
        const destinationAgent = consignee?.party.relatedProfiles.find(
          (rp) => rp.partyType === PartyType.DestinationAgent
        );

        const relatedPartiesObj = [
          { partyType: PartyType.Consignee, partyId: consignee?.party.id },
          {
            partyType: PartyType.DestinationAgent,
            partyId: destinationAgent ? destinationAgent.profile.id : undefined,
          },
          { partyType: PartyType.Supplier, partyId:  supplier?.party.id },
          { partyType: PartyType.Shipper, partyId:  supplier?.party.id },
          { partyType: PartyType.Manufacturer, partyId: manufacturer?.party.id },
        ];

        for (const m of manufacturerList) {
          const originAgent = m?.party.relatedProfiles.find(
            (rp) => rp.partyType === PartyType.OriginAgent
          );
          relatedPartiesObj.push({
            partyType: PartyType.OriginAgent,
            partyId: originAgent ? originAgent.profile.id : undefined,
          });
        }
        const containers: GqlContainerInput[] = [];
        for (const item of equipmentList) {
          for (let i = 0; i < item.qty; i++) {
            containers.push({ containerType: item.code });
          }
        }

        const input: GqlNewConsolidationInput = {
          logistics: {
            cfsOpenDate: cfsOpenDate,
            cfsCutoffDate: cfsCutoffDate,
            transportationMode: TransportMode.Sea,
            moveType: moveType,
            containerMode: containerMode,
            carrierId: carrier ? carrier.id : undefined,
            pickupLocationId: pickupLocation?.id,
            deliveryLocationId: deliveryLocation?.id,
            finalDestinationId: deliveryLocation?.id,
            podCode: pod?.code,
            polCode: pol?.code,
            polEtd: etd,
            podEta: eta,
          },
          bookingIds: selectedBookings.map((booking) => booking.id),
          containers: containers,
          remark: remark
            ? {
                text: remark,
              }
            : undefined,
          relatedParties: relatedPartiesObj,
        };
        saveConsolidation({
          variables: {
            input: input,
          },
        });
      }}
      style={{ padding: '8px 32px' }}
    >
      <Typography variant="h3" color="inherit" style={{ width: '100px' }}>
        Consolidate
      </Typography>
    </Button>
  );
}
