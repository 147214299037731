import {
  faAddressBook,
  faNetworkWired,
  faProjectDiagram,
  faUser,
} from '@fortawesome/free-solid-svg-icons';
import { Box, Typography } from '@material-ui/core';
import { navigate, RouteComponentProps } from '@reach/router';
import { useAllUsersQuery } from 'api/GQL_Types';
import AdminCardList from 'app/admin/components/AdminCardList';
import { ManagePageHeader_BackStack } from 'app/admin/components/ManagePageHeader';
import ErrorMessage from 'components/ErrorMessage';
import SearchBar from 'components/SearchBar';
import React from 'react';
import { selector, useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import { genKey, newAtom } from 'recoil-utils/utils';
import { theme } from 'styles';

const usersSearchState = newAtom('');
const usersListState = newAtom<AdminObj[]>([]);
const filteredUsersListState = selector({
  key: genKey(),
  get: ({ get }) => {
    const searchField = get<string>(usersSearchState);
    const users = get<any>(usersListState);
    const lowerField = searchField.toLowerCase();

    if (searchField === '') {
      return users;
    } else {
      return users.filter(
        (user: any) =>
          user.name.toLowerCase().includes(lowerField) ||
          user.networkName.toLowerCase().includes(lowerField) ||
          user.title.toLowerCase().includes(lowerField) ||
          user.profileName.toLowerCase().includes(lowerField)
      );
    }
  },
});

interface AdminObj {
  id: string;
  name: string;
  title: string;
  status: boolean;
  networkName: string;
  profileName: string;
  profileContacts: number;
}

interface UserListProps extends RouteComponentProps {}
export default function UserList(props: UserListProps) {
  const setBackStack = useSetRecoilState(ManagePageHeader_BackStack);
  const setUsers = useSetRecoilState(usersListState);
  const [searchField, setSearchField] = useRecoilState(usersSearchState);
  const filteredUsers = useRecoilValue(filteredUsersListState);
  const { loading, error } = useAllUsersQuery({
    fetchPolicy: 'cache-and-network',
    onCompleted(data) {
      const userList: AdminObj[] = data.users.map((user) => {
        return {
          id: user.id,
          name: user.fullName ?? '',
          title: user.title ?? '',
          status: user.isActive ?? false,
          networkName: user.defaultContact.profile.network.name,
          profileName: user.defaultContact.profile.name,
          profileContacts: user.profileContacts.length,
        };
      });
      setUsers(userList);
    },
  });

  return (
    <div>
      <Box margin="16px 24px">
        <Box display="flex" justifyContent="space-between" marginBottom={3}>
          <Box>
            <Typography variant="h2">Users</Typography>
            <Typography variant="body1">
              {loading ? 'loading...' : `${filteredUsers.length} Total`}
            </Typography>
          </Box>
          <Box width="25%" bgcolor="#FFFFFF" padding={1} alignSelf="center" borderRadius="4px">
            <SearchBar
              placeholder="Search Users"
              field={searchField}
              updateField={setSearchField}
            />
          </Box>
        </Box>
        <ErrorMessage error={error ? error + '' : null} />
        <AdminCardList<AdminObj>
          list={filteredUsers}
          iconList={[
            {
              name: 'Title',
              key: 'title',
              icon: faUser,
              color: theme.palette.primary.light,
            },
            {
              name: 'Network Name',
              key: 'networkName',
              icon: faProjectDiagram,
              color: theme.palette.blueGreen.main,
            },
            {
              name: 'Profile Name',
              key: 'profileName',
              icon: faNetworkWired,
              color: theme.palette.orange.main,
            },
            {
              name: 'Profile Contacts',
              key: 'profileContacts',
              icon: faAddressBook,
              color: theme.palette.dodgerBlue.main,
            },
          ]}
          onManageClicked={(item) => {
            setBackStack([{ label: 'User List', path: '/admin/users' }]);
            navigate('admin/users/' + item.id);
          }}
        />
      </Box>
    </div>
  );
}
