import { GqlMasterBill } from 'api/GQL_Types';
import { Document } from 'app/components/DocumentsPanel';
import { Remark } from 'components/Remarks';
import { newAtom } from 'recoil-utils/utils';

export const MblPageStates = {
  mbl: newAtom<GqlMasterBill | null>(null),
  allRemarks: newAtom<Remark[]>([]),
  allDocuments: newAtom<Document[]>([]),
};
