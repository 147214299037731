import { Box, Button, Link, Typography } from '@material-ui/core';
import { navigate, RouteComponentProps } from '@reach/router';
import { downloadStaticFile } from 'api/download';
import { ImportFileStatus, PermissionCode, usePoImportFilesQuery } from 'api/GQL_Types';
import { auth } from 'app';
import Panel from 'components/Panel';
import TableExportWindowGroup, { uwlColToGTable } from 'components/TableExportWindowGroup';
import { UWLTable } from 'components/UWLTable/UWLTable';
import React from 'react';
import { UWLTableColumn } from 'types/UWLTable';

interface PoImportFileRow {
  id: string;
  controllingClientName: string;
  networkName: string;
  uploadDate: Date | null;
  totalPos: number;
  uploadedBy: string;
  fileStatus: ImportFileStatus;
  fileName: string;
  extension: string;
}

const columns: UWLTableColumn<PoImportFileRow>[] = [
  { id: 'controllingClientName', label: 'Controlling Client', type: 'string' },
  { id: 'networkName', label: 'Network', type: 'string' },
  { id: 'uploadDate', label: 'Uploaded', type: 'date' },
  { id: 'totalPos', label: "# of PO's", type: 'number', align: 'left' },
  { id: 'uploadedBy', label: 'Uploaded By', type: 'string' },
  { id: 'fileStatus', label: 'Status', type: 'string' },
  { id: 'fileName', label: 'File', type: 'string' },
];

interface Props extends RouteComponentProps {}

export default function UploadPOListPage(props: Props) {
  const { userContext } = auth.useAuthState();
  const [importFiles, setImportFiles] = React.useState<PoImportFileRow[]>([]);

  const { loading, error } = usePoImportFilesQuery({
    fetchPolicy: 'no-cache',
    onCompleted(data) {
      setImportFiles(
        data.poImportFiles.map((file) => {
          return {
            id: file.id,
            controllingClientName: file.network.controllingClient.name,
            networkName: file.network.name,
            uploadDate: file.uploadDate,
            totalPos: file.totalPos ?? 0,
            uploadedBy: `${file.uploadedBy.firstName} ${file.uploadedBy.lastName}`,
            fileStatus: file.fileStatus,
            fileName: file.importFileName,
            extension: file.extension,
          };
        })
      );
    },
  });

  return (
    <Panel
      title="Upload History"
      growPanel
      titleDecorator={
        <TableExportWindowGroup
          label="PurchaseOrderUploads"
          rows={importFiles}
          columns={columns.map(uwlColToGTable)}
        />
      }
      topRight={
        userContext?.permissionCodes.has(PermissionCode.PoImportUpload) && (
          <Button
            variant="contained"
            color="primary"
            size="large"
            onClick={() => {
              navigate('/purchase-orders/uploads/new');
            }}
          >
            <Typography variant="h3" color="textSecondary">
              new upload
            </Typography>
          </Button>
        )
      }
    >
      <Box height="100%" paddingX={2} paddingBottom={2} paddingTop={1}>
        <UWLTable
          rowId="id"
          columns={columns}
          rows={importFiles}
          isLoading={loading}
          error={error ? error + '' : null}
          emptyMessage="No Uploaded Files Available"
          virtualize={{
            bufferHeight: 300,
            rowHeight(row) {
              return 32;
            },
          }}
          renderCell={{
            fileName(row) {
              return (
                <Button
                  component={Link}
                  onClick={() => {
                    downloadStaticFile(row.id, row.fileName, row.extension);
                  }}
                  style={{
                    whiteSpace: 'nowrap',
                    padding: 0,
                    color: '#3a81b9',
                    textTransform: 'none',
                  }}
                >
                  {row.fileName}
                  {row.extension}
                </Button>
              );
            },
          }}
          rowAction={(row) => {
            if (row.fileStatus !== 'PENDING') {
              return null;
            }
            return (
              <Button
                onClick={() => {
                  navigate('/purchase-orders/uploads/' + row.id, {
                    state: { status: row.fileStatus },
                  });
                }}
                style={{
                  whiteSpace: 'nowrap',
                  backgroundColor: '#3a81b9',
                }}
                color="primary"
                variant="contained"
                size="small"
              >
                Review File
              </Button>
            );
          }}
        />
      </Box>
    </Panel>
  );
}
