import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Switch from '@material-ui/core/Switch';
import Typography from '@material-ui/core/Typography';
import FormInputMonthDays from 'components/form/FormInputMonthDays';
import FormInputSelect from 'components/form/FormInputSelect';
import { FormInputText } from 'components/form/FormInputText';
import FormInputTime from 'components/form/FormInputTime';
import FormInputTimezone from 'components/form/FormInputTimezone';
import FormInputWeekDays from 'components/form/FormInputWeekDays';
import * as React from 'react';
import reportCategoriesStore from 'stores/reportCategoriesStore';
import reportFormStore from 'stores/reportFormStore';

interface Props {}

export default function Step1(props: Props) {
  const { values, validation } = reportFormStore.use();
  const categories = reportCategoriesStore.use();

  const availableCategories = categories.data;

  return (
    <Box maxWidth={700}>
      <Grid container spacing={3}>
        <Grid item xs={12} md={6}>
          <FormInputText
            label="Report Name"
            required
            value={values.name}
            onValue={(name) => reportFormStore.put({ name })}
            error={!!validation.name}
            helperText={validation.name}
          />
        </Grid>
      </Grid>

      <Box display="flex" alignItems="center" marginY={2}>
        <Typography variant="h3">Schedule</Typography>

        <Switch
          checked={values.scheduled}
          onChange={(e) => {
            const isChecked = e.target.checked;
            reportFormStore.put({ scheduled: isChecked });
          }}
          color="secondary"
        />
      </Box>

      <Grid container spacing={3}>
        <Grid item xs={12} md={6}>
          <FormInputSelect
            label="Frequency"
            required
            options={[
              { id: 'weekly', label: 'Weekly' },
              { id: 'monthly', label: 'Monthly' },
            ]}
            value={values.period || null}
            onValue={(period) => {
              if (period === 'weekly' || period === 'monthly') {
                reportFormStore.put({ scheduled: true, period });
              } else {
                reportFormStore.put({ scheduled: false, weekDays: [], days: [] });
              }
            }}
            disabled={!values.scheduled}
            error={!!validation.period}
            helperText={validation.period}
          />
          <Box marginY={2}>
            {values.period === 'weekly' && (
              <FormInputWeekDays
                value={values.weekDays}
                onValue={(weekDays) => reportFormStore.put({ weekDays })}
                disabled={!values.scheduled}
                error={validation.weekDays}
              />
            )}
            {values.period === 'monthly' && (
              <FormInputMonthDays
                value={values.days}
                onValue={(days) => reportFormStore.put({ days })}
                disabled={!values.scheduled}
                error={validation.days}
              />
            )}
          </Box>
        </Grid>
        <Grid item xs={12} md={6}>
          <FormInputTime
            label="Time"
            required
            value={values.time}
            onValue={(time) => reportFormStore.put({ time })}
            disabled={!values.scheduled}
            error={validation.time}
          />
          <Box marginY={2}>
            <FormInputTimezone
              label="Timezone"
              required
              value={values.timezone}
              onValue={(timezone) => reportFormStore.put({ timezone })}
              disabled={!values.scheduled}
              error={validation.timezone}
            />
          </Box>
        </Grid>
      </Grid>

      <Box marginY={4}>
        <Typography variant="h3">Report Type</Typography>
      </Box>

      <Grid container spacing={3}>
        <Grid item xs={12} md={6}>
          <FormInputSelect
            label="Category"
            required
            options={availableCategories.map((c) => ({ id: c.id, label: c.name }))}
            value={values.datasetId || null}
            onValue={(str) => {
              let datasetId: string | null = null;
              let fields: string[] = [];
              const dataset = availableCategories.find((c) => c.id === str);
              if (dataset) {
                datasetId = str;
                fields = dataset.defaultDisplayables;
              }
              reportFormStore.put({
                datasetId,
                fields,
                filters: [], // reset filters b/c they may no longer apply if they changed report types
              });
            }}
            disabled={categories.waiting}
            error={!!(validation.datasetId || categories.error)}
            helperText={validation.datasetId || categories.error}
          />
        </Grid>
        <Grid item xs></Grid>
      </Grid>
    </Box>
  );
}
