import React from 'react';
import { RouteComponentProps } from '@reach/router';
import ProfileList from './list';
import ManageProfile from './manage';
import { FlexGrowRouter } from 'components/StyledComponents';

interface Props extends RouteComponentProps {}
export default function Profiles(props: Props) {
  return (
    <FlexGrowRouter>
      <ProfileList path="/" />
      <ManageProfile path="/:profileId/*" />
    </FlexGrowRouter>
  );
}
