import { Box, Fab, Typography } from '@material-ui/core';
import { RouteComponentProps } from '@reach/router';
import { PermissionCode } from 'api/GQL_Types';
import { auth } from 'app';
import ProfileListItem from 'app/admin/components/ProfileListItem';
import SearchBar from 'components/SearchBar';
import { Line } from 'components/StyledComponents';
import React from 'react';
import { selector, useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import { genKey, newAtom } from 'recoil-utils/utils';
import { theme } from 'styles';
import { getAccentColorFromIndex } from 'utils/Colors';
import { mapPartyType } from 'utils/Enums';
import { ProfilePageRelatedProfile, ProfilePageStates } from '../states';
import RelatedProfilesDialog, {
  RelatedProfilesDialog_Data,
  RelatedProfilesDialog_Open,
} from './RelatedProfilesDialog';
import RemoveRelatedProfileDialog, {
  RemoveRelatedProfileDialog_Data,
  RemoveRelatedProfileDialog_Open,
} from './RemoveRelatedProfileDialog';

const searchState = newAtom('');

const filteredListState = selector<ProfilePageRelatedProfile[]>({
  key: genKey(),
  get: ({ get }) => {
    const searchField = get<string>(searchState);
    const profile = get(ProfilePageStates.profile);
    const list = profile?.relatedProfiles || [];
    const lowerField = searchField.toLowerCase();

    if (searchField === '') {
      return list;
    } else {
      return list.filter((item) => item.profile.name.toLowerCase().includes(lowerField));
    }
  },
});

interface Props extends RouteComponentProps {}

export default function RelatedProfiles(props: Props) {
  const { userContext } = auth.useAuthState();
  const profile = useRecoilValue(ProfilePageStates.profile);
  const [searchField, setSearchField] = useRecoilState(searchState);
  const filteredList = useRecoilValue(filteredListState);

  const setOpen = useSetRecoilState(RelatedProfilesDialog_Open);
  const setDialogRelatedProfile = useSetRecoilState(RelatedProfilesDialog_Data);
  const setRemoveOpen = useSetRecoilState(RemoveRelatedProfileDialog_Open);
  const setDialogRemoveRelatedProfile = useSetRecoilState(RemoveRelatedProfileDialog_Data);

  if (!profile) {
    return null;
  }

  const relationsAvailable = profile.network.connectedProfiles.filter((p) => p.id !== profile.id);

  return (
    <div>
      <Box display="flex" justifyContent="space-between">
        <Box>
          <Typography variant="h2">Related Profiles</Typography>
          <Typography variant="body1">List of profiles related to this profile.</Typography>
        </Box>
        <Box width="25%" bgcolor={theme.palette.background.default} padding={1} alignSelf="center">
          <SearchBar
            placeholder="Search Related Profiles"
            field={searchField}
            updateField={setSearchField}
          />
        </Box>
      </Box>
      <Line height={2} color={theme.palette.primary.main} />
      <Box paddingBottom={4}>
        {filteredList.length === 0 && (
          <div>
            {searchField.length > 0 ? (
              <div>No related profiles matching search: "{searchField}"</div>
            ) : (
              <div>- none -</div>
            )}
          </div>
        )}
        {filteredList.map((relatedProfile, index) => {
          return (
            <ProfileListItem
              key={relatedProfile.profile.id}
              accentColor={getAccentColorFromIndex(index)}
              name={relatedProfile.profile.name}
              nRoles={relatedProfile.profile.roles.length}
              nContacts={relatedProfile.profile.contacts.length}
              nLocations={relatedProfile.profile.locations.length}
              onManageClicked={
                userContext?.permissionCodes.has(PermissionCode.ProfileRelatedProfilesUpdate)
                  ? () => {
                      setDialogRelatedProfile(relatedProfile);
                      setOpen(true);
                    }
                  : undefined
              }
              onRemoveClicked={
                userContext?.permissionCodes.has(PermissionCode.ProfileRelatedProfilesDelete)
                  ? () => {
                      setDialogRemoveRelatedProfile(relatedProfile);
                      setRemoveOpen(true);
                    }
                  : undefined
              }
              extraDisplays={[
                <Typography variant="h4">{mapPartyType(relatedProfile.partyType)}</Typography>,
              ]}
            />
          );
        })}
      </Box>
      {userContext?.permissionCodes.has(PermissionCode.ProfileRelatedProfilesCreate) && (
        <Box position="absolute" bottom="48px" right="36px">
          <CreateButton />
        </Box>
      )}

      <RelatedProfilesDialog
        profileId={profile.id}
        profileOptions={relationsAvailable.filter(
          (relation) => !profile.relatedProfiles.map((p) => p.profile.id).includes(relation.id)
        )}
      />
      <RemoveRelatedProfileDialog profileId={profile.id} profileName={profile.name} />
    </div>
  );
}

function CreateButton() {
  const setOpen = useSetRecoilState(RelatedProfilesDialog_Open);

  return (
    <Fab
      color="primary"
      aria-label="add"
      variant="extended"
      onClick={() => {
        setOpen(true);
      }}
    >
      <i className="material-icons" style={{ marginRight: '8px' }}>
        {'add'}
      </i>
      New Related Profile
    </Fab>
  );
}
