import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';
import clsx from 'clsx';
import React from 'react';
import { makeCss, theme } from 'styles';

const classes = makeCss({
  root: {
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',
    '&:hover $sortIcon': {
      opacity: 1,
    },
  },
  active: {},
  sortIcon: {
    opacity: 0,
    width: 17,
    height: 17,
    '&$active': {
      opacity: 1,
    },
    transition: theme.transitions.create('opacity', {
      easing: theme.transitions.easing.easeInOut,
      duration: theme.transitions.duration.shorter,
    }),
  },
  icon: {
    width: 17,
    height: 17,
  },
  label: {
    marginRight: '8px',
    userSelect: 'none',
    msUserSelect: 'none',
    MozUserSelect: 'none',
    WebkitUserSelect: 'none',
  },
});

interface Props {
  isSortedCol: boolean;
  onChange: (direction: 'asc' | 'desc') => void;
  direction: 'asc' | 'desc';
}

export const HeaderSortDecorator: React.FC<Props> = ({
  isSortedCol,
  onChange,
  direction,
  children,
}) => {
  return (
    <div
      className={classes.root}
      onClick={() => {
        if (isSortedCol) {
          onChange(direction === 'desc' ? 'asc' : 'desc');
        } else {
          onChange('desc');
        }
      }}
    >
      <div className={classes.label}>{children}</div>
      <div className={clsx(classes.sortIcon, isSortedCol && classes.active)}>
        {direction === 'desc' ? (
          <ArrowDownwardIcon className={classes.icon} />
        ) : (
          <ArrowUpwardIcon className={classes.icon} />
        )}
      </div>
    </div>
  );
};
