import { Box, InputAdornment, TextField, Typography } from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import { GqlPackLine } from 'api/GQL_Types';
import { BookingPageStates, ItemPosition } from 'app/bookings/details/states';
import FormInputSelect from 'components/form/FormInputSelect';
import UniversalPopover from 'components/UniversalPopover';
import React from 'react';
import { useRecoilValue } from 'recoil';
import VanPositionGridSelector from './VanPositionGridSelector';

interface VanPositionItemProps {
  index: number;
  item: ItemPosition;
  onClose: Function;
  onItemChange: (item: ItemPosition) => void;
}

export default function VanPositionItem(props: VanPositionItemProps) {
  const bookingPackLines = useRecoilValue(
    BookingPageStates.containerAssignment.editContainer.allBookingPackLines
  );
  const openBookingPackLines = useRecoilValue(
    BookingPageStates.containerAssignment.editContainer.availableBookingPackLines
  );
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event: any) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  return (
    <Box
      display="flex"
      justifyContent="space-between"
      bgcolor="#FAFAFA"
      padding={2}
      margin={1}
      alignItems="center"
    >
      <Box display="flex" alignItems="center">
        <Box marginRight={2}>
          <Typography variant="h4">ITEM:</Typography>
        </Box>
        <div style={{ width: '200px', marginRight: '24px' }}>
          <FormInputSelect
            options={bookingPackLines.map((packLine) => ({
              id: packLine.id,
              label: `${packLine.orderLine.purchaseOrder.poNumber}: ${packLine.orderLine.itemNumber}`,
            }))}
            value={props.item.packLine.id === '' ? null : props.item.packLine.id}
            onValue={(v) => {
              if (v === null) {
                props.onItemChange({
                  id: props.item.id,
                  piecesQty: 0,
                  cartonsQty: 0,
                  volume: 0,
                  weight: 0,
                  vanPositionCode: null,
                  packLine: {} as GqlPackLine,
                });
                return;
              }
              for (const line of openBookingPackLines) {
                if (line.id === v) {
                  props.onItemChange({
                    ...props.item,
                    piecesQty: line.quantityLeft < 0 ? 0 : line.quantityLeft,
                    cartonsQty: line.ctnsLeft < 0 ? 0 : line.ctnsLeft,
                    weight: line.weight,
                    volume: line.volume,
                    packLine: line,
                  });
                  return;
                }
              }
              props.onItemChange({
                id: '',
                piecesQty: 0,
                cartonsQty: 0,
                volume: 0,
                weight: 0,
                vanPositionCode: null,
                packLine: {} as GqlPackLine,
              });
            }}
            label="Item"
            nullOptionLabel="No Item Selected"
          />
        </div>
      </Box>
      <TextField
        label="Qty"
        variant="outlined"
        margin="dense"
        type="number"
        value={props.item.piecesQty}
        onFocus={(e) => {
          e.target.select();
        }}
        disabled={props.item.packLine.id === 'None'}
        onChange={(e) => {
          props.onItemChange({
            ...props.item,
            piecesQty: +e.target.value,
          });
        }}
        style={{ width: '-webkit-fill-available', margin: '8px 24px 8px 0' }}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <Typography variant="caption" style={{ lineHeight: '1.5' }}>
                PCS
              </Typography>
            </InputAdornment>
          ),
          style: { paddingRight: '10px' },
        }}
      />

      <TextField
        label="Ctns"
        variant="outlined"
        margin="dense"
        type="number"
        value={props.item.cartonsQty}
        onFocus={(e) => {
          e.target.select();
        }}
        disabled={props.item.packLine.id === 'None'}
        onChange={(e) => {
          props.onItemChange({
            ...props.item,
            cartonsQty: +e.target.value,
          });
        }}
        style={{ width: '-webkit-fill-available', margin: '8px 24px 8px 0' }}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <Typography variant="caption" style={{ lineHeight: '1.5' }}>
                CTNS
              </Typography>
            </InputAdornment>
          ),
          style: { paddingRight: '10px' },
        }}
      />

      <TextField
        label="Volume"
        variant="outlined"
        margin="dense"
        type="number"
        value={props.item.volume}
        onFocus={(e) => {
          e.target.select();
        }}
        disabled={props.item.packLine.id === 'None'}
        onChange={(e) =>
          props.onItemChange({
            ...props.item,
            volume: +e.target.value,
          })
        }
        style={{ width: '-webkit-fill-available', margin: '8px 24px 8px 0' }}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <Typography variant="caption" style={{ lineHeight: '1.5' }}>
                M3
              </Typography>
            </InputAdornment>
          ),
          style: { paddingRight: '10px' },
        }}
      />

      <TextField
        label="Weight"
        variant="outlined"
        margin="dense"
        type="number"
        value={props.item.weight}
        onFocus={(e) => {
          e.target.select();
        }}
        disabled={props.item.packLine.id === 'None'}
        onChange={(e) =>
          props.onItemChange({
            ...props.item,
            weight: +e.target.value,
          })
        }
        style={{ width: '-webkit-fill-available', margin: '8px 24px 8px 0' }}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <Typography variant="caption" style={{ lineHeight: '1.5' }}>
                KGS
              </Typography>
            </InputAdornment>
          ),
          style: { paddingRight: '10px' },
        }}
      />

      <TextField
        label="VAN Pos"
        variant="outlined"
        margin="dense"
        value={props.item.vanPositionCode ? props.item.vanPositionCode : ''}
        onClick={props.item.packLine.id === 'None' ? undefined : handleClick}
        disabled={props.item.packLine.id === 'None'}
        style={{ width: '-webkit-fill-available', margin: '8px 24px 8px 0' }}
        InputLabelProps={{
          shrink: true, // See #OMS-54
        }}
      />

      <UniversalPopover
        popoverId={'position-select-popover'}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
      >
        <VanPositionGridSelector
          selectedCode={props.item.vanPositionCode}
          onPositionSelected={(vanPositionCode) => {
            props.onItemChange({ ...props.item, vanPositionCode: vanPositionCode });
            handleClose();
          }}
        />
      </UniversalPopover>

      <IconButton
        onClick={() => {
          props.onClose(props.index);
        }}
      >
        <CloseIcon />
      </IconButton>
    </Box>
  );
}
