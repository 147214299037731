import { Box, Tooltip } from '@material-ui/core';
import WarningIcon from '@material-ui/icons/Warning';
import { GqlNetworkContainerType } from 'api/GQL_Types';
import { AppWideStates } from 'app/states';
import Panel from 'components/Panel';
import { Line } from 'components/StyledComponents';
import { UWLTable } from 'components/UWLTable/UWLTable';
import React from 'react';
import { useRecoilValue } from 'recoil';
import { UWLTableColumn } from 'types/UWLTable';
import { NewConsolidationStates } from '../../states';
import { UtilizationChart } from './UtilizationChart';

interface ContainerCapacityTV {
  id: string;
  referenceNumber: { to: string; value: string };
  weight: number;
  volume: number;
}

const containerCapHeaders: UWLTableColumn<ContainerCapacityTV>[] = [
  { id: 'referenceNumber', label: 'Booking', type: 'link' },
  { id: 'weight', label: 'Weight', type: 'weight', unit: 'KG', align: 'left' },
  { id: 'volume', label: 'Volume', type: 'volume', unit: 'M3', align: 'left' },
];

export default function EquipmentUtilization() {
  const bookings = useRecoilValue(NewConsolidationStates.step1.selectedBookings);
  const equipment = useRecoilValue(NewConsolidationStates.step1.equipmentList);
  const containerTypes = useRecoilValue(AppWideStates.network.containerTypes);
  const [containerWCapacity, setContainerWCapacity] = React.useState(0);
  const [containerVCapacity, setContainerVCapacity] = React.useState(0);
  const [bookingWCapacity, setBookingWCapacity] = React.useState(0);
  const [bookingVCapacity, setBookingVCapacity] = React.useState(0);
  const [containerMinWeight, setContainerMinWeight] = React.useState(0);
  const [containerMaxWeight, setContainerMaxWeight] = React.useState(0);
  const [containerMinVCapacity, setContainerMinVCapacity] = React.useState(0);
  const [containerMaxVCapacity, setContainerMaxVCapacity] = React.useState(0);

  let containerWarning: string[] = [];

  const containerCapacityMockTable: ContainerCapacityTV[] = bookings.map((booking) => {
    return {
      id: booking.id,
      referenceNumber: booking.referenceNumber,
      weight: booking.weight,
      volume: booking.volume,
    };
  });

  if (bookingWCapacity > containerMaxWeight) {
    containerWarning.push('Booked weight is over total equipment weight maximum');
  }
  if (bookingVCapacity > containerMaxVCapacity) {
    containerWarning.push('Booked volume is over total equipment volume maximum');
  }
  if (bookingWCapacity < containerMinWeight) {
    containerWarning.push('Booked weight is under total equipment weight minimum');
  }
  if (bookingVCapacity < containerMinVCapacity) {
    containerWarning.push('Booked volume is under total equipment volume minimum');
  }

  React.useEffect(() => {
    if (bookings) {
      setBookingWCapacity(bookings.reduce((value, booking) => value + booking.weight, 0));
      setBookingVCapacity(bookings.reduce((value, booking) => value + booking.volume, 0));
    }
  }, [bookings]);

  React.useEffect(() => {
    const eqps: GqlNetworkContainerType[] = [];
    for (const eqp of equipment) {
      for (let i = 0; i < eqp.qty; i++) {
        const containerType = containerTypes.byCode.get(eqp.code);
        if (containerType) {
          eqps.push(containerType);
        }
      }
    }
    setContainerWCapacity(eqps.reduce((value, eqp) => value + (eqp?.capacityWeightKg ?? 0), 0));
    setContainerMinWeight(eqps.reduce((value, eqp) => value + (eqp?.ruleMinWeightKg ?? 0), 0));
    setContainerMaxWeight(eqps.reduce((value, eqp) => value + (eqp?.ruleMaxWeightKg ?? 0), 0));
    setContainerMinVCapacity(eqps.reduce((value, eqp) => value + (eqp?.ruleMinVolumeM3 ?? 0), 0));
    setContainerMaxVCapacity(eqps.reduce((value, eqp) => value + (eqp?.ruleMaxVolumeM3 ?? 0), 0));
    setContainerVCapacity(eqps.reduce((value, eqp) => value + (eqp?.capacityVolumeM3 ?? 0), 0));
  }, [equipment]);

  return (
    <Panel
      title="Container Utilization"
      titleDecorator={
        <Tooltip placement="top" arrow title={containerWarning.join(', ')}>
          {containerWarning.length ? (
            <WarningIcon htmlColor="#ffd758" style={{ height: '20px' }} />
          ) : (
            <div />
          )}
        </Tooltip>
      }
    >
      <Line height={1} />
      <Box marginTop={2}>
        <Box display="flex" alignItems="center" justifyContent="space-around">
          <UtilizationChart
            current={bookingWCapacity}
            min={containerMinWeight}
            max={containerMaxWeight ? containerMaxWeight : containerWCapacity}
            currentLabel="Booking Weight"
            maxLabel="Container Weight Capacity"
            minLabel="Container Minimum Weight"
            unitType="kg"
          />
          <UtilizationChart
            current={bookingVCapacity}
            min={containerMinVCapacity}
            max={containerMaxVCapacity ? containerMaxVCapacity : containerVCapacity}
            currentLabel="Booking Volume"
            maxLabel="Container Volume Capacity"
            minLabel="Container Minimum Volume"
            unitType="m3"
          />
        </Box>
        <Box display="flex" margin={2}>
          <Box flexGrow={1}>
            <Box height="100%">
              <UWLTable
                rowId="id"
                columns={containerCapHeaders}
                rows={containerCapacityMockTable}
                emptyMessage="- No Bookings -"
              />
            </Box>
          </Box>
        </Box>
      </Box>
    </Panel>
  );
}
