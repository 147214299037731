import { PartyType } from 'api/GQL_Types';
import { GTableColumn } from 'components/g-table/types';
import { ExceptionQueryResult } from '.';
import { TableLink } from '../../states';

export interface DelayedUnloadException {
  id: string;
  bookingNumber: TableLink;
  vendorName: string;
  expectedCargoReadyDate: Date | null | undefined;
  poNumber: TableLink[];
  cargoReadyDate: Date | null | undefined;
  destination: string;
  polEtd: Date | null | undefined;
  polAtd: Date | null | undefined;
  podEta: Date | null | undefined;
  podAta: Date | null | undefined;
  deliveryEta: Date | null | undefined;
}

export function DelayedUnloadException_toRow(
  exception: ExceptionQueryResult
): DelayedUnloadException | null {
  if (exception.__typename !== 'DelayedArrivalException') {
    return null;
  }
  const booking = exception.booking;

  return {
    id: booking.id,
    bookingNumber: { to: '/bookings/' + booking.id, value: booking.referenceNumber },
    vendorName:
      booking.relatedParties.find(
        (party: { partyType: PartyType }) => party.partyType === PartyType.Supplier
      )?.party.name ?? '',
    expectedCargoReadyDate: booking.packLines
      .map((pl: any) => {
        return pl.orderLine.expectedCargoReadyDate;
      })
      .filter((d) => d)[0],
    poNumber: booking.packLines.map((pl: any) => {
      return {
        to: '/purchase-orders/' + pl.orderLine.purchaseOrder.id,
        value: pl.orderLine.purchaseOrder.poNumber,
      };
    }),
    cargoReadyDate: booking.cargoReadyDate,
    destination: booking.logistics.finalDestination?.name || '',
    polEtd: booking.logistics.polEtd,
    polAtd: booking.logistics.polAtd,
    podEta: booking.logistics.podEta,
    podAta: booking.logistics.podAta,
    deliveryEta: booking.logistics.deliveryEta,
  };
}

export const DelayedUnloadException_columns: GTableColumn<DelayedUnloadException>[] = [
  { id: 'bookingNumber', label: 'Booking #', type: 'link', weight: 1 },
  { id: 'vendorName', label: 'Vendor', type: 'string', weight: 1 },
  { id: 'expectedCargoReadyDate', label: 'Exp Cgo Rdy Date', type: 'date', weight: 1 },
  { id: 'poNumber', label: 'PO #', type: 'link', weight: 1 },
  { id: 'cargoReadyDate', label: 'Cgo Rdy Date', type: 'date', weight: 1 },
  { id: 'destination', label: 'Destination', type: 'string', weight: 1 },
  { id: 'cargoReadyDate', label: 'Cgo Rdy Date', type: 'date', weight: 1 },
  { id: 'polEtd', label: 'POL ETD', type: 'date', weight: 1 },
  { id: 'polAtd', label: 'POL ATD', type: 'date', weight: 1 },
  { id: 'podEta', label: 'POD ETA', type: 'date', weight: 1 },
  { id: 'podAta', label: 'POD ATA', type: 'date', weight: 1 },
  { id: 'deliveryEta', label: 'ETA Final Destination', type: 'date', weight: 1 },
];
