import { Grid } from '@material-ui/core';
import LineItem from 'components/LineItem';
import { useRecoilValue } from 'recoil';
import { mapMoveType } from 'utils/Enums';
import { MblPageStates } from '../states';

export default function MBLDetailsSection() {
  const mbl = useRecoilValue(MblPageStates.mbl);

  return (
    <Grid container spacing={3}>
      <Grid item xs>
        <LineItem label="Carrier" value={mbl?.shipment.logistics.carrier?.name} />
        <LineItem label="Vessel" value={mbl?.shipment.logistics.motherVessel?.name} />
        <LineItem label="Voyage" value={mbl?.shipment.logistics.motherVoyage?.name} />
        <LineItem label="Release Type" value={mbl?.releaseType} />
        <LineItem label="Contract Type" value={mbl?.shipment.logistics.contractType} />
        <LineItem
          label="Delivery Type"
          value={
            mbl?.shipment.logistics.moveType ? mapMoveType(mbl.shipment.logistics.moveType) : ''
          }
        />
      </Grid>
      <Grid item xs>
        <LineItem label="Payment Type" value={mbl?.paymentType} />
        <LineItem label="Contract" value={mbl?.shipment.logistics.contractNumber} />
        <LineItem label="Container Count" value={mbl?.containerCount} />
        <LineItem label="Total Pieces" value={mbl?.totalPieces} />
        <LineItem label="Total Ctns" value={mbl?.totalCartons} />
      </Grid>
    </Grid>
  );
}
