import { GqlContainer } from 'api/GQL_Types';
import { HotState } from 'components/HotToggleSwitch';
import { newAtom } from 'recoil-utils/utils';

export interface ContainerPageData extends GqlContainer {
  hot: HotState;
}

export const EquipmentPageStates = {
  container: newAtom<ContainerPageData | null>(null),
};
