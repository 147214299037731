import {
  faAddressBook,
  faAddressCard,
  faBuilding,
  faProjectDiagram,
  faUser,
} from '@fortawesome/free-solid-svg-icons';
import { Box, Typography } from '@material-ui/core';
import { navigate, RouteComponentProps } from '@reach/router';
import { useProfilesQuery } from 'api/GQL_Types';
import { ManagePageHeader_BackStack } from 'app/admin/components/ManagePageHeader';
import ErrorMessage from 'components/ErrorMessage';
import SearchBar from 'components/SearchBar';
import React from 'react';
import { selector, useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import { genKey, newAtom } from 'recoil-utils/utils';
import { theme } from 'styles';
import AdminCardList from '../../components/AdminCardList';

const profilesSearchState = newAtom('');
const profilesListState = newAtom<AdminObj[]>([]);
const filteredProfilesListState = selector({
  key: genKey(),
  get: ({ get }) => {
    const searchField = get<string>(profilesSearchState);
    const profiles = get<any>(profilesListState);
    const lowerField = searchField.toLowerCase();

    if (searchField === '') {
      return profiles;
    } else {
      return profiles.filter(
        (profile: any) =>
          profile.name.toLowerCase().includes(lowerField) ||
          profile.networkName.toLowerCase().includes(lowerField)
      );
    }
  },
});

interface AdminObj {
  id: string;
  name: string;
  logo?: string;
  profileTypeName: string;
  roles: number;
  contacts: number;
  locations: number;
  networkName: string;
  warning?: string | undefined;
}

interface Props extends RouteComponentProps {}
export default function ProfileList(props: Props) {
  const setBackStack = useSetRecoilState(ManagePageHeader_BackStack);
  const setProfiles = useSetRecoilState(profilesListState);
  const [searchField, setSearchField] = useRecoilState(profilesSearchState);
  const filteredProfiles = useRecoilValue(filteredProfilesListState);

  const { error } = useProfilesQuery({
    fetchPolicy: 'no-cache',
    onCompleted(data) {
      const profileList = data.profiles.map((profile): AdminObj => {
        return {
          id: profile.id,
          name: profile.name,
          logo: profile.logo?.url,
          warning: profile.needsUpdate
            ? 'Profile was auto generated. Click manage button to merge with existing profile or make it a new profile.'
            : undefined,
          profileTypeName: profile.profileType?.name ?? '',
          roles: profile.roles.length,
          contacts: profile.contacts.length,
          locations: profile.locations.length,
          networkName: profile.network?.name || '',
        };
      });
      setProfiles(profileList);
    },
  });

  return (
    <div>
      <Box margin="16px 24px">
        <Box display="flex" justifyContent="space-between" marginBottom={3}>
          <Box>
            <Typography variant="h2">Profiles</Typography>
            <Typography variant="body1">{filteredProfiles.length} Total</Typography>
          </Box>
          <Box width="25%" bgcolor="#FFFFFF" padding={1} alignSelf="center" borderRadius="4px">
            <SearchBar
              placeholder="Search Profiles"
              field={searchField}
              updateField={setSearchField}
            />
          </Box>
        </Box>
        <ErrorMessage error={error ? error + '' : null} />
        <AdminCardList<AdminObj>
          list={filteredProfiles}
          iconList={[
            {
              name: 'Network',
              key: 'networkName',
              icon: faProjectDiagram,
              color: theme.palette.primary.light,
            },
            {
              name: 'Profile Type',
              key: 'profileTypeName',
              icon: faUser,
              color: theme.palette.blueGreen.main,
            },
            {
              name: 'Roles',
              key: 'roles',
              icon: faAddressCard,
              color: theme.palette.dodgerBlue.main,
            },
            {
              name: 'Contacts',
              key: 'contacts',
              icon: faAddressBook,
              color: theme.palette.orange.main,
            },
            {
              name: 'Locations',
              key: 'locations',
              icon: faBuilding,
              color: theme.palette.secondary.dark,
            },
          ]}
          onManageClicked={(item) => {
            setBackStack([{ label: 'Profile List', path: '/admin/profiles' }]);
            navigate('admin/profiles/' + item.id);
          }}
        />
      </Box>
    </div>
  );
}
