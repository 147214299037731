import { Box, Grid } from '@material-ui/core';
import {
  ContainerMode,
  GqlCarrier,
  GqlLocation,
  GqlRelatedParty,
  MoveType,
  useCarriersQuery,
} from 'api/GQL_Types';
import AtomicAutocompleteV2 from 'components/atomic/AtomicAutocompleteV2';
import AtomicDatePickerV2 from 'components/atomic/AtomicDatePickerV2';
import AtomicRadioGroupV2 from 'components/atomic/AtomicRadioGroupV2';
import AtomicTextFieldV2 from 'components/atomic/AtomicTextFieldV2';
import { FormInputPortAny } from 'components/form/FormInputPortAny';
import { FormItem } from 'components/form/FormItem';
import Panel from 'components/Panel';
import React from 'react';
import { useRecoilState, useRecoilValue } from 'recoil';
import { mapContainerMode, mapMoveType } from 'utils/Enums';
import { NewConsolidationStates } from '../states';

export default function ConsolidationFields() {
  const bookings = useRecoilValue(NewConsolidationStates.step1.selectedBookings);
  const supplierList = useRecoilValue(NewConsolidationStates.step2.formSupplierList);
  const manufacturerList = useRecoilValue(NewConsolidationStates.step2.formManufacturerList);
  const [pickUpLocation, setPickUpLocation] = useRecoilState(
    NewConsolidationStates.step2.formPickupLocation
  );
  const [pod, setPod] = useRecoilState(NewConsolidationStates.step2.formPod);
  const [pol, setPol] = useRecoilState(NewConsolidationStates.step2.formPol);
  const relatedPartiesMap: { [key: string]: GqlRelatedParty } = {};
  const [locationsMap, setLocationsMap] = React.useState<GqlLocation[]>([]);
  const { data: carriersData } = useCarriersQuery();

  React.useEffect(() => {
    const newMapTemp: [{ [key: string]: GqlLocation }] = [{}];
    if (supplierList) {
      for (const s of supplierList) {
        for (const loc of s.party.locations) {
          newMapTemp.push({[loc.id]: loc});
        }
      }
    }
    if (manufacturerList) {
      for (const m of manufacturerList) {
        for (const loc of m.party.locations) {
          newMapTemp.push({[loc.id]: loc});
        }
      }
    }
    const newLocationMap: [{ [key: string]: GqlLocation }] = [{}];
    for (const r of newMapTemp) {
      if (!newLocationMap.some(a => Object.keys(a)[0] === Object.keys(r)[0])) {
        newLocationMap.push(r);
      }
    };
    newLocationMap.splice(0, 1);
    const locationKeys: string[] = [];
    const locationValuesList: GqlLocation[] = [];
    newLocationMap.forEach((l) => {
      locationKeys.push(Object.keys(l)[0]);
      locationValuesList.push(Object.values(l)[0]);
    });
    setLocationsMap(locationValuesList);
    if (pickUpLocation && !locationKeys.includes(pickUpLocation.id)) {
      setPickUpLocation(null);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [supplierList, manufacturerList]);

  const locationNamesInBookings: string[] = [];
  for (const booking of bookings) {
    locationNamesInBookings.push(booking.finalDestinationName);
    for (const rp of booking.relatedParties) {
      relatedPartiesMap[rp.party.id] = rp;
    }
  }

  const relatedParties = Object.values(relatedPartiesMap);

  const locations: GqlLocation[] = relatedParties.reduce((locationArray: GqlLocation[], rp) => {
    return locationArray.concat(rp.party.locations);
  }, []);

  return (
    <Panel title="Consolidation Fields">
      <form noValidate autoComplete="off">
        <Box paddingLeft={3} paddingY={2}>
          <Grid container alignItems="center">
            <Grid item xs={3}>
              <AtomicTextFieldV2
                disabled
                state={NewConsolidationStates.step2.formBookingParty}
                label="Booking Party"
              />
            </Grid>
            <Grid item xs={3}>
              <AtomicTextFieldV2
                disabled
                state={NewConsolidationStates.step2.formConsigneeField}
                label="Consignee"
              />
            </Grid>
            <Grid item xs={3}>
              <AtomicTextFieldV2
                disabled
                state={NewConsolidationStates.step2.formSupplierField}
                label="Supplier"
              />
            </Grid>
            <Grid item xs={3}>
              <AtomicTextFieldV2
                disabled
                state={NewConsolidationStates.step2.formManufacturerField}
                label="Manufacturer"
              />
            </Grid>
          </Grid>

          <Grid container alignItems="center">
            <Grid item xs={3}>
              <AtomicAutocompleteV2<MoveType>
                state={NewConsolidationStates.step2.formMoveType}
                label={'Move Type'}
                optionsList={Object.values(MoveType)}
                displayResolver={(value: MoveType) => {
                  return mapMoveType(value);
                }}
              />
            </Grid>
            <Grid item xs={3}>
              <AtomicRadioGroupV2<ContainerMode>
                state={NewConsolidationStates.step2.formContainerMode}
                label="Container Mode"
                options={Object.values(ContainerMode).filter((mode) => mode !== ContainerMode.Bcn)}
                displayResolver={(value: ContainerMode) => {
                  return mapContainerMode(value);
                }}
              />
            </Grid>
            <Grid item xs={3}>
              <AtomicAutocompleteV2<GqlLocation>
                state={NewConsolidationStates.step2.formPickupLocation}
                label={'Pick Up Loc'}
                optionsList={locationsMap}
                displayResolver={(value: GqlLocation) => {
                  return value.name ?? 'N/A';
                }}
              />
            </Grid>
            <Grid item xs={3}>
              <AtomicAutocompleteV2<GqlLocation>
                state={NewConsolidationStates.step2.formDeliveryLocation}
                label={'Delivery Loc'}
                optionsList={locations.filter((loc) =>
                  locationNamesInBookings.includes(loc.name ?? '')
                )}
                displayResolver={(value: GqlLocation) => {
                  return value.name ?? 'N/A';
                }}
              />
            </Grid>
          </Grid>

          <Grid container alignItems="center">
            <Grid item xs={3}>
              <FormItem>
                <FormInputPortAny label="Port of Load" value={pol} onValue={setPol} />
              </FormItem>
            </Grid>
            <Grid item xs={3}>
              <AtomicAutocompleteV2<GqlCarrier>
                state={NewConsolidationStates.step2.formCarrier}
                label={'Carrier'}
                optionsList={carriersData ? carriersData.carriers : []}
                displayResolver={(value) => value.name}
              />
            </Grid>
            <Grid item xs={3}>
              <AtomicDatePickerV2
                state={NewConsolidationStates.step2.formCfsOpenDate}
                required
                label="CFS Open Date"
              />
            </Grid>
            <Grid item xs={3}>
              <AtomicDatePickerV2
                state={NewConsolidationStates.step2.formCfsCutoffDate}
                required
                label="CFS Cutoff Date"
              />
            </Grid>
          </Grid>

          <Grid container alignItems="center">
            <Grid item xs={3}>
              <AtomicDatePickerV2 state={NewConsolidationStates.step2.formEtd} label="ETD" />
            </Grid>
            <Grid item xs={3}>
              <FormItem>
                <FormInputPortAny label="Port of Discharge" value={pod} onValue={setPod} />
              </FormItem>
            </Grid>
            <Grid item xs={3}>
              <AtomicDatePickerV2 state={NewConsolidationStates.step2.formEta} label="ETA" />
            </Grid>
          </Grid>
        </Box>
      </form>
    </Panel>
  );
}
