import React from 'react';
import {
  FormControl,
  FormLabel,
  RadioGroup,
  Box,
  FormControlLabel,
  Radio,
} from '@material-ui/core';
import { RecoilState, useRecoilState } from 'recoil';

interface Props<T> {
  state: RecoilState<T | null>;
  label: string;
  options: T[];
  displayResolver: (value: T) => string;
  decorator?: Function;
}

export default function AtomicRadioGroupV2<T>(props: Props<T>) {
  const [field, setField] = useRecoilState(props.state);

  return (
    <FormControl
      component="fieldset"
      size="small"
      style={{ width: '-webkit-fill-available', flexGrow: 1, marginLeft: '8px' }}
    >
      <FormLabel component="legend">{props.label}</FormLabel>
      <RadioGroup
        value={field}
        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
          const value: any = (e.target as HTMLInputElement).value;
          setField(value as T);
        }}
      >
        <Box display="flex">
          {props.options.map((c, index) => {
            return (
              <FormControlLabel
                key={index}
                value={c}
                control={<Radio />}
                label={props.displayResolver(c)}
              />
            );
          })}
        </Box>
      </RadioGroup>
    </FormControl>
  );
}
