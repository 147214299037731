import { RuleFieldType } from 'api/GQL_Types';
import React from 'react';
import { useRecoilState } from 'recoil';
import { NetworkAdminPageStates } from '../states';
import { InputItem, InputRow } from './components/InputLayout';
import { RuleCard } from './components/RuleCard';
import { RuleCardDay } from './components/RuleCardDay';
import { RuleCardGroup } from './components/RuleCardGroup';
import { RuleFieldInput } from './components/RuleFieldInput';
import { RuleNumDaysInput } from './components/RuleNumDaysInput';

const ALL_poDateFields: RuleFieldType[] = [
  RuleFieldType.PoDate,
  RuleFieldType.PoExpectedCrd,
  RuleFieldType.PoRevisedCrd,
  RuleFieldType.PoIndc,
  RuleFieldType.PoFirstShip,
  RuleFieldType.PoLastShip,
  RuleFieldType.PoBookBy,
];

const ALL_logisticsDateFields: RuleFieldType[] = [
  RuleFieldType.LogisticsCyCutoff,
  RuleFieldType.LogisticsCfsCutoff,
  RuleFieldType.LogisticsCfsReceived,
  RuleFieldType.LogisticsVgmCutoff,
  RuleFieldType.LogisticsSiCutoff,
  RuleFieldType.LogisticsPolEtd,
  RuleFieldType.LogisticsPolAtd,
  RuleFieldType.LogisticsPodEta,
  RuleFieldType.LogisticsPodAta,
  RuleFieldType.LogisticsRampEta,
  RuleFieldType.LogisticsRampAta,
  RuleFieldType.LogisticsDeliveryEta,
  RuleFieldType.LogisticsDeliveryAta,
];

const ALL_containerDateFields: RuleFieldType[] = [
  RuleFieldType.ContainerShipFromFactory,
  RuleFieldType.ContainerTerminalReceived,
  RuleFieldType.ContainerRampEta,
  RuleFieldType.ContainerRampAta,
  RuleFieldType.ContainerRampOutGate,
  RuleFieldType.ContainerPortOutGate,
  RuleFieldType.ContainerDeliveryEta,
  RuleFieldType.ContainerDeliveryAta,
  RuleFieldType.ContainerDeliveryUnload,
  RuleFieldType.ContainerEmptyNotify,
  RuleFieldType.ContainerEmptyPickup,
  RuleFieldType.ContainerEmptyReturned,
  RuleFieldType.ContainerDemurrageLastFreeDate,
  RuleFieldType.ContainerDetentionLastFreeDate,
  RuleFieldType.ContainerConsolidatorLastFreeDate,
];

const ALL_LogisticsAndContainerDateFields = [
  ...ALL_logisticsDateFields,
  ...ALL_containerDateFields,
];

interface Props {}

export const RulesShipment: React.FC<Props> = (props) => {
  const [rulesForm, setRulesForm] = useRecoilState(NetworkAdminPageStates.rulesForm);

  if (!rulesForm) {
    return <div></div>;
  }

  return (
    <RuleCardGroup
      title="Shipment"
      bools={[
        rulesForm.shipmentPolEtdChangeEnabled,
        rulesForm.shipmentPodEtaChangeEnabled,
        rulesForm.shipmentRevisedCrdChangeEnabled,
        rulesForm.shipmentCriticalEnabled,
      ]}
    >
      <RuleCardDay
        notYetSupported
        name="Port of Load ETD Change"
        description="Notify when the Port of Load ETD changes by more than # days."
        enabled={rulesForm.shipmentPolEtdChangeEnabled}
        onEnabled={(enabled) =>
          setRulesForm({ ...rulesForm, shipmentPolEtdChangeEnabled: enabled })
        }
        daysPlusOrMinus
        days={rulesForm.shipmentPolEtdChangeDays}
        onDays={(days) => setRulesForm({ ...rulesForm, shipmentPolEtdChangeDays: days })}
      />
      <RuleCardDay
        notYetSupported
        name="Port of Discharge ETA Change"
        description="Notify when the Port of Discharge ETA changes by more than # days."
        enabled={rulesForm.shipmentPodEtaChangeEnabled}
        onEnabled={(enabled) =>
          setRulesForm({ ...rulesForm, shipmentPodEtaChangeEnabled: enabled })
        }
        daysPlusOrMinus
        days={rulesForm.shipmentPodEtaChangeDays}
        onDays={(days) => setRulesForm({ ...rulesForm, shipmentPodEtaChangeDays: days })}
      />
      <RuleCardDay
        notYetSupported
        name="Revised Cargo Ready Date Change"
        description="Notify when the Revised Cargo Ready Date changes by more than # days."
        enabled={rulesForm.shipmentRevisedCrdChangeEnabled}
        onEnabled={(enabled) =>
          setRulesForm({ ...rulesForm, shipmentRevisedCrdChangeEnabled: enabled })
        }
        daysPlusOrMinus
        days={rulesForm.shipmentRevisedCrdChangeDays}
        onDays={(days) => setRulesForm({ ...rulesForm, shipmentRevisedCrdChangeDays: days })}
      />
      <RuleCard
        notYetSupported
        name="Critical Shipment"
        description="When the difference between these dates exceeds # days."
        enabled={rulesForm.shipmentCriticalEnabled}
        onEnabled={(enabled) => {
          setRulesForm({ ...rulesForm, shipmentCriticalEnabled: enabled });
        }}
      >
        <InputRow>
          <InputItem small>
            <RuleNumDaysInput
              value={rulesForm.shipmentCriticalDays}
              onValue={(v) => {
                setRulesForm({ ...rulesForm, shipmentCriticalDays: v });
              }}
              plusOrMinus
            />
          </InputItem>
          <InputItem>
            <RuleFieldInput
              options={ALL_poDateFields}
              value={rulesForm.shipmentCriticalField1}
              onValue={(v) => {
                setRulesForm({ ...rulesForm, shipmentCriticalField1: v });
              }}
            />
          </InputItem>
        </InputRow>
        <InputRow>
          <InputItem small></InputItem>
          <InputItem>
            <RuleFieldInput
              options={ALL_LogisticsAndContainerDateFields}
              value={rulesForm.shipmentCriticalField2}
              onValue={(v) => {
                setRulesForm({ ...rulesForm, shipmentCriticalField2: v });
              }}
            />
          </InputItem>
        </InputRow>
      </RuleCard>
    </RuleCardGroup>
  );
};
