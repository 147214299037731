import { GqlHouseBill } from 'api/GQL_Types';
import { Document } from 'app/components/DocumentsPanel';
import { HotState } from 'components/HotToggleSwitch';
import { Remark } from 'components/Remarks';
import { newAtom } from 'recoil-utils/utils';

export interface HblPageData extends GqlHouseBill {
  hot: HotState;
}

export const HblPageStates = {
  hbl: newAtom<HblPageData | null>(null),
  allRemarks: newAtom<Remark[]>([]),
  allDocuments: newAtom<Document[]>([]),
};
