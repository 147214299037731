import { gql } from '@apollo/client/core';

export const PortFragment = gql`
  fragment Port on Port {
    code
    name
    latitude
    longitude
    stateCode
    stateName
    countryCode
    countryName
  }
`;

export const SearchPortsQuery = gql`
  query SearchPorts($query: String!) {
    searchPorts(query: $query) {
      ...Port
    }
  }
  ${PortFragment}
`;
