import React from 'react';
import PropTypes from 'prop-types';
import { useDivDimensions } from 'lib/useDivDimensions';

export default function TabPanel(props: any) {
  const { children, value, index, onMeasure, ...other } = props;
  const { divEl, width, height } = useDivDimensions();

  React.useEffect(() => {
    props.onMeasure(width, height - 8);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [width, height]);

  return (
    <div
      ref={divEl}
      role="tabpanel"
      hidden={value !== index}
      id={`profile-tabpanel-${index}`}
      aria-labelledby={`profile-tab-${index}`}
      {...other}
      style={{ flexGrow: 1 }}
    >
      {value === index && <div style={{ display: 'flex', flexGrow: 1 }}>{children}</div>}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
  onMeasure: PropTypes.any.isRequired,
};

export function panelProps(index: number) {
  return {
    id: `profile-tab-${index}`,
    'aria-controls': `profile-tabpanel-${index}`,
  };
}
