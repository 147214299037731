import Loading from 'app/Loading';
import scrollbarSize from 'dom-helpers/scrollbarSize';
import { sortBy } from 'lib/sort';
import * as React from 'react';
import { CSSProperties } from 'react';
import { AutoSizer, ScrollSync } from 'react-virtualized';
import BodyGrid from './body-grid';
import HeaderGrid from './header-grid';
import { ExpandableRow, GTableColumn, GTableColumnType, RenderCell, RenderCells } from './types';

interface Props<T> {
  noContentRenderer?: string | (() => React.ReactNode);
  columns: GTableColumn<T>[];
  headingLabels: Partial<
    {
      [key in keyof T]: string;
    }
  >;
  rows: T[];
  headerStyles?: CSSProperties;
  cellStyles?: CSSProperties;
  footerStyles?: CSSProperties;
  onRowClick?: (data: T) => void;
  onCellClick?: (data: T) => void;
  expandable?: ExpandableRow<T>;
  renderCell?: RenderCell<T>;
  renderCells?: RenderCells<T>;
  renderHeaderCell?: RenderCell<T>;
  renderHeaderCells?: RenderCells<T>;
  renderFooterCell?: RenderCell<T>;
  isLoading?: boolean;
  debugId?: string;
  emptyMessage?: string;
  linkInTab?: boolean;
  onHeightEstimated?: (height: number, headerHeight: number) => void;
}

export default function GTable<T extends {}>(props: Props<T>) {
  const [headerHeight, setHeaderHeight] = React.useState(1);
  const [bodyHeight, setBodyHeight] = React.useState(0);
  const [footerHeight, setFooterHeight] = React.useState(1);

  const [sortedRows, setSortedRows] = React.useState(props.rows);
  const [labels, setLabels] = React.useState<any[]>([]);

  React.useEffect(() => {
    setSortedRows([...props.rows]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.rows]);

  React.useEffect(() => {
    setLabels([{ ...props.headingLabels, id: 'header-id' }]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.headingLabels]);

  React.useEffect(() => {
    if (props.onHeightEstimated && headerHeight > 1 && bodyHeight > 0) {
      props.onHeightEstimated(headerHeight + bodyHeight + footerHeight, headerHeight);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [headerHeight]);

  React.useEffect(() => {
    if (props.onHeightEstimated && bodyHeight > 0 && headerHeight > 1) {
      props.onHeightEstimated(headerHeight + bodyHeight + footerHeight, headerHeight);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [bodyHeight]);

  React.useEffect(() => {
    if (props.onHeightEstimated && footerHeight > 0 && bodyHeight > 0 && headerHeight > 1) {
      props.onHeightEstimated(headerHeight + bodyHeight + footerHeight, headerHeight);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [footerHeight]);

  const footerOffset = footerHeight > 1 ? footerHeight + scrollbarSize() : 0;

  return (
    <ScrollSync>
      {({ onScroll, scrollLeft, scrollTop }) => {
        return (
          <div
            style={{
              width: '100%',
              display: 'flex',
              flexDirection: 'column',
              height: '100%',
            }}
          >
            <AutoSizer>
              {({ width, height }) => {
                return (
                  <>
                    <HeaderGrid<T>
                      width={width}
                      height={headerHeight}
                      rows={labels}
                      columns={props.columns}
                      styles={props.headerStyles}
                      renderCell={props.renderHeaderCell}
                      renderCells={props.renderHeaderCells}
                      scrollLeft={scrollLeft}
                      onHeightEstimated={(height) => {
                        setHeaderHeight(height);
                      }}
                      onSort={(column: any, direction: any) => {
                        setSortedRows(
                          handleTypeSort<T>([...sortedRows], column.id, column.type, direction)
                        );
                      }}
                    />

                    {props.isLoading ? (
                      <div
                        style={{
                          width: width,
                          height: height - headerHeight - footerHeight,
                          display: 'flex',
                          justifyContent: 'center',
                          alignItems: 'center',
                        }}
                      >
                        <Loading />
                      </div>
                    ) : (
                      <BodyGrid<T>
                        scrollTop={scrollTop}
                        debugId={props.debugId}
                        onHeightEstimated={(height) => {
                          setBodyHeight(height);
                        }}
                        noContentRenderer={() => (
                          <div
                            style={{ display: 'flex', justifyContent: 'center', marginTop: '24px' }}
                          >
                            - {props.noContentRenderer} -
                          </div>
                        )}
                        width={width}
                        height={height - headerHeight - footerOffset}
                        rows={sortedRows}
                        columns={props.columns}
                        styles={props.cellStyles}
                        onScroll={onScroll}
                        renderCell={props.renderCell}
                        renderCells={props.renderCells}
                        expandable={props.expandable}
                        onRowClick={props.onRowClick}
                        onCellClick={props.onCellClick}
                        linkInTab={props.linkInTab}
                      />
                    )}

                    {props.renderFooterCell && props.rows.length > 0 && (
                      <div
                        style={{
                          width: width,
                          position: 'absolute',
                          top: height - footerHeight,
                          left: 0,
                        }}
                      >
                        <HeaderGrid<T>
                          width={width}
                          height={footerHeight}
                          rows={[{}]}
                          columns={props.columns}
                          styles={props.footerStyles}
                          renderCell={props.renderFooterCell}
                          scrollLeft={scrollLeft}
                          onHeightEstimated={(height) => {
                            setFooterHeight(height);
                          }}
                        />
                      </div>
                    )}
                  </>
                );
              }}
            </AutoSizer>
          </div>
        );
      }}
    </ScrollSync>
  );
}

function handleTypeSort<T extends {}>(
  data: any[],
  id: Extract<keyof T, string>,
  type: GTableColumnType,
  direction: 'asc' | 'desc'
): any[] {
  switch (type) {
    case 'date':
      return sortBy(
        data,
        (row) => {
          const val = row[id];
          if (Array.isArray(val)) {
            return val.length;
          }
          return val;
        },
        direction
      );

    case 'link':
      return sortBy(
        data,
        (row) => {
          const val = row[id];
          if (Array.isArray(val)) {
            return val.length;
          }
          return val?.value;
        },
        direction
      );

    case 'string':
    case 'number':
    case 'currency':
    default:
      return sortBy(data, (row) => row[id], direction);
  }
}
