import { gqlClient } from 'api/ApolloClient';
import {
  GqlSetPoRelatedPartiesMutation,
  GqlSetPoRelatedPartiesMutationVariables,
} from 'api/GQL_Types';
import { SetPoRelatedPartiesMutation } from 'api/queries/poQueries';
import { OverrideRelatedPartiesDialog2 } from 'components/override-related-parties-dialog/OverrideRelatedPartiesDialog2';
import { useAsyncAction } from 'lib/useAsyncAction';
import React from 'react';
import { PoPageData } from './states';

interface Props {
  data: PoPageData;
  onClose(): void;
  reloadPoPageData(): void;
}

export const PoPartyOverride: React.FC<Props> = ({ data, onClose, reloadPoPageData }) => {
  const actionSetPoRelatedPartiesMutation = useAsyncAction(setPoRelatedParties);

  return (
    <OverrideRelatedPartiesDialog2
      onClose={onClose}
      isSaving={actionSetPoRelatedPartiesMutation.waiting}
      saveError={actionSetPoRelatedPartiesMutation.error}
      value={data.relatedParties}
      onValue={(value) => {
        actionSetPoRelatedPartiesMutation
          .act({
            input: {
              poId: data.id,
              parties: value.map((v) => {
                return {
                  profileId: v.profileId,
                  partyType: v.partyType,
                };
              }),
            },
          })
          .then((res) => {
            if (res.type === 'data') {
              reloadPoPageData();
              onClose();
            }
          });
      }}
    />
  );
};

async function setPoRelatedParties(
  variables: GqlSetPoRelatedPartiesMutationVariables
): Promise<void> {
  const res = await gqlClient.mutate<GqlSetPoRelatedPartiesMutation>({
    mutation: SetPoRelatedPartiesMutation,
    variables,
  });
  if (!res.data?.setPoRelatedParties.success) {
    throw new Error(res.data?.setPoRelatedParties.message || 'Unexpected Error');
  }
}
