import { Box, Grid, Typography } from '@material-ui/core';
import { GqlLocation, ShipmentStatus, useVesselsQuery } from 'api/GQL_Types';
import AtomicAutocompleteV2 from 'components/atomic/AtomicAutocompleteV2';
import AtomicDatePickerV2 from 'components/atomic/AtomicDatePickerV2';
import { FormInputPortAny } from 'components/form/FormInputPortAny';
import { FormItem } from 'components/form/FormItem';
import SectionTitle from 'components/SectionTitle';
import React from 'react';
import { RecoilState, useRecoilState, useRecoilValue } from 'recoil';
import { ConsolidationPageStates, fromGQL_VesselOption, VesselOption, VoyageOption } from '../../states';

export default function ShippingLogistics() {
  const consolidation = useRecoilValue(ConsolidationPageStates.consolidation);
  const [pod, setPod] = useRecoilState(ConsolidationPageStates.editLogistics.pod);
  const [pol, setPol] = useRecoilState(ConsolidationPageStates.editLogistics.pol);
  const [transitPort, setTransitPort] = useRecoilState(ConsolidationPageStates.editLogistics.transitPort);
  const [ramp, setRamp] = useRecoilState(ConsolidationPageStates.editLogistics.ramp);
  const [vessels, setVessels] = React.useState<VesselOption[]>([]);

  useVesselsQuery({
    onCompleted(data) {
      setVessels(data.vessels.map(fromGQL_VesselOption));
    },
  });

  const locationMap =
    consolidation?.relatedParties.reduce((locationMap: { [key: string]: GqlLocation }, rp) => {
      for (const loc of rp.party.locations as GqlLocation[]) {
        locationMap[loc.id] = loc;
      }
      return locationMap;
    }, {}) ?? {};

  return (
    <>
      <SectionTitle title="Shipping Logistics" />
      <Box padding={1} color="#1897A0">
        <Typography variant="h4" color="inherit">
          Load Port
        </Typography>
      </Box>
      <Grid container spacing={3} alignItems="flex-start" direction="row">
        <Grid item xs={4}>
          <FormItem>
            <FormInputPortAny label="POL" value={pol} onValue={setPol} />
          </FormItem>
        </Grid>
        <Grid item xs={4}>
          <AtomicDatePickerV2 state={ConsolidationPageStates.editLogistics.polEtd} label="POL ETD" />
        </Grid>
        <Grid item xs={4}>
          <AtomicDatePickerV2
            state={ConsolidationPageStates.editLogistics.polAtd}
            label="POL ATD"
            disabled={
              consolidation?.status === ShipmentStatus.Booked ||
              consolidation?.status === ShipmentStatus.Confirmed
            }
          />
        </Grid>
      </Grid>
      <hr />

      <Box padding={1} color="#1897A0">
        <Typography variant="h4" color="inherit">
          Transshipment
        </Typography>
      </Box>
      <Grid container spacing={3} alignItems="flex-start">
        <Grid item xs={4}>
          <FormItem>
            <FormInputPortAny
              label="Transshipment Port"
              value={transitPort}
              onValue={setTransitPort}
            />
          </FormItem>
        </Grid>
        <Grid item xs={4}>
          <AtomicDatePickerV2
            state={ConsolidationPageStates.editLogistics.transitPortEta}
            label="Transship ETA"
          />
        </Grid>
        <Grid item xs={4}>
          <AtomicDatePickerV2
            state={ConsolidationPageStates.editLogistics.transitPortAta}
            label="Transship ATA"
            disabled={consolidation?.status !== ShipmentStatus.Shipped}
          />
        </Grid>
      </Grid>
      <Grid container spacing={3} alignItems="flex-start">
        <Grid item xs={4}>
          <AtomicDatePickerV2
            state={ConsolidationPageStates.editLogistics.transitPortEtd}
            label="Transship ETD"
          />
        </Grid>
        <Grid item xs={4}>
          <AtomicDatePickerV2
            state={ConsolidationPageStates.editLogistics.transitPortAtd}
            label="Transship ATD"
            disabled={consolidation?.status !== ShipmentStatus.Shipped}
          />
        </Grid>
      </Grid>
      <hr />

      <Box padding={1} color="#1897A0">
        <Typography variant="h4" color="inherit">
          Discharge Port
        </Typography>
      </Box>
      <Grid container spacing={3} alignItems="flex-start">
        <Grid item xs={4}>
          <FormItem>
            <FormInputPortAny label="POD" value={pod} onValue={setPod} />
          </FormItem>
        </Grid>
        <Grid item xs={4}>
          <AtomicDatePickerV2 state={ConsolidationPageStates.editLogistics.podEta} label="POD ETA" />
        </Grid>
        <Grid item xs={4}>
          <FormItem>
            <FormInputPortAny label="Ramp" value={ramp} onValue={setRamp} />
          </FormItem>
        </Grid>
      </Grid>

      <Grid container spacing={3} alignItems="flex-start">
        <Grid item xs={4}>
          <AtomicDatePickerV2 state={ConsolidationPageStates.editLogistics.rampEta} label="Ramp ETA" />
        </Grid>
        <Grid item xs={4}>
          <AtomicAutocompleteV2<GqlLocation>
            state={ConsolidationPageStates.editLogistics.finalDestination}
            label={'Final Destination'}
            optionsList={Object.values(locationMap)}
            displayResolver={(value: GqlLocation) => {
              return value.name ?? 'N/A';
            }}
          />
        </Grid>
        <Grid item xs={4}>
          <AtomicDatePickerV2
            state={ConsolidationPageStates.editLogistics.deliveryEta}
            label="Delivery ETA"
          />
        </Grid>
      </Grid>
      <hr />
      <Box padding={1} color="#1897A0">
        <Typography variant="h4" color="inherit">
          Vessel
        </Typography>
      </Box>

      <VesselVoyageSelector
        vessels={vessels}
        setVessels={setVessels}
        adjective={'Mother'}
        vesselAtom={ConsolidationPageStates.editLogistics.motherVessel}
        voyageAtom={ConsolidationPageStates.editLogistics.motherVoyage}
      />
      <VesselVoyageSelector
        vessels={vessels}
        setVessels={setVessels}
        adjective={'Feeder'}
        vesselAtom={ConsolidationPageStates.editLogistics.feederVessel}
        voyageAtom={ConsolidationPageStates.editLogistics.feederVoyage}
      />
    </>
  );
}

interface VesselVoyageSelectorProps {
  vessels: VesselOption[];
  setVessels: React.Dispatch<React.SetStateAction<VesselOption[]>>;
  adjective: string;
  vesselAtom: RecoilState<VesselOption | null>;
  voyageAtom: RecoilState<VoyageOption | null>;
}

function VesselVoyageSelector(props: VesselVoyageSelectorProps) {
  const [vessel, setVessel] = useRecoilState(props.vesselAtom);
  const [voyage, setVoyage] = useRecoilState(props.voyageAtom);
  const [voyages, setVoyages] = React.useState<VoyageOption[]>([]);
  const refVoyageInput = React.useRef<HTMLInputElement>(null);
  const [focusVoyage, setFocusVoyage] = React.useState<boolean>(true);

  React.useEffect(() => {
    const voyages = vessel?.voyages || [];
    setVoyages(voyages);
    setVoyage(
      (voyage && voyages.find((v: any) => v.id === voyage.id && v.name === voyage.name)) || null
    );
  }, [vessel]);

  React.useEffect(() => {
    if (focusVoyage && vessel) {
      // going through this rigamarole so that when the user hits tab to create a new vessel they go the voyage input AFTER it has been enabled - OMS-101
      if (refVoyageInput.current) {
        refVoyageInput.current.focus();
      }
      setFocusVoyage(false);
    }
  }, [focusVoyage, vessel]);

  return (
    <Grid container spacing={3} alignItems="flex-start">
      <Grid item xs={6}>
        <AtomicAutocompleteV2<VesselOption>
          state={props.vesselAtom}
          label={props.adjective + ' Vessel'}
          optionsList={props.vessels}
          onAddNew={(text, event) => {
            event.preventDefault(); // going through this rigamarole so that when the user hits tab to create a new vessel they go the voyage input AFTER it has been enabled - OMS-101
            setFocusVoyage(true);

            const newVessel: VesselOption = { id: null, name: text, voyages: [] };
            setVessel(newVessel);
            props.setVessels([newVessel, ...props.vessels]);
          }}
          displayResolver={(value) => value.name + (!value.id ? ' (new)' : '')}
        />
      </Grid>
      <Grid item xs={6}>
        <AtomicAutocompleteV2<VoyageOption>
          inputRef={refVoyageInput}
          state={props.voyageAtom}
          label={props.adjective + ' Voyage'}
          optionsList={voyages}
          disabled={!Boolean(vessel)}
          onAddNew={(text) => {
            const newVoyage: VoyageOption = { id: null, name: text };
            setVoyage(newVoyage);
            setVoyages([newVoyage, ...voyages]);
          }}
          displayResolver={(value) => value.name + (!value.id ? ' (new)' : '')}
        />
      </Grid>
    </Grid>
  );
}
