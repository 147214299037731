import Collapse from '@material-ui/core/Collapse';
import Paper from '@material-ui/core/Paper';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import clsx from 'clsx';
import React from 'react';
import { makeCss, theme } from 'styles';

const transition = {
  duration: theme.transitions.duration.shortest,
};

const classes = makeCss({
  root: {
    flexGrow: 1,
    width: '100%',
    marginBottom: theme.spacing(2),
  },
  header: {
    padding: theme.spacing(1, 2),
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: '100%',
    transition: theme.transitions.create(['padding'], {
      duration: theme.transitions.duration.shortest,
    }),
    '&$isCollapsed': {
      padding: theme.spacing(2),
    },
    '&$collapsible': {
      cursor: 'pointer',
    },
  },
  titleWrap: {
    display: 'flex',
    alignItems: 'center',
  },
  title: {
    ...theme.typography.h2,
    color: theme.palette.primary.main,
    letterSpacing: '.5px',
    marginRight: '12px',
    userSelect: 'none',
  },
  collapsible: {},
  isCollapsed: {},
  topRight: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  expandIcon: {
    marginLeft: theme.spacing(2),
    transform: 'rotate(180deg)',
    transition: theme.transitions.create('transform', transition),
    '&$isCollapsed': {
      transform: 'rotate(0deg)',
    },
  },
  body: {
    flexGrow: 1,
    minHeight: 0, // NOTE: Don't remove this. This is a silly hack that tells flexbox to not not let this div overflow the panel when the children have their own scroll area.
  },
  paper: {
    height: '100%',
    overflow: 'hidden',
    display: 'flex',
    flexDirection: 'column',
  },
});

interface Props {
  title: string;
  titleDecorator?: React.ReactNode;
  topCenter?: React.ReactNode;
  topRight?: React.ReactNode;
  growPanel?: boolean;

  collapsible?: boolean;
  isCollapsed?: boolean;
  setIsCollapsed?: (isCollapsed: boolean) => void;
}

export default function Panel(props: React.PropsWithChildren<Props>) {
  return (
    <div className={classes.root}>
      <Paper className={classes.paper} elevation={2}>
        <div
          className={clsx(
            classes.header,
            props.isCollapsed && classes.isCollapsed,
            props.collapsible && classes.collapsible
          )}
          onClick={
            props.setIsCollapsed
              ? (e) => props.setIsCollapsed && props.setIsCollapsed(!props.isCollapsed)
              : undefined
          }
        >
          <div className={classes.titleWrap}>
            <div className={classes.title}>{props.title}</div>
            {props.titleDecorator}
          </div>

          {props.topCenter}

          {props.topRight}

          {props.collapsible && (
            <div className={clsx(classes.expandIcon, props.isCollapsed && classes.isCollapsed)}>
              <ExpandMoreIcon />
            </div>
          )}
        </div>

        <div className={classes.body}>
          {props.collapsible ? (
            <Collapse in={!props.isCollapsed} timeout="auto">
              {props.children}
            </Collapse>
          ) : (
            props.children
          )}
        </div>
      </Paper>
    </div>
  );
}
