import { Box, Grid, Typography } from '@material-ui/core';
import { DashboardFeatureCode, GqlPackLine } from 'api/GQL_Types';
import { userContextAtom } from 'app';
import React from 'react';
import { useRecoilValue } from 'recoil';
import { theme } from 'styles';
import BookingBarLabels from './BookingBarLabels';

interface Props {
  rowHeight?: number;
  containerHeight: number;
  containerWidth: number;
}

export function addPackLineTotals(totals: GqlPackLine[]): number {
  let total = 0;
  for (const t of totals) {
    total += t.linePrice ?? 0;
  }
  return total;
}

export default function DashboardBookingsDetails(props: Props) {
  const userContext = useRecoilValue(userContextAtom);
  const height = props.rowHeight ?? 40;

  return (
    <Box
      display="flex"
      height={props.containerHeight}
      width={props.containerWidth}
      flexDirection="column"
      justifyContent="space-around"
      marginTop={2}
    >
      <Grid container wrap="nowrap" justify="space-around">
        <Grid item xs={2}>
          <Box display="flex" flexGrow={1} flexDirection="column">
            {userContext?.dashboardFeatureCodes.has(DashboardFeatureCode.ChartBookingsByWeekDetailsContainers) && (
              <LeftLabelBox rowHeight={height}>Total Containers</LeftLabelBox>
            )}
            {userContext?.dashboardFeatureCodes.has(DashboardFeatureCode.ChartBookingsByWeekDetailsSku) && (
              <LeftLabelBox rowHeight={height}>SKU/Line Items</LeftLabelBox>
            )}
            {userContext?.dashboardFeatureCodes.has(DashboardFeatureCode.ChartBookingsByWeekDetailsBookings) && (
              <LeftLabelBox rowHeight={height}>Bookings</LeftLabelBox>
            )}
            {userContext?.dashboardFeatureCodes.has(DashboardFeatureCode.ChartBookingsByWeekDetailsCommercialValue) && (
              <LeftLabelBox rowHeight={height}>Commercial Invoice Value</LeftLabelBox>
            )}
          </Box>
        </Grid>
        <Grid item xs={10}>
          <BookingBarLabels rowHeight={height} />
        </Grid>
      </Grid>
    </Box>
  );
}

interface LeftLabelBoxProps {
  children?: any;
  rowHeight: number;
}

function LeftLabelBox(props: LeftLabelBoxProps) {
  return (
    <Box
      style={{
        display: 'flex',
        paddingLeft: '24px',
        alignItems: 'center',
        backgroundColor: theme.palette.grayscale.light,
        height: props.rowHeight + 'px',
        marginTop: props.rowHeight * 0.15 + 'px',
        marginBottom: props.rowHeight * 0.15 + 'px',
      }}
    >
      <Typography variant="h4">{props.children}</Typography>
    </Box>
  );
}
