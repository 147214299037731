import { Box, Typography } from '@material-ui/core';
import { HotPoLineSwitch } from 'app/components/HotPoLineSwitch';
import Panel from 'components/Panel';
import { Prepend } from 'components/Prepend';
import { UWLTable } from 'components/UWLTable/UWLTable';
import React from 'react';
import { UWLTableColumn } from 'types/UWLTable';
import { roundNumberDecimalPlacesWithCommas } from 'utils/Numbers';
import { PoPageData, PoPageDataLineRow } from './states';

const lineHeaders: UWLTableColumn<PoPageDataLineRow>[] = [
  { id: 'itemNumber', label: 'Item/SKU', type: 'string', whiteSpace: 'nowrap' },
  { id: 'lineNumber', label: 'Line', type: 'string' },
  { id: 'description', label: 'Description', type: 'string' },
  { id: 'totalQty', label: 'Order Qty', type: 'string' },
  { id: 'outerQty', label: 'Cartons', type: 'string' },
  { id: 'approvedQty', label: 'Appr Qty', type: 'string' },
  { id: 'balanceQty', label: 'Balance Qty', type: 'string' },
  { id: 'shippedQty', label: 'Shipped Qty', type: 'string' },
  { id: 'weight', label: 'Weight', type: 'string' },
  { id: 'volume', label: 'Volume', type: 'string' },
  { id: 'expectedCargoReadyDate', label: 'Cgo Rdy Date', type: 'date' },
  { id: 'revisedCargoReadyDate', label: 'Rev Cgo Rdy Date', type: 'date' },
  { id: 'shipToLocationName', label: 'Ship To Loc', type: 'string' },
  { id: 'shipToLocationCode', label: 'Ship To Loc Code', type: 'string' },
  { id: 'indcDate', label: 'INDC Date', type: 'date' },
];

const expandedLineHeader: UWLTableColumn<PoPageDataLineRow>[] = [
  { id: 'htsCode', label: 'HTS Code', type: 'string' },
  { id: 'bookByDate', label: 'Booked by Date', type: 'date' },
  { id: 'lineStatus', label: 'Line Status', type: 'string' },
  { id: 'style', label: 'Style', type: 'string' },
  { id: 'size', label: 'Size', type: 'string' },
  { id: 'color', label: 'Color', type: 'string' },
  { id: 'length', label: 'Length', type: 'string' },
  { id: 'width', label: 'Width', type: 'string' },
  { id: 'height', label: 'Height', type: 'string' },
  { id: 'material', label: 'Material', type: 'string' },
  { id: 'categoryCode', label: 'Category Code', type: 'string' },
  { id: 'unitPrice', label: 'Unit Cost', type: 'currency', currencyField: 'unitCurrencyCode' },
  { id: 'dutyPrice', label: 'Duty', type: 'currency', currencyField: 'dutyCurrencyCode' },
  { id: 'firstShipDate', label: 'First Ship Date', type: 'date' },
  { id: 'lastShipDate', label: 'Last Ship Date', type: 'date' },
];

interface Props {
  data: PoPageData;
}

export const PoOrderLines: React.FC<Props> = (props) => {
  return (
    <Panel title="Order Lines">
      <Box marginX={2} marginY={1}>
        <UWLTable
          rowId="lineId"
          columns={lineHeaders}
          rows={props.data.orderLineRows}
          emptyMessage="No Orders Lines"
          renderCell={{
            itemNumber(row) {
              return (
                <Prepend
                  item={<HotPoLineSwitch orderLineId={row.lineId} initialState={row.hot} small />}
                >
                  <Typography variant="body1">
                    <Box component="span" fontWeight="caption.fontWeight" fontStyle="italic">
                      {row.itemNumber}
                    </Box>
                  </Typography>
                </Prepend>
              );
            },
            volume(row) {
              if (!row.volume) {
                return null;
              }
              return (
                <Typography variant="body1" align="right">
                  {roundNumberDecimalPlacesWithCommas(row.volume.volume, 2)}
                  <Box
                    component="span"
                    fontWeight="caption.fontWeight"
                    fontStyle="italic"
                    marginLeft={1}
                  >
                    {row.volume.unit}
                  </Box>
                </Typography>
              );
            },
            weight(row) {
              if (!row.weight) {
                return null;
              }
              return (
                <Typography variant="body1">
                  {roundNumberDecimalPlacesWithCommas(row.weight.weight, 2)}
                  <Box
                    component="span"
                    fontWeight="caption.fontWeight"
                    fontStyle="italic"
                    marginLeft={1}
                  >
                    {row.weight.unit}
                  </Box>
                </Typography>
              );
            },
            length(row) {
              if (!row.length) {
                return null;
              }
              return (
                <Typography variant="body1">
                  {roundNumberDecimalPlacesWithCommas(row.length.value, 2)}
                  <Box
                    component="span"
                    fontWeight="caption.fontWeight"
                    fontStyle="italic"
                    marginLeft={1}
                  >
                    {row.length.unit}
                  </Box>
                </Typography>
              );
            },
            width(row) {
              if (!row.width) {
                return null;
              }
              return (
                <Typography variant="body1">
                  {roundNumberDecimalPlacesWithCommas(row.width.value, 2)}
                  <Box
                    component="span"
                    fontWeight="caption.fontWeight"
                    fontStyle="italic"
                    marginLeft={1}
                  >
                    {row.width.unit}
                  </Box>
                </Typography>
              );
            },
            height(row) {
              if (!row.height) {
                return null;
              }
              return (
                <Typography variant="body1">
                  {roundNumberDecimalPlacesWithCommas(row.height.value, 2)}
                  <Box
                    component="span"
                    fontWeight="caption.fontWeight"
                    fontStyle="italic"
                    marginLeft={1}
                  >
                    {row.height.unit}
                  </Box>
                </Typography>
              );
            },
          }}
          rowExpand={(row) => {
            return (
              <UWLTable
                rowId="lineId"
                columns={expandedLineHeader}
                rows={[row]}
                emptyMessage="No Orders Lines"
                renderCell={{
                  volume(row) {
                    if (!row.volume) {
                      return null;
                    }
                    return (
                      <Typography variant="body1" align="right">
                        {roundNumberDecimalPlacesWithCommas(row.volume.volume, 2)}
                        <Box
                          component="span"
                          fontWeight="caption.fontWeight"
                          fontStyle="italic"
                          marginLeft={1}
                        >
                          {row.volume.unit}
                        </Box>
                      </Typography>
                    );
                  },
                  weight(row) {
                    if (!row.weight) {
                      return null;
                    }
                    return (
                      <Typography variant="body1">
                        {roundNumberDecimalPlacesWithCommas(row.weight.weight, 2)}
                        <Box
                          component="span"
                          fontWeight="caption.fontWeight"
                          fontStyle="italic"
                          marginLeft={1}
                        >
                          {row.weight.unit}
                        </Box>
                      </Typography>
                    );
                  },
                  length(row) {
                    if (!row.length) {
                      return null;
                    }
                    return (
                      <Typography variant="body1">
                        {roundNumberDecimalPlacesWithCommas(row.length.value, 2)}
                        <Box
                          component="span"
                          fontWeight="caption.fontWeight"
                          fontStyle="italic"
                          marginLeft={1}
                        >
                          {row.length.unit}
                        </Box>
                      </Typography>
                    );
                  },
                  width(row) {
                    if (!row.width) {
                      return null;
                    }
                    return (
                      <Typography variant="body1">
                        {roundNumberDecimalPlacesWithCommas(row.width.value, 2)}
                        <Box
                          component="span"
                          fontWeight="caption.fontWeight"
                          fontStyle="italic"
                          marginLeft={1}
                        >
                          {row.width.unit}
                        </Box>
                      </Typography>
                    );
                  },
                  height(row) {
                    if (!row.height) {
                      return null;
                    }
                    return (
                      <Typography variant="body1">
                        {roundNumberDecimalPlacesWithCommas(row.height.value, 2)}
                        <Box
                          component="span"
                          fontWeight="caption.fontWeight"
                          fontStyle="italic"
                          marginLeft={1}
                        >
                          {row.height.unit}
                        </Box>
                      </Typography>
                    );
                  },
                }}
              />
            );
          }}
        />
      </Box>
    </Panel>
  );
};
