import { ApolloProvider } from '@apollo/client/react';
import DateFnsUtils from '@date-io/date-fns';
import { MuiThemeProvider } from '@material-ui/core';
import { LocalizationProvider } from '@material-ui/pickers';
import { gqlClient } from 'api/ApolloClient';
import 'index.css';
import { SnackbarProvider } from 'notistack';
import React from 'react';
import ReactDOM from 'react-dom';
import { RecoilRoot } from 'recoil';
import { theme } from 'styles';
import App from './app';

ReactDOM.render(
  <MuiThemeProvider theme={theme}>
    <LocalizationProvider dateAdapter={DateFnsUtils}>
      <ApolloProvider client={gqlClient}>
        <RecoilRoot>
          <SnackbarProvider maxSnack={10}>
            <App />
          </SnackbarProvider>
        </RecoilRoot>
      </ApolloProvider>
    </LocalizationProvider>
  </MuiThemeProvider>,
  document.getElementById('root')
);
