import { Box, Button, Chip, Grid, Tooltip, Typography } from '@material-ui/core';
import CheckIcon from '@material-ui/icons/HowToReg';
import RemoveCircleOutlineIcon from '@material-ui/icons/RemoveCircleOutline';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconDefinition } from '@fortawesome/free-solid-svg-icons';
import WarningIcon from '@material-ui/icons/Warning';
import styled from 'styled-components';
import { theme } from 'styles';
import React from 'react';
import { Avatar } from '@material-ui/core';
import LineItem from 'components/LineItem';
import { ListCard } from 'components/ListCard';
import { getAccentColorFromUUID, getRandomAccentColor } from 'utils/Colors';
import { Line } from 'components/StyledComponents';

const Thumb = styled.div`
  display: 'inline-flex';
  border-radius: 2;
  border: '1px solid #eaeaea';
  margin-bottom: 8;
  margin-right: 8;
  width: 100;
  height: 100;
  padding: 4;
  box-sizing: 'border-box';
`;

interface DefaultFields {
  id: string;
  name: string;
  description?: string;
  warning?: string;
  logo?: string;
  status?: boolean;
}

interface IconDef<T> {
  name: string;
  key: Extract<keyof T, string>;
  icon: IconDefinition;
  color: string;
}

interface Props<T> {
  list: T[];
  iconList: IconDef<T>[];
  onManageClicked: (data: T) => void;
}

export default function AdminCardList<T extends DefaultFields>(props: Props<T>) {
  return (
    <Box paddingBottom={4}>
      <Grid container spacing={2}>
        {props.list.map((row) => {
          return (
            <Grid item xs={12} sm={6} md={4} xl={3} key={row.id}>
              <ListCard
                accentColor={row.id ? getAccentColorFromUUID(row.id) : getRandomAccentColor()}
              >
                <Box display="flex" flexDirection="row" paddingTop={1} paddingBottom={1}>
                  <Box display="flex" flexDirection="column" height="100%" width="100%">
                    <Box marginBottom={1} marginTop={1}>
                      {row.warning && (
                        <Box display="flex" justifyContent="center" alignItems="center">
                          <Tooltip
                            title={<Typography color="textSecondary">{row.warning}</Typography>}
                            interactive
                          >
                            <WarningIcon
                              style={{ color: theme.palette.warning.main, marginRight: '8px' }}
                            />
                          </Tooltip>
                          <Typography variant="h3">{row.name}</Typography>
                        </Box>
                      )}
                      {row.logo && (
                        <Box
                          display="flex"
                          justifyContent="space-between"
                          alignItems="center"
                          margin="0 8px"
                        >
                          <Thumb key={row.id}>
                            <Box style={{ display: 'flex', minWidth: 0, overflow: 'hidden' }}>
                              <img
                                alt=""
                                src={row.logo}
                                style={{
                                  display: 'block',
                                  maxWidth: '275px',
                                  minWidth: '32px',
                                  height: '32px',
                                }}
                              />
                            </Box>
                          </Thumb>
                          <Typography variant="h3">{row.name}</Typography>
                        </Box>
                      )}
                      {!row.logo && !row.warning && (
                        <Box textAlign="center">
                          <Typography variant="h3">{row.name}</Typography>
                          {row.hasOwnProperty('status') &&
                            (row.status ? (
                              <Chip
                                icon={<CheckIcon />}
                                label="Active User"
                                size="small"
                                variant="outlined"
                                color="secondary"
                              />
                            ) : (
                              <Box
                                display="flex"
                                bgcolor={theme.palette.warning.main}
                                borderRadius="12px"
                                marginLeft={1}
                              >
                                <RemoveCircleOutlineIcon
                                  style={{
                                    color: '#FFF',
                                    fontSize: '21px',
                                    margin: '0 3px 0 9px',
                                  }}
                                />
                                <Typography
                                  variant="body1"
                                  style={{
                                    color: '#FFF',
                                    fontSize: '15px',
                                    margin: '0 12px 0 3px',
                                  }}
                                >
                                  Inactive
                                </Typography>
                              </Box>
                            ))}
                        </Box>
                      )}
                      {row.description && (
                        <Box
                          marginTop={1}
                          textAlign="center"
                          overflow="hidden"
                          textOverflow="ellipsis"
                          whiteSpace="nowrap"
                        >
                          {row.description}
                        </Box>
                      )}
                    </Box>
                    <Line height={1} />
                    {props.iconList.map((icon) => {
                      return (
                        <Box key={icon.key + row.id}>
                          <Box display="flex" alignItems="center" paddingLeft={1} paddingRight={1}>
                            <Avatar
                              style={{
                                backgroundColor: icon.color,
                                height: theme.spacing(4),
                                width: theme.spacing(4),
                              }}
                            >
                              <FontAwesomeIcon icon={icon.icon} size="sm" color="#fff" />
                            </Avatar>
                            <LineItem label={icon.name} value={(row as T)[icon.key]} noUnderline />
                          </Box>
                          <Line height={1} />
                        </Box>
                      );
                    })}
                    <Box
                      display="flex"
                      height="100%"
                      width="100%"
                      marginBottom={1}
                      marginTop={2}
                      justifyContent="center"
                    >
                      <Button
                        onClick={() => {
                          props.onManageClicked(row);
                        }}
                        color="secondary"
                        style={{ width: '50%' }}
                        variant="contained"
                      >
                        Manage
                      </Button>
                    </Box>
                  </Box>
                </Box>
              </ListCard>
            </Grid>
          );
        })}
      </Grid>
    </Box>
  );
}
