import {
  GqlBooking,
  GqlConsolidation,
  GqlContainer,
  GqlDashboardBookingSummary,
  GqlDashboardShipmentInTransit,
  GqlPackLine,
  OrderStatus,
  PartyType,
} from 'api/GQL_Types';
import { DateRangeValue } from 'components/form/FormInputDateRange';
import { GTableColumn } from 'components/g-table/types';
import { newAtom } from 'recoil-utils/utils';
import { mapShipmentStatus } from 'utils/Enums';
import { NewBookingNotificationDisplay } from './alerts/buttons/NewBookingsButton';
import { NewDocumentNotificationDisplay } from './alerts/buttons/NewDocumentsButton';
import {
  DashExceptions,
  emptyState as DashExceptionsEmptyState,
} from './dashboard-exceptions/exceptions';

export interface PoTable {
  id: string;
  lineStatus: OrderStatus;
  po: { id: string; poNumber: string };
  poLink: TableLink;
  itemNumber: string;
  shipToLocationName: string;
  qty: number;
  vendor: String;
  expectedCargoReadyDate: Date | null | undefined;
  indcDate: Date | null | undefined;
  poValue: string;
}

export interface ShipmentStatuses {
  early: GqlContainer[];
  onTime: GqlContainer[];
  late: GqlContainer[];
}

export interface Milestones {
  onWater: ShipmentStatuses;
  atDischarge: ShipmentStatuses;
  atRamp: ShipmentStatuses;
  delivered: ShipmentStatuses;
  totals: ShipmentStatuses;
}

export interface TableLink {
  to: string;
  value: string;
}

export interface DashboardContainer {
  id: string;
  shipmentId: string;
  equipmentNumber: TableLink;
  equipmentType: string;
  bookingNumber: TableLink;
  bookingDate: Date | null | undefined;
  status: string;
  cargoReadyDate: Date | null | undefined;
  revisedCargoReadyDate: Date | null | undefined;
  pos: TableLink[];
  shipper: string;
  origin: string;
  destination: string;
  qty: number;
}

export interface DashboardSkuItem {
  id: string;
  pos: TableLink;
  sku: string;
  lineNumber: number;
  shippedQty: number;
  booking: TableLink;
  bookingDate: Date | null | undefined;
  status: string;
  cargoReadyDate: Date | null | undefined;
  revisedCargoReadyDate: Date | null | undefined;
  equipment: TableLink[];
  shipper: string;
  origin: string;
  destination: string;
}

export interface DashboardBooking {
  id: string;
  bookingNumber: TableLink;
  bookingDate: Date | null | undefined;
  status: string;
  cargoReadyDate: Date | null | undefined;
  revisedCargoReadyDate: Date | null | undefined;
  equipment: TableLink[];
  shipper: string;
  origin: string;
  destination: string;
}

export interface DashboardInvoiceItem {
  id: string;
  cost: number;
  pos: TableLink;
  sku: string;
  lineNumber: number;
  shippedQty: number;
  booking: TableLink;
  bookingDate: Date | null | undefined;
  status: string;
  cargoReadyDate: Date | null | undefined;
  revisedCargoReadyDate: Date | null | undefined;
  equipment: TableLink[];
  shipper: string;
  origin: string;
  destination: string;
}

export interface ContainersInTransitDisplay {
  id: string;
  unread: boolean;
  consignee: string;
  bl: TableLink;
  cargoReadyDate: Date | null | undefined;
  revisedCargoReadyDate: Date | null | undefined;
  shipToLocation: string;
  equipment: TableLink;
  invoice: number;
  carrier: string;
  pol: string;
  polEtd: Date | null | undefined;
  polAtd: Date | null | undefined;
  pod: string;
  podEta: Date | null | undefined;
  podAta: Date | null | undefined;
  ramp: string;
  rampEta: Date | null | undefined;
  rampAta: Date | null | undefined;
  deliveryLocation: string;
  deliveryEta: Date | null | undefined;
  deliveryAta: Date | null | undefined;
}

export const DashboardPageStates = {
  po: {
    openPos: newAtom<PoTable[]>([]),
    expiredPos: newAtom<PoTable[]>([]),
    latePos: newAtom<PoTable[]>([]),
    selectedOpenData: newAtom<PoTable[]>([]),
    openPopover: newAtom<boolean>(false),
    popoverLabel: newAtom<string>(''),
  },
  inTransit: newAtom<GqlDashboardShipmentInTransit[]>([]),
  exceptions: newAtom<DashExceptions>(DashExceptionsEmptyState()),
  booking: {
    bookingSummaries: newAtom<GqlDashboardBookingSummary[]>([]),
    trimmedWeeks: newAtom<GqlDashboardBookingSummary[]>([]),
    dateRange: newAtom<DateRangeValue>({
      type: 'plus-minus-n-days',
      nDays: 30,
    }),
  },
  notifications: {
    newBookings: newAtom<NewBookingNotificationDisplay[]>([]),
    newDocuments: newAtom<NewDocumentNotificationDisplay[]>([]),
  },
};

export const containersInTransitDisplayColumns: GTableColumn<ContainersInTransitDisplay>[] = [
  // TODO Hot Flag
  { id: 'consignee', label: 'Consignee', type: 'string', weight: 150 },
  { id: 'bl', label: 'BOL', type: 'link', weight: 150 },
  { id: 'cargoReadyDate', label: 'CRG RDY Date', type: 'date', weight: 150 },
  { id: 'revisedCargoReadyDate', label: 'REV CRG RDY Date', type: 'date', weight: 150 },
  { id: 'shipToLocation', label: 'Ship To Location', type: 'string', weight: 150 },
  { id: 'equipment', label: 'Equipment', type: 'link', weight: 150 },
  { id: 'invoice', label: 'COMM INV Total', type: 'currency', weight: 150 }, // COMM INV TOTAL (THIS IS THE SUM TOTAL OF ALL PO/LINES IN THE CONTAINER).
  { id: 'carrier', label: 'Carrier', type: 'string', weight: 150 },
  { id: 'pol', label: 'POL', type: 'string', weight: 100 },
  { id: 'polEtd', label: 'POL ETD', type: 'date', weight: 100 },
  { id: 'polAtd', label: 'POL ATD', type: 'date', weight: 100 },
  { id: 'pod', label: 'POD', type: 'string', weight: 100 },
  { id: 'podEta', label: 'POD ETA', type: 'date', weight: 100 },
  { id: 'podAta', label: 'POD ATA', type: 'date', weight: 100 },
  { id: 'ramp', label: 'Rail Ramp', type: 'string', weight: 100 },
  { id: 'rampEta', label: 'Rail Ramp ETA', type: 'date', weight: 100 },
  { id: 'rampAta', label: 'Rail Ramp ATA', type: 'date', weight: 100 },
  { id: 'deliveryLocation', label: 'Delivery Location', type: 'string', weight: 150 },
  { id: 'deliveryEta', label: 'Final ETA', type: 'date', weight: 100 },
  { id: 'deliveryAta', label: 'Final ATA', type: 'date', weight: 100 },
  // TODO In DC Date
];

interface ExtTableLink extends TableLink {
  containerType: string;
  qty: number;
}

export const transformBookingToDashboardBookingDisplay = (
  bookings: GqlBooking[]
): DashboardBooking[] => {
  const data: DashboardBooking[] = [];

  for (const booking of bookings) {
    const assigned: ExtTableLink[] = [];
    const unassigned: ExtTableLink[] = [];
    for (const container of booking.containers) {
      if (container.containerNumber) {
        assigned.push({
          to: '/equipment/' + container.id,
          value: container.containerNumber ?? '',
          qty: 1,
          containerType: container.containerType,
        });
      } else {
        const found = unassigned.find((e) => e.containerType === container.containerType);
        if (!found) {
          unassigned.push({
            to: '',
            value: `${container.containerType} (QTY: ${1})`,
            containerType: container.containerType,
            qty: 1,
          });
        } else {
          found.qty++;
          found.value = `${container.containerType} (QTY: ${found.qty})`;
        }
      }
    }

    const equipment: ExtTableLink[] = [...assigned, ...unassigned];
    data.push({
      id: booking.id,
      bookingNumber: { to: '/bookings/' + booking.id, value: booking.referenceNumber },
      bookingDate: booking.createDate,
      status: mapShipmentStatus(booking.status),
      cargoReadyDate: booking.cargoReadyDate,
      revisedCargoReadyDate: booking.revisedCargoReadyDate,
      equipment: equipment,
      shipper:
        booking.relatedParties.find((rp) => rp.partyType === PartyType.Shipper)?.party.name ?? '',
      origin: booking.logistics.pol?.name ?? '',
      destination: booking.logistics.pod?.name ?? '',
    });
  }
  return data;
};

export const transformContainerToDashboardContainerDisplay = (
  containers: GqlContainer[]
): DashboardContainer[] => {
  let assigned: DashboardContainer[] = [];
  const unassigned: DashboardContainer[] = [];
  // TODO Sort and find unassigned ones

  for (const container of containers) {
    if (container.containerNumber) {
      assigned.push({
        id: container.id,
        shipmentId: container.shipment.id,
        equipmentNumber: {
          to: `/equipment/${container.id}`,
          value: container.containerNumber ?? '',
        },
        equipmentType: container.containerType,
        bookingNumber: {
          to: `/bookings/${container.shipment.id}`,
          value: container.shipment.referenceNumber,
        },
        bookingDate: container.shipment.createDate,
        status: mapShipmentStatus(container.shipment.status),
        cargoReadyDate: (container.shipment as GqlBooking).cargoReadyDate,
        revisedCargoReadyDate: (container.shipment as GqlBooking).revisedCargoReadyDate,
        pos: container.vanPositions.map((vp) => {
          return {
            to: `/purchase-orders/${vp.packLine.orderLine.purchaseOrder.id}`,
            value: vp.packLine.orderLine.purchaseOrder.poNumber,
          };
        }),
        shipper:
          container.shipment.relatedParties.find((rp) => rp.partyType === PartyType.Shipper)?.party
            .name ?? '',
        origin: container.shipment.logistics.pol?.name ?? '',
        destination: container.shipment.logistics.pod?.name ?? '',
        qty: 1,
      });
    } else {
      unassigned.push({
        id: container.id,
        shipmentId: container.shipment.id,
        equipmentNumber: {
          to: ``,
          value: 'QTY: ',
        },
        equipmentType: container.containerType,
        bookingNumber: {
          to: `/bookings/${container.shipment.id}`,
          value: container.shipment.referenceNumber,
        },
        bookingDate: container.shipment.createDate,
        status: mapShipmentStatus(container.shipment.status),
        cargoReadyDate: (container.shipment as GqlBooking).cargoReadyDate,
        revisedCargoReadyDate: (container.shipment as GqlBooking).revisedCargoReadyDate,
        pos: container.vanPositions.map((vp) => {
          return {
            to: `/purchase-orders/${vp.packLine.orderLine.purchaseOrder.id}`,
            value: vp.packLine.orderLine.purchaseOrder.poNumber,
          };
        }),
        shipper:
          container.shipment.relatedParties.find((rp) => rp.partyType === PartyType.Shipper)?.party
            .name ?? '',
        origin: container.shipment.logistics.pol?.name ?? '',
        destination: container.shipment.logistics.pod?.name ?? '',
        qty: 1,
      });
    }
  }

  const uniques: DashboardContainer[] = [];

  for (const unassignedContainer of unassigned) {
    const dataFound = uniques.find(
      (container) =>
        container.shipmentId === unassignedContainer.shipmentId &&
        container.equipmentType === unassignedContainer.equipmentType
    );
    if (!dataFound) {
      uniques.push(unassignedContainer);
    } else {
      dataFound.qty++;
    }
  }

  for (const unique of uniques) {
    unique.equipmentNumber.value = 'QTY: ' + unique.qty;
  }

  assigned = assigned.concat(uniques);

  assigned = assigned.sort((a, b) => {
    const aDate = a.bookingDate?.getTime() || 0;
    const bDate = b.bookingDate?.getTime() || 0;
    if (aDate < bDate) {
      return -1;
    } else if (aDate > bDate) {
      return 1;
    } else {
      return 0;
    }
  });

  return assigned;
};

export const transformPackLineToSkuItemsDisplay = (
  packLines: GqlPackLine[]
): DashboardSkuItem[] => {
  const dialogData: DashboardSkuItem[] = [];
  for (const packline of packLines) {
    const assigned: ExtTableLink[] = [];
    const unassigned: ExtTableLink[] = [];

    for (const van of packline.vanPositions) {
      if (van.container.containerNumber) {
        assigned.push({
          to: '/equipment/' + van.container.id,
          value: van.container.containerNumber ?? '',
          qty: 1,
          containerType: van.container.containerType,
        });
      } else {
        const found = unassigned.find((e) => e.containerType === van.container.containerType);
        if (!found) {
          unassigned.push({
            to: '',
            value: `${van.container.containerType} (QTY: ${1})`,
            containerType: van.container.containerType,
            qty: 1,
          });
        } else {
          found.qty++;
          found.value = `${van.container.containerType} (QTY: ${found.qty})`;
        }
      }
    }

    const equipment: ExtTableLink[] = [...assigned, ...unassigned];

    dialogData.push({
      id: packline.id,
      pos: {
        to: `/purchase-orders/${packline.orderLine.purchaseOrder.id}`,
        value: packline.orderLine.purchaseOrder.poNumber,
      },
      sku: packline.orderLine.itemNumber,
      lineNumber: packline.lineNumber,
      shippedQty: packline.shippedQty,
      booking: { to: `/bookings/${packline.booking.id}`, value: packline.booking.referenceNumber },
      bookingDate: packline.booking.createDate,
      status: mapShipmentStatus(packline.booking.status),
      cargoReadyDate: packline.booking.cargoReadyDate,
      revisedCargoReadyDate: packline.booking.revisedCargoReadyDate,
      equipment: equipment,
      shipper:
        packline.booking.relatedParties.find((rp) => rp.partyType === PartyType.Shipper)?.party
          .name ?? '',
      origin: packline.booking.logistics.pol?.name ?? '',
      destination: packline.booking.logistics.pod?.name ?? '',
    });
  }

  return dialogData;
};

export const transformPackLineToInvoiceItemDisplay = (
  packLines: GqlPackLine[]
): DashboardInvoiceItem[] => {
  const data: DashboardInvoiceItem[] = [];

  for (const packLine of packLines) {
    const assigned: ExtTableLink[] = [];
    const unassigned: ExtTableLink[] = [];
    for (const van of packLine.vanPositions) {
      if (van.container.containerNumber) {
        assigned.push({
          to: '/equipment/' + van.container.id,
          value: van.container.containerNumber ?? '',
          qty: 1,
          containerType: van.container.containerType,
        });
      } else {
        const found = unassigned.find((e) => e.containerType === van.container.containerType);
        if (!found) {
          unassigned.push({
            to: '',
            value: `${van.container.containerType} (QTY: ${1})`,
            containerType: van.container.containerType,
            qty: 1,
          });
        } else {
          found.qty++;
          found.value = `${van.container.containerType} (QTY: ${found.qty})`;
        }
      }
    }

    const equipment: ExtTableLink[] = [...assigned, ...unassigned];
    data.push({
      id: packLine.id,
      cost: packLine.linePrice ?? 0,
      pos: {
        to: `/purchase-orders/${packLine.orderLine.purchaseOrder.id}`,
        value: packLine.orderLine.purchaseOrder.poNumber,
      },
      sku: packLine.orderLine.itemNumber,
      lineNumber: packLine.lineNumber,
      shippedQty: packLine.shippedQty,
      booking: { to: `/bookings/${packLine.booking.id}`, value: packLine.booking.referenceNumber },
      bookingDate: packLine.booking.createDate,
      status: mapShipmentStatus(packLine.booking.status),
      cargoReadyDate: packLine.booking.cargoReadyDate,
      revisedCargoReadyDate: packLine.booking.revisedCargoReadyDate,
      equipment: equipment,
      shipper:
        packLine.booking.relatedParties.find((rp) => rp.partyType === PartyType.Shipper)?.party
          .name ?? '',
      origin: packLine.booking.logistics.pol?.name ?? '',
      destination: packLine.booking.logistics.pod?.name ?? '',
    });
  }
  return data;
};

export const transformContainerToShipmentInTransitDisplay = (
  containers: GqlContainer[]
): ContainersInTransitDisplay[] => {
  const displayArray: ContainersInTransitDisplay[] = containers.map(
    (container): ContainersInTransitDisplay => {
      const shipment = container.shipment;
      const logistics = container.shipment.logistics;

      return {
        unread: true,
        id: container.id,
        consignee:
          shipment.relatedParties.find((party) => party.partyType === PartyType.Consignee)?.party
            .name ?? '',
        bl: (shipment as GqlConsolidation).mbl
          ? {
              to: `/mbl/${(shipment as GqlConsolidation).mbl?.id ?? ''}`,
              value: (shipment as GqlConsolidation).mbl?.referenceNumber ?? '',
            }
          : (shipment as GqlBooking).hbl
          ? {
              to: `/hbl/${(shipment as GqlBooking).hbl?.id ?? ''}`,
              value: (shipment as GqlBooking).hbl?.referenceNumber ?? '',
            }
          : { to: '', value: '' },
        cargoReadyDate: (shipment as GqlBooking).cargoReadyDate,
        revisedCargoReadyDate: (container.shipment as GqlBooking).revisedCargoReadyDate,
        shipToLocation: logistics.finalDestination?.name ?? '',
        equipment: {
          to: `/equipment/${container.id ?? ''}`,
          value: container.containerNumber ?? '',
        },
        invoice: container.vanPositions.reduce(
          (previousValue, position) => previousValue + (position.commercialInvoiceTotal ?? 0),
          0
        ),
        carrier: logistics.carrier?.name ?? '',
        pol: logistics.pol?.name ?? '',
        polEtd: logistics.polEtd,
        polAtd: logistics.polAtd,
        pod: logistics.pod?.name ?? '',
        podEta: logistics.podEta,
        podAta: logistics.podAta,
        ramp: container.ramp?.name ?? '',
        rampEta: container.rampEta,
        rampAta: container.rampAta,
        deliveryLocation: logistics.finalDestination?.name ?? '',
        deliveryEta: container.deliveryEta,
        deliveryAta: container.deliveryAta,
      };
    }
  );

  return displayArray;
};
