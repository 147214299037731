import { Box, Popover, Typography } from '@material-ui/core';
import { useWindowSize } from 'lib/useWindowSize';
import React from 'react';

interface Props {
  /**
   * The HTML element used to position ght popover
   */
  anchorEl?: null | Element | ((element: Element) => Element);

  open: boolean;
  onClose(): void;

  title: string;
  titleRight?: React.ReactNode;

  totalLabel?: string;
  totalValue?: string;

  /**
   * How wide should the popover be. Defaults to a percentage of the window i.e. 80%
   *
   * The component enforces a minium and max width to prevent the popover
   * from taking too much of the page so they can't click away to dismiss it.
   */
  width?: number;

  /**
   * How high is the contents of the the popover. Defaults to a minium hight i.e. 100px
   *
   * Fixing this height makes it easier for the popover to position itself properly.
   * Also makes it easier for tables to anchor their sticky header on scroll and figure out virtualization.
   *
   * The component enforces a minium and max height to prevent the popover
   * from taking too much of the page so they can't click away to dismiss it.
   */
  bodyHeight?: number;
}

export const PopoverTable: React.FC<Props> = ({
  open,
  onClose,
  title,
  titleRight,
  totalLabel,
  totalValue,
  width,
  bodyHeight,
  anchorEl,
  children,
}) => {
  const windowSize = useWindowSize();

  const maxBodyWidth = Math.min(windowSize.width - 150, windowSize.width * 0.8);
  const maxBodyHeight = Math.min(windowSize.height - 150, windowSize.height * 0.5);

  const fixedWidth = Math.max(300, Math.min(maxBodyWidth, width ?? maxBodyWidth));
  const fixedHeight = Math.max(120, Math.min(maxBodyHeight, bodyHeight ?? 0));

  return (
    <Popover
      open={open}
      anchorEl={anchorEl}
      onClose={onClose}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'center',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'center',
      }}
    >
      <Box padding={2} paddingBottom={0} display="flex" flexDirection="column">
        <Box display="flex" justifyContent="space-between" marginBottom={2}>
          <Box marginRight={3} display="flex" alignItems="center">
            <Typography variant="h3">{title}</Typography>
            {titleRight}
          </Box>
          <Box marginLeft={3}>
            <Box display="flex" alignItems="center">
              {totalLabel && (
                <Box marginRight={1}>
                  <Typography variant="h4">
                    <i>{totalLabel}:</i>
                  </Typography>
                </Box>
              )}
              <Typography variant="h3">{totalValue}</Typography>
            </Box>
          </Box>
        </Box>
        <div style={{ width: fixedWidth, height: fixedHeight }}>{children}</div>
      </Box>
    </Popover>
  );
};
