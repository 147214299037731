import { GqlPort, LocationType } from 'api/GQL_Types';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { newAtom } from 'recoil-utils/utils';

export const NewProfileFormStates = {
  profile: {
    id: newAtom<string | null>(null),
    name: newAtom<string>(''),
    code: newAtom<string>(''),
    type: newAtom<{ id: string; name: string }>({ id: '', name: '' }),
    logo: newAtom<File | null>(null),
  },
  location: {
    id: newAtom<string | null>(null),
    name: newAtom<string>(''),
    code: newAtom<string>(''),
    locationTypes: newAtom<LocationType[]>([]),
    addressLine1: newAtom<string>(''),
    addressLine2: newAtom<string>(''),
    city: newAtom<string>(''),
    state: newAtom<string>(''),
    postalCode: newAtom<string>(''),
    country: newAtom<string>(''),
    relatedPortCode: newAtom<GqlPort | null>(null),
    companyPhone: newAtom<string>(''),
    companyUrl: newAtom<string>(''),
  },
  contact: {
    id: newAtom<string | null>(null),
    firstName: newAtom<string>(''),
    lastName: newAtom<string>(''),
    title: newAtom<string>(''),
    email: newAtom<string>(''),
    phoneMain: newAtom<string>(''),
    extension: newAtom<string>(''),
    phoneCell: newAtom<string>(''),
    phoneAlternative: newAtom<string>(''),
  },
};

export function useSetupNewProfileForm() {
  // profile
  const setProfileId = useSetRecoilState(NewProfileFormStates.profile.id);
  const setProfileName = useSetRecoilState(NewProfileFormStates.profile.name);
  const setProfileCode = useSetRecoilState(NewProfileFormStates.profile.code);
  const setProfileType = useSetRecoilState(NewProfileFormStates.profile.type);
  const setProfileLogo = useSetRecoilState(NewProfileFormStates.profile.logo);

  // location
  const setLocationId = useSetRecoilState(NewProfileFormStates.location.id);
  const setLocationName = useSetRecoilState(NewProfileFormStates.location.name);
  const setLocationCode = useSetRecoilState(NewProfileFormStates.location.code);
  const setLocationLocationTypes = useSetRecoilState(NewProfileFormStates.location.locationTypes);
  const setLocationAddressLine1 = useSetRecoilState(NewProfileFormStates.location.addressLine1);
  const setLocationAddressLine2 = useSetRecoilState(NewProfileFormStates.location.addressLine2);
  const setLocationCity = useSetRecoilState(NewProfileFormStates.location.city);
  const setLocationState = useSetRecoilState(NewProfileFormStates.location.state);
  const setLocationPostalCode = useSetRecoilState(NewProfileFormStates.location.postalCode);
  const setLocationCountry = useSetRecoilState(NewProfileFormStates.location.country);
  const setLocationRelatedPortCode = useSetRecoilState(
    NewProfileFormStates.location.relatedPortCode
  );
  const setLocationCompanyPhone = useSetRecoilState(NewProfileFormStates.location.companyPhone);
  const setLocationCompanyUrl = useSetRecoilState(NewProfileFormStates.location.companyUrl);

  // contact
  const setContactId = useSetRecoilState(NewProfileFormStates.contact.id);
  const setContactFirstName = useSetRecoilState(NewProfileFormStates.contact.firstName);
  const setContactLastName = useSetRecoilState(NewProfileFormStates.contact.lastName);
  const setContactTitle = useSetRecoilState(NewProfileFormStates.contact.title);
  const setContactEmail = useSetRecoilState(NewProfileFormStates.contact.email);
  const setContactPhoneMain = useSetRecoilState(NewProfileFormStates.contact.phoneMain);
  const setContactExtension = useSetRecoilState(NewProfileFormStates.contact.extension);
  const setContactPhoneCell = useSetRecoilState(NewProfileFormStates.contact.phoneCell);
  const setContactPhoneAlternative = useSetRecoilState(
    NewProfileFormStates.contact.phoneAlternative
  );

  return (
    init: {
      profileId: string | null;
      profileName: string;
      profileCode: string;
    } | null = null
  ) => {
    // profile
    setProfileId(init ? init.profileId : null);
    setProfileName(init?.profileName || '');
    setProfileCode(init?.profileCode || '');
    setProfileType({ id: '', name: '' });
    setProfileLogo(null);

    // location
    setLocationId(null);
    setLocationName('');
    setLocationCode('');
    setLocationLocationTypes([]);
    setLocationAddressLine1('');
    setLocationAddressLine2('');
    setLocationCity('');
    setLocationState('');
    setLocationPostalCode('');
    setLocationCountry('');
    setLocationRelatedPortCode(null);
    setLocationCompanyPhone('');
    setLocationCompanyUrl('');

    // contact
    setContactId(null);
    setContactFirstName('');
    setContactLastName('');
    setContactTitle('');
    setContactEmail('');
    setContactPhoneMain('');
    setContactExtension('');
    setContactPhoneCell('');
    setContactPhoneAlternative('');
  };
}

export function useNewProfileFormState() {
  return {
    profile: {
      id: useRecoilValue(NewProfileFormStates.profile.id),
      name: useRecoilValue(NewProfileFormStates.profile.name),
      code: useRecoilValue(NewProfileFormStates.profile.code),
      type: useRecoilValue(NewProfileFormStates.profile.type),
      logo: useRecoilValue(NewProfileFormStates.profile.logo),
    },
    location: {
      id: useRecoilValue(NewProfileFormStates.location.id),
      name: useRecoilValue(NewProfileFormStates.location.name),
      code: useRecoilValue(NewProfileFormStates.location.code),
      locationTypes: useRecoilValue(NewProfileFormStates.location.locationTypes),
      addressLine1: useRecoilValue(NewProfileFormStates.location.addressLine1),
      addressLine2: useRecoilValue(NewProfileFormStates.location.addressLine2),
      city: useRecoilValue(NewProfileFormStates.location.city),
      state: useRecoilValue(NewProfileFormStates.location.state),
      postalCode: useRecoilValue(NewProfileFormStates.location.postalCode),
      country: useRecoilValue(NewProfileFormStates.location.country),
      relatedPortCode: useRecoilValue(NewProfileFormStates.location.relatedPortCode),
      companyPhone: useRecoilValue(NewProfileFormStates.location.companyPhone),
      companyUrl: useRecoilValue(NewProfileFormStates.location.companyUrl),
    },
    contact: {
      id: useRecoilValue(NewProfileFormStates.contact.id),
      firstName: useRecoilValue(NewProfileFormStates.contact.firstName),
      lastName: useRecoilValue(NewProfileFormStates.contact.lastName),
      title: useRecoilValue(NewProfileFormStates.contact.title),
      email: useRecoilValue(NewProfileFormStates.contact.email),
      phoneMain: useRecoilValue(NewProfileFormStates.contact.phoneMain),
      extension: useRecoilValue(NewProfileFormStates.contact.extension),
      phoneCell: useRecoilValue(NewProfileFormStates.contact.phoneCell),
      phoneAlternative: useRecoilValue(NewProfileFormStates.contact.phoneAlternative),
    },
  };
}
