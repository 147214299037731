import { gql } from '@apollo/client/core';
import { gqlClient } from 'api/ApolloClient';
import {
  GqlMarkNotificationsAsReadMutation,
  GqlMarkNotificationsAsReadMutationVariables,
} from '../GQL_Types';
import { PortFragment } from './portQueries';

export const NotificationsQuery = gql`
  query Notifications {
    notifications {
      id
      notificationCode
      createTime

      ... on NewBookingNotification {
        booking {
          id
          referenceNumber
          status
          createDate
          cargoReadyDate
          revisedCargoReadyDate
          logistics {
            confirmationDate
            pol {
              name
            }
            pod {
              name
            }
            finalDestination {
              name
            }
          }
          packLines {
            orderLine {
              expectedCargoReadyDate
              purchaseOrder {
                id
                poNumber
              }
              itemNumber
            }
          }
          containers {
            id
            containerType
          }
          relatedParties {
            partyType
            party {
              name
            }
          }
        }
      }
      ... on NewDocumentNotification {
        document {
          id
          documentType
          uploadDate
        }
        booking {
          id
          referenceNumber
          relatedParties {
            partyType
            party {
              name
            }
          }
          hbl {
            id
            referenceNumber
          }
          logistics {
            pol {
              ...Port
            }
            polEtd
            polAtd

            pod {
              ...Port
            }
            podEta
            deliveryLocation {
              name
            }
          }
        }
      }
    }
  }
  ${PortFragment}
`;

export const MarkNotificationsAsReadMutation = gql`
  mutation MarkNotificationsAsRead($input: MarkNotificationsAsReadInput!) {
    markNotificationsAsRead(input: $input) {
      success
      message
    }
  }
`;

export async function markNotificationsAsRead(notificationIds: string[]): Promise<void> {
  await gqlClient.mutate<
    GqlMarkNotificationsAsReadMutation,
    GqlMarkNotificationsAsReadMutationVariables
  >({
    mutation: MarkNotificationsAsReadMutation,
    variables: { input: { notificationIds } },
    fetchPolicy: 'no-cache',
  });
}

export const DashboardPOsQuery = gql`
  query DashboardPOs {
    dashboardPurchaseOrders {
      orderLine {
        id
        itemNumber
        lineStatus
        totalQty
        expectedCargoReadyDate
        indcDate
        unitPrice
        unitPriceCurrencyCode
        shipToLocation {
          id
          name
        }
        purchaseOrder {
          id
          poNumber
          relatedParties {
            partyType
            party {
              name
            }
          }
        }
      }
    }
  }
`;

export const BookingSummaryQuery = gql`
  query BookingSummary($startDate: Date, $endDate: Date) {
    bookingSummary(startDate: $startDate, endDate: $endDate) {
      start
      end
      weekNumber
      containers {
        id
        containerNumber
        containerType
        vanPositions {
          packLine {
            orderLine {
              purchaseOrder {
                id
                poNumber
              }
            }
          }
        }
        shipment {
          id
          referenceNumber
          createDate
          status
          ... on Booking {
            cargoReadyDate
            revisedCargoReadyDate
          }
          relatedParties {
            partyType
            party {
              name
            }
          }
          logistics {
            pol {
              name
            }
            pod {
              name
            }
          }
        }
      }
      skuPackLines {
        id
        orderLine {
          itemNumber
          purchaseOrder {
            id
            poNumber
          }
        }
        lineNumber
        shippedQty
        booking {
          id
          referenceNumber
          createDate
          status
          cargoReadyDate
          revisedCargoReadyDate
          relatedParties {
            partyType
            party {
              name
            }
          }
          logistics {
            pol {
              name
            }
            pod {
              name
            }
          }
        }
        vanPositions {
          container {
            id
            containerNumber
          }
        }
      }
      invoicePackLines {
        id
        orderLine {
          itemNumber
          purchaseOrder {
            id
            poNumber
          }
        }
        linePrice
        lineNumber
        shippedQty
        booking {
          id
          referenceNumber
          createDate
          status
          cargoReadyDate
          revisedCargoReadyDate
          relatedParties {
            partyType
            party {
              name
            }
          }
          logistics {
            pol {
              name
            }
            pod {
              name
            }
          }
        }
        vanPositions {
          container {
            id
            containerNumber
            containerType
          }
        }
        shippedCartons
        volume
        volumeUnit
        weight
        weightUnit
      }
      bookings {
        id
        referenceNumber
        createDate
        status
        ... on Booking {
          cargoReadyDate
          revisedCargoReadyDate
        }
        containers {
          id
          containerNumber
          containerType
        }
        relatedParties {
          partyType
          party {
            name
          }
        }
        logistics {
          pol {
            name
          }
          pod {
            name
          }
        }
      }
    }
  }
`;

export const DashboardNewBookingsQuery = gql`
  query DashboardNewBookings {
    newBookings {
      id
      referenceNumber
      createDate
      status
      cargoReadyDate
      revisedCargoReadyDate
      relatedParties {
        partyType
        party {
          name
        }
      }
      packLines {
        orderLine {
          id
          itemNumber
          purchaseOrder {
            id
            poNumber
          }
        }
      }
      containers {
        id
        containerNumber
        containerType
      }
      logistics {
        pol {
          name
        }
        pod {
          name
        }
      }
    }
  }
`;

export const DashboardNewDocumentsQuery = gql`
  query DashboardNewDocuments {
    newDocuments {
      id
      bookingId
      bookingNumber
      importFileName
      fileType
      documentType
      uploadDate
      shipperName
      hblId
      hblNumber
      pol {
        code
        name
      }
      polEtd
      polAtd
      pod {
        code
        name
      }
      podEta
      deliveryLocation
    }
  }
`;

export const ExceptionsQuery = gql`
  query Exceptions {
    exceptions {
      ... on DelayedArrivalException {
        type
        booking {
          id
          referenceNumber
          cargoReadyDate
          logistics {
            polEtd
            polAtd
            podEta
            podAta
            deliveryEta
            finalDestination {
              name
            }
          }
          relatedParties {
            partyType
            party {
              name
            }
          }
          packLines {
            orderLine {
              expectedCargoReadyDate
              purchaseOrder {
                id
                poNumber
              }
            }
          }
        }
      }
      ... on PendingOutgateException {
        type
        notificationId
        markedAsRead
        container {
          id
          containerNumber
          rampAta
          demurrageLastFreeDayDate
          deliveryEta
          shipment {
            logistics {
              finalDestination {
                name
              }
            }
          }
        }
      }
      ... on EmptyNotReturnedException {
        type
        notificationId
        markedAsRead
        container {
          id
          containerNumber
          rampAta
          portOutGateDate
          deliveryAta
          detentionLastFreeDayDate
          shipment {
            logistics {
              finalDestination {
                name
              }
            }
          }
        }
      }
      ... on HotPurchaseOrder {
        type
        openOrder {
          id
          orderLine {
            id
            lineStatus
            totalQty
            purchaseOrder {
              id
              poNumber
              relatedParties {
                partyType
                party {
                  name
                }
              }
            }
            item {
              itemNumber
            }
            shipToLocation {
              name
            }
          }
          isHot
          hotMarkedBy
          hotMarkedTimestamp
        }
      }
      ... on HotShipment {
        type
        shipment {
          id
          referenceNumber
          ... on Booking {
            cargoReadyDate
            revisedCargoReadyDate
            hbl {
              id
              referenceNumber
            }
            mbl {
              id
              referenceNumber
            }
            isHot
            hotMarkedBy
            hotMarkedTimestamp
          }
          ... on Consolidation {
            mbl {
              id
              referenceNumber
            }
          }
          logistics {
            carrier {
              name
            }
            pol {
              code
            }
            polEtd
            polAtd
            pod {
              code
            }
            podEta
            podAta
            ramp {
              code
            }
            rampEta
            rampAta
            finalDestination {
              name
            }
            deliveryEta
            deliveryAta
          }
          relatedParties {
            partyType
            party {
              name
            }
          }
          containers {
            id
            containerType
            vanPositions {
              commercialInvoiceTotal
            }
          }
        }
      }
      ... on HotContainer {
        type
        container {
          id
          containerNumber
          isHot
          hotMarkedBy
          hotMarkedTimestamp
          shipment {
            ... on Booking {
              cargoReadyDate
              revisedCargoReadyDate
              hbl {
                id
                referenceNumber
              }
            }
            ... on Consolidation {
              mbl {
                id
                referenceNumber
              }
            }
            logistics {
              carrier {
                name
              }
              pol {
                code
              }
              polEtd
              polAtd
              pod {
                code
              }
              podEta
              podAta
              ramp {
                code
              }
              rampEta
              rampAta
              finalDestination {
                name
              }
              deliveryEta
              deliveryAta
            }
            relatedParties {
              partyType
              party {
                name
              }
            }
          }
          vanPositions {
            commercialInvoiceTotal
          }
        }
      }
      ... on MissingDocumentException {
        type
        notificationId
        markedAsRead
        documentType
        booking {
          id
          referenceNumber
          cargoReadyDate
          hbl {
            id
            referenceNumber
          }
          mbl {
            id
            referenceNumber
          }
          logistics {
            pol {
              ...Port
            }
            polEtd
            polAtd
            pod {
              ...Port
            }
            podEta
            podAta
            deliveryEta
            finalDestination {
              name
            }
          }
          relatedParties {
            partyType
            party {
              name
            }
          }
          packLines {
            orderLine {
              expectedCargoReadyDate
              purchaseOrder {
                id
                poNumber
              }
            }
          }
        }
      }
      ... on LateBookingException {
        type
        notificationId
        markedAsRead
        booking {
          id
          referenceNumber
          status
          createDate
          cargoReadyDate
          revisedCargoReadyDate
          relatedParties {
            partyType
            party {
              name
            }
          }
          logistics {
            pol {
              ...Port
            }
            finalDestination {
              name
            }
          }
        }
        orderLine {
          id
          itemNumber
          bookByDate
          expectedCargoReadyDate
          purchaseOrder {
            id
            poNumber
            expectedCargoReadyDate
          }
        }
      }
    }
  }
  ${PortFragment}
`;

export const ShipmentsInTransitQuery = gql`
  query ShipmentsInTransit {
    shipmentsInTransit {
      container {
        id
        containerNumber
        ramp {
          name
        }
        rampEta
        rampAta
        deliveryEta
        deliveryAta
        vanPositions {
          commercialInvoiceTotal
        }
        shipment {
          ... on Booking {
            cargoReadyDate
            revisedCargoReadyDate
            hbl {
              id
              referenceNumber
            }
            mbl {
              id
              referenceNumber
            }
          }
          ... on Consolidation {
            mbl {
              id
              referenceNumber
            }
          }
          relatedParties {
            partyType
            party {
              name
            }
          }
          logistics {
            pol {
              name
            }
            polEtd
            polAtd
            pod {
              name
            }
            podEta
            podAta
            finalDestination {
              name
            }
            carrier {
              name
            }
            deliveryLocation {
              name
            }
          }
        }
      }
      status
      milestone
      isDueToArrive
    }
  }
`;
