import React from 'react';
import { Link } from '@reach/router';
import Panel from 'components/Panel';
import { UWLTable } from 'components/UWLTable/UWLTable';
import { UWLTableColumn } from 'types/UWLTable';
import { MblPageStates } from '../states';
import { useRecoilValue } from 'recoil';
import { GqlBooking } from 'api/GQL_Types';
import { Box } from '@material-ui/core';

interface RelatedHBLS {
  id: string;
  hbl: string;
  type: string;
  bookingNumber: string;
  bookingId: string;
}

const columns: UWLTableColumn<RelatedHBLS>[] = [
  { id: 'hbl', label: 'HBL', type: 'string' },
  { id: 'type', label: 'Type', type: 'string' },
  { id: 'bookingNumber', label: 'Booking #', type: 'string' },
];

export default function RelatedHbl() {
  const mbl = useRecoilValue(MblPageStates.mbl);
  const [hblList, setHblList] = React.useState<RelatedHBLS[]>([]);

  React.useEffect(() => {
    let shipments: any = [];
    const hblList = [(mbl?.shipment as GqlBooking).hbl]
    if (mbl) {
      for (const hbl of hblList ?? []) {
        shipments.push({
          id: hbl?.id ?? '',
          hbl: hbl?.referenceNumber ?? '',
          type: hbl?.releaseType ?? '',
          bookingNumber: hbl?.booking.referenceNumber,
          bookingId: hbl?.booking.id,
        });
      }
      setHblList(shipments);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mbl]);

  return (
    <Box height="100%" display="flex">
      <Panel title="related hbl" growPanel>
        <Box margin={2}>
          <UWLTable
            rowId="hbl"
            columns={columns}
            sizeMedium
            isLoading={false}
            rows={hblList}
            emptyMessage="No Items Available"
            renderCell={{
              hbl(row) {
                return <Link to={'/hbl/' + row.id}>{row.hbl}</Link>;
              },
              bookingNumber(row) {
                return <Link to={'/bookings/' + row.bookingId}>{row.bookingNumber}</Link>;
              },
            }}
          />
        </Box>
      </Panel>
    </Box>
  );
}
