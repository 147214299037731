import { gql } from '@apollo/client/core';
import { PortFragment } from './portQueries';

export const ConsolidationListQuery = gql`
  query ConsolidationList {
    shipments(
      shipment_filter: {
        type: CONSOLIDATION
        statuses: [BOOKED, PENDING, CONFIRMED, CONSOLIDATED, ASSIGNED]
      }
    ) {
      id
      referenceNumber
      status
      createDate
      logistics {
        id
        confirmationNumber
        confirmationDate
        moveType
        transportationMode
        carrier {
          id
          name
        }
        carrierConfirmationNumber
        pickupLocation {
          id
          name
        }
        deliveryLocation {
          id
          name
        }
        cyCutoffDate
        pod {
          ...Port
        }
        pol {
          ...Port
        }
        polEtd
        polAtd
      }
      containers {
        id
        sequential
        containerNumber
        containerType
        volume
        volumeUnit
        weight
        weightUnit
        isHot
      }
      ... on Consolidation {
        bookings {
          packLines {
            linePrice
          }
          containers {
            id
            sequential
            containerNumber
            containerType
            volume
            volumeUnit
            weight
            weightUnit
            isHot
            vanPositions {
              commercialInvoiceTotal
            }
          }
        }
      }
      relatedParties {
        id
        partyType
        party {
          id
          name
          locations {
            id
            name
          }
          relatedProfiles {
            id
            partyType
            profile {
              id
            }
          }
        }
      }
    }
  }
`;

export const ShippedConsolidationListQuery = gql`
  query ShippedConsolidationList {
    shipments(shipment_filter: { type: CONSOLIDATION, statuses: [SHIPPED] }) {
      id
      referenceNumber
      status
      createDate
      ... on Booking {
        cargoReadyDate
        revisedCargoReadyDate
      }
      logistics {
        id
        confirmationNumber
        confirmationDate
        moveType
        transportationMode
        carrier {
          id
          name
        }
        carrierConfirmationNumber
        pickupLocation {
          id
          name
        }
        deliveryLocation {
          id
          name
        }
        cyCutoffDate
        pod {
          ...Port
        }
        pol {
          ...Port
        }
        polEtd
        polAtd
      }
      containers {
        id
        sequential
        containerNumber
        containerType
        volume
        volumeUnit
        weight
        weightUnit
        isHot
      }

      ... on Consolidation {
        bookings {
          packLines {
            linePrice
          }
          containers {
            id
            sequential
            containerNumber
            containerType
            volume
            volumeUnit
            weight
            weightUnit
            isHot
            vanPositions {
              commercialInvoiceTotal
            }
          }
        }
      }
      relatedParties {
        id
        partyType
        party {
          id
          name
          locations {
            id
            name
          }
          relatedProfiles {
            id
            partyType
            profile {
              id
            }
          }
        }
      }
    }
  }
`;

export const AvailableConsolidationBookingListQuery = gql`
  query AvailableConsolidationBookingList {
    availableConsolidationBookings {
      id
      referenceNumber
      status
      createDate
      cargoReadyDate
      logistics {
        id
        moveType
        finalDestination {
          name
        }
        pod {
          ...Port
        }
        pol {
          ...Port
        }
        polEtd
        polAtd
      }
      packLines {
        weight
        volume
      }
      containers {
        id
        containerType
        volume
        weight
      }
      relatedParties {
        id
        partyType
        party {
          id
          name
          locations {
            id
            name
          }
          relatedProfiles {
            id
            partyType
            profile {
              id
            }
          }
        }
      }
    }
  }
  ${PortFragment}
`;

export const ConsolidationQuery = gql`
  query Consolidation($consolId: ID!) {
    consolidation(consolId: $consolId) {
      id
      referenceNumber
      status
      createDate
      bookingParty {
        id
        firstName
        lastName
      }
      mbl {
        id
        referenceNumber
        releaseType
        paymentType
      }
      bookings {
        id
        referenceNumber
        status
        createDate
        cargoReadyDate
        packLines {
          id
          requestedDeliveryDate
          shippedQty
          shippedCartons
          volume
          weight
          orderLine {
            id
            lineNumber
            unitPrice
            itemNumber
            itemDescription
            purchaseOrder {
              id
              poNumber
              htsCode
            }
            indcDate
            lastShipDate
            shipToLocation {
              id
              name
            }
          }
          booking {
            referenceNumber
          }
        }
        logistics {
          id
          moveType
          finalDestination {
            name
          }
          pol {
            code
          }
          polEtd
          pod {
            code
          }
        }
        remarks {
          id
          text
          createDate
          createdBy {
            id
            firstName
            lastName
            profile {
              name
            }
          }
        }
        containers {
          id
          containerType
          weight
          volume
        }
        documents {
          id
          documentType
          importFileName
          fileName
          fileType
          fileSize
          uploadDate
          description
        }
        relatedParties {
          id
          partyType
          party {
            id
            name
          }
        }
        hbl {
          id
          referenceNumber
          releaseType
          paymentType
          documents {
            id
            documentType
            importFileName
            fileName
            fileType
            fileSize
            uploadDate
            description
          }
        }
        mbl {
          id
          referenceNumber
          documents {
            id
            documentType
            importFileName
            fileName
            fileType
            fileSize
            uploadDate
            description
          }
        }
      }
      remarks {
        id
        text
        createDate
        createdBy {
          id
          firstName
          lastName
          profile {
            name
          }
        }
      }
      relatedParties {
        id
        partyType
        party {
          id
          name
          locations {
            id
            name
          }
        }
      }
      logistics {
        id
        confirmationNumber
        confirmationDate
        carrierConfirmationNumber
        moveType
        transportationMode
        pickupLocation {
          name
        }
        deliveryLocation {
          name
        }
        carrier {
          name
        }
        contractType
        contractNumber
        cyCutoffDate
        cfsCutoffDate
        cfsOpenDate
        vgmCutoffDate
        siCutoffDate
        motherVessel {
          name
          voyages {
            id
            name
          }
        }
        motherVoyage {
          name
        }
        feederVessel {
          id
          name
          voyages {
            id
            name
          }
        }
        feederVoyage {
          id
          name
        }
        transitPort {
          code
          name
          stateCode
          countryCode
          countryName
          latitude
          longitude
          stateName
        }
        transitPortEta
        transitPortAta
        transitPortEtd
        transitPortAtd
        pol {
          code
          name
          stateCode
          countryCode
          countryName
          latitude
          longitude
          stateName
        }
        polEtd
        polAtd
        pod {
          code
          name
          stateCode
          countryCode
          countryName
          latitude
          longitude
          stateName
        }
        podEta
        ramp {
          code
          name
          stateCode
          countryCode
          countryName
          latitude
          longitude
          stateName
        }
        rampEta
        finalDestination {
          id
          name
        }
        deliveryEta
      }
      containers {
        id
        containerNumber
        containerType
        sealNumber
        volume
        weight
        shipFromFactoryDate
        terminalReceivedDate
        vanPositions {
          id
          packLine {
            id
            orderLine {
              lineNumber
              itemNumber
              itemDescription
              purchaseOrder {
                poNumber
              }
            }
          }
          volume
          weight
          piecesQty
          cartonsQty
          vanCode
        }
        isHot
      }
      documents {
        id
        documentType
        importFileName
        fileName
        fileType
        fileSize
        uploadDate
        description
      }
    }
  }
`;

export const NewConsolidationContainerMutation = gql`
  mutation NewConsolidationContainers($input: NewConsolidationContainersInput!) {
    newConsolidationContainers(input: $input) {
      success
    }
  }
`;

export const AssignConsolidationContainerMutation = gql`
  mutation AssignConsolidationContainer($input: AssignConsolidationContainerInput!) {
    assignConsolidationContainer(input: $input) {
      success
    }
  }
`;

export const DeleteContainerMutation = gql`
  mutation DeleteContainer($input: DeleteContainerInput!) {
    deleteContainer(input: $input) {
      success
    }
  }
`;

export const UpdateConsolidationMutation = gql`
  mutation UpdateConsolidation($input: UpdateConsolidationInput!) {
    updateConsolidation(input: $input) {
      success
    }
  }
`;

export const UpdateConsolidationLogisticsMutation = gql`
  mutation UpdateConsolidationLogistics($input: UpdateConsolidationLogisticsInput!) {
    updateConsolidationLogistics(input: $input) {
      success
      message
    }
  }
`;

export const SaveConsolidationMutation = gql`
  mutation SaveConsolidation($input: NewConsolidationInput!) {
    newConsolidation(input: $input) {
      success
      message
      consolidation {
        id
        referenceNumber
      }
    }
  }
`;

export const AssignConsolidationRelatedPartyMutation = gql`
  mutation AssignConsolidationRelatedParty($input: AssignConsolidationRelatedPartyInput!) {
    assignConsolidationRelatedParty(input: $input) {
      success
      message
    }
  }
`;

export const RemoveConsolidationRelatedPartyMutation = gql`
  mutation RemoveConsolidationRelatedParty($input: RemoveConsolidationRelatedPartyInput!) {
    removeConsolidationRelatedParty(input: $input) {
      success
      message
    }
  }
`;

export const UpdateConsolidationRelatedPartyMutation = gql`
  mutation UpdateConsolidationRelatedParty($input: UpdateConsolidationRelatedPartyInput!) {
    updateConsolidationRelatedParty(input: $input) {
      success
      message
    }
  }
`;

export const NewConsolidationRemarkMutation = gql`
  mutation NewConsolidationRemark($input: NewConsolidationRemarkInput!) {
    newConsolidationRemark(input: $input) {
      success
      message
      remark {
        id
        text
        createDate
        createdBy {
          firstName
          lastName
          title
          profile {
            name
          }
        }
      }
    }
  }
`;
