import {
  ContainerMode,
  ContractType,
  ExceptionType,
  IncoTerm,
  LocationType,
  MoveType,
  OrderStatus,
  PartyType,
  PaymentType,
  ReleaseType,
  SearchType,
  ShipmentStatus,
  TransportMode,
  VanCode,
} from 'api/GQL_Types';

export function mapPaymentType(enumValue: PaymentType): string {
  switch (enumValue) {
    case PaymentType.Collect:
      return 'Collect';
    case PaymentType.Prepaid:
      return 'Prepaid';
  }
}

export function mapReleaseType(enumValue: ReleaseType): string {
  switch (enumValue) {
    case ReleaseType.Obo:
      return 'Obo Release';
    case ReleaseType.Tlx:
      return 'Telex Release';
  }
}

export function mapLocationType(enumValue: LocationType): string {
  switch (enumValue) {
    case LocationType.Ap:
      return 'Accounts Payable';
    case LocationType.Ar:
      return 'Accounts Receivable';
    case LocationType.Bol:
      return 'AWB/BOL';
    case LocationType.ConsignmentDelivery:
      return 'Consignment Delivery Address';
    case LocationType.ConsignmentPickup:
      return 'Consignment Pickup';
    case LocationType.Customs:
      return 'Customs Address of Record';
    case LocationType.DeliveryAddress:
      return 'Delivery address';
    case LocationType.Mailing:
      return 'Mailing';
    case LocationType.PickupAddress:
      return 'Pick up address';
    case LocationType.Postal:
      return 'Postal Address';
    case LocationType.Primary:
      return 'Main/Primary';
    case LocationType.Secondary:
      return 'Secondary Control';
  }
}

export function mapShipmentStatus(enumValue: ShipmentStatus): string {
  switch (enumValue) {
    case ShipmentStatus.Assigned:
      return 'Assigned';
    case ShipmentStatus.Booked:
      return 'Booked';
    case ShipmentStatus.Confirmed:
      return 'Confirmed';
    case ShipmentStatus.Consolidated:
      return 'Consolidated';
    case ShipmentStatus.Pending:
      return 'Pending';
    case ShipmentStatus.Shipped:
      return 'Shipped';
  }
}

export function mapContractType(enumValue: ContractType): string {
  switch (enumValue) {
    case ContractType.Direct:
      return 'Direct';
    case ContractType.Nvo:
      return 'NVO';
  }
}

export function mapContainerMode(enumValue: ContainerMode): string {
  switch (enumValue) {
    case ContainerMode.Fcl:
      return 'FCL';
    case ContainerMode.Lcl:
      return 'LCL';
    case ContainerMode.Bcn:
      return 'BCN';
  }
}

export function mapTransportMode(enumValue: TransportMode): string {
  switch (enumValue) {
    case TransportMode.Sea:
      return 'Sea';
    case TransportMode.Air:
      return 'Air';
    case TransportMode.Rail:
      return 'Rail';
    case TransportMode.Road:
      return 'Road';
  }
}

export function mapMoveType(enumValue: MoveType): string {
  switch (enumValue) {
    case MoveType.CfsCfs:
      return 'CFS / CFS';
    case MoveType.CfsCy:
      return 'CFS / CY';
    case MoveType.CfsDoor:
      return 'CFS / Door';
    case MoveType.CyCfs:
      return 'CY / CFS';
    case MoveType.CyCy:
      return 'CY / CY';
    case MoveType.CyDoor:
      return 'CY / Door';
    case MoveType.DoorCfs:
      return 'Door / CFS';
    case MoveType.DoorCy:
      return 'Door / CY';
    case MoveType.DoorDoor:
      return 'Door / Door';
  }
}

export function mapIncoTerm(enumValue: IncoTerm): string {
  switch (enumValue) {
    case IncoTerm.Cfr:
      return 'CFR';
    case IncoTerm.Cif:
      return 'CIF';
    case IncoTerm.Exw:
      return 'EXW';
    case IncoTerm.Fca:
      return 'FCA';
    case IncoTerm.Fob:
      return 'FOB';
  }
}

export function mapPartyType(enumValue: PartyType): string {
  switch (enumValue) {
    case PartyType.Consignee:
      return 'Consignee';
    case PartyType.Shipper:
      return 'Shipper';
    case PartyType.Supplier:
      return 'Supplier';
    case PartyType.Notify:
      return 'Notify';
    case PartyType.Manufacturer:
      return 'Manufacturer';
    case PartyType.BuyingAgent:
      return 'Buying Agent';
    case PartyType.Buyer:
      return 'Buyer';
    case PartyType.Customer:
      return 'Customer';
    case PartyType.Related:
      return 'Related';
    case PartyType.OriginAgent:
      return 'Origin Agent';
    case PartyType.DestinationAgent:
      return 'Destination Agent';
    // default: - NOTE no default case or final return b/c we want typescript to complain if we forgot to handle a case
  }
}

export function mapVanCode(enumValue: VanCode): string {
  switch (enumValue) {
    case VanCode.A1:
      return 'A1';
    case VanCode.A2:
      return 'A2';
    case VanCode.B1:
      return 'B1';
    case VanCode.B2:
      return 'B2';
    case VanCode.C1:
      return 'C1';
    case VanCode.C2:
      return 'C2';
    case VanCode.D1:
      return 'D1';
    case VanCode.D2:
      return 'D2';
    case VanCode.E1:
      return 'E1';
    case VanCode.E2:
      return 'E2';
  }
}

export function mapSearchType(enumValue: SearchType): string {
  switch (enumValue) {
    case SearchType.Hbl:
      return 'House bill #';
    case SearchType.Mbl:
      return 'Master bill #';
    case SearchType.VesselVoyage:
      return 'Vessel / Voyage #';
    case SearchType.Vessel:
      return 'Vessel #';
    case SearchType.Voyage:
      return 'Voyage #';
    case SearchType.Container:
      return 'Container #';
    case SearchType.Booking:
      return 'Booking #';
    case SearchType.BookingByHblMbl:
      return 'Booking, HBL, or MBL #';
    case SearchType.Po:
      return 'PO #';
    case SearchType.Sku:
      return 'SKU/Item #';
  }
}

export function mapLineStatus(enumValue: OrderStatus): string {
  switch (enumValue) {
    case OrderStatus.Open:
      return 'Open';
    case OrderStatus.Closed:
      return 'Closed';
    case OrderStatus.Critical:
      return 'Critical';
    case OrderStatus.Expired:
      return 'Expired';
    case OrderStatus.Cancelled:
      return 'Cancelled';
  }
}

export function mapExceptionType(enumValue: ExceptionType): string {
  switch (enumValue) {
    case ExceptionType.HotShipment:
      return 'Hot Shipment';
    case ExceptionType.HotContainer:
      return 'Hot Container';
    case ExceptionType.HotPo:
      return 'Hot Po';
    case ExceptionType.PendingOutgate:
      return 'Pending Outgate';
    case ExceptionType.DelayedPoArrival:
      return 'Delayed PO Arrival';
    case ExceptionType.EmptyNotReturned:
      return 'Empty Not Returned';
    case ExceptionType.MissingDocument:
      return 'Missing Document';
    case ExceptionType.LateBooking:
      return 'Late Booking';
  }
}
