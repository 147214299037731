import { Box, Button, Container } from '@material-ui/core';
import {
  NotificationCode,
  DashboardFeatureCode,
  PermissionCode,
  useNewProfileTypeMutation,
} from 'api/GQL_Types';
import { ProfileTypeTemplatesQuery } from 'api/queries/profileTypeQueries';
import NotificationCodeSwitches from 'app/admin/components/NotificationCodeSwitches';
import { DashboardFeatureSwitches } from 'app/admin/components/DashboardFeatureSwitches';
import AtomicTextFieldV2 from 'components/atomic/AtomicTextFieldV2';
import { DialogForm, Line } from 'components/StyledComponents';
import UniversalDialog from 'components/UniversalDialog';
import { useSnackbar } from 'notistack';
import React from 'react';
import { selector, useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import { genKey, newAtom } from 'recoil-utils/utils';
import PermissionsComponent from '../../../components/PermissionsComponent';

export const AddProfileTypeDialog_Open = newAtom(false);
const profileTypeNameState = newAtom('');
const profileTypeDescriptionState = newAtom('');
const profileTypePermissionsState = newAtom<PermissionCode[]>([]);
const profileTypeAlertsState = newAtom<NotificationCode[]>([]);
const profileTypeDashFeaturesState = newAtom<DashboardFeatureCode[]>([]);

const formValidationState = selector<string | null>({
  key: genKey(),
  get: ({ get }) => {
    const name = get(profileTypeNameState);
    const permissions = get(profileTypePermissionsState);
    const notificationCodes = get(profileTypeAlertsState);
    const features = get(profileTypeDashFeaturesState);

    if (name.trim() === '') {
      return 'Please enter a name';
    }
    if (permissions.length === 0) {
      return 'Please select permissions.';
    }
    if (notificationCodes.length === 0) {
      return 'Please select notifications.';
    }
    if (features.length === 0) {
      return 'Please select features.';
    }
    return null;
  },
});

const ALL_PermissionCodes = Object.values(PermissionCode);
const ALL_NotificationCodes = Object.values(NotificationCode);
const ALL_DashboardFeatureCode = Object.values(DashboardFeatureCode);

export default function NewProfileTypeTemplateDialog() {
  const [openDialog, setOpenDialog] = useRecoilState(AddProfileTypeDialog_Open);
  const setName = useSetRecoilState(profileTypeNameState);
  const setDescription = useSetRecoilState(profileTypeDescriptionState);
  const [profileTypePermissions, setProfileTypePermissions] = useRecoilState(
    profileTypePermissionsState
  );
  const [profileTypeAlerts, setProfileTypeAlerts] = useRecoilState(profileTypeAlertsState);
  const [dashFeatureCodes, setDashFeatureCodes] = useRecoilState(profileTypeDashFeaturesState);

  React.useEffect(() => {
    setName('');
    setDescription('');
    setProfileTypePermissions([]);
    setProfileTypeAlerts([]);
    setDashFeatureCodes([]);
  }, [openDialog]);

  return (
    <UniversalDialog
      open={openDialog}
      title="New Profile Type Template"
      large
      setClose={() => {
        setOpenDialog(false);
      }}
    >
      <Line height={1} />
      <Container>
        <Box marginBottom={2}>
          <DialogForm>
            <AtomicTextFieldV2 state={profileTypeNameState} required label="Profile Type Name" />
          </DialogForm>
          <DialogForm>
            <AtomicTextFieldV2
              state={profileTypeDescriptionState}
              label="Description"
              multiline
              rows={5}
            />
          </DialogForm>
          <DialogForm>
            <PermissionsComponent
              permissionsToDisplay={ALL_PermissionCodes}
              permissionsSet={profileTypePermissions}
              onPermissionToggle={(perm, checked) => {
                let newPermissions = [...profileTypePermissions];
                if (checked) {
                  newPermissions.push(perm);
                } else {
                  newPermissions = profileTypePermissions.filter((p) => p !== perm);
                }
                setProfileTypePermissions(newPermissions);
              }}
            />
          </DialogForm>
          <DialogForm>
            <NotificationCodeSwitches
              options={ALL_NotificationCodes}
              values={profileTypeAlerts}
              onValues={setProfileTypeAlerts}
            />
          </DialogForm>
          <DialogForm>
            <DashboardFeatureSwitches
              options={ALL_DashboardFeatureCode}
              values={dashFeatureCodes}
              onValues={setDashFeatureCodes}
            />
          </DialogForm>
        </Box>
      </Container>
      <Line height={1} />
      <Footer />
    </UniversalDialog>
  );
}

function Footer() {
  return (
    <Box display="flex" alignItems="center" justifyContent="flex-end">
      <CancelButton />
      <SaveProfileTypeButton />
    </Box>
  );
}

function SaveProfileTypeButton() {
  const setOpenDialog = useSetRecoilState(AddProfileTypeDialog_Open);
  const profileTypeName = useRecoilValue(profileTypeNameState);
  const profileTypeDescription = useRecoilValue(profileTypeDescriptionState);
  const profileTypePermissions = useRecoilValue(profileTypePermissionsState);
  const profileTypeAlerts = useRecoilValue(profileTypeAlertsState);
  const dashFeatureCodes = useRecoilValue(profileTypeDashFeaturesState);
  const validation = useRecoilValue(formValidationState);
  const { enqueueSnackbar } = useSnackbar();
  const [createProfileType, { data }] = useNewProfileTypeMutation({
    refetchQueries: () => [
      {
        query: ProfileTypeTemplatesQuery,
        fetchPolicy: 'cache-and-network',
      },
    ],
  });

  React.useEffect(() => {
    if (data) {
      if (data.newProfileTypeTemplate.success) {
        setOpenDialog(false);
        enqueueSnackbar('Profile Type Template Created.', {
          variant: 'success',
          anchorOrigin: {
            vertical: 'top',
            horizontal: 'right',
          },
        });
      }
    }
  }, [data]);

  return (
    <Button
      variant="contained"
      color="primary"
      disabled={validation !== null}
      onClick={() => {
        createProfileType({
          variables: {
            input: {
              profileType: {
                id: null,
                name: profileTypeName,
                description: profileTypeDescription,
                permissionCodes: profileTypePermissions,
                notificationCodes: profileTypeAlerts,
                dashboardFeatureCodes: dashFeatureCodes,
              },
            },
          },
        });
        setOpenDialog(false);
      }}
    >
      Create Profile Type
    </Button>
  );
}

function CancelButton() {
  const setOpenDialog = useSetRecoilState(AddProfileTypeDialog_Open);

  return (
    <Button
      color="default"
      onClick={() => {
        setOpenDialog(false);
      }}
      style={{ marginRight: '12px' }}
    >
      Cancel
    </Button>
  );
}
