import { Tabs, Tab, Typography } from '@material-ui/core';
import { DashboardPageStates } from 'app/dashboard/states';
import TabPanel, { panelProps } from 'components/TabPanel';
import React from 'react';
import { useRecoilValue } from 'recoil';
import DashboardBookingsSummary from './dashboard-bookings-summary';
import DashboardBookingsDetails from './dashboard-bookings-details';

interface Props {
  containerWidth: number;
  containerHeight: number;
}

export default function BookingTabs(props: Props) {
  const weeks = useRecoilValue(DashboardPageStates.booking.bookingSummaries);
  const [value, setValue] = React.useState(0);

  const [divWidth, setDivWidth] = React.useState(0);
  const [divHeight, setDivHeight] = React.useState(0);

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        flexGrow: 1,
        marginLeft: '12px',
        marginRight: '12px',
      }}
    >
      <Tabs
        value={value}
        onChange={(e, newValue) => {
          setValue(newValue);
        }}
        variant="fullWidth"
      >
        <Tab
          label={<Typography variant="h3">Booking summary</Typography>}
          {...panelProps(0)}
          style={{ borderBottom: '2px solid #888' }}
        />
        <Tab
          label={<Typography variant="h3">Booking Details</Typography>}
          {...panelProps(1)}
          style={{ borderBottom: '2px solid #888' }}
        />
      </Tabs>
      <TabPanel
        value={value}
        index={0}
        onMeasure={(width: number, height: number) => {
          setDivWidth(width);
          setDivHeight(height);
        }}
      >
        <DashboardBookingsSummary
          weeks={weeks}
          containerHeight={divHeight}
          containerWidth={props.containerWidth}
        />
      </TabPanel>
      <TabPanel value={value} index={1} onMeasure={(width: number, height: number) => {}}>
        <DashboardBookingsDetails
          rowHeight={props.containerHeight / 10}
          containerHeight={divHeight}
          containerWidth={divWidth - 12}
        />
      </TabPanel>
    </div>
  );
}
