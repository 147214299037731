import { Box, Button } from '@material-ui/core';
import { GqlRelatedParty } from 'api/GQL_Types';
import React from 'react';
import { RecoilState, useSetRecoilState } from 'recoil';
import { RelatedPartyItem } from '.';

export interface PartyChanges {
  new: RelatedPartyItem[];
  delete: RelatedPartyItem[];
  update: RelatedPartyItem[];
}

interface FooterProps {
  openState: RecoilState<boolean>;
  relatedParties: GqlRelatedParty[];
  items: { [key: string]: RelatedPartyItem };
  onOverrideClicked: (changes: PartyChanges) => void;
}

export default function Footer(props: FooterProps) {
  const setOpenDialog = useSetRecoilState(props.openState);
  const [startingItems, setStartingItems] = React.useState({});
  const [currentItems, setCurrentItems] = React.useState<{ [key: string]: RelatedPartyItem }>({});
  const [changes, setChanges] = React.useState<PartyChanges>({ new: [], delete: [], update: [] });

  React.useEffect(() => {
    const obj: { [key: string]: RelatedPartyItem } = {};
    for (const rp of props.relatedParties) {
      obj[rp.id + ':' + rp.party.id + ':' + rp.partyType] = {
        relationId: rp.id,
        profile: { id: rp.party.id, name: rp.party.name },
        partyType: rp.partyType,
      };
    }
    setStartingItems(obj);
  }, [props.relatedParties]);

  React.useEffect(() => {
    const obj: { [key: string]: RelatedPartyItem } = {};
    for (const item of Object.values(props.items)) {
      obj[
        item.relationId +
          ':' +
          (item.profile ? item.profile.id : '') +
          ':' +
          (item.partyType ? item.partyType : '')
      ] = item;
    }
    setCurrentItems(obj);
  }, [props.items]);

  React.useEffect(() => {
    setChanges(checkRelatedPartyChanges(startingItems, currentItems));
  }, [startingItems, currentItems]);

  return (
    <Box display="flex" justifyContent="flex-end" alignItems="center">
      <Button
        variant="contained"
        color="default"
        size="large"
        style={{ height: '40px', marginRight: '12px' }}
        onClick={() => {
          setOpenDialog(false);
        }}
      >
        Cancel
      </Button>
      <Button
        variant="contained"
        color="primary"
        size="large"
        style={{ height: '40px' }}
        disabled={
          (changes.new.length === 0 &&
            changes.delete.length === 0 &&
            changes.update.length === 0) ||
          Boolean(
            Object.values(currentItems).find(
              (item) => item.profile === null || item.partyType === null
            )
          )
        }
        onClick={() => {
          props.onOverrideClicked(changes);
        }}
      >
        Override Parties
      </Button>
    </Box>
  );
}

function checkRelatedPartyChanges(
  startingItems: { [key: string]: RelatedPartyItem },
  currentItems: { [key: string]: RelatedPartyItem }
): PartyChanges {
  const changes: PartyChanges = { new: [], delete: [], update: [] };

  const startingValues: { [key: string]: string } = {};
  for (const rp of Object.values(startingItems)) {
    startingValues[rp.relationId] = (rp.profile?.id as string) + ':' + rp.partyType;
  }

  for (const [currentKey, currentValue] of Object.entries(currentItems)) {
    const split = currentKey.split(':');
    if (split[0]) {
      // Has an id so check for update
      if (startingValues[split[0]] !== split[1] + ':' + split[2]) {
        changes.update.push(currentValue);
      }
    } else {
      // Does not have id so check if its present already in the startingValues.
      if (
        !inStartingValues(startingValues, split[1], split[2]) &&
        split[1] !== '' &&
        split[2] !== ''
      ) {
        changes.new.push(currentValue);
      }
    }
  }

  for (const startingValue of Object.values(startingItems)) {
    if (
      !inCurrentItems(
        currentItems,
        startingValue.profile?.id + ':' + startingValue.partyType,
        startingValue.relationId
      )
    ) {
      changes.delete.push(startingValue);
    }
  }
  return changes;
}

function inStartingValues(
  startingValues: { [key: string]: string },
  profileId: string,
  partyType: string
): boolean {
  for (const value of Object.values(startingValues)) {
    if (value === profileId + ':' + partyType) {
      return true;
    }
  }
  return false;
}

function inCurrentItems(
  currentItems: { [key: string]: RelatedPartyItem },
  id: string,
  relationId: string
): boolean {
  for (const value of Object.values(currentItems)) {
    if (value.profile?.id + ':' + value.partyType === id || value.relationId === relationId) {
      return true;
    }
  }
  return false;
}
