import React from 'react';
import { Box, Typography } from '@material-ui/core';
import { VanCode } from 'api/GQL_Types';
import styled from 'styled-components';
import CloseIcon from '@material-ui/icons/Close';

const GridBox = styled.div`
  width: 38px;
  height: 38px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 4px;
`;

interface GridBoxClickableProps {
  selected: boolean;
}
const GridBoxClickable = styled.div<GridBoxClickableProps>`
  position: relative;
  width: 38px;
  height: 38px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 4px;
  cursor: pointer;
  background-color: ${(props) => (props.selected ? '#ffd758' : '#043c5f')};
`;

const CloseBox = styled.div`
  border-radius: 10px;
  border: '1px solid gray';
  background-color: #eee;
  width: 20px;
  height: 20px;
  position: absolute;
  right: -5px;
  top: -5px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

interface Props {
  selectedCode: VanCode | null;
  onPositionSelected: (vanPosition: VanCode | null) => void;
}
export default function VanPositionGridSelector(props: Props) {
  return (
    <Box padding={2}>
      <Box display="flex">
        <Typography variant="body1">Select Position</Typography>
      </Box>
      <Box display="flex">
        <GridBox />
        <GridBox>
          <b>A</b>
        </GridBox>
        <GridBox>
          <b>B</b>
        </GridBox>
        <GridBox>
          <b>C</b>
        </GridBox>
        <GridBox>
          <b>D</b>
        </GridBox>
        <GridBox>
          <b>E</b>
        </GridBox>
      </Box>
      <Box display="flex">
        <GridBox>
          <b>1</b>
        </GridBox>
        <GridBoxClickableWithRemove
          selected={props.selectedCode === VanCode.A1}
          onClick={() => {
            props.onPositionSelected(VanCode.A1);
          }}
          onRemove={() => {
            props.onPositionSelected(null);
          }}
        />
        <GridBoxClickableWithRemove
          selected={props.selectedCode === VanCode.B1}
          onClick={() => {
            props.onPositionSelected(VanCode.B1);
          }}
          onRemove={() => {
            props.onPositionSelected(null);
          }}
        />
        <GridBoxClickableWithRemove
          selected={props.selectedCode === VanCode.C1}
          onClick={() => {
            props.onPositionSelected(VanCode.C1);
          }}
          onRemove={() => {
            props.onPositionSelected(null);
          }}
        />
        <GridBoxClickableWithRemove
          selected={props.selectedCode === VanCode.D1}
          onClick={() => {
            props.onPositionSelected(VanCode.D1);
          }}
          onRemove={() => {
            props.onPositionSelected(null);
          }}
        />
        <GridBoxClickableWithRemove
          selected={props.selectedCode === VanCode.E1}
          onClick={() => {
            props.onPositionSelected(VanCode.E1);
          }}
          onRemove={() => {
            props.onPositionSelected(null);
          }}
        />
      </Box>
      <Box display="flex">
        <GridBox>
          <b>2</b>
        </GridBox>
        <GridBoxClickableWithRemove
          selected={props.selectedCode === VanCode.A2}
          onClick={() => {
            props.onPositionSelected(VanCode.A2);
          }}
          onRemove={() => {
            props.onPositionSelected(null);
          }}
        />
        <GridBoxClickableWithRemove
          selected={props.selectedCode === VanCode.B2}
          onClick={() => {
            props.onPositionSelected(VanCode.B2);
          }}
          onRemove={() => {
            props.onPositionSelected(null);
          }}
        />
        <GridBoxClickableWithRemove
          selected={props.selectedCode === VanCode.C2}
          onClick={() => {
            props.onPositionSelected(VanCode.C2);
          }}
          onRemove={() => {
            props.onPositionSelected(null);
          }}
        />
        <GridBoxClickableWithRemove
          selected={props.selectedCode === VanCode.D2}
          onClick={() => {
            props.onPositionSelected(VanCode.D2);
          }}
          onRemove={() => {
            props.onPositionSelected(null);
          }}
        />
        <GridBoxClickableWithRemove
          selected={props.selectedCode === VanCode.E2}
          onClick={() => {
            props.onPositionSelected(VanCode.E2);
          }}
          onRemove={() => {
            props.onPositionSelected(null);
          }}
        />
      </Box>
    </Box>
  );
}

function GridBoxClickableWithRemove(props: {
  selected: boolean;
  onClick: () => void;
  onRemove: () => void;
}) {
  return (
    <GridBoxClickable
      selected={props.selected}
      onClick={() => {
        props.onClick();
      }}
    >
      {props.selected && (
        <CloseBox>
          <CloseIcon
            onClick={(e) => {
              props.onRemove();
              e.stopPropagation();
            }}
            fontSize="small"
          />
        </CloseBox>
      )}
    </GridBoxClickable>
  );
}
