import { createStyles, makeStyles, Theme } from '@material-ui/core';
import { jssPreset } from '@material-ui/core/styles';
import createMuiTheme from '@material-ui/core/styles/createMuiTheme';
import jss, { Styles, StyleSheetFactoryOptions } from 'jss';

jss.use(...jssPreset().plugins);
/**
 * Define your CSS using JSS
 * This will return an object with the class names.
 *
 * This is significantly faster than `useStyle()`
 */
export function makeCss<Name extends string | number | symbol>(
  styles: Partial<Styles<Name>>,
  options?: StyleSheetFactoryOptions
): Record<Name, string> {
  const sheet = jss.createStyleSheet(styles, options);
  sheet.attach();
  return sheet.classes;
}

export const useTableStyles = makeStyles((theme: Theme) =>
  createStyles({
    table: {
      overflow: 'hidden',
      maxHeight: window.innerHeight - 190 + 'px',
      flex: 1,
      margin: theme.spacing(1, 0, 0, 1),
    },
  })
);

declare module '@material-ui/core/styles/createPalette' {
  interface Palette {
    orange: Palette['primary'];
    blueGreen: Palette['primary'];
    dodgerBlue: Palette['primary'];
    indigoBlue: Palette['primary'];
    grayscale: Palette['primary'];
  }
  interface PaletteOptions {
    orange: PaletteOptions['primary'];
    blueGreen: PaletteOptions['primary'];
    dodgerBlue: PaletteOptions['primary'];
    indigoBlue: PaletteOptions['primary'];
    grayscale: PaletteOptions['primary'];
  }
}

// Material Theme
export const theme = createMuiTheme({
  palette: {
    common: {
      white: '#ffffff',
    },

    primary: {
      main: '#043c5f',
      dark: '#183041',
      light: '#024b79',
    },
    secondary: {
      main: '#026BAD',
      light: '#0098FA',
      dark: '#028ADE',
    },
    orange: {
      main: '#FFA333',
    },
    blueGreen: {
      main: '#00C0C4',
    },
    dodgerBlue: {
      main: '#0098FA',
    },
    indigoBlue: {
      main: '#034168',
      light: '#024b79',
    },
    error: {
      main: '#E6001F',
      light: '#ffcb3e',
    },
    text: {
      primary: '#333333',
      secondary: '#ffffff',
      // disabled: string;
      // hint: string;
    },
    grayscale: {
      main: '#cccccc',
      light: '#F0F0F0',
      dark: '#888888',
    },
    background: {
      default: '#f8f8f8',
    },
    warning: {
      main: '#f5cb42',
    },
    success: {
      main: '#37c225',
    },
  },
  typography: {
    fontFamily: 'Lato, Helvetica, Arial, sans-serif',

    h1: {
      color: '#183041',
      fontSize: '1.625rem',
      fontWeight: 900,
    },
    h2: {
      color: '#183041',
      fontSize: '1.125rem',
      textTransform: 'uppercase',
      fontWeight: 900,
    },
    h3: {
      color: '#183041',
      fontSize: '1rem',
      textTransform: 'uppercase',
      fontWeight: 900,
    },
    h4: {
      color: '#000',
      fontSize: '1rem',
      fontWeight: 700,
    },
    h5: {
      color: '#ffd758',
      fontSize: '0.875rem',
      fontWeight: 700,
      fontStyle: 'italic',
    },
    body1: {
      color: '#000',
      fontSize: '1rem',
    },
    caption: {
      color: '#000',
      fontSize: '0.875rem',
      fontWeight: 600,
      fontStyle: 'italic',
    },
  },
  overrides: {
    MuiTableSortLabel: {
      root: {
        '&:hover': {
          color: 'inherit',
        },
        '&:focus': {
          color: 'inherit',
        },
        '&$active': {
          color: 'inherit',
          // && instead of & is a workaround for https://github.com/cssinjs/jss/issues/1045
          '&& $icon': {
            color: 'inherit',
          },
        },
      },
    },
    MuiCheckbox: {
      root: {
        color: 'inherit',
      },
    },
    MuiRadio: {
      root: {
        color: 'inherit',
      },
    },
    MuiDialog: {
      scrollPaper: {
        alignItems: 'flex-start',
      },
    },
    MuiDialogActions: {
      root: {
        justifyContent: 'flex-start',
        padding: '16px 24px',
      },
    },
  },
});

// Global CSS
export const useGlobalStyles = makeStyles({
  '@global': {
    'html, body, body > #root': {
      height: '100%',
      margin: 0,
      padding: 0,
      fontFamily: "'Lato', sans-serif",
      // overflow: 'auto',
    },
    hr: {
      border: ' 0.5px solid #afafaf',
    },
    'button > span': {
      fontSize: '0.875rem',
      fontWeight: 700,
    },
    '.rv-xy-plot__axis__title text': {
      fontSize: 16,
      fontWidth: 600,
    },
    '::-webkit-scrollbar': {
      width: '8px',
      height: '8px',
    },
    '::-webkit-scrollbar-track': {
      background: 'transparent',
    },
    /* Handle */
    '::-webkit-scrollbar-thumb': {
      background: ' #ccc',
    },
    '.MuiTableCell-root': {
      borderBottom: '1px solid #ccc',
      color: '#000',
    },
    '.MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: '#383838',
      },
    },
    '.MuiFormLabel-root': {
      color: '#383838',
      fontWeight: 800,
    },
    '.ReactVirtualized__Grid': {
      outline: 'none',
    },
  },
});
