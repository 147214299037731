import { PartyType } from 'api/GQL_Types';
import { DashboardPageStates, TableLink } from 'app/dashboard/states';
import { GTableColumn } from 'components/g-table/types';
import React from 'react';
import { useRecoilValue } from 'recoil';
import { portToStringMaybe } from 'types/Port';
import { AlertButton } from '../../components/AlertButton';
import AlertsPopover from '../AlertsPopover';
import { NotificationResult, BaseAlert } from '../states';

export interface NewDocumentNotificationDisplay extends BaseAlert {
  id: string;
  unread: boolean;
  bookingNumber: TableLink;
  documentType: string;
  uploadDate: Date | null | undefined;
  shipperName: string;
  hblNumber: TableLink;
  pol: string;
  polEtd: Date | null | undefined;
  polAtd: Date | null | undefined;
  pod: string;
  podEta: Date | null | undefined;
  deliveryLocation: string;
}

const columns: GTableColumn<NewDocumentNotificationDisplay>[] = [
  { id: 'bookingNumber', label: 'Booking #', type: 'link', weight: 1 },
  { id: 'documentType', label: 'Doc Type', type: 'string', weight: 1 },
  { id: 'uploadDate', label: 'Upload Date', type: 'date', weight: 1 },
  { id: 'shipperName', label: 'Shipper Name', type: 'string', weight: 1 },
  { id: 'hblNumber', label: 'HBL #', type: 'link', weight: 1 },
  { id: 'pol', label: 'POL', type: 'string', weight: 1 },
  { id: 'polEtd', label: 'POL ETD', type: 'date', weight: 1 },
  { id: 'polAtd', label: 'POL ATD', type: 'date', weight: 1 },
  { id: 'pod', label: 'POD', type: 'string', weight: 1 },
  { id: 'podEta', label: 'POD ETA', type: 'date', weight: 1 },
  { id: 'deliveryLocation', label: 'Place of Delivery', type: 'string', weight: 1 },
];

export default function NewDocumentsButton() {
  const [anchorEl, setAnchorEl] = React.useState<(EventTarget & HTMLElement) | null>(null);
  const [openPopover, setOpenPopover] = React.useState(false);

  const newDocumentAlerts = useRecoilValue(DashboardPageStates.notifications.newDocuments);

  return (
    <>
      <AlertButton
        label="New Documents"
        quantity={newDocumentAlerts.length}
        onClick={(e) => {
          setAnchorEl(e.currentTarget);
          setOpenPopover(true);
        }}
      />
      <AlertsPopover
        id="new-documents-popover"
        emptyMessage="No new documents"
        headingLabel="New Documents"
        exportLabel="NewDocuments"
        totalLabel="Total New Documents"
        anchorEl={anchorEl}
        openPopover={openPopover}
        onClose={() => {
          setAnchorEl(null);
          setOpenPopover(false);
        }}
        items={newDocumentAlerts}
        columns={columns}
      />
    </>
  );
}

export function NewDocumentNotificationDisplay_toRow(
  alert: NotificationResult
): NewDocumentNotificationDisplay | null {
  if (alert.__typename !== 'NewDocumentNotification') {
    return null;
  }
  return {
    id: alert.id,
    unread: true,
    bookingNumber: {
      to: '/bookings/' + alert.booking.id,
      value: alert.booking.referenceNumber ?? '',
    },
    documentType: alert.document.documentType,
    uploadDate: alert.document.uploadDate,
    shipperName:
      alert.booking.relatedParties.find((rp) => rp.partyType === PartyType.Shipper)?.party.name ??
      '',
    hblNumber: {
      to: '/hbl/' + alert.booking.hbl?.id,
      value: alert.booking.hbl?.referenceNumber ?? '',
    },
    pol: portToStringMaybe(alert.booking.logistics.pol),
    polEtd: alert.booking.logistics.polEtd,
    polAtd: alert.booking.logistics.polAtd,
    pod: portToStringMaybe(alert.booking.logistics.pod),
    podEta: alert.booking.logistics.podEta,
    deliveryLocation: alert.booking.logistics.deliveryLocation?.name ?? '',
  };
}
