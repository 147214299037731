import React from 'react';
import { Box, Typography } from '@material-ui/core';
import TableExportWindowGroup from 'components/TableExportWindowGroup';
import { GTableColumn } from 'components/g-table/types';
import UWLTableV2 from 'components/g-table/UWLTableV2';
import { GqlBooking } from 'api/GQL_Types';
import { transformBookingToDashboardBookingDisplay } from 'app/dashboard/states';

const containerColumns: GTableColumn<any>[] = [
  { id: 'bookingNumber', label: 'Booking #', type: 'link', weight: 150 },
  { id: 'bookingDate', label: 'Booking Date', type: 'date', weight: 100 },
  { id: 'status', label: 'Status', type: 'string', weight: 100 },
  { id: 'cargoReadyDate', label: 'CGO RDY Date', type: 'date', weight: 100 },
  { id: 'revisedCargoReadyDate', label: 'REV CGO RDY Date', type: 'date', weight: 100 },
  { id: 'equipment', label: 'Equipment', type: 'link', weight: 100 },
  { id: 'shipper', label: 'Shipper', type: 'string', weight: 200 },
  { id: 'origin', label: 'Origin', type: 'string', weight: 100 },
  { id: 'destination', label: 'Destination', type: 'string', weight: 200 },
];

interface Props {
  popoverLabel: string;
  data: GqlBooking[];
}

export default function BookingsPopoverContent(props: Props) {
  const displayData = transformBookingToDashboardBookingDisplay(props.data);
  return (
    <Box padding={3} width={window.innerWidth * 0.9} display="flex" flexDirection="column">
      <Box display="flex" justifyContent="space-between" marginBottom={2}>
        <Box marginRight={3} display="flex" alignItems="center">
          <Typography variant="h3">{props.popoverLabel}</Typography>
          <TableExportWindowGroup label="Bookings" rows={displayData} columns={containerColumns} />
        </Box>
        <Box marginLeft={3}>
          <Box display="flex" alignItems="center">
            <Box marginRight={1}>
              <Typography variant="h4">
                <i>Total Bookings:</i>
              </Typography>
            </Box>
            <Typography variant="h3">{props.data.length}</Typography>
          </Box>
        </Box>
      </Box>
      <UWLTableV2
        rows={displayData}
        columns={containerColumns}
        maxHeight={500}
        emptyMessage="No Bookings"
      />
    </Box>
  );
}
