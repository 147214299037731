import React from 'react';
import { Dialog, Typography, Box, IconButton } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { ApolloProvider } from '@apollo/client/react';
import { gqlClient } from 'api/ApolloClient';

interface Props {
  open: boolean;
  title?: string;
  setClose: any;
  large?: boolean;
  small?: boolean;
  subTitle?: string;
  removeHR?: boolean;
}
export default function UniversalDialog(props: React.PropsWithChildren<Props>) {
  return (
    <Dialog
      open={props.open}
      disableBackdropClick
      fullWidth={props.large || props.small ? true : false}
      maxWidth={props.small ? 'sm' : 'lg'}
      onClose={() => {
        props.setClose();
      }}
      PaperProps={{ style: { alignSelf: 'center' } }}
    >
      <Box margin={3}>
        {props.title && (
          <Box display="flex" flexGrow={1} justifyContent="space-between">
            <Typography variant="h2" style={{ paddingTop: '10px', paddingBottom: '10px' }}>
              {props.title}
            </Typography>
            <IconButton
              aria-label="delete"
              onClick={props.setClose}
              style={{ padding: '10px', color: '#456f89' }}
            >
              {' '}
              <CloseIcon />
            </IconButton>
          </Box>
        )}
        <ApolloProvider client={gqlClient}>
          {props.subTitle && <Typography variant="body1">{props.subTitle}</Typography>}
          {!props.removeHR && props.removeHR}
          {props.children}
        </ApolloProvider>
      </Box>
    </Dialog>
  );
}
