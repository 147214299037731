import { Box } from '@material-ui/core';
import Loading from 'app/Loading';
import { AppWideStates } from 'app/states';
import { FormInputNumber } from 'components/form/FormInputNumber';
import { UWLTable } from 'components/UWLTable/UWLTable';
import React from 'react';
import { useRecoilState, useRecoilValue } from 'recoil';
import { theme } from 'styles';
import { UWLTableColumn } from 'types/UWLTable';
import { formatNumber } from 'utils/Numbers';
import { NewBookingPageStates, OpenOrderLine } from '../states';
import { faArrowDown, IconDefinition } from '@fortawesome/free-solid-svg-icons';
import { faArrowUp } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const columns: UWLTableColumn<OpenOrderLine>[] = [
  { id: 'orderNumber', label: 'PO', type: 'string', whiteSpace: 'nowrap' },
  { id: 'itemNumber', label: 'Item/SKU', type: 'string', whiteSpace: 'nowrap' },
  { id: 'lineNumber', label: 'Line', type: 'string', whiteSpace: 'nowrap' },
  { id: 'balanceQty', label: 'Balance Qty', type: 'number', align: 'left' },
  { id: 'orderQty', label: 'Ordered Qty', type: 'number', align: 'left' },
  { id: 'shippedQty', label: 'Shipped Qty', type: 'number', align: 'left' },
  { id: 'shippedCartons', label: 'Shipped Ctns', type: 'number', align: 'left' },
  { id: 'volume', label: 'Volume', type: 'number', align: 'left' },
  { id: 'weight', label: 'Weight', type: 'number', align: 'left' },
  { id: 'expectedCargoReadyDate', label: 'Exp Cgo Rdy Date', type: 'date' },
  { id: 'revisedCargoReadyDate', label: 'Rev Cgo Rdy Date', type: 'date' },
  { id: 'firstShipDate', label: 'First Ship', type: 'date' },
  { id: 'lastShipDate', label: 'Last Ship', type: 'date' },
  { id: 'shipToLocationName', label: 'Ship to Loc', type: 'string', whiteSpace: 'nowrap' },
];

interface Props {}

export const LinesToBookTable: React.FC<Props> = (props) => {
  const [packLines, setPackLines] = useRecoilState(NewBookingPageStates.step1.selectedLines);
  const [selectedLinesChecked, setSelectedLinesChecked] = useRecoilState(
    NewBookingPageStates.step1.selectedLinesChecked
  );
  const networkRules = useRecoilValue(AppWideStates.network.rules);

  function updateLine(id: string, update: (line: OpenOrderLine) => OpenOrderLine) {
    let changed = false;
    const newLines = packLines.map((line) => {
      if (line.id === id) {
        changed = true;
        return update(line);
      } else {
        return line;
      }
    });
    if (changed) {
      setPackLines(newLines);
    }
  }

  if (!packLines) {
    return <Loading />;
  }

  let totalPieces: number = 0;
  let totalCartons: number = 0;
  let totalVolume: number = 0;
  let totalWeight: number = 0;
  for (const line of packLines) {
    totalPieces += line.shippedQty;
    totalCartons += line.shippedCartons;
    totalVolume += line.volume;
    totalWeight += line.weight;
  }

  return (
    <Box paddingX={1} paddingBottom={1} display="flex" maxHeight="100%">
      <div
        style={{
          // This inner div is needed to give the table a reference point for it's scroll container b/c it can't be done directly in a maxHeight div
          overflow: 'hidden',
        }}
      >
        <UWLTable
          rowId="id"
          columns={columns}
          rows={packLines}
          emptyMessage="- No Line Items -"
          renderFooterCell={{
            orderQty() {
              return <FooterCell>Summary Total</FooterCell>;
            },
            shippedQty() {
              return <FooterCell>{formatNumber(totalPieces)} pcs</FooterCell>;
            },
            shippedCartons() {
              return <FooterCell>{formatNumber(totalCartons)} ctns</FooterCell>;
            },
            volume() {
              return <FooterCell>{formatNumber(totalVolume)} m3</FooterCell>;
            },
            weight() {
              return <FooterCell>{formatNumber(totalWeight)} kg</FooterCell>;
            },
          }}
          checkboxes={{
            checked: selectedLinesChecked,
            setChecked: setSelectedLinesChecked,
          }}
          renderCell={{
            orderNumber(row) {
              return <div style={{ marginRight: theme.spacing(2) }}>{row.orderNumber}</div>;
            },
            shippedQty(row) {
              let error: string | undefined;
              let icon: IconDefinition | undefined;
              if (networkRules.bookingQtyToleranceEnabled) {
                let qtyDiff = row.shippedQty / row.orderQty;
                if (qtyDiff - 1 > networkRules.bookingQtyTolerancePercentOver) {
                  error = 'Over Tolerance';
                  icon = faArrowDown;
                } else if (1 - qtyDiff > networkRules.bookingQtyTolerancePercentUnder) {
                  error = 'Under Tolerance';
                  icon = faArrowUp;
                }
              }
              return (
                <NumberInputCell
                  value={row.shippedQty}
                  onValue={(v) => {
                    updateLine(row.id, (line) => {
                      return {
                        ...line,
                        shippedQty: v,
                        balanceQty: line.orderQty - v,
                      };
                    });
                  }}
                  unit="pcs"
                  maximumFractionDigits={0}
                  errorMsg={error}
                  startAdornment={
                    !!icon && (
                      <FontAwesomeIcon
                        icon={icon}
                        color={theme.palette.error.main}
                        style={{ marginRight: theme.spacing(1) }}
                      />
                    )
                  }
                />
              );
            },
            shippedCartons(row) {
              return (
                <NumberInputCell
                  value={row.shippedCartons}
                  onValue={(v) => {
                    updateLine(row.id, (line) => {
                      return {
                        ...line,
                        shippedCartons: v,
                      };
                    });
                  }}
                  unit="ctns"
                  maximumFractionDigits={0}
                />
              );
            },
            volume(row) {
              return (
                <NumberInputCell
                  value={row.volume}
                  onValue={(v) => {
                    updateLine(row.id, (line) => {
                      return {
                        ...line,
                        volume: v,
                      };
                    });
                  }}
                  unit="m3"
                  maximumFractionDigits={2}
                />
              );
            },
            weight(row) {
              return (
                <NumberInputCell
                  value={row.weight}
                  onValue={(v) => {
                    updateLine(row.id, (line) => {
                      return {
                        ...line,
                        weight: v,
                      };
                    });
                  }}
                  unit="kg"
                  maximumFractionDigits={2}
                />
              );
            },
          }}
        />
      </div>
    </Box>
  );
};

const FooterCell: React.FC = (props) => {
  return (
    <Box paddingLeft={1} fontSize="1rem" color="#333333" fontWeight="bold">
      {props.children}
    </Box>
  );
};

const NumberInputCell: React.FC<{
  value: number;
  onValue: (value: number) => void;
  unit: string;
  maximumFractionDigits: number;
  errorMsg?: string;
  startAdornment?: string | React.ReactNode;
}> = ({ value, onValue, unit, maximumFractionDigits, errorMsg, startAdornment }) => {
  const width = '130px';
  const [focused, setFocused] = React.useState(false);
  const [over, setOver] = React.useState(false);

  return (
    <div
      style={{
        width: width,
        margin: '1px',
      }}
      onClick={(e) => e.stopPropagation()}
      onMouseOver={(e) => {
        setOver(true);
      }}
      onMouseOut={(e) => {
        setOver(false);
      }}
    >
      <FormInputNumber
        value={value}
        onValue={(v) => {
          onValue(v || 0);
        }}
        startAdornment={startAdornment}
        endAdornment={unit}
        size="xs"
        selectAllOnFocus
        maximumFractionDigits={maximumFractionDigits}
        error={!!errorMsg}
        onFocusChange={(isFocused) => {
          setFocused(isFocused);
        }}
        // helperText={errorMsg}
      />
      {(focused || over) && (
        <div
          style={{
            position: 'absolute',
            zIndex: 3,
            backgroundColor: 'white',
            width: width,
            textAlign: 'center',
            color: theme.palette.error.main,
            marginTop: theme.spacing(1),
            boxShadow:
              '0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%)',
          }}
        >
          {errorMsg}
        </div>
      )}
    </div>
  );
};
