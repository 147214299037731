import { faCopy } from '@fortawesome/free-solid-svg-icons';
import { PartyType } from 'api/GQL_Types';
import { markNotificationsAsRead } from 'api/queries/dashboardQueries';
import { AlertIconButton } from 'app/dashboard/components/AlertIconButton';
import { MarkAllAsReadButton } from 'app/dashboard/components/MarkAllAsReadButton';
import TableExportWindowGroup, { uwlColToGTable } from 'components/TableExportWindowGroup';
import { STANDARD_ROW_OVERALL_HEIGHT, UWLTable } from 'components/UWLTable/UWLTable';
import { useWindowSize } from 'lib/useWindowSize';
import React from 'react';
import { useRecoilState } from 'recoil';
import { portToStringMaybe } from 'types/Port';
import { UWLTableColumn } from 'types/UWLTable';
import { formatNumber } from 'utils/Numbers';
import { ExceptionQueryResult } from '.';
import { MarkAsReadCell } from '../../components/MarkAsReadCell';
import { PopoverTable } from '../../components/PopoverTable';
import { DashboardPageStates, TableLink } from '../../states';

export interface MissingDocumentDisplay {
  key: string;
  notificationId: string | null;
  isUnread: boolean;
  bookingNumber: TableLink;
  billNumber: TableLink;
  vendorName: string;
  documentType: string;
  cargoReadyDate: Date | null | undefined;
  pol: string;
  polEtd: Date | null | undefined;
  polAtd: Date | null | undefined;
  pod: string;
  podEta: Date | null | undefined;
  podAta: Date | null | undefined;
}

export function MissingDocumentsException_toRow(
  exception: ExceptionQueryResult
): MissingDocumentDisplay | null {
  if (exception.__typename !== 'MissingDocumentException') {
    return null;
  }
  const booking = exception.booking;

  let supplierName = '';
  booking.relatedParties.forEach((rp) => {
    if (rp.partyType === PartyType.Supplier) {
      supplierName = rp.party.name;
    }
  });

  let billNumber: TableLink = { to: '', value: '' };
  if (booking.hbl && booking.hbl.id) {
    billNumber = {
      to: `/hbl/${booking.hbl.id}`,
      value: booking.hbl.referenceNumber ?? '',
    };
  } else if (booking.mbl && booking.mbl.id) {
    billNumber = {
      to: `/mbl/${booking.mbl.id}`,
      value: booking.mbl.referenceNumber ?? '',
    };
  }

  return {
    key: booking.id + exception.documentType + exception.notificationId,
    notificationId: exception.notificationId || null,
    isUnread: exception.notificationId ? !exception.markedAsRead : false,
    bookingNumber: {
      to: '/bookings/' + booking.id,
      value: booking.referenceNumber ?? '',
    },
    billNumber: billNumber,
    vendorName: supplierName,
    documentType: exception.documentType,
    cargoReadyDate: booking.cargoReadyDate,
    pol: portToStringMaybe(booking.logistics.pol),
    polEtd: booking.logistics.polEtd,
    polAtd: booking.logistics.polAtd,
    pod: portToStringMaybe(booking.logistics.pod),
    podEta: booking.logistics.podEta,
    podAta: booking.logistics.podAta,
  };
}

const columns: UWLTableColumn<MissingDocumentDisplay>[] = [
  { id: 'isUnread', label: '!', type: 'string' },
  { id: 'bookingNumber', label: 'Booking #', type: 'link' },
  { id: 'billNumber', label: 'BL #', type: 'link' },
  { id: 'vendorName', label: 'Vendor', type: 'string' },
  { id: 'documentType', label: 'Doc Type', type: 'string' },
  { id: 'cargoReadyDate', label: 'CGO RDY Date', type: 'date' },
  { id: 'pol', label: 'POL', type: 'string' },
  { id: 'polEtd', label: 'POL ETD', type: 'date' },
  { id: 'polAtd', label: 'POL ATD', type: 'date' },
  { id: 'pod', label: 'POD', type: 'string' },
  { id: 'podEta', label: 'POD ETA', type: 'date' },
  { id: 'podAta', label: 'POD ATA', type: 'date' },
];

const displayColumnsUnread = columns.map((c) => c.id);
const displayColumnsAllRead = displayColumnsUnread.filter((id) => id !== 'isUnread');

const exportColumns = columns.filter((c) => c.id !== 'isUnread').map(uwlColToGTable);

interface Props {}

export const MissingDocumentsExceptionCmpt: React.FC<Props> = (props) => {
  const [open, setOpen] = React.useState(false);
  const refAnchor = React.useRef<HTMLDivElement>(null);
  const windowSize = useWindowSize();

  const [exceptions, setExceptions] = useRecoilState(DashboardPageStates.exceptions);
  const rows = exceptions.missingDocuments;
  const unreadCount = rows.filter((row) => row.isUnread).length;

  return (
    <>
      <AlertIconButton
        innerRef={refAnchor}
        name="Missing Documents"
        unreadCount={unreadCount}
        resultCount={rows.length}
        faIcon={faCopy}
        onClick={() => setOpen(true)}
      />

      <PopoverTable
        anchorEl={refAnchor.current}
        open={open}
        onClose={() => setOpen(false)}
        title="Missing Documents"
        titleRight={
          <>
            <TableExportWindowGroup label="Missing Documents" rows={rows} columns={exportColumns} />
            {unreadCount > 0 && (
              <MarkAllAsReadButton
                onClick={() => {
                  const unreadAlertIds: string[] = [];
                  for (const row of rows) {
                    if (row.isUnread && row.notificationId) {
                      unreadAlertIds.push(row.notificationId);
                    }
                  }
                  markAsRead(unreadAlertIds);
                }}
              />
            )}
          </>
        }
        totalLabel="Total Missing Documents"
        totalValue={formatNumber(rows.length)}
        width={windowSize.width * 0.8}
        bodyHeight={rows.length * STANDARD_ROW_OVERALL_HEIGHT}
      >
        {open && (
          <UWLTable
            rowId={'key'}
            rows={rows}
            columns={columns}
            columnsDisplay={unreadCount > 0 ? displayColumnsUnread : displayColumnsAllRead}
            emptyMessage="No Missing Documents"
            virtualize={'single-line-cells'}
            renderCell={{
              isUnread(row) {
                return (
                  <MarkAsReadCell
                    isUnread={row.isUnread}
                    notificationId={row.notificationId}
                    onClick={(notificationId) => {
                      markAsRead([notificationId]);
                    }}
                  />
                );
              },
            }}
          />
        )}
      </PopoverTable>
    </>
  );

  function markAsRead(notificationIds: string[]) {
    markNotificationsAsRead(notificationIds);
    setExceptions((e) => {
      return {
        ...e,
        missingDocuments: e.missingDocuments.map((d) => {
          if (notificationIds.includes(d.notificationId || '')) {
            return { ...d, isUnread: false };
          }
          return d;
        }),
      };
    });
  }
};
