import {
  NotificationFrequency,
  GqlLocation,
  GqlNetworkContainerTypeInput,
  GqlNetworkQuery,
  GqlNetworkRulesInput,
  GqlRole,
  LocationType,
  PermissionCode,
} from 'api/GQL_Types';
import { sortBy } from 'lib/sort';
import { useSetRecoilState } from 'recoil';
import { newAtom } from 'recoil-utils/utils';

export interface NetworkAdminPageData {
  id: string;
  name: string;
  connectedProfiles: NetworkAdminConnectedProfiles[];
  profileTypes: NetworkAdminProfileType[];
  items: NetworkAdminItem[];
  rules: NetworkRules;
  containerTypes: NetworkContainerType[];
}

export type NetworkRules = GqlNetworkRulesInput;

export type NetworkContainerType = GqlNetworkContainerTypeInput;

export const NetworkAdminPageStates = {
  errorLoadingNetwork: newAtom<string | null>(null),
  network: newAtom<NetworkAdminPageData | null>(null),
  rulesForm: newAtom<NetworkRules | null>(null),
  containerTypeForm: newAtom<NetworkContainerType[]>([]),
};

export function useSetNetworkAdminPageState() {
  const setNetwork = useSetRecoilState(NetworkAdminPageStates.network);
  const setRulesForm = useSetRecoilState(NetworkAdminPageStates.rulesForm);
  const setContainerTypeForm = useSetRecoilState(NetworkAdminPageStates.containerTypeForm);

  return (data: GqlNetworkQuery | null) => {
    if (!data?.network) {
      setNetwork(null);
      setRulesForm(null);
      return;
    }

    const network = {
      id: data.network.id,
      name: data.network.name,
      connectedProfiles: sortBy(
        data.network.connectedProfiles.map((profile): NetworkAdminConnectedProfiles => {
          return {
            id: profile.id,
            name: profile.name,
            profileType: profile.profileType || null,
            isControllingClient: profile.isControllingClient,
            locations: normalizeProfileLocations(profile.locations),
            logo: profile.logo ? { url: profile.logo.url } : null,
            needsUpdate: profile.needsUpdate,
            contacts: normalizeProfileContacts(profile.contacts),
            relatedProfiles: profile.relatedProfiles,
            roles: normalizeProfileRole(profile.roles),
            network: profile.network,
          };
        }),
        (profile) => {
          return (profile.isControllingClient ? 'A' : 'Z') + profile.name.trim().toLowerCase();
        },
        'asc'
      ),
      profileTypes: data.network.profileTypes?.map((pt) => {
        return {
          id: pt.id,
          name: pt.name || '',
          description: pt.description || '',
          isTemplate: pt.isTemplate || false,
          permissionCodes: pt.permissionCodes || [],
          defaultRoles: pt.defaultRoles || [],
        };
      }),
      items: data.network.items?.map((item) => {
        return {
          id: item.id,
          itemNumber: item.itemNumber,
          description: item.description,
          htsCode: item.htsCode,
          supplier: {
            name: item.supplier?.name || '',
          },
        };
      }),
      rules: data.network.rules,
      containerTypes: data.network.containerTypes.map(({ __typename, ...ct }) => ct),
    };
    setNetwork(network);
    const { __typename, ...rulesForm } = network.rules;
    // omit __typename
    setRulesForm({
      ...rulesForm,
      documentTypes: rulesForm.documentTypes.map(({ __typename, ...dt }) => dt),
    });
    setContainerTypeForm(network.containerTypes);
  };
}

export interface NetworkAdminConnectedProfiles {
  id: string;
  name: string;
  profileType: NetworkAdminProfile | null;
  isControllingClient: boolean;
  logo: NetworkAdminProfileLogo | null;
  needsUpdate: boolean;
  locations: NetworkAdminProfileLocation[];
  contacts: NetworkAdminProfileContact[];
  relatedProfiles: NetworkAdminProfileRelatedProfile[];
  roles: NetworkAdminRole[];
  network: {
    id: string;
    name: string;
  };
}

export interface NetworkAdminProfileLocation {
  id: string;
  name: string;
  code: string;
  nameAliases: string[];
  codeAliases: string[];
  addressLine1: string;
  addressLine2: string;
  city: string;
  state: string;
  postalCode: string;
  country: string;
  companyPhone: string;
  companyUrl: string;
  locationTypes: LocationType[];
  relatedPort: {
    code: string;
    name: string;
  };
}

export interface NetworkAdminProfileContact {
  id: string;
  firstName: string;
  lastName: string;
  title: string;
  email: string;
  phoneMain: string;
  phoneAlternative: string;
  extension: string;
  phoneCell: string;
  isMainContact: boolean;
  isUserEnabled: boolean;
  notificationFrequency: NotificationFrequency;
}

export interface NetworkAdminProfileRelatedProfile {
  id: string;
}

export interface NetworkAdminRole {
  id: string;
  name: string;
  description: string;
  isDefaultRole: boolean;
  permissionCodes: PermissionCode[];
}

export interface NetworkAdminProfile {
  id: string;
  name: string;
}

export interface NetworkAdminProfileType {
  id: string;
  name: string;
  description: string;
  isTemplate: boolean;
  permissionCodes: PermissionCode[];
  defaultRoles: {
    id: string;
    name: string;
  }[];
}

export interface NetworkAdminItem {
  id: string;
  itemNumber: string;
  description: string;
  htsCode: string;
  supplier: {
    name: string;
  };
}

export interface NetworkAdminProfileLogo {
  url: string;
}

interface GqlPickLocation {
  id: string;
  name?: string | null;
  code?: string | null;
  nameAliases?: string[] | null;
  codeAliases?: string[] | null;
  addressLine1?: string | null;
  addressLine2?: string | null;
  city?: string | null;
  state?: string | null;
  postalCode?: string | null;
  country?: string | null;
  companyPhone?: string | null;
  companyUrl?: string | null;
  locationTypes: LocationType[];
  relatedPort?: GqlPickPort | null;
}

interface GqlPickPort {
  code: string;
  name: string;
}

interface GqlPickContact {
  id: string;
  firstName?: string | null;
  lastName?: string | null;
  title?: string | null;
  email?: string | null;
  phoneMain?: string | null;
  phoneAlternative?: string | null;
  extension?: string | null;
  phoneCell?: string | null;
  isMainContact?: boolean;
  isUserEnabled?: boolean;
  notificationFrequency?: NotificationFrequency | null;
  role?: Pick<GqlRole, 'id' | 'name'> | undefined | null;
  location?: Pick<GqlLocation, 'id' | 'name'> | undefined | null;
}

interface GqlPickRole {
  id: string;
  name?: string | null;
  description?: string | null;
  isDefaultRole?: boolean;
  permissionCodes?: PermissionCode[];
}

function normalizeProfileLocations(locations: GqlPickLocation[]): NetworkAdminProfileLocation[] {
  if (!locations) {
    return [];
  }

  return locations.map((loc) => {
    return {
      id: loc.id || '',
      name: loc.name || '',
      code: loc.code || '',
      nameAliases: loc.nameAliases || [],
      codeAliases: loc.codeAliases || [],
      addressLine1: loc.addressLine1 || '',
      addressLine2: loc.addressLine2 || '',
      city: loc.city || '',
      state: loc.state || '',
      postalCode: loc.postalCode || '',
      country: loc.country || '',
      companyPhone: loc.companyPhone || '',
      companyUrl: loc.companyUrl || '',
      locationTypes: loc.locationTypes,
      relatedPort: {
        name: loc.relatedPort?.name || '',
        code: loc.relatedPort?.code || '',
      },
    };
  });
}

function normalizeProfileContacts(contacts: GqlPickContact[]): NetworkAdminProfileContact[] {
  if (!contacts) {
    return [];
  }

  return contacts.map((contact) => {
    return {
      id: contact.id,
      firstName: contact.firstName || '',
      lastName: contact.lastName || '',
      title: contact.title || '',
      email: contact.email || '',
      phoneMain: contact.phoneMain || '',
      phoneAlternative: contact.phoneAlternative || '',
      phoneCell: contact.phoneCell || '',
      extension: contact.extension || '',
      isMainContact: contact.isMainContact || false,
      isUserEnabled: contact.isUserEnabled || false,
      notificationFrequency: contact.notificationFrequency || NotificationFrequency.Immediately,
      role: contact.role || null,
      location: contact.location || null,
    };
  });
}

function normalizeProfileRole(roles: GqlPickRole[]): NetworkAdminRole[] {
  if (!roles) {
    return [];
  }

  return roles.map((role) => {
    return {
      id: role.id,
      name: role.name || '',
      description: role.description || '',
      isDefaultRole: role.isDefaultRole || false,
      permissionCodes: role.permissionCodes || [],
    };
  });
}
