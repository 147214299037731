import { Box, Grid } from '@material-ui/core';
import { GqlRelatedParty, PartyType } from 'api/GQL_Types';
import LineItem from 'components/LineItem';
import Panel from 'components/Panel';
import { useRecoilValue } from 'recoil';
import { formatDate } from 'utils/Dates';
import { mapContractType, mapMoveType, mapShipmentStatus, mapTransportMode } from 'utils/Enums';
import { ConsolidationPageStates } from '../states';

interface Props {}

export default function ConsolidationDetailsPanel(props: Props) {
  const consolidation = useRecoilValue(ConsolidationPageStates.consolidation);

  return (
    <Box width="100%" height="100%" display="flex">
      <Panel title="Consolidation Details" growPanel>
        <Box padding={3}>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={6}>
              <LineItem
                label="Consolidation"
                value={consolidation?.referenceNumber}
              />
              <LineItem
                value={formatDate(consolidation?.createDate || null)}
                label="Consolidation Date"
              />
              <LineItem
                value={consolidation?.status}
                label="Consolidation Status"
                decorator={mapShipmentStatus}
              />
              <LineItem
                value={consolidation?.logistics.transportationMode}
                label="Transportation Mode"
                decorator={mapTransportMode}
              />
              <LineItem
                value={consolidation?.logistics.moveType}
                label="Move Type"
                decorator={mapMoveType}
              />
              <LineItem
                value={consolidation?.logistics?.pickupLocation?.name}
                label="Place of Receipt"
              />
              <LineItem
                value={consolidation?.logistics?.deliveryLocation?.name}
                label="Place of Delivery"
              />
              <LineItem
                value={consolidation?.logistics.contractType}
                label="Contract Type"
                decorator={mapContractType}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <LineItem
                value={consolidation?.bookingParty.firstName}
                label="Booking Party"
              />
              <LineItem
                value={consolidation?.relatedParties}
                label="Manufacturer"
                decorator={(parties: GqlRelatedParty[]) =>
                  parties.find((p) => p.partyType === PartyType.Manufacturer)?.party.name
                }
              />
              <LineItem
                value={consolidation?.relatedParties}
                label="Shipper"
                decorator={(parties: GqlRelatedParty[]) =>
                  parties.find((p) => p.partyType === PartyType.Shipper)?.party.name
                }
              />
              <LineItem
                value={consolidation?.relatedParties}
                label="Consignee"
                decorator={(parties: GqlRelatedParty[]) =>
                  parties.find((p) => p.partyType === PartyType.Consignee)?.party.name
                }
              />
              <LineItem
                value={consolidation?.logistics.cfsOpenDate}
                decorator={formatDate}
                label="CFS Open Date"
              />
              <LineItem
                value={consolidation?.logistics.cfsCutoffDate}
                decorator={formatDate}
                label="CFS Cutoff Date"
              />
              <LineItem value={consolidation?.logistics?.carrier?.name} label="Carrier" />
              <LineItem
                value={consolidation?.logistics.contractNumber}
                label="Contract"
              />
            </Grid>
          </Grid>
        </Box>
      </Panel>
    </Box>
  );
}
