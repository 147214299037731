import { Box } from '@material-ui/core';
import React from 'react';
import VanPositionItem from './VanPositionItem';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import { GqlBooking, GqlContainer, GqlPackLine } from 'api/GQL_Types';
import { BookingPageStates, ItemPosition, OpenLineItem } from 'app/bookings/details/states';
import AddItemButton from './AddItemButton';

export default function ContainerItemList() {
  return (
    <Box display="flex" marginY={1} flexDirection="column" flexGrow={1} overflow="auto">
      <AddItemButton />
      <VanPositionList />
    </Box>
  );
}

function VanPositionList() {
  const selectedContainer = useRecoilValue(BookingPageStates.containerAssignment.selectedContainer);
  const booking = useRecoilValue(BookingPageStates.booking);
  const [bookingPackLines, setBookingPackLines] = useRecoilState(
    BookingPageStates.containerAssignment.editContainer.allBookingPackLines
  );
  const setOpenBookingPackLines = useSetRecoilState(
    BookingPageStates.containerAssignment.editContainer.availableBookingPackLines
  );
  const [selectedItems, setSelectedItems] = useRecoilState(
    BookingPageStates.containerAssignment.editContainer.selectedBookingPackLines
  );

  React.useEffect(() => {
    if (selectedContainer) {
      const items: ItemPosition[] = [];
      for (const position of selectedContainer.vanPositions) {
        items.push({
          id: position.id,
          packLine: position.packLine ? position.packLine : ({} as GqlPackLine),
          piecesQty: position.piecesQty,
          cartonsQty: position.cartonsQty,
          volume: position.volume ?? 0,
          weight: position.weight ?? 0,
          vanPositionCode: position.vanCode ?? null,
        });
      }
      setSelectedItems(items);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedContainer]);

  React.useEffect(() => {
    if (booking?.packLines) {
      const lines = Array.from(booking.packLines);
      setBookingPackLines(lines);
      updateOpenBookingPackLines(booking, selectedContainer, lines);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [booking]);

  React.useEffect(() => {
    if (booking) {
      updateOpenBookingPackLines(booking, selectedContainer, bookingPackLines);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedItems]);

  function updateOpenBookingPackLines(
    booking: GqlBooking,
    selectedContainer: GqlContainer | null,
    items: GqlPackLine[]
  ) {
    const totalQty: any = {};
    const totalCtns: any = {};

    const openLineItems: OpenLineItem[] = items.map((pl) => {
      return {
        ...pl,
        lineNumber: +pl.orderLine.lineNumber,
        itemDescription: pl.orderLine.itemDescription,
        itemNumber: pl.orderLine.itemNumber,
        orderNumber: pl.orderLine.purchaseOrder.poNumber,
        quantityLeft: 0,
        ctnsLeft: 0,
      };
    });

    for (const line of openLineItems) {
      totalQty[line.id] = line.shippedQty;
      totalCtns[line.id] = line.shippedCartons;
    }

    for (const container of booking.containers) {
      if (selectedContainer && container.id === selectedContainer.id) {
        for (const pos of selectedItems) {
          if (pos.packLine.id) {
            totalQty[pos.packLine.id] -= pos.piecesQty ? pos.piecesQty : 0;
            totalCtns[pos.packLine.id] -= pos.cartonsQty ? pos.cartonsQty : 0;
          }
        }
      } else {
        for (const pos of container.vanPositions) {
          totalQty[pos.packLine.id] -= pos.piecesQty;
          totalCtns[pos.packLine.id] -= pos.cartonsQty;
        }
      }
    }

    for (let i = 0; i < openLineItems.length; i++) {
      if (totalCtns[openLineItems[i].id]) {
        openLineItems[i].ctnsLeft = totalCtns[openLineItems[i].id];
      }

      openLineItems[i].quantityLeft = totalQty[openLineItems[i].id];
    }
    setOpenBookingPackLines(openLineItems);
  }

  return (
    <Box flexGrow={1} flexDirection="column" overflow="auto">
      {selectedItems && selectedItems.length > 0 ? (
        selectedItems.map((item, index) => {
          return (
            <VanPositionItem
              key={index}
              index={index}
              item={item}
              onItemChange={(item) => {
                const newItems = [];
                for (const currentItem of selectedItems) {
                  if (currentItem.id === item.id) {
                    newItems.push({ ...item });
                  } else {
                    newItems.push(currentItem);
                  }
                }
                setSelectedItems(newItems);
              }}
              onClose={(itemIndex: number) => {
                const newItems = selectedItems.filter((value: any, index: any, array: any) => {
                  return itemIndex !== index;
                });
                setSelectedItems(newItems);
              }}
            />
          );
        })
      ) : (
        <Box display="flex" justifyContent="center" bgcolor="#EEE" padding={2} margin={1}>
          No Items Selected
        </Box>
      )}
    </Box>
  );
}
