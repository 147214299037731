import { Box, Typography } from '@material-ui/core';
import { formatNumber } from 'utils/Numbers';
import React from 'react';
import { RadialChart } from 'react-vis';
import styled from 'styled-components';

interface SquareProps {
  color: string;
  size: string;
}

const ColoredSquare = styled.div<SquareProps>`
  width: ${(props) => props.size};
  height: ${(props) => props.size};
  background-color: ${(props) => props.color};
  margin-right: 8px;
`;

interface DonutSummaryProps {
  title: string;
  new: number;
  updated: number;
  total: number;
}

export function DonutSummary(props: DonutSummaryProps) {
  const total = props.total;
  const newV = props.new;
  const updated = props.updated;
  const unchanged = total - newV - updated;

  return (
    <Box display="flex" flexDirection="row" alignItems="center">
      <Box position="relative">
        <RadialChart
          data={
            total !== 0
              ? [
                  { angle: newV, color: '#043c5f' },
                  { angle: updated, color: '#3a81b9' },
                  { angle: unchanged, color: '#1897a0' },
                ]
              : [{ angle: 1, color: '#aaa' }]
          }
          width={120}
          height={120}
          radius={59}
          innerRadius={50}
          colorType="literal"
        />
        <Box position="absolute" top="0" left="0" width="100%" height="100%">
          <Box
            display="flex"
            width="100%"
            height="100%"
            justifyContent="center"
            alignItems="center"
            flexDirection="column"
          >
            <Typography variant="h1">{total}</Typography>
            <Typography variant="h4">Total</Typography>
          </Box>
        </Box>
      </Box>
      <Box padding={3}>
        <Typography variant="h3">{props.title}</Typography>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <ColoredSquare size="10px" color="#043c5f" />
          <Typography variant="body1">{formatNumber(newV)} New</Typography>
        </div>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <ColoredSquare size="10px" color="#3a81b9" />
          <Typography variant="body1">{formatNumber(updated)} Updated</Typography>
        </div>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <ColoredSquare size="10px" color="#1897a0" />
          <Typography variant="body1">{formatNumber(unchanged)} Unchanged</Typography>
        </div>
      </Box>
    </Box>
  );
}
