import {
  Button,
  CircularProgress,
  IconButton,
  InputAdornment,
  Paper,
  Popper,
} from '@material-ui/core';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import CloseIcon from '@material-ui/icons/Close';
import clsx from 'clsx';
import ErrorMessage from 'components/ErrorMessage';
import * as React from 'react';
import { makeCss, theme } from '../../styles';
import { FormInputText } from './FormInputText';

const classes = makeCss({
  root: {
    '&:hover $clearButton': {
      display: 'block',
    },
  },
  clearButton: {
    padding: '2px',
    display: 'none',
  },
  popupIndicator: {
    padding: '2px',
  },
  popupIndicatorOpen: {
    transform: 'rotate(180deg)',
  },
  popper: {
    zIndex: theme.zIndex.modal,
  },
  paper: {
    ...theme.typography.body1,
    overflow: 'hidden',
    margin: '4px 0',
  },
  statusText: {
    ...theme.typography.body1,
    padding: theme.spacing(1.75, 2),
  },
  listbox: {
    listStyle: 'none',
    margin: 0,
    padding: '8px 0',
    maxHeight: '40vh',
    overflow: 'auto',
    display: 'relative', // for offsetParent
  },
  option: {
    minHeight: 16,
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    cursor: 'pointer',
    boxSizing: 'border-box',
    outline: '0',
    WebkitTapHighlightColor: 'transparent',
    padding: theme.spacing(0.75, 2),
    '&$selected': {
      backgroundColor: theme.palette.action.selected,
    },
  },
  selected: {},
  btnAdd: {
    textTransform: 'none',
    textAlign: 'left',
  },
});

interface Props<T> {
  options: T[];
  displayResolver: (value: T) => string;
  renderOption?: (value: T, query: string) => any;

  value: T | null;
  onValue(v: T | null): void;

  label?: string;
  disabled?: boolean;
  required?: boolean;
  waiting?: boolean;
  error?: string | null;
  noValueMessage?: string | null;

  onFilterQuery?: (query: string) => void;

  localFilter?: (query: string, v: T) => boolean;

  onAddNew?: (
    query: string,
    event: React.KeyboardEvent<HTMLDivElement> | React.MouseEvent<HTMLButtonElement>
  ) => void;

  inputRef?: React.RefObject<HTMLInputElement>;

  size?: 'xs';
}

export function FormInputAutocomplete<T>({
  options,
  displayResolver,
  renderOption,
  value,
  onValue,
  label,
  disabled,
  required,
  waiting,
  error,
  noValueMessage,
  onFilterQuery,
  localFilter,
  onAddNew,
  inputRef,
  size,
}: Props<T>) {
  const refRoot = React.useRef<HTMLDivElement>(null);
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const refInput = inputRef || React.useRef<HTMLInputElement>(null);
  const refList = React.useRef<HTMLUListElement>(null);
  const [showPopover, setShowPopover] = React.useState<boolean>(false);
  const [filterQuery, setFilterQuery] = React.useState<string>('');
  const [selectedIndexBase, setSelectedIndex] = React.useState<number>(0);

  const query = filterQuery.trim().toLowerCase();

  const optionsFiltered =
    query.length > 0 && localFilter ? options.filter((v) => localFilter(query, v)) : options;

  const selectedIndex = Math.max(0, Math.min(optionsFiltered.length, selectedIndexBase));

  React.useEffect(() => {
    const listboxNode = refList.current;
    if (!listboxNode || listboxNode.scrollHeight <= listboxNode.clientHeight) {
      return;
    }
    const element = listboxNode.querySelector<HTMLLIElement>(
      `[data-option-index="${selectedIndex}"]`
    );
    if (!element) {
      return;
    }
    const scrollBottom = listboxNode.scrollTop + listboxNode.clientHeight;
    const elementBottom = element.offsetTop + element.offsetHeight;
    if (elementBottom > scrollBottom) {
      listboxNode.scrollTop = elementBottom - listboxNode.clientHeight;
    } else if (element.offsetTop < listboxNode.scrollTop) {
      listboxNode.scrollTop = element.offsetTop;
    }
  }, [selectedIndex]);

  React.useEffect(() => {
    setFilterQuery(value ? displayResolver(value) : '');
  }, [value]);

  return (
    <>
      <div
        className={classes.root}
        ref={refRoot}
        onKeyDown={(e) => {
          switch (e.code) {
            case 'Escape': {
              if (showPopover) {
                e.preventDefault();
                e.stopPropagation();
                setShowPopover(false);
                setFilterQuery(value ? displayResolver(value) : '');
                if (refInput.current) {
                  refInput.current.blur();
                }
              }
              break;
            }
            case 'Delete': {
              e.preventDefault();
              setFilterQuery('');
              onValue(null);
              setSelectedIndex(0);
              if (refInput.current) {
                refInput.current.focus();
              }
              break;
            }
            case 'ArrowUp': {
              if (showPopover && optionsFiltered.length > 0) {
                e.preventDefault();
                setSelectedIndex((index) => {
                  index = (index - 1) % optionsFiltered.length;
                  return index < 0 ? optionsFiltered.length - 1 : index;
                });
              }
              break;
            }
            case 'ArrowDown': {
              if (showPopover && optionsFiltered.length > 0) {
                e.preventDefault();
                setSelectedIndex((index) => (index + 1) % optionsFiltered.length);
              }
              break;
            }
            case 'Tab': {
              const option = optionsFiltered[selectedIndex];
              if (showPopover && option) {
                // Don't prevent default so they go to the next field
                onValue(option);
                setShowPopover(false);
              } else if (
                showPopover &&
                onAddNew &&
                optionsFiltered.length === 0 &&
                filterQuery.trim().length > 0
              ) {
                // Don't prevent default so they go to the next field
                onAddNew(filterQuery.trim(), e);
                setShowPopover(false);
              }
              break;
            }
            case 'Enter': {
              const option = optionsFiltered[selectedIndex];
              if (showPopover && option) {
                e.preventDefault();
                onValue(option);
                setShowPopover(false);
              } else if (
                showPopover &&
                onAddNew &&
                optionsFiltered.length === 0 &&
                filterQuery.trim().length > 0
              ) {
                e.preventDefault();
                onAddNew(filterQuery.trim(), e);
                setShowPopover(false);
              }
              break;
            }
          }
        }}
      >
        <FormInputText
          value={filterQuery}
          onValue={(v) => {
            setFilterQuery(v);
            if (!showPopover && v.length > 0) {
              setShowPopover(true);
            }
            if (onFilterQuery) {
              onFilterQuery(v);
            }
          }}
          label={label}
          disabled={disabled}
          required={required}
          size={size}
          error={!!error}
          helperText={error}
          endAdornment={
            <InputAdornment position="end" style={{ marginRight: '-8px' }}>
              {waiting && <CircularProgress size="1em" />}
              {!waiting && filterQuery !== '' && (
                <IconButton
                  disabled={disabled || waiting}
                  title="Clear"
                  className={classes.clearButton}
                  onClick={() => {
                    setFilterQuery('');
                    onValue(null);
                    setSelectedIndex(0);
                    if (refInput.current) {
                      refInput.current.focus();
                    }
                  }}
                  tabIndex={-1}
                >
                  <CloseIcon fontSize="small" />
                </IconButton>
              )}
              <IconButton
                disabled={disabled || waiting}
                title="Open"
                className={clsx(classes.popupIndicator, {
                  [classes.popupIndicatorOpen]: showPopover,
                })}
                onClick={() => setShowPopover((p) => !p)}
                tabIndex={-1}
              >
                <ArrowDropDownIcon />
              </IconButton>
            </InputAdornment>
          }
          inputRef={refInput}
          onFocus={() => {
            setShowPopover(true);
          }}
          onBlur={() => {
            setShowPopover(false);
            setFilterQuery(value ? displayResolver(value) : '');
          }}
        />
      </div>

      {showPopover && refRoot.current && (
        <Popper
          className={classes.popper}
          style={{ width: refRoot.current.clientWidth }}
          role="presentation"
          anchorEl={refRoot.current}
          disablePortal
          open
        >
          <Paper
            className={classes.paper}
            onMouseDown={(e) => {
              e.preventDefault(); // Prevent blur
            }}
          >
            {optionsFiltered.length === 0 ? (
              waiting ? (
                <div className={classes.statusText}>
                  <i>Searching...</i>
                </div>
              ) : (
                <div>
                  {error && (
                    <div className={classes.statusText}>
                      <ErrorMessage error={error} />
                    </div>
                  )}
                  {onAddNew && filterQuery.trim().length > 0 ? (
                    <Button
                      color="primary"
                      className={classes.btnAdd + ' ' + classes.option + ' ' + classes.selected}
                      onClick={(e) => {
                        onAddNew(filterQuery.trim(), e);
                        setShowPopover(false);
                      }}
                      fullWidth
                    >
                      + Add "{filterQuery.trim()}"
                    </Button>
                  ) : (
                    <div className={classes.statusText}>
                      <i>{noValueMessage || '- none -'}</i>
                    </div>
                  )}
                </div>
              )
            ) : (
              <ul ref={refList} className={classes.listbox}>
                {optionsFiltered.map((option, index) => {
                  return (
                    <li
                      key={index}
                      tabIndex={-1}
                      className={clsx(classes.option, index === selectedIndex && classes.selected)}
                      data-option-index={index}
                      onMouseOver={(e) => {
                        setSelectedIndex(index);
                      }}
                      onClick={(e) => {
                        onValue(option);
                        setShowPopover(false);
                      }}
                    >
                      {renderOption ? renderOption(option, query) : displayResolver(option)}
                    </li>
                  );
                })}
              </ul>
            )}
          </Paper>
        </Popper>
      )}
    </>
  );
}
