import { Box, Typography } from '@material-ui/core';
import React from 'react';
import { RadialChart } from 'react-vis';
import { theme } from 'styles';
import { formatNumberDecimalPlaces } from 'utils/Numbers';

interface Props {
  currentLabel: string;
  maxLabel: string;
  minLabel?: string;
  current: number;
  max: number;
  min?: number;
  unitType: string;
}
export const UtilizationChart = (props: Props) => {
  const currentPercent = (props.current / (props.max === 0 ? 1 : props.max)) * 100;
  const minPercent = props.min ? (props.min / props.max) * 100 : 0;

  return (
    <div
      style={{
        display: 'flex',
        alignItems: 'center',
      }}
    >
      <Box position="relative">
        <RadialChart
          data={
            currentPercent > 100
              ? [{ angle: 1, color: theme.palette.error.main }]
              : currentPercent <= 0
              ? [
                  { angle: 100 - minPercent, color: '#aaa' },
                  { angle: minPercent - currentPercent, color: theme.palette.warning.main },
                ]
              : currentPercent < minPercent
              ? [
                  { angle: 100 - minPercent, color: '#aaa' },
                  { angle: minPercent - currentPercent, color: theme.palette.warning.main },
                  { angle: currentPercent, color: theme.palette.success.light },
                ]
              : currentPercent <= 100
              ? [
                  { angle: 100 - currentPercent, color: '#aaa' },
                  { angle: currentPercent, color: theme.palette.success.light },
                ]
              : []
          }
          width={140}
          height={140}
          radius={68}
          innerRadius={55}
          colorType="literal"
        />
        <Box position="absolute" top="0" left="0" width="100%" height="100%">
          <Box
            display="flex"
            width="100%"
            height="100%"
            justifyContent="center"
            alignItems="center"
            flexDirection="column"
          >
            <Typography variant="h1">
              {currentPercent < 100
                ? formatNumberDecimalPlaces(currentPercent, 0, 0) + '%'
                : '> 100%'}
            </Typography>
          </Box>
        </Box>
      </Box>
      <Box display="flex" flexDirection="column" marginLeft={2}>
        <Typography variant="h2">
          {formatNumberDecimalPlaces(props.current, 0, 2)} {props.unitType}
        </Typography>
        <Typography variant="caption" style={{ marginBottom: '8px' }}>
          {props.currentLabel}
        </Typography>
        {props.minLabel && (
          <>
            <Typography variant="h2">
              {formatNumberDecimalPlaces(props.min ?? 0, 0, 2)} {props.unitType}
            </Typography>
            <Typography variant="caption" style={{ marginBottom: '8px' }}>
              {props.minLabel}
            </Typography>
          </>
        )}
        <Typography variant="h2">
          {formatNumberDecimalPlaces(props.max, 0, 2)} {props.unitType}
        </Typography>
        <Typography variant="caption">{props.maxLabel}</Typography>
      </Box>
    </div>
  );
};
