import { ExpanderSection } from 'components/ExpanderSection';
import React from 'react';
import { makeCss } from 'styles';
import { formatNumber } from 'utils/Numbers';

const classes = makeCss({
  root: {},
});

function enabledSummary(enableds: boolean[]): string {
  const nEnabled = enableds.filter((e) => e).length;
  const total = enableds.length;
  return `${formatNumber(nEnabled)} / ${formatNumber(total)}`;
}

interface Props {
  title: string;
  bools: boolean[];
}

export const RuleCardGroup: React.FC<Props> = ({ title, bools, children }) => {
  return (
    <ExpanderSection title={title} summary={enabledSummary(bools)} defaultExpanded={false}>
      <div className={classes.root}>{children}</div>
    </ExpanderSection>
  );
};
