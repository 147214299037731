import MultiLineDetail from 'components/MultiLineDetail';
import IconButton from 'components/IconButton';
import { HblPageStates } from '../states';
import { useRecoilValue } from 'recoil';
import { ShipmentStatus } from 'api/GQL_Types';
import { Box, Grid } from '@material-ui/core';

export default function HBLDetailsHeader() {
  const hbl = useRecoilValue(HblPageStates.hbl);
  let shipper = hbl?.relatedParties.find((party: any) => party.partyType === 'MANUFACTURER');
  if (!shipper) {
    shipper = hbl?.relatedParties.find((party: any) => party.partyType === 'SHIPPER');
  }
  const shipperParty = shipper?.party;
  const consignee = hbl?.relatedParties.find((party: any) => party.partyType === 'CONSIGNEE');
  const consigneeParty = consignee?.party;
  const notify = hbl?.relatedParties.find((party: any) => party.partyType === 'NOTIFY');
  const notifyParty = notify?.party;

  function getBookingIcon(status: ShipmentStatus | undefined) {
    if (status === 'BOOKED') {
      return 'event_available';
    } else if (status === 'SHIPPED') {
      return 'local_shipping';
    } else if (status === 'ASSIGNED') {
      return 'assignment';
    } else return 'done';
  }

  return (
    <Box flexGrow={1} marginLeft={3}>
      <Grid container direction="row" justify="flex-end" alignItems="center" spacing={2}>
        <Grid item sm={6} md={3}>
          <Box display="flex" alignItems="center" margin="0 auto">
            <IconButton outlined color="#3A81B9">
              <Box component="span" fontSize="inherit" color="#3A81B9" className="material-icons">
                local_shipping
              </Box>
            </IconButton>
            <MultiLineDetail
              label="Shipper"
              line1={shipperParty ? shipperParty.name : <br />}
            />
          </Box>
        </Grid>
        <Grid item sm={6} md={3}>
          <Box display="flex" alignItems="center">
            <IconButton outlined color="#1CB3BE">
              <Box component="span" fontSize="inherit" color="#1CB3BE" className="material-icons">
                how_to_reg
              </Box>
            </IconButton>
            <MultiLineDetail
              label="Consignee"
              line1={consigneeParty ? consigneeParty.name : <br />}
            />
          </Box>
        </Grid>
        <Grid item sm={6} md={3}>
          <Box display="flex" alignItems="center">
            <IconButton outlined color="#F69941">
              <Box component="span" fontSize="inherit" color="#F69941" className="material-icons">
                contact_phone
              </Box>
            </IconButton>
            <MultiLineDetail label="Notify" line1={notifyParty ? notifyParty.name : <br />} />
          </Box>
        </Grid>
        <Grid item sm={6} md={3}>
          <Box display="flex" alignItems="center">
            <IconButton outlined color="#0DAA4F">
              <Box component="span" fontSize="inherit" color="#0DAA4F" className="material-icons">
                {getBookingIcon(hbl?.booking.status)}
              </Box>
            </IconButton>
            <MultiLineDetail label="Status" line1={hbl?.booking.status} />
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
}
