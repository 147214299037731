import { Box, Button } from '@material-ui/core';
import { calculateDateRange } from 'components/form/FormInputDateRange';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { NewConsolidationStates } from '../../states';

export default function ApplyFiltersButton() {
  const bookings = useRecoilValue(NewConsolidationStates.step1.bookings);
  const setFilteredBookings = useSetRecoilState(NewConsolidationStates.step1.filteredBookings);
  const filterDestination = useRecoilValue(NewConsolidationStates.filters.filterDestination);
  const filterCargoReadyDate = useRecoilValue(NewConsolidationStates.filters.filterCargoReadyDate);
  const filterPol = useRecoilValue(NewConsolidationStates.filters.filterPol);
  const filterEtd = useRecoilValue(NewConsolidationStates.filters.filterEtd);
  const filterEqptType = useRecoilValue(NewConsolidationStates.filters.filterEqptType);
  const filterDeliveryType = useRecoilValue(NewConsolidationStates.filters.filterDeliveryType);
  const filterSupplier = useRecoilValue(NewConsolidationStates.filters.filterSupplier);
  const setFilters = useSetRecoilState(NewConsolidationStates.filters.filtersLength);

  return (
    <Box display="flex" justifyContent="flex-end">
      <Button
        variant="contained"
        color="primary"
        onClick={() => {
          let bookingsCopy = [...bookings];
          let filtersCount = 0;
          if (filterDestination !== null) {
            filtersCount++;
            bookingsCopy = bookingsCopy.filter((booking) =>
              booking.finalDestinationName.includes(filterDestination.name)
            );
          }
          if (filterCargoReadyDate) {
            filtersCount++;
            const { from, to } = calculateDateRange(filterCargoReadyDate);
            bookingsCopy = bookingsCopy.filter((booking) => {
              if (!booking.cargoReadyDate) {
                return false;
              }
              return booking.cargoReadyDate >= from && booking.cargoReadyDate <= to;
            });
          }
          if (filterPol) {
            filtersCount++;
            bookingsCopy = bookingsCopy.filter((booking) => booking.pol.includes(filterPol));
          }
          if (filterEtd) {
            filtersCount++;
            const { from, to } = calculateDateRange(filterEtd);
            bookingsCopy = bookingsCopy.filter((booking) => {
              if (!booking.polEtd) {
                return false;
              }
              return booking.polEtd >= from && booking.polEtd <= to;
            });
          }
          if (filterEqptType !== null) {
            filtersCount++;
            bookingsCopy = bookingsCopy.filter((booking) =>
              booking.containers.includes(filterEqptType)
            );
          }
          if (filterDeliveryType !== null) {
            filtersCount++;
            bookingsCopy = bookingsCopy.filter((booking) =>
              booking.moveType.includes(filterDeliveryType)
            );
          }
          if (filterSupplier !== null) {
            filtersCount++;
            bookingsCopy = bookingsCopy.filter((booking) =>
              booking.supplierName.includes(filterSupplier.party.name)
            );
          }
          setFilteredBookings([...bookingsCopy]);
          setFilters(filtersCount);
        }}
      >
        Apply Filters
      </Button>
    </Box>
  );
}
