import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Box, List, Typography } from '@material-ui/core';
import { DashboardFeatureCode, useExceptionsQuery } from 'api/GQL_Types';
import { userContextAtom } from 'app';
import CompositePanel from 'components/CompositePanel';
import ErrorMessage from 'components/ErrorMessage';
import React from 'react';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { theme } from 'styles';
import { DashboardPageStates } from '../states';
import { initDashExceptions } from './exceptions';
import { EmptyNotReturnedExceptionCmpt } from './exceptions/EmptyNotReturnedException';
import { HotContainerExceptionCmpt } from './exceptions/HotContainerException';
import { HotPOExceptionCmpt } from './exceptions/HotPOException';
import { HotShipmentExceptionCmpt } from './exceptions/HotShipmentException';
import { LateBookingExceptionCmpt } from './exceptions/LateBookingException';
import { MissingDocumentsExceptionCmpt } from './exceptions/MissingDocumentsException';
import { PendingOutgateExceptionCmpt } from './exceptions/PendingOutgateException';

export default function DashboardExceptions() {
  const setExceptions = useSetRecoilState(DashboardPageStates.exceptions);

  const { error } = useExceptionsQuery({
    fetchPolicy: 'no-cache',
    onCompleted(data) {
      setExceptions(initDashExceptions(data));
    },
  });

  return (
    <CompositePanel>
      <Box margin={2} display="flex" alignItems="center" justifyContent="space-between">
        <Typography
          variant="h2"
          color="primary"
          style={{ minWidth: 'fit-content', letterSpacing: '0.5px' }}
        >
          <Box component="span" marginRight={2}>
            <FontAwesomeIcon icon={faExclamationTriangle} color={theme.palette.indigoBlue.main} />
          </Box>
          Alerts
          <ErrorMessage error={error ? error + '' : null} />
        </Typography>
      </Box>

      <Box display="flex" paddingLeft={1} paddingRight={1} paddingBottom={2}>
        <ExceptionItemList />
      </Box>
    </CompositePanel>
  );
}

const ExceptionItemList: React.FC = () => {
  const userContext = useRecoilValue(userContextAtom);

  return (
    <List style={{ paddingTop: 0 }}>
      {userContext?.dashboardFeatureCodes.has(DashboardFeatureCode.ExceptionHotPos) && (
        <HotPOExceptionCmpt />
      )}
      {userContext?.dashboardFeatureCodes.has(DashboardFeatureCode.ExceptionHotBookings) && (
        <HotShipmentExceptionCmpt />
      )}
      {userContext?.dashboardFeatureCodes.has(DashboardFeatureCode.ExceptionHotContainers) && (
        <HotContainerExceptionCmpt />
      )}
      {userContext?.dashboardFeatureCodes.has(DashboardFeatureCode.ExceptionMissingDocuments) && (
        <MissingDocumentsExceptionCmpt />
      )}
      {userContext?.dashboardFeatureCodes.has(DashboardFeatureCode.ExceptionPendingOutgate) && (
        <PendingOutgateExceptionCmpt />
      )}
      {userContext?.dashboardFeatureCodes.has(DashboardFeatureCode.ExceptionEmptyNotReturned) && (
        <EmptyNotReturnedExceptionCmpt />
      )}
      {userContext?.dashboardFeatureCodes.has(DashboardFeatureCode.ExceptionLateBooking) && (
        <LateBookingExceptionCmpt />
      )}
    </List>
  );
};
