import { IconDefinition } from '@fortawesome/fontawesome-common-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Avatar, Box, Button, Fab, Grid, Typography } from '@material-ui/core';
import { NotificationCode, DashboardFeatureCode, PermissionCode } from 'api/GQL_Types';
import LineItem from 'components/LineItem';
import { ListCard } from 'components/ListCard';
import { Line } from 'components/StyledComponents';
import { useSetRecoilState } from 'recoil';
import { theme } from 'styles';
import { getAccentColorFromIndex, getAccentColorFromUUID } from 'utils/Colors';
import RoleDialogComponent, { RoleDialog_Data } from '../components/RoleDialogComponent';

export interface RoleObj {
  id: string;
  name: string;
  description: string;
  permissionLength: number;
  permissions: PermissionCode[];
  notificationCodes: NotificationCode[];
  dashboardFeatureCodes: DashboardFeatureCode[];
  documentTypesCanView: string[];
}

interface IconObj<T> {
  name?: string | undefined;
  key: Extract<keyof T, string>;
  icon?: IconDefinition | undefined;
  color?: string | undefined;
}

interface Props {
  filteredList: RoleObj[];
  iconList: IconObj<RoleObj>[];
  type: 'Profile' | 'Profile Type';
  id: string;
  canCreate: boolean;
  canEdit: boolean;
}

export default function RolesCardList(props: Props) {
  return (
    <Box paddingBottom={4}>
      <Grid container spacing={2}>
        {props.filteredList.map((row, index) => {
          return (
            <Grid item xs={12} sm={3} key={row.id}>
              <ListCard
                accentColor={
                  row.id ? getAccentColorFromUUID(row.id) : getAccentColorFromIndex(index)
                }
              >
                <Box display="flex" flexDirection="row">
                  <Box display="flex" flexDirection="column" height="100%" width="100%">
                    <Box
                      display="flex"
                      justifyContent="center"
                      textAlign="center"
                      alignItems="center"
                      height="34px"
                      marginBottom={1}
                      marginTop={1}
                    >
                      <Typography variant="h3">{row.name}</Typography>
                    </Box>
                    <Line height={1} />
                    {props.iconList.map((icon) => {
                      return (
                        <Box key={icon.key + row.id}>
                          <Box marginBottom="8px">
                            {icon.name && icon.icon ? (
                              <>
                                <Box
                                  display="flex"
                                  alignItems="center"
                                  paddingLeft={1}
                                  paddingRight={1}
                                >
                                  <Avatar
                                    style={{
                                      backgroundColor: icon.color,
                                      height: theme.spacing(4),
                                      width: theme.spacing(4),
                                    }}
                                  >
                                    <FontAwesomeIcon icon={icon.icon} size="sm" color="#fff" />
                                  </Avatar>
                                  <LineItem label={icon.name} value={row[icon.key]} noUnderline />
                                </Box>
                                <Line height={1} />
                              </>
                            ) : (
                              <Box
                                display="flex"
                                justifyContent="flex-start"
                                height="56px"
                                paddingLeft={1}
                                paddingRight={1}
                              >
                                <Typography variant="subtitle2">{row[icon.key]}</Typography>
                              </Box>
                            )}
                          </Box>
                        </Box>
                      );
                    })}
                    {props.canEdit && (
                      <Box
                        display="flex"
                        height="100%"
                        width="100%"
                        marginBottom={2}
                        marginTop={1}
                        justifyContent="center"
                      >
                        <ManageRoleButton role={row} type={props.type} />
                      </Box>
                    )}
                  </Box>
                </Box>
              </ListCard>
            </Grid>
          );
        })}
      </Grid>
      <RoleDialogComponent type={props.type} id={props.id} />
      {props.canCreate && (
        <Box position="absolute" bottom="48px" right="36px">
          <AddRoleButton type={props.type} />
        </Box>
      )}
    </Box>
  );
}

interface ManageRoleProps {
  role: RoleObj;
  type: 'Profile' | 'Profile Type';
}

function ManageRoleButton(props: ManageRoleProps) {
  const setRoleDialog = useSetRecoilState(RoleDialog_Data);

  return (
    <Button
      onClick={() => {
        setRoleDialog({
          id: props.role.id,
          name: props.role.name,
          description: props.role.description,
          permissions: props.role.permissions,
          notificationCodes: props.role.notificationCodes,
          dashboardFeatureCodes: props.role.dashboardFeatureCodes,
          documentTypesCanView: props.role.documentTypesCanView,

          title: 'Manage Role',
          subtitle:
            props.type === 'Profile'
              ? 'Manage a role for this profile'
              : 'Manage a default role for this profile type',
        });
      }}
      style={{
        width: '50%',
      }}
      color="secondary"
      variant="contained"
    >
      Manage
    </Button>
  );
}

interface AddRoleProps {
  type: 'Profile' | 'Profile Type';
}

function AddRoleButton(props: AddRoleProps) {
  const setRoleDialog = useSetRecoilState(RoleDialog_Data);

  return (
    <Fab
      color="primary"
      aria-label="add"
      variant="extended"
      onClick={() => {
        setRoleDialog({
          id: null,
          name: '',
          description: '',
          permissions: [],
          notificationCodes: [],
          dashboardFeatureCodes: [],
          documentTypesCanView: [],

          title: 'Add Role',
          subtitle:
            props.type === 'Profile'
              ? 'Create a new role for this profile'
              : 'Create a new default role for this profile type',
        });
      }}
    >
      <i className="material-icons" style={{ marginRight: '8px' }}>
        {'add'}
      </i>
      {props.type === 'Profile' ? 'Create New Profile Role' : 'Create New Profile Type Role'}
    </Fab>
  );
}
