import {
  NotificationCode,
  NotificationFrequency,
  DashboardFeatureCode,
  GqlProfileQuery,
  LocationType,
  PartyType,
  PermissionCode,
} from 'api/GQL_Types';
import { RoleObj } from 'app/admin/components/RolesCardList';
import { AdminPagesContact } from 'app/admin/states';
import { sortWithBy } from 'lib/sort';
import { useSetRecoilState } from 'recoil';
import { newAtom } from 'recoil-utils/utils';

export interface ProfilePageData {
  id: string;
  name: string;
  nameAliases: string[];
  profileCode: string;
  profileCodeAliases: string[];
  needsUpdate: boolean;

  network: {
    id: string;
    name: string;
    connectedProfiles: ProfilePageConnectedProfile[];
  };

  relatedProfiles: ProfilePageRelatedProfile[];

  logo: { url: string } | null;

  profileType: {
    id: string;
    name: string;
    permissionCodes: PermissionCode[];
    notificationCodes: NotificationCode[];
    dashboardFeatureCodes: DashboardFeatureCode[];
  } | null;

  contacts: AdminPagesContact[];

  mainLocation: ProfilePageLocation | null;
  mainContact: AdminPagesContact | null;

  profileTypeOptions: {
    id: string;
    name: string;
  }[];

  rolesList: RoleObj[];

  locationList: ProfilePageLocation[];
}

export const ProfilePageStates = {
  profile: newAtom<ProfilePageData | null>(null),

  profileName: newAtom<string>(''),
  profileNameAliases: newAtom<string[]>([]),
  profileCode: newAtom<string>(''),
  profileCodeAliases: newAtom<string[]>([]),
  profileType: newAtom<{ id: string; name: string }>({ id: '', name: '' }),
  mainLocation: newAtom<{ id: string; name: string }>({ id: '', name: '' }),
  mainContact: newAtom<{ id: string; name: string }>({ id: '', name: '' }),
};

export function useSetProfilePageState() {
  const setProfile = useSetRecoilState(ProfilePageStates.profile);

  const setProfileName = useSetRecoilState(ProfilePageStates.profileName);
  const setProfileNameAliases = useSetRecoilState(ProfilePageStates.profileNameAliases);
  const setProfileCode = useSetRecoilState(ProfilePageStates.profileCode);
  const setProfileCodeAliases = useSetRecoilState(ProfilePageStates.profileCodeAliases);
  const setProfileType = useSetRecoilState(ProfilePageStates.profileType);
  const setMainLocation = useSetRecoilState(ProfilePageStates.mainLocation);
  const setMainContact = useSetRecoilState(ProfilePageStates.mainContact);

  return (data: GqlProfileQuery) => {
    if (data.profile) {
      const locations = extractLocations(data);
      locations.sort(
        sortWithBy((loc) => {
          const isPrimary = loc.locationTypes.find((locType) => locType === LocationType.Primary);
          return (isPrimary ? 'A' : 'Z') + loc.name + loc.id;
        })
      );

      const contacts = extractContacts(data);
      contacts.sort(
        sortWithBy((contact) => {
          return (
            (contact.isUserEnabled ? 'A' : 'Z') + contact.firstName + contact.lastName + contact.id
          );
        })
      );

      const mainLocation =
        locations.find((loc) => loc.locationTypes.find((t) => t === LocationType.Primary)) || null;
      const mainContact = contacts.find((c) => c.isMainContact) || null;

      setProfile({
        id: data.profile.id,
        name: data.profile.name,
        nameAliases: data.profile.nameAliases,
        profileCode: data.profile.profileCode || '',
        profileCodeAliases: data.profile.profileCodeAliases,
        needsUpdate: data.profile.needsUpdate,

        network: data.profile.network,
        relatedProfiles: extractRelatedParties(data),

        logo: data.profile.logo ? { url: data.profile.logo.url } : null,

        locationList: locations,
        contacts,

        mainLocation,
        mainContact,

        profileType: data.profile.profileType || null,

        profileTypeOptions: data.profile.network.profileTypes,

        rolesList: data.profile.roles.map((role) => {
          return {
            id: role.id,
            name: role.name,
            description: role.description || '',
            permissionLength: role.permissionCodes.length,
            permissions: role.permissionCodes,
            notificationCodes: role.notificationCodes,
            dashboardFeatureCodes: role.dashboardFeatureCodes,
            documentTypesCanView: role.documentTypesCanView,
          };
        }),
      });
      setMainLocation(
        mainLocation ? { id: mainLocation.id, name: mainLocation.name } : { id: '', name: '' }
      );
      setMainContact(
        mainContact
          ? { id: mainContact.id, name: `${mainContact.firstName} ${mainContact.lastName}`.trim() }
          : { id: '', name: '' }
      );
    } else {
      setProfile(null);
      setMainLocation({ id: '', name: '' });
      setMainContact({ id: '', name: '' });
    }

    setProfileName(data.profile?.name || '');
    setProfileNameAliases(data.profile?.nameAliases || []);
    setProfileCode(data.profile?.profileCode || '');
    setProfileCodeAliases(data.profile?.profileCodeAliases || []);
    setProfileType(data.profile?.profileType || { id: '', name: '' });
  };
}

export interface ProfilePageLocation {
  id: string;
  name: string;
  code: string;
  nameAliases: string[];
  codeAliases: string[];
  addressLine1: string;
  addressLine2: string;
  city: string;
  state: string;
  postalCode: string;
  country: string;
  companyPhone: string;
  companyUrl: string;
  needsUpdate: boolean;
  locationTypes: LocationType[];
  relatedPort: {
    code: string;
    name: string;
  } | null;
}

function extractLocations(data: GqlProfileQuery): ProfilePageLocation[] {
  if (!data.profile) {
    return [];
  }
  return data.profile.locations.map((loc): ProfilePageLocation => {
    return {
      id: loc.id,
      name: loc.name,
      code: loc.code,
      nameAliases: loc.nameAliases,
      codeAliases: loc.codeAliases,
      addressLine1: loc.addressLine1 || '',
      addressLine2: loc.addressLine2 || '',
      city: loc.city || '',
      state: loc.state || '',
      postalCode: loc.postalCode || '',
      relatedPort: loc.relatedPort || null,
      country: loc.country || '',
      companyPhone: loc.companyPhone || '',
      companyUrl: loc.companyUrl || '',
      needsUpdate: loc.needsUpdate,
      locationTypes: loc.locationTypes,
    };
  });
}

function extractContacts(data: GqlProfileQuery): AdminPagesContact[] {
  if (!data.profile) {
    return [];
  }
  return data.profile.contacts.map((contact) => {
    return {
      id: contact.id,
      firstName: contact.firstName || '',
      lastName: contact.lastName || '',
      title: contact.title || '',
      email: contact.email || '',
      phoneMain: contact.phoneMain || '',
      extension: contact.extension || '',
      phoneAlternative: contact.phoneAlternative || '',
      phoneCell: contact.phoneCell || '',
      isUserEnabled: contact.isUserEnabled,
      isMainContact: contact.isMainContact,
      needsUpdate: contact.needsUpdate,
      location: contact.location
        ? { id: contact.location.id, name: contact.location.name || '' }
        : null,
      role: contact.role ? { id: contact.role.id, name: contact.role.name || '' } : null,
      notificationFrequency: contact.notificationFrequency || NotificationFrequency.Immediately,
    };
  });
}

export interface ProfilePageConnectedProfile {
  id: string;
  name: string;
  isControllingClient: boolean;
}

export interface ProfilePageRelatedProfile {
  id: string;
  partyType: PartyType;
  profile: {
    id: string;
    name: string;
    //   profileCode
    //   profileType {
    //     id
    //     name
    //   }
    roles: {
      id: string;
    }[];
    locations: {
      id: string;
      name: string;
    }[];
    contacts: {
      id: string;
      // firstName: string;
      // lastName: string;
    }[];
    //   relatedProfiles {
    //     id
    //   }
    //   network {
    //     name
    //   }
  };
}

function extractRelatedParties(data: GqlProfileQuery): ProfilePageRelatedProfile[] {
  if (!data.profile) {
    return [];
  }
  return data.profile.relatedProfiles;
}
