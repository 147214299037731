import { Box, Button, Typography } from '@material-ui/core';
import { navigate } from '@reach/router';
import { useMergeProfileMutation } from 'api/GQL_Types';
import { ProfileQuery } from 'api/queries/profileQueries';
import { ManagePageHeader_BackStack } from 'app/admin/components/ManagePageHeader';
import FormInputSelect from 'components/form/FormInputSelect';
import UniversalDialog from 'components/UniversalDialog';
import { useSnackbar } from 'notistack';
import React from 'react';
import { useRecoilState } from 'recoil';
import { newAtom } from 'recoil-utils/utils';
import { KeepAsNewProfile } from './KeepAsNewProfile';

export const ReviewProfileDialog_Open = newAtom(false);

interface Props {
  profile: {
    id: string;
    name: string;
    profileCode: string;
  };

  mergeToProfileOptions: {
    id: string;
    name: string;
  }[];

  profileTypeOptions: {
    id: string;
    name: string;
  }[];
}

export const ReviewProfileDialog: React.FC<Props> = (props) => {
  const [openDialog, setOpenDialog] = useRecoilState(ReviewProfileDialog_Open);
  const [backStack, setBackStack] = useRecoilState(ManagePageHeader_BackStack);
  const [mergeToId, setMergeToId] = React.useState<string | null>(null);
  const [keepAsNew, setKeepAsNew] = React.useState<boolean>(false);

  const { enqueueSnackbar } = useSnackbar();

  const [mergeProfile, { loading: mergeProfileLoading }] = useMergeProfileMutation({
    refetchQueries: () => [
      {
        query: ProfileQuery,
        variables: { profileId: props.profile.id },
      },
    ],
    onCompleted(data) {
      if (data.mergeProfile.success) {
        enqueueSnackbar('Profile Merged!', {
          variant: 'success',
          anchorOrigin: {
            vertical: 'top',
            horizontal: 'right',
          },
        });
        setOpenDialog(false);
        if (backStack.length > 0) {
          const copy = [...backStack];
          const to = copy.pop() ?? { label: '', path: '' };
          setBackStack(copy);
          if (mergeToId) {
            navigate('/admin/profiles/' + mergeToId);
          } else {
            navigate(to.path);
          }
        } else {
          if (mergeToId) {
            navigate('/admin/profiles/' + mergeToId);
          }
        }
      } else {
        enqueueSnackbar(data.mergeProfile.message || 'Failed to merge profiles.', {
          variant: 'error',
          anchorOrigin: {
            vertical: 'top',
            horizontal: 'right',
          },
        });
      }
    },
    onError(error) {
      enqueueSnackbar('Failed to merge profiles.', {
        variant: 'error',
        anchorOrigin: {
          vertical: 'top',
          horizontal: 'right',
        },
      });
    },
  });

  React.useEffect(() => {
    setMergeToId(null);
    setKeepAsNew(false);
  }, [openDialog]);

  if (keepAsNew) {
    return (
      <UniversalDialog
        title="Review Profile"
        open={openDialog}
        setClose={() => setKeepAsNew(false)}
      >
        <KeepAsNewProfile
          profile={props.profile}
          mergeToProfileOptions={props.mergeToProfileOptions}
          profileTypeOptions={props.profileTypeOptions}
          onCancel={() => {
            setKeepAsNew(false);
          }}
        />
      </UniversalDialog>
    );
  }

  return (
    <UniversalDialog
      title="Review Profile"
      open={openDialog}
      setClose={() => {
        setOpenDialog(false);
      }}
    >
      <Box width={700}>
        <Box display="flex" alignItems="center" marginTop={3}>
          <Box flexGrow={1} marginRight={2}>
            <FormInputSelect
              label="Merge to Profile"
              options={props.mergeToProfileOptions
                .filter((profile) => profile.id !== props.profile.id)
                .map(({ id, name }) => ({ id, label: name }))}
              value={mergeToId}
              onValue={setMergeToId}
              disabled={mergeProfileLoading}
            />
          </Box>
          <Box display="flex" justifyContent="center">
            <Button
              variant="contained"
              size="large"
              color="primary"
              disabled={mergeProfileLoading || !mergeToId}
              onClick={() => {
                if (!mergeToId) return;
                mergeProfile({
                  variables: {
                    input: {
                      parentProfileId: mergeToId,
                      mergedProfileId: props.profile.id,
                    },
                  },
                });
              }}
            >
              Merge Profile
            </Button>
          </Box>
        </Box>

        <Box display="flex" justifyContent="center" margin={3}>
          <Typography variant="h2">OR</Typography>
        </Box>

        <Box display="flex" justifyContent="center">
          <Button
            variant="contained"
            size="large"
            color="primary"
            disabled={mergeProfileLoading}
            onClick={() => {
              setKeepAsNew(true);
            }}
          >
            Keep As New Profile
          </Button>
        </Box>
      </Box>
    </UniversalDialog>
  );
};
